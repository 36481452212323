/* eslint-disable react-hooks/exhaustive-deps */
import './ProductBulkDescriptionDialog.scss';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    API_PRODUCT_BULK_DESCRIPTION_CHANGE,
    API_PRODUCT_BULK_DESCRIPTION_CHANGE_GET_RELATED_ATTRIBUTES,
    API_PRODUCT_BULK_DESCRIPTION_CHANGE_PRE_VALIDATION
} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {
    bulkProductUpdateDuplicateCheckResponse,
    productBulkDescriptionChangeGetRelatedAttributesPayload,
    productBulkDescriptionChangeGetRelatedAttributesResponse,
    productBulkDescriptionChangePayload
} from 'shared/models/product.model';
import {characteristicsObject, slimAttributeResponseModel} from 'shared/models/attribute.model';
import {commonAttributeDataModel} from 'shared/models/attributeComponent.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Attribute, {isAttributeValid} from 'components/Attribute';
import {isAttributeMandatory} from 'components/Attribute/AttributeHelpers';
import ProductDescriptionDisplay from 'components/Displays/ProductDescriptionDisplay';
import {ConfirmDialog} from 'components/ConfirmDialog';
import {PaperX} from 'components/PaperX';
import {productSearchTableRow} from 'modules/MasterData/Product/ProductsListSearchResults'
import {characteristicsObject2Array} from 'modules/MasterData/Product/ProductBulkChange/CharacteristicsDrawer';
import SelectDescriptionDialog from 'modules/MasterData/Product/ProductDetails/SelectDescriptionDialog';
import DuplicateFoundDialog from 'modules/MasterData/Product/ProductBulkChange/DuplicateFoundDialog';
import {DIALOGS} from 'modules/MasterData/Product/ProductBulkChange/ProductBulkChange';

interface ProductBulkDescriptionDialogProps {
    data: productSearchTableRow[],
    onClose: (updateSuccessful: boolean) => void,
    open: boolean,
}

const ProductBulkDescriptionDialog = ({data, onClose, open}: ProductBulkDescriptionDialogProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDescriptionId, setSelectedDescriptionId] = useState<string>(null);
    const [categoryBrickId, setCategoryBrickId] = useState<string>(null);
    const [depreciatedAttributeNames, setDepreciatedAttributeNames] = useState<string[]>([]);
    const [relatedAttributes, setRelatedAttributes] = useState<slimAttributeResponseModel[]>([]);
    const [characteristics, setCharacteristics] = useState<characteristicsObject>({});
    const [duplicatesToShow, setDuplicatesToShow] = useState<productSearchTableRow[]>([]);
    const [showDialog, setShowDialog] = useState<DIALOGS>(DIALOGS.NONE)
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const notifyError = () => enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});

    useEffect(() => {
        if (open) {
            setShowDialog(DIALOGS.SELECT_DESCRIPTION);
        }
    }, [open]);

    const getRelatedAttributes = () => {
        setIsLoading(true);
        const payload: productBulkDescriptionChangeGetRelatedAttributesPayload = {
            lang,
            productIds: data.map((product) => product.id),
            replacementProductDescriptionId: selectedDescriptionId
        }
        axios.post<productBulkDescriptionChangeGetRelatedAttributesResponse>(API_PRODUCT_BULK_DESCRIPTION_CHANGE_GET_RELATED_ATTRIBUTES, payload)
            .then((resp) => {
                if(responseValidation(resp.data)) {
                    if (resp.data.deprecatedAttributeNames?.length === 0 && resp.data.newAttributes?.attributes?.length === 0) { // no attribute change, show confirmation
                        setCategoryBrickId(resp.data.newAttributes.id);
                        setShowDialog(DIALOGS.CONFIRM_UPDATE);
                    } else { //attribute change, show depreciated and new attributes
                        setDepreciatedAttributeNames(resp.data.deprecatedAttributeNames);
                        setRelatedAttributes(resp.data.newAttributes.attributes);
                        setCategoryBrickId(resp.data.newAttributes.id);
                        const newCharacteristics: characteristicsObject = {};
                        resp.data.newAttributes.attributes.forEach((attribtue) => newCharacteristics[attribtue.id] = null);
                        setCharacteristics(newCharacteristics);
                    }
                } else {
                    notifyError();
                }
            })
            .catch((e) => {
                console.log(e);
                notifyError();
            })
            .finally(() => setIsLoading(false));
    };

    const buildPayload = (): productBulkDescriptionChangePayload => ({
        characteristics: characteristicsObject2Array(characteristics),
        categoryBrickId,
        productIds: data.map((product) => product.id),
        replacementProductDescriptionId: selectedDescriptionId
    });

    const checkForDuplicates = (brickId?: string) => {
        setIsLoading(true);
        const payload: productBulkDescriptionChangePayload = buildPayload();
        if (brickId) {
            payload.categoryBrickId = brickId;
        }
        axios.post<bulkProductUpdateDuplicateCheckResponse>(API_PRODUCT_BULK_DESCRIPTION_CHANGE_PRE_VALIDATION, payload)
            .then((resp) => {
                if(responseValidation(resp.data)) {
                    const duplicateProductIds: string[] = resp.data.duplicates;
                    if (duplicateProductIds?.length) { //duplicates found, confirm
                        setDuplicatesToShow(data.filter((item) => duplicateProductIds.includes(item.id)));
                        setShowDialog(DIALOGS.CONFIRM_DUPLICATES);
                        setIsLoading(false);
                    } else { // no duplicates, proceed
                        executeBulkDescriptionUpdate(payload);
                    }
                } else {
                    notifyError();
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                notifyError();
                setIsLoading(false);
            });
    };

    const executeBulkDescriptionUpdate = (payload: productBulkDescriptionChangePayload) => {
        setIsLoading(true);
        axios.patch(API_PRODUCT_BULK_DESCRIPTION_CHANGE, payload)
            .then(() => {
                cleanup();
                onClose(true);
            })
            .catch((e) => {
                console.log(e);
                notifyError();
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (selectedDescriptionId) {
            getRelatedAttributes();
        }
    }, [selectedDescriptionId]);

    const handleSelectDescription = (descriptionId: string) => {
        setSelectedDescriptionId(descriptionId);
        setShowDialog(DIALOGS.NONE);
    };

    const handleProductCharacteristicChange = (key: string, value: commonAttributeDataModel) => {
        const newCharacteristics = {...characteristics};
        newCharacteristics[key] = value;
        setCharacteristics(newCharacteristics);
    };

    const cleanup = () => {
        setSelectedDescriptionId(null);
        setDepreciatedAttributeNames([]);
        setRelatedAttributes([]);
        setCategoryBrickId(null);
        setCharacteristics({});
        setShowDialog(DIALOGS.NONE);
    };

    const handleClose = () => {
        cleanup();
        onClose(false);
    };

    const disableSave = (): boolean => {
        if (!selectedDescriptionId) return true;

        let disable:boolean = false;
        relatedAttributes
            .forEach((item) => {
                if ((isAttributeMandatory(item) || characteristics[item.id] !== null) && !isAttributeValid(characteristics[item.id])) {
                    disable = true;
                }
            });
        return disable;
    };

    const handleCloseSelectDescription = () => {
        setShowDialog(DIALOGS.NONE)
        if (!selectedDescriptionId) {
            onClose(false);
        }
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <Dialog className="productBulkDescriptionDialogRoot _dialog-likeView" open={open} fullScreen>
                <DialogTitle>
                    <PaperX>
                        <div className="_formRow noMargins">
                            <div>
                                <ProductDescriptionDisplay id={selectedDescriptionId}/>
                            </div>
                        </div>
                        <div className="_formRow noMargins">
                            <Button variant="outlined" size="small" color="primary" onClick={() => setShowDialog(DIALOGS.SELECT_DESCRIPTION)}>
                                <FormattedMessage id='product.chooseDescription'/>
                            </Button>
                        </div>
                    </PaperX>
                </DialogTitle>
                <DialogContent className="_directionCol">
                    <div className="dialogContentWrapper">
                        <PaperX className="_halfWidth _scrollY">
                            <div className="_header">
                                <FormattedMessage id="b.deprecatedCharacteristics"/>:
                            </div>
                            <ul>
                                {depreciatedAttributeNames?.map((item) => <li key={item}>{item}</li>)}
                            </ul>
                        </PaperX>
                        <PaperX className="_halfWidth _scrollY">
                            <div className="_header withExplanation">
                                <span>
                                    <FormattedMessage id="b.characteristics"/>:
                                </span>
                                <Tooltip title={translate({id: 'product.bulkDescriptionHelp'})}>
                                    <HelpOutlineIcon color="secondary" fontSize="small"/>
                                </Tooltip>
                            </div>
                            {relatedAttributes
                                .map((attribute) =>
                                    <div className="_formRow" key={attribute.id}>
                                        <div key={`${attribute.id}-key`}>
                                            <Attribute newData={attribute}
                                                       data={characteristics[attribute.id]}
                                                       onChange={handleProductCharacteristicChange}
                                                       required={isAttributeMandatory(attribute)}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </PaperX>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary"
                            disabled={disableSave()}
                            onClick={() => setShowDialog(DIALOGS.CONFIRM_UPDATE)}>
                        <FormattedMessage id="a.submit"/>
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => handleClose()}><FormattedMessage id="a.cancel"/></Button>
                </DialogActions>
            </Dialog>
            <SelectDescriptionDialog category={null}
                                     isDescribingFrame={false}
                                     marketId={countryMarket.market}
                                     open={showDialog === DIALOGS.SELECT_DESCRIPTION}
                                     onClose={handleCloseSelectDescription}
                                     onSelect={(descriptionId) => handleSelectDescription(descriptionId)}
            />
            <DuplicateFoundDialog data={duplicatesToShow}
                                  onConfirmDuplicates={() => executeBulkDescriptionUpdate(buildPayload())}
                                  open={showDialog === DIALOGS.CONFIRM_DUPLICATES}
                                  onClose={() => setShowDialog(DIALOGS.NONE)}
            />
            <ConfirmDialog open={showDialog === DIALOGS.CONFIRM_UPDATE}
                           onConfirm={() => checkForDuplicates()}
                           onCancel={() => setShowDialog(DIALOGS.NONE)}
                           message={
                               <div>
                                   <div className="_formRow">
                                       <FormattedMessage id="product.bulkUpdateDescription" values={{count: data.length}}/>:
                                   </div>
                                   <div>
                                       <ProductDescriptionDisplay id={selectedDescriptionId}/>
                                   </div>
                               </div>
                           }
                           confirmLabelId="a.confirm"
                           cancelLabelId="a.cancel"/>
        </>
    );
};

export default ProductBulkDescriptionDialog
import {useRef, useState} from 'react';
import axios from 'axios';
import {API_STATUSES} from 'config/api/constants';
import {useSnackbar} from 'notistack';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';

type options = {
    failureMsg?: string,
    errMsg?: string,
    succMsg?: string
}

export const parseParams = (params):string => {
    let options = '';

    for (const key in params) {
        const isParamTypeObject = typeof params[key] === 'object';
        const isParamTypeArray = Array.isArray(params[key]);

        if (!isParamTypeObject) {
            options += `${key}=${params[key]}&`;
        }

        if (isParamTypeObject && isParamTypeArray) {
            for (const element of params[key]) {
                options += `${key}=${element}&`;
            }
        }
    }
    return options ? options.slice(0, -1) : options;
}

export const useApi = (method: string, initState: any, options?: options) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();
    const [data, setData] = useState(initState);
    const [dataError, setDataError] = useState(null);
    const [status, setStatus] = useState(API_STATUSES.NEW);
    const [errorCode, setErrorCode] = useState<number>();

    const succMsg = options ? options.succMsg : '';
    const errMsg = options ? options.errMsg : '';
    const failureMsg = options ? options.failureMsg : '';

    const errResponses = {
        LEAFLET_ALREADY_EXISTS: 'headerData.duplicateLeaflet',
        LEAFLET_LOCKED: 'headerData.errLocked',
        PRODUCT_LOCK: 'gtin.productLocked'
    }

    const cancelToken = useRef(null);

    const clearToken = () => {
        if (cancelToken.current) {
            cancelToken.current();
            setStatus(API_STATUSES.CANCELLED);
        }
    };

    const call = (path: string, data?: any, cfg?: any) => {
        clearToken();
        setErrorCode(null);
        setStatus(API_STATUSES.PENDING);
        // data works as params for get requests, ex { responseType: 'blob' }
        axios[method](path, data, {...cfg, cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if (method.toLowerCase() === 'get') {
                    if (responseValidation(resp.data)) {
                        if (succMsg) {
                            enqueueSnackbar(`${translate({id: succMsg})}`, {variant: 'success', persist: false});
                        }
                        setData(resp.data);
                        setStatus(API_STATUSES.IDLE);
                    } else {
                        setStatus(API_STATUSES.ERROR);
                    }
                    
                } else {
                    if (succMsg) {
                        enqueueSnackbar(`${translate({id: succMsg})}`, {variant: 'success', persist: false});
                    }
                    setData(resp.data);
                    setStatus(API_STATUSES.IDLE);
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    setDataError(e?.response?.data);
                    setErrorCode(e.response.status);
                    if (e.response && errResponses[e?.response?.data?.message]) {
                        enqueueSnackbar(`${translate({id: errResponses[e.response.data.message]})}`, {variant: 'error', persist: false});
                    } else if (e.response && failureMsg) {
                        enqueueSnackbar(`${translate({id: failureMsg})}`, {variant: 'error', persist: false});
                    } else if (errMsg) {
                        enqueueSnackbar(`${translate({id: errMsg})}`, {variant: 'error', persist: false});
                    }
                    setStatus(API_STATUSES.ERROR);
                } else {
                    setStatus(API_STATUSES.CANCELLED);
                }
            });
        return cancelToken.current;
    };

    return {call, status, data, dataError, errorCode, clearToken};
};
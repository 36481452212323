import {basic} from 'shared/models/_common.model';
import {API_GET_BRANDS_LIST} from 'config/api/constants';
import Search from 'components/Search/Search';

type brandSearchProps = {
    autoFocus?: boolean,
    customLabel?: string,
    clearOnSelect?: boolean,
    data?: basic,
    limit?: number,
    onBrandSelect: (brand: basic) => void,
    queryParams?: any,
    required?: boolean
}

const defaultBrandResultsLimit: number = 100;

const BrandSearch = (props:brandSearchProps) => {
    return (
        <Search onSelectionChange={props.onBrandSelect}
                searchUrl={API_GET_BRANDS_LIST}
                label={props.customLabel || "b.brand"}
                limit={props.limit || defaultBrandResultsLimit}
                minQueryLen={1}
                data={props.data}
                queryParams={props.queryParams}
                required={props.required}
                autoFocus={props.autoFocus}
                clearOnSelect={props.clearOnSelect}
        />)
};
export default BrandSearch;
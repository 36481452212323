import './PrintMediaOverviewAction.scss';

import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import {ArrowForward} from '@mui/icons-material';
import { paths } from 'paths';
import {useFormatMessage} from 'utils/translate';
import {printMediaOverviewTableRow, leafletStatus} from 'shared/models/leaflet.model';

const quickActions = {
    FRAME: 'FRAME',
    ENTER_GLOBAL_HEADER_DATA: 'ENTER_GLOBAL_HEADER_DATA',
    DESCRIBE_FRAMES: 'DESCRIBE_FRAMES'
};

export const leafletStatusLabels = {
    [leafletStatus.UPLOADED]: 'printMediaOverview.leafletUploaded',
    [leafletStatus.PAGED]: 'printMediaOverview.leafletPaged',
    [leafletStatus.ENTERED_HEADER_DATA]: 'printMediaOverview.leafletDescribed',
    [leafletStatus.FRAMED]: 'printMediaOverview.leafletFramed',
    [leafletStatus.DESCRIBING_IN_PROGRESS]: 'leafletStatus.desribingInProgress',
    [leafletStatus.FRAMES_DESCRIBED]: 'printMediaOverview.leafletReady',
}

interface LeafletOverviewQuickActionProps {
    quickAction: string,
    id: string
}

function LeafletOverviewQuickAction(props: LeafletOverviewQuickActionProps) {
    const translate = useFormatMessage();
    const {id, quickAction} = props;

    switch (quickAction) {
        case quickActions.ENTER_GLOBAL_HEADER_DATA:
            return (
                <Link to={`${paths.enterHeaderData}?id=${id}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'printMediaOverview.headerDataCTA'})}</Button>
                </Link>);
        case quickActions.FRAME:
            return (
                <Link to={`${paths.framing}?leafletId=${id}&from=${paths.printMediaOverview}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'nav.framingCTA'})}</Button>
                </Link>);
        case quickActions.DESCRIBE_FRAMES:
            return (
                <Link to={`${paths.describeFrame}?leafletId=${id}&from=${paths.printMediaOverview}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'printMediaOverview.describeFrame'})}</Button>
                </Link>);
        default:
            return null;
    }
}

interface PrintMediaOverviewActionProps {
    data: printMediaOverviewTableRow,
    value: string,
}

export function PrintMediaOverviewAction({data, value}: PrintMediaOverviewActionProps) {
    const translate = useFormatMessage();
    return (<div className="overviewLeafletActionRoot">
        <span>{translate({id: leafletStatusLabels[value]})}</span>
        <LeafletOverviewQuickAction quickAction={data.quickAction} id={data.id}/>
    </div>);
}

interface PrintMediaOverviewLabelProps {
    value: string
}

export function PrintMediaOverviewLabel({value}: PrintMediaOverviewLabelProps) {
    const translate = useFormatMessage();

    return (<div className="overviewLeafletActionRoot">
        <span>{translate({id: leafletStatusLabels[value]})}</span>
    </div>);
}
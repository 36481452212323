const windowIsDefined = typeof window === 'undefined' ? null : window; // @TODO this check was added for test engines, verify if still needed

export const storeInLocalStorage = (key: string = '', value: string = ''): void => {
    if (windowIsDefined && key.length > 0 && value.length > 0) {
        try {
            window.localStorage.setItem(key, value);
        } catch (err) {
            console.error(`could not store ${key} into localStorage ${err}`);
        }
    } else {
        console.warn(`could not store ${key} into localStorage, is it non-empty string?`);
        window.localStorage.removeItem(key);
    }
};

export const removeFromLocalStorage = (key: string) => {
    if (windowIsDefined && key.length > 0 ) {
        try {
            window.localStorage.removeItem(key);
        } catch (err) {
            console.error(`could not remove ${key} fromlocalStorage ${err}`);
        }
    } else {
        console.warn(`could not remove ${key} from localStorage, is it non-empty string?`);
    }
};

export const getFromLocalStorage = (key: string): string | null => {
    try {
        return window.localStorage.getItem(key);
    } catch (err) {
        return null;
    }
};

export const getNumberFromLocalStorage = (key: string, float = false): number | null => {
    try {
        if (float) {
            return parseFloat(window.localStorage.getItem(key));
        }
        return parseInt(window.localStorage.getItem(key));
    } catch (err) {
        return null;
    }
};

export const getObjectFromLocalStorage = (key: string) => {
    try {
        return JSON.parse(window.localStorage.getItem(key));
    } catch (err) {
        return null;
    }
};

/* eslint-disable react-hooks/exhaustive-deps */
import './CountrySelector.scss';

import {useEffect, useMemo, useState } from 'react';
import {FormattedMessage} from 'react-intl';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {countries} from 'shared/reducers/countryMarketConst';
import {useFormatMessage} from 'utils/translate';

export enum inputSize {
    'small' = 'small',
    'medium' = 'medium'
}

interface countrySelectorProps {
    any?: boolean,
    compact?: boolean,
    disabled?: boolean
    onCountryChange: (countryId: string) => void,
    required?: boolean,
    size?: inputSize,
    value: string,
}

const CountrySelector = ({onCountryChange, compact, any, value, size, required, disabled} : countrySelectorProps) => {
    const translate = useFormatMessage();

    const [selectedCountry, setSelectedCountry] = useState<string>(any ? translate({id: 'a.any'}) : value);

    const buildCountriesList = (): React.ReactNode[] => compact ?
        countries.map((country) => (<MenuItem key={country.id} value={country.id}>{country.id}</MenuItem>))
        :
        countries.map((country) => (<MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>));

    const countriesList = useMemo(buildCountriesList, [countries, compact]);

    useEffect(() => {
        if(value){
            setSelectedCountry(value)
        }
    }, [value]);

    useEffect(()=> {
        onCountryChange(selectedCountry);
    }, [selectedCountry]);

    const handleCountryChange = (value: string) => setSelectedCountry(value);

    return <div className={compact ? "countrySelectorRootCompact" : "countrySelectorRoot"}>
        <FormControl className="countrySelect" required={required} fullWidth>
            <InputLabel color="primary"><FormattedMessage id="b.country"/></InputLabel>
            <Select color="primary" value={selectedCountry} size={size || "medium"} disabled={disabled}
                    required={required}
                    onChange={(e) => handleCountryChange(e.target.value)}
                    label={translate({id: 'b.country'})}
            >
                {any && <MenuItem key="anyCountry" value={translate({id: 'a.any'})}><FormattedMessage id="a.any"/></MenuItem>}
                {countriesList}
            </Select>
        </FormControl>
    </div>
};

export default CountrySelector;
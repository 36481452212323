import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';

interface confirmDialogProps {
    open: boolean,
    onConfirm: () => void,
    onCancel: () => void,
    message: string | ReactElement,
    confirmLabelId: string,
    cancelLabelId: string
}

const ConfirmDialog = (props:confirmDialogProps) => {
    const {message, open, onConfirm, onCancel, confirmLabelId, cancelLabelId} = props;

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>
                <FormattedMessage id="a.confirm"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                {message}
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onConfirm}><FormattedMessage id={confirmLabelId}/></Button>
                <Button variant="outlined" color="secondary" onClick={onCancel}><FormattedMessage id={cancelLabelId}/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
import './BrandNewView.scss';
import {ReactElement, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {History} from 'history';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_CREATE_BRAND} from 'config/api/constants';
import {paths} from 'paths';
import {
    basicBrandDuplicateResponseModel,
    basicBrandPayload,
    basicBrandResponse,
    brand
} from 'shared/models/brand.model';
import {basic} from 'shared/models/_common.model';
import {useFormatMessage} from 'utils/translate';
import {ButtonClose} from 'components/Buttons';
import Footer from 'components/Footer';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {LoadingOverlay} from 'components/LoadingOverlay';
import BrandDetails from './BrandDetails';

export type POSTPUTAPICallParams = {
    marketId: string,
    force?: boolean,
    reUse?: boolean
}

interface brandNewViewProps {
    history: History,
    isDescribingFrame?: boolean,
    onClose?: () => void,
    onSelect?: (brand: basic) => void
}

const BrandNewView = ({history, isDescribingFrame, onClose, onSelect}: brandNewViewProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brand, setBrand] = useState<brand>(null);
    const [isBrandDuplicateDialogOpen, setIsBrandDuplicateDialogOpen] = useState<boolean>(false);
    const [duplicatedBrands, setDuplicatedBrands] = useState<string[]>([]);

    const handleBrandChange = (brand: brand) => setBrand(brand);

    const handleSaveClick = (force: boolean = false) => {
        setIsLoading(true);
        const payload: basicBrandPayload = {
            active: brand.active,
            name: brand.name,
            supplierIds: brand.suppliers.map((item) => item.id)
        };
        const params: POSTPUTAPICallParams = {
            marketId: brand.marketId
        };
        if (force) params.force = true;
        if (isDescribingFrame) params.reUse = true;

        axios.post<basicBrandResponse>(API_CREATE_BRAND, payload, {
            params
        })
            .then((resp) => {
                setIsLoading(false);
                setDuplicatedBrands([]);
                setIsBrandDuplicateDialogOpen(false);
                !isDescribingFrame ? cleanAndClose(true) : selectAndClose(resp.data);
            })
            .catch((e) => {
                console.log(e);
                if (e?.response?.status === 409) {
                    const resp: basicBrandDuplicateResponseModel = e?.response?.data;
                    if (Array.isArray(resp?.potentialDuplicates)) {
                        setDuplicatedBrands(resp.potentialDuplicates);
                    }
                    setIsBrandDuplicateDialogOpen(true);
                } else {
                    enqueueSnackbar(`${translate({id: 'brand.saveErr'})}`, {variant: 'error', persist: false});
                }
                setIsLoading(false);
            });
    };

    const canSave = ():boolean => {
        if (brand) {
            if (!brand.name || !brand.marketId) {
                return false;
            }
            return true;
        }
        return false;
    };

    const cleanAndClose = (reload:boolean = false) => {
        if (reload) {
            history.push(`${paths.brands}?reload=true`);
        } else if (isDescribingFrame) {
            onClose();
        } else {
            history.push(paths.brands);
        }
    };

    const selectAndClose = (brand: basicBrandResponse) => {
        const {id, name} = brand;
        onSelect({id, name});
    }

    const handleConfirm = () => handleSaveClick(true);

    const handleCancel = () => {
        setIsBrandDuplicateDialogOpen(false);
        setDuplicatedBrands([]);
    };

    const getDialogContent = (): ReactElement => {
        return <>
            <div>
                <FormattedMessage id='brand.duplicateConfirmation1'/>
            </div>
            <div className="_bold">
                {brand?.name}
            </div>
            <div>
                <FormattedMessage id='brand.duplicateFound'/>:
            </div>
            <ul>
                {duplicatedBrands
                    .map((item, idx) =>
                        <li key={idx}><span className="_bold">{item}</span></li>)}
            </ul>
            <br/>
            {translate({id: 'brand.duplicateConfirmation2'})}
        </>;
    };

    return (
        <div className={`viewRoot brandNewViewRoot ${isDescribingFrame ? 'onDialogMode' : ''}`}>
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={isLoading}/>
                    <BrandDetails onBrandChange={(brand: brand) => handleBrandChange(brand)}/>
                </div>
            </div>
            <Footer
                hideNavigationMenu={isDescribingFrame}
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={() => handleSaveClick()} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        <ButtonClose onClick={cleanAndClose}/>
                    </>
                }
            />
            <ConfirmDialog open={isBrandDuplicateDialogOpen}
                           onConfirm={handleConfirm}
                           onCancel={handleCancel}
                           message={getDialogContent()}
                           confirmLabelId="a.save"
                           cancelLabelId="a.close"
            />
        </div>
    );
};

export default BrandNewView;
import bgLocale from 'date-fns/locale/bg';
import csLocale from 'date-fns/locale/cs';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import hrLocale from 'date-fns/locale/hr';
import huLocale from 'date-fns/locale/hu';
import plLocale from 'date-fns/locale/pl';
import roLocale from 'date-fns/locale/ro';
import skLocale from 'date-fns/locale/sk';
import slLocale from 'date-fns/locale/sl';

export const calendarLocales = {
  bg: bgLocale,
  cs: csLocale,
  de: deLocale,
  en: enLocale,
  fr: frLocale,
  hr: hrLocale,
  hu: huLocale,
  pl: plLocale,
  ro: roLocale,
  sk: skLocale,
  sl: slLocale
};
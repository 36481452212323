import {categoryBrick, categoryBrickUI} from 'shared/models/categoryBrick.model';
import {attrTypes} from 'modules/Dictionaries/Attributes/Attribute';

export const transformCategoryResponse = (categoryBrickBE: categoryBrick): categoryBrickUI=> {
    const {name, id, active, activeAssignedAttributes, inactiveAssignedAttributes} = categoryBrickBE;
    const fullAttributes = {};
    categoryBrickBE.activeAssignedAttributes.forEach((item) => fullAttributes[item.id] = null);
    categoryBrickBE.inactiveAssignedAttributes.forEach((item) => fullAttributes[item.id] = null);
    return {
        name,
        id,
        active,
        activeAssignedAttributes,
        inactiveAssignedAttributes,
        fullAttributes
    }
};

export const canSave = (categoryUI: categoryBrickUI): boolean => {
    if (categoryUI) {
        if (Object.keys(categoryUI.fullAttributes).length === 0) {
            return false;
        }
        let isPromotionValid: boolean = true;
        for (const attributeId in categoryUI.fullAttributes) {
            let attr = categoryUI.fullAttributes[attributeId];
            if (attr && attr.type === attrTypes.BRICK_DEPENDENT_ENUMERATION && attr?.activeAssignedLiterals?.length === 0 && attr?.inactiveAssignedLiterals?.length === 0) {
                isPromotionValid = false;
            }
        }
        return isPromotionValid;
    }
    return false;
}
// @TODO unable to define types for product lines, need refactoring
import './ProductLine.scss';
import {
    API_PRODUCT_DATA_PRODUCT_LINE,
    API_PRODUCT_DATA_PRODUCT_LINES,
    API_STATUSES
} from 'config/api/constants';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Button, TextField} from '@mui/material';
import {paths} from 'paths';
import {productLinePayloadModel} from 'shared/models/productLine.model';
import {filteredProductsResponseModel} from 'shared/models/product.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId} from 'utils/routing';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import ActiveCheckbox from 'components/ActiveCheckbox';
import ProductsSelector from 'components/Selectors/ProductsSelector/ProductsSelector';
import ProductFilter from 'components/ProductFilter/ProductFilter';
import {useProductsQuery} from 'components/Selectors/ProductsSelector/productAPI';

type UIProductLineModel = {
    active: boolean,
    name: string,
    id?: string,
    products: any[]
}

const productLineTemplate = {
    active: true,
    name: '',
    products: []
};

type productLineInputProps = {
    onChange: Function,
    label: string,
    value?: string,
    propIdentifier: string,
    required?: boolean
}

const ProductLineInput = (props:productLineInputProps) => {
    const {onChange, label, value, propIdentifier, required} = props;
    const translate = useFormatMessage();
    return <TextField
        fullWidth
        InputLabelProps={{
            shrink: true,
        }}
        value={value}
        onChange={(e) => onChange(propIdentifier, e.target.value)}
        label={translate({id: label})}
        type="text"
        required={required}
    />
};

const ProductLine = ({history}) => {
    const translate = useFormatMessage();
    const cancelToken = useRef(null);

    const productLineAPIGet = useApi('get', productLineTemplate, {errMsg: 'productLine.err'});
    const productLineAPIPost = useApi('post', null, {errMsg: 'productLine.saveErr', succMsg: 'productLine.succ', failureMsg: 'productLine.duplicate'});
    const productLineAPIPut = useApi('put', null, {errMsg: 'productLine.saveErr', succMsg: 'productLine.succ', failureMsg: 'productLine.duplicate'});

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [currentProductLine, setCurrentProductLine] = useState<UIProductLineModel>(productLineTemplate);
    const [products, setProducts] = useState<filteredProductsResponseModel[]>([]);
    const [filters, setFilters] = useState(undefined);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productsData, setProductsData] = useState(null);
    const productsQuery = useProductsQuery(filters, cancelToken.current, null, userProfile.countryMarket.market);

    useEffect(() => {
        const id = getId();
        if (id) {
            productLineAPIGet.call(API_PRODUCT_DATA_PRODUCT_LINE(id));
        }
        return () => {
            productLineAPIGet.clearToken();
            productLineAPIPost.clearToken();
            productLineAPIPut.clearToken();
        }
    }, []);

    useEffect(() => {
        if (productsData) {
            let newProducts = productsData.map((product) => ({...product, selectable: isSelectable(product)}));
            setProducts(newProducts);
        }
    },[productsData]);

    useEffect(() => {
        if (productLineAPIGet.data) {
            let newProductLineProducts = productLineAPIGet.data.products.map((product) => ({...product, productLineName: productLineAPIGet.data.name}));
            setCurrentProductLine({...productLineAPIGet.data , products: newProductLineProducts});
        }
    }, [productLineAPIGet.data]);

    useEffect(() => {
        if (productLineAPIPost.status === API_STATUSES.IDLE) {
            history.push(paths.productLineList);
        }
    }, [productLineAPIPost.status]);

    useEffect(() => {
        if (productLineAPIPut.status === API_STATUSES.IDLE) {
            history.push(paths.productLineList);
        }
    }, [productLineAPIPut.status]);

    useEffect(() => {
        let newProducts = currentProductLine.products.map((product) => ({...product, selectable: true}));
        setSelectedProducts(newProducts);
    },[currentProductLine]);

    useEffect(() => {
        if (productsQuery.isSuccess) {
            setProductsData(productsQuery.data);
        }
    }, [productsQuery.data]);

    useEffect(() => {
        if (filters !== undefined) productsQuery.refetch();
    },[filters])

    const handleChange = (key, value) => {
        const newProductLine = {...currentProductLine};
        newProductLine[key] = value;
        setCurrentProductLine(newProductLine);
    };

    const handleFiltersChange = (filters) => {
        setFilters(filters);
    };

    const handleSelectionChange = (products) => {
        const newProductLine = {...currentProductLine};
        newProductLine["products"] = products;
        setCurrentProductLine(newProductLine);
    };

    const handleSaveClick = () => {
        const {name, active, products} = currentProductLine;
        const payload: productLinePayloadModel = {name, active, products: products.map((a) => a.id)};
        if (currentProductLine.id) {
            productLineAPIPut.call(API_PRODUCT_DATA_PRODUCT_LINE(currentProductLine.id), payload);
        } else {
            productLineAPIPost.call(API_PRODUCT_DATA_PRODUCT_LINES, payload);
        }
    }

    const isSelectable = (product) => {
        if (product.productLineName !== undefined && product.productLineName !== currentProductLine.name){
            return false;
        }
        return true;
    }

    const showLoading = () => productLineAPIGet.status === API_STATUSES.PENDING || productLineAPIPost.status === API_STATUSES.PENDING || productLineAPIPut.status === API_STATUSES.PENDING;

    const canSave = () => currentProductLine.name;

    const upperTableProps = {
        defaultColDef: {
            cellClass: (params) => (params.data.productLineName !== currentProductLine.name && params.data.productLineName) ? '_disableTableRow' : null,
        }
    };

    return (
        <div className="viewRoot productLineRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX className="_fullWidth">
                        <div className="_formRowDouble">
                            <ProductLineInput onChange={handleChange} value={currentProductLine.name} label="productLine.name" propIdentifier="name" required/>
                            <div className="activePosition">
                                <ActiveCheckbox tabIndex={-1} onChange={handleChange} value={currentProductLine.active}/>
                            </div>
                        </div>
                    </PaperX>
                    <div className="_directionRow">
                        <PaperX className="_fullHeight">
                            <div className="productsFiltersContainer">
                                <ProductFilter onFiltersChange={handleFiltersChange} filterOptions={["brand","description","capacity","capacityUnit","contentOfTradingUnits","contentOfTradingUnitsUnit","variety"]} activeBox={true} userSettings={null}/>
                            </div>
                            <LoadingOverlay show={productsQuery.isLoading}/>
                        </PaperX>
                        <ProductsSelector upperTableProps={upperTableProps} products={products} selectedProducts={selectedProducts} onSelectionChange={handleSelectionChange}/>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        <Link to={paths.productLineList}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default ProductLine;
import {advertisementsOverviewRow} from 'shared/models/advertisement.model';
import {PriceDisplay} from 'components/Displays';

interface priceCellProps {
    data: advertisementsOverviewRow
}

const PriceCell = (props: priceCellProps) => {
    const {mpu, absoluteDiscount, currency, promotionalPrice, regularPrice, relativeDiscount} = props.data;
    return <PriceDisplay absoluteDiscount={absoluteDiscount}
                         currency={currency}
                         mpu={mpu}
                         promotionalPrice={promotionalPrice}
                         regularPrice={regularPrice}
                         relativeDiscount={relativeDiscount}/>;
}

export default PriceCell;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Checkbox} from '@mui/material';
import {fullAttributePayloadModel} from 'shared/models/attribute.model';
import {dictionary, dictionaryWithElements} from 'shared/models/dictionaries.model';
import {literalObject} from 'shared/models/literal.model';
import {IRootState} from 'shared/reducers';
import SummaryCard from 'components/Cards/SummaryCard';
import {attributeSummaryCardRow, attrTypeLabels, attrTypes} from './Attribute';

interface AttributeSummaryProps {
    attr: fullAttributePayloadModel,
    dictionaries: (dictionaryWithElements | dictionary)[],
    literals: literalObject[],
    onActiveClick: () => void
}

const AttributeSummary = (props: AttributeSummaryProps) => {
    const {attr, dictionaries, literals, onActiveClick} = props;

    const lang = useSelector((state: IRootState) => state.userProfile.langData);
    const [cardContent, setCardContent] = useState<attributeSummaryCardRow[]>([]);

    useEffect(() => {
        const content = [
            {label: 'attributes.name', value: attr.translations[lang]},
            {label: 'a.active', value: <Checkbox checked={attr.active} onChange={handleActiveChange}/>, optionalClass: 'checkbox'},
            {label: 'attributes.type', value: <FormattedMessage id={attrTypeLabels[attr.type]}/>}
        ];
        if (attr.type === attrTypes.ENUMERATION) {
            if(literals) {
                content.push({label: 'literals.label', value: props.literals.map((item) => item.translations[lang]).join(', ')})
            }
        }
        if (attr.type === attrTypes.NUMERICAL || attr.type === attrTypes.RANGE) {
            if (dictionaries) {
                content.push({label: 'attributes.selectedUnits', value: props.dictionaries.map((item) => item.translations[lang]).join(', ')})
            }
        }
        setCardContent(content);
    }, [attr]);

    const handleActiveChange = () => {
        if (onActiveClick) onActiveClick();
    };

    return (
        <div className="_halfWidth">
            <SummaryCard content={cardContent}/>
        </div>
    )
};

export default AttributeSummary;
/* eslint-disable react-hooks/exhaustive-deps */
import './Search.scss';
// inspired by bannerSelector

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Autocomplete, CircularProgress, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {API_STATUSES} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {genericSearchResponseModel} from 'shared/models/search.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import useDebounce from 'utils/debounce';

type ImpmSearchProps = {
    autoSelect?: boolean,
    onSelectionChange: (value: basic) => void,
    searchUrl: string,
    isDescriptionSearch?: boolean,
    customResults?: any,
    customLabel?: any,
    label?: string,
    limit?: number,
    minQueryLen?: number,
    data?: basic,
    required?: boolean,
    clearOnSelect?: boolean,
    autoFocus?: boolean,
    queryParams?: any
}

const defaultLimit: number = 100;

const Search = (props: ImpmSearchProps) => {
    const {clearOnSelect, onSelectionChange, searchUrl, required, isDescriptionSearch} = props;
    const autoSelectEnabled = props.autoSelect === false ? false : true;
    const label = props.label || 'a.search';
    const minQueryLen = props.minQueryLen || 2;
    const getDescriptions = (option) => option.mainDescriptions[0].description + (option.alternativeDescriptions[0] ? ' / ' + option.alternativeDescriptions[0].description : '');

    const listRenderer = (props, option) => {
        if (isDescriptionSearch) {
            return <li {...props} className="mpmSearchOption" key={option['id']}>
                <span>{getDescriptions(option)}</span>
            </li>
        }
        else {
            if (props.customResults) return props.customResults;
            else return <li {...props} className="mpmSearchOption" key={option['id']}><span>{option['name']}</span></li>;
        }
    }
    
    const labelRenderer = (option) => {
        if (isDescriptionSearch) return getDescriptions(option);
        if (props.customLabel) return props.customLabel;
        else return option.name;
    }

    const translate = useFormatMessage();
    const searchApiGet = useApi('get', [], {errMsg: 'a.error2'});

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [queryStr, setQueryStr] = useState<string>('');
    const [localResults, setLocalResults] = useState<genericSearchResponseModel>([]);
    const [dbncdQueryStr, setDbncdQueryStr] = useDebounce(queryStr, 500);

    const [dynamicKey, setDynamicKey] = useState<number>(0); //used to rerender input when clearOnSelect to actually clear selection
    const inputRef = useRef(null);

    useEffect(() => {
        if (props.data) {
            setLocalResults([props.data])
        }
    }, [props.data]);

    useEffect(() => {
        if (dbncdQueryStr.length >= minQueryLen) {
            if (isDescriptionSearch) searchApiGet.call(searchUrl, {params: {...props.queryParams, description: dbncdQueryStr, lang: selectedLanguage}});
            else searchUrl && searchApiGet.call(searchUrl, {params: {...props.queryParams, search: dbncdQueryStr, lang: selectedLanguage, limit: props.limit || defaultLimit}});
        }
    }, [dbncdQueryStr]);

    useEffect(() => {
        if (searchApiGet.status === API_STATUSES.IDLE) {
            setLocalResults(searchApiGet.data);
        } else {
            setLocalResults([]);
        }
    }, [searchApiGet.status]);

    useEffect(() => {
        if (dynamicKey > 0 && clearOnSelect) {
            inputRef.current.click();
        }
    }, [dynamicKey]);

    const handleAutocompleteChange = (value: basic | string) => {
        if (typeof value !== 'string') {
            onSelectionChange(value);
            if (clearOnSelect) {
                setDynamicKey(dynamicKey + 1);
                inputRef.current.click();
            }
        }
    };

    const handleSearchInputChange = (val: string) => {
        if (searchApiGet.status === API_STATUSES.PENDING) {
            searchApiGet.clearToken();
        }
        setQueryStr(val);
        setDbncdQueryStr(val);
    };

    return (<div className="mpmSearchRoot">
        <Autocomplete
            key={'a' + dynamicKey}
            className="searchInput"
            selectOnFocus
            clearOnBlur
            autoSelect={autoSelectEnabled}
            value={props.data}
            options={localResults}
            onChange={(e, value) => handleAutocompleteChange(value)}
            filterOptions={(option) => option}            
            getOptionLabel={(option) => labelRenderer(option)}
            renderOption={(props, option) => listRenderer(props, option)}
            renderInput={(params) => <TextField {...params}
                                                onChange={(e) => handleSearchInputChange(e.target.value)}
                                                label={translate({id: label || 'a.search'})}
                                                required={required}
                                                ref={inputRef}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    autoFocus: props.autoFocus || false,
                                                    startAdornment: (
                                                        <>
                                                            <InputAdornment position="start">
                                                                {searchApiGet.status === API_STATUSES.PENDING ? <CircularProgress size={24}/>: <SearchIcon/> }
                                                            </InputAdornment>
                                                            {params.InputProps.startAdornment}
                                                        </>
                                                    )
                                                }}
            />}
        />
    </div>)
};

export default Search;
/* eslint-disable react-hooks/exhaustive-deps */
import './Gtins.scss';

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_FETCH_EXTERNAL_GTIN_DATA} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {
    combinedGtin,
    externalGtin,
    externalGtinDataResponseModel,
    externalGtinDataPayloadModel,
    gtinObject
} from 'shared/models/gtin.model';
import {categorySelectorOption} from 'shared/models/category.model';
import {simpleDescription} from 'shared/models/description.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import GtinShortList from './GtinShortList';
import GtinsDetailsDialog from './GtinsDetailsDialog';
import AddGtinDialog from './AddGtinDialog';

interface gtinsProps {
    brand: basic,
    category: categorySelectorOption,
    description: simpleDescription[],
    gtins: gtinObject[],
    isDescribingFrame?: boolean,
    onActiveToggle: (gtin: string) => void,
    onAdd: (gtin: string) => void,
    onDelete: (gtin: string) => void
}

export type productInfoObject = {
    brand: string,
    category: string,
    description: string
}

enum externalGtinDataState {
    NEW = 'NEW',
    PENDING = 'PENDING',
    READY = 'READY',
    ERROR = 'ERROR',
}

const Gtins = (props: gtinsProps) => {
    const {brand, category, description, gtins, isDescribingFrame, onActiveToggle, onAdd, onDelete} = props;
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const selectedDataLang = useSelector((state: IRootState) => state.userProfile.langData);

    const [productInfo, setProductInfo] = useState<productInfoObject>(null);

    const [shortGtinList, setShortGtinList] = useState<combinedGtin[]>([]);
    const [fullGtinsList, setFullGtinsList] = useState<combinedGtin[]>([]);
    const [externalGtinData, setExternalGtinData] = useState<externalGtinDataResponseModel>([]);

    const [extDataState, setExtDataState] = useState<externalGtinDataState>(externalGtinDataState.NEW);

    const [gtinDetailsDialogOpen, setGtinDetailsDialogOpen] = useState<boolean>(false);
    const [addGtinDialogOpen, setAddGtinDialogOpen] = useState<boolean>(false);

    const cancelToken = useRef(null);

    const fetchExternalGtinData = () => {
        setExtDataState(externalGtinDataState.PENDING);
        const payload: externalGtinDataPayloadModel = gtins.map((item) => item.value);
        axios.post(API_FETCH_EXTERNAL_GTIN_DATA(countryMarket.preferredCountry, selectedDataLang), payload, {cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                setExtDataState(externalGtinDataState.READY);
                const externalGtins: externalGtinDataResponseModel = response.data;
                setExternalGtinData(externalGtins);
                setShortGtinList(buildShortGtinList(gtins, externalGtins));
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'product.gtinFetchErr'})}`, {variant: 'error', persist: false});
                    setExtDataState(externalGtinDataState.ERROR);
                    setShortGtinList(onFailed2getDetails(gtins));
                }
            })
    }

    useEffect(() => {
        return () => {
            if(cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if (gtins?.length && extDataState === externalGtinDataState.NEW) {
            setShortGtinList(buildShortGtinList(gtins, externalGtinData));
            fetchExternalGtinData();
        } else {
            setShortGtinList(buildShortGtinList(gtins, externalGtinData));
        }
    }, [gtins]);

    useEffect(() => {
        setProductInfo({
            brand: brand?.name,
            category: category?.name,
            description: description?.map((descriptionObj) => `${descriptionObj.description} (${descriptionObj.language}) `).join(", ")
        });
    }, [brand, category, description]);

    useEffect(() => {
        setFullGtinsList(buildFullGtinList(gtins, externalGtinData));
    }, [shortGtinList]);

    const handleGtinRemoval = (gtin: string) => {
        setExternalGtinData(externalGtinData.filter((item) => item.value !== gtin));
        onDelete(gtin);
        enqueueSnackbar(`${translate({id: 'product.gtinRemoved'})}`, {variant: 'success', persist: false});
    };

    const handleShortListRowClick = () => setGtinDetailsDialogOpen(true);

    const handleGtinAdd = (gtin: string, externalGtins: externalGtin[]) => {
        setExternalGtinData(externalGtinData.concat(externalGtins));
        onAdd(gtin);
        enqueueSnackbar(`${translate({id: 'product.gtinAdded'})}`, {variant: 'success', persist: false});
    }

    return (
        <div className="gtinsRoot">
            <LoadingOverlay show={extDataState === externalGtinDataState.PENDING}/>
            <Button variant="outlined" color="primary" onClick={() => setAddGtinDialogOpen(true)}>
                <FormattedMessage id="product.addGtin"/>
            </Button>
            <GtinShortList gtins={shortGtinList} onRowClick={handleShortListRowClick}/>
            <GtinsDetailsDialog gtins={fullGtinsList}
                                isDescribingFrame={isDescribingFrame}
                                onActiveToggle={(gtin) => onActiveToggle(gtin)}
                                open={gtinDetailsDialogOpen}
                                onClose={() => setGtinDetailsDialogOpen(false)}
                                onDelete={(gtin) => handleGtinRemoval(gtin)}
                                productInfo={productInfo}
            />
            <AddGtinDialog existingGtins={gtins}
                           isDescribingFrame={isDescribingFrame}
                           onAdd={(gtin, externalGtins) =>  handleGtinAdd(gtin, externalGtins)}
                           onClose={() => setAddGtinDialogOpen(false)}
                           open={addGtinDialogOpen}
                           productInfo={productInfo}

            />
        </div>
    )
};

const buildShortGtinList = (gtins: gtinObject[], externalGtins: externalGtin[]): combinedGtin[] => gtins
    .map((item) => {
        const extGtin: externalGtin = externalGtins.find((ext) => ext.value === item.value);
        return extGtin ? {...item, ...extGtin} : {...item, gtinFound: undefined};
    });

const buildFullGtinList = (gtins: gtinObject[], externalGtins: externalGtin[]): combinedGtin[] => {
    const fullList: combinedGtin[] = [];
    externalGtins.forEach((extGtin) => {
        const original = gtins.find((gtin) => gtin.value === extGtin.value);
        if (!original) {
            console.error('broken data: external gtins contain unknown gtin');
        } else {
            fullList.push({...extGtin, active: original.active});
        }
    });
    gtins.forEach((gtin) => {
        if (!fullList.find((item) => item.value === gtin.value)) {
            fullList.push({...gtin, gtinFound: false});
        }
    });
    return fullList;
}

const onFailed2getDetails = (gtins: gtinObject[]): combinedGtin[] => gtins.map((item) => ({...item, gtinFound: false}));

export default Gtins;
/* eslint-disable react-hooks/exhaustive-deps */
import './ProductsListFilterResults.scss';

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import axios from 'axios';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {RowClickedEvent} from 'ag-grid-community';
import {useSnackbar} from 'notistack';
import {API_GET_FILTERED_PRODUCTS} from 'config/api/constants';
import {fetchProductsAPI, filteredProductsResponseModel} from 'shared/models/product.model';
import {IRootState} from 'shared/reducers';
import {PaperX} from 'components/PaperX';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import ProductFilter, {saneFilters} from 'components/ProductFilter/ProductFilter';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {MarketSelector} from 'components/Selectors';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ProductPreview from 'components/gfx/ProductPreview/ProductPreview';
import ProductListLinkButton from './ProductListLinkButton';

const userSettingsKey: string = 'PRODUCTS_LIST_FILTERS';

interface productsListFilterResultsProps {
    onRowClick: (id:string) => void,
}

export const getFilteredProductsColDef = (translate): ColDef[] => [
    { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
    { field: 'actions', headerName: translate({ id: 'a.actions' }), flex: 1, cellRenderer: (params) =>
            <Button
                color="secondary"
                variant="outlined"
                size="small"
                endIcon={params.node.expanded ? <ArrowDropUp /> : <ArrowDropDown/>}
                onClick={() => {
                    params.node.setExpanded(!params.node.expanded);
                    params.api.refreshCells({rowNodes: [params.node], force: true});
                }}
            >
                {translate({id: params.node.expanded ? 'product.hideImages' : 'product.showImages'})}
            </Button>
    },
    { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
    { field: 'category', headerName: translate({id: 'b.category'}), flex: 1 },
    { field: 'typeOfPackage', headerName: translate({id: 'b.typeOfPackage'}), flex: 1 },
    { field: 'capacity', headerName: translate({id: 'b.capacity'}), flex: 1,
        valueGetter: (params) => {
            const from = params.data.capacityFrom;
            const to = params.data.capacityTo;
            if (from || to) {
                if (from === to) {
                    return from;
                }
                return `${from} - ${to}`;
            }
            return '';
        }
    },
    { field: 'capacityUnit', headerName: translate({id: 'b.capacityUnit'}), flex: 1 },
    { field: 'contentOfTradingUnits', headerName: translate({id: 'b.contentOfTradingUnit'}), flex: 1 },
    { field: 'contentOfTradingUnitsUnit', headerName: translate({id: 'b.contentOfTradingUnitUnit'}), flex: 1 },
    { field: 'variety', headerName: translate({id: 'b.variety'}), flex: 1 },
    { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1 },
    { field: 'productLineName', headerName: translate({id: 'b.productLine'}), flex: 1 },
    { field: 'supplierName', headerName: translate({id: 'b.supplier'}), flex: 1 },
    { field: 'relatedPromotionsCount', headerName: translate({id: 'product.relatedPromotions'}), flex: 1,
        cellRenderer: (params) => <ProductListLinkButton id={params.node.data.id} label={params.node.data.relatedPromotionsCount}/>
    },
    { field: 'active', headerName: translate({ id: 'a.active' }), width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), suppressMenu: true},
];

const ProductsListFilterResults = ({onRowClick}:productsListFilterResultsProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();
    const cancelToken = useRef(null);

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [filters, setFilters] = useState<saneFilters>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<filteredProductsResponseModel[]>([]);

    useEffect(() => {
        return () => {
            if (cancelToken.current) {
                cancelToken.current();
            }
        }
    }, []);

    const getProducts = () => {
        if (cancelToken.current) {
            cancelToken.current();
        }
        setIsLoading(true);
        const params: fetchProductsAPI = {
            ...filters,
            marketId: userProfile.countryMarket.market,
            lang: userProfile.langData
        }
        axios.get<filteredProductsResponseModel[]>(
            API_GET_FILTERED_PRODUCTS,
            {
                params: params,
                cancelToken: new axios.CancelToken(
                    cancel => (cancelToken.current = cancel)
                )
            })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setProducts(resp.data);
                } else {
                    enqueueSnackbar(translate({id: 'product.listErr'}), {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if (!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(translate({id: 'product.listErr'}), {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    }

    const handleFiltersChange = (filters: saneFilters) => setFilters(filters);

    useEffect(() => {
        if (filters !== undefined && userProfile.countryMarket.market) {
            getProducts();
        }
    },[filters, userProfile]);

    const handleRowClick = (e: RowClickedEvent) => {
        const htmlTargetEl = e.event.target as HTMLElement;
        if (htmlTargetEl.className.split(' ').includes('ag-cell')) onRowClick(e.data.id);
        else return;
    };

    const columnDefs = getFilteredProductsColDef(translate);

    return (
        <div className="viewContainer _directionRow productsListFiltersRoot">
            <LoadingOverlay show={isLoading}/>
            <PaperX className="filtersColumn">
                <MarketSelector/>
                <div className="_header">{translate({id: 'filters'})}</div>
                <div className="filtersContainer">
                    <ProductFilter onFiltersChange={handleFiltersChange} filterOptions={["brand","description","category","typeOfPackage","capacity","contentOfTradingUnits","variety","productLineName"]} activeBox userSettings={userSettingsKey}/>
                </div>
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _fullTable">
                <AgTable
                    rowData={products}
                    defaultColDef={{
                        resizable: true,
                        sortable: true
                    }}
                    columnDefs={columnDefs}
                    detailCellRenderer={(rowData) => <ProductPreview productId={rowData.data.id}/>}
                    onRowClicked={handleRowClick}
                    masterDetail={true}
                    localStorageColumnsKey={localStorageColumnsKeys.productsList}
                />
            </PaperX>
        </div>
    );
};

export default ProductsListFilterResults;
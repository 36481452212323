import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import {categoryType, categoryUIModel} from "../../../shared/models/category.model";
import {Button} from "@mui/material";
import {CategorySearch} from "modules/MasterData/ProductCategories/CategorySearch";
import {buildCategoryUItemplate, transform4UI} from "modules/MasterData/Category/categoryIO";


let z = {"id":"32b40c2c-6f8e-4b3c-8f24-ea276e3e97f9","translations":{"cs":"Osobní doplňky - sady","de":"Persönliche Accessoires - Sets","bg":"Лични аксесоари - комплект","sk":"Osobné doplnky - súpravy","en":"Personal Accessories Variety Packs","hr":"Osobni pribor - setovi","it":"Accessori - Confezioni Miste","fr":"Assortiments d’Accessoires Corporels","pl":"Akcesoria osobiste - zestawy","hu":"Több fajta személyes öltözék kiegészíto összecsomagolása","ro":"Accesorii personale - seturi","nl":"Persoonlijke Accessoires - Assortimenten"},"active":true,"parentCategoryId":"20826aeb-8439-40f6-993f-5f6760126773","type":categoryType.BRICK,"parentCategoryName":"test"};
const x = () => {
    console.log('zx');
    console.log(z);
    console.log(transform4UI(z))
    return transform4UI(z);
}

const TestView = () => {
  const [counter, setCounter] = useState(0);

  const storageState = {
    stringSample: null,
    intSample: null,
    floatSample: null,
    objectSample: null
  };

  storageState.stringSample = useSelector((state: IRootState) => state.storagePileState.stringSample);
  storageState.intSample = useSelector((state: IRootState) => state.storagePileState.intSample);
  storageState.floatSample = useSelector((state: IRootState) => state.storagePileState.floatSample);
  storageState.objectSample = useSelector((state: IRootState) => state.storagePileState.objectSample);

  useEffect(() => {
    setCounter(counter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageState.stringSample, storageState.intSample, storageState.floatSample, storageState.objectSample]);


  const [type, setType] = useState(categoryType.FAMILY);
  const [categoryOK, setCategoryOK] = useState(null);
  const [model, setModel] = useState<categoryUIModel>(buildCategoryUItemplate(categoryType.BRICK));
  const handleCategoryChangeClick = () => {
      if (type === categoryType.SEGMENT) {
          setType(categoryType.FAMILY)
      }
      if (type === categoryType.FAMILY) {
          setType(categoryType.CLASS)
      }
      if (type === categoryType.CLASS) {
          setType(categoryType.BRICK)
      }
      if (type === categoryType.BRICK) {
          setType(categoryType.SEGMENT)
      }
  };
  const handleCategoryChange2 = (category) => {
      console.log(category);
      setCategoryOK(category)
    }

    const loadBrick = () => {
      setModel(x())
    }
    const handleCategoryChange3 = (category) => {
      const newModel = {...model};
      newModel.parentCategory = category;
      setModel(newModel);
    }
  return (
      <div>
        <div>refresh counter: {counter}</div>
        <div>string value: {storageState.stringSample}</div>
        <div>int value: {storageState.intSample}</div>
        <div>float value: {storageState.floatSample}</div>
        <div>object value: {JSON.stringify(storageState.objectSample)}</div>
        <Button onClick={handleCategoryChangeClick}>change category type</Button> {type}
          <Button onClick={loadBrick}>load brick</Button>
        <CategorySearch onCategorySelect={handleCategoryChange2} value={categoryOK} categoryType={type}/>
          <div>
              {JSON.stringify(categoryOK)}
          </div>
          {model&&<CategorySearch onCategorySelect={handleCategoryChange3} value={model?.parentCategory} categoryType={model.parentCategory?.type}/>}
          <div>
              {JSON.stringify(model)}
          </div>
      </div>
  );
};

export default TestView;
import './GtinsDetailsDialog.scss';

import {FormattedMessage} from 'react-intl';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {combinedGtin} from 'shared/models/gtin.model';
import {useFormatMessage} from 'utils/translate';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import ButtonClose from 'components/Buttons/ButtonClose';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {PaperX} from 'components/PaperX';
import {FoundCellRenderer} from './GtinShortList';
import {productInfoObject} from './Gtins';
import ProductInfo from './ProductInfo';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';

export const getGtinsDialogClassName = (isDescribingFrame: boolean): string => {
    let className = 'gtinDetailsDialogRoot _dialog-likeView';
    if (isDescribingFrame) className += ' _dialogOnDescribeFrameView';
    return className;
}

interface gtinsDetailsDialogProps  {
    gtins: combinedGtin[],
    isDescribingFrame?: boolean,
    onActiveToggle: (gtin: string) => void,
    onClose: Function,
    onDelete: (gtin: string) => void,
    open: boolean,
    productInfo: productInfoObject
}

const GtinsDetailsDialog = (props: gtinsDetailsDialogProps) => {
    const {gtins, isDescribingFrame, onActiveToggle, onClose, onDelete, open, productInfo} = props;
    const translate = useFormatMessage();

    const colDef: ColDef[] = [
        {field: 'value', headerName: translate({ id: 'b.gtin' }), sort: 'asc'},
        {field: 'brand', headerName: translate({ id: 'b.brand' })},
        {field: 'description', headerName: translate({ id: 'b.descr' }), flex: 1},
        {field: 'supplier', headerName: translate({ id: 'b.supplier' })},
        {field: 'capacity', headerName: translate({ id: 'b.capacity'})},
        {field: 'gtinFound', headerName: translate({ id: 'product.gtinVerified'}),
            cellRenderer: (params) => <><FoundCellRenderer data={params.data}/>{params.data.verifiedBy}</>},
        {field: 'actions', headerName: translate({ id: 'a.actions' }),
            cellRenderer: (params) => <>
                    <ActiveCheckbox value={params.data.active} onChange={() => onActiveToggle(params.data.value)}/>
                    <Button onClick={() => onDelete(params.data.value)} variant="outlined" size="small">
                        <FormattedMessage id="a.remove"/>
                    </Button>
                </>},
    ];

    const getContextMenu = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
        if (!params.node || !params.node.data) return [];
        const copyThisGTIN: MenuItemDef = {
            name: translate({id: 'product.copyThisGtin'}),
            action: () => {
                const singleGtin: string = params.node.data.value;
                if (singleGtin) navigator.clipboard.writeText(singleGtin);
            },
            icon: '<span class="ag-icon ag-icon-copy"></span>'
        };
        const copyAllGTINs: MenuItemDef = {
            name: translate({id: 'product.copyAllGtins'}),
            action: () => {
                const allGtins: string[] = [];
                params.api.forEachNode(node => {
                    if (node.data.value) {
                        allGtins.push(node.data.value);
                    }
                });
                const allGtinsList = allGtins.join(', ');
                navigator.clipboard.writeText(allGtinsList);
            },
            icon: '<span class="ag-icon ag-icon-copy"></span>',
            disabled: gtins.length < 2
        };
        return [copyThisGTIN, copyAllGTINs];
    };

    return (
        <Dialog className={getGtinsDialogClassName(isDescribingFrame)} open={open} fullScreen>
            <DialogContent className="_directionCol">
                <div className="_halfWidth">
                    <ProductInfo productInfo={productInfo}/>
                </div>
                <PaperX className="_fullHeight _fullTable _scrollY">
                    <AgTable
                        defaultColDef={{
                            resizable: true,
                            sortable: true
                                       }}
                        rowData={gtins}
                        columnDefs={colDef}
                        suppressContextMenu={false}
                        getContextMenuItems={getContextMenu}
                        localStorageColumnsKey={localStorageColumnsKeys.gtinDetails1}
                    />
                </PaperX>
            </DialogContent>
            <DialogActions>
                {!isDescribingFrame && (
                    <DialogAuxiliaryActions>
                        <NavigationMenuButton/>
                    </DialogAuxiliaryActions>
                )}
                <ButtonClose onClick={onClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default GtinsDetailsDialog;
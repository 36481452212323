import './DeleteMediaDialog.scss';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from "@mui/material";
import {FormattedMessage} from "react-intl";
import {useState} from "react";

interface DeleteMediaDialogProps {
    fileName?: string,
    media?: boolean,
    onCancel: () => void,
    onConfirm: () => void,
    open: boolean,
}

const DeleteMediaDialog = ({media, open, onConfirm, onCancel, fileName}: DeleteMediaDialogProps) => {
    const handleCopy = (e) => {
        e.preventDefault();
    }
    const [deletionText, setDeletionText] = useState<string>('');
    const handleChange = (text: string) => setDeletionText(text);
    const canSave = (): boolean => deletionText === "DELETE";

    const promptText = media ? 'media.deletePrompt' : 'printMediaOverview.deleteConfirmation';

    const handleConfirmClick = () => {
        setDeletionText('');
        onConfirm();
    }

    const handleCancelClick = () => {
        setDeletionText('');
        onCancel();
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <FormattedMessage id="a.confirm"/>
            </DialogTitle>
            <Divider/>
            <DialogContent className="deleteMediaContentRoot">
                <div>
                    <FormattedMessage id={promptText}/>
                </div>
                {fileName && <div className="_bold">
                    {fileName}
                </div>}
                <div>
                    <FormattedMessage id="printMediaOverview.deleteInstruction"/>
                </div>
                <TextField
                    fullWidth
                    onCopy={handleCopy}
                    onCut={handleCopy}
                    onPaste={handleCopy}
                    value={deletionText}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" disabled={!canSave()} onClick={() => handleConfirmClick()}><FormattedMessage id="a.confirm"/></Button>
                <Button variant="outlined" color="secondary" onClick={() => handleCancelClick()}><FormattedMessage id="a.cancel"/></Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteMediaDialog;

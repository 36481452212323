/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {API_PRODUCT_SUGGESTIONS} from 'config/api/constants';
import {
    productSuggestionResponseModel
} from 'shared/models/frame.model';
import {productSearchResultUI} from 'shared/models/product.model';
import {useFormatMessage} from 'utils/translate';
import {getURLParam} from 'utils/routing';
import {responseValidation} from 'utils/responseValidation';
import ButtonClose from 'components/Buttons/ButtonClose';
import ProductSuggestion from './ProductSuggestion';

interface ProductSuggestionsProps {
    frameId: string,
    onClick: (product: productSearchResultUI) => void
}

const ProductSuggestions = (props: ProductSuggestionsProps) => {
    const {frameId, onClick} = props;
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    const [open, setOpen] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<productSuggestionResponseModel>([]);

    const cancelToken = useRef(null);

    const cancelQuery = () => {
        if (cancelToken.current) cancelToken.current();
    };

    const getProductSuggestions = (frameId: string) => {
        // to disable feature until BE ready in next story
        if (getURLParam('debug') === null) {
            return null;
        }
        cancelQuery();
        enqueueSnackbar(`${translate({id: 'frameDescription.fetchProdSug'})}`,{variant: 'info', persist: false});
        axios
            .get(API_PRODUCT_SUGGESTIONS(frameId), {
                cancelToken: new axios.CancelToken(
                    cancel => (cancelToken.current = cancel)
                )
            })
            .then((response) => {
                if (responseValidation(response.data) && response.data?.length) {
                    setSuggestions(response.data);
                    setOpen(true);
                } else {
                    enqueueSnackbar(`${translate({id: 'frameDescription.fetchProdSugNothing'})}`,{variant: 'info', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e)
                    enqueueSnackbar(`${translate({id: 'frameDescription.fetchProdSugErr'})}`,{variant: 'error', persist: false});
                }
            })
    };

    useEffect(() => {
        return cancelQuery();
    }, []);

    useEffect(() => {
        if (frameId) {
            getProductSuggestions(frameId);
        } else {
            cancelQuery();
        }
    }, [frameId]);

    const handleSuggestionClick = (product: productSearchResultUI) => {
        setOpen(false);
        onClick(product);
    }

    return <Dialog open={open} className="_dialogOnDescribeFrameView" fullScreen>
        <DialogTitle>
            <FormattedMessage id="frameDescription.fetchProdSugTitle"/>
        </DialogTitle>
        <DialogContent className="_directionRow">
            {suggestions
                .map((item) =>
                    <ProductSuggestion key={item.id}
                                       data={item}
                                       onClick={(product) => handleSuggestionClick(product)}
                    />
                )
            }
        </DialogContent>
        <Divider/>
        <DialogActions>
            <ButtonClose onClick={() => setOpen(false)}/>
        </DialogActions>
    </Dialog> ;
}

export default ProductSuggestions;
import {Button} from '@mui/material';
import {useFormatMessage} from 'utils/translate';

interface ButtonCloseProps {
    disabled?: boolean,
    onClick?: Function,
}

const ButtonClose = ({disabled, onClick}: ButtonCloseProps) => {
    const translate = useFormatMessage();
    const handleClick = () => onClick ? onClick() : null;
    return (<Button color="secondary" disabled={disabled} variant="outlined" onClick={handleClick}>{translate({id: 'a.close'})}</Button>);
};

export default ButtonClose;
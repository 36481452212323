import Layout from 'components/Layout';
import FaiLog from 'components/FaiLog/FaiLog';
import OptionsDialogButton from 'modules/Options/OptionsDialogButton';
import Navigation from './Navigation';

function Dashboard () {
  return (
      <Layout headerContent={[<OptionsDialogButton key="options"/>, <FaiLog key="log"/>]}>
        <Navigation/>
      </Layout>
  );
}

export default Dashboard;
import './StructuredBrandNewView.scss';
import {useState} from 'react';
import {History} from 'history';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_PRODUCT_DATA_STRUCTURED_BRAND_POST} from 'config/api/constants';
import {paths} from 'paths';
import {basic} from 'shared/models/_common.model';
import {
    arrayOfBrandIdStringsWithRedundantOrderNumber,
    structuredBrandPayload,
    structuredBrandResponse,
    structuredBrandUI
} from 'shared/models/brand.model';
import {useFormatMessage} from 'utils/translate';
import {POSTPUTAPICallParams} from './BrandNewView';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {ButtonClose} from 'components/Buttons';
import StructuredBrandDetails from './StructuredBrandDetails';

export const makeArrayOfBrandsWithRedundantOrderNumberAndMainBrandIsFirst = (mainBrand: basic, secondaryBrands: basic[]): arrayOfBrandIdStringsWithRedundantOrderNumber[] => {
    const regularArray: basic[] = [mainBrand].concat(secondaryBrands);
    return regularArray.map((item, index) => ({brandId: item.id, orderNumber: index}));
}

interface structuredBrandNewViewProps {
    history: History,
    isDescribingFrame?: boolean,
    onClose?: () => void,
    onSelect?: (brand: basic) => void
}

const StructuredBrandNewView = ({history, isDescribingFrame, onClose, onSelect}: structuredBrandNewViewProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [brand, setBrand] = useState<structuredBrandUI>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSaveClick = () => {
        setIsLoading(true);
        const payload: structuredBrandPayload = {
            active: brand.active,
            brands: makeArrayOfBrandsWithRedundantOrderNumberAndMainBrandIsFirst(brand.mainBrand, brand.secondaryBrands),
            supplierIds: brand.suppliers.map((item) => item.id)
        };
        const params: POSTPUTAPICallParams = {
            marketId: brand.marketId,
            ...(isDescribingFrame ? { reUse: true } : {})
        };

        axios.post(API_PRODUCT_DATA_STRUCTURED_BRAND_POST, payload, {
            params
        })
            .then((resp) => {
                !isDescribingFrame ? history.push(`${paths.brands}?reload=true`) : selectAndClose(resp.data);
            })
            .catch((e) => {
                console.log(e);
                if (e?.response?.status === 409) {
                    enqueueSnackbar(`${translate({id: 'brand.duplicate'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleBrandChange = (value: structuredBrandUI) => setBrand(value);

    const selectAndClose = (structuredBrand: structuredBrandResponse) => {
        const {id, name} = structuredBrand;
        onSelect({id, name});
    };

    const canSave = () => {
        if (!brand || brand.mainBrand === null || brand.secondaryBrands.length === 0) {
            return false;
        }
        return true;
    };

    return (
        <div className={`viewRoot structuredBrandNewViewRoot ${isDescribingFrame ? 'onDialogMode' : ''}`}>
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={isLoading}/>
                    <StructuredBrandDetails history={history} onBrandChange={(brand: structuredBrandUI) => handleBrandChange(brand)}/>
                </div>
            </div>
            <Footer
                hideNavigationMenu={isDescribingFrame}
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        <ButtonClose onClick={() => isDescribingFrame ? onClose() : history.push(paths.brands)}/>
                    </>
                }
            />
        </div>
    );
};

export default StructuredBrandNewView;
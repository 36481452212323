/* eslint-disable react-hooks/exhaustive-deps */
import './BannerSelector.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { API_STATUSES } from 'config/api/constants';
import { IRootState } from 'shared/reducers';
import {bannerWithRetailer} from 'shared/models/banner.model';
import { fetchBannersWithRetailers } from 'shared/reducers/bannersWithRetailer';
import { useFormatMessage } from 'utils/translate';

type BannerSelectorProps = {
    bannerId?: string,
    countryId?: string,
    onBannerChange?: (bannerId: bannerWithRetailer) => void
};

export const BannerSelector = ({ bannerId, countryId, onBannerChange }: BannerSelectorProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const bannersStatus = useSelector((state: IRootState) => state.bannersWithRetailer.status);
    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);

    const [filteredBanners, setFilteredBanners] = useState<bannerWithRetailer[]>([]);

    const [selectedBanner, setSelectedBanner] = useState<bannerWithRetailer>(null);

    useEffect(() => {
        if (bannersStatus === API_STATUSES.ERROR) {
            dispatch(fetchBannersWithRetailers());
        }
    }, []);

    useEffect(() => {
        if (bannersStatus === API_STATUSES.NEW) {
            dispatch(fetchBannersWithRetailers());
        }
    }, [bannersStatus]);

    useEffect(() => {
        const foundBanner: bannerWithRetailer = bannersData.find((item) => item.bannerId === bannerId);
        if (foundBanner) {
          setSelectedBanner(foundBanner);
        }
    },[bannersData, bannerId]);

    useEffect(() => {
        setFilteredBanners(bannersData.filter((item) => item.countryId === countryId));
    },[bannersData, countryId]);

    const filterBanners = (options: bannerWithRetailer[], { inputValue }): bannerWithRetailer[] => {
        const loCased: string = inputValue ? inputValue.toLowerCase() : '';
        return options.filter((item) => item.bannerName.toLowerCase().indexOf(loCased) > -1 || item.retailerName.toLowerCase().indexOf(loCased) > -1);
    }

    const handleAutocompleteChange = (value: bannerWithRetailer | string) => {
        if (typeof value !== 'string') {
            setSelectedBanner(value);
            onBannerChange(value);
        }
    };

    return (
        <div className="bannerSelectorRoot">
          <Autocomplete
              className="bannerSelectorInput"
              options={filteredBanners}
              filterOptions={filterBanners}
              onChange={(e, value) => handleAutocompleteChange(value)}
              isOptionEqualToValue={(option, value) => option.bannerId === value.bannerId}
              getOptionLabel={(option) => `${option.bannerName}       -       ${option.retailerName} (${option.countryId})`}
              renderOption={(props, option) => <li {...props} className={selectedBanner && option.bannerId === selectedBanner.bannerId ? "bannerSelectorOption selected" : "bannerSelectorOption"} key={option['bannerId']}><span className="bannerName">{option['bannerName']}</span> <span>{option['retailerName']} ({option['countryId']})</span></li>}
              value={selectedBanner}
              defaultValue={filteredBanners.find((item) => item.bannerId === bannerId)}
              renderInput={(params) => <TextField {...params} label={translate({id: 'b.banner'})} />}
          />
            {bannersStatus === API_STATUSES.PENDING && <div className="bannerSelectorInput loading"><span>loading</span></div>}
        </div>
    );
};

export default BannerSelector;
/* eslint-disable react-hooks/exhaustive-deps */
import './GiveAwayBundlingDrawer.scss';
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import {Drawer} from '@mui/material';
import {apiGetPromotionsForFrameResponsePromotionTypeProductType} from 'shared/models/frame.model';
import {promotionUI} from 'shared/models/promotion.model';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ProductSearch from 'modules/Advertisement/FrameDescription/ProductSearch/ProductSearch';
import ProductDrawerGiveAwayBundling from 'modules/Advertisement/FrameDescription/ProductDrawer/ProductDrawerGiveAwayBundling';

interface GiveAwayBundlingDrawerProps {
    countryId: string,
    data: promotionUI,
    open: boolean,
    onClose: () => void,
    onUpdatePromotion: (key: string,
                        value: apiGetPromotionsForFrameResponsePromotionTypeProductType[],
                        UIId: string) => void
}

const GiveAwayBundlingDrawer = (props: GiveAwayBundlingDrawerProps) => {
    const { countryId, data, open, onClose, onUpdatePromotion} = props;
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
    const [isProductOpen, setIsProductOpen] = useState<boolean>(false);
    const [productId, setProductId] = useState<string>(null);

    useEffect(() => {
        if (open) setIsSearchOpen(true);
        else setIsSearchOpen(false);
    }, [open]);

    const handleProductSelect = (selectedProduct: apiGetPromotionsForFrameResponsePromotionTypeProductType) => {
        if (!selectedProduct.isTemplate) {
            const newPromotions: apiGetPromotionsForFrameResponsePromotionTypeProductType[] = [...data?.giveAwayBundledProducts];
            newPromotions.push(selectedProduct);
            onUpdatePromotion('giveAwayBundledProducts', newPromotions, data.UIId);
            enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
        } else {
            setProductId(selectedProduct.id);
            setIsSearchOpen(false);
            setIsProductOpen(true);
        }
    };

    const handleCloseProduct = () => {
        setProductId(null);
        setIsSearchOpen(true);
        setIsProductOpen(false);
    };

    return (
        <>
            <Drawer className="giveAwayBundlingDrawerRoot" open={isSearchOpen} anchor="right">
                <div className="viewContainer _directionCol">
                    <PaperX className="_fullWidth weldBottom">
                        <div className="_header">
                            <FormattedMessage id="productSearch.giveAwayBundling"/>
                        </div>
                    </PaperX>
                    <PaperX className="weldTop">
                        <ProductSearch countryId={countryId}
                                       onProductClick={handleProductSelect}
                                       selectedItemsIds={data.giveAwayBundledProductIds || data.giveAwayBundledProducts?.map((item) => item.id) || []}
                        />
                    </PaperX>
                </div>
                <Footer
                    hideNavigationMenu
                    actionsRight={
                        <ButtonClose onClick={onClose}/>
                    }/>
            </Drawer>
            <ProductDrawerGiveAwayBundling
                open={isProductOpen}
                data={data}
                productId={productId}
                onClose={handleCloseProduct}
                onUpdatePromotion={onUpdatePromotion}
            />
        </>
    );
};

export default GiveAwayBundlingDrawer;
export const handyAttributes = {
    capacity: '0bb6c41f-1f86-4e6a-97e6-b2be0acc6af2',
    contentOfTradingUnit: '27984db4-e6cc-44dc-ae38-4efabbfdcd47',
    description: 'f9f47462-b668-482c-b1ae-9ca2563172ba',
    leafletType: 'bd9eabd4-737a-4b3d-873a-0e8298122b25',
    promotionType: 'b5521f0b-cc68-4446-b42f-9a72c128ab43',
    qualitySeal: 'bedae7d4-7d3f-4a5f-9c96-ae7ffe47005b',
    themeSeason: '1c70744c-3b47-434b-b9c9-31c3df6fea65',
    countryThemedWeeks: '2c89bec9-ddb1-48f3-9341-60e48906f0e5',
    typeOfPackage: 'e5688ba3-ab06-47ae-bc20-93de363d3488',
    variety: '3acf46d7-529f-4c97-85fb-e13fbe54ea2a'
};

export const hardcodedPercent: string = '%';
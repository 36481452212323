import './ResolveIssueDialog.scss';

import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import {API_FIX_REPORTED_PRODUCT} from 'config/api/constants';
import {LoadingOverlay} from 'components/LoadingOverlay';
import { ProductComment } from 'shared/models/product.model';

interface ResolveIssueDialogProps {
    comments: ProductComment[],
    onClose: () => void,
    onResolveSuccess: () => void,
    open: boolean,
    productId: string
}

const ResolveIssueDialog = ({comments, onClose, onResolveSuccess, productId, open}: ResolveIssueDialogProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const [isResolvePending, setIsResolvePending] = useState<boolean>(false);

    const resolve = () => {
        setIsResolvePending(true);
        axios.patch(API_FIX_REPORTED_PRODUCT(productId))
            .then(() => {
                enqueueSnackbar(`${translate({id: 'product.issuesResolved'})}`, {variant: 'success', persist: false});
                onResolveSuccess();
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsResolvePending(false));
    };

    return (
        <>
            <LoadingOverlay show={isResolvePending}/>
            <Dialog className="resolveIssueDialogRoot" open={open} maxWidth={'sm'} fullWidth>
                <DialogTitle>
                    <FormattedMessage id="product.fixReported"/>?
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className="_formRow">
                        <FormattedMessage id="nav.qualityBrowse"/>:
                    </div>
                    <ul>
                        {comments?.map((comment, idx) => (
                            <li key={idx}>
                                {comment.text}<br />
                                {comment.author ? <><FormattedMessage id="b.reportedBy" />: {comment.author}</> : null}
                            </li>
                        ))}
                    </ul>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={resolve} variant="contained">
                        <FormattedMessage id="a.confirm"/>
                    </Button>
                    <ButtonClose onClick={onClose} />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ResolveIssueDialog;
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl, InputLabel, MenuItem, Select,
    TextField
} from '@mui/material';
import {reportedFrameIssueType} from 'shared/models/frame.model';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import {useReportFrame, useRemarkAdvertisement} from './frameDescriptionAPI';

export enum reportIssuesType {
    'frame' = 'frame',
    'advertisement' = 'advertisement',
    'product' = 'product'
}

interface frameDescriptionReportDialogProps {
    frameId: string,
    open: boolean,
    onClose: () => void,
    onSuccess: () => void,
    reportType: reportIssuesType
}

const FrameDescriptionReportDialog = (props: frameDescriptionReportDialogProps) => {
    const translate = useFormatMessage();
    const {frameId, onClose, onSuccess, open, reportType} = props;

    const [reason, setReason] = useState<string>('');
    const [frameIssueType, setFrameIssueType] = useState<reportedFrameIssueType>(reportedFrameIssueType.FRAMING_ISSUE);

    const postSubmit = () => {
        setReason('');
        onSuccess();
    };

    const isReportingFrame = (): boolean => reportType === reportIssuesType.frame; // otherwise means its report advertisement dialog....

    const reportFrameApi = useReportFrame(frameId, frameIssueType, reason, postSubmit);
    const remarkAdvertisementApi = useRemarkAdvertisement(frameId, reason, postSubmit);

    const handleSubmit = () => isReportingFrame() ? reportFrameApi.mutate() : remarkAdvertisementApi.mutate();

    const canSubmit = () => reason.length > 5 && frameId;

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <FormattedMessage id={isReportingFrame() ? "frameDescription.report" : "frameDescription.reportAdvertisement"}/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                { isReportingFrame() && <div className="_formRow">
                    <FormControl fullWidth>
                        <InputLabel color="primary">
                            <FormattedMessage id="a.type"/>
                        </InputLabel>
                        <Select color="primary"
                                label={translate({id: 'a.type'})}
                                size="medium"
                                defaultValue={reportedFrameIssueType.FRAMING_ISSUE}
                                value={frameIssueType}
                                onChange={(e) => setFrameIssueType(e.target.value as reportedFrameIssueType)}
                        >
                            <MenuItem key='FRAMING_ISSUE' value={reportedFrameIssueType.FRAMING_ISSUE}>
                                <FormattedMessage id="frameDescription.reportReason1"/>
                            </MenuItem>
                            <MenuItem key='UNABLE_TO_DESCRIBE' value={reportedFrameIssueType.UNABLE_TO_DESCRIBE}>
                                <FormattedMessage id="frameDescription.reportReason2"/>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>}
                <TextField autoFocus
                           fullWidth
                           label={translate({id: 'a.reason'})}
                           minRows={4}
                           multiline
                           onChange={(e) => setReason(e.target.value)}
                           value={reason}
                />
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button onClick={handleSubmit} variant={"contained"} disabled={!canSubmit()}>
                    <FormattedMessage id="a.submit"/>
                </Button>
                <ButtonClose onClick={onClose} />
            </DialogActions>
        </Dialog>
    );
};

export default FrameDescriptionReportDialog;
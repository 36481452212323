/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useFormatMessage } from 'utils/translate';
import { FormattedMessage } from 'react-intl';
import {Tooltip} from '@mui/material';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { useApi } from 'utils/axiosHooks/axiosHooks';
import { API_GET_LEAFLET_PROGRESS, API_STATUSES } from 'config/api/constants';
import { leafletProgress } from 'shared/models/leaflet.model';

interface leafletProgressProps {
    leafletId: string,
    leafletFileName?: string,
    isApiErr?: boolean,
    isDialogMode?: boolean,
    shouldUpdate?: number,
    onClose?: () => void,
    onReportedFramesChange?: (value: boolean) => void
}

export const progressLeafletTemplate: leafletProgress = {
    currentUsersCount: 0,
    currentUsers: [],
    describedFrames: 0,
    reportedFrames: 0,
    reportedPages: 0,
    totalFrames: 0,
};

const LeafletProgress = (props:leafletProgressProps) => {
    const translate = useFormatMessage();
    const {leafletId, leafletFileName, isApiErr, isDialogMode, shouldUpdate, onReportedFramesChange} = props;
    const [data, setData] = useState<leafletProgress>(progressLeafletTemplate);
    const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

    const progressDataApiGet = useApi('get', {errMsg: 'a.error2'});

    const handleReportedFramesChange = () => onReportedFramesChange && (data.reportedFrames > 0 || data.reportedPages > 0) ? onReportedFramesChange(true) : onReportedFramesChange(false);

    useEffect(() => {
        isDialogMode && handleReportedFramesChange();
    }, [data]);

    useEffect(() => {
        leafletId && progressDataApiGet.call(API_GET_LEAFLET_PROGRESS(leafletId));
    }, [leafletId, shouldUpdate])

    useEffect(() => {
        setData(progressDataApiGet.data);
    }, [progressDataApiGet.data])

    useEffect(() => {
        if (progressDataApiGet.status === API_STATUSES.ERROR) setIsDataFetched(false);
        else if (progressDataApiGet.status === API_STATUSES.IDLE) setIsDataFetched(true);
    }, [progressDataApiGet.status])

    return (
        <div className="leafletProgressRoot">
            <LoadingOverlay show={progressDataApiGet.status === API_STATUSES.PENDING}/>
            <p className="leafletProgressTitle">{leafletFileName}</p>
            {(isDataFetched && !isApiErr) 
            ?
            <ul className="leafletProgressList">
                <li>{translate({id: 'printMediaOverview.totalFrames'})}: <span className="_bold">{data.totalFrames}</span></li>
                <li>{translate({id: 'printMediaOverview.describedFrames'})}: <span className="_bold">{data.describedFrames}</span></li>
                <li>{translate({id: 'printMediaOverview.reportedFrames'})}: <span className="_bold">{data.reportedFrames}</span></li>
                {isDialogMode && <li>{translate({id: 'printMediaOverview.reportedPages'})}: <span className="_bold">{data.reportedPages}</span></li>}
                <li><span>&nbsp;</span></li>
                {data.currentUsers.length
                ?
                    <Tooltip placement='bottom-end' title={<ul>{data.currentUsers.map((name, i) => <li key={i}>{name}</li>)}</ul>}>
                        <li className="currentUsersList">
                            <FormattedMessage id="printMediaOverview.currentUsers"/>: <span className="_bold">{data.currentUsersCount}</span>
                        </li>
                    </Tooltip>
                 :
                    <li>
                        <FormattedMessage id="printMediaOverview.currentUsers"/>: <span className="_bold">{data.currentUsersCount}</span>
                    </li>
                }
            </ul>
            :
            <FormattedMessage id="printMediaOverview.noProgressData"/>
            }
        </div>
    );
};

export default LeafletProgress;
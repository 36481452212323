/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import { Link } from 'react-router-dom';
import {GetContextMenuItemsParams, GridOptions, MenuItemDef} from 'ag-grid-community';
import {Button, TextField} from '@mui/material';
import {paths} from 'paths';
import {API_ADVERTISING_AREAS, API_GET_ADVERTISING_AREA, API_STATUSES} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {bannerWithRetailer} from 'shared/models/banner.model';
import {
    advertisingArea,
    advertisingAreaPayloadModel,
    advertisingAreaUIModel
} from 'shared/models/advertisingArea.model';
import {branch} from 'shared/models/branch.model';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import {CountryBannerSelector, BranchesSelector} from 'components/Selectors';
import {PaperX} from 'components/PaperX';
import {defaultColCfgWithSearch} from 'components/AgTable/AgTable';
import {getBranchesWithRepresentative} from 'modules/MasterData/AdvertisingArea/AdevrtisingAreaBranchesDecorator';
import RepresentativeBranch from './RepresentativeBranch';

const areaTemplate: advertisingAreaUIModel = {
    bannerId: '',
    branchIds: [],
    id: null,
    name: ''
};

const AdvertisingArea = (props) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();
    const areasAPIGet = useApi('get', areaTemplate, {errMsg: 'advertisingArea.err'});
    const areasAPIPost = useApi('post', areaTemplate, {errMsg: 'advertisingArea.saveErr', succMsg: 'advertisingArea.succ'});
    const areasAPIPut = useApi('put', areaTemplate, {errMsg: 'advertisingArea.saveErr', succMsg: 'advertisingArea.succ', failureMsg: 'advertisingArea.invalidRepresentative'});

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const [currentArea, setCurrentArea] = useState<advertisingAreaUIModel>(areaTemplate);
    const [country, setCountry] = useState<string>(countryMarket.preferredCountry);
    const [bannerId, setBannerId] = useState<string>('');

    const [branchesWithRep, setBranchesWithRep] = useState<branch[]>([]);
    const [waiting4Branches, setWaiting4Branches] = useState<boolean>(false);

    const [representativeBranchId, setRepresentativeBranchId] = useState<string>('');

    useEffect(() => {
        const areaId: string = getId();
        const bannerId: string = getURLParam('bannerId');
        if (bannerId) {
            setBannerId(bannerId);
        } else if (areaId) {
            areasAPIGet.call(API_GET_ADVERTISING_AREA(areaId))
        }
    }, []);

    useEffect(() => {
        if (bannerId !== currentArea.bannerId) {
            // cleanup selected branches
            setCurrentArea({...currentArea, bannerId, branchIds: []});
        } else {
            setCurrentArea({...currentArea, bannerId});
        }
        if (bannerId) {
            setWaiting4Branches(true);
            getBranchesWithRepresentative(bannerId)
                .then((response) => {
                    setBranchesWithRep(response);
                })
                .catch((e) => console.error(e))
                .finally(() => setWaiting4Branches(false));
        }
    }, [bannerId]);

    useEffect(() => {
        if (areasAPIGet.status === API_STATUSES.IDLE) {
            const area: advertisingArea = areasAPIGet.data;
            setCurrentArea(area);
            setBannerId(area.bannerId);
            setRepresentativeBranchId(area.representativeBranchId);
        }
    }, [areasAPIGet.status]);

    useEffect(() => {
        if (areasAPIPost.status === API_STATUSES.IDLE || areasAPIPut.status === API_STATUSES.IDLE) {
            props.history.push(`${paths.advertisingAreasList}?id=${bannerId}`);
        }
    }, [areasAPIPost.status, areasAPIPut.status]);

    const handleSaveClick = () => {
        const {bannerId, name, branchIds} = currentArea;
        const payload:advertisingAreaPayloadModel = {bannerId, name, branchIds, representativeBranchId};
        if (currentArea.id) {
            areasAPIPut.call(`${API_ADVERTISING_AREAS}/${currentArea.id}`, payload);
        } else {
            areasAPIPost.call(API_ADVERTISING_AREAS, payload);
        }
    };

    const handleBannerChange = (banner: bannerWithRetailer) => {
        setBannerId(banner?.bannerId);
        setRepresentativeBranchId('');
    };

    const handleCountryChange = (value:string) => {
        setCountry(value);
        dispatch(setPreferredCountry(value));
    };

    const handleNameChange = (name: string) => setCurrentArea({...currentArea, name});

    const handleSelectionChange = (branchIds: string[]) => {
        const isRepresenetativeAreaStillSelected = branchIds.find((branchId) => branchId === representativeBranchId);
        if (isRepresenetativeAreaStillSelected === undefined) {
            setRepresentativeBranchId('');
        }
        setCurrentArea({...currentArea, branchIds});
    };

    const cantSave = (): boolean => !currentArea.bannerId || !currentArea.name || currentArea.branchIds.length === 0 || representativeBranchId === '';

    const showLoading = (): boolean => waiting4Branches || areasAPIGet.status === API_STATUSES.PENDING || areasAPIPost.status === API_STATUSES.PENDING || areasAPIPut.status === API_STATUSES.PENDING;

    const getContextMenu = (rowNode: GetContextMenuItemsParams): MenuItemDef[] => rowNode.node.data.isRep ? [{name: translate({id: 'advertisingArea.cantSetAsRep'})}] :
        [
            {
                name: translate({id: 'advertisingArea.setAsRep'}),
                action: () => setRepresentativeBranchId(rowNode.node.data.id)
            }
        ];

    const handleClearRepresentativeBranch = () => setRepresentativeBranchId('');

    const leftTableProps: GridOptions = {defaultColDef: {...defaultColCfgWithSearch, cellClass: (params) => params.data.isRep ? '_highlightedTableRow': null}};
    const rightTableProps: GridOptions = {
        defaultColDef: {
            ...defaultColCfgWithSearch,
            cellClass: (params) => params.data.id === representativeBranchId ? '_highlightedTableRow': null,
            tooltipValueGetter: () => translate({id: 'a.right4context'})
        },
        tooltipShowDelay: 500,
        suppressContextMenu: false,
        getContextMenuItems: (rowNode) => getContextMenu(rowNode)
    };

    return (
        <div className="viewRoot advertisingAreaRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX className="inputsContainer">
                        <div className="_formRowDouble">
                            <CountryBannerSelector country={country}
                                                   bannerId={currentArea.bannerId}
                                                   onCountryChange={handleCountryChange}
                                                   onBannerChange={handleBannerChange}
                            />
                        </div>
                        <div className="_formRowDouble">
                            <TextField
                                className="_fullWidth"
                                value={currentArea.name}
                                onChange={(e) => handleNameChange(e.target.value)}
                                label={translate({id: 'advertisingArea.name'})}
                            />
                            <div className="_fullWidth">
                                <RepresentativeBranch id={representativeBranchId} onClear={handleClearRepresentativeBranch}/>
                            </div>
                        </div>
                    </PaperX>
                    <BranchesSelector leftTableProps={leftTableProps} rightTableProps={rightTableProps} branches={branchesWithRep} selectedBranches={currentArea.branchIds} onSelectionChange={handleSelectionChange}/>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                        <Link to={bannerId ? `${paths.advertisingAreasList}?id=${bannerId}` : paths.advertisingAreasList}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default AdvertisingArea;
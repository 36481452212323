/* eslint-disable react-hooks/exhaustive-deps */

import {useState} from 'react';
import {Link} from 'react-router-dom';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {API_BPCO_WITH_SUPPLIER_SEARCH} from 'config/api/constants';
import {paths} from 'paths';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import {MarketSelector} from 'components/Selectors';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {ButtonClose, ButtonNew} from 'components/Buttons';
import ProductSearch from 'components/ProductSearch/ProductSearch';
import {productWithSupplierSearchResult, shortCharacteristic} from 'shared/models/product.model';
import {getFromLocalStorage} from 'utils/storageUtils';
import {extractMainAttributes} from 'modules/Advertisement/AdvertisementsOverview/AdvertisementsTable';
import {CategoryTooltip, MainAttributesTooltip} from 'modules/MasterData/Product/Tooltips';

type BPCOSearchTableRow = {
    brand: string,
    description: string,
    category: string,
    characteristics: shortCharacteristic[],
    id: string,
    mainAttributes: string,
    supplierName: string
}

const queryStrLocalStorageKey: string = 'BPCOListViewQueryStr';

const BPCOList = ({history}) => {
    const translate = useFormatMessage();
    const [BPCOTableData, setBPCOTableData] = useState<BPCOSearchTableRow[]>([]);
    const restoredQueryStr: string = getFromLocalStorage(queryStrLocalStorageKey);

    const columnDefs: ColDef[] = [
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'supplierName', headerName: translate({id: 'b.supplier'}), flex: 1 },
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'category', headerName: translate({id: 'b.category'}), flex: 1,
            tooltipField: 'category',
            tooltipComponent: CategoryTooltip
        },
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            tooltipField: 'characteristics',
            tooltipComponent: MainAttributesTooltip
        }];

    const transformResponseWithSuppliers = (BPCOSearchResponse: productWithSupplierSearchResult[]): BPCOSearchTableRow[] => BPCOSearchResponse.map((item) => {
            const {brand, category, characteristics, description, id, supplierName} = item;
            const row: BPCOSearchTableRow = {
                brand,
                description,
                category,
                characteristics,
                id,
                mainAttributes: extractMainAttributes(characteristics),
                supplierName
            }
            return row;
        });

    const goToDetails = (BPCOId: string) => history.push(`${paths.BPCODetails}?id=${BPCOId}`);

    return (
        <div className="viewRoot BPCOListRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div className="_formRowDouble noMargins">
                            <ProductSearch<productWithSupplierSearchResult> onNewData={(data) => setBPCOTableData(transformResponseWithSuppliers(data))}
                                           searchUrl={API_BPCO_WITH_SUPPLIER_SEARCH}
                                           queryStrStorageKey={queryStrLocalStorageKey}
                                           restoredQueryStr={restoredQueryStr}
                            />
                            <MarketSelector />
                        </div>
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _fullTable _customTooltipPositionFix">
                        <AgTable
                            rowData = {BPCOTableData}
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                suppressMenu: true
                            }}
                            tooltipShowDelay={0}
                            columnDefs = {columnDefs}
                            onRowClicked = {(rowData) => goToDetails(rowData.data.id)}
                            localStorageColumnsKey={localStorageColumnsKeys.BPCOList}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={`${paths.BPCODetailsNew}?new=true`}>
                            <ButtonNew/>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    )
};

export default BPCOList;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { InputLabel, MenuItem, FormControl } from '@mui/material';
import Select from '@mui/material/Select';
import {regionResponseModel} from 'shared/models/region.model';
import {states} from 'shared/models/states.model';
import { useFormatMessage } from 'utils/translate';
import {AttributeError, AttributeLoading} from 'components/Attribute/AttributeHelpers';
import {useRegionQuery} from './RegionAPI';

interface RegionSelectorProps {
  country: string;
  required?: boolean;
  regionId?: string;
  onRegionChange?: (regionId: string) => void;
}

export const RegionSelector = (props: RegionSelectorProps) => {
    const { country, regionId, onRegionChange, required } = props;
    const translate = useFormatMessage();

    const regionQuery = useRegionQuery();
    const [componentState, setComponentState] = useState<states>(states.loading);
    const [regionsData, setRegionsData] = useState<regionResponseModel>([]);

    useEffect(() => {
        if (regionQuery.isLoading) {
            setComponentState(states.loading);
        } else if (regionQuery.isError) {
            setComponentState(states.error);
        } else if (regionQuery.isSuccess) {
            setComponentState(states.ready);
            setRegionsData(regionQuery.data);
        }
    }, [regionQuery]);

    const regionsByCountry: regionResponseModel = regionsData.filter((region) => region.countryId === country);

    const renderByState = (state: states) => {
        switch (state) {
            case states.loading:
                return <AttributeLoading/>;
            case states.error:
                return <AttributeError errorLabel="industrySelector.err"/>
            case states.ready:
                return <FormControl fullWidth required={required}>
                    <InputLabel>{translate({id: "b.region"})}</InputLabel>
                    <Select color="primary"
                            value={regionId}
                            required={required}
                            onChange={(e) => onRegionChange(e.target.value)}
                            label={translate({id: "b.region"})}
                    >
                        {regionsByCountry.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
            default:
                return <AttributeError errorLabel="industrySelector.err"/>
        }
    };

    return renderByState(componentState);
}
export default RegionSelector;
import './MasterData.scss';

import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import { paths } from 'paths';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import MasterDataCard from 'components/Cards/MasterDataCard';

const MasterData = () => {
    return (
        <div className="viewRoot masterDataRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow _centerX _centerY">
                    <div className="_directionRow _centerY">
                        <div className="categoryPanel">
                            <div className="categoryHeader">
                                <FormattedMessage id="masterData.basicMasterData"/>
                            </div>
                            <div className="_directionCol categoryItems">
                                <Link to={paths.attribute}>
                                    <MasterDataCard text="attributes.label"/>
                                </Link>
                                <Link to={paths.dictionary}>
                                    <MasterDataCard text="masterData.dictionaries"/>
                                </Link>
                                <Link to={paths.literal}>
                                    <MasterDataCard text="literals.label"/>
                                </Link>
                            </div>
                        </div>
                        <div className="categoryPanel">
                            <div className="categoryHeader">
                                <FormattedMessage id="b.product"/>
                            </div>
                            <div className="_directionCol categoryItems">
                                <Link to={paths.productsList}>
                                    <MasterDataCard text="masterData.products"/>
                                </Link>
                                <Link to={paths.BPCOList}>
                                    <MasterDataCard text="masterData.BPCO"/>
                                </Link>
                                <Link to={paths.productDescriptionList}>
                                    <MasterDataCard text="b.productDescription"/>
                                </Link>
                                <Link to={paths.BPCODescriptionList}>
                                    <MasterDataCard text="b.bpcoDescription"/>
                                </Link>
                                <Link to={paths.productLineList}>
                                    <MasterDataCard text="masterData.productLines"/>
                                </Link>
                                <Link to={paths.categoriesHierarchy}>
                                    <MasterDataCard text="b.categories"/>
                                </Link>
                                <Link to={paths.category}>
                                    <MasterDataCard text="b.categoryAttributes"/>
                                </Link>
                                <Link to={paths.brands}>
                                    <MasterDataCard text="b.brands"/>
                                </Link>
                                <Link to={paths.suppliers}>
                                    <MasterDataCard text="b.suppliers"/>
                                </Link>
                            </div>
                        </div>
                        <div className="categoryPanel">
                            <div className="categoryHeader">
                                <FormattedMessage id="b.retailer"/>
                            </div>
                            <div className="_directionCol categoryItems">
                                <Link to={paths.keyHierarchy}>
                                    <MasterDataCard text="masterData.retailerHierarchy"/>
                                </Link>
                                <Link to={paths.retailerHQList}>
                                    <MasterDataCard text="retailerHQ.names"/>
                                </Link>
                                <Link to={paths.branchesList}>
                                    <MasterDataCard text="b.branches"/>
                                </Link>
                                <Link to={paths.advertisingAreasList}>
                                    <MasterDataCard text="b.advertisingAreas"/>
                                </Link>
                                <Link to={paths.franchiseOwnersList}>
                                    <MasterDataCard text="b.franchiseOwners"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={paths.home}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default MasterData;
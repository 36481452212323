import {useDispatch} from 'react-redux';
import {CloudUpload} from '@mui/icons-material';
import { useFormatMessage } from 'utils/translate';
import {leafletUploadDialogToggle} from 'shared/reducers/leafletUpload';
import IconTile2 from 'components/IconTile2/IconTile2';

const LeafletUploadNavButton = () => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();
    return (<IconTile2 Icon={CloudUpload} title={translate({id: 'leafletUploadNavButton.leafletUploadCTA'})} onClick={() => dispatch(leafletUploadDialogToggle(true))}/>);
};

export default LeafletUploadNavButton;
// inspired by components from AttributeHelpers.tsx
import './StatusDisplay.scss';

import {FormattedMessage} from 'react-intl';
import {CircularProgress} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface StatusDisplayErrorProps {
    errorLabel?: string,
    textMode?: boolean
}

const StatusDisplayError = (props: StatusDisplayErrorProps) => {
    if (props.textMode) {
        return <FormattedMessage id={props.errorLabel || 'a.error'}/>;
    }
    return (
        <div className="statusDisplayErrorRoot">
            <WarningIcon/>
            &nbsp;
            <FormattedMessage id={props.errorLabel || 'a.error'}/>
        </div>
    );
};

interface StatusLoadingProps {
    textMode?: boolean
}

const StatusDisplayLoading = (props: StatusLoadingProps) => {
    if (props.textMode) {
        return <><FormattedMessage id="a.loading"/>...</>;
    }
    return (
        <div className="statusDisplayLoadingRoot">
            <CircularProgress size={20}/>
            &nbsp;
            <FormattedMessage id="a.loading"/>
        </div>
    );
};

export {StatusDisplayError, StatusDisplayLoading};
import './Attribute.scss';

import {TextField} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {rangeAttributeDataModel, unitAttributeModel} from 'shared/models/attributeComponent.model';
import AttributeUnit from './AttributeUnit';

type attributeRangeProps ={
    data: rangeAttributeDataModel,
    model: unitAttributeModel,
    onChange: Function,
    onClear: Function,
    required?: boolean,
    disabled?: boolean
}

const AttributeRange = (props:attributeRangeProps) => {
    const translate = useFormatMessage();
    const {model, data, onChange, onClear, required, disabled} = props;

    const handleChange = (key, value) => {
        const newData = {...data};
        if (key === "valueFrom"){
            newData[key] = value;
            newData["valueTo"] = value;
        }else newData[key] = value;
        onChange(model.id, newData)
    };

    return (
        <AttributeUnit model={model} data={data} onChange={handleChange} onClear={() => onClear(model.id)} required={required} disabled={disabled}>
            <TextField
                className="numberInput"
                type="number"
                label={translate({id: 'a.valueFrom'})}
                value={data ? data.valueFrom : ''}
                onChange={(e) => handleChange('valueFrom', e.target.value)}
                disabled={disabled}
            />
            <TextField
                className="numberInput"
                type="number"
                label={translate({id: 'a.valueTo'})}
                value={data ? data.valueTo : ''}
                onChange={(e) => handleChange('valueTo', e.target.value)}
                disabled={disabled}
            />
        </AttributeUnit>
    )
};
export default AttributeRange;
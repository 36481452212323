import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios, {AxiosError} from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_DELETE_FRAME} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface DeleteFrameProps {
    frameId: string,
    on404: () => void,
    onFrameDeleted: () => void,
}

const DeleteFrame = (props: DeleteFrameProps) => {
    const {frameId, on404, onFrameDeleted} = props;
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const handleConfirmClick = () => {
        setIsLoading(true);
        axios.delete(API_DELETE_FRAME(frameId))
            .then(() => {
                setOpen(false);
                onFrameDeleted();
            })
            .catch((e: AxiosError) => {
                console.log(e);
                if (e.response.status === 404) {
                    setOpen(false);
                    on404 && on404();
                }
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.deleteFrameErr'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <Button variant="contained" color="primary"
                    onClick={() => setOpen(true)}
                    disabled={!frameId}>
                <FormattedMessage id="advertisementsOverview.deleteFrame"/>
            </Button>
            <ConfirmDialog open={open}
                           onConfirm={handleConfirmClick}
                           onCancel={() => setOpen(false)}
                           message={<FormattedMessage id="advertisementsOverview.deleteFrameConfirm"/>}
                           confirmLabelId="a.yes"
                           cancelLabelId="a.no"
            />
        </>
    )
}

export default DeleteFrame;
import './SnackbarDismissAction.scss';

import {ClearRounded}from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const SnackbarDismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <div className="snackbarDismissActionRoot" onClick={() => closeSnackbar(id)}>
      <ClearRounded color="inherit"/>
    </div>
  )
};

export default SnackbarDismissAction;
/* eslint-disable react-hooks/exhaustive-deps */
import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {PROMOTION_TYPES, promotionMediaTableRowBE, promotionMediaTableRow} from 'shared/models/promotion.model';
import {hardcodedPercent} from 'shared/handyAttributes';
import {shortCharacteristic} from 'shared/models/product.model';
import {validity} from 'shared/models/validity.model';
import {useFormatMessage} from 'utils/translate';

interface AdvertisementsMediaTableProps {
    rowData: promotionMediaTableRowBE[],
    onRowClick?: (id: string) => void
}

const AdvertisementsMediaTable = (props: AdvertisementsMediaTableProps) => {
    const translate = useFormatMessage();

    const promotionsColDefs: ColDef[] = [
        { field: 'brand', headerName: translate({ id: 'b.brand' })},
        { field: 'description', headerName: translate({ id: 'b.descr' })},
        { field: 'category', headerName: translate({id: 'b.category' }), flex: 2},
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3},
        { field: 'promotionalPrice', headerName: translate({ id: 'b.promotionalPrice'})},
        { field: 'regularPrice', headerName: translate({ id: 'b.regularPrice'})},
        { field: 'relativeDiscount', headerName: translate({ id: 'b.relativeDiscount'}),
            cellRenderer: (params) => params.value ? `${params.value} ${hardcodedPercent}` : ''},
        { field: 'absoluteDiscount', headerName: translate({ id: 'b.absoluteDiscount'}),
            cellRenderer: (params) => params.value ? `${params.value} ${params.data.currency}`: ''},
        { field: 'generalInformation', headerName: translate({ id: 'b.general' }), flex: 4},
        { field: 'isTemplate', headerName: translate({ id: 'product.isTemplate' }),
            width: 160, cellClass: 'active-flag-cell',
            cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)
        },
        { field: 'validityDates', headerName: translate({ id: 'promotion.validity'}), flex: 2,
            valueGetter: (params) => params.data.validityDates.join(', '),
            tooltipField: 'validityDates'
        },
        { field: 'giveAwayBundling', headerName: translate({ id: 'productSearch.giveAwayBundling' }), flex: 2},
    ];

    const defaultColDef = {
        flex: 1,
        resizable: true,
        suppressMenu: true,
        sortable: true
    };
    return (
            <AgTable
                columnDefs={promotionsColDefs}
                defaultColDef={defaultColDef}
                localStorageColumnsKey={localStorageColumnsKeys.advertisementMedia}
                enableBrowserTooltips={true}
                {...props}
                rowData={flattenBETableData(props.rowData)}
                onRowClicked={(rowData: RowClickedEvent) => {
                    if (props.onRowClick) props.onRowClick(rowData.data.id);
                }}
            />
    );
}

const flattenBETableData = (data: promotionMediaTableRowBE[]): promotionMediaTableRow[] => {
    return data.map((row) => {
        const isProduct: boolean = row.promotionObjectType === PROMOTION_TYPES.PRODUCT;

        let generalInformation = [];
        if (row.mpu) generalInformation.push(row.mpu);
        generalInformation = generalInformation.concat(row.promotionTypes).concat(row.themeSeasons).concat(row.countryThemedWeeks).concat(row.qualitySeals);

        let giveAwayBundlingValues = [];
        if (row.giveAwayBundledProducts && row.giveAwayBundledProducts.length > 0) {
            giveAwayBundlingValues.push(`${row?.giveAwayBundledProducts?.map(item => item.brand ? item.brand : item).join(', ')}`);
        }

        let description: string = '';
        if (isProduct) {
            description = row.product.description;
        } else {
            description = row.bpco.description;
        }

        const flatRow: promotionMediaTableRow = {
            absoluteDiscount: row.absoluteDiscount,
            brand: isProduct ? row.product.brand : row.bpco.brand,
            category: isProduct ? row.product.category : row.bpco.category,
            currency: row.currency,
            description,
            generalInformation,
            id: row.id,
            isTemplate: isProduct ? row.product.isTemplate : false,
            mainAttributes: extractMainAttributes(isProduct ? row.product.characteristics : row.bpco.characteristics),
            promotionalPrice: row.promotionalPrice,
            regularPrice: row.regularPrice,
            relativeDiscount: row.relativeDiscount,
            giveAwayBundling: giveAwayBundlingValues,
            validityDates: row.validityDates.map((item) => makeValidityString(item))
        };
        return flatRow;
    });
};

export const extractMainAttributes = (characteristics: shortCharacteristic[]): string => characteristics
    .map((item) => item.value)
    .join(', ');

const makeValidityString = (validityDates: validity): string => {
    if (validityDates) {
        if (validityDates.validFrom === validityDates.validTo) {
            return validityDates.validFrom;
        } else {
            return `${validityDates.validFrom} - ${validityDates.validTo}`;
        }
    }
    return '';
}

export default AdvertisementsMediaTable;
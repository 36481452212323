import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {BPCODescriptionResponseModel} from 'shared/models/BPCO.model';
import {useFormatMessage} from 'utils/translate';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';

interface BPCODescriptionsTableProps {
    data: BPCODescriptionResponseModel[],
    onRowClick: (description: BPCODescriptionResponseModel) => void
}

const BPCODescriptionsTable = ({data, onRowClick}: BPCODescriptionsTableProps) => {
    const translate = useFormatMessage();

    const columnDefs: ColDef[] = [
        { field: 'mainDescriptions', headerName: translate({id: 'b.mainDescription'}), flex: 1, valueGetter: (params) => {
                const mainDescriptions: string[] = [];
                for (let index in params.data.mainDescriptions){
                    mainDescriptions.push(`${params.data.mainDescriptions[index].description} (${params.data.mainDescriptions[index].language})`)
                }
                return mainDescriptions.join(', ');
            }
        },
        { field: 'alternativeDescriptions', headerName: translate({id: 'b.alternativeDescription'}), flex: 1, valueGetter: (params) => {
                const altDescriptions: string[] = [];
                for (let index in params.data.alternativeDescriptions){
                    altDescriptions.push(`${params.data.alternativeDescriptions[index].description} (${params.data.alternativeDescriptions[index].language})`)
                }
                return altDescriptions.join(', ');
            }
        }
    ];
    
    return (
        <AgTable
            rowData={data}
            defaultColDef={{
                resizable: true,
                sortable: true
            }}
            columnDefs={columnDefs}
            onRowClicked={(params) => onRowClick(params.data)}
            localStorageColumnsKey={localStorageColumnsKeys.BPCODescriptionList}
        />
    );
};

export default BPCODescriptionsTable;
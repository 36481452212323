import './PromotionItem.scss';

import {FormattedMessage} from 'react-intl';
import {PROMOTION_TYPES, suggestedPromotionForFrame} from 'shared/models/promotion.model';
import {BPCODetails, PriceDisplay, ProductDetails} from 'components/Displays';
import {GeneralInformation} from 'modules/Advertisement/FrameDescription/ProductSuggestions/ProductSuggestion';

interface PromotionItemProps {
    data: suggestedPromotionForFrame,
    isLast: boolean
}
const PromotionItem = ({data,isLast}: PromotionItemProps) => {
    const {promotionObjectType, countryThemedWeeks, promotionTypes, qualitySeals, themeSeasons, product,
        absoluteDiscount, bpco, currency, mpu, promotionalPrice, regularPrice, relativeDiscount
    } = data;

    return (
        <div className="promotionSuggestionItemRoot">
            <h3><FormattedMessage id="b.product"/></h3>
            {promotionObjectType === PROMOTION_TYPES.PRODUCT ? <ProductDetails product={product}/> : <BPCODetails bpco={bpco}/>}
            <h3><FormattedMessage id="b.price"/></h3>
            <PriceDisplay absoluteDiscount={absoluteDiscount}
                          currency={currency}
                          mpu={mpu}
                          promotionalPrice={promotionalPrice}
                          regularPrice={regularPrice}
                          relativeDiscount={relativeDiscount}/>
            <GeneralInformation countryThemedWeeks={countryThemedWeeks}
                                promotionTypes={promotionTypes}
                                qualitySeals={qualitySeals}
                                themeSeasons={themeSeasons}/>
            {!isLast && <div className="divider"></div>}
        </div>
    );
};

export default PromotionItem;
/* eslint-disable react-hooks/exhaustive-deps */
// @TODO unable to define types for product lines, need refactoring
import {useEffect, useState} from "react";
import {GridOptions} from "ag-grid-community";
import {filteredProductsResponseModel} from 'shared/models/product.model';
import {useFormatMessage} from "utils/translate";
import {PaperX} from "components/PaperX";
import AgTable from "components/AgTable/AgTable";
import {ActiveFlagCell} from "components/AgTable/renderers";
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";

enum PRODUCTS_SELECTOR_ACTIONS {'select', 'deselect'}

type productsSelectorProps = {
    products: filteredProductsResponseModel[],
    selectedProducts: any[], // see comment on productLine model - products of product lines does not have constant structure...
    onSelectionChange:any,
    columnDefs?:any[],
    upperTableProps?: any
    rightTableProps?: GridOptions
}

const ProductsSelector = (props:productsSelectorProps) => {
    const translate = useFormatMessage();

    const [products, setProducts] = useState<filteredProductsResponseModel[]>([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        setProducts(props.products);
    }, [props.products]);

    useEffect(() => {
        setSelectedProducts(props.selectedProducts);
    }, [props.selectedProducts]);

    useEffect(() => {
        setSelectionModel(products.map((product) => ({...product, selected: selectedProducts.find((index) => index.id === product.id) ? true : false})));
    }, [products, selectedProducts]);

    const handleSelectionChange = (event, type:PRODUCTS_SELECTOR_ACTIONS) => {
        let returnSelection = [];
        const newSelection = event.api.getSelectedNodes().map((node) => node.data).filter((product) => product.selectable === true);
        if (type === PRODUCTS_SELECTOR_ACTIONS.select) {
            returnSelection = [...selectedProducts].concat(newSelection);
        } else {
            returnSelection= [...selectedProducts].filter((existing) => newSelection[0].id !== existing.id) ;
        }
        props.onSelectionChange(returnSelection);
    };

    const defaultColumnDefs: ColDef[] = [
        { field: 'brand', headerName: translate({ id: 'b.brand' }),suppressMenu: true, sortable: true, flex: 1},
        { field: 'description', headerName: translate({ id: 'b.descr' }),suppressMenu: true, sortable: true, flex: 1},
        { field: 'typeOfPackage', headerName: translate({ id: 'b.typeOfPackage'}),suppressMenu: true, sortable: true, flex: 1},
        { field: 'capacity', headerName: translate({ id: 'b.capacity' }),suppressMenu: true, sortable: true, maxWidth:150, flex: 1,
            valueGetter: (params) => {
                const from = params.data.capacityFrom;
                const to = params.data.capacityTo;
                if (from || to) {
                    if (from === to) {
                        return from;
                    }
                    return `${from} - ${to}`;
                }
                return '';
            }
        },
        { field: 'capacityUnit', headerName: translate({ id: 'b.capacityUnit'}),suppressMenu: true, sortable: true, flex: 1},
        { field: 'contentOfTradingUnits', headerName: translate({ id: 'b.contentOfTradingUnit'}),suppressMenu: true, sortable: true, flex: 1},
        { field: 'contentOfTradingUnitsUnit', headerName: translate({ id: 'b.contentOfTradingUnitUnit'}),suppressMenu: true, sortable: true, flex: 1},
        { field: 'variety', headerName: translate({ id: 'b.variety'}),suppressMenu: true, sortable: true, flex: 1},
        { field: 'productLineName', headerName: translate({id: 'b.productLine'}), suppressMenu: true, sortable: true, flex: 1},
        { field: 'active', headerName: translate({ id: 'a.active'}), width: 75, suppressMenu: true, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)}
    ];

    return (
        <div className="_directionCol">
            <PaperX className="weldBottom">
                <span>{translate({id: 'productsSelector.avail'})}</span>
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _fullTable weldTop">
                <AgTable
                    rowData={selectionModel.filter((product) => product.selected === false)}
                    rowSelection={'single'}
                    columnDefs={defaultColumnDefs}
                    onSelectionChanged={(e) => handleSelectionChange(e, PRODUCTS_SELECTOR_ACTIONS.select)}
                    {...props.upperTableProps || null}
                />
            </PaperX>
            <PaperX className="weldBottom">
                <span>{`${translate({id: 'productsSelector.selected'})}`}</span>
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _fullTable weldTop">
                <AgTable
                    rowSelection={'single'}
                    rowData={selectedProducts}
                    columnDefs={defaultColumnDefs}
                    onSelectionChanged={(e) => handleSelectionChange(e, PRODUCTS_SELECTOR_ACTIONS.deselect)}
                />
            </PaperX>
        </div>
    );
};

export default ProductsSelector;
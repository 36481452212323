import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import axios from 'axios';
import {parseParams} from 'utils/axiosHooks/axiosHooks';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {
    API_MEDIA_RADIO_SPOT_DELETE,
    API_RADIO_SPOTS_OVERVIEW
} from 'config/api/constants';
import {paths} from 'paths';
import {radioSpotOverviewRow, spotFilters} from 'shared/models/media.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {validityDatesGetter} from 'components/AgTable/getters';
import {RadioSpostOverviewAction} from './RadioSpotOverviewActions';
import DeleteMediaDialog from 'components/DeleteMediaDialog/DeleteMediaDialog';
import RadioSpotOverviewFilters, {getRadioSpotOverviewFilters} from './RadioSpotOverviewFilters';

export enum radioSpotActions {
    EDIT_HEADER_DATA = 'EDIT_HEADER_DATA',
    VERIFY = 'VERIFY',
    DESCRIBE_SPOT = 'DESCRIBE_SPOT',
    NO_ACTION = 'NO_ACTION'
};

const RadioSpotOverview = ({history}) => {
    const translate = useFormatMessage();
    const cancelToken = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const dateFns = new AdapterDateFns();
    const [data, setData] = useState<radioSpotOverviewRow[]>([]);
    const [selectedSpot, setSelectedSpot] = useState<radioSpotOverviewRow>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // @TODO there's reusable notifyError utility function available now, use when merged with dev
    const notifyError = () => enqueueSnackbar(`${translate({id: 'media.radioFetchErr'})}`, {variant: 'error', persist: false});

    const getData = (filters: spotFilters) => {
        setIsLoading(true);
        setData([]);
        axios.get<radioSpotOverviewRow[]>(API_RADIO_SPOTS_OVERVIEW, {
            params: filters,
            paramsSerializer: (params) => parseParams(params),
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if(responseValidation(resp.data)) {
                    setData(resp.data.map((item) => ({
                        ...item,
                        status: item.status,
                        createdAt: item.createdAt ? dateFns.formatByString(new Date(item.createdAt), 'yyyy/MM/dd kk:mm:ss') : ''
                    })));
                } else {
                    notifyError();
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    notifyError();
                }
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const handleCellClicked = (event) => {
        if (event.column.colId === 'status') {
            if (event.data.quickAction === radioSpotActions.EDIT_HEADER_DATA) {
                history.push(`${paths.radioSpotHeaderData}?id=${event.data.id}`);
            } else if (event.data.quickAction === radioSpotActions.VERIFY) {
                history.push(`${paths.radioSpotHeaderData}?id=${event.data.id}`);
            } else if (event.data.quickAction === radioSpotActions.DESCRIBE_SPOT) {
                history.push(`${paths.describeRadioSpot}?id=${event.data.id}&from=${paths.radioSpotOverview}`);
            }
        }
    };

    const leafletsColumnDefs: ColDef[] = [
        { field: 'banner', headerName: translate({ id: 'b.banner' }), flex: 1, lockVisible: true},
        // { field: 'fileName', headerName: translate({ id: 'b.fileName' }), flex: 1},
        { field: 'language', headerName: translate({id: 'dictionaryElement.lang'}), flex: 1, maxWidth: 120, valueGetter: (params) => params.data.language ? (translate({id: 'language.'+ params.data.language})) : ''},
        { field: 'status', headerName: translate({ id: 'printMediaOverview.status' }), flex: 1, cellRenderer: (params) => <RadioSpostOverviewAction data={params.data} value={params.value}/>},
        { field: 'validityDates', headerName: translate({ id: 'printMediaOverview.validityDates' }), flex: 1, valueGetter: (params) => (validityDatesGetter(params.data))},
        { field: 'createdAt', headerName: translate({ id: 'printMediaOverview.creationDate'}), maxWidth: 200},
        { field: 'createdBy', headerName: translate({ id: 'printMediaOverview.createdBy'}), maxWidth: 120},
        { field: 'modifiedBy', headerName: translate({ id: 'printMediaOverview.modifiedBy'}), maxWidth: 120},
        // { field: 'repBranch', headerName: translate({ id: 'b.representativeBranch' }) , flex: 1}
    ];

    const canDo = (doWhat: radioSpotActions, availableActions: string[]): boolean => availableActions.findIndex((item) => item === doWhat) > -1;

    const getContextMenu = (rowNode: GetContextMenuItemsParams): (MenuItemDef | string)[] =>
        [
            canDo(radioSpotActions.EDIT_HEADER_DATA, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'b.enterHeaderData'}),
                    action: () => {
                        history.push(`${paths.radioSpotHeaderData}?id=${rowNode.node.data.id}`)
                    }
                } : 
                {
                    name: translate({id: 'b.enterHeaderData'}),
                    disabled: true
                },
            canDo(radioSpotActions.VERIFY, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'media.radioSpotVerify'}),
                    action: () => {
                        history.push(`${paths.radioSpotHeaderData}?id=${rowNode.node.data.id}`)
                    }
                } : null,
            canDo(radioSpotActions.DESCRIBE_SPOT, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'media.radioSpotDescribe'}),
                    action: () => {
                        history.push(`${paths.describeRadioSpot}?id=${rowNode.node.data.id}&from=${paths.radioSpotOverview}`)
                    }
                } :
                {
                    name: translate({id: 'media.radioSpotDescribe'}),
                    disabled: true
                },
            'separator',
            {
                name: translate({id: 'media.delete'}),
                action: () => {
                    setSelectedSpot(rowNode.node.data);
                    setDeleteDialogOpen(true);
                }
            }
        ];

    const handleDeleteConfirm = () => {
        setIsLoading(true);
        axios.delete(API_MEDIA_RADIO_SPOT_DELETE(selectedSpot['id']), {cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then(() => {
                const filters: spotFilters = getRadioSpotOverviewFilters();
                if (filters) getData(filters);
                enqueueSnackbar(`${translate({id: 'media.deleteSucc'})}`, {variant: 'success', persist: false});
                setDeleteDialogOpen(false);
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar(`${translate({id: 'media.deleteErr'})}`, {variant: 'error', persist: false});
                if(!e.__CANCEL__) {
                    console.log(e)
                }
            });
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setSelectedSpot(null);
    };

    const handleFiltersChange = (filters: spotFilters) => {
        if (!filters) return;
        getData(filters);
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <RadioSpotOverviewFilters onFilterChange={(filters) => handleFiltersChange(filters)} view="spot"/>
                    </PaperX>
                    <PaperX className="_fullHeight _fullTable">
                        <AgTable
                            defaultColDef={{
                                resizable: true,
                                sortable: true,
                                tooltipValueGetter: () => translate({id: 'a.right4context'})
                            }}
                            rowData={data}
                            columnDefs={leafletsColumnDefs}
                            suppressContextMenu={false}
                            getContextMenuItems={getContextMenu}
                            rowSelection="multiple"
                            localStorageColumnsKey={localStorageColumnsKeys.radioSpotsOverview}
                            onCellClicked={(e) => handleCellClicked(e)}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer actionsRight={
                <>
                    <Link to={paths.radioSpotHeaderData}>
                        <Button color="primary" variant="contained">{translate({id: 'a.new'})}</Button>
                    </Link>
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                </>
            }
            />
            <DeleteMediaDialog open={deleteDialogOpen}
                               onConfirm={handleDeleteConfirm}
                               onCancel={handleDeleteCancel}
                               media/>
        </div>
    );
}

export default RadioSpotOverview;
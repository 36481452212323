import './RadioSpotOverviewActions.scss';
import {Link} from 'react-router-dom';
import {ArrowForward} from '@mui/icons-material';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {radioSpotStatus, radioSpotOverviewRow} from 'shared/models/media.model';
import {useFormatMessage} from 'utils/translate';

const quickActions = {
    EDIT_HEADER_DATA: 'EDIT_HEADER_DATA',
    VERIFY: 'VERIFY',
    DESCRIBE_SPOT: 'DESCRIBE_SPOT',
    NO_ACTION: 'NO_ACTION'
};

export const radioSpotStatusLabels = {
    [radioSpotStatus.REGISTERED]: 'media.statusRegistered',
    [radioSpotStatus.PENDING]: 'media.statusPending',
    [radioSpotStatus.VERIFIED]: 'media.statusVerified',
    [radioSpotStatus.DONE]: 'media.statusDone'
}

interface RadioSpotOverviewQuickActionProps {
    quickAction: string,
    id: string
}

function RadioSpotOverviewQuickAction(props: RadioSpotOverviewQuickActionProps) {
    const translate = useFormatMessage();
    const {id, quickAction} = props;

    switch (quickAction) {
        case quickActions.EDIT_HEADER_DATA:
            return (
                <Link to={`${paths.radioSpotHeaderData}?id=${id}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'printMediaOverview.headerDataCTA'})}</Button>
                </Link>);
        case quickActions.VERIFY:
            return (
                <Link to={`${paths.radioSpotHeaderData}?id=${id}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'media.radioSpotVerify'})}</Button>
                </Link>);
        case quickActions.DESCRIBE_SPOT:
            return (
                <Link to={`${paths.describeRadioSpot}?id=${id}&from=${paths.radioSpotOverview}`}> 
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'media.radioSpotDescribe'})}</Button>
                </Link>);
        case quickActions.NO_ACTION:
            return null;
        default:
            return null;
    }
}

interface RadioSpotOverviewActionProps {
    data: radioSpotOverviewRow,
    value: string,
}

export function RadioSpostOverviewAction({data, value}: RadioSpotOverviewActionProps) {
    const translate = useFormatMessage();
    return (<div className="radioSpotActionRoot">
        <span>{translate({id: radioSpotStatusLabels[value]})}</span>
        <RadioSpotOverviewQuickAction quickAction={data.quickAction} id={data.id}/>
    </div>);
}

interface SpotsOverviewLabelProps {
    value: string
}

export function SpotsOverviewLabel({value}: SpotsOverviewLabelProps) {
    const translate = useFormatMessage();

    return (<div className="overviewSpotsActionRoot">
        <span>{translate({id: radioSpotStatusLabels[value]})}</span>
    </div>);
}
import './Attribute.scss';
import {
    multiselectAttributeDataModel,
    unitAttributeModel
} from 'shared/models/attributeComponent.model';

type attributeEnumTextProps = {
    data: multiselectAttributeDataModel,
    model: unitAttributeModel
}

const AttributeMultiselectText = (props: attributeEnumTextProps) => {
    const {data, model} = props;

    const getSelectedOptions = () => {
        if (data !== undefined) {
            let selection = data.literals.map((item) => item.literalId);
            const options:string[] = [];
            model.options.forEach((option) => {
                if (selection.indexOf(option.id) > -1) {
                    options.push(option.name);
                }
            })
            return options.join(', ');
        }
    }

    return <div className="attributeTextRoot">
        <span className="attributeName">{model.name}: </span>
        <span className="attributeValues">{getSelectedOptions()}</span>
    </div>;
};
export default AttributeMultiselectText;
import {categorySelectorOption} from './category.model';
import {gtinObject} from './gtin.model';
import {characteristicPayload} from './attribute.model';
import {simpleDescription, simpleDescriptionUi} from './description.model';
import {handyAttributes} from 'shared/handyAttributes';

export type UIBPCOModel = {
    active: boolean,
    alternativeDescriptions: simpleDescription[],
    brand: any,
    category?: categorySelectorOption,
    characteristics: any,
    descriptionId?: string,
    gtins: gtinObject[],
    id?: string,
    mainDescriptions: simpleDescription[],
    marketId: string,
    supplierId: string,
    supplierName: string
}

export type BPCOResponseModel = {
    active: boolean
    alternativeDescriptions: simpleDescription[],
    brandId: string,
    brandName: string,
    categoryBrickId?: string,
    categoryBrickName?: string,
    categoryClassId?: string,
    categoryClassName?: string,
    categoryFamilyId?: string,
    categoryFamilyName?: string,
    categorySegmentId?: string,
    categorySegmentName?: string,
    characteristics: any[], // @TODO return to characteristics once attribute component is typed properly
    descriptionId: string,
    gtins: any,// @TODO no gtins returned for BPCO at the momemnt of writing
    id: string,
    mainDescriptions: simpleDescription[],
    marketId: string,
    supplierId: string,
    supplierName: string
}

export type BPCOPayloadModel = {
    active: boolean,
    brandId: string,
    categorySegmentId: string,
    categoryFamilyId: string,
    categoryClassId: string,
    categoryBrickId: string,
    characteristics: characteristicPayload[],
    descriptionId?: string,
    gtins: any,// @TODO no gtins can be saved for BPCO at the momemnt of writing,
    marketId: string,
    supplierId: string
}

export type BPCOPostResponseModel = {
    id: string
}

export type BPCODescriptionResponseModel = {
    id: string,
    mainDescriptions: simpleDescriptionUi[],
    alternativeDescriptions: simpleDescriptionUi[]
};

export type BPCODescriptionSingleResponseModel = {
    alternativeDescriptions: simpleDescription[]
    id: string,
    mainDescriptions: simpleDescription[],
    marketId: string
}

export type BPCODescriptionFilters = {
    countryId?: string,
    description: string,
    marketId: string,
    lang: string
}

export type BPCODescriptionUI = {
    alternativeDescriptions: simpleDescriptionUi[],
    id?: string,
    mainDescriptions: simpleDescriptionUi[],
    marketId: string
};

export type BPCODescriptionPayload = {
    alternativeDescriptions: simpleDescription[],
    marketId: string,
    mainDescriptions: simpleDescription[],
}

export type BPCODescriptionUpdatePayload = {
    mainDescriptions: simpleDescription[],
    alternativeDescriptions: simpleDescription[]
}

// templates

// export function buildBPCOTemplate (marketId: string): UIBPCOModel {
export function buildBPCOTemplate (marketId: string): UIBPCOModel {
    return {
        active: true,
        brand: null,
        category: null,
        descriptionId: null,
        characteristics: {
            [handyAttributes.description]: null,
            [handyAttributes.capacity]: null,
            [handyAttributes.contentOfTradingUnit]: null
        },
        gtins: [],
        marketId,
        mainDescriptions: [],
        alternativeDescriptions: [],
        supplierId: null,
        supplierName: null
    };
}
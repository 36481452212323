/* eslint-disable react-hooks/exhaustive-deps */
import 'components/Snackbar/SnackbarStyles.scss';

import { IntlProvider } from 'react-intl';
import { Route } from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {useSelector} from 'react-redux';
import { IRootState } from 'shared/reducers';
import { SnackbarProvider } from 'notistack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { muiMarkantTheme } from 'muiMarkantTheme';
import { paths } from 'paths';
import lang from 'i18n';
import { calendarLocales } from 'utils/calendar';
import LoadUserProfile from 'utils/LoadUserProfile';
import SnackbarDismissAction from 'components/Snackbar/SnackbarDismissAction';
import StoragePile from 'components/StoragePile/StoragePile';
import {TitleSetter} from 'components/TitleSetter';
import Dashboard from 'modules/Dashboard';
import { TestView, TestView2 } from 'modules/LocalStoragePoC';
import Framing from './modules/Frames/Framing';
import {HeaderData} from 'modules/Leaflet/HeaderData';
import LeafletBrowserFullScreen from 'modules/Leaflet/LeafletBrowserFullScreen/LeafletBrowserFullScreen';
import {PrintMediaOverview} from 'modules/Leaflet/PrintMediaOverview';
import MasterData from 'modules/MasterData/MasterData';
import {ProductsList, ProductCloneView, ProductNewView} from 'modules/MasterData/Product';
import KeyHierarchy from 'modules/MasterData/KeyHierarchy';
import LeafletUploadDialog from 'modules/Leaflet/LeafletUpload/LeafletUploadDialog';
import {BranchesList, BranchDetails} from 'modules/MasterData/BranchesList';
import {AdvertisingArea, AdvertisingAreasList} from 'modules/MasterData/AdvertisingArea';
import {FranchiseOwner, FranchiseOwnersList} from 'modules/MasterData/FranchiseOwner';
import {Dictionary, DictionaryElement} from 'modules/Dictionaries';
import {LiteralCreate, Literal} from 'modules/Dictionaries/Literals';
import {AttributesBrowser, AttributeCreate} from 'modules/Dictionaries/Attributes';
import {AdvertisementBrowser, AdvertisementBrowserMedia, AdvertisementsOverview, AdvertisementSpotOverview, FrameDescriptionLeafletBrowser, FrameDescription} from 'modules/Advertisement';
import {BrandEditView, BrandNewView, Brands, StructuredBrandsEditView, StructuredBrandNewView} from 'modules/MasterData/Brand';
import {BPCODescriptionView, BPCODescriptionList, BPCOList} from 'modules/MasterData/BPCO';
import {BPCONewView, BPCOEditView} from 'modules/MasterData/BPCO/BPCODetails/index';
import ProductLine from 'modules/MasterData/ProductLine/ProductLine';
import ProductLineList from 'modules/MasterData/ProductLine/ProductLineList';
import Statistics from 'modules/Quality/Statistics/Statistics';
import {
  Category,
  CategoryAttributes,
  CategoriesHierarchy,
  CategoryDetails
} from 'modules/MasterData/Category';
import BrickView from 'modules/MasterData/Category/Brick/BrickView';
import ReportedIssues from 'modules/Quality/ReportedIssues/ReportedIssues';
import ReportedFramesBrowser from 'modules/Quality/ReportedFrames/ReportedFramesBrowser/ReportedFramesBrowser';
import ReportedAdvertisementsBrowser from 'modules/Quality/ReportedAdvertisements/ReportedAdvertisementsBrowser/ReportedAdvertisementsBrowser';
import FixReportedFrames from 'modules/Quality/ReportedFrames/FixReportedFrames/FixReportedFrames';
import ProductEditView from 'modules/MasterData/Product/ProductDetails/ProductEditView';
import AdvOverviewPromotions from 'modules/Advertisement/AdvertisementsOverviewPromotions/AdvOverviewPromotions';
import MediaDescription from 'modules/Advertisement/MediaDescription/MediaDescription';
import ProductDescriptionListView from 'modules/MasterData/ProductDescription/ProductDescriptionListView';
import ProductDescriptionView from 'modules/MasterData/ProductDescription/ProductDescriptionView';
import {ProductDescriptionReplacement} from 'modules/MasterData/ProductDescription/ProductDescriptionReplacement';
import GtinQsDashboard from 'modules/Quality/GtinQsDashboard/GtinQsDashboard';
import {MyAdvertisementsView} from 'modules/Quality';
import OptionsDialog from 'modules/Options/OptionsDialog';
import {RetailerHQDetails, RetailerHQList} from 'modules/MasterData/RetailerHQ';
import {RadioSpotHeaderData, RadioSpotOverview} from 'modules/Medium/RadioSpot';
import {SupplierDetails, Suppliers} from "./modules/MasterData/Supplier";
import ProductBulkChange from 'modules/MasterData/Product/ProductBulkChange/ProductBulkChange';
import {ProductBulkSupplier} from 'modules/MasterData/Product/ProductBulkSupplier';
import {ProductBulkDescription} from 'modules/MasterData/Product/ProductBulkDescription';

function App() {
  const userInterfaceLang = useSelector((state: IRootState) => state.userProfile.langInterface);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={userInterfaceLang} messages={lang[userInterfaceLang]}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLocales.en}>
            <ThemeProvider theme={muiMarkantTheme}>
              <CssBaseline/>
              <StoragePile/>
              <LoadUserProfile/>
              <OptionsDialog/>
              <Router>
                <TitleSetter />
                <SnackbarProvider
                  action={(key) => <SnackbarDismissAction id={key} />}
                  maxSnack={3}
                >
                  <Switch>
                    <Route path={paths.BPCODescription} component={BPCODescriptionView}/>
                    <Route path={paths.BPCODescriptionList} component={BPCODescriptionList}/>
                    <Route path={paths.BPCODetails} component={BPCOEditView}/>
                    <Route path={paths.BPCODetailsNew} component={BPCONewView}/>
                    <Route path={paths.BPCOList} component={BPCOList}/>
                    <Route path={paths.advertisementBrowser} component={AdvertisementBrowser}/>
                    <Route path={paths.advertisementBrowserMedia} component={AdvertisementBrowserMedia}/>
                    <Route path={paths.advertisementsOverviewPromotions} component={AdvOverviewPromotions}/>
                    <Route path={paths.advertisementsOverview} component={AdvertisementsOverview}/>
                    <Route path={paths.advertisementsSpotOverview} component={AdvertisementSpotOverview}/>
                    <Route path={paths.advertisingAreasList} component={AdvertisingAreasList}/>
                    <Route path={paths.advertisingArea} component={AdvertisingArea}/>
                    <Route path={paths.attributeCreate} component={AttributeCreate}/>
                    <Route path={paths.attribute} component={AttributesBrowser}/>
                    <Route path={paths.branchDetails} component={BranchDetails}/>
                    <Route path={paths.branchesList} component={BranchesList}/>
                    <Route path={paths.brands} component={Brands}/>
                    <Route path={paths.newBrand} component={BrandNewView}/>
                    <Route path={paths.editBrand} component={BrandEditView}/>
                    <Route path={paths.newStructuredBrand} component={StructuredBrandNewView}/>
                    <Route path={paths.editStructuredBrand} component={StructuredBrandsEditView}/>
                    <Route path={paths.categoriesAttributes} component={CategoryAttributes}/>
                    <Route path={paths.categoriesHierarchy} component={CategoriesHierarchy}/>
                    <Route path={paths.categoryDetails} component={CategoryDetails}/>
                    <Route path={paths.brickDetails} component={BrickView}/>
                    <Route path={paths.category} component={Category}/>
                    <Route path={paths.describeFrameLeafletBrowser} component={FrameDescriptionLeafletBrowser}/>
                    <Route path={paths.describeFrame} component={FrameDescription}/>
                    <Route path={paths.describeRadioSpot} component={MediaDescription}/>
                    <Route path={paths.dictionaryEdit} component={DictionaryElement}/>
                    <Route path={paths.dictionary} component={Dictionary}/>
                    <Route path={paths.enterHeaderData} component={HeaderData}/>
                    <Route path={paths.fixReportedFrames} component={FixReportedFrames}/>
                    <Route path={paths.framing} component={Framing}/>
                    <Route path={paths.franchiseOwnersList} component={FranchiseOwnersList}/>
                    <Route path={paths.franchiseOwner} component={FranchiseOwner}/>
                    <Route path={paths.gtin} component={GtinQsDashboard}/>
                    <Route path={paths.keyHierarchy} component={KeyHierarchy}/>
                    <Route path={paths.leafletBrowserFullScreen} component={LeafletBrowserFullScreen}/>
                    <Route path={paths.printMediaOverview} component={PrintMediaOverview}/>
                    <Route path={paths.literalCreate} component={LiteralCreate}/>
                    <Route path={paths.literal} component={Literal}/>
                    <Route path={paths.localStorage1} component={TestView}/>
                    <Route path={paths.localStorage2} component={TestView2}/>
                    <Route path={paths.masterData} component={MasterData}/>
                    <Route path={paths.myAdvertisements} component={MyAdvertisementsView}/>
                    <Route path={paths.productDescriptionList} component={ProductDescriptionListView}/>
                    <Route path={paths.productDescription} component={ProductDescriptionView}/>
                    <Route path={paths.productDescriptionReplace} component={ProductDescriptionReplacement}/>
                    <Route path={paths.productDetailsClone} component={ProductCloneView}/>
                    <Route path={paths.productDetailsNew} component={ProductNewView}/>
                    <Route path={paths.productDetails} component={ProductEditView}/>
                    <Route path={paths.productLineList} component={ProductLineList}/>
                    <Route path={paths.productLine} component={ProductLine}/>
                    <Route path={paths.productsList} component={ProductsList}/>
                    <Route path={paths.productBulkChange} component={ProductBulkChange}/>
                    <Route path={paths.productBulkDescription} component={ProductBulkDescription}/>
                    <Route path={paths.productBulkSupplier} component={ProductBulkSupplier}/>
                    <Route path={paths.reportedAdvertisementsBrowser} component={ReportedAdvertisementsBrowser}/>
                    <Route path={paths.reportedFramesBrowser} component={ReportedFramesBrowser}/>
                    <Route path={paths.reportedIssues} component={ReportedIssues}/>
                    <Route path={paths.statistics} component={Statistics}/>
                    <Route path={paths.retailerHQList} component={RetailerHQList}/>
                    <Route path={paths.retailerHQDetails} component={RetailerHQDetails}/>
                    <Route path={paths.radioSpotOverview} component={RadioSpotOverview}/>
                    <Route path={paths.radioSpotHeaderData} component={RadioSpotHeaderData}/>
                    <Route path={paths.suppliers} component={Suppliers}/>
                    <Route path={paths.supplierDetails} component={SupplierDetails}/>
                    <Route path={paths.home} component={Dashboard}/>
                  </Switch>
                  <LeafletUploadDialog/>
                </SnackbarProvider>
              </Router>
            </ThemeProvider>
          </LocalizationProvider>
        </IntlProvider>
      </QueryClientProvider>
  );
}

export default App;

import './Header.scss'
import MPMLogo from 'components/Logo';

type HeaderProps = {
  children?: React.ReactNode
};

const Header = ({children}: HeaderProps) => {
  return (
    <div className="headerRoot">
      <MPMLogo />
      <div className="headerRight">
        {children}
      </div>
    </div>
  )
};

export default Header;

import {saneFilters} from 'components/ProductFilter/ProductFilter';
import {basic} from './_common.model';
import {characteristicModel, characteristicPayload, characteristicPayloadProper} from './attribute.model';
import {PROMOTION_TYPES} from './promotion.model';
import {gtinObject} from './gtin.model';
import {categorySelectorOption, categoryType} from './category.model';
import {simpleDescription} from './description.model';
import {categoryBrick} from './categoryBrick.model';

export type shortCharacteristic = {
    id: string,
    name: string,
    value: string
}

export type characteristic = {
    id: string,
    name: string,
    value: string,
    valueId?: string
}

export interface characteristicUI extends characteristic {
    uniq?: boolean
}

export interface shortCharacteristicUI extends shortCharacteristic {
    uniq?: boolean
}

export type productSearchResult = {
    brand: string,
    brandId: string, //always in response, but used only in specific scenarios, like bulk supplier/brand change
    category: string,
    characteristics: shortCharacteristic[],
    description: string,
    gtin?: string, // same API is used when searching for BPCO but gtins dont exist on BPCO model
    id: string,
    isTemplate: boolean,
    relatedPromotionsCount: number
}

export type productSearchResultResponse = productSearchResult[];

export interface productWithSupplierSearchResult extends productSearchResult {
    supplierName: string
}

export type productWithSupplierSearchResultResponse = productWithSupplierSearchResult[];

export interface productSearchResultUI extends Omit<productSearchResult, 'characteristics' | 'brandId'> {
    characteristics: characteristicUI[],
    promotionObjectType: PROMOTION_TYPES
}

export interface extendedProductSearchResultUI extends Omit<productSearchResultUI, 'gtin'> {
    active: boolean,
    alternativeDescription: string,
    alternativeProductId: string,
    gtin: string,
    marketId: string,
    productLineId: string,
    productLineName: string
}

export interface ProductComment {
    text: string,
    author: string | null,
};

export type UIProductModel = {
    active: boolean,
    alternativeDescriptions: simpleDescription[],
    brand: basic,
    category: categorySelectorOption,
    characteristics: any,
    comments: ProductComment[],
    gtins: gtinObject[],
    id?: string,
    mainDescriptions: simpleDescription[],
    marketId: string,
    productDescriptionId: string,
    replacementProductId: string,
    supplierId: string,
    supplierName: string
}

export type productDetailsResponseModel = {
    active: boolean,
    alternativeDescriptions: simpleDescription[],
    brandId: string,
    brandName: string,
    categoryBrickId: string,
    categoryBrickName: string,
    categoryClassName: string,
    characteristics: characteristicModel[],
    comments: ProductComment[],
    gtins: gtinObject[],
    id: string,
    isTemplate: boolean,
    mainDescriptions: simpleDescription[],
    marketId: string,
    productDescriptionId: string,
    replacementProductId: string,
    supplierId: string,
    supplierName: string
}

export type fetchProductsAPI = saneFilters & {
    lang?: string,
    marketId: string
}

export type fetchProductsAPIAdvanced = {
    lang: string,
    countryId: string
}

export type filteredProductsResponseModel = {
    active: boolean,
    brand: string,
    capacityFrom: number,
    capacityTo: number,
    capacityUnit: string,
    category: string,
    contentOfTradingUnits: number,
    contentOfTradingUnitsUnit: string,
    description: string,
    gtin: string,
    id: string,
    relatedPromotionsCount: number,
    typeOfPackage: string,
    variety: string
}

export type reportedProductsResponseModel = filteredProductsResponseModel & {
    comments: string[],
    reported: boolean
}

export type productPOSTResponseModel = {
    id: string
}

export type productsDuplicatedGtins = {
    gtin: string,
    products: filteredProductsResponseModel[]
}

export type productsDuplicatedGtinsResponse = productsDuplicatedGtins[];

export type productPayload = {
    active: boolean,
    brandId: string,
    productDescriptionId: string
    characteristics: characteristicPayload[],
    gtins: gtinObject[],
    marketId: string
}

export type bulkProductUpdatePayload = {
    categoryBrickId: string,
    characteristics: characteristicPayloadProper[],
    productIds: string[],
}

export type bulkProductBrandSupplierUpdatePayload = {
    brandId: string,
    productIds: string[],
    supplierId: string,
}

export type bulkProductUpdateDuplicateCheckResponse = {
    duplicates: string[]
}

export type reportProductPayload = {
    comment: string
}

export type productBulkDescriptionChangeGetRelatedAttributesPayload = {
    lang: string,
    productIds: string[],
    replacementProductDescriptionId: string
}

export type productBulkDescriptionChangeGetRelatedAttributesResponse = {
    deprecatedAttributeNames: string[],
    newAttributes: categoryBrick,
};

export type productBulkDescriptionChangePayload = {
    characteristics: characteristicPayloadProper[] | string,
    categoryBrickId: string,
    productIds: string[],
    replacementProductDescriptionId: string
}

// templates

export function buildProductTemplate (marketId: string): UIProductModel {
    return {
        active: true,
        alternativeDescriptions: [],
        brand: null,
        characteristics: {},
        category: {
            id: '',
            name: '',
            type: categoryType.BRICK
        },
        comments: [],
        gtins: [],
        mainDescriptions: [],
        marketId: marketId,
        productDescriptionId: '',
        replacementProductId: null,
        supplierId: null,
        supplierName: null
    };
}
import {ValiditySelector} from 'components/Selectors';
import {pageValidity} from 'shared/models/leaflet.model';

type PageValiditiesProps = {
    validities: pageValidity[],
    numberOfPages: number,
    onValidityChange: (pageValidities: pageValidity[]) => void
}

const PageValidities = (props:PageValiditiesProps) => {
    const {numberOfPages, onValidityChange, validities} = props;

    const setPageValidityPg = (idx: number, page: string) => {
        const pgValidities: pageValidity[] = validities.map((item, index) => index === idx ? {...item, pageNumber: page, modified: true} : item);
        onValidityChange(pgValidities);
    };

    const removePageValidity = (idx: number) => {
        const pgValidities: pageValidity[] = [...validities];
        pgValidities.splice(idx, 1);
        onValidityChange(pgValidities);
    };

    const setPageValidityFrom = (idx: number, date: Date) => {
        const pgValidities = validities.map((item, index) => index === idx ? {...item, validFrom: date, modified: true} : item);
        onValidityChange(pgValidities);
    };

    const setPageValidityTo = (idx: number, date: Date) => {
        const pgValidities = validities.map((item, index) => index === idx ? {...item, validTo: date, modified: true} : item);
        onValidityChange(pgValidities);
    };

    return (
        <>{validities.map((item, idx) =>
            <div className="_formRowDouble" key={idx}>
                <div>
                    <ValiditySelector id={idx}
                                      required
                                      removable
                                      pageNumber={item.pageNumber}
                                      numberOfPages={numberOfPages}
                                      rangeMode
                                      validityFrom={item.validFrom ||  null }
                                      validityTo={item.validTo ||  null}
                                      onPageChange={(id, page) => setPageValidityPg(id, page)}
                                      onRemove={(id) => removePageValidity(id)}

                                      onValidityFromChange={(id, date) => { setPageValidityFrom(id, date)}}
                                      onValidityToChange={(id, date) => { setPageValidityTo(id, date)}}
                    />
                </div>
            </div>)
        }</>
    );
};
export default PageValidities;
import {useCallback, useState} from 'react';
import debounce from 'lodash.debounce';

const useDebounce = (obj = null, wait = 1000) => {
    const [state, setState] = useState(obj);
// eslint-disable-next-line
    const dbnc = useCallback(
        debounce((_prop) => {
            setState(_prop);
        }, wait),
        []
    );

    const setDebouncedState = (_val) => {
        dbnc(_val);
    };

    return [state, setDebouncedState];
};

export default useDebounce;
import { createTheme } from '@mui/material/styles';
// import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

// type overridesNameToClassKey = {
//   [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
// };

// declare module '@material-ui/core/styles/overrides' {
//   export interface ComponentNameToClassKey extends overridesNameToClassKey {}
// }

export const mpmRed = '#EB002D';
export const mpmPlum1 = '#5a0037';
export const mpmBlue = '#E6F0FA';
export const iceBlue = '#dbe0e5';
export const successColor = '#0F9B5F';
export const warningColor = '#FF7800';
export const errorColor = '#EB002D';
export const inputBorderColor = '#c8cfd6';
export const lighterGray = '#f0f0f0';
export const fontFamilyBold = 'Gilroy-Bold';
export const fontFamilySemiBold = 'Gilroy-SemiBold';
export const fontFamilyMedium= 'Gilroy-Medium';
export const textColorMap = {
  gray1: '#96A3B0',
  gray2: '#68798A',
  gray3: '#3C4650',
  gray4: '#2D353D',
  gray5: '#1F2429',
  gray6: '#101316'
};

const defaultTheme = createTheme();

export const muiMarkantTheme = createTheme({
  palette: {
    background: {
      default: mpmBlue
    },
    primary: {
      main: mpmRed
    },
    secondary: {
      main: mpmPlum1
    },
    error: {
      main: mpmRed
    },
    text: {
      primary: mpmPlum1
    }
  },
  typography: {
    fontFamily: 'Gilroy-Regular, Arial, Open Sans, sans-serif',
    allVariants: {
      color: textColorMap.gray6
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: mpmPlum1,
          '&.MuiAutocomplete-paper .MuiCheckbox-root': {
            paddingLeft: '0',
            paddingTop: '2px',
            paddingBottom: '2px'
          }
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '30px',
          textTransform: 'capitalize',
          '&$textSizeSmall': {
            paddingLeft: defaultTheme.spacing(1),
            paddingRight: defaultTheme.spacing(1)
          }
        }
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: textColorMap.gray2,
          '&$focused': {
            color: mpmPlum1
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: mpmBlue,
            '&:hover': {
              backgroundColor: mpmBlue
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: mpmRed,
          },
          '&:hover': {
            backgroundColor: mpmBlue
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: textColorMap.gray6
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: mpmPlum1
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&.MuiFilledInput-underline:after': {
            borderBottomColor: mpmPlum1
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: inputBorderColor
          },
          '&:after': {
            borderBottomColor: mpmPlum1
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: textColorMap.gray6
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: mpmPlum1
          }
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-groupLabel': {
            color: mpmPlum1,
            fontFamily: fontFamilyBold
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transition: 'all .3s',
          '&$colorSecondary': {
            color: mpmPlum1
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: lighterGray
        }
      }
    },
    // @TODO old picker, need to be overwritten against mui picker
    // MuiPickersToolbar: {
    //   toolbar: {
    //     backgroundColor: mpmBlue,
    //     borderRadius: '36px',
    //     justifyContent: 'space-between',
    //     minHeight: '50px',
    //     height: '50px',
    //     '& .MuiButtonBase-root': {
    //       padding: '4px 12px'
    //     },
    //     '& .MuiTypography-root': {
    //       fontSize: '1rem',
    //       fontFamily: fontFamilySemiBold
    //     },
    //     '&.MuiPickersDatePickerRoot-toolbar': {
    //       flexDirection: 'row',
    //       alignItems: 'center',
    //     },
    //     '& .MuiPickersToolbarText-toolbarBtnSelected': {
    //       color: textColorMap.gray6
    //     },
    //     '& .MuiPickersToolbarText-toolbarTxt': {
    //       color: textColorMap.gray6
    //     },
    //     '& .MuiButtonBase-root:hover': {
    //       backgroundColor: 'rgba(255, 255, 255, .4)'
    //     }
    //   }
    // },
    // MuiPickersCalendarHeader: {
    //   transitionContainer: {
    //     '& .MuiTypography-root': {
    //       color: textColorMap.gray6,
    //       fontFamily: fontFamilyMedium
    //     }
    //   },
    //   iconButton: {
    //     '& .MuiSvgIcon-root': {
    //       border: '1px solid #5a0037',
    //       borderRadius: '50%',
    //       color: '#5a0037',
    //     },
    //     '&.Mui-disabled .MuiSvgIcon-root': {
    //       borderColor: iceBlue,
    //       color: iceBlue,
    //     }
    //   },
    //   dayLabel: {
    //     color: textColorMap.gray1,
    //     fontFamily: fontFamilyMedium
    //   }
    // },
    // MuiPickersDay: {
    //   day: {
    //     color: textColorMap.gray6,
    //     '& .MuiTypography-body2': {
    //       fontFamily: fontFamilyMedium
    //     }
    //   },
    //   dayDisabled: {
    //     color: textColorMap.gray1,
    //   },
    //   current: {
    //     backgroundColor: '#fff',
    //     border: `1px solid ${mpmRed}`,
    //     color: mpmRed
    //   },
    // },
    // MuiPickersYear: {
    //   root: {
    //     color: textColorMap.gray6
    //   },
    //   yearSelected: {
    //     color: mpmRed
    //   }
    // },
    // some old styling of chips, from mock age
    // MuiChip: {
    //   styleOverrides: {
    //     root: {
    //       border: '1px solid #e0e3e6',
    //       backgroundColor: '#e0e3e6',
    //       color: textColorMap.gray3,
    //       fontFamily: fontFamilySemiBold,
    //     },
    //     deleteIcon: {
    //       styleOverrides: {
    //         color: textColorMap.gray3,
    //         '&:hover': {
    //           color: textColorMap.gray6
    //         },
    //         '&:focus': {
    //           color: textColorMap.gray6
    //         }
    //       }
    //     },
    //     outlined: {
    //       border: '1px solid #e2e3e5'
    //     }
    //   }
    // },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: textColorMap.gray3
        }
      }
    }
  }
});

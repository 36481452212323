/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {CellValueChangedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Checkbox} from '@mui/material';
import {paths} from 'paths';
import {API_LITERALS, API_STATUSES} from 'config/api/constants';
import {literalObject} from 'shared/models/literal.model';
import {dictionaryTranslationRow} from 'shared/models/dictionaries.model';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable from 'components/AgTable/AgTable';
import {dictionary2arrayX} from 'modules/Dictionaries/Literals/Literal';

const literalTemplate: literalObject = {
    id: '',
    active: true,
    translations: {en: ''}
};

const LiteralCreate = ({history}) => {
    const translate = useFormatMessage();
    const literalsApiPost = useApi('post', {id: null}, {succMsg: 'literals.succCreate', errMsg: 'literals.errCreate'});

    const [selectedLiteral, setSelectedLiteral] = useState<literalObject>(literalTemplate);
    const [lock4edit, setLock4edit] = useState<boolean>(false);
    const [selectedLiteralData, setSelectedLiteralData] = useState<dictionaryTranslationRow[]>(dictionary2arrayX({en: ''}));

    useEffect(() => {
        if (literalsApiPost.status === API_STATUSES.IDLE) {
            history.push(`${paths.literal}?id=${literalsApiPost.data.id}`)
        }
    }, [literalsApiPost.status]);


    const dictElemColumnDefs: ColDef[] = [
        {field: 'lang', headerName: translate({id: 'dictionaryElement.lang'}), cellRenderer: (params) => translate({id: params.value})},
        {field: 'trans', headerName: translate({id: 'dictionaryElement.trans'}), flex: 1, editable: true,
            tooltipValueGetter: () => translate({id: 'dictionaryElement.info'}),
            cellClass: (params) => params.data.lang === 'en' && !params.value ? 'required-cell' : ''
        }
    ];

    const handleCellChange = (event: CellValueChangedEvent) => {
        const newData: dictionaryTranslationRow[] = [...selectedLiteralData];
        newData[event.rowIndex] = event.data;
        setSelectedLiteralData(newData);
    };

    const handleSaveClick = () => {
        const saveObject: literalObject = {
            active: selectedLiteral.active,
            translations: {en: ''}
        };
        selectedLiteralData.forEach((item) => {
            saveObject.translations[item.lang] = item.trans;
        });
        literalsApiPost.call(API_LITERALS, saveObject);
    };

    const handleActiveChange = (val: boolean) => {
        const newLiteral: literalObject = {...selectedLiteral};
        newLiteral.active = val;
        setSelectedLiteral(newLiteral);
    };

    const cantSave = (): boolean => lock4edit || selectedLiteralData.find((item) => item.lang === 'en' && item.trans === '') !== undefined;

    return (
        <div className="viewRoot franchiseOwnerRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <PaperX className="weldBottom">
                        <FormattedMessage id="a.active"/><Checkbox checked={selectedLiteral.active} color="primary" onChange={(e) => handleActiveChange(e.target.checked)}/>
                    </PaperX>
                    <PaperX className="_fullHeight _fullTable weldTop">
                        <AgTable
                            rowData={selectedLiteralData}
                            defaultColDef={{
                                suppressMenu: true,
                                suppressMovable: true
                            }}
                            columnDefs={dictElemColumnDefs}
                            onCellValueChanged={handleCellChange}
                            onCellEditingStarted={() => setLock4edit(true)}
                            onCellEditingStopped={() => setLock4edit(false)}
                            tooltipShowDelay={1000}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                        <Link to={paths.literal}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default LiteralCreate;
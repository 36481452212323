import axios from 'axios';
import { API_STATUSES, API_BANNER_AND_RETAILER } from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {
    bannersWithRetailerReducerModel,
    bannersWithRetailerResponseModel,
    bannerWithRetailer
} from 'shared/models/banner.model';

enum ACTION_TYPES {
    START_FETCHING_BANNERS= 'START_FETCHING_BANNERS',
    ON_SUCCESS_BANNERS = 'ON_SUCCESS_BANNERS',
    ON_ERROR_BANNERS = 'ON_ERROR_BANNERS'
}

const sortBannersByName = (banners: bannersWithRetailerResponseModel): bannersWithRetailerResponseModel =>
    banners.sort((a, b) => a.bannerName.toLowerCase() < b.bannerName.toLowerCase() ? -1 : 1);

export function fetchBannersWithRetailers () {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_BANNERS
        });
        axios.get(API_BANNER_AND_RETAILER).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_BANNERS,
                    payload: {data: sortBannersByName(resp.data)}
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_BANNERS});
            }
        }).catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_BANNERS})
        })
    }
}

export function isBannerInCountry(bannerId: string, countryId: string, bannersData: bannersWithRetailerResponseModel): boolean {
    const foundBanner: bannerWithRetailer = bannersData?.find((item) => item.bannerId === bannerId);
    if (foundBanner?.countryId === countryId) {
        return true;
    }
    return false;
}

const initialState: bannersWithRetailerReducerModel = {
    status: API_STATUSES.NEW,
    data: []
};

export type BannersWithRetailerState = Readonly<typeof initialState>;

const bannersWithRetailerReducer = (state: BannersWithRetailerState = initialState, action): BannersWithRetailerState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_BANNERS:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_BANNERS:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_BANNERS:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default bannersWithRetailerReducer;
import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import {API_GET_FILTERED_PRODUCT_DESCRIPTIONS} from 'config/api/constants';
import {productDescriptionResponseModel} from 'shared/models/productDescription.model';
import {descriptionFilters} from 'shared/models/description.model';
import {categorySelectorOption} from 'shared/models/category.model';
import {responseValidation} from 'utils/responseValidation';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {ButtonClose} from 'components/Buttons';
import DescriptionsFilterSimple from 'components/DescriptionFilter/DescriptionsFilterSimple';
import {ProductDescriptionListTable} from 'modules/MasterData/ProductDescription/ProductDescriptionList';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';

interface SelectDescriptionDialogProps {
    category: categorySelectorOption,
    isDescribingFrame: boolean,
    lockBrick?: boolean,
    marketId: string,
    onClose: () => void,
    onSelect: (id: string) => void
    open: boolean,
    showNav?: boolean
}

const SelectDescriptionDialog = ({category, isDescribingFrame, lockBrick, marketId, onClose, onSelect, open, showNav}: SelectDescriptionDialogProps) => {
    const cancelToken = useRef(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [productDescriptionData, setProductDescriptionData] = useState<productDescriptionResponseModel[]>([]);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const getData = (filters: descriptionFilters) => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current();
        setProductDescriptionData([]);
        axios.get<productDescriptionResponseModel[]>(API_GET_FILTERED_PRODUCT_DESCRIPTIONS, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setProductDescriptionData(response.data);
                }
            })
            .catch((e) => {
                if(!e?.__CANCEL__) {
                    console.log(e);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleFiltersChange = (filters: descriptionFilters) => {
        if (!filters) return;
        if (Object.keys(filters).length > 2 && filters.marketId) {
            getData(filters);
        }
    };

    return (
        <Dialog className={`${isDescribingFrame ? '_dialogOnDescribeFrameView _dialog-likeView' : '_dialog-likeView'}`} open={open} fullScreen>
            <DialogContent className="_directionCol">
                <LoadingOverlay show={isLoading}/>
                <PaperX>
                    <DescriptionsFilterSimple category={category}
                                              isDescribingFrame={isDescribingFrame}
                                              lockBrick={lockBrick}
                                              marketId={marketId}
                                              onFilterChange={(filters) => handleFiltersChange(filters)}/>
                </PaperX>
                <PaperX className="_fullHeight _fullWidth _fullTable">
                    <ProductDescriptionListTable data={productDescriptionData}
                                                 onRowClick={(descriptionId) => onSelect(descriptionId)}/>
                </PaperX>
            </DialogContent>
            <DialogActions>
                {showNav && (
                    <DialogAuxiliaryActions>
                        <NavigationMenuButton/>
                    </DialogAuxiliaryActions>
                )}
                <ButtonClose onClick={() => onClose()}/>
            </DialogActions>
        </Dialog>
    );
};

export default SelectDescriptionDialog;
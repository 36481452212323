import {FormattedMessage} from 'react-intl';
import AgTable from 'components/AgTable/AgTable';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {PaperX} from 'components/PaperX';
import {productSearchTableRow} from 'modules/MasterData/Product/ProductsListSearchResults';
import {MainAttributesTooltip} from 'modules/MasterData/Product/Tooltips';

interface DuplicateFoundDialogProps {
    data: productSearchTableRow[],
    onClose: () => void,
    onConfirmDuplicates: () => void,
    open: boolean
}

const DuplicateFoundDialog = ({data, onClose, onConfirmDuplicates, open}: DuplicateFoundDialogProps) => {
    const translate = useFormatMessage();

    const columnDefs: ColDef[] = [
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            tooltipField: 'characteristics',
            tooltipComponent: MainAttributesTooltip
        },
        { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1}
    ];

    return (
        <Dialog open={open} fullScreen>
            <DialogTitle>
                <FormattedMessage id="product.bulkDuplicates" values={{count: data.length}}/>
            </DialogTitle>
            <DialogContent>
                <PaperX className="_fullHeight _fullTable _scrollY">
                    <AgTable
                        rowData={data}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            suppressMenu: true
                        }}
                        tooltipShowDelay={0}
                        columnDefs={columnDefs}
                    />
                </PaperX>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onConfirmDuplicates}><FormattedMessage id="a.confirm"/></Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.cancel"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default DuplicateFoundDialog;
import {Checkbox} from '@mui/material';
import {dictionaryElement} from 'shared/models/dictionaries.model';

interface DictionaryElementCellProps {
    dictEl: dictionaryElement,
    lang: string
}

export function DictionaryElementCell ({dictEl, lang}: DictionaryElementCellProps) {
    return (
        <div>
            <div>
                {dictEl.translations[lang]}
            </div>
            {dictEl.alternatives ?
            <div>
                {dictEl.alternatives[lang]}
            </div> : null}
        </div>
    )
}

interface ActiveFlagCellProps {
    disabled: boolean,
    value: boolean
}

export function ActiveFlagCell ({value, disabled}: ActiveFlagCellProps) {
    if (value) return (<Checkbox checked={true} color="primary" disabled={disabled}/>);
    if (value === false) return (<Checkbox color="primary" disabled={disabled}/>);
    return null;
}
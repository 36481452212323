/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Button, Checkbox} from '@mui/material';
import {paths} from 'paths';
import {API_ATTRIBUTES_SEARCH} from 'config/api/constants';
import {getId} from 'utils/routing';
import {basic} from 'shared/models/_common.model';
import {attributeResponseModel} from 'shared/models/attribute.model';
import {IRootState} from 'shared/reducers';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import Search from 'components/Search/Search';
import {LoadingOverlay} from 'components/LoadingOverlay';
import SummaryCard from 'components/Cards/SummaryCard';
import {useAttributeQuery} from 'components/Attribute/attributeAPI';

export enum attrTypes {
    ENUMERATION = 'ENUMERATION',
    NUMERICAL = 'NUMERICAL',
    RANGE = 'RANGE',
    FREETEXT = 'FREETEXT',
    BRICK_DEPENDENT_ENUMERATION = 'BRICK_DEPENDENT_ENUMERATION'
}

export const attrTypeLabels = {
    ENUMERATION: 'attributes.typeSet',
    NUMERICAL: 'attributes.typeNumUnit',
    RANGE: 'attributes.typeRangeUnit',
    FREETEXT: 'attributes.typeTxt',
    BRICK_DEPENDENT_ENUMERATION: 'attributes.typeBrickDependentSet'
};

export type attributeSummaryCardRow = {
    label: string,
    value: string | React.ReactNode,
    optionalClass?: string
}

export const buildAttributeSummaryCard = (attr: attributeResponseModel): attributeSummaryCardRow[] => {
    const content: attributeSummaryCardRow[] = [
        {label: 'attributes.name', value: attr.name},
        {label: 'a.active', value: <Checkbox checked={attr.active} disabled/>, optionalClass: 'checkbox'},
        {label: 'attributes.type', value: <FormattedMessage id={attrTypeLabels[attr.type]}/>}
    ];
    if (attr.type === attrTypes.ENUMERATION) {
        if(attr.literals && attr.literals.length) {
            content.push({label: 'literals.label', value: attr.literals.map((item) => item.name).join(', ')})
        }
    }
    if (attr.type === attrTypes.NUMERICAL || attr.type === attrTypes.RANGE) {
        if (attr.dictionaries && attr.dictionaries.length) {
            content.push({label: 'attributes.selectedUnits', value: attr.dictionaries.map((item) => item.name).join(', ')})
        }
    }
    return content;
};

const AttributesBrowser = () => {
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const [selectedAttributeSlim, setSelectedAttributeSlim] = useState<basic>(null);
    const [selectedAttribute, setSelectedAttribute] = useState<attributeResponseModel>(null);
    const [cardContent, setCardContent] = useState<attributeSummaryCardRow[]>([]);

    const attributeQuery = useAttributeQuery(selectedAttributeSlim?.id, lang);

    useEffect(() => {
        const literalId: string = getId();
        if (literalId) {
            setSelectedAttributeSlim({id: literalId, name: ''});
        }
    }, []);

    useEffect(() => {
        if (attributeQuery.data) {
            // @TODO currently DB is populated with 'null' active flag, we treat it as true, unless user explicitly change it to false
            const attribute: attributeResponseModel = {...attributeQuery.data};
            if (attribute.active === null) attribute.active = true;
            setSelectedAttribute(attribute);
        }
    }, [attributeQuery.data]);

    useEffect(() => {
        if (selectedAttribute) {
            setCardContent(buildAttributeSummaryCard(selectedAttribute));
        }
    }, [selectedAttribute]);

    const handleSearchSelectionChange = (attribute: basic) => setSelectedAttributeSlim(attribute); // changing selectedAttributeSlim triggers attributeQuery

    return (
        <div className="viewRoot franchiseOwnerRoot">
            <div className="viewport">
                <LoadingOverlay show={attributeQuery.isLoading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div className="_halfWidth">
                            <Search onSelectionChange={handleSearchSelectionChange} searchUrl={API_ATTRIBUTES_SEARCH} label="attributes.label"/>
                        </div>
                    </PaperX>
                    <Link to={`${paths.attributeCreate}?id=${selectedAttribute ? selectedAttribute.id : ''}`}>
                        <div className="_halfWidth">
                            {selectedAttribute && cardContent && <SummaryCard content={cardContent}/>}
                        </div>
                    </Link>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={paths.attributeCreate}>
                            <Button variant="contained" color="primary"><FormattedMessage id="attributes.new"/></Button>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default AttributesBrowser;
import './AttributeHelpers.scss';

import {FormattedMessage} from 'react-intl';
import {CircularProgress} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {slimAttributeResponseModel} from 'shared/models/attribute.model';
import {handyAttributes} from 'shared/handyAttributes';
import {attrTypes} from 'modules/Dictionaries/Attributes/Attribute';

interface AttributeErrorProps {
    errorLabel?: string,
    textmode?: boolean
}

const AttributeError = (props:AttributeErrorProps) => {
    if (props.textmode) {
        return <FormattedMessage id={props.errorLabel || 'masterData.attributeError'}/>;
    }
    return (
        <div className="attributeErrorRoot">
            <WarningIcon/>
            &nbsp;
            <FormattedMessage id={props.errorLabel || 'masterData.attributeError'}/>
        </div>
    );
};

type attributeLoadingProps = {
    textmode?: boolean
}

const AttributeLoading = (props: attributeLoadingProps) => {
    if (props.textmode) {
        return <><FormattedMessage id="a.loading"/>...</>;
    }
    return (
        <div className="attributeLoadingRoot">
            <CircularProgress size={20}/>
            &nbsp;
            <FormattedMessage id="a.loading"/>
        </div>
    );
};
export {AttributeError, AttributeLoading};

export function isAttributeMandatory(attribute: slimAttributeResponseModel): boolean {
    const at: attrTypes = attribute.type;
    return at === attrTypes.ENUMERATION || at === attrTypes.BRICK_DEPENDENT_ENUMERATION || attribute.id === handyAttributes.contentOfTradingUnit;
}

/* eslint-disable react-hooks/exhaustive-deps */
import './BranchesSelector.scss';

import {useEffect, useState} from 'react';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {GridOptions, SelectionChangedEvent} from 'ag-grid-community';
import AgTable, {defaultColCfgWithSearch} from 'components/AgTable/AgTable';
import {PaperX} from 'components/PaperX';
import {branch, branchWithSelection} from 'shared/models/branch.model';
import {useFormatMessage} from 'utils/translate';

enum BRANCHES_SELECTOR_ACTIONS {'select', 'deselect'}

type branchesSelectorProps = {
    branches: branch[],
    selectedBranches: string[],
    onSelectionChange: (branchIds: string[]) => void,
    columnDefs?: ColDef[],
    leftTableProps?: GridOptions
    rightTableProps?: GridOptions
}

const BranchesSelector = (props:branchesSelectorProps) => {
  const translate = useFormatMessage();

  const [branches, setBranches] = useState<branch[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<string[]>([]);
  const [selectionModel, setSelectionModel] = useState<branchWithSelection[]>([]);

  const [externalColumnDefs, setExternalColumnDefs] = useState(null);

  useEffect(() => setBranches(props.branches), [props.branches]);

  useEffect(() => setSelectedBranches(props.selectedBranches), [props.selectedBranches]);

  useEffect(() => {
    setSelectionModel(branches.map((branch) => ({...branch, selected: selectedBranches.find((id) => id === branch.id) ? true : false})));
  }, [branches, selectedBranches]);

  const selectColumn: ColDef = { width: 40, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: false, headerTooltip : translate({ id: 'a.selectAll' }),
      floatingFilter: false};

  const defaultColumnDefs: ColDef[] = [
      selectColumn,
      { field: 'postCode', headerName: translate({ id: 'b.postCode' }), maxWidth: 100, flex: 1 },
      { field: 'city', headerName: translate({ id: 'b.city' }), flex: 1},
      { field: 'street', headerName: translate({ id: 'b.street' }), flex: 1 },
      { field: 'houseNumber', headerName: translate({ id: 'b.houseNumber' }), headerTooltip : translate({ id: 'b.houseNumber' }), width: 100 },
      { field: 'regionName', headerName: translate({ id: 'b.region'}), flex: 1},
      { field: 'nielsenRegionName', headerName: translate({ id: 'b.nielsenRegion'}), headerTooltip : translate({ id: 'b.nielsenRegion' }), width: 100 }
  ];

  useEffect(() => {
      if (props.columnDefs) {
          setExternalColumnDefs([selectColumn].concat(props.columnDefs));
      }
  }, [props.columnDefs]);

  const handleSelectionChange = (event: SelectionChangedEvent, type: BRANCHES_SELECTOR_ACTIONS) => {
      let returnSelection: string[] = [];
      const newSelection: string[] = event.api.getSelectedNodes().map((node) => node.data.id);
      if (type === BRANCHES_SELECTOR_ACTIONS.select) {
          returnSelection = [...selectedBranches].concat(newSelection);
      } else {
          returnSelection= [...selectedBranches].filter((existingId) => !newSelection.includes(existingId)) ;
      }
      props.onSelectionChange(returnSelection);
  };

  return (
      <PaperX className="branchesSelectorRoot _fullHeight">
          <div className="_directionCol">
              <div className="headers">
                  <span>{translate({id: 'branchesSelector.avail'})}</span>
                  <span>{`${translate({id: 'branchesSelector.selected'})} (${selectedBranches.length} ${translate({id: 'a.of'})} ${branches.length})`}</span>
              </div>
              <div className="_directionRow">
                  <AgTable
                      rowData={selectionModel.filter((branch) => branch.selected === false)}
                      rowSelection={'single'}
                      defaultColDef={{...defaultColCfgWithSearch, suppressMovable: true}}
                      columnDefs={externalColumnDefs ? externalColumnDefs : defaultColumnDefs}
                      onSelectionChanged={(e: SelectionChangedEvent) => handleSelectionChange(e, BRANCHES_SELECTOR_ACTIONS.select)}
                      {...props.leftTableProps || null}
                  />
                  <AgTable
                      rowSelection={'single'}
                      rowData={selectionModel.filter((branch) => branch.selected === true)}
                      defaultColDef={{...defaultColCfgWithSearch, suppressMovable: true}}
                      columnDefs={externalColumnDefs ? externalColumnDefs : defaultColumnDefs}
                      onSelectionChanged={(e: SelectionChangedEvent) => handleSelectionChange(e, BRANCHES_SELECTOR_ACTIONS.deselect)}
                      {...props.rightTableProps || null}
                  />
              </div>
          </div>
      </PaperX>);
};

export default BranchesSelector;
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {API_PRODUCT_REPLACE} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import AgTable from 'components/AgTable/AgTable';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface ProductReplacementConfirmationDialogProps {
    columnDefs: ColDef[],
    data: string[][],
    onClose: () => void,
    onReplaceSuccess: () => void,
    open: boolean,
    productToReplaceId: string,
    replaceWithProductId: string
}

const ProductReplacementConfirmationDialog = ({columnDefs, data, onClose, onReplaceSuccess, open, productToReplaceId, replaceWithProductId}: ProductReplacementConfirmationDialogProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleReplace = () => {
        setIsLoading(true);
        axios.patch(API_PRODUCT_REPLACE(productToReplaceId), {replacementProductId: replaceWithProductId})
            .then(() => {
                setIsLoading(false);
                onReplaceSuccess();
            })
            .catch((e) => {
                console.log(e)
                setIsLoading(false);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            });
    };

    return (
        <Dialog className="_dialog-likeView" open={open} onClose={onClose} fullScreen>
            <LoadingOverlay show={isLoading}/>
            <DialogContent className="_directionCol">
                <PaperX>
                    <h2>
                        <FormattedMessage id="product.confirmReplace"/>
                    </h2>
                </PaperX>
                <PaperX className="_fullWidth _fullHeight _fullTable">
                    <AgTable
                        rowData={data}
                        defaultColDef={{
                            suppressMenu: true,
                            wrapText: true,
                            autoHeight: true
                        }}
                        columnDefs={columnDefs}
                    />
                </PaperX>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleReplace}><FormattedMessage id="a.confirm"/></Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.cancel"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductReplacementConfirmationDialog;
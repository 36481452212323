import {frameTemplate} from 'shared/models/frame.model';
import {getNumberFromLocalStorage} from 'utils/storageUtils';

export enum ACTION_TYPES {
  STORE_VALUE = 'STORE_VALUE',
}

const storeAsInt = (value) => parseInt(value);
const storeAsFloat = (value) => parseFloat(value);
const storeAsObject = (value) => JSON.parse(value);
const storeAsString = (value) => value;

const watchedEntries = new Set([
    'forDifferentView',
    'stringSample',
    'intSample',
    'floatSample',
    'objectSample',
    'leafletBrowserTest',
    'leafletBrowserFullScreen',
    'describeFrameLeafletBrowser_leafletId',
    'describeFrameLeafletBrowser_frame',
    'describeFrameLeafletBrowser_pageNumber'
]);

const setters = {
  forDifferentView: storeAsString,
  stringSample: storeAsString,
  intSample: storeAsInt,
  floatSample: storeAsFloat,
  objectSample: storeAsObject,
  leafletBrowserTest: storeAsObject,
  leafletBrowserFullScreen: storeAsString,
  describeFrameLeafletBrowser_leafletId: storeAsString,
  describeFrameLeafletBrowser_frame: storeAsObject,
  describeFrameLeafletBrowser_pageNumber: storeAsInt
};

const initialState = {
  stringSample: localStorage.getItem('stringSample') || '',
  intSample: 0,
  floatSample: 0,
  objectSample: {},
  leafletBrowserTest: {},
  leafletBrowserFullScreen: '',
  describeFrameLeafletBrowser_leafletId: '',
  describeFrameLeafletBrowser_frame: frameTemplate,
  describeFrameLeafletBrowser_pageNumber: getNumberFromLocalStorage('describeFrameLeafletBrowser_pageNumber') || 0
};

export type StoragePileState = Readonly<typeof initialState>;

export const storeValue = (key: string, value: string) => {
  return {
    type: ACTION_TYPES.STORE_VALUE,
    payload: {key, value}
  };
};

const storagePileReducer = (state: StoragePileState = initialState, action): StoragePileState => {
  switch (action.type) {
    case ACTION_TYPES.STORE_VALUE:
      const {key, value} = action.payload;
      if (watchedEntries.has(key)) {
        let newState = {...state};// @TODO clonedeep here?
        // @TODO maybe should compare if value is actually different than original one
        newState[key] = setters[key](value);
        return newState;
      }
      return  state;
    default:
      return state;
  }
};

export default storagePileReducer;
// descriptions filter variant: no storage, fixed market, used in productDetails
/* eslint-disable react-hooks/exhaustive-deps */
import './CommonDescriptionsFilterStyles.scss';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {categorySelectorOption, categoryTableItem, categoryType} from 'shared/models/category.model';
import {descriptionFilters} from 'shared/models/description.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import useDebounce from 'utils/debounce';
import MarketDisplay from 'components/Displays/MarketDisplay';
import {CategoryTurboSearch} from 'modules/MasterData/Category';
import {buildFiltersTemplate, sanitizeDescriptionsFilters} from './DescriptionsFilter';

interface DescriptionsFilterSimpleProps {
    category: categorySelectorOption,
    isDescribingFrame: boolean,
    lockBrick?: boolean,
    marketId: string,
    onFilterChange: (filters: descriptionFilters) => void,
}

const DescriptionsFilterSimple = ({category, isDescribingFrame, lockBrick, marketId, onFilterChange}: DescriptionsFilterSimpleProps) => {
    const translate = useFormatMessage();

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [brick, setBrick] = useState<categorySelectorOption>(null);
    const [filters, setFilters] = useState<descriptionFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then

    const handleSearchInputChange = (val: string) => setFilters({...filters, description: val});

    const handleCategoryChange = (categoryRow: categoryTableItem) => {
        if (categoryRow === null) {
            setFilters({...filters, brickId: null});
            setBrick({
                id: '',
                name: '',
                type: null
            });
        } else {
            setFilters({...filters, brickId: categoryRow.bId});
            setBrick({
                id: categoryRow.bId,
                name: categoryRow.bName,
                type: categoryType.BRICK
            });
        }
    };

    useEffect(() => {
        if (category) {
            setBrick(category);
        }
        setFilters(buildFiltersTemplate(marketId, userProfile.langData, category));
    }, []);
    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);

    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeDescriptionsFilters(dbncdFilters);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
            }
        }
    }, [dbncdFilters]);

    return !filters ? null : (
        <div className="descriptionsFilterRoot">
            <div className="left">
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           value={filters.description || ''}
                           fullWidth
                           label={translate({id: 'b.descr'})}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )
                           }}/>
            </div>
            {lockBrick ?
                <div className="mid simple stretch">
                    <span><span className="_bold">{translate({id: 'b.categoryBrick'})}:</span> {brick?.name}</span>
                </div>
                :
                <div className="mid simple stretch">
                    <CategoryTurboSearch onCategorySelect={(categoryRow) => handleCategoryChange(categoryRow)}
                                         onFrameDescriptionView={isDescribingFrame}
                                         remoteSearch
                                         customLauncher={
                                             <TextField label={`${translate({id: 'b.categoryBrick'})}:`}
                                                        variant="outlined"
                                                        value={brick?.name}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon/>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                             />
                                         }
                    />
                    <IconButton onClick={() => handleCategoryChange(null)}>
                        <ClearIcon/>
                    </IconButton>
                </div>
            }
            <div className="right centered">
                <MarketDisplay marketId={marketId}/>
            </div>
        </div>
    );
};

export default DescriptionsFilterSimple;
import './MasterDataCard.scss';

import {Paper} from '@mui/material';
import {useFormatMessage} from 'utils/translate';

interface MasterDataCardProps {
    text: string
}

const MasterDataCard = ({text}: MasterDataCardProps) => {
    const translate = useFormatMessage();
    return (
        <Paper className="masterDataCard_root" elevation={0}>
            {translate({id: text})}
        </Paper>
    );
};

export default MasterDataCard;
import {isAttributeValid} from 'components/Attribute';
import {productDetailsResponseModel, productPayload, UIProductModel} from 'shared/models/product.model';
import {categoryType} from 'shared/models/category.model';

export const transformForBE = (UIModel, categoryCharacteristics): productPayload => {
    // @TODO need to refactor typing, issues start on characteristics model, they're any, so sometimes comes [], sometimes {}, and are not quite compatible with type: characteristicPayload
    const BEModel = {
        active: UIModel.active,
        brandId: UIModel.brand ? UIModel.brand.id : '',
        productDescriptionId: UIModel.productDescriptionId,
        characteristics: [],
        gtins: UIModel.gtins,
        marketId: UIModel.marketId,
        supplierId: UIModel.supplierId || null
    };
    const combinedCharacteristics = {...UIModel.characteristics};
    for (let id in categoryCharacteristics){
        if (!combinedCharacteristics[id]) {
            combinedCharacteristics[id] = categoryCharacteristics[id];
        }
    }
    for (const id in combinedCharacteristics) {
        const characteristic = {
            attributeId: id
        };
        if (combinedCharacteristics[id]) {
            for (const prop in combinedCharacteristics[id]) {
                if (prop !== 'attributeName' && prop !== 'unitName') { //excluding UI-model-specific properties
                    characteristic[prop] = combinedCharacteristics[id][prop];
                }
            }
            if (isAttributeValid(characteristic)) {
                BEModel.characteristics.push(characteristic);
            }
        }
    }
    return BEModel;
};

export const transformForUI = (BEModel: productDetailsResponseModel): UIProductModel => {
    if (!BEModel) {
        return null;
    }

    const UIModel: UIProductModel = {
        active: BEModel.active,
        alternativeDescriptions: BEModel.alternativeDescriptions,
        brand: null,
        characteristics: [],
        category: {
            id: BEModel.categoryBrickId,
            name: BEModel.categoryBrickName,
            type: categoryType.BRICK},
        comments: BEModel.comments,
        gtins: BEModel.gtins,
        id: BEModel.id,
        mainDescriptions: BEModel.mainDescriptions,
        marketId: BEModel.marketId,
        productDescriptionId: BEModel.productDescriptionId,
        replacementProductId: BEModel.replacementProductId,
        supplierId: BEModel.supplierId,
        supplierName: BEModel.supplierName
    };
    if (BEModel.brandId) {
        UIModel.brand = {
            id: BEModel.brandId,
            name: BEModel.brandName,
        };
    }
    BEModel.characteristics.forEach((item) => {
        UIModel.characteristics[item.attributeId] = item;
    });
    return UIModel;
};
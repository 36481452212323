/* eslint-disable react-hooks/exhaustive-deps */
import './CommonDescriptionsFilterStyles.scss';

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {categorySelectorOption, categoryType} from 'shared/models/category.model';
import {descriptionFilters} from 'shared/models/description.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import useDebounce from 'utils/debounce';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {MarketSelector} from 'components/Selectors';
import {CategorySearch} from 'modules/MasterData/ProductCategories';

export const sanitizeDescriptionsFilters = (filters: descriptionFilters): descriptionFilters => {
    const saneFilters: descriptionFilters = {};
    if (filters.description && filters.description.length > 2) saneFilters.description = filters.description;
    if (filters.brickId) saneFilters.brickId = filters.brickId;
    if (filters.lang) saneFilters.lang = filters.lang;
    if (filters.active) saneFilters.active = filters.active;
    if (filters.marketId) saneFilters.marketId = filters.marketId;
    return Object.keys(saneFilters).length === 0 ? null : saneFilters;
};

interface DescriptionsFilterProps {
    forceReloadCounter?: number
    onFilterChange: (filters: descriptionFilters) => void,
    storageKey: string,
    storageKey4Brick?: string
}


export const buildFiltersTemplate = (marketId: string, lang: string, category?: categorySelectorOption): descriptionFilters => {
    return {
        active: true,
        brickId: category ? category.id : '',
        description: '',
        lang,
        marketId
    };
};

const DescriptionsFilter = (props: DescriptionsFilterProps) => {
    const translate = useFormatMessage();
    const {forceReloadCounter, onFilterChange, storageKey, storageKey4Brick} = props;

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [brick, setBrick] = useState<categorySelectorOption>(null);
    const [filters, setFilters] = useState<descriptionFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then
    const showBrickSelector = useRef<boolean>(!!storageKey4Brick);

    const handleSearchInputChange = (val: string) => setFilters({...filters, description: val});
    const handleCategoryChange = (val: categorySelectorOption) => {
        setFilters({...filters, brickId: val.id});
        setBrick(val);
        if (storageKey4Brick) {
            storeInLocalStorage(storageKey4Brick, JSON.stringify(val));
        }
    };

    useEffect(() => {
        //@TODO keep full brick element stored...
        const storedFilters: descriptionFilters = JSON.parse(getFromLocalStorage(storageKey));
        if (storedFilters) {
            storedFilters.lang = userProfile.langData;
            storedFilters.marketId = userProfile.countryMarket.market;
            if (storageKey4Brick) {
                const storedBrick: categorySelectorOption = JSON.parse(getFromLocalStorage(storageKey4Brick));
                if (storedBrick) {
                    setBrick(storedBrick);
                } else {
                    storedFilters.brickId = ''; // brick lost, so should remove brick id from filters as well
                }
            } else {
                storedFilters.brickId = ''; // brick lost, so should remove brick id from filters as well
            }
            setFilters(storedFilters);
        } else {
            setFilters(buildFiltersTemplate(userProfile.countryMarket.market || '', userProfile.langData));
        }
    }, []);

    useEffect(() => {
        if (filters && userProfile.countryMarket.market) {
            setFilters({...filters, marketId: userProfile.countryMarket.market});
        }
    }, [userProfile.countryMarket.market]);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);

    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeDescriptionsFilters(dbncdFilters);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
                storeInLocalStorage(storageKey, stringifiedSaneFilters);
            }
        }
    }, [dbncdFilters]);

    useEffect(() => {
        if (forceReloadCounter > 0 && dbncdFilters) {
            onFilterChange(sanitizeDescriptionsFilters(dbncdFilters));
        }
    }, [forceReloadCounter]);

    return !filters ? null : (
        <div className="descriptionsFilterRoot">
            <div className="left">
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           value={filters.description || ''}
                           fullWidth
                           label={translate({id: 'b.descr'})}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )
                           }}/>
            </div>
            {showBrickSelector.current && <div className="mid">
                <CategorySearch onCategorySelect={(brick) => handleCategoryChange(brick)} value={brick} categoryType={categoryType.BRICK} getOnlyIfActiveFlagEquals={true}/>
            </div>}
            <div className="right">
                <MarketSelector/>
            </div>
        </div>
    );
};

export default DescriptionsFilter;
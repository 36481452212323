import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useFormatMessage} from 'utils/translate';

interface ButtonNewProps {
    onClick?: Function,
    label?: string
}

export const ButtonNew = ({label, onClick}: ButtonNewProps) => {
    const translate = useFormatMessage();
    const handleClick = () => onClick ? onClick() : null;
    return (<Button color="primary" variant="contained" onClick={handleClick}>{translate({id: label || 'a.new'})}</Button>);
};

export const ButtonNewWhite = ({label, onClick}: ButtonNewProps) => {
    const translate = useFormatMessage();
    const handleClick = () => onClick ? onClick() : null;
    return (<Button color="primary" variant="outlined" onClick={handleClick} startIcon={<AddIcon />}>{translate({id: label || 'a.new'})}</Button>);
};
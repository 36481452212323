import {useFormatMessage} from 'utils/translate';
import {TextField} from '@mui/material';

interface gtinInputProps {
    disabled?: boolean,
    error: boolean,
    onChange: (index: number, value: string) => void,
    propIdentifier: number,
    value: string
}

const GtinInput = (props: gtinInputProps) => {
    const {onChange, value, propIdentifier, error, disabled} = props;
    const translate = useFormatMessage();

    return <TextField
        InputLabelProps={{
            shrink: true,
        }}
        autoFocus
        value={value}
        disabled={disabled}
        onChange={(e) => onChange(propIdentifier, e.target.value)}
        error={error}
        helperText={error && translate({id: 'product.gtinErr'})}
        label={translate({id: 'b.gtin'})}
        type="text"
    />
};

export default GtinInput;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {CellClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Drawer} from '@mui/material';
import {API_PRODUCT_LOCK} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {shortCharacteristic} from 'shared/models/product.model';
import {releaseProductLock} from 'utils/lockUtils';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable from 'components/AgTable/AgTable';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {productSearchTableRow} from 'modules/MasterData/Product/ProductsListSearchResults';
import ProductReplacementConfirmationDialog from './ProductReplacementConfirmationDialog';

interface ProductReplacementDrawerProps {
    data: productSearchTableRow[],
    id: string,
    onClose: (hasBeenReplaced: boolean) => void
    open: boolean,
}

export function buildCharacteristicsDictionary(data: productSearchTableRow[]): basic[] {
    const characteristicsDictionary: basic[] = [];
    data.forEach((item) => {
        item.characteristics.forEach((characteristic) => {
            if (!characteristicsDictionary.find((z) => z.id === characteristic.id)) {
                characteristicsDictionary.push({id: characteristic.id, name: characteristic.name});
            }
        }
        )
    });
    return characteristicsDictionary;
}

function equalizeCharacteristics(characteristics: shortCharacteristic[], dictionary: basic[]): shortCharacteristic[] {
    const equalCharacteristics: shortCharacteristic[] = [];
    dictionary.forEach((dictItem) => {
        const ownCharacteristic: shortCharacteristic = characteristics.find((charItem) => charItem.id === dictItem.id);
        if (ownCharacteristic) {
            equalCharacteristics.push(ownCharacteristic)
        } else {
            equalCharacteristics.push({...dictItem, value: ''});
        }
    })
    return equalCharacteristics;
}

function flattenCharacteristics(data: productSearchTableRow[]): productSearchTableRow[] {
    return data.map((dataItem) => {
        const newItem = {};
        dataItem.characteristics.forEach((charItem) => {
            newItem[charItem.name] = charItem.value || '';
        })
        return {...dataItem, ...newItem};
    });
}

export function equalizeData (data: productSearchTableRow[], charDict: basic[]): productSearchTableRow[] {
    const equalData: productSearchTableRow[] = data.map((item) => {
        return {
            ...item,
            characteristics: equalizeCharacteristics(item.characteristics, charDict)
        }
    });
    return flattenCharacteristics(equalData);
}

function transposeDataAndMakeColDefs(data: productSearchTableRow[], charDict: basic[], headerName: string): {output: string[][], colDef: ColDef[]} {
    // replace array of objects with array of arrays
    const newDataArray: string[][] = data.map((item) => {
        // get only relevant props from objects
        const relevantProperties: string[] = [item.id, item.brand, item.category, item.description, item.gtin];
        // and add characteristics
        charDict.forEach((dictItem) => {
            relevantProperties.push(item[dictItem.name])
        });
        return relevantProperties;
    });
    // add row with property names (this will become column with property names aft transposition)
    newDataArray.unshift(['id', 'brand', 'category', 'description', 'gtin'].concat(charDict.map((item) => item.name)));
    // transpose
    const output: string[][] = newDataArray[0].map((_, colIndex) => newDataArray.map(row => row[colIndex]));

    const colDef: ColDef[] = [];
    for (let i = 0; i < data.length + 1; i++) { //increasing data length as one more column was added - list of attributes
        colDef.push({ headerName: i - 1 + '', valueGetter: `data[${i}]`, suppressMovable: true});
    }
    colDef[0].pinned = true;
    colDef[0].lockPinned = true;
    colDef[0].suppressMovable = true;
    colDef[0].headerName = '';
    colDef[1].pinned = true;
    colDef[1].lockPinned = true;
    colDef[1].suppressMovable = true;
    colDef[1].headerName = headerName;

    return {output, colDef};
}

const ProductReplacementDrawer = (props: ProductReplacementDrawerProps) => {
    const {data, id, onClose, open} = props;
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [localData, setLocalData] = useState<string[][]>([]);
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

    const [summaryData, setSummaryData] = useState<string[][]>([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [confirmColDefs, setConfirmColDefs] = useState<ColDef[]>([]);
    const [replaceWithProductId, setReplaceWithProductId] = useState<string>(null);

    const cancelToken = useRef(null);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const lockProduct = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);
        axios.post(API_PRODUCT_LOCK(id),null,  {
            cancelToken: new axios.CancelToken(cancel => (cancelToken.current = cancel))
        })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    if (e?.response?.status === 409) {
                        enqueueSnackbar(`${translate({id: 'gtin.productLocked'})}`, {variant: 'error', persist: false});
                    } else {
                        enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    }
                    onClose(false);
                }
            })
            .finally(() => setIsLoading(false))
    };

    useEffect(() => {
        if (open) {
            lockProduct();
            const prod2replace = data.find((item) => item.id === id);
            // filtering products - we don't want to replace with templates
            // from search view we get only active items, but watch on filters view - need to filter also inactive items
            // also removing product that we want to replace - we don't want it doubled in search results; also need to put it in front
            const filteredData: productSearchTableRow[] = data
                .filter((item) => item.isTemplate === false && item.id !== prod2replace.id);
            // push product-to-replace to the beginning of an array
            filteredData.unshift(prod2replace);
            const charDict: basic[] = buildCharacteristicsDictionary(filteredData);
            const equalData: productSearchTableRow[] = equalizeData(filteredData, charDict);
            const dataPresentation = transposeDataAndMakeColDefs(equalData, charDict, translate({id: 'product.toReplace'}));
            setLocalData(dataPresentation.output);
            setColumnDefs(dataPresentation.colDef);
        } else {
            releaseProductLock();
            setLocalData([]);
        }
    }, [open]);

    const handleCellClicked = (e: CellClickedEvent) => {
        const dataIndex: number = parseInt(e.column.getColId());
        // this works bc we're using array of arrays so aggrid automatically set id to index (it's still string though)
        // if array of objects was used, when you'll need to find data index by finding index of column definition where
        // 'field' match
        if (typeof dataIndex === 'number' && dataIndex > 1) {
            // [description column, product-to-replace column, clicked column]
            const summary: string[][] = localData.map((item) => [item[0], item[1], item[dataIndex]]);
            setSummaryData(summary);
            setReplaceWithProductId(summary[0][2])
            setConfirmColDefs(
                [
                    columnDefs[0],
                    columnDefs[1],
                    { headerName: translate({id: 'a.replaceW'}), valueGetter: `data[2]`, suppressMovable: true }
                ]
            );
            setConfirmDialogOpen(true);
        }
    };

    const handleConfirmDialogClose = () => {
        setSummaryData([]);
        setReplaceWithProductId(null);
        setConfirmDialogOpen(false);
    };

    const handleReplaceSuccess = () => {
        handleConfirmDialogClose();
        enqueueSnackbar(`${translate({id: 'product.succReplaced'})}`, {variant: 'success', persist: false});
        onClose(true);
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <Drawer className="_fullScreenDrawer"
                    anchor="right"
                    hideBackdrop
                    open={open}
            >
                <div className="viewContainer _directionRow">
                    <AgTable
                        rowData={localData}
                        defaultColDef={{
                            suppressMenu: true,
                            wrapText: true,
                            autoHeight: true
                        }}
                        columnHoverHighlight={true}
                        columnDefs={columnDefs}
                        onCellClicked={(e: CellClickedEvent) => handleCellClicked(e)}
                        />
                </div>
                <Footer
                    hideNavigationMenu
                    actionsRight={
                        <ButtonClose onClick={() => onClose(false)} />
                    }
                />
            </Drawer>
            <ProductReplacementConfirmationDialog columnDefs={confirmColDefs}
                                                  data={summaryData}
                                                  onClose={handleConfirmDialogClose}
                                                  onReplaceSuccess={handleReplaceSuccess}
                                                  open={confirmDialogOpen}
                                                  productToReplaceId={id}
                                                  replaceWithProductId={replaceWithProductId}
            />
        </>
    );
};

export default ProductReplacementDrawer;
/* eslint-disable react-hooks/exhaustive-deps */
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {paths} from 'paths';
import {reportTypeList} from 'shared/models/advertisement.model';
import {useFormatMessage} from 'utils/translate';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import CountrySelectorConnected from 'components/Selectors/CountrySelectorConnected/CountrySelectorConnected';
import {MarketSelector} from 'components/Selectors';
import {reportIssuesType} from 'modules/Advertisement/FrameDescription/FrameDescriptionReportDialog';
import ReportedFrames from 'modules/Quality/ReportedFrames/ReportedFrames';
import ReportedProducts from 'modules/Quality/ReportedProducts/ReportedProducts';
import ReportedAdvertisements from 'modules/Quality/ReportedAdvertisements/ReportedAdvertisements';

const reportedIssuesTypeLocalStorageKey: string = 'reportedIssuesType';

const ReportedIssues = () => {
    const translate = useFormatMessage();

    const [selectedReportType, setSelectedReportType] = useState<reportIssuesType>(null);

    useEffect(() => {
        const reportType: string = getFromLocalStorage(reportedIssuesTypeLocalStorageKey);
        setSelectedReportType(reportType ? reportType as reportIssuesType : reportIssuesType.frame);
    }, []);

    const handleReportTypeChange = (value: reportIssuesType) => {
        setSelectedReportType(value);
        storeInLocalStorage(reportedIssuesTypeLocalStorageKey, value);
    };

    return selectedReportType ? (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <PaperX>
                    <div className="_formRowDouble noMargins">
                        {selectedReportType === reportIssuesType.product ? <MarketSelector/> : <CountrySelectorConnected/>}
                        <FormControl fullWidth>
                                <InputLabel color="primary">
                                    <FormattedMessage id="b.issuesType"/>
                                </InputLabel>
                                <Select color="primary"
                                        label={translate({id: 'b.issuesType'})}
                                        size="medium"
                                        defaultValue={selectedReportType}
                                        value={selectedReportType}
                                        onChange={(e) => handleReportTypeChange(e.target.value as reportIssuesType)}
                                >
                                    {reportTypeList.map(item => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {translate({id: item.label})}
                                        </MenuItem>
                                    ))}
                                </Select>
                        </FormControl>
                        </div>
                    </PaperX>
                    <PaperX className="_fullHeight _fullTable">
                        {selectedReportType === reportIssuesType.frame && <ReportedFrames/>}
                        {selectedReportType === reportIssuesType.advertisement && <ReportedAdvertisements/>}
                        {selectedReportType === reportIssuesType.product && <ReportedProducts/>}
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>
    ) : null;
}

export default ReportedIssues;
import './Attributes.scss';

import {Chip} from '@mui/material';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {IRootState} from 'shared/reducers';
import {dictionary, dictionaryElement, dictionaryWithElements} from 'shared/models/dictionaries.model';
import {PaperX} from 'components/PaperX';
import {DictionaryElementsTable} from 'components/Tables';
import {DictionarySelector} from 'components/Selectors';

function buildDictionariesChips (dictionaries: (dictionaryWithElements | dictionary)[],
                                   lang: string,
                                   onDictSelect: (dictionary: dictionaryWithElements | dictionary) => void): JSX.Element[] {
    return dictionaries
        .map((dict) => (<Chip color="primary" variant="filled" size="small"
                              onClick={onDictSelect ? () => onDictSelect(dict) : null}
                              onDelete={onDictSelect ? () => onDictSelect(dict) : null}
                              label={dict.translations[lang]}
                              key={dict.id} />));
}

interface AttributeUnitProps {
    data: dictionaryElement[],
    onDictSelect: (dictionary: dictionaryWithElements | dictionary) => void,
    selectedDicts: (dictionaryWithElements | dictionary)[]
}

const AttributeUnit = (props: AttributeUnitProps) => {
    const {data, onDictSelect, selectedDicts} = props;
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    return (<>
        <PaperX>
            <div className="_halfWidth">
                <DictionarySelector dictionaryId={''} onDictionaryChange={onDictSelect}/>
            </div>
            {selectedDicts && selectedDicts.length > 0 && <div className="attributeSelectedDictionaries">
                <FormattedMessage id="attributes.selectedUnits"/>: <br/>
                {buildDictionariesChips(selectedDicts, lang, onDictSelect)}
            </div>}
        </PaperX>
        <PaperX className="_fullHeight _fullTable">
            <DictionaryElementsTable data={data} />
        </PaperX>
    </>);
};

export default AttributeUnit;
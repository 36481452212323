import axios from 'axios';
import {useQuery} from 'react-query';
import {useSnackbar} from 'notistack';
import {
    API_GET_REPORTED_PAGES,
    API_LEAFLET_MANAGEMENT_LEAFLETS
} from 'config/api/constants';
import {reportedEntity} from 'shared/models/_common.model';
import {leafletModel} from 'shared/models/leaflet.model';
import {useFormatMessage} from 'utils/translate';
import {leafletDataTemplate} from 'modules/Advertisement';

const getLeafletDetails = async (id:string): Promise<leafletModel> => {
    if (!id) return leafletDataTemplate;
    const response = await axios.get(`${API_LEAFLET_MANAGEMENT_LEAFLETS}/${id}`);
    return response.data;
};

export const useGetLeafletDetails = (leafletId:string) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useQuery(
        [leafletId, 'getLeafletDetails'],
        () => getLeafletDetails(leafletId),
        {
            staleTime: 0,
            cacheTime: 1,
            retry: 1,
            initialData: leafletDataTemplate,
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            }
        }
    );
};

const getReportedPagesForLeaflet = async (id: string): Promise<reportedEntity[]> => {
    if (!id) return [];
    const response = await axios.get(API_GET_REPORTED_PAGES(id));
    return response.data;
};

export const useGetReportedPagesForLeaflet = (leafletId: string) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useQuery(
        [leafletId, 'getReportedPagesForLeaflet'],
        () => getReportedPagesForLeaflet(leafletId),
        {
            staleTime: 0,
            cacheTime: 1,
            retry: 1,
            initialData: [],
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            }
        }
    );
};
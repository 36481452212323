export type franchiseOwner = {
    branchIds: string[],
    id: string,
    name: string,
    retailerId: string,
}

export type franchiseOwnersResponseModel = franchiseOwner[];

export type framchiseOwnerPOSTPayloadModel = {
    branchIds: string[],
    name: string,
    retailerId: string,
}

// templates

export const franchiseOwnerTemplate: franchiseOwner = {
    id: null,
    retailerId: '',
    name: '',
    branchIds: []
};
/* eslint-disable react-hooks/exhaustive-deps */
import './ReportedFramesBrowser.scss';

import {useEffect, useRef, useState} from 'react';
import {paths} from 'paths';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_FRAMES_FOR_LEAFLET} from 'config/api/constants';
import {framesPageNumberOffset} from 'shared/models/frame.model';
import { getId, getURLParam } from 'utils/routing';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import {LeafletBrowser} from 'components/gfx';
import {useGetLeafletDetails, useGetReportedPagesForLeaflet} from './ReportedFramesBrowserAPI';
import ReportedFramesSummary from './ReportedFramesSummary';
import {framesResponseModel} from 'shared/models/frame.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';

const ReportedFramesBrowser = ({history}) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const cancelToken = useRef(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [returnPath, setReturnPath] = useState<string>(paths.reportedIssues);
    const [id, setId] = useState<string>(null);
    const [selectedPageNumber, setSelectedPageNumber] = useState<number>(0);
    const [data, setData] = useState<framesResponseModel>(null);

    const leafletAPI = useGetLeafletDetails(id);
    const reportedPagesAPI = useGetReportedPagesForLeaflet(id);

    useEffect(() => {
        const leafletId: string = getId();
        const fromUrl: string = getURLParam('from');

        if (leafletId) setId(leafletId);
        if (fromUrl) setReturnPath(fromUrl);
    }, []);

    useEffect(() => id && fetchFramesForLeaflet(), [id]);
   
    const fetchFramesForLeaflet = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);

        axios.get<framesResponseModel>(API_GET_FRAMES_FOR_LEAFLET(id), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setData(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch(e => {
                if(!e?.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    }

    const handleRowClick = (pageNumber: number) => {
        if (selectedPageNumber === pageNumber - 1) {
            // solving scenario: usr click row, then change page manually, then  click same row (same page number) again
            // - as there will be no change in selectedPageNumber leaflet page won't change,
            setSelectedPageNumber(0);
        }
        setSelectedPageNumber(pageNumber - 1);
    }

    return (
        <div className="viewRoot reportedFramesBrowserRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading || leafletAPI.isFetching || reportedPagesAPI.isFetching}/>
                <div className="viewContainer _directionRow">
                    <PaperX className="_fullWidth leafletContainer">
                        {data && <LeafletBrowser leaflet={leafletAPI.data}
                                        frames={framesPageNumberOffset(data)}
                                        externalPageNumber={selectedPageNumber}
                                        showFileName
                                        hideReportPage
                        />}
                    </PaperX>
                    <div className="_directionCol">
                        {data && <ReportedFramesSummary frames={data.filter((frame) => frame.reported === true)}
                                                        pages={reportedPagesAPI.data}
                                                        leafletId={id}
                                                        onRowClick={handleRowClick}
                        />}
                    </div>

                </div>
            </div>
            <Footer
                actionsRight={
                    <ButtonClose onClick={() => history.push(returnPath)} />
                }
            />
        </div>
    );
}

export default ReportedFramesBrowser;
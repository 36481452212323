import './RepresentativeBranch.scss';

import {useSelector} from 'react-redux';
import {TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {useFormatMessage} from 'utils/translate';
import {branch} from 'shared/models/branch.model';
import {IRootState} from 'shared/reducers';

type representativeBranchProps = {
    id?: string,
    onClear?: () => void
}

const RepresentativeBranch = (props: representativeBranchProps) => {
    const {id, onClear} = props;
    const branchesData = useSelector((state: IRootState) => state.branchesList.data);
    const translate = useFormatMessage();

    const getValue = (): string => {
        if (id) {
            const match = branchesData.find((branch) => branch.id === id);
            if (match) {
                return getRepresentativeBranchString(match);
            }
            return id;
        }
        return '';
    };

    return (
        <div className="representativeBranchRoot">
            <TextField className="_fullWidth"
                       variant="outlined"
                       label={translate({id: 'b.representativeBranch'})}
                       value={getValue()}
                       required
                       disabled/>
            {onClear && <div className="clearInputButtonContainer">
                <IconButton onClick={onClear} color="primary" tabIndex={-1} disabled={id === ''}>
                    <ClearIcon/>
                </IconButton>
            </div>}
        </div>
    );
};

export const getRepresentativeBranchString = (branchObject: branch): string => {
    const {city, houseNumber, nielsenRegionName, postCode, regionName, street} = branchObject;
    let value = `${postCode} ${city}, ${street} ${houseNumber}, ${regionName || ''} ${nielsenRegionName || ''}`;
    return value.replaceAll('null', '');
}

export default RepresentativeBranch;
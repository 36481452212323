/* eslint-disable react-hooks/exhaustive-deps */
import './LeafletBrowser.scss';

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {Button, IconButton} from '@mui/material';
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight
} from '@mui/icons-material';
import {leafletModel} from 'shared/models/leaflet.model';
import {frame} from 'shared/models/frame.model';
import {API_PAGE_IMAGE, API_STATUSES} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import LeafletPage from './LeafletPage';
import ReportPage from './ReportPage';

enum BrowsingDirection {
    both,
    fwd,
    back
}

interface LeafletBrowserProps {
    disableFrameInteraction?: boolean,
    externalPageNumber?: number,
    frame2highlight?: string,
    frames?: frame[],
    fullScreen?: boolean,
    go2Frame?: boolean,
    hideColorPicker?: boolean,
    hideReportPage?: boolean,
    isDisabled?: boolean,
    leaflet: leafletModel,
    onFrameClick?: (frameId: string) => void,
    onPageChange?: (pageNumber: number) => void,
    showFileName?: boolean,
}

type page4browser = {
    status: API_STATUSES,
    imageData: Blob
}

const PAGES_BUFFER: number = 4;

const LeafletBrowser = (props: LeafletBrowserProps) => {
    const cancelTokens = useRef([]);
    const nextButton = useRef(null);
    const prevButton = useRef(null);

    const [leafletId, setLeafletId] = useState<string>('');
    const [numberOfPages, setNumberOfPages] = useState<number>(0);
    const [fileName, setFileName] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pages, setPages] = useState<page4browser[]>([]);
    const [browsingDirection, setBrowsingDirection] = useState<BrowsingDirection>(BrowsingDirection.both);
    const [frames, setFrames] = useState<frame[]>([]);

    const buildPages = () => {
        const newPages: page4browser[] = [];
        for (let i = 0; i <= numberOfPages; i++) {
            newPages.push({imageData: null, status: API_STATUSES.NEW})
        }
        setPages(newPages);
    };

    useEffect(() => {
        if (props.leaflet && props.leaflet.leafletId && props.leaflet.metaData) {
            setLeafletId(props.leaflet.leafletId);
            setNumberOfPages(props.leaflet.metaData.numberOfPages - 1);
            setFileName(props.leaflet.metaData.fileName);
            setCurrentPage(typeof props.externalPageNumber === 'number' ? props.externalPageNumber : 0);
        }

        return () => cancelTokens.current.forEach((cancel) => cancel());
    }, [props.leaflet]);

    useEffect(()=> {
        if (typeof props.externalPageNumber === 'number' && props.externalPageNumber >= 0) setCurrentPage(props.externalPageNumber);
    }, [props.externalPageNumber]);

    useEffect(() => {
        buildPages();
    }, [leafletId]);

    const setPageStatus = (pageNumber: number, status: API_STATUSES) => {
        const newPages: page4browser[] = [...pages];
        newPages[pageNumber].status = status;
        setPages(newPages);
    };

    const setPageImage = (pageNumber: number, imageData: Blob) => {
        const newPages: page4browser[] = [...pages];
        newPages[pageNumber].status = API_STATUSES.IDLE;
        newPages[pageNumber].imageData = imageData;
        setPages(newPages);
    };

    const fetchPage = (pageNumber: number) => {
        setPageStatus(pageNumber, API_STATUSES.PENDING);
        axios.get(API_PAGE_IMAGE(leafletId, pageNumber + 1), {
            responseType: 'blob',
            cancelToken: new axios.CancelToken(
                cancel => (cancelTokens.current.push(cancel))
            )
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setPageImage(pageNumber, resp.data);
                } else {
                    setPageStatus(pageNumber, API_STATUSES.ERROR);
                }
            })
            .catch(() => setPageStatus(pageNumber, API_STATUSES.ERROR));
    };

    const testAndFetch = (pageNumber: number) => {
        if (pages[pageNumber] && pages[pageNumber].status === API_STATUSES.NEW) {
            fetchPage(pageNumber);
        }
    };

    const planDownloads = () => {
        if (browsingDirection === BrowsingDirection.both) {
            for(let i = -PAGES_BUFFER; i <= PAGES_BUFFER; i++){
                testAndFetch(currentPage + i);
            }
        }
        if (browsingDirection === BrowsingDirection.fwd) {
            for(let i = currentPage; i <= currentPage + PAGES_BUFFER; i++){
                testAndFetch(currentPage + i);
            }
        }
        if (browsingDirection === BrowsingDirection.back) {
            for(let i = currentPage; i >= currentPage - PAGES_BUFFER; i--){
                testAndFetch(i);
            }
        }
    };

    useEffect(() => {
        if(pages.length && leafletId) planDownloads();
        testFrames(props.frames);
    }, [pages, currentPage]);

    useEffect(() => {
        testFrames(props.frames);
    }, [props.frames]);

    const testFrames = (frames: frame[]) => {
        if (frames) {
            const validFrames: frame[] = [];
            frames.forEach((frame) => {
                if (frame && frame.pageNumber === currentPage && frame.leafletId === leafletId) {
                    validFrames.push(frame);
                }
            })
            setFrames(validFrames);
        }
    };

    const firstPage = () => {
        setCurrentPage(0);
        setBrowsingDirection(BrowsingDirection.fwd);
        props.onPageChange && props.onPageChange(0);
    };

    const prevPage = () => {
        if (currentPage !== 0) {
            setCurrentPage(currentPage - 1);
            setBrowsingDirection(BrowsingDirection.back);
            props.onPageChange && props.onPageChange(currentPage - 1);
        }
    };
    const nextPage = () => {
        if (currentPage !== numberOfPages){
            setCurrentPage(currentPage + 1);
            setBrowsingDirection(BrowsingDirection.fwd);
            props.onPageChange && props.onPageChange(currentPage + 1);
        }
    };
    const lastPage = () => {
        setCurrentPage(numberOfPages);
        setBrowsingDirection(BrowsingDirection.back);
        props.onPageChange && props.onPageChange(numberOfPages);
    };

    const nextBtnClickHandler = () => nextButton.current.click();
    const prevBtnClickHandler = () => prevButton.current.click();

    const pageWithFrame = () => {
        if (props.externalPageNumber >= 0) setCurrentPage(props.externalPageNumber);
    };

    const regularHeader = () => {
        if (props.showFileName && fileName) {
            return (<div className="leafletFileNameLabel">
                <div title={fileName}>{fileName}</div>
            </div>);
        }
        return null;
    };

    const disableFirstPrevBtn = (): boolean => currentPage === 0 || props.isDisabled;

    const disableNextLastBtn = (): boolean => currentPage === numberOfPages || props.isDisabled;

    return (
        <div className="leafletBrowserRoot">
            {regularHeader()}
            {pages[currentPage] && <LeafletPage externalImage={pages[currentPage].imageData}
                                                externalStatus={pages[currentPage].status}
                                                fullScreen={props.fullScreen}
                                                onSwipeLeft={() => {nextBtnClickHandler()}}
                                                onSwipeRight={() => {prevBtnClickHandler()}}
                                                frames={frames}
                                                frame2highlight={props.frame2highlight}
                                                onFrameClick={(frameId) => props.onFrameClick ? props.onFrameClick(frameId) : null}
                                                isDisabled={props.isDisabled || props.disableFrameInteraction}
                                                hideColorPicker={props.hideColorPicker}/>}
            <div className="leafletBrowserActions">
                {props.go2Frame && props.frames && props.externalPageNumber >= 0 && <Button onClick={pageWithFrame} variant="outlined" size="small" tabIndex={-1}><FormattedMessage id="leafletBrowser.goToFrame"/></Button>}
                <IconButton onClick={firstPage} color="primary" size="small" disabled={disableFirstPrevBtn()}><KeyboardDoubleArrowLeft/></IconButton>
                <IconButton onClick={prevPage} ref={prevButton} color="primary" size="small" disabled={disableFirstPrevBtn()}> <KeyboardArrowLeft/> </IconButton>
                {currentPage + 1} <FormattedMessage id="a.of"/> {numberOfPages + 1}
                <IconButton onClick={nextPage} ref={nextButton} color="primary" size="small" disabled={disableNextLastBtn()}> <KeyboardArrowRight/></IconButton>
                <IconButton onClick={lastPage} color="primary" size="small" disabled={disableNextLastBtn()}> <KeyboardDoubleArrowRight/> </IconButton>
                {!props.hideReportPage && <ReportPage leafletId={props.leaflet?.leafletId} pageNumber={currentPage + 1}/>}
            </div>
        </div>
    );
};
export default LeafletBrowser;
import {markets} from 'shared/reducers/countryMarketConst';
import {storeInLocalStorage} from 'utils/storageUtils';

export const USER_PROFILE_SET_LANG_DATA: string = 'USER_PROFILE_SET_LANG_DATA';
export const USER_PROFILE_SET_PREFERRED_COUNTRY: string = 'USER_PROFILE_SET_PREFERRED_COUNTRY';
export const USER_PROFILE_SET_LANG_USER_INTERFACE: string = 'USER_PROFILE_SET_LANG_USER_INTERFACE';
export const USER_PROFILE_SET_MARKET: string = 'USER_PROFILE_SET_MARKET';
export const USER_PROFILE_SET_OPTIONS_DIALOG_OPEN: string = 'USER_PROFILE_SET_OPTIONS_DIALOG_OPEN';
export const USER_PROFILE_SET_COUNTRY_MARKET: string = 'USER_PROFILE_SET_COUNTRY_MARKET';

export const LOCAL_STORAGE_KEY_LANG_DATA: string = 'langData';
export const LOCAL_STORAGE_KEY_LANG_USER_INTERFACE: string = 'langInterface';
export const LOCAL_STORAGE_KEY_COUNTRY_MARKET: string = 'countryMarket';

export const DEFAULT_COUNTRY: string = 'DE';
export const DEFAULT_DATA_LANG: string = 'en';

const currencyPerCountry = {
    'AT': 'EUR',
    'BE': 'EUR',
    'BG': 'BGN',
    'CH': 'CHF',
    'CS': 'CZK',
    'DE': 'EUR',
    'HR': 'EUR',
    'HU': 'HUF',
    'LU': 'EUR',
    'MD': 'MDL',
    'PL': 'PLN',
    'RO': 'RON',
    'SI': 'EUR',
    'SK': 'EUR'
};

export function getCurrencyForCountry(country: string): string {
    return country ? currencyPerCountry[country] : '-';
}

function getMarketForCountry (countryId: string): string {
    return markets.find((market) => market.countries.find((country) => country.id === countryId))?.id || '';
}

function getCountryForMarket (marketId: string): string {
    return markets.find((market) => market.id === marketId)?.countries[0]?.countryId || '';
}

export type countryMarketItem = {
    market: string,
    preferredCountry: string
}

type userProfileState = {
    countryMarket: countryMarketItem,
    currency: string,
    langData: string,
    langInterface: string,
    optionsDialogOpen: boolean,
}

const initialState: userProfileState = {
    countryMarket: {
        market: getMarketForCountry(DEFAULT_COUNTRY),
        preferredCountry: DEFAULT_COUNTRY
    },
    currency: currencyPerCountry[DEFAULT_COUNTRY],
    langData: 'DEFAULT_DATA_LANG',
    langInterface: 'en',
    optionsDialogOpen: false,
};

export type UserProfileState = Readonly<typeof initialState>;

const userProfileReducer = (state: UserProfileState = initialState, action): UserProfileState => {
    switch (action.type) {
        case USER_PROFILE_SET_LANG_DATA:
            storeInLocalStorage(LOCAL_STORAGE_KEY_LANG_DATA, action.payload.langData);
            return {...state, langData: action.payload.langData};
        case USER_PROFILE_SET_LANG_USER_INTERFACE:
            storeInLocalStorage(LOCAL_STORAGE_KEY_LANG_USER_INTERFACE, action.payload.langInterface);
            return {...state, langInterface: action.payload.langInterface};  
        case USER_PROFILE_SET_PREFERRED_COUNTRY:
            const {country} = action.payload;
            const countryBasedMarket: countryMarketItem = {
                market: getMarketForCountry(country),
                preferredCountry: country
            };
            storeInLocalStorage(LOCAL_STORAGE_KEY_COUNTRY_MARKET, JSON.stringify(countryBasedMarket));
            return {
                ...state,
                currency: currencyPerCountry[country],
                countryMarket: countryBasedMarket
            };
        case USER_PROFILE_SET_MARKET:
            const {marketId} = action.payload;
            const marketBasedCountry: countryMarketItem = {
                market: marketId,
                preferredCountry: getCountryForMarket(marketId)
            };
            storeInLocalStorage(LOCAL_STORAGE_KEY_COUNTRY_MARKET, JSON.stringify(marketBasedCountry));
            return {
                ...state,
                countryMarket: marketBasedCountry
                };
        case USER_PROFILE_SET_COUNTRY_MARKET:
            return {...state, countryMarket: action.payload.countryMarket}
        case USER_PROFILE_SET_OPTIONS_DIALOG_OPEN:
            return {...state, optionsDialogOpen: action.payload.optionsDialogOpen};
        default:
            return state;
    }
};

export default userProfileReducer;
import './PromotionsTableEraseRows.scss';

import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Checkbox, FormControlLabel} from '@mui/material';
import {promotionUI} from 'shared/models/promotion.model';
import {useFormatMessage} from 'utils/translate';

export enum pricePropNames {
    promotionalPrice = 'promotionalPrice',
    regularPrice = 'regularPrice',
    relativeDiscount = 'relativeDiscount',
    absoluteDiscount = 'absoluteDiscount',
    mpu = 'mpu'
}

export enum literalPropNames {
    promotionTypes = 'promotionTypes',
    themeSeasons = 'themeSeasons',
    countryThemedWeeks = 'countryThemedWeeks',
    qualitySeals = 'qualitySeals',
}

interface EraseRowProps {
    data: promotionUI[],
    onChange: (propName: pricePropNames|literalPropNames|string, value: boolean) => void,
}

interface PriceRowProps extends EraseRowProps{
    label: string,
    propName: pricePropNames,
}

export const PriceRow = ({data, label, onChange, propName}: PriceRowProps) => {
    const translate = useFormatMessage();
    const [checked, setChecked] = useState<boolean>(false);

    const handleToggle = (value: boolean) => {
        setChecked(value);
        onChange(propName, value);
    };

    return (
        <div className="promotionsTableEraseRowRoot">
            <div>
                <FormattedMessage id={label}/>
            </div>
            <div className="values">
                {Array.from(new Set(data?.map((item) => item[propName]).filter(Boolean))).join(', ')}
            </div>
            <EraseCheckbox checked={checked} onChange={(value) => handleToggle(value)} label={translate({id: 'a.erase'})}/>
        </div>
    );
};

interface LiteralsRowProps extends EraseRowProps{
    propName: literalPropNames,
    label: string,
}

export const LiteralsRow = ({data, label, onChange, propName}: LiteralsRowProps) => {
    const translate = useFormatMessage();
    const [checked, setChecked] = useState<boolean>(false);

    const handleToggle = (value: boolean) => {
        setChecked(value);
        onChange(propName, value);
    };

    return (
        <div className="promotionsTableEraseRowRoot">
            <div>
                <FormattedMessage id={label}/>
            </div>
            <div className="values">
                {Array.from(new Set (data?.map((item) => item[propName]?.literals?.map((literal) => literal.literalName).filter(Boolean)).filter(Boolean).flat())).join(', ')}
            </div>
            <EraseCheckbox checked={checked} onChange={(value) => handleToggle(value)} label={translate({id: 'a.erase'})}/>
        </div>
    );
};

export const DatesRow = ({data, onChange}: EraseRowProps) => {
    const translate = useFormatMessage();
    const [checked, setChecked] = useState<boolean>(false);

    const handleToggle = (value: boolean) => {
        setChecked(value);
        onChange('validityDates', value);
    };

    return (
        <div className="promotionsTableEraseRowRoot">
            <div>
                <FormattedMessage id="promotion.validity"/>
            </div>
            <div className="values">
                {Array.from(new Set (data?.map((item) => item?.validityDates?.map((validity) => {
                    const {validFrom, validTo} = validity;
                    if (validFrom) {
                        if (validTo) {
                            return `${validFrom} - ${validTo}`;
                        } else {
                            return validFrom;
                        }
                    } else if (validTo) {
                        return validTo
                    }
                    return '';
                }).filter(Boolean)).filter(Boolean).flat())).join(', ')}
            </div>
            <EraseCheckbox checked={checked} onChange={(value) => handleToggle(value)} label={translate({id: 'a.erase'})}/>
        </div>
    );
};

export const BundlesRow = ({data, onChange}: EraseRowProps) => {
    const translate = useFormatMessage();
    const [checked, setChecked] = useState<boolean>(false);

    const handleToggle = (value: boolean) => {
        setChecked(value);
        onChange('giveAwayBundledProducts', value);
    };

    return (
        <div className="promotionsTableEraseRowRoot">
            <div>
                <FormattedMessage id="productSearch.giveAwayBundling"/>
            </div>
            <div className="values">
                {Array.from(new Set (data?.map((item) => item.giveAwayBundledProducts?.map((gaProduct) => gaProduct.brand).filter(Boolean)).filter(Boolean).flat())).join(', ')}
            </div>
            <EraseCheckbox checked={checked} onChange={(value) => handleToggle(value)} label={translate({id: 'a.erase'})}/>
        </div>
    );
};

interface EraseCheckboxProps {
    checked: boolean,
    label: string,
    onChange: (value: boolean) => void
}

const EraseCheckbox = ({checked, label, onChange}: EraseCheckboxProps) =>
    <div>
        <FormControlLabel className="showLeafletCheckbox"
                          control={<Checkbox tabIndex={-1}
                                             checked={checked}
                                             onChange={(e) => onChange(e.target.checked)}/>
                          }
                          label={label}/>
    </div>;
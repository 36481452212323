import axios from 'axios';
import {useMutation} from 'react-query';
import {useSnackbar} from 'notistack';
import {
    API_REPORT_FRAME,
    API_REMARK_ADVERTISEMENT,
    API_SKIP_FRAME
} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import {reportedFrameIssueType} from 'shared/models/frame.model';

export const useSkipFrame = (frameId: string, onSuccess: Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        () => axios.put(API_SKIP_FRAME(frameId)),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'frameDescription.skipped'})}`, {variant: 'success', persist: false});
                onSuccess();
            },
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            }
        }
    )
};

export const useReportFrame = (frameId: string, reportedIssueType: reportedFrameIssueType,  comment: string, onSuccess: Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        (reason) => axios.put(API_REPORT_FRAME(frameId), {comment, reportedIssueType}),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'frameDescription.reported'})}`, {variant: 'success', persist: false});
                onSuccess();
            },
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            }
        }
    )
};

export const useRemarkAdvertisement = (frameId: string, comment: string, onSuccess: Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        (reason) => axios.post(API_REMARK_ADVERTISEMENT(frameId), {comment}),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'frameDescription.reportedAdvertisement'})}`, {variant: 'success', persist: false});
                onSuccess();
            },
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            }
        }
    )
};
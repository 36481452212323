/* eslint-disable react-hooks/exhaustive-deps */
import './CategoryTaxes.scss';

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {FormControl, MenuItem, Select} from '@mui/material';
import {API_STATUSES} from 'config/api/constants';
import {fetchDictTaxes} from 'shared/reducers/dictTaxes';
import {IRootState} from 'shared/reducers';
import {taxesPerCountry, taxItem, taxItemWithCountry} from 'shared/models/taxes.model';
import {countries} from 'shared/reducers/countryMarketConst';
import {useFormatMessage} from 'utils/translate';
import AgTable from 'components/AgTable/AgTable';

interface CategoryTaxesProps {
    onTaxChange: (tax: taxItem, country: string) => void
    taxes: taxItemWithCountry[]
}

type categoryTaxRow = {
    countryId: string,
    countryName: string,
    taxName: string,
    taxId: string,
    rate: number
}

const CategoryTaxes = ({onTaxChange, taxes}: CategoryTaxesProps) => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();
    const taxesStatus = useSelector((state: IRootState) => state.dictTaxes.status);
    const taxRates = useSelector((state: IRootState) => state.dictTaxes.data);

    const [data, setData] = useState<categoryTaxRow[]>([]);

    useEffect(() => {
        if (taxesStatus === API_STATUSES.ERROR) {
            dispatch(fetchDictTaxes());
        }
    }, []);

    useEffect(() => {
        if (taxesStatus === API_STATUSES.NEW) {
            dispatch(fetchDictTaxes());
        }
        if (taxesStatus === API_STATUSES.IDLE) {
            setData(buildData());
        }
    }, [taxesStatus]);

    useEffect(() => {
        if (taxesStatus === API_STATUSES.IDLE) {
            setData(buildData());
        }
    }, [taxes]);

    let defaultColumnDefs: ColDef[] = [
        {field: 'countryName', headerName: translate({ id: 'b.country' }), width: 240, cellClass: 'centered'},
        {field: 'taxName', headerName: translate({id: 'b.taxRate'}), flex: 1, cellClass: 'centered',
            cellRenderer: (props) => <TaxDropdown selected={props.data.taxId}
                                                  rates={taxRates.filter((item) => item.country === props.data.countryId)?.[0]}
                                                  onChange={(newTax, country) => onTaxChange(newTax, country)}
            />
        }
    ];

    const buildData = (): categoryTaxRow[] => {
        const newData: categoryTaxRow[] = [];
        countries.forEach((item) => {
            const existingTax: taxItemWithCountry = taxes.find((taxItem) => taxItem.country === item.countryId);
            const taxRow: categoryTaxRow = {
                countryId: item.countryId,
                countryName: item.name,
                taxName: existingTax?.name || '',
                taxId: existingTax?.id || '',
                rate: existingTax?.rate || null
            };
            newData.push(taxRow);
        });
        return newData;
    };

    return (
        <AgTable className="categoryTaxesRoot"
                 rowHeight={50}
                 rowData={data}
                 columnDefs={defaultColumnDefs}
                 defaultColDef={{suppressMenu: true}}
        />
    );
};

export default CategoryTaxes;

interface TaxDropdownProps {
    onChange: (tax: taxItem, country: string) => void
    rates: taxesPerCountry,
    selected: string
}

const TaxDropdown = ({onChange, rates, selected}: TaxDropdownProps) => {
    return (
        <FormControl size="small"
                     className="taxDropdownRoot"
        >
            <Select value={selected}
                    size="small"
                    onChange={(e) => onChange(rates.taxes.find((taxItem) => taxItem.id === e.target.value), rates.country)}
            >
                <MenuItem value={''}>-</MenuItem>
                {rates.taxes.map((item) => <MenuItem value={item.id} key={item.id}>{item.name} {Math.floor(item.rate * 100)}%</MenuItem>)}
            </Select>
        </FormControl>
    );
};
/* eslint-disable react-hooks/exhaustive-deps */
import './RadioSpotHeaderData.scss';

import {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios, {AxiosError} from 'axios';
import {parseISO, isValid} from 'date-fns';
import {useSnackbar} from 'notistack';
import {API_MEDIA_RADIO_SPOT} from 'config/api/constants';
import {Button, TextField} from '@mui/material';
import {Attribute} from 'components/Attribute';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import {CountryBannerSelector, ValiditySelector} from 'components/Selectors';
import LanguageSelector from 'components/Selectors/LanguageSelector/LanguageSelector';
import {languages} from 'modules/Dictionaries/Dictionary';
import {dateToString} from 'modules/Leaflet/HeaderData/pageValiditiesIO';
import MediumProposals from 'modules/Medium/MediumProposals';
import MediaAudiofile from 'modules/Medium/MediaAudiofile/MediaAudiofile';
import {paths} from 'paths';
import {handyAttributes} from 'shared/handyAttributes';
import {bannerWithRetailer} from 'shared/models/banner.model';
import {commonAttributeDataModel, mapOfMultiselectAttributes} from 'shared/models/attributeComponent.model';
import {radioSpotPutPostPayloadModel, radioSpotStatus, radioSpotResponseModel} from 'shared/models/media.model';
import {IRootState} from 'shared/reducers';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {getId} from 'utils/routing';
import {lockLevels, lockSetter, releaseMediumLock} from 'utils/lockUtils';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';

const radioSpotDataTemplate: radioSpotResponseModel = {
    country: '',
    headerData: {
        bannerId: '',
        bannerName: '',
        countryThemedWeeks: [],
        language: '',
        themeSeasons: [],
        transcription: '',
        validFrom: '',
        validTo: ''
    },
    radioSpotId: '',
    status: null,
    transcriptProposals: [],
    metaData: {
        sourceFile: '',
        storageFileName: ''
    }
}

function buildRadioSpotPropsTemplate (): mapOfMultiselectAttributes {
    const template: mapOfMultiselectAttributes = {};
    template[handyAttributes.themeSeason] = {id: handyAttributes.themeSeason, literals: []};
    template[handyAttributes.countryThemedWeeks] = {id: handyAttributes.countryThemedWeeks, literals: []};
    return template;
}

function restoreProperties (themeSeasons: string[], themedWeeks: string[]): mapOfMultiselectAttributes {
    const properties: mapOfMultiselectAttributes = {};
    properties[handyAttributes.themeSeason] = {
        id: handyAttributes.themeSeason,
        literals: themeSeasons ? themeSeasons.map((item) => ({literalId: item})) : []
    };
    properties[handyAttributes.countryThemedWeeks] = {
        id: handyAttributes.countryThemedWeeks,
        literals: themedWeeks ? themedWeeks.map((item) => ({literalId: item})) : []
    };
    return properties;
}

const RadioSpotHeaderData = ({history}) => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bannerId, setBannerId] = useState<string>(null);
    const [country, setCountry] = useState<string>(countryMarket.preferredCountry);
    const [language, setLanguage] = useState<string>(undefined);
    const [radioSpotId, setRadioSpotId] = useState<string>(null);
    const [validityFrom, setValidityFrom] = useState<Date>(null);
    const [validityTo, setValidityTo] = useState<Date>(null);
    const [validFromModified, setValidFromModified] = useState<boolean>(false);
    const [validToModified, setValidToModified] = useState<boolean>(false);
    const [radioSpotProperties, setRadioSpotProperties] = useState<mapOfMultiselectAttributes>(buildRadioSpotPropsTemplate());
    const [transcription, setTranscription] = useState<string>(radioSpotDataTemplate.headerData.transcription);
    const [data, setData] = useState<radioSpotResponseModel>(radioSpotDataTemplate);
    const [selectedFile, setSelectedFile] = useState<File | string>(null);
    const [transcriptProposals, setTranscriptProposals] = useState<string[]>(null);

    useEffect(() => {
        const radioSpotId: string = getId();
        if (radioSpotId) {
            setRadioSpotId(radioSpotId);
            setIsLoading(true);
            lockSetter(lockLevels.medium);
            axios.get<radioSpotResponseModel>(`${API_MEDIA_RADIO_SPOT}/${radioSpotId}?acquireLock=true`)
                .then((response) => {
                    if (responseValidation(response.data)) {
                        setData(response.data);
                    } else {
                        enqueueSnackbar(`${translate({id: 'media.radioFetchErr'})}`, {variant: 'error', persist: false});
                        history.push(paths.radioSpotOverview);
                    }
                })
                .catch((e: AxiosError) => {
                    if (axios.isAxiosError(e)) {
                        console.log(e);
                        if (e?.response?.status === 409) {
                            enqueueSnackbar(`${translate({id: 'media.spotLocked'})}`, {variant: 'error', persist: false});
                        } else {
                            enqueueSnackbar(`${translate({id: 'media.radioFetchErr'})}`, {variant: 'error', persist: false});
                        }
                        history.push(paths.radioSpotOverview);
                    }
                })
                .finally(() => setIsLoading(false));
        }
        return history.listen(location => (location.pathname === paths.radioSpotOverview) && releaseMediumLock());
    }, []);

    useEffect(() => {
        if (data.headerData.bannerId) setBannerId(data.headerData.bannerId);
        if (data.headerData.language) setLanguage(data.headerData.language);
        if (data.headerData.transcription) setTranscription(data.headerData.transcription);
        if (data.headerData.validFrom) setValidityFrom(parseISO(data.headerData.validFrom));
        if (data.headerData.validTo) setValidityTo(parseISO(data.headerData.validTo));
        setRadioSpotProperties(restoreProperties(data.headerData.themeSeasons, data.headerData.countryThemedWeeks));
        if (data.metaData.sourceFile) setSelectedFile(data.metaData.sourceFile);
        if (data.transcriptProposals) setTranscriptProposals(data.transcriptProposals);
    }, [data]);

    const handleBannerChange = (banner: bannerWithRetailer) => setBannerId(banner ? banner.bannerId : null);

    const handleCountryChange = (value: string) => {
        setCountry(value);
        dispatch(setPreferredCountry(value));
    };
    
    const handleLanguageChange = (lang: string) => setLanguage(lang);

    const handleRadioSpotPropertyChange = (key: string, value: commonAttributeDataModel) => {
        const newRadioSpotProperties: mapOfMultiselectAttributes = {...radioSpotProperties};
        newRadioSpotProperties[key] = value;
        setRadioSpotProperties(newRadioSpotProperties);
    };
   
    const createRadioSpotPayload: radioSpotPutPostPayloadModel = {
        country: radioSpotId ? data.country : country,
        headerData: {
            bannerId: bannerId,
            countryThemedWeeks: radioSpotProperties[handyAttributes.countryThemedWeeks].literals.map((item) => item.literalId),
            language: language,
            themeSeasons: radioSpotProperties[handyAttributes.themeSeason].literals.map((item) => item.literalId),
            transcription: transcription,
            validFrom: isValid(validityFrom) ? dateToString(validityFrom) : null,
            validTo: isValid(validityTo) ? dateToString(validityTo) : null

        }
    }

    const handleSelectProposal = (selectedItem: string) => setTranscription(selectedItem);

    const handleSaveClick = async () => {
        setIsLoading(true);
        const method = radioSpotId ? 'put' : 'post';
        const API_MEDIA_URL = radioSpotId ? `${API_MEDIA_RADIO_SPOT}/${radioSpotId}` : API_MEDIA_RADIO_SPOT;

        let payload: FormData | radioSpotPutPostPayloadModel;
        if (!!!radioSpotId) {
            const payloadBlob = new Blob([JSON.stringify(createRadioSpotPayload)], {type: 'application/json'});
            const formData = new FormData();
            formData.append('radioSpotFile', selectedFile);
            formData.append('request', payloadBlob);
            payload = formData;
        } else payload = createRadioSpotPayload;

        axios[method](API_MEDIA_URL, payload, !!!radioSpotId && {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then(() => {
                enqueueSnackbar(`${translate({id: 'media.radioSpotSucc'})}`, {variant: 'success', persist: false});
                releaseMediumLock();
                history.push(paths.radioSpotOverview);
            })
            .catch((e: AxiosError) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'media.radioSpotSaveErr'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false));
    }

    const canSave = (): boolean => {
        if (data.status === radioSpotStatus.REGISTERED) {
            return [country, language, transcription].every(Boolean)
        } else return [country, language].every(Boolean) && (!!transcription || !!selectedFile);
    }

    const handleSelectFile = (selectedFile: File) => setSelectedFile(selectedFile);
    
    const handleDeleteFile = () => setSelectedFile(null);

    return (
        <div className="viewRoot radioSpotDescriptionRoot">
            <div className="viewport">
            <LoadingOverlay show={isLoading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        {/*@TODO use separate CountryDisplayAsInput and BannerSelector components, see HeaderData.tsx */}
                        <CountryBannerSelector
                            country={radioSpotId ? data.country : country}
                            bannerId={bannerId}
                            onBannerChange={handleBannerChange}
                            onCountryChange={handleCountryChange}
                            disabledCountry={!!radioSpotId}
                        />
                    </PaperX>
                    <div className="_directionRow">
                        <PaperX className="radioSpotContainer _fullWidth _scrollY">
                            <div className="_formRowDouble">
                                <LanguageSelector language={language} onLanguageChange={handleLanguageChange} languageSet={languages} label="media.radioSpotLanguage" required/>
                                <div>
                                    <Attribute id={handyAttributes.themeSeason} data={radioSpotProperties[handyAttributes.themeSeason]} onChange={handleRadioSpotPropertyChange} multiselect disableAutoselect/>
                                </div>
                            </div>
                            <div className="_formRowDouble">
                                <ValiditySelector id={1}
                                                  labelFrom={translate({id: 'b.validFrom'})}
                                                  labelTo={translate({id: 'b.validTo'})}
                                                  validityFrom={validityFrom || validFromModified ? validityFrom : '' && radioSpotDataTemplate.headerData.validFrom ? new Date(radioSpotDataTemplate.headerData.validFrom) : null}
                                                  validityTo={validityTo || validToModified? validityTo : '' && radioSpotDataTemplate.headerData.validTo ? new Date(radioSpotDataTemplate.headerData.validTo) : null}
                                                  onValidityFromChange={(_id, date: Date) => {
                                                    setValidFromModified(true);
                                                    setValidityFrom(date);}}
                                                  onValidityToChange={(_id, date: Date) => {
                                                    setValidToModified(true);
                                                    setValidityTo(date);}}
                                />
                                <div>
                                    <Attribute id={handyAttributes.countryThemedWeeks} data={radioSpotProperties[handyAttributes.countryThemedWeeks]} onChange={handleRadioSpotPropertyChange} multiselect disableAutoselect/>
                                </div>
                            </div>
                            <div className="_formRowDouble">
                                <div>
                                    <MediaAudiofile
                                        country={country}
                                        filename={data.metaData.sourceFile}
                                        storageFileName={data.metaData.storageFileName}
                                        isPlayMode={!!data.metaData.storageFileName}
                                        isUploadDisabled={!!radioSpotId}
                                        onDelete={handleDeleteFile}
                                        onSelect={handleSelectFile}
                                    />
                                    <div className="transcriptionContainer">
                                        <TextField
                                            label={translate({id: 'media.transcription'})}
                                            rows={20}
                                            multiline
                                            fullWidth
                                            value={transcription}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setTranscription(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {transcriptProposals && transcriptProposals.length > 0 &&
                                    <MediumProposals
                                        transcriptProposals={transcriptProposals}
                                        handleSelectProposal={handleSelectProposal}
                                />}
                            </div>
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer actionsRight = {<>
                    <Button color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                            disabled={!canSave()}>
                        <FormattedMessage id="a.save"/>
                    </Button>
                    <ButtonClose onClick={() => history.push(paths.radioSpotOverview)}/>
                </>}
            />
        </div>
      );
    };
export default RadioSpotHeaderData;
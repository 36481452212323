/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {IRootState} from 'shared/reducers';
import {paths} from 'paths';
import axios from 'axios';
import {API_GET_PRODUCTS_DUPLICATED_GTINS} from 'config/api/constants';
import {filteredProductsResponseModel, productsDuplicatedGtinsResponse} from 'shared/models/product.model';
import {gtinsQsDashboardTablesProps} from 'shared/models/gtin.model';
import {useSnackbar} from 'notistack';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {LoadingOverlay} from 'components/LoadingOverlay';

const GtinQsDuplicatedGtins = (props: gtinsQsDashboardTablesProps) => {
    const {history, tableView} = props;
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [productsData, setProductsData] = useState<productsDuplicatedGtinsResponse>([]);
    const [transformedProductData, setTransformedProductData] = useState<filteredProductsResponseModel[]>([]);
    const cancelToken = useRef(null);
    const gridRef = useRef(null);

    const columnDefs: ColDef[] = [
        { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1, rowGroup: true, cellRenderer: () => null, sortable: false},
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'alternativeDescriptions', headerName: translate({id: 'b.alternativeDescription'}), flex: 1 },
        { field: 'category', headerName: translate({id: 'b.category'}), flex: 1 },
        { field: 'typeOfPackage', headerName: translate({id: 'b.typeOfPackage'}), flex: 1 },
        { field: 'capacity', headerName: translate({id: 'b.capacity'}), flex: 1,
            valueGetter: (params) => {
                const from = params?.data?.capacityFrom;
                const to = params?.data?.capacityTo;
                if (from || to) {
                    if (from === to) {
                        return from;
                    }
                    return `${from} - ${to}`;
                }
                return '';
            }
        },
        { field: 'capacityUnit', headerName: translate({id: 'b.capacityUnit'}), flex: 1 },
        { field: 'contentOfTradingUnits', headerName: translate({id: 'b.contentOfTradingUnit'}), flex: 1 },
        { field: 'contentOfTradingUnitsUnit', headerName: translate({id: 'b.contentOfTradingUnitUnit'}), flex: 1 },
        { field: 'variety', headerName: translate({id: 'b.variety'}), flex: 1 },
        { field: 'productLineName', headerName: translate({id: 'b.productLine'}), flex: 1 },
        { field: 'active', headerName: translate({ id: 'a.active' }), width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), suppressMenu: true},
    ];

    useEffect(() => {
      fetchDuplicatedGtins();
      if (gridRef.current) {
        const api = gridRef.current.api;
        api.forEachNode(node => {
          node.setExpanded(false);
        });
      }
      return () => {
        if (cancelToken.current) cancelToken.current();
      }
    }, []);

    useEffect(() => {
      if (productsData.length === 0) setTransformedProductData([]);
      else transformDataForTable();
    }, [productsData]);
    
    const fetchDuplicatedGtins = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);
        axios.get<productsDuplicatedGtinsResponse>(API_GET_PRODUCTS_DUPLICATED_GTINS(lang), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
              if (responseValidation(response.data)) {
                setProductsData(response.data);
              } else {
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
              }
            })
            .catch(e => {
                if(!e?.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    }

    const goToDetails = (productId: string) => {
      if (productId) history.push(`${paths.productDetails}?id=${productId}&from=${paths.gtin}&activeTab=${tableView}`);
    }
 
  const transformDataForTable = () => {
    const transformedData:filteredProductsResponseModel[] = [];
      productsData?.forEach((item) => {
        item.products.forEach((dataItem) => {
          const dataEntry:filteredProductsResponseModel = {...dataItem , gtin: item.gtin};
          transformedData.push(dataEntry);
        });
      });
    setTransformedProductData(transformedData);
  }

  const getContextMenu = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    if (!params.node || !params.node.key) return [];
    const copyThisGTIN: MenuItemDef = {
        name: translate({id: 'product.copyThisGtin'}),
        action: () => {
            const singleGtin: string = params.node.key;
            if (singleGtin) navigator.clipboard.writeText(singleGtin);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>',
        disabled: !productsData.some(item => item.gtin)
    };
    const copyAllGTINs: MenuItemDef = {
        name: translate({id: 'product.copyAllGtins'}),
        action: () => {
            const allGtins: string[] = [];
            params.api.forEachNode(node => {
                if (node.data && node.data.gtin) {
                    allGtins.push(node.data.gtin);
                }
            });
            const allGtinsList = allGtins.join(', ');
            navigator.clipboard.writeText(allGtinsList);
        },
        icon: '<span class="ag-icon ag-icon-copy"></span>',
        disabled: !productsData.some(item => item.gtin)
    };
    return [copyThisGTIN, copyAllGTINs];
  };

  return (
    <div className="viewRoot">
        <LoadingOverlay show={isLoading}/>
        <AgTable
            ref={gridRef}
            columnDefs={columnDefs}
            rowData={transformedProductData}
            groupDisplayType="groupRows"
            onRowClicked = {(rowData: RowClickedEvent) => goToDetails(rowData?.data?.id)}
            defaultColDef={{
              resizable: true,
              sortable: true
            }}
            autoGroupColumnDef={{
              headerName: 'gtin',
              field: 'gtin',
              cellRenderer: 'agGroupCellRenderer',
            }}
            suppressContextMenu={false}
            getContextMenuItems={getContextMenu}
            localStorageColumnsKey={localStorageColumnsKeys.gtinQsDashboardDuplicatedGtins}
          />
      </div>
    );
};
export default GtinQsDuplicatedGtins;



import {PROMOTION_TYPES} from 'shared/models/promotion.model';
import {FormattedMessage} from 'react-intl';
import {advertisementsOverviewRow} from 'shared/models/advertisement.model';

interface PromoTypeProps {
    data: advertisementsOverviewRow
}

const PromoType = (props: PromoTypeProps) =>{
    const promoType: PROMOTION_TYPES = props.data.promotionObjectType;
    if (promoType === PROMOTION_TYPES.PRODUCT) {
        return <FormattedMessage id="b.product"/>
    } else if (promoType === PROMOTION_TYPES.BPCO) {
        return <FormattedMessage id="b.bpco"/>
    } else {
        return <FormattedMessage id="a.error"/>
    }
};

export default PromoType;
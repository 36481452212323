import {Link} from 'react-router-dom';
import {IconButton} from '@mui/material';
import {KeyboardArrowRight} from '@mui/icons-material';
import {paths} from 'paths';

interface ProductListLinkButtonProps {
    id: string,
    label: string,
}

const ProductListLinkButton = ({id, label}: ProductListLinkButtonProps) => {
    return <>
        <span>{label}</span>
        <Link to={`${paths.advertisementsOverviewPromotions}?id=${id}&from=${window.location.pathname}`}><IconButton color="primary"><KeyboardArrowRight/></IconButton></Link>
    </>;
}

export default ProductListLinkButton;
import axios from 'axios';
import {API_BRANCHES_WITH_BANNERS, API_REPRESENTATIVE_BRANCH_FOR_BANNER} from 'config/api/constants';
import {branch} from 'shared/models/branch.model';
import {representativeBranchResponseModel} from 'shared/models/representativeBranch.model';

export function getBranchesWithRepresentative(bannerId:string) {
    const endpoints = [
        API_REPRESENTATIVE_BRANCH_FOR_BANNER(bannerId),
        API_BRANCHES_WITH_BANNERS(bannerId)
    ];

    return axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then((responses) => {
        const representativeBranches: representativeBranchResponseModel = responses[0].data;
        const branches: branch[] = responses[1].data;
        if (representativeBranches.length === 0) {
            return branches;
        }
        const branchesWithRep: branch[] = [];
        branches.forEach((item) => {
            if (representativeBranches.find((rep) => rep.branchId === item.id)) {
                branchesWithRep.push({...item, isRep: true});
            } else {
                branchesWithRep.push(item);
            }
        });
        return branchesWithRep;
    });
}
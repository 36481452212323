/* eslint-disable react-hooks/exhaustive-deps */

import {ReactElement, useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {keyAccount} from 'shared/models/keyAccount.model';
import ButtonClose from 'components/Buttons/ButtonClose';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {API_KEY_ACCOUNTS, API_STATUSES} from 'config/api/constants';
import {ConfirmDialog} from 'components/ConfirmDialog';

interface HierarchyDialogKeyAccountProps {
    open: boolean,
    onClose: (reload?: boolean) => void,
    keyAccount: keyAccount
}

export const keyAccountTemplate:keyAccount = {
    id: '',
    name: '',
    active: true
}

export const HierarchyDialogKeyAccount = (props:HierarchyDialogKeyAccountProps) => {
    const translate = useFormatMessage();
    const keyAccountAPIPost = useApi('post', null, {errMsg: 'keyAccount.saveErr', succMsg: 'keyAccount.succ', failureMsg: 'keyAccount.duplicate'});
    const keyAccountAPIPut = useApi('put', null, {errMsg: 'keyAccount.saveErr', succMsg: 'keyAccount.succ', failureMsg: 'keyAccount.duplicate'});
    const {open, onClose, keyAccount} = props;

    const [keyAccountLocal, setKeyAccountLocal] = useState<keyAccount>(keyAccountTemplate);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

    const buildConfirmationMsg = (): ReactElement => <p>{`${translate({id: 'b.keyAccount'})} ${translate({id: 'hierarchy.confirmation1'})}`}
        <br/>
        <br/>
        {`${translate({id: 'hierarchy.confirmation2'})}`}
        <br/>
        {`${translate({id: 'b.retailers'})}`},
        <br/>
        {`${translate({id: 'b.banners'})}`},
        <br/>
        {`${translate({id: 'b.branches'})}`}
    </p>;
    const confMsg: ReactElement = useMemo(() => buildConfirmationMsg(), []);

    useEffect(() => {
        if (keyAccount) {
            setKeyAccountLocal(keyAccount);
        }
    }, [keyAccount]);

    useEffect(() => {
        if (keyAccountAPIPost.status === API_STATUSES.IDLE) {
            onClose(true);
        }
    }, [keyAccountAPIPost.status]);

    useEffect(() => {
        if (keyAccountAPIPut.status === API_STATUSES.IDLE) {
            onClose(true);
        }
    }, [keyAccountAPIPut.status]);

    const handleKeyAccountPropertyChange = (key: string, value: string | boolean) => {
        const newKeyAccLocal: keyAccount = {...keyAccountLocal};
        newKeyAccLocal[key] = value;
        setKeyAccountLocal(newKeyAccLocal);
    };

    const cantSave = (): boolean => !keyAccountLocal.name;

    const showLoading = (): boolean => keyAccountAPIPut.status === API_STATUSES.PENDING || keyAccountAPIPost.status === API_STATUSES.PENDING;

    const handleSaveClick = () => {
        if (keyAccountLocal.id) {
            if (keyAccountLocal.active) {
                keyAccountAPIPut.call(`${API_KEY_ACCOUNTS}/${keyAccountLocal.id}`, keyAccountLocal);
            } else {
                setConfirmationDialogOpen(true);
            }
        } else {
            keyAccountAPIPost.call(API_KEY_ACCOUNTS, keyAccountLocal);
        }
    };

    const handleSaveConfirm = () => {
        setConfirmationDialogOpen(false);
        keyAccountAPIPut.call(`${API_KEY_ACCOUNTS}/${keyAccountLocal.id}`, keyAccountLocal);
    };

    return (
        <>
            <Dialog open={open} fullWidth onClose={() => {if (!showLoading()) onClose();}}>
                <LoadingOverlay show={showLoading()}/>
                <DialogTitle>
                    <FormattedMessage id={keyAccountLocal.id ? 'b.keyAccount' : 'hierarchy.createKeyAccount'}/>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <TextField
                        required
                        className="_fullWidth"
                        label={translate({id: 'b.name'})}
                        value={keyAccountLocal.name}
                        onChange={(e) => handleKeyAccountPropertyChange('name', e.target.value)}
                        autoFocus
                    />
                    <ActiveCheckbox value={keyAccountLocal.active} onChange={(key, value) => handleKeyAccountPropertyChange(key, value)}/>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                    <ButtonClose onClick={() => onClose()}/>
                </DialogActions>
            </Dialog>
            <ConfirmDialog cancelLabelId="a.cancel"
                           confirmLabelId="a.save"
                           onCancel={() => setConfirmationDialogOpen(false)}
                           onConfirm={handleSaveConfirm}
                           open={confirmationDialogOpen}
                           message={confMsg}/>
        </>
    );
}
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {dictionaryWithElements} from 'shared/models/dictionaries.model';
import {states} from 'shared/models/states.model';
import {AttributeError, AttributeLoading} from 'components/Attribute/AttributeHelpers';
import {getTranslation} from 'components/Selectors/IndustrySelector/IndustrySelector';
import {useTypeOfBranchQuery} from './TypeOfBranchAPI';

interface TypeOfBranchSelectorProps {
    value: string,
    onChange: (typeOfBranchId: string) => void,
    required?: boolean
}

export const TypeOfBranchSelector = (props: TypeOfBranchSelectorProps) => {
    const {onChange, value, required} = props;
    const typeOfBranchQuery = useTypeOfBranchQuery();
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [componentState, setComponentState] = useState<states>(states.loading);
    const [typeOfBranchData, setTypeOfBranchData] = useState<dictionaryWithElements>(null);

    useEffect(() => {
        if (typeOfBranchQuery.isLoading) {
            setComponentState(states.loading);
        } else if (typeOfBranchQuery.isError) {
            setComponentState(states.error);
        } else if (typeOfBranchQuery.isSuccess) {
            setComponentState(states.ready);
            setTypeOfBranchData(typeOfBranchQuery.data);
        }
    }, [typeOfBranchQuery]);

    const renderByState = (state: states) => {
        switch (state) {
            case states.loading:
                return <AttributeLoading/>;
            case states.error:
                return <AttributeError errorLabel="typeOfBranchSelector.err"/>
            case states.ready:
                return <FormControl fullWidth required={required}>
                    <InputLabel>{getTranslation(typeOfBranchData, selectedLanguage)}</InputLabel>
                    <Select color="primary"
                            value={value}
                            required={required}
                            onChange={(e) => onChange(e.target.value)}
                            label={getTranslation(typeOfBranchData, selectedLanguage)}
                    >
                        {typeOfBranchData.elements.map((item) => <MenuItem key={item.id} value={item.id}>{getTranslation(item, selectedLanguage)}</MenuItem>)}
                    </Select>
                </FormControl>
            default:
                return <AttributeError errorLabel="typeOfBranchSelector.err"/>
        }
    };

    return renderByState(componentState);
}
export default TypeOfBranchSelector;
import {forwardRef, useState, useImperativeHandle} from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {reasons} from 'shared/models/inputValidation';
import {useFormatMessage} from 'utils/translate';
import {helperText, validationResult} from './NumberInput';
import {promotionCopyLocalStorage} from 'components/Promotion/Promotion';

export type externalError = {
    error: boolean,
    reason: reasons
}

export interface PriceInputRef {
    resetInputValue: () => void
}
interface priceInputProps {
    autoFocus?: any,
    externalError?: externalError,
    label: string,
    onChange: (propIdentifier: string, value: number, error: boolean) => void,
    propIdentifier: string,
    value: number
}

export enum priceReasons {
    'zeroNegative' = 'v.zeroNegative',
    'invalidChars' = 'v.invalidChars',
    'invalidFormulaProduct' = 'v.invalidFormulaProduct'
}

const validateInput = (input: string): number => {
    const forbiddenCharactersRegexp = /[^0-9-+*()/,.]/g;
    const matched = input.replace(forbiddenCharactersRegexp, '');
    const replaced = matched.replace(/,/g,".");
// eslint-disable-next-line no-eval
    return Number(eval(replaced)?.toFixed(2));
}

const PriceInput = forwardRef<PriceInputRef, priceInputProps>((props, ref) => {
    const {onChange, value, propIdentifier, label, autoFocus, externalError} = props;
    const translate = useFormatMessage();
    const [inputValue, setInputValue] = useState<string>(null);
    const [hasError, setHasError] = useState<boolean>(false);
    const [reason, setReason] = useState<reasons>(null);

    useImperativeHandle(ref, () => ({
        resetInputValue() {
            const clipboardPromotion = JSON.parse(localStorage.getItem(promotionCopyLocalStorage));
            if (clipboardPromotion) {
                setInputValue('');
                setHasError(false);
                setReason(null);
                onChange('promotionalPrice', clipboardPromotion.promotionalPrice, false);
                onChange('regularPrice', clipboardPromotion.regularPrice, false);
            }
        },
    }));

    const validate = (value: string): validationResult => {
        const result: validationResult = {
            value: null,
            error: false,
            reason: null
        };

        if (value === '') return result;

        const wantedCharacters = /[^0-9-+*/(),.]+/;

        if (!wantedCharacters.test(value)){
            try {
                const evalResult:number = validateInput(value);
                if (!(evalResult === Infinity || isNaN(evalResult))){
                    if (evalResult > 0) {
                        result.value = evalResult;
                        result.error = false;
                        result.reason = null;
                    } else {
                        result.value = null;
                        result.error = true;
                        result.reason = priceReasons.zeroNegative;
                    }
                }else {
                    result.value = null;
                    result.error = true;
                    result.reason = priceReasons.invalidFormulaProduct;
                }
            } catch (e) {
                result.value = null;
                result.error = true;
                result.reason = priceReasons.invalidFormulaProduct;
            }
        } else {
            result.value = null;
            result.error = true;
            result.reason = priceReasons.invalidChars;
        }

        return result;
    }

    const handleChange = (inputValue: string) => {
        const {error, reason, value} = validate(inputValue);
        setHasError(error);
        setReason(reason);
        setInputValue(inputValue);
        onChange(propIdentifier, value, error);
    };

    return (
        <div className="numInput">
            <TextField fullWidth
                       InputLabelProps={{
                           shrink: true
                       }}
                       InputProps={{
                           endAdornment: <InputAdornment position="end">{value}</InputAdornment>
                       }}
                       error={hasError || externalError?.error}
                       onChange={(e) => handleChange(e.target.value)}
                       value={inputValue}
                       label={translate({id: label})}
                       autoFocus={autoFocus}
                       helperText={helperText(reason || externalError?.reason, translate)}
    />
</div>
);
});

export default PriceInput;
import {
    countryMarketItem, USER_PROFILE_SET_COUNTRY_MARKET,
    USER_PROFILE_SET_LANG_DATA,
    USER_PROFILE_SET_LANG_USER_INTERFACE,
    USER_PROFILE_SET_MARKET,
    USER_PROFILE_SET_OPTIONS_DIALOG_OPEN,
    USER_PROFILE_SET_PREFERRED_COUNTRY
} from './userProfile';

export const setUserLanguageData = (langData: string) => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_LANG_DATA,
        payload: {langData}
    })
};

export const setLanguageUserInterface = (langInterface: string) => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_LANG_USER_INTERFACE,
        payload: {langInterface}
    })
}

export const setPreferredCountry = (country: string) => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_PREFERRED_COUNTRY,
        payload: {country}
    })
};

export const restoreCountryMarket = (countryMarket: countryMarketItem) => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_COUNTRY_MARKET,
        payload: {countryMarket}
    })
};

export const setMarket = (marketId: string) => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_MARKET,
        payload: {marketId}
    })
};

export const openOptionsDialog = () => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_OPTIONS_DIALOG_OPEN,
        payload: {optionsDialogOpen: true}
    })
};

export const closeOptionsDialog = () => (dispatch) => {
    dispatch({
        type: USER_PROFILE_SET_OPTIONS_DIALOG_OPEN,
        payload: {optionsDialogOpen: false}
    })
};
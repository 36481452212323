import './CategoriesHierarchy.scss';

import {ReactElement, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {paths} from 'paths';
import {categoryTableItem, categoryType} from 'shared/models/category.model';
import {dictionaryElement, dictionaryTranslationRow} from 'shared/models/dictionaries.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import AgTable, {defaultColCfgWithSearch} from 'components/AgTable/AgTable';
import {ActivityFilter } from 'modules/MasterData/Category';
import {tree2table} from './categoryIO';
import {useCategoriesHierarchyQuery} from './categoryAPI';
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";
import {GetContextMenuItemsParams, MenuItemDef} from "ag-grid-community";
import {FilterChangedEvent} from "ag-grid-community/dist/lib/events";

export const categoryCreated: string = 'categoryHierarchyCreated';
export const categoryChanged: string = 'categoryHierarchyChanged';
const categoryFilter: string = 'categoriesHierarchyFilter';

interface ColumnFilterFields {
    filterType: string, 
    type: string, 
    filter: string
}
interface CategoriesHierarchyFilterFields {
    "ag-Grid-AutoColumn-cName"?: ColumnFilterFields,
    "ag-Grid-AutoColumn-fName"?: ColumnFilterFields,
    "ag-Grid-AutoColumn-sName"?: ColumnFilterFields,
    "bName"?: ColumnFilterFields
}

type categoryObjectType = dictionaryElement & {
    translations: dictionaryTranslationRow[],
    type: categoryType
}

const CategoriesHierarchy = ({history}) => {
    const translate = useFormatMessage();
    const gridRef:any = useRef();
    const lang = useSelector((state: IRootState) => state.userProfile.langData);
    const [activeFlag, setActiveFlag] = useState<boolean>(undefined);
    const hierarchyAPI = useCategoriesHierarchyQuery(lang, activeFlag);
    const [categoryData, setCategoryData] = useState<categoryTableItem[]>([]);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [filterModel, setFilterModel] = useState();

    useEffect(() => {
        if (hierarchyAPI.data) {
            setCategoryData(tree2table(hierarchyAPI.data));
            if (isFirstLoad){
                JSON.parse(localStorage.getItem(categoryFilter)) && setFilterModel(JSON.parse(localStorage.getItem(categoryFilter)));
                setIsFirstLoad(false);
            }
        }
    }, [hierarchyAPI.data,isFirstLoad]);

    const autoGroupColumnDef: ColDef = {
        flex: 1,
        filter: 'agTextColumnFilter',
        // @ts-ignore getColId() actually works, could replace with colId maybe
        tooltipValueGetter: params => params.node.groupData && params.node.groupData[params.column.getColId()],
        filterValueGetter: params => {
            const colId = params.column.getColId();
            if (colId.includes('sName')) {
                return params.data.sName;
            } else if (colId.includes('fName')) {
                return params.data.fName;
            }
            return params.data.cName;
        },
        cellRendererParams: {
            innerRenderer: (params) => groupCellRenderer(params)
        },
        cellRendererSelector: (params) => {
            if (params.node.allChildrenCount === 1) {
                if (params.node.allLeafChildren[0]) {
                    if (params.node.level === 0 && !params.node.allLeafChildren[0].data.fName) {
                        return {component: () => params.value ? groupCellRenderer(params) : null};
                    } else if (params.node.level === 1 && !params.node.allLeafChildren[0].data.cName) {
                        return {component: () => params.value ? groupCellRenderer(params) : null};
                    } else if (params.node.level === 2 && !params.node.allLeafChildren[0].data.bName) {
                        return {component: () => params.value ? groupCellRenderer(params) : null};
                    }
                    return { component: 'agGroupCellRenderer' };
                }
                return {component: () => params.value ? groupCellRenderer(params) : null};
            }
            return { component: 'agGroupCellRenderer'};
        }
    }

    const handleClick = (value: string, nameKey: string, idKey: string, typeKey: categoryType, isNew: boolean, parentName: string, parentNameKey: string) => {
        let categoryByName: categoryTableItem;

        if (parentNameKey) {
            categoryByName = categoryData.find((item) => item[nameKey] === value && item[parentNameKey] === parentName);
        } else {
            categoryByName = categoryData.find((item) => item[nameKey] === value);
        }
        if (categoryByName === undefined) {
            console.error(`no matching category found for value: ${value}, nameKey: ${nameKey}, idKey: ${idKey}, typeKey: ${typeKey}`);
        } else if (isNew) {
            history.push(`${paths.categoryDetails}?parentId=${categoryByName[idKey]}&type=${typeKey}`);
        } else {
            history.push(`${paths.categoryDetails}?id=${categoryByName[idKey]}&type=${typeKey}`);
        }
    }

    const columnDefs: ColDef[] = [
        {
            field: 'sName',
            rowGroup: true,
            hide: true,
            headerName: translate({id: 'b.categorySegment'})
        },
        {
            field: 'fName',
            rowGroup: true,
            hide: true,
            headerName: translate({id: 'b.categoryFamily'})
        },
        {
            field: 'cName',
            rowGroup: true,
            hide: true,
            headerName: translate({id: 'b.categoryClass'})
        },
        {
            field: 'bName',
            headerName: translate({id: 'b.categoryBrick'}),
            flex: 1,
            tooltipField:'bName',
            cellRenderer: (params) => params.value ? <div className="hierarchyGroupCell">
                <div className="name">{params.value}</div>
                <div>
                    <Link to={`${paths.brickDetails}?getParentForId=${params.data.bId}`}>
                        <Button>{translate({id: "a.new"})}</Button>
                    </Link>
                    <Link to={`${paths.brickDetails}?id=${params.data.bId}`}>
                        <Button>{translate({id: "a.edit"})}</Button>
                    </Link>
                    <Link to={`${paths.categoriesAttributes}?id=${params.data.bId}&from=${paths.categoriesHierarchy}`}>
                        <Button>{translate({id: "attributes.label"})}</Button>
                    </Link>
                </div>
            </div> : null
        }
    ];

    const groupCellRenderer = (params): ReactElement => {
        const colId: string = params.column.getColId();
        const parentName: string = params?.node?.parent?.key;
        let idKey: string = 'cId';
        let nameKey: string = 'cName';
        let parentNameKey: string = 'fName';
        let typeKey: categoryType = categoryType.CLASS;
        if (colId.includes('sName')) {
            idKey = 'sId';
            nameKey = 'sName';
            parentNameKey = null; //no parent for segment
            typeKey = categoryType.SEGMENT;
        } else if (colId.includes('fName')) {
            idKey = 'fId';
            nameKey = 'fName';
            parentNameKey = 'sName';
            typeKey = categoryType.FAMILY;
        }
        return <div className="hierarchyGroupCell">
            <div className="name">{params.value}</div>
            <div>
                <Button onClick={()=>handleClick(params.value, nameKey, idKey, typeKey, true, parentName, parentNameKey)}>new</Button>
                <Button onClick={()=>handleClick(params.value, nameKey, idKey, typeKey,false, parentName, parentNameKey)}>edit</Button>
            </div>
        </div>;
    };

    const getContextMenu = (rowNode: GetContextMenuItemsParams): MenuItemDef[] => [
        {
            name: translate({id: 'category.expandRow'}),
            action: () => {
                rowNode.node.setExpanded(true);
                rowNode.node.childrenAfterGroup.forEach((item) => {
                    item.setExpanded(true);
                    item.childrenAfterGroup?.forEach((item2) => {
                        item2.setExpanded(true);
                    })
                })
            }
        },
        {
            name: translate({id: 'category.expandAll'}),
            action: () => gridRef.current.expandAll()
        }
    ];

    const parseType = (type: categoryType): string => {
        switch (type) {
            case categoryType.SEGMENT:
                return 'ag-Grid-AutoColumn-sName';
            case categoryType.FAMILY:
                return 'ag-Grid-AutoColumn-fName';
            case categoryType.CLASS:
                return 'ag-Grid-AutoColumn-cName';
            case categoryType.BRICK:
                return 'bName';
            default:
                return 'a.error'
        }
    }

    const onFilterChanged = (params: FilterChangedEvent) => {
        let model: CategoriesHierarchyFilterFields;

        if (localStorage.getItem(categoryCreated)) {
            const createdCategoryObject: categoryObjectType = JSON.parse(localStorage.getItem(categoryCreated));
            model = JSON.parse(localStorage.getItem(categoryFilter));
            model[parseType(createdCategoryObject.type)]?.values?.push(createdCategoryObject.translations[lang] || createdCategoryObject.translations["en"]);
            localStorage.removeItem(categoryCreated);
        } else if (localStorage.getItem(categoryChanged)){
            const changedCategoryObject: categoryObjectType = JSON.parse(localStorage.getItem(categoryChanged));
            model = JSON.parse(localStorage.getItem(categoryFilter));
            if (Object.keys(model).length) {
                model[parseType(changedCategoryObject.type)]?.values?.push(changedCategoryObject.translations.find((index) => index.lang === lang ? index : index.lang === "en").trans);
            } else {
                model = params.api.getFilterModel();
            }
            localStorage.removeItem(categoryChanged);
        } else {
            model = params.api.getFilterModel();
        }
        localStorage.setItem(categoryFilter,JSON.stringify(model));
        params.api.setFilterModel(model)
    }

    return (
        <div className="viewRoot categoriesHierarchyRoot">
            <div className="viewport">
                <LoadingOverlay show={hierarchyAPI.isLoading}/>
                <div className="viewContainer overLay">
                    <PaperX className="_fullHeight _fullTable">
                        <AgTable
                            ref={gridRef}
                            onGridReady={(e) => gridRef.current = e.api}
                            rowData={categoryData}
                            defaultColDef={{
                                ...defaultColCfgWithSearch,
                                suppressMovable: true
                            }}
                            columnDefs={columnDefs}
                            autoGroupColumnDef={autoGroupColumnDef}
                            suppressContextMenu={false}
                            getContextMenuItems={getContextMenu}
                            // showOpenedGroup={true}
                            groupDisplayType="multipleColumns"
                            // groupDisplayType="singleColumn"
                            // groupDisplayType="groupRows"
                            groupAllowUnbalanced={true}
                            // groupSuppressBlankHeader={true}
                            onFirstDataRendered={(params) => params.api.setFilterModel(filterModel)}
                            onFilterChanged={onFilterChanged}
                        />
                    </PaperX>
                    <div className="fourRow">
                        <div>
                            <Link to={`${paths.categoryDetails}?type=${categoryType.SEGMENT}`}>
                                <Button className="button" color="primary" variant="outlined" startIcon={<AddIcon />}><FormattedMessage id="a.new"/></Button>
                            </Link>
                        </div>
                        <div>
                            <Link to={`${paths.categoryDetails}?type=${categoryType.FAMILY}`}>
                                <Button className="button" color="primary" variant="outlined" startIcon={<AddIcon />}><FormattedMessage id="a.new"/></Button>
                            </Link>
                        </div>
                        <div>
                            <Link to={`${paths.categoryDetails}?type=${categoryType.CLASS}`}>
                                <Button className="button" color="primary" variant="outlined" startIcon={<AddIcon />}><FormattedMessage id="a.new"/></Button>
                            </Link>
                        </div>
                        <div>
                            <Link to={paths.brickDetails}>
                                <Button className="button" color="primary" variant="outlined" startIcon={<AddIcon />}><FormattedMessage id="a.new"/></Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer
                actionsLeft={<ActivityFilter value={activeFlag} onChange={(value) => setActiveFlag(value)}/>}
                actionsRight={
                    <>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
}

export default CategoriesHierarchy;
/* eslint-disable react-hooks/exhaustive-deps */
import './FranchiseOwner.scss';

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import { Link } from 'react-router-dom';
import {Button, TextField} from '@mui/material';
import {paths} from 'paths';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {
    framchiseOwnerPOSTPayloadModel,
    franchiseOwner,
    franchiseOwnerTemplate
} from 'shared/models/franchiseOwner.model';
import {retailer} from 'shared/models/retailer.model';
import {IRootState} from 'shared/reducers';
import {fetchBranchesPerRetailer} from 'shared/reducers/branchsePerRetailer';
import {API_FRANCHISE_OWNERS, API_GET_FRANCHISE_OWNER, API_STATUSES} from 'config/api/constants';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import { BranchesSelector, RetailerSelector } from 'components/Selectors';
import {PaperX} from 'components/PaperX';

const FranchiseOwner = (props) => {
    const dispatch = useDispatch();
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const ownersAPIGet = useApi('get', franchiseOwnerTemplate, {errMsg: 'franchiseOwner.err'});
    const ownersAPIPost = useApi('post', franchiseOwnerTemplate, {errMsg: 'franchiseOwner.saveErr', succMsg: 'franchiseOwner.succ'});
    const ownersAPIPut = useApi('put', franchiseOwnerTemplate, {errMsg: 'franchiseOwner.saveErr', succMsg: 'franchiseOwner.succ'});
    const translate = useFormatMessage();

    const [currentOwner, setCurrentOwner] = useState<franchiseOwner>(franchiseOwnerTemplate);
    const [retailerId, setRetailerId] = useState<string>('');

    const branchesData = useSelector((state: IRootState) => state.branchesPerRetailer.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesPerRetailer.status);

    useEffect(() => {
        const ownerId: string = getId();
        const retailerId: string = getURLParam('retailerId');
        if (retailerId) {
            setRetailerId(retailerId);
        } else if (ownerId) {
            ownersAPIGet.call(API_GET_FRANCHISE_OWNER(ownerId))
        }
    }, []);

    useEffect(() => {
        if (retailerId !== currentOwner.retailerId) {
            // cleanup selected branches
            setCurrentOwner({...currentOwner, retailerId, branchIds: []});
        } else {
            setCurrentOwner({...currentOwner, retailerId});
        }
        if (retailerId) {
            dispatch(fetchBranchesPerRetailer(retailerId, selectedLanguage));
        }
    }, [retailerId]);

    useEffect(() => {
        if (ownersAPIGet.status === API_STATUSES.IDLE) {
            const owner: franchiseOwner = ownersAPIGet.data;
            setCurrentOwner(owner);
            setRetailerId(ownersAPIGet.data.retailerId);
        }
    }, [ownersAPIGet.status]);

    useEffect(() => {
        if (ownersAPIPost.status === API_STATUSES.IDLE || ownersAPIPut.status === API_STATUSES.IDLE) {
            props.history.push(`${paths.franchiseOwnersList}?id=${retailerId}`);
        }
    }, [ownersAPIPost.status, ownersAPIPut.status]);

    const handleSaveClick = () => {
        if (currentOwner.id) {
            ownersAPIPut.call(`${API_FRANCHISE_OWNERS}/${currentOwner.id}?lang=${selectedLanguage}`, currentOwner);
        } else {
            const {retailerId, name, branchIds} = currentOwner;
            const payload: framchiseOwnerPOSTPayloadModel= {retailerId, name, branchIds}
            ownersAPIPost.call(`${API_FRANCHISE_OWNERS}?lang=${selectedLanguage}`, payload);
        }
    };

    const handleRetailerChange = (retailer: retailer) => setRetailerId(retailer.id);

    const handleNameChange = (name: string) => setCurrentOwner({...currentOwner, name});

    const handleSelectionChange = (branchIds: string[]) => setCurrentOwner({...currentOwner, branchIds});

    // cant create new owner without branches, but can remove branches from existing owner
    const cantSave = (): boolean => !currentOwner.retailerId || !currentOwner.name || (!currentOwner.id && currentOwner.branchIds.length === 0);

    const showLoading = (): boolean => branchesStatus === API_STATUSES.PENDING || ownersAPIGet.status === API_STATUSES.PENDING || ownersAPIPost.status === API_STATUSES.PENDING || ownersAPIPut.status === API_STATUSES.PENDING;

    return (
        <div className="viewRoot franchiseOwnerRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX className="inputsContainer">
                        <div className="retailerWithCountry">
                            <div className="retailerContainer">
                                <RetailerSelector retailerId={currentOwner.retailerId} onRetailerChange={handleRetailerChange}/>
                            </div>
                        </div>
                        <TextField
                            className="_halfWidth"
                            value={currentOwner.name}
                            onChange={(e) => handleNameChange(e.target.value)}
                            label={translate({id: 'franchiseOwner.name'})}
                        />
                    </PaperX>
                    <BranchesSelector branches={branchesData} selectedBranches={currentOwner.branchIds} onSelectionChange={handleSelectionChange}/>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                        <Link to={retailerId ? `${paths.franchiseOwnersList}?id=${retailerId}` : paths.franchiseOwnersList}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default FranchiseOwner;
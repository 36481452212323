/* eslint-disable react-hooks/exhaustive-deps */
import './Brands.scss';

import {useEffect, useRef, useState} from 'react';
import axios, {AxiosError} from 'axios';
import {useSnackbar} from 'notistack';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {responseValidation} from 'utils/responseValidation';
import {FormattedMessage} from 'react-intl';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {RowClickedEvent} from 'ag-grid-community';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {API_GET_BRANDS_LIST} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {PaperX} from 'components/PaperX';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {brandFilters, brandsResponseModel} from 'shared/models/brand.model';
import BrandsFilters from './BrandsFilters';

const Brands = ({history}) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const cancelToken = useRef(null);

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brands, setBrands] = useState<brandsResponseModel>([]);

    useEffect(() => {
        return () => cancelToken.current && cancelToken.current();
    }, []);

    const brandsColumnDefs: ColDef[] = [
        { field: 'active', headerName: translate({ id: 'a.active' }), pinned: 'left', width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), suppressMenu: true},
        { field: 'name', headerName: translate({ id: 'b.brand' }), flex: 1, sortable: true, lockVisible: true},
        { field: 'structured', headerName: translate({ id: 'brand.struct' }), pinned: 'right', width: 160, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), suppressMenu: true, sortable: true},
    ];

    const handleEditBrand = (data) => {
        if (data?.structured) {
            history.push(`${paths.editStructuredBrand}?id=${data.id}`)
        } else {
            history.push(`${paths.editBrand}?id=${data.id}`)
        }
    };

    const getData = (filters: brandFilters) => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current();
        setBrands([]);
        axios.get<brandsResponseModel>(API_GET_BRANDS_LIST, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setBrands(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'brand.errs'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e: AxiosError) => {
                if (axios.isAxiosError(e) || !axios.isCancel(e)) {
                    console.warn(e);
                    enqueueSnackbar(`${translate({id: 'brand.errs'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleFiltersChange = (filters: brandFilters) => {
        if (!filters) return;
        if (filters.search && filters.marketId) {
            getData(filters);
        } else setBrands([]);
    };

    return (
        <div className="viewRoot brandsRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading}/>
                <div className="viewContainer _directionCol brandsListContainer">
                    <PaperX>
                        <BrandsFilters onFilterChange={(filters) => handleFiltersChange(filters)} />
                    </PaperX>
                    <PaperX className="_fullWidth _fullHeight _fullTable">
                        <AgTable rowData={brands}
                                 columnDefs={brandsColumnDefs}
                                 onRowClicked = {(rowData: RowClickedEvent) => handleEditBrand(rowData.data)}
                                 localStorageColumnsKey={localStorageColumnsKeys.brandsList}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={paths.newBrand}>
                            <Button variant="contained" color="primary" disabled={!countryMarket.market}><FormattedMessage id="brand.create"/></Button>
                        </Link>
                        <Link to={paths.newStructuredBrand}>
                            <Button variant="contained" color="primary" disabled={!countryMarket.market}><FormattedMessage id="brand.createStructured"/></Button>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    )
};

export default Brands;
/* eslint-disable react-hooks/exhaustive-deps */
import {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {paths} from 'paths';
import axios, {AxiosError, CancelTokenSource} from 'axios';
import {responseValidation} from 'utils/responseValidation';
import {API_GET_BPCO_DESCRIPTIONS_LIST} from 'config/api/constants';
import {BPCODescriptionResponseModel} from 'shared/models/BPCO.model';
import {descriptionFilters} from 'shared/models/description.model';
import {ButtonClose, ButtonNew} from 'components/Buttons';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import BPCODescriptionsTable from './BPCODescriptionsTable';
import DescriptionsFilter from 'components/DescriptionFilter/DescriptionsFilter';

const storageKey = 'filters_BPCODescriptionsFilter';

const BPCODescriptionList = ({history}) => {
    const cancelToken = useRef<CancelTokenSource | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [BPCODescriptionData, setBPCODescriptionData] = useState<BPCODescriptionResponseModel[]>([]);

    const getData = (filters: descriptionFilters) => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current.cancel();
        setBPCODescriptionData([]);
        axios.get<BPCODescriptionResponseModel[]>(API_GET_BPCO_DESCRIPTIONS_LIST, {
            params: filters,
            cancelToken: cancelToken.current?.token
        })
            .then((response) => {
                if (responseValidation(response.data)) {
                    setBPCODescriptionData(response.data);
                }
            })
            .catch((e: AxiosError) => {
                if (axios.isAxiosError(e)) console.log(e)
            })
            .finally(() => setIsLoading(false));
    };

    const handleFiltersChange = (filters: descriptionFilters) => {
        if (!filters) return;
        if (Object.keys(filters).length > 2 && filters.marketId) {
            getData(filters);
        }
    };

    const handleEditBPCODescription = (BPCODescription: BPCODescriptionResponseModel) => history.push(`${paths.BPCODescription}?id=${BPCODescription.id}`);

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <LoadingOverlay show={isLoading}/>
                    <PaperX>
                        <DescriptionsFilter onFilterChange={(filters) => handleFiltersChange(filters)}
                                            storageKey={storageKey}
                        />
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _fullTable">
                        <BPCODescriptionsTable data={BPCODescriptionData}
                                               onRowClick={(description) => handleEditBPCODescription(description)}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={`${paths.BPCODescription}?new=true`}>
                            <ButtonNew/>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    )
};
export default BPCODescriptionList;
import './MediumProposals.scss';
import {useFormatMessage} from 'utils/translate';

interface MediumProposalsProps {
    transcriptProposals: string[],
    handleSelectProposal: (proposal: string) => void
}

const MediumProposals = ({transcriptProposals, handleSelectProposal}: MediumProposalsProps) => {
    const translate = useFormatMessage();

    return ( 
        <div className="mediumProposalsRoot">
            <p className="mediumProposalsTitle">{translate({id: 'media.transcriptProposals'})}</p>
                {transcriptProposals.map((proposal, i) => (
                    <div key={i} className="mediumProposalItem" onClick={() => handleSelectProposal(proposal)}>
                        {proposal}
                    </div>
                ))}
        </div>
   )
}

export default MediumProposals;
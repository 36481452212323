import {FormattedMessage} from 'react-intl';

interface frameValidityProps {
    frameValidity: frameValidityTemplate
}

export type frameValidityTemplate = {
    validFrom: string,
    validTo: string
}
const FrameValidity = (props:frameValidityProps) => {
    const {frameValidity} = props;

    return (
        <div>
            <FormattedMessage id="frame.validity"/>:
            {frameValidity.validFrom && frameValidity.validTo ?
                <span className="_bold"> {frameValidity.validFrom} - {frameValidity.validTo}</span> : null }
        </div>
    );
};

export default FrameValidity;
/* eslint-disable react-hooks/exhaustive-deps */

import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {IRootState} from 'shared/reducers';
import {setMarket} from 'shared/reducers/userProfileActions';
import {markets} from 'shared/reducers/countryMarketConst';
import {useFormatMessage} from 'utils/translate';

interface marketSelectorProps {
    required?: boolean
}

const MarketSelector = ({required}: marketSelectorProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const buildMarketsList = (): React.ReactNode[] => markets
        .map((market) => (<MenuItem key={market.id} value={market.id}>{market.name}</MenuItem>))

    const marketsList = useMemo(buildMarketsList, [markets]);
    
    const handleMarketChange = (marketId: string) => dispatch(setMarket(marketId));

    return (
        <FormControl fullWidth required={required}>
            <InputLabel color="primary"><FormattedMessage id="b.market"/></InputLabel>
            <Select color="primary" size={"medium"}
                    defaultValue={""}
                    value={countryMarket.market}
                    onChange={(e) => handleMarketChange(e.target.value)}
                    label={translate({id: 'b.market'})}
            >
                {marketsList}
            </Select>
        </FormControl>
    );
};

export default MarketSelector;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl/lib';
import {useSnackbar} from 'notistack';
import {exportMultipleSheetsAsExcel} from 'ag-grid-enterprise';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {GridReadyEvent} from 'ag-grid-community/dist/lib/events';
import {GridApi} from 'ag-grid-community/dist/lib/gridApi';
import PaperX from 'components/PaperX/PaperX';
import AgTable from 'components/AgTable/AgTable';
import {useStatsMediaQuery, useStatsPromoQuery} from './statisticsAPI';
import {useFormatMessage} from 'utils/translate';
import {statisticsUI} from 'shared/models/statistics.model';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {countryMarketItem} from 'shared/reducers/userProfile';

interface StatisticsGeneralProps {
    setParentClickHandler: Function,
    countryMarket: countryMarketItem
}

const StatisticsGeneral = ({setParentClickHandler, countryMarket}: StatisticsGeneralProps) => {
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();
    const notifyError = (msgId: string) => enqueueSnackbar(`${translate({id: msgId})}`, {variant: 'error', persist: false});

    const productGridRef = useRef<GridApi>();
    const mediaGridRef= useRef<GridApi>();

    const statsPromoQuery = useStatsPromoQuery(countryMarket.preferredCountry, () => notifyError('statistics.promoErr'));
    const statsMediaQuery = useStatsMediaQuery(countryMarket.preferredCountry,() => notifyError('statistics.mediaErr'));

    const [promoData, setPromoData] = useState<statisticsUI>([]);
    const [mediaData, setMediaData] = useState<statisticsUI>([]);
    const [colDefs, setColDefs] = useState<ColDef[]>([]);

    const buildColDefs = () => {
        const columns: ColDef[] = [{field: '0', headerName: translate({id: 'statistics.wkYr'}), width: 100, pinned: 'left'}];
        for (let i =1; i< 54; i++) {
            columns.push(
                { field: '' + i, headerName: '' + i, width: 80},
            )
        }
        setColDefs(columns);
    }

    useEffect(() => {
        buildColDefs();
    }, []);

    useEffect(() => {
        setParentClickHandler(() => export2excel);
    }, [countryMarket]);

    useEffect(() => {
        if (statsPromoQuery.data) {
            const data: statisticsUI = statsPromoQuery.data.map((row) => [row.year].concat(row.countPerWeek));
            setPromoData(data);
        }
    }, [statsPromoQuery.data]);

    useEffect(() => {
        if (statsMediaQuery.data) {
            const data: statisticsUI = statsMediaQuery.data.map((row) => [row.year].concat(row.countPerWeek));
            setMediaData(data);
        }
    }, [statsMediaQuery.data]);

    const showLoading = (): boolean => statsPromoQuery.isLoading || statsMediaQuery.isLoading;

    const getContextMenu = (rowNode: GetContextMenuItemsParams, label: string): MenuItemDef[] => [
            {
                name: translate({id: 'a.exportExcel'}),
                action: () => rowNode.api.exportDataAsExcel({
                    fileName: `${translate({id: label})} ${countryMarket.preferredCountry}`,
                    sheetName: translate({id: label})
                })
            }
        ];

    const export2excel = () => {
        const spreadsheets = [];
        if (productGridRef.current && mediaGridRef.current) {
            spreadsheets.push(
                productGridRef.current.getSheetDataForExcel({ sheetName: translate({id: 'statistics.prod'}) }),
                mediaGridRef.current.getSheetDataForExcel({ sheetName: translate({id: 'statistics.media'}) })
            );
            exportMultipleSheetsAsExcel({
                data: spreadsheets,
                fileName: `${translate({id: 'nav.overviewQuality'})} ${countryMarket.preferredCountry}`,
            });
        }
    }

    return (
        <>
            <LoadingOverlay show={showLoading()}/>
            <PaperX className="weldBottom">
                <div className="_header"><FormattedMessage id="statistics.prod"/></div>
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _fullTable weldTop">
            <AgTable
                suppressContextMenu={false}
                rowData = {promoData}
                defaultColDef={{suppressMenu: true, tooltipValueGetter: () => translate({id: 'a.right4context'})}}
                columnDefs = {colDefs}
                getContextMenuItems={(rowData) => getContextMenu(rowData, 'statistics.prod')}
                onGridReady={(e: GridReadyEvent) => productGridRef.current = e.api}/>
            </PaperX>
            <PaperX className="weldBottom">
                <div className="_header"><FormattedMessage id="statistics.media"/></div>
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _fullTable weldTop">
                <AgTable
                    suppressContextMenu={false}
                    rowData = {mediaData}
                    defaultColDef={{suppressMenu: true, tooltipValueGetter: () => translate({id: 'a.right4context'})}}
                    getContextMenuItems={(rowData) => getContextMenu(rowData, 'statistics.media')}
                    columnDefs = {colDefs}
                    onGridReady={(e: GridReadyEvent) => mediaGridRef.current = e.api}/>
            </PaperX>
        </>
    );
};

export default StatisticsGeneral;
/* eslint-disable react-hooks/exhaustive-deps */
import './ProductLineList.scss';
import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Button, CircularProgress, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {paths} from 'paths';
import {
    API_PRODUCT_DATA_PRODUCT_LINE,
    API_GET_PRODUCT_LINES,
    API_STATUSES
} from 'config/api/constants';
import {productLine, productLineResponseModel} from 'shared/models/productLine.model';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import useDebounce from 'utils/debounce';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {ButtonNew} from 'components/Buttons';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {BtnProductLineRenderer} from './BtnProductLineRenderer';

const ProductLineList = ({history}) => {
    const translate = useFormatMessage();
    const [queryStr, setQueryStr] = useState('');
    const [dbncdQueryStr, setDbncdQueryStr] = useDebounce(queryStr, 500);
    const cancelToken = useRef(null);
    const [status, setStatus] = useState(API_STATUSES.NEW);

    const productLineAPIGet= useApi('get', [], {errMsg: 'productLine.listErr'});
    const productLineAPIGetSingle= useApi('get', null, {errMsg: 'productLine.err'});

    const [productLines, setProductLines] = useState<productLineResponseModel>([]);
    const [productsForProductLine, setProductsForProductLine] = useState([]);
    const [selectedProductLine, setSelectedProductLine] = useState<productLine>(null);
    const getHeaderLabel = () => <span>{translate({id: 'productLine.productsOf'})}:&nbsp; {selectedProductLine ? selectedProductLine.name : '-'}</span>;

    useEffect(() => {
        setProductLines(productLineAPIGet.data.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    }, [productLineAPIGet.data]);

    useEffect(() => {
        setSelectedProductLine(productLineAPIGetSingle.data);
    }, [productLineAPIGetSingle.data]);

    useEffect(() => {
        if (selectedProductLine === null || selectedProductLine === undefined) {
            setProductsForProductLine([]);
        } else {
            setProductsForProductLine(selectedProductLine.products);
        }
    }, [selectedProductLine]);

    useEffect(() => {
            getData(cancelToken.current);
    }, [dbncdQueryStr]);

        const getData = (origToken) => {
        if (origToken) {
            origToken();
        }
        setStatus(API_STATUSES.PENDING);
            axios.get(API_GET_PRODUCT_LINES, {
                params: {search: dbncdQueryStr},
                cancelToken: new axios.CancelToken(
                    cancel => (cancelToken.current = cancel)
                )
            })
                .then(resp => {
                    setProductLines(resp.data);
                    setStatus(API_STATUSES.IDLE);
                })
                .catch(e => {
                    if (!e.__CANCEL__) {
                        console.log(e)
                    }
                });
    };

    const handleSearchInputChange = (val) => {
        setQueryStr(val);
        setDbncdQueryStr(val)
    };

    const handleEdit = () => {
        history.push(`${paths.productLine}?id=${selectedProductLine.id}`);
    }

    const showProductLineProducts = (productLine) => productLineAPIGetSingle.call(API_PRODUCT_DATA_PRODUCT_LINE(productLine.id));

    const productLineColumnDefs = [
        { field: 'active', headerName: translate({ id: 'a.active' }), width: 75, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)},
        { field: 'name', headerName: translate({ id: 'b.productLine' }), flex: 1,
            cellRenderer: (params) => <BtnProductLineRenderer value={params.value} id={params.data.id}/>
        }
    ];

    const productsColumnDefs = [
        { field: 'brand', headerName: translate({ id: 'b.brand' }), flex: 1, lockVisible: true},
        { field: 'description', headerName: translate({ id: 'b.descr' }), flex: 1},
        { field: 'typeOfPackage', headerName: translate({ id: 'b.typeOfPackage'}), flex: 1},
        { field: 'capacity', headerName: translate({ id: 'b.capacity' }), flex: 1,
            valueGetter: (params) => {
                const from = params.data.capacityFrom;
                const to = params.data.capacityTo;
                if (from || to) {
                    if (from === to) {
                        return from;
                    }
                    return `${from} - ${to}`;
                }
                return '';
            }
        },
        { field: 'capacityUnit', headerName: translate({ id: 'b.capacityUnit'}), flex: 1},
        { field: 'contentOfTradingUnits', headerName: translate({ id: 'b.contentOfTradingUnit'}), flex: 1},
        { field: 'contentOfTradingUnitsUnit', headerName: translate({ id: 'b.contentOfTradingUnitUnit'}), flex: 1},
        { field: 'variety', headerName: translate({ id: 'b.variety'}), flex: 1},
        { field: 'active', headerName: translate({ id: 'a.active'}), width: 75, suppressMenu: true, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)}
    ];

    return (
        <div className="viewRoot productLineListRoot">
            <div className="viewport">
                <LoadingOverlay show={productLineAPIGet.status === API_STATUSES.PENDING || productLineAPIGetSingle.status === API_STATUSES.PENDING}/>
                <div className="viewContainer _directionCol">
                    <div className="_directionRow">
                        <div className="_directionCol">
                            <PaperX className="weldBottom productLineListIndexRoot">
                                <div className="_header"><FormattedMessage id="masterData.productLines"/></div>
                                <div className="spacer"/>
                                <TextField className="_fullWidth"
                                       onChange={(e) => handleSearchInputChange(e.target.value)}
                                       label={translate({id: 'searchProductLine'})}
                                       value={queryStr}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start">
                                               {status === API_STATUSES.PENDING ? <CircularProgress size={24}/>: <SearchIcon color="secondary"/> }
                                           </InputAdornment>
                                       }}
                                />
                            </PaperX>
                            <PaperX className="weldTop _fullHeight">
                                <AgTable
                                    rowData={productLines}
                                    defaultColDef={{
                                        suppressMenu: true,
                                        suppressMovable: true
                                    }}
                                    columnDefs={productLineColumnDefs}
                                    onRowClicked={(rowData) => showProductLineProducts(rowData.data)}
                                />
                            </PaperX>
                        </div>
                        <PaperX  className="_fullWidth _fullHeight">
                            <div className="_directionCol">
                                <div className="_header">{getHeaderLabel()}</div>
                                <AgTable
                                    rowData={productsForProductLine}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true
                                    }}
                                    columnDefs={productsColumnDefs}
                                    localStorageColumnsKey={localStorageColumnsKeys.productLineList}
                                />
                            </div>
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleEdit} disabled={!selectedProductLine}>{translate({id: 'a.edit'})}</Button>
                        <Link to={paths.productLine}>
                            <ButtonNew/>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    )
};

export default ProductLineList;
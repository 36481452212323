import {advertisementsOverviewRow} from 'shared/models/advertisement.model';
import GeneralInfoDisplay from 'components/Displays/GeneralInfoDisplay';

interface GeneralCellProps {
    data: advertisementsOverviewRow
}

const GeneralCell = (props: GeneralCellProps) => {
    const {countryThemedWeeks, promotionTypes, themeSeasons, qualitySeals} = props.data;
    return <GeneralInfoDisplay countryThemedWeeks={countryThemedWeeks}
                               promotionTypes={promotionTypes}
                               themeSeasons={themeSeasons}
                               qualitySeals={qualitySeals}/>;
};

export default GeneralCell;
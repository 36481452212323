import './IconTile2.scss';

import { FunctionComponent, useState } from 'react';

type IconTileProps = {
  Icon: FunctionComponent<any>,
  title: string,
  onClick?: Function
};

const IconTile2 = ({Icon, title, onClick}: IconTileProps) => {
  const [color, setColor] = useState('primary');
  const onMouseOver = () => setColor('secondary');
  const onMouseOut = () => setColor('primary');

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="iconTileRoot" onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={handleClick}>
      <div className="content">
        <div className="icon">
          <Icon color={color}/>
        </div>
        <p className="title">{title}</p>
      </div>
    </div>
  )
};

export default IconTile2;
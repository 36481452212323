import {categoryType} from 'shared/models/category.model';
import {handyAttributes} from 'shared/handyAttributes';
import {BPCODescriptionPayload, BPCODescriptionSingleResponseModel, BPCODescriptionUI, BPCODescriptionUpdatePayload, BPCOPayloadModel, BPCOResponseModel, UIBPCOModel} from 'shared/models/BPCO.model';
import {v4 as uuid} from 'uuid';

export const transformForUI = (BEModel: BPCOResponseModel): UIBPCOModel => {
    if (!BEModel) {
        return null;
    }
    const UIModel = {
        id: BEModel.id,
        active: BEModel.active,
        brand: null,
        category: null,
        characteristics: {},
        descriptionId: BEModel.descriptionId,
        gtins: BEModel.gtins,
        marketId: BEModel.marketId,
        mainDescriptions: BEModel.mainDescriptions,
        alternativeDescriptions: BEModel.alternativeDescriptions,
        supplierId: BEModel.supplierId,
        supplierName: BEModel.supplierName
    };
    if (BEModel.categoryBrickId) {
        UIModel.category = {
            id: BEModel.categoryBrickId,
            name: BEModel.categoryBrickName,
            type: categoryType.BRICK
        }
    }
    if (BEModel.categoryClassId) {
        UIModel.category = {
            id: BEModel.categoryClassId,
            name: BEModel.categoryClassName,
            type: categoryType.CLASS
        }
    }
    if (BEModel.categoryFamilyId) {
        UIModel.category = {
            id: BEModel.categoryFamilyId,
            name: BEModel.categoryFamilyName,
            type: categoryType.FAMILY
        }
    }
    if (BEModel.categorySegmentId) {
        UIModel.category = {
            id: BEModel.categorySegmentId,
            name: BEModel.categorySegmentName,
            type: categoryType.SEGMENT
        }
    }
    if (BEModel.brandId) {
        UIModel.brand = {
            id: BEModel.brandId,
            name: BEModel.brandName,
        };
    }

    UIModel.characteristics[handyAttributes.description] = null;
    UIModel.characteristics[handyAttributes.capacity] = null;
    UIModel.characteristics[handyAttributes.contentOfTradingUnit] = null;

    BEModel.characteristics.forEach((item) => {
        UIModel.characteristics[item.attributeId] = item;
    });
    return UIModel;
};

export const transformBPCOForBE = (UIModel: UIBPCOModel): BPCOPayloadModel => {
    const BEModel: BPCOPayloadModel = {
        active: UIModel.active,
        brandId: UIModel.brand ? UIModel.brand.id : '',
        categorySegmentId: UIModel.category?.type === categoryType.SEGMENT ? UIModel.category.id : undefined,
        categoryFamilyId: UIModel.category?.type === categoryType.FAMILY ? UIModel.category.id : undefined,
        categoryClassId: UIModel.category?.type === categoryType.CLASS ? UIModel.category.id : undefined,
        categoryBrickId: UIModel.category?.type === categoryType.BRICK ? UIModel.category.id : undefined,
        characteristics: [],
        descriptionId: UIModel.descriptionId ? UIModel.descriptionId : null,
        gtins: UIModel.gtins,
        marketId: UIModel.marketId,
        supplierId: UIModel.supplierId || null
    };
    // @TODO not best way to build characteristics, but BPCOs will be reworked anyways
    for (const id in UIModel.characteristics) {
        if (UIModel.characteristics[id]) {
            let characteristic = null;
            if (UIModel.characteristics[id].hasOwnProperty('text')) {
                characteristic = {
                    attributeId: id,
                    text: UIModel.characteristics[id].text
                };
            } else if (UIModel.characteristics[id].hasOwnProperty('value')) {
                characteristic = {
                    attributeId: id,
                    unitId: UIModel.characteristics[id].unitId,
                    value: UIModel.characteristics[id].value
                };
            } else if (UIModel.characteristics[id].hasOwnProperty('unitId')) {
                characteristic = {
                    attributeId: id,
                    unitId: UIModel.characteristics[id].unitId,
                    valueFrom: UIModel.characteristics[id].valueFrom,
                    valueTo: UIModel.characteristics[id].valueTo
                };
            }
            BEModel.characteristics.push(characteristic);
        }
    }
    return BEModel;
};

export const transformBPCODescriptionUpdatePayload = (UIModel: BPCODescriptionPayload): BPCODescriptionUpdatePayload => {
    return {
        mainDescriptions: UIModel.mainDescriptions.map((item) => ({language: item.language, description: item.description})),
        alternativeDescriptions: UIModel.alternativeDescriptions.map((item) => ({language: item.language, description: item.description}))
    };
};

export const transformBPCODescriptionForBE = (description: BPCODescriptionUI): BPCODescriptionPayload => {
    return {
        marketId: description.marketId,
        alternativeDescriptions: description.alternativeDescriptions.map((item) => ({language: item.language, description: item.description})),
        mainDescriptions: description.mainDescriptions.map((item) => ({language: item.language, description: item.description}))
    }
}

export const transformBPCODescriptionForUI = (BPCODescription: BPCODescriptionSingleResponseModel): BPCODescriptionUI => {
    const {alternativeDescriptions, id, mainDescriptions, marketId} = BPCODescription;
    return {
        alternativeDescriptions: alternativeDescriptions.map((item) => (
            {
                language: item.language,
                description: item.description,
                UIId: uuid()
            }
        )),
        id,
        mainDescriptions: mainDescriptions.map((item) => (
            {
                language: item.language,
                description: item.description,
                UIId: uuid()
            }
        )),
        marketId
    }
};
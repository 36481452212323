import './ImportGtins.scss';
import {useState, useRef, ChangeEvent} from 'react';
import {FormattedMessage} from 'react-intl';
import {useFormatMessage} from 'utils/translate';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {API_IMPORT_GTINS_BLOCKING, ERROR_RESPONSES} from 'config/api/constants';
import {importGtinsSummaryResponseModel} from 'shared/models/gtin.model';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ButtonClose from 'components/Buttons/ButtonClose/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';

const ImportGtins = () => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const fileInputRef = useRef(null);
    const [isImportGtinsDialogOpen, setIsImportGtinsDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<importGtinsSummaryResponseModel>(null);

    const handleImportGtins = async () => fileInputRef.current.click();

    const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            axios.post<importGtinsSummaryResponseModel>(API_IMPORT_GTINS_BLOCKING, formData)
                .then((response) => {
                    setData(response.data);
                    setIsImportGtinsDialogOpen(true);
                })
                .catch((err) => {
                    if (err?.response?.data?.message === ERROR_RESPONSES.IMPORT_GTIN_INVALID_FILE_EXTENSION) {
                      console.error(err.response);
                      enqueueSnackbar(`${translate({id: 'gtin.errorUploadingFileExtension'})}`, {variant: 'error', persist: false});
                    } else {
                      console.error(err);
                      enqueueSnackbar(`${translate({id: 'gtin.errorUploadingFile'})}`, {variant: 'error', persist: false});
                    }
                })
                .finally(() => setIsLoading(false));
        } else console.error(`Error with selecting file, event: ${e}`)
        fileInputRef.current.value = null;
    }

    const handleCloseImportGtinsDialog = () => setIsImportGtinsDialogOpen(false);

    return (
        <div className="importGtinsRoot">
            <Button variant="outlined" color="secondary" onClick={handleImportGtins} startIcon={<FileUploadIcon />} disabled={isLoading}>
                {translate({id: 'gtin.importGtins'})}
            </Button>
            <input
                className="fileInputRef"
                type="file"
                accept=".csv"
                ref={fileInputRef}
                onChange={handleFileSelect}
            />
            <LoadingOverlay show={isLoading}/>
            <Dialog open={isImportGtinsDialogOpen} onClose={handleCloseImportGtinsDialog} maxWidth={'sm'} fullWidth className="importGtinsDialogRoot">
                <DialogTitle>
                    <FormattedMessage id="a.summary"/>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <ul className="importGtinsSummaryList">
                        <li>{translate({id: 'gtin.recordInFile'})}: <span className="_bold">{data?.recordsInFile}</span></li>
                        <li>{translate({id: 'gtin.numberOfAdjustedProducts'})}: <span className="_bold">{data?.numberOfModifiedProducts}</span></li>
                        <li>{data?.incorrectProductIds && data?.incorrectProductIds?.length > 0 && `${translate({id: 'gtin.incorrectProducts'})}:`}
                            <ul className="incorrectValues _bold">
                                {data?.incorrectProductIds && data?.incorrectProductIds?.length > 0 && data?.incorrectProductIds.map((item,id) => <li key={id}>{item}</li>)}
                            </ul>
                        </li>
                        <li>{data?.incorrectGtins && data?.incorrectGtins?.length > 0 && `${translate({id: 'gtin.incorrectGtins'})}:`}
                            <ul className="incorrectValues _bold">
                                {data?.incorrectGtins && data?.incorrectGtins?.length > 0 && data?.incorrectGtins.map((item,id) => <li key={id}>{item}</li>)}
                            </ul>
                        </li>                        
                    </ul>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <ButtonClose onClick={handleCloseImportGtinsDialog}/>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default ImportGtins;

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_UPDATE_BRAND} from 'config/api/constants';
import {paths} from 'paths';
import {basicBrandDuplicateResponseModel, basicBrandPayload, brand} from 'shared/models/brand.model';
import {useFormatMessage} from 'utils/translate';
import {getId} from 'utils/routing';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import BrandDetails from './BrandDetails';
import {POSTPUTAPICallParams} from './BrandNewView';

const BrandEditView = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brandId, setBrandId] = useState<string>('');
    const [brand, setBrand] = useState<brand>(null);
    const [isBrandDuplicateDialogOpen, setIsBrandDuplicateDialogOpen] = useState<boolean>(false);
    const [duplicatedBrands, setDuplicatedBrands] = useState<string[]>([]);

    useEffect(() => {
        const id = getId();
        if (id) {
            setBrandId(id);
        }
    }, []);

    const handleBrandChange = (brand: brand) => setBrand(brand);

    const handleSaveClick = (force: boolean = false) => {
        setIsLoading(true);
        const payload: basicBrandPayload = {
            active: brand.active,
            name: brand.name,
            supplierIds: brand.suppliers.map((item) => item.id)
        };
        const params: POSTPUTAPICallParams = {
            marketId: brand.marketId
        };
        if (force) {
            params.force = true;
        }
        axios.put(API_UPDATE_BRAND(brandId), payload,  {
            params
        })
            .then(() => cleanAndClose(true))
            .catch((e) => {
                console.log(e);
                if (e?.response?.status === 409) {
                    const resp: basicBrandDuplicateResponseModel = e?.response?.data;
                    if (Array.isArray(resp?.potentialDuplicates)) {
                        setDuplicatedBrands(resp.potentialDuplicates);
                    }
                    setIsBrandDuplicateDialogOpen(true);
                } else {
                    enqueueSnackbar(`${translate({id: 'brand.saveErr'})}`, {variant: 'error', persist: false});
                }
                setIsLoading(false);
            });
    }

    const cleanAndClose = (reload:boolean = false) => {
        setIsLoading(false);
        setDuplicatedBrands([]);
        setIsBrandDuplicateDialogOpen(false);
        if (reload) {
            history.push(`${paths.brands}?reload=true`);
        } else {
            history.push(paths.brands);
        }
    };

    const canSave = ():boolean => {
        if (brand && brandId) {
            if (!brand.name || !brand.marketId) {
                return false;
            }
            return true;
        }
        return false;
    };

    const handleConfirm = () => handleSaveClick(true);

    const handleCancel = () => {
        setIsBrandDuplicateDialogOpen(false);
        setDuplicatedBrands([]);
    };

    const getDialogContent = (): JSX.Element => {
        return <>
            <div>
                <FormattedMessage id='brand.duplicateConfirmation1'/>
            </div>
            <div className="_bold">
                {brand?.name}
            </div>
            <div>
                <FormattedMessage id='brand.duplicateFound'/>:
            </div>
            <ul>
                {duplicatedBrands
                    .map((item, idx) =>
                        <li key={idx}><span className="_bold">{item}</span></li>)}
            </ul>
            <br/>
            {translate({id: 'brand.duplicateConfirmation2'})}
        </>;
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={isLoading}/>
                    {brandId && <BrandDetails brandId={brandId} onBrandChange={(brand: brand) => handleBrandChange(brand)}/>}
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={() => handleSaveClick()} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        <ButtonClose onClick={cleanAndClose}/>
                    </>
                }
            />
            <ConfirmDialog open={isBrandDuplicateDialogOpen}
                           onConfirm={handleConfirm}
                           onCancel={handleCancel}
                           message={getDialogContent()}
                           confirmLabelId="a.save"
                           cancelLabelId="a.close"
            />
        </div>
    );
};

export default BrandEditView;
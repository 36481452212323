export const checkGtin = (gtin:string):boolean => {
    if (!checkBasics(gtin)) {
        return false;
    }

    let checkDigitArray:number[] = [];
    const gtinMaths:number[] = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
    const modifier:number = 17 - (gtin.length - 1);
    const barcodeArray:string[] = gtin.split("");
    const gtinLength:number = gtin.length;
    let tmpCheckSum:number = 0;

    for (let i=0; i < (gtinLength - 1); i++) {
        checkDigitArray[modifier + i] = parseInt(barcodeArray[i], 10);
    }
    for (let i=modifier; i < 17; i++) {
        tmpCheckSum += (checkDigitArray[i] * gtinMaths[i]);
    }

    let tmpCheckDigit:number = (Math.ceil(tmpCheckSum / 10) * 10) - tmpCheckSum;
    
    if (parseInt(gtin.slice(-1), 10) === tmpCheckDigit) {
        return true;
    }
    return false;
}

function checkBasics(gtin:string):boolean {
    if (![8, 12, 13, 14].includes(gtin.length)) {
        return false;
    }
    const m = gtin.match(/\d+/);
    if (!m || isNaN(parseInt(m[0], 10))) {
        return false;
    }
    return true;
}

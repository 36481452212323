import './Logo.scss';

const MPMLogo = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="239" height="64" viewBox="0 0 239 64" className="logoRoot">
    <path fill="#FFF" d="M9.338 26.924c3.083-6.54 9.786-11.08 17.492-11.08 7.783 0 14.487 4.694 17.569 11.311-3.082 6.54-9.786 11.08-17.492 11.08-7.783 0-14.486-4.693-17.569-11.31"/>
    <path fill="#820F35" d="M0 27.091c0 14.72 12.108 26.737 26.624 26.737-10.905 0-18.652-8.787-18.652-18.851 0-10.364 8.499-18.85 18.877-18.85 7.596 0 14.14 4.58 17.148 11.04 1.128-2.329 1.805-5.107 1.805-8.036C45.802 8.917 37.905.28 27.15.28 12.033.28 0 12.221 0 27.091"/>
    <path fill="#EB002D" d="M26.892 19.28c-4.287 0-7.822 3.455-7.822 7.736 0 4.356 3.535 7.81 7.822 7.81s7.747-3.454 7.747-7.81a7.73 7.73 0 0 0-7.747-7.735"/>
    <path fill="#EB002D" d="M53.765 27.016C53.765 12.296 41.657.28 27.141.28c10.905 0 18.624 8.787 18.624 18.85 0 10.365-8.47 18.85-18.85 18.85-7.595 0-14.139-4.58-17.147-11.039C8.64 29.27 7.88 32.048 7.88 34.977c0 10.214 7.981 18.85 18.736 18.85 15.117 0 27.15-11.94 27.15-26.81"/>
    <text className="logoTop" x="64" y="26">data</text>
    <text className="logoBottom" x="64" y="50">entering</text>
  </svg>
};

export default MPMLogo;

/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {Button} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
    API_COUNT_PRODUCTS_FOR_DESCRIPTION, API_PRODUCT_DATA_DESCRIPTION,
    API_PRODUCT_DATA_GET_DESCRIPTION,
    API_STATUSES
} from 'config/api/constants';
import {paths} from 'paths';
import {IRootState} from 'shared/reducers';
import {
    countProductsForDescriptionsResponse,
    productDescriptionReplacePayload,
    productDescriptionResponseModel,
    productDescriptionUI
} from 'shared/models/productDescription.model';
import {getId} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {transformDescriptionForUI} from 'modules/MasterData/ProductDescription/productDescriptionIO'
import SelectDescriptionDialog from 'modules/MasterData/Product/ProductDetails/SelectDescriptionDialog';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {PaperX} from 'components/PaperX';
import DescriptionDisplayText from './DescriptionDisplayText';

const ProductDescriptionReplacement = ({history}) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();
    const userProfile = useSelector((state: IRootState) => state.userProfile);
    const [description2replaceId, setDescription2replaceId] = useState<string>('');
    const [description2replace, setDescription2replace] = useState<productDescriptionUI>(null);
    const [replacementDescription, setReplacementDescription] = useState<productDescriptionUI>(null);
    const [selectDescriptionDialogOpen, setSelectDescriptionDialogOpen] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const [productCount, setProductCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const productDescriptionAPIGet = useApi('get', null, {errMsg: 'productDescription.err'});
    const replacementDescriptionAPIGet = useApi('get', null, {errMsg: 'productDescription.err'});
    const countProductsAPI = useApi('get', null, {errMsg: 'a.error2'});

    useEffect(() => {
        setDescription2replaceId(getId());
    }, []);

    useEffect(() => {
        if (description2replaceId) {
            productDescriptionAPIGet.call(API_PRODUCT_DATA_GET_DESCRIPTION(description2replaceId, userProfile.langData));
        }
    }, [description2replaceId]);

    useEffect(() => {
        if (productDescriptionAPIGet.status === API_STATUSES.IDLE){
            const resp: productDescriptionResponseModel = productDescriptionAPIGet.data;
            if (responseValidation(resp)) {
                setDescription2replace(transformDescriptionForUI(resp));
            } else {
                enqueueSnackbar(translate({id: 'productDescription.err'}), {variant: 'error'});
            }
        }
    },[productDescriptionAPIGet.status]);

    useEffect(() => {
        if (replacementDescriptionAPIGet.status === API_STATUSES.IDLE){
            const resp: productDescriptionResponseModel = replacementDescriptionAPIGet.data;
            if (responseValidation(resp)) {
                setReplacementDescription(transformDescriptionForUI(resp));
            } else {
                enqueueSnackbar(translate({id: 'productDescription.err'}), {variant: 'error'});
            }
        }
    },[replacementDescriptionAPIGet.status]);

    useEffect(() => {
        if (countProductsAPI.status === API_STATUSES.IDLE){
            const resp: countProductsForDescriptionsResponse = countProductsAPI.data;
            if (responseValidation(resp)) {
                setProductCount(resp.productCount);
                setConfirmDialogOpen(true);
            } else {
                enqueueSnackbar(translate({id: 'a.error2'}), {variant: 'error'});
            }
        }
    },[countProductsAPI.status]);

    const handleSelectDescription = (id: string) => {
        setSelectDescriptionDialogOpen(false);
        replacementDescriptionAPIGet.call(API_PRODUCT_DATA_GET_DESCRIPTION(id, userProfile.langData));
    };

    const cantReplace = (): boolean => {
        if (description2replace && replacementDescription) {
            if (description2replace.id === replacementDescription.id) return true; // cant replace with same
            if (description2replace.marketId !== replacementDescription.marketId) return true;
            if (description2replace.category.id !== replacementDescription.category.id) return true;
            return false;
        }
        return true;
    };

    const count = () => {
        setProductCount(0);
        countProductsAPI.call(API_COUNT_PRODUCTS_FOR_DESCRIPTION(description2replaceId));
    };

    const replace = () => {
        setIsLoading(true);
        const payload: productDescriptionReplacePayload = {replacementProductDescriptionId: replacementDescription.id};
        axios.patch(
            `${API_PRODUCT_DATA_DESCRIPTION}/${description2replace.id}`,
            payload

        ).then(() => {
            enqueueSnackbar(translate({id: 'b.descriptionReplaced'}), {variant: 'success'});
            history.push(paths.productDescriptionList);
        }).catch((e) => {
            setIsLoading(false);
            console.log(e)
            enqueueSnackbar(translate({id: 'productDescription.replaceErr'}), {variant: 'error'});
        });
    };

    return (
        <div className="viewRoot productDescriptionReplacementRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={
                        productDescriptionAPIGet.status === API_STATUSES.PENDING ||
                        replacementDescriptionAPIGet.status === API_STATUSES.PENDING ||
                        countProductsAPI.status === API_STATUSES.PENDING ||
                        isLoading
                    }/>
                    <div className="_directionCol _halfWidth">
                        {description2replace && <>
                            <PaperX className="weldBottom">
                                <div className="_formRow">
                                    <div className="_header">
                                        <FormattedMessage id="b.productDescription"/>
                                    </div>
                                </div>
                            </PaperX>
                            <DescriptionDisplayText data={description2replace}/>
                        </>}
                    </div>
                    <div className="_directionCol _halfWidth">
                        {description2replace && <>
                            <PaperX className={replacementDescription ? 'weldBottom' : ''}>
                                <div className="_formRowDouble">
                                    <div className="_header">
                                        <FormattedMessage id="a.replaceW"/>
                                    </div>
                                    <Button className="_fullWidth"
                                            onClick={() => setSelectDescriptionDialogOpen(true)}
                                            startIcon={<SearchIcon/>}
                                            variant="outlined"
                                    >
                                        <FormattedMessage id="a.search"/>
                                    </Button>
                                </div>
                            </PaperX>
                        </>
                        }
                        {replacementDescription && <DescriptionDisplayText data={replacementDescription}/>}
                    </div>
                </div>
            </div>
            <Footer actionsRight={
                <>
                    <Button disabled={cantReplace()} onClick={count} variant="contained">
                        <FormattedMessage id="a.replace"/>
                    </Button>
                    <Link to={paths.productDescriptionList}>
                        <ButtonClose/>
                    </Link>
                </>
            }/>
            <SelectDescriptionDialog category={description2replace?.category}
                                     isDescribingFrame={false}
                                     lockBrick={true}
                                     marketId={description2replace?.marketId}
                                     open={selectDescriptionDialogOpen}
                                     onClose={() => setSelectDescriptionDialogOpen(false)}
                                     onSelect={(descriptionId) => handleSelectDescription(descriptionId)}
            />
            <ConfirmDialog open={confirmDialogOpen}
                           onConfirm={replace}
                           onCancel={() => setConfirmDialogOpen(false)}
                           message={
                                <p>
                                    <FormattedMessage id="product.confirmReplaceDescription"/><br/>
                                    <br/>
                                    <FormattedMessage id="product.confirmUpdate" values={{count: productCount}}/>
                                </p>
                           }
                           confirmLabelId="a.confirm"
                           cancelLabelId="a.close"
            />
        </div>
    );
};

export default ProductDescriptionReplacement;
import {Link} from 'react-router-dom';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {IconButton, Tooltip} from '@mui/material';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import {paths} from 'paths';
import {reportedAdvertisementIssuesTableRow} from 'shared/models/_common.model';
import {frame} from 'shared/models/frame.model';
import {useFormatMessage} from 'utils/translate';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {PaperX} from 'components/PaperX';

interface ReportedAdvertisementsSummaryProps {
    leafletId: string,
    frames: frame[],
    onRowClick: (pageNumber: number) => void
}

function makeTableRows(frames: frame[]): reportedAdvertisementIssuesTableRow[] {
    return frames.map((frame) => ({
        comment: frame.comment,
        pageNumber: frame.pageNumber,
        frameId: frame.frameId,
        reportedIssueType: frame.reportedIssueType,
        reportedBy: frame.reportedBy,
    }));
}

const ReportedAdvertisementsSummary = ({leafletId, frames, onRowClick}: ReportedAdvertisementsSummaryProps) => {
    const translate = useFormatMessage();

    const columDefs: ColDef[] = [
        {
            field: "pageNumber",
            headerName: translate({ id: "reportedFramesSummary.pageNo" }),
            width: 100,
        },
        {
            field: "comment",
            headerName: translate({ id: "a.reason" }),
            cellRenderer: (params) => (
                <div className="reasonText">{params.data.comment}</div>
            ),
            tooltipValueGetter: (params) => params.data.comment,
        },
        {
            field: "reportedBy",
            headerName: translate({ id: "b.reportedBy" }),
            flex: 1,
            cellRenderer: (params) => (
                <div className="reporterAndFix">
                    <div className="reporterText">{params.data.reportedBy}</div>
                    <Link
                        to={`${paths.advertisementBrowser}?id=${leafletId}&page=${params.data.pageNumber - 1}&frameId=${params.data.frameId}&from=${
                            paths.reportedAdvertisementsBrowser
                        }`}
                        className="fixLink"
                    >
                        <Tooltip title={translate({ id: "reportedFramesBrowser.fixAdvertisementBtn" })}>
                            <IconButton size="small" color="primary">
                                <WysiwygIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                </div>
            ),
            tooltipValueGetter: (params) => params.data.reportedBy,
        },
    ];

    return (
        <PaperX className="_fullHeight _fullTable reportedFramesSummaryRoot">
            <AgTable
                defaultColDef={{
                    suppressMenu: false,
                    resizable: true,
                    sortable: true
                }}
                rowData={makeTableRows(frames)}
                columnDefs={columDefs}
                onRowClicked = {(rowData) => onRowClick(rowData.data.pageNumber)}
                localStorageColumnsKey={localStorageColumnsKeys.reportedFramesSummary}
            />
        </PaperX>
    );
};

export default ReportedAdvertisementsSummary;
import { ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuItemProps } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { releaseIfLocked } from 'utils/lockUtils';
import { useFormatMessage } from 'utils/translate';
import { NavigationItem, NavigationSection, navigationSections } from './NavigationMenuItems';
import { leafletUploadDialogToggle } from 'shared/reducers/leafletUpload';

type NavigationMenuProps = {
    anchorEl: HTMLElement | null,
    onClose: () => void,
};

type NavigationMenuItemProps = MenuItemProps & {
    item: NavigationItem,
};

const NavigationMenuItem = ({ item, children, ...restOfProps }: NavigationMenuItemProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const matchesCurrentRoute = !!useRouteMatch({
        path: item.path,
        exact: true,
    });

    const handleItemClick = () => {
        releaseIfLocked();
        dispatch(leafletUploadDialogToggle(false));
        history.push(`${item.path}${item.params ?? ''}`);
        if (item.action) {
            dispatch(item.action);
        }
    };

    return (
        <MenuItem onClick={handleItemClick} {...restOfProps} disabled={matchesCurrentRoute}>
            {children}
        </MenuItem>
    );
};

const NavigationMenuSection = ({ headingLabelId, items }: NavigationSection) => {
    const translate = useFormatMessage();

    return (
        <>
            <ListSubheader>{translate({ id: headingLabelId })}</ListSubheader>
            {items.map((item) => (
                <NavigationMenuItem key={item.path} item={item}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{translate({ id: item.labelId })}</ListItemText>
                </NavigationMenuItem>
            ))}
        </>
    );
};

export const NavigationMenu = ({ anchorEl, onClose }: NavigationMenuProps) => {
    return (
        <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
            {navigationSections.map(({ ...props }) => (
                <NavigationMenuSection key={props.headingLabelId} {...props} />
            ))}
        </Menu>
    );
};

import axios from 'axios';
import {useQuery} from 'react-query';
import {useSnackbar} from 'notistack';
import {API_ATTRIBUTE, API_ATTRIBUTE_SLIM} from 'config/api/constants';
import {attributeResponseModel, slimAttributeResponseModel} from 'shared/models/attribute.model';
import {useFormatMessage} from 'utils/translate';
import {attrTypes} from 'modules/Dictionaries/Attributes/Attribute';

const fetchAttributeSlim = async (id:string, lang:string): Promise<slimAttributeResponseModel> => {
    const response = await axios.get(API_ATTRIBUTE_SLIM(id, lang));
    return response.data;
};

export const useSlimAttributeQuery = (id:string, lang:string, enabled: boolean) => useQuery([id, lang], () => fetchAttributeSlim(id, lang),
    {
        staleTime: Infinity,
        retry: 1,
        enabled
    });

const fetchAttribute = async (id: string, lang: string): Promise<attributeResponseModel> => {
    const response = await axios.get(API_ATTRIBUTE(id, lang));
    return response.data;
};

export const useAttributeQuery = (id: string, lang: string) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useQuery(
        [id, lang],
        () => fetchAttribute(id, lang),
        {
            staleTime: 0,
            cacheTime: 1,
            retry: 0,
            enabled: false,
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'attributes.err'})}`, {variant: 'error', persist: false});
            }
        });
}

export const isResponseValid = (attributeResponse: slimAttributeResponseModel): boolean => {
    const {id, name, type} = attributeResponse;
    if (typeof id !== 'string' || typeof name !== 'string' || typeof type !== 'string') {
        console.error('attribute is missing one of string-type properties: id, name or type');
        return false;
    }
    if (!attrTypes[type]) {
        console.error(`unknown type: ${type} of attribute: ${id}`);
        return false;
    }
    if (type === attrTypes.RANGE || type === attrTypes.NUMERICAL) {
        if (!Array.isArray(attributeResponse.options)) {
            console.error(`options array missing for type: ${type} of attribute: ${id}`);
            return false;
        }
        if (Array.isArray(attributeResponse.options) && attributeResponse.options.length === 0) {
            console.error(`options array empty for type: ${type} of attribute: ${id}`);
            return false;
        }
    }
    return true;
};
import './FaiLogDiaLog.scss';

import {FormattedMessage} from 'react-intl';
import {format, isToday} from 'date-fns';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {clearFaiLog, faiLogEntry, getFaiLog} from 'utils/failureService';
import {useFormatMessage} from 'utils/translate';
import {PaperX} from 'components/PaperX';
import AgTable from 'components/AgTable/AgTable';

interface FaiLogDiaLogProps {
    onClose: () => void
}

const dateTimeFormat: string = 'yyyy-MM-dd HH:mm';

const FaiLogDiaLog = ({onClose}: FaiLogDiaLogProps) => {
    const translate = useFormatMessage();
    const data: faiLogEntry[] = getFaiLog();

    const defaultColumnDefs: ColDef[] = [
        {field: 'timeStamp', headerName: translate({id: 'log.date'}), width: 200, sortable: true, sort: 'desc',
            tooltipValueGetter: () => translate({id: 'a.copy'}),
            cellRenderer: (props) => format(props.data.timeStamp, dateTimeFormat)},
        {field: 'response', headerName: translate({id: 'log.details'}), flex: 1,
            tooltipValueGetter: () => translate({id: 'a.copy'}),
            cellRenderer: (props) => JSON.stringify(props.data.response) + JSON.stringify(props.data.response)}
    ];

    const copy = (logEntry: faiLogEntry) => navigator.clipboard.writeText(JSON.stringify(logEntry));

    const copyToday = () => navigator.clipboard.writeText(JSON.stringify(data.filter((item) => isToday(item.timeStamp))));

    const copyAll = () => navigator.clipboard.writeText(JSON.stringify(data));

    const handleClearAllClick = () => {
        clearFaiLog();
        onClose();
    };

    return (
        <Dialog className="faiLogDiaLogRoot _dialog-likeView" open={true} onClose={onClose} fullScreen>
            <DialogContent className="_directionCol">
                <PaperX className="weldBottom">
                    <div className="_header">
                        <FormattedMessage id="log.header"/>
                    </div>
                </PaperX>
                <PaperX className="_fullHeight _fullTable _scrollY weldTop">
                    <AgTable rowData={data}
                             columnDefs={defaultColumnDefs}
                             defaultColDef={{suppressMenu: true}}
                             onRowClicked = {(rowData) => copy(rowData.data)}
                             tooltipShowDelay={100}
                    />
                </PaperX>
            </DialogContent>
            <DialogActions>
                <Button className="removeButton" variant="outlined" color="secondary" onClick={handleClearAllClick}><FormattedMessage id="log.removeAll"/></Button>
                <Button variant="contained" color="primary" onClick={copyToday}><FormattedMessage id="log.copyToday"/></Button>
                <Button variant="outlined" color="primary" onClick={copyAll}><FormattedMessage id="log.copyAll"/></Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.close"/></Button>
            </DialogActions>
        </Dialog>
    );
};
export default FaiLogDiaLog;
/* eslint-disable react-hooks/exhaustive-deps */

import {ReactElement, useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    TextField,
    Tooltip
} from '@mui/material';
import {API_BANNER_DETAILS, API_MASTER_DATA_BANNERS, API_STATUSES} from 'config/api/constants';
import {banner} from 'shared/models/banner.model';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import ButtonClose from 'components/Buttons/ButtonClose';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {CountrySelector, IndustrySelector, RetailerSelector} from 'components/Selectors';
import {ConfirmDialog} from 'components/ConfirmDialog';

interface HierarchyDialogBannerProps {
    open: boolean,
    onClose: (reload: boolean) => void,
    banner: banner
}

export const bannerTemplate:banner = {
    id: '',
    name: '',
    active: true,
    industryId: '',
    countryId: '',
    publishWithValidity: false,
    retailerId: '',
    retailPartner: false
};

export const HierarchyDialogBanner = (props:HierarchyDialogBannerProps) => {
    const {open, onClose, banner} = props;
    const translate = useFormatMessage();
    const bannerAPIGet = useApi('get', null, {errMsg: 'banner.banner'});
    const bannerAPIPost = useApi('post', null, {errMsg: 'banner.saveErr', succMsg: 'banner.succ', failureMsg: 'banner.duplicate'});
    const bannerAPIPut = useApi('put', null, {errMsg: 'banner.saveErr', succMsg: 'banner.succ', failureMsg: 'banner.duplicate'});

    const [bannerLocal, setBannerLocal] = useState<banner>(bannerTemplate);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

    const buildConfirmationMsg = (): ReactElement => <p>{`${translate({id: 'b.banner'})} ${translate({id: 'hierarchy.confirmation1'})}`}
        <br/>
        <br/>
        {`${translate({id: 'hierarchy.confirmation2'})}`}
        <br/>
        {`${translate({id: 'b.branches'})}`}
    </p>;
    const confMsg: ReactElement = useMemo(() => buildConfirmationMsg(), []);

    useEffect(() => {
        if (banner) {
            if (banner.id) {
                // call API to get more banner details
                bannerAPIGet.call(API_BANNER_DETAILS(banner.id));
            } else {
                // populate banner with retailer id
                setBannerLocal(banner);
            }
        }
    }, [banner]);

    useEffect(() => {
        if (bannerAPIGet.status === API_STATUSES.IDLE && bannerAPIGet.data) {
            setBannerLocal(bannerAPIGet.data);
        }
    }, [bannerAPIGet.status]);

    useEffect(() => {
        if (bannerAPIPost.status === API_STATUSES.IDLE) {
            cleanAndClose(true);
        }
    }, [bannerAPIPost.status]);

    useEffect(() => {
        if (bannerAPIPut.status === API_STATUSES.IDLE) {
            cleanAndClose(true);
        }
    }, [bannerAPIPut.status]);

    const handleBannerPropertyChange = (key: string, value: string | boolean) => {
        const newBannerLocal: banner = {...bannerLocal};
        newBannerLocal[key] = value;
        setBannerLocal(newBannerLocal);
    };

    const cantSave = (): boolean => !bannerLocal.name || !bannerLocal.retailerId || !bannerLocal.countryId || !bannerLocal.industryId;

    const showLoading = (): boolean => bannerAPIPut.status === API_STATUSES.PENDING || bannerAPIPost.status === API_STATUSES.PENDING || bannerAPIGet.status === API_STATUSES.PENDING;

    const handleSaveClick = () => {
        if (bannerLocal.id) {
            if (bannerLocal.active) {
                bannerAPIPut.call(`${API_MASTER_DATA_BANNERS}/${bannerLocal.id}`, bannerLocal);
            } else {
                setConfirmationDialogOpen(true);
            }
        } else {
            bannerAPIPost.call(API_MASTER_DATA_BANNERS, bannerLocal);
        }
    };

    const cleanAndClose = (reload: boolean = false) => {
        setBannerLocal(bannerTemplate);
        onClose(reload);
    }

    const handleSaveConfirm = () => {
        setConfirmationDialogOpen(false);
        bannerAPIPut.call(`${API_MASTER_DATA_BANNERS}/${bannerLocal.id}`, bannerLocal)
    };

    return (
        <>
            <Dialog open={open} fullWidth  maxWidth="md" onClose={() => {if (!showLoading()) cleanAndClose();}}>
                <LoadingOverlay show={showLoading()}/>
                <DialogTitle>
                    <FormattedMessage id={bannerLocal.id ? 'b.banner' : 'hierarchy.createBanner'}/>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className="_formRow">
                        <TextField
                            required
                            className="_fullWidth"
                            label={translate({id: 'b.name'})}
                            value={bannerLocal.name}
                            onChange={(e) => handleBannerPropertyChange('name', e.target.value)}
                            autoFocus
                        />
                    </div>
                    <div className="_formRow">
                        <RetailerSelector retailerId={bannerLocal.retailerId}
                                          required
                                          onRetailerChange={(retailer) => handleBannerPropertyChange('retailerId', retailer ? retailer.id : '')}/>
                    </div>
                    <div className="_formRow">
                        <CountrySelector value={bannerLocal.countryId}
                                         required
                                         onCountryChange={(country) => handleBannerPropertyChange('countryId', country)}  />
                    </div>
                    <div className="_formRow">
                        <IndustrySelector value={bannerLocal.industryId}
                                          required
                                          onChange={(industryId) => handleBannerPropertyChange('industryId', industryId)}
                        />
                    </div>
                    <ActiveCheckbox value={bannerLocal.active} onChange={(key, value) => handleBannerPropertyChange(key, value)}/>
                    <FormControlLabel
                        control={<Checkbox checked={bannerLocal.retailPartner} color="primary" onChange={(e) => handleBannerPropertyChange('retailPartner', e.target.checked)}/>}
                        label={translate({id: 'b.isMarkantRetailPartner'})}/>
                    <Tooltip title={translate({id: 'banner.tooltipPublishWithValidity'})}
                             PopperProps={{
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -12],
                                    },
                                  },
                                ],
                              }}>
                        <FormControlLabel
                            control={<Checkbox checked={bannerLocal.publishWithValidity} color="primary" onChange={(e) => handleBannerPropertyChange('publishWithValidity', e.target.checked)}/>}
                            label={translate({id: 'banner.publishWithValidity'})}/>
                    </Tooltip>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                    <ButtonClose onClick={() => cleanAndClose()}/>
                </DialogActions>
            </Dialog>
            <ConfirmDialog cancelLabelId="a.cancel"
                           confirmLabelId="a.save"
                           onCancel={() => setConfirmationDialogOpen(false)}
                           onConfirm={handleSaveConfirm}
                           open={confirmationDialogOpen}
                           message={confMsg}/>
        </>
    );
}
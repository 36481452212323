import {useState} from 'react';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from '@mui/material';
import {API_PRODUCT_REPORT} from 'config/api/constants';
import {reportProductPayload} from 'shared/models/product.model';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface ReportProductProps {
    id: string
}

const ReportProduct = ({id}: ReportProductProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [comment, setComment] = useState<string>('');

    const canSubmit = () => comment.length > 5 && id;

    const handleSubmit = () => {
        setLoading(true);
        const payload: reportProductPayload = {comment};
        axios.patch(API_PRODUCT_REPORT(id), payload)
            .then(() => {
                enqueueSnackbar(`${translate({id: 'product.reported'})}`, {variant: 'success', persist: false});
                setDialogOpen(false);
                setComment('');
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setLoading(false));
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setComment('');
    };

    return id ? (
        <>
            <LoadingOverlay show={loading} fullScreen/>
            <div>
                <Button size="small" onClick={() => setDialogOpen(true)}>
                    <FormattedMessage id="reportProduct.label"/>
                </Button>
            </div>
            <Dialog open={dialogOpen} maxWidth={'sm'} fullWidth>
                <DialogTitle>
                    <FormattedMessage id="reportProduct.label"/>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <TextField autoFocus
                               fullWidth
                               label={translate({id: 'a.reason'})}
                               minRows={4}
                               multiline
                               onChange={(e) => setComment(e.target.value)}
                               value={comment}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={handleSubmit} variant="contained" disabled={!canSubmit()}>
                        <FormattedMessage id="a.submit"/>
                    </Button>
                    <ButtonClose onClick={handleDialogClose} />
                </DialogActions>
            </Dialog>
        </>
    ) : null;
};

export default ReportProduct;
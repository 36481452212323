/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {Button, CircularProgress} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {API_GET_BRAND, API_GET_STRUCTURED_BRAND, API_STATUSES} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {basicBrand, brand, structuredBrand} from 'shared/models/brand.model';
import {responseValidation} from 'utils/responseValidation';

interface BrandDisplayProps {
    brand: basic,
    onDelete?: (string) => void
}

const BrandDisplay = ({brand, onDelete}: BrandDisplayProps) => {
    return (
        <div>
            <span>{brand.name}</span>
            {onDelete && <Button onClick={() => onDelete(brand.id)}>
                <FormattedMessage id="a.remove"/>
            </Button>}
        </div>
    );
}

export {BrandDisplay};

interface BrandSmartDisplayProps {
    id: string,
    onDelete?: (string) => void
}

const BrandSmartDisplay = ({id, onDelete}: BrandSmartDisplayProps) => {
    const [brand, setBrand] = useState<basicBrand>(null);
    const [status, setStatus] = useState<API_STATUSES>(API_STATUSES.NEW);

    const cancelToken = useRef(null);

    useEffect(() => {
        getData();
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const getData = () => {
        if (cancelToken.current) cancelToken.current();
        setStatus(API_STATUSES.PENDING);
        axios.get<brand>(API_GET_BRAND(id), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setBrand(resp.data);
                    setStatus(API_STATUSES.IDLE)
                } else {
                    setStatus(API_STATUSES.ERROR);
                }
            })
            .catch((e) => {
                console.log(e);
                setStatus(API_STATUSES.ERROR);
            })
    }
    const content = () => {
        if (status === API_STATUSES.ERROR) {
            return (
                <>
                    <WarningIcon/>
                    <FormattedMessage id="a.error"/>
                </>);
        }
        if (status === API_STATUSES.PENDING) {
            return (
                <>
                    <CircularProgress size={20}/>
                    &nbsp;
                    <FormattedMessage id="a.loading"/>
                </>
            );
        }
        if (status === API_STATUSES.IDLE) {
            return (
                <>
                    <span>{brand.name}</span>
                    {onDelete && <Button onClick={() => onDelete(id)}>
                        <FormattedMessage id="a.remove"/>
                    </Button>}
                </>
            );
        }
    }
    return (
        <div className="brandSmartDisplayRoot">
            {content()}
        </div>
    );
};

export {BrandSmartDisplay};

interface StructuredBrandSmartDisplayProps {
    id: string,
    onDelete?: (string) => void
}

const StructuredBrandSmartDisplay = ({id, onDelete}: StructuredBrandSmartDisplayProps) => {
    const [brand, setBrand] = useState<structuredBrand>(null);
    const [status, setStatus] = useState<API_STATUSES>(API_STATUSES.NEW);

    const cancelToken = useRef(null);

    useEffect(() => {
        getData();
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const getData = () => {
        if (cancelToken.current) cancelToken.current();
        setStatus(API_STATUSES.PENDING);
        axios.get<structuredBrand>(API_GET_STRUCTURED_BRAND(id), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setBrand(resp.data);
                    setStatus(API_STATUSES.IDLE)
                } else {
                    setStatus(API_STATUSES.ERROR);
                }
            })
            .catch((e) => {
                console.log(e);
                setStatus(API_STATUSES.ERROR);
            })
    }
    const content = () => {
        if (status === API_STATUSES.ERROR) {
            return (
                <>
                    <WarningIcon/>
                    <FormattedMessage id="a.error"/>
                </>);
        }
        if (status === API_STATUSES.PENDING) {
            return (
                <>
                    <CircularProgress size={20}/>
                    &nbsp;
                    <FormattedMessage id="a.loading"/>
                </>
            );
        }
        if (status === API_STATUSES.IDLE) {
            return (
                <>
                    <span>{brand.brands.map((item) => item.name).join(' // ')}</span>
                    {onDelete && <Button onClick={() => onDelete(id)}>
                        <FormattedMessage id="a.remove"/>
                    </Button>}
                </>
            );
        }
    }
    return (
        <div className="brandSmartDisplayRoot">
            {content()}
        </div>
    );
};

export {StructuredBrandSmartDisplay};
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {API_GET_SUPPLIERS_FOR_BRAND} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {responseValidation} from 'utils/responseValidation';
import {getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface SupplierDialogProps {
    brand: basic,
    currentSupplierId?: string,
    onClose: () => void,
    onRemove?: () => void,
    onSelect: (supplier: basic) => void,
    open: boolean,
    brandChangeCounter: number
}

const SupplierDialog = ({brand, currentSupplierId, onClose, onRemove, onSelect, open, brandChangeCounter}: SupplierDialogProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const cancelToken = useRef(null);

    const [suppliers, setSuppliers] = useState<basic[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if (open && brand?.id) {
            setSuppliers([]);
            getSuppliersForBrand();
        }
    }, [open]);

    useEffect(() => {
        if (brand?.id) {
            setSuppliers([]);
            getSuppliersForBrand();
        }
    }, [brand]);

    useEffect(() => {
        const cloneUrl = getURLParam('clone');
        if (!open && (brandChangeCounter || cloneUrl) && suppliers.length === 1) onSelect(suppliers[0]);
    }, [suppliers]);

    const getSuppliersForBrand = () => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current();
        axios.get<basic[]>(API_GET_SUPPLIERS_FOR_BRAND(brand?.id), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setSuppliers(resp.data);
                } else {
                    handleError();
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    handleError();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleError = () => {
        enqueueSnackbar(`${translate({id: 'supplier.errors'})}`, {variant: 'error'});
        onClose();
    };

    return (
        <Dialog open={open}>
            <LoadingOverlay show={isLoading}/>
            <DialogTitle>
                <FormattedMessage id="supplier.select"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <span className="_bold"><FormattedMessage id="b.brand"/>:&nbsp;</span>{brand?.name}
                <br/>
                <span className="_bold"><FormattedMessage id="b.suppliers"/>:</span>
                <ul>
                    {suppliers.map((item) => (
                        <li key={item.id}>
                            {item.name}
                            {currentSupplierId !== item.id ?
                                <Button onClick={() => onSelect(item)}>
                                    <FormattedMessage id="a.select"/>
                                </Button>
                                :
                                <Button onClick={() => onRemove()}>
                                    <FormattedMessage id="a.remove"/>
                                </Button>
                            }
                        </li>))
                    }
                </ul>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.close"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default SupplierDialog;
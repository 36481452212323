import SummaryCard from 'components/Cards/SummaryCard';
import {productInfoObject} from './Gtins';

interface ProductInfoProps {
    productInfo: productInfoObject
}

const ProductInfo = (props: ProductInfoProps) => {
    const {brand, category, description} = props.productInfo;
    return (
        <div className="productInfoRoot">
            <SummaryCard content={[
                {label: 'b.brand', value: brand},
                {label: 'b.category', value: category},
                {label: 'b.descr', value: description}
            ]}/>
        </div>
    );
};

export default ProductInfo;
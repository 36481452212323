/* eslint-disable react-hooks/exhaustive-deps */
import './BPCODescriptionView.scss'

import {paths} from 'paths';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button} from '@mui/material';
import {API_BPCO_DESCRIPTIONS, API_STATUSES} from 'config/api/constants';
import {BPCODescriptionPayload} from 'shared/models/BPCO.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import BPCODescription from './BPCODescription';
import {transformBPCODescriptionUpdatePayload} from '../BPCOio';

const BPCODescriptionView = ({history}) => {
    const translate = useFormatMessage();
    const [isNewDescription, setIsNewDescription] = useState<boolean>(false);
    const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false);
    const [originalDescription, setOriginalDescription] = useState<string>(null);
    const [BPCODescriptionId, setBPCODescriptionId] = useState<string>(null);
    const [descriptionPayload, setDescriptionPayload] = useState<BPCODescriptionPayload>(null);

    const BPCODescriptionAPIPut = useApi('put', null, {errMsg: 'b.descriptionSaveErr', succMsg: 'b.descriptionSaveSucc', failureMsg: 'bpco.descriptionDuplicate'});
    const BPCODescriptionAPIPost = useApi('post', null, {errMsg: 'b.descriptionSaveErr', succMsg: 'b.descriptionSaveSucc', failureMsg: 'bpco.descriptionDuplicate'});

    useEffect(() => {
        const id: string = getId();
        if (id) setBPCODescriptionId(id);
        const newDescr: string = getURLParam('new');
        if (newDescr) setIsNewDescription(true);
    }, []);

    useEffect(() => {
        if (BPCODescriptionAPIPut.status === API_STATUSES.IDLE) history.push(paths.BPCODescriptionList);
    }, [BPCODescriptionAPIPut.status]);

    useEffect(() => {
        if (BPCODescriptionAPIPost.status === API_STATUSES.IDLE) history.push(paths.BPCODescriptionList);
    }, [BPCODescriptionAPIPost.status]);

    const handleSaveClick = () => {
        if (BPCODescriptionId) {
            BPCODescriptionAPIPut.call(`${API_BPCO_DESCRIPTIONS}/${BPCODescriptionId}`, transformBPCODescriptionUpdatePayload(descriptionPayload));
        } else {
            BPCODescriptionAPIPost.call(API_BPCO_DESCRIPTIONS, descriptionPayload);
        }
    };

    const handleProductDescriptionChange = (description: BPCODescriptionPayload, descriptionValid: boolean) => {
        setIsDescriptionValid(descriptionValid);
        setDescriptionPayload(descriptionValid ? description : null);
        if (originalDescription === null && descriptionValid) {
            setOriginalDescription(JSON.stringify(description));
        }
    };

    const isDescriptionChanged = () => isNewDescription ? true : originalDescription !== JSON.stringify(descriptionPayload);

    const canSave = (): boolean => isDescriptionValid && isDescriptionChanged();

    return (
        <div className="viewRoot BPCODescriptionRoot">
            <div className="viewport">
                <div className="viewContainer">
                    <LoadingOverlay show={BPCODescriptionAPIPost.status === API_STATUSES.PENDING || BPCODescriptionAPIPut.status === API_STATUSES.PENDING}/>
                    <BPCODescription onBPCODescriptionChange={handleProductDescriptionChange} descriptionId={BPCODescriptionId}/>
                </div>
            </div>
                <Footer actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        <Link to={paths.BPCODescriptionList}>
                            <ButtonClose/>
                        </Link>
                    </>
                }/>
        </div>
    )
}
export default BPCODescriptionView;
import './LeafletBrowserFullScreen.scss';

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Paper} from '@mui/material';
import {getId} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {API_LEAFLET_MANAGEMENT_LEAFLETS} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {LeafletBrowser} from 'components/gfx';

const LeafletBrowserFullScreen = () => {
    const [leafletId, setLeafletId] = useState<string>('');
    const leafletAPIGet = useApi('get', {metaData: {numberOfPages: 0}});

    const synchronizedLeafletId = useSelector((state: IRootState) => state.storagePileState.leafletBrowserFullScreen);

    useEffect(() => {
        setLeafletId(getId())
    }, []);

    useEffect(() => {
        if (synchronizedLeafletId) setLeafletId(synchronizedLeafletId)
    }, [synchronizedLeafletId]);

    useEffect(() => {
        if (leafletId) leafletAPIGet.call(`${API_LEAFLET_MANAGEMENT_LEAFLETS}/${leafletId}`);
    }, [leafletId]);

    return (
        <div className="leafletBrowserFullScreenRoot">
            <div className="_fullHeight _fullWidth">
                <Paper elevation={0} className="_fullHeight">
                    <LeafletBrowser leaflet={leafletAPIGet.data} fullScreen hideReportPage/>
                    <div className="leafletIdLabel"> ID:&nbsp;{leafletId}</div>
                </Paper>
            </div>
        </div>);
};

export default LeafletBrowserFullScreen;
import axios from 'axios';
import {API_STATUSES, API_BRANCHES_FOR_RETAILER} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {branchesPerRetailer} from 'shared/models/dict.model';

enum ACTION_TYPES {
    START_FETCHING_BRANCHES_PER_RETAILER = 'START_FETCHING_BRANCHES_PER_RETAILER',
    ON_SUCCESS_BRANCHES_PER_RETAILER = 'ON_SUCCESS_BRANCHES_PER_RETAILER',
    ON_ERROR_BRANCHES_PER_RETAILER = 'ON_ERROR_BRANCHES_PER_RETAILER'
}

export function fetchBranchesPerRetailer(retailerId: string, lang: string) {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_BRANCHES_PER_RETAILER
        });
        axios.get(API_BRANCHES_FOR_RETAILER(retailerId, lang)).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_BRANCHES_PER_RETAILER,
                    payload: { data: resp.data }
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_BRANCHES_PER_RETAILER});
            }
        }).catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_BRANCHES_PER_RETAILER})
        })
    }
}

const initialState: branchesPerRetailer = {
    status: API_STATUSES.NEW,
    data: []
};

export type BranchesPerRetailerState = Readonly<typeof initialState>;

const branchesPerRetailerReducer = (state: BranchesPerRetailerState = initialState, action): BranchesPerRetailerState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_BRANCHES_PER_RETAILER:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_BRANCHES_PER_RETAILER:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_BRANCHES_PER_RETAILER:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default branchesPerRetailerReducer;
import './PageCountryThemedWeeksSelector.scss';

import {IconButton, TextField} from '@mui/material';
import {DeleteOutline} from '@mui/icons-material';
import {useFormatMessage} from 'utils/translate';
import {Attribute} from 'components/Attribute';
import {applyRangeMask} from 'components/Selectors/ValiditySelector/ValiditySelector';
import {multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {handyAttributes} from 'shared/handyAttributes';

type PageCountryThemedWeeksSelectorProps = {
    id: number,
    pageNumber?: number | string,
    numberOfPages: number,
    countryThemedWeeks: multiselectAttributeDataModel,
    onPageChange?: (id: number, pageNumber: string) => void,
    onRemove?: (id: number) => void,
    onCountryThemedWeeksChange: (id: number, themedWeeks: multiselectAttributeDataModel) => void
}

const PageCountryThemedWeeksSelector = ({id, pageNumber, numberOfPages, countryThemedWeeks, onPageChange, onRemove, onCountryThemedWeeksChange}: PageCountryThemedWeeksSelectorProps) => {
    const translate = useFormatMessage();

    const handlePageChange = (value: string | number) => {
        if (typeof value === 'number') {
            onPageChange(id, value + '');
        } else {
            onPageChange(id, value);
        }
    };

    const handleCountryThemedWeeksChange = (value: multiselectAttributeDataModel) => onCountryThemedWeeksChange(id, value);

    const handleRangeInputChange = (value: string) => onPageChange(id, applyRangeMask(value, 1, numberOfPages));

    return (
        <div className="pageCountryThemedWeeksRoot">
            <div className="pageNumber">
                {numberOfPages
                ?   <TextField
                        label={translate({id: 'headerData.pages'})}
                        value={pageNumber}
                        onChange={(e) => handlePageChange(e.target.value)}
                        onBlur={(e) => handleRangeInputChange(e.target.value)}
                        error={!pageNumber}
                    />
                : null
                }
            </div>
            <div className="countryThemedWeeksSelector">
                <Attribute id={handyAttributes.countryThemedWeeks}
                           data={countryThemedWeeks}
                           onChange={(key, value) => handleCountryThemedWeeksChange(value)}
                           multiselect
                           disableAutoselect/>
            </div>
            <IconButton className="deleteBtn" onClick={() => onRemove(id)}>
                <DeleteOutline color="secondary" fontSize="small"/>
            </IconButton>
        </div>
    );
}

export default PageCountryThemedWeeksSelector;
import axios from 'axios';
import {API_DICTIONARY_BY_ID} from 'config/api/constants';
import {useQuery} from 'react-query';
import {typeOfBrandDictionaryId} from 'shared/handyDictionaryEntries';
import {dictionaryWithElements} from 'shared/models/dictionaries.model';

const fetchTypeOfBrand = async (): Promise<dictionaryWithElements> => {
    const response = await axios.get(API_DICTIONARY_BY_ID(typeOfBrandDictionaryId));
    return response.data
};

export const useTypeOfBrandQuery = () => useQuery([typeOfBrandDictionaryId], () => fetchTypeOfBrand(), {staleTime: Infinity});
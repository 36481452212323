import {useState} from 'react';
import {Link} from 'react-router-dom';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {paths} from 'paths';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {ButtonClose, ButtonNew} from 'components/Buttons';
import ProductDescriptionList from './ProductDescriptionList';
import ProductDescriptionCopyDialog from './ProductDescriptionCopyDialog';

const ProductDescriptionListView = ({history}) => {
    const translate = useFormatMessage();

    const [productDescriptionCopyDialogOpen, setProductDescriptionCopyDialogOpen] = useState<boolean>(false);
    const [copyDescriptionId, setCopyDescriptionId] = useState<string>(null);
    const [forceReloadCounter, setForceReloadCounter] = useState<number>(0);

    const goToEdit = (productDescriptionId: string) => history.push(`${paths.productDescription}?id=${productDescriptionId}`);

    const getContextMenu = (rowData: GetContextMenuItemsParams): (MenuItemDef | string)[] => [
            {
                name: translate({id: 'productDescription.createCopy'}),
                action: () => {
                    setCopyDescriptionId(rowData.node.data.id);
                    setProductDescriptionCopyDialogOpen(true);
                }
            },
            'separator',
            {
                name: translate({id: 'a.replace'}),
                action: () => history.push(`${paths.productDescriptionReplace}?id=${rowData.node.data.id}`),
            }
        ];

    const handleCloseProductDescriptionCopyDialog = () => {
        setCopyDescriptionId(null);
        setProductDescriptionCopyDialogOpen(false);
    };

    const handleProductDescriptionCopy = () => {
        handleCloseProductDescriptionCopyDialog();
        setForceReloadCounter(forceReloadCounter + 1);
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <ProductDescriptionList forceReloadCounter={forceReloadCounter}
                                            getContextMenu={getContextMenu}
                                            hasContextMenu
                                            onRowClick={(descriptionId) => goToEdit(descriptionId)}/>
                </div>
            </div>
            <ProductDescriptionCopyDialog copyDescriptionId={copyDescriptionId}
                                          isDescribingFrame={false}
                                          onCopy={() => handleProductDescriptionCopy()}
                                          onClose={() => handleCloseProductDescriptionCopyDialog()}
                                          open={productDescriptionCopyDialogOpen}/>
            <Footer
                actionsRight={
                    <>
                        <Link to={`${paths.productDescription}?new=true`}>
                            <ButtonNew/>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    )
};

export default ProductDescriptionListView;
/* eslint-disable react-hooks/exhaustive-deps */
import './FileUpload.scss'

import { useState, useEffect, useRef, useCallback } from 'react';
import {Button, Paper} from '@mui/material';
import {CloudUpload} from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {useFormatMessage} from 'utils/translate';
import FileUploadOverlay from 'components/FileUpload/FileUploadOverlay/FileUploadOverlay';
import {mediumType} from 'shared/models/leaflet.model';
import {supportedLeafletFileTypes, supportedPressAdvFileTypes} from './FileExtensions';

type FileUploadProps = {
  onFilesChange: (files: File[]) => void,
  selectedMediumType: mediumType,
  simple?: boolean
};

const FileUpload = ({onFilesChange, selectedMediumType, simple}: FileUploadProps) => {
  const translate = useFormatMessage();
  const { enqueueSnackbar } = useSnackbar();
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const fileInput = useRef(null);

  const isValidFileType = (file: File): boolean => {
    if (selectedMediumType === mediumType.LEAFLET) return supportedLeafletFileTypes.includes(file.type);
    else return supportedPressAdvFileTypes.includes(file.type);
  };

  const acceptedExtensions = ():string => {
    let extensions: string = '.pdf';
    if (selectedMediumType === mediumType.PRESS_ADVERTISEMENT) {
      extensions += ', .jpg, .jpeg,';
    }
    return extensions;
  }

  const handleFiles = useCallback((files: FileList) => {
    const validFiles = Array.from(files).filter(file => isValidFileType(file));
    const invalidFiles = Array.from(files).filter(file => !isValidFileType(file));

    if (invalidFiles.length) {
      enqueueSnackbar(
        `${translate({id: 'fileUpload.formatWarning'}, {files: invalidFiles.map(f => f.name).join(', ')})}`,
        {
          variant: 'warning',
          persist: false
        });
    }

    if (!invalidFiles.length) {
      onFilesChange(validFiles);
    }
  }, [onFilesChange, enqueueSnackbar, translate]);

  const onChangeHandler = (event) => {
    if (event.target.files.length) {
      handleFiles(event.target.files);
    }
  };

  const onDragEnterHandler = useCallback((event) => {
    setIsDragActive(true);
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDragOverHandler = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDragLeaveHandler = (event) => {
    setIsDragActive(false);
    event.stopPropagation();
    event.preventDefault();
  };

  const onDropHandler = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    const dt = event.dataTransfer;
    const files = dt.files;
    handleFiles(files);

    setIsDragActive(false);
  }, [handleFiles]);

  const uploadBtnClickHandler = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    document.body.addEventListener('dragenter', onDragEnterHandler);
    document.body.addEventListener('dragover', onDragOverHandler);
    document.body.addEventListener('drop', onDropHandler);

    return () => {
      document.body.removeEventListener('dragenter', onDragEnterHandler);
      document.body.removeEventListener('dragover', onDragOverHandler);
      document.body.removeEventListener('drop', onDropHandler);
    }
  }, [onDragEnterHandler, onDragOverHandler, onDropHandler]);

  return (
      <Paper elevation={0} className="_fullHeight fileUploadRoot">
          {/* LAYOUT */}
          <div className={simple ? ['fileUploadContainer', 'withoutBorder'].join(',') : 'fileUploadContainer'}>
            <div className="fileUploadContent">
              {simple ? null : <div className="fileUploadIcon">
                <CloudUpload fontSize='inherit'/>
              </div>}
              {simple ? null : <p className="fileUploadText">
                {translate({id: 'fileUpload.uploadFilePlaceholder'})}
                <span className="fileUploadTextOr"><br/>{translate({id: 'fileUpload.or'})}</span>
              </p>}
              <Button
                onClick={uploadBtnClickHandler}
                variant="contained"
                color="primary"
                size="large"
                startIcon={simple ? <CloudUpload/> : null}
              >
                {translate({id: 'fileUpload.chooseFiles'})}
              </Button>
            </div>
          </div>

          {/* HIDDEN INPUT */}
          <input
            className="inputFile"
            type="file"
            multiple
            accept={acceptedExtensions()}
            ref={fileInput}
            onChange={onChangeHandler}
          />

          {/* DROPZONE */}
          {isDragActive && <div className="dropZone" onDragLeave={onDragLeaveHandler} />}

          {/* DRAG OVERLAY */}
          <FileUploadOverlay show={isDragActive}/>
      </Paper>
  )
}

export default FileUpload;

import './Navigation.scss';

import { Link } from 'react-router-dom';
import {Card, CardContent, CardHeader}from '@mui/material';
import {
    AccountTreeTwoTone,
    BugReport,
    BurstMode,
    Dashboard,
    ListAlt,
    PhotoSizeSelectSmall,
    PictureInPicture,
    Radio,
    Timeline,
    Tv,
    StarOutline
} from '@mui/icons-material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PlayLessonIcon from '@mui/icons-material/PlayLesson';
import { paths } from 'paths';
import {useFormatMessage} from 'utils/translate';
import IconTile2 from 'components/IconTile2/IconTile2';
import LeafletUploadNavButton from 'modules/Leaflet/LeafletUpload/LeafletUploadNavButton';

export function Navigation() {
  const translate = useFormatMessage();
  return (
      <div className="navigationRoot">
        <Card className="card" elevation={0}>
            <CardHeader className="cardHeader" title={translate({id: 'nav.leaflet'})}/>
            <CardContent className="cardContent">
                <LeafletUploadNavButton/>
                <Link to={paths.printMediaOverview}>
                  <IconTile2 Icon={ListAlt} title={translate({id: 'printMediaOverview.overview'})}/>
                </Link>
                <Link to={`${paths.framing}?canChangeCountry=true`}>
                    <IconTile2 Icon={PhotoSizeSelectSmall} title={translate({id: 'nav.framingCTA'})}/>
                </Link>
            </CardContent>
            <CardHeader className="cardHeader" title={translate({id: 'b.media'})}/>
            <CardContent className="cardContent">
                <Link to={paths.radioSpotOverview}>
                    <IconTile2 Icon={Radio} title={translate({id: 'nav.mediumRadio'})}/>
                </Link>
                <Link to={paths.home} hidden>
                    <IconTile2 Icon={Tv} title="tv spot overview"/>
                </Link>
            </CardContent>
        </Card>
        {/*<Card className="card" elevation={0}>*/}
        {/*  <CardHeader className="cardHeader" title={translate({id: 'nav.frame'})}/>*/}
        {/*  <CardContent className="cardContent">*/}
        {/*    <Link to={`${paths.framing}?canChangeCountry=true`}>*/}
        {/*      <IconTile2 Icon={PhotoSizeSelectSmall} title={translate({id: 'nav.framingCTA'})}/>*/}
        {/*    </Link>*/}
        {/*  </CardContent>*/}
        {/*</Card>*/}
        <Card className="card" elevation={0}>
          <CardHeader className="cardHeader" title={translate({id: 'nav.advertisement'})}/>
          <CardContent className="cardContent">
            <Link to={`${paths.describeFrame}?canChangeCountry=true`}>
                <IconTile2 Icon={BurstMode} title={translate({id: 'printMediaOverview.describeFrame'})}/>
            </Link>
              <Link to={`${paths.describeRadioSpot}`}>
                  <IconTile2 Icon={PlayLessonIcon} title={translate({ id: 'media.describeMedia' })} />
              </Link>
          </CardContent>
            <CardContent className="cardContent">
                <Link to={paths.advertisementsOverview}>
                    <IconTile2 Icon={ListAlt} title={translate({id: 'nav.overviewAdvertisements'})}/>
                </Link>
                <Link to={paths.advertisementsSpotOverview}>
                    <IconTile2 Icon={PlayCircleIcon} title={translate({id: 'nav.overviewAdvertisementsSpot'})}/>
                </Link>
                <Link to={paths.advertisementsOverviewPromotions}>
                    <IconTile2 Icon={PictureInPicture} title={translate({id: 'nav.overviewAdvertisementsPromo'})}/>
                </Link>
            </CardContent>
        </Card>
        <Card className="card" elevation={0}>
          <CardHeader className="cardHeader" title={translate({id: 'nav.quality'})}/>
          <CardContent className="cardContent">
            <Link to={paths.reportedIssues}>
                <IconTile2 Icon={BugReport} title={translate({id: 'nav.qualityBrowse'})}/>
            </Link>
              <Link to={paths.myAdvertisements}>
                <IconTile2 Icon={StarOutline} title={translate({id: 'nav.myAdvertisements'})}/>
              </Link>
            <Link to={paths.statistics}>
              <IconTile2 Icon={Timeline} title={translate({id: 'nav.overviewQuality'})}/>
            </Link>
            <Link to={paths.gtin}>
              <IconTile2 Icon={Dashboard} title={translate({id: 'b.gtin'})}/>
            </Link>
          </CardContent>
        </Card>
        <Card className="card" elevation={0}>
          <CardHeader className="cardHeader" title={translate({id: 'nav.masterData'})}/>
          <CardContent className="cardContent">
            <Link to={paths.masterData}>
              <IconTile2 Icon={AccountTreeTwoTone} title={translate({id: 'nav.masterData'})}/>
            </Link>
          </CardContent>
        </Card>
      </div>
  )
}

export default Navigation;
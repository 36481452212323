/* eslint-disable react-hooks/exhaustive-deps */
import 'modules/Leaflet/LeafletBrowserFullScreen/LeafletBrowserFullScreen.scss';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Paper} from '@mui/material';
import {getId, getURLParam} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {
    API_GET_FRAMES_FOR_LEAFLET,
    API_LEAFLET_MANAGEMENT_FRAMES,
    API_LEAFLET_MANAGEMENT_LEAFLETS,
    API_STATUSES
} from 'config/api/constants';
import {frame} from 'shared/models/frame.model';
import {IRootState} from 'shared/reducers';
import {LeafletBrowser} from 'components/gfx';

const FrameDescriptionLeafletBrowser = () => {
    const leafletAPIGet = useApi('get', {metaData: {numberOfPages: 0}});
    const frameAPIGet = useApi('get', {metaData: {numberOfPages: 0}});
    const framesAPIGet = useApi('get', []);

    const [leafletId, setLeafletId] = useState<string>('');
    const [frameId, setFrameId] = useState<string>('');
    const [frameData, setFrameData] = useState<frame>(null);
    const [frames, setFrames] = useState<frame[]>([]);
    const [externalPageNumber, setExternalPageNumber] = useState<number>(0);
    const [ready, setReady] = useState<boolean>(false);

    const synchronizedLeafletId = useSelector((state: IRootState) => state.storagePileState.describeFrameLeafletBrowser_leafletId);
    const synchronizedFrame = useSelector((state: IRootState) => state.storagePileState.describeFrameLeafletBrowser_frame);
    const synchronizedPageNumber = useSelector((state: IRootState) => state.storagePileState.describeFrameLeafletBrowser_pageNumber);

    useEffect(() => {
        // localStorage.removeItem('describeFrameLeafletBrowser_leafletId');
        // localStorage.removeItem('describeFrameLeafletBrowser_frame');
        // localStorage.removeItem('describeFrameLeafletBrowser_pageNumber');
        setLeafletId(getId());
        setFrameId(getURLParam('frameId'));
        setExternalPageNumber(parseInt(getURLParam('pageNumber')));
    }, []);

    useEffect(() => {
        if (synchronizedLeafletId) setLeafletId(synchronizedLeafletId)
    }, [synchronizedLeafletId]);

    useEffect(() => {
        if (synchronizedFrame && synchronizedFrame.leafletId === leafletId) setFrameData(synchronizedFrame);
        if (frameId !== synchronizedFrame.frameId) setFrameId(synchronizedFrame.frameId);
    }, [synchronizedFrame]);

    useEffect(() => {
        if (synchronizedPageNumber >= 0) setExternalPageNumber(synchronizedPageNumber);
    }, [synchronizedPageNumber]);

    useEffect(() => {
        if (leafletId) {
            leafletAPIGet.call(`${API_LEAFLET_MANAGEMENT_LEAFLETS}/${leafletId}`);
            framesAPIGet.call(API_GET_FRAMES_FOR_LEAFLET(leafletId));
            if (synchronizedFrame.leafletId === leafletId) {
                setFrameData(synchronizedFrame);
            }
        }
    }, [leafletId]);

    useEffect(() => {
        if (frameId) {
            frameAPIGet.call(`${API_LEAFLET_MANAGEMENT_FRAMES}/${frameId}`);
        }
    }, [frameId]);

    useEffect(() => {
        if (frameAPIGet.status === API_STATUSES.IDLE && framesAPIGet.status === API_STATUSES.IDLE) {
            const response: frame = frameAPIGet.data;
            const {leafletId, frameId, x1, y1, x2, y2, pageNumber} = response;
            const newFrameData = {leafletId, frameId, x1, y1, x2, y2, pageNumber: pageNumber-1};
            const response2: frame[] = framesAPIGet.data.map((item) => ({...item, pageNumber: item.pageNumber - 1}));
            setFrames(response2);
            setFrameData(newFrameData);
            setReady(true);
        }
    }, [frameAPIGet.status, framesAPIGet.status]);

    return (
        <div className="leafletBrowserFullScreenRoot">
            <div className="_fullHeight _fullWidth">
                <Paper elevation={0} className="_fullHeight">
                    {ready &&
                        <LeafletBrowser leaflet={leafletAPIGet.data}
                                        frames={frames}
                                        frame2highlight={frameData.frameId}
                                        fullScreen
                                        externalPageNumber={externalPageNumber}
                                        go2Frame={true}
                                        disableFrameInteraction/>
                    }
                    <div className="leafletIdLabel"> ID:&nbsp;{leafletId}</div>
                </Paper>
            </div>
        </div>);
};

export default FrameDescriptionLeafletBrowser;
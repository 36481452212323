/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {Button, Drawer} from '@mui/material';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {API_GET_PRODUCT_DETAILS, API_PRODUCT_DATA_PRODUCTS, API_STATUSES} from 'config/api/constants';
import {apiGetPromotionsForFrameResponsePromotionTypeProductType} from 'shared/models/frame.model';
import {productPayload, productPOSTResponseModel} from 'shared/models/product.model';
import {promotionUI} from 'shared/models/promotion.model';
import {IRootState} from 'shared/reducers';
import {productsDetailsForGiveAwayUI} from 'modules/Advertisement/FrameDescription/frameDescriptionUtils';
import {ProductDetails} from 'modules/MasterData/Product/ProductDetails'

export interface productDrawerGiveAwayBundlingProps {
    open: boolean,
    data: promotionUI,
    productId: string,
    onClose: Function,
    onUpdatePromotion: (key: string,
                        value: apiGetPromotionsForFrameResponsePromotionTypeProductType[],
                        UIId: string) => void
}

const ProductDrawerGiveAwayBundling = (props: productDrawerGiveAwayBundlingProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const {open, data, productId, onClose, onUpdatePromotion} = props;
    
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const [isProductValid, setIsProductValid] = useState<boolean>(false);
    const [originalProduct, setOriginalProduct] = useState<string>(null);
    const [productPayload, setProductPayload] = useState<productPayload>(null);

    const productAPIPost = useApi('post', null, {errMsg: 'product.saveErr', succMsg: 'product.succ', failureMsg: 'product.duplicate'});
    const productAPIGet = useApi('get', null, {errMsg: 'product.err'});

    useEffect(() => {
        if (productAPIGet.status === API_STATUSES.IDLE) {
            setOriginalProduct(null);
            if (productAPIGet.data) {
                const newProduct = productsDetailsForGiveAwayUI(productAPIGet.data);
                const newPromotions: apiGetPromotionsForFrameResponsePromotionTypeProductType[] = [...data?.giveAwayBundledProducts];
                newPromotions.push(newProduct);
                onUpdatePromotion('giveAwayBundledProducts', newPromotions, data.UIId);
            }
        } else if (productAPIGet.status === API_STATUSES.ERROR) {
            enqueueSnackbar(`${translate({id: 'product.errAdding'})}`, {variant: 'error', persist: false});
        }
        handleClose();
    }, [productAPIGet.status]);

    useEffect(() => {
        if (productAPIPost.status === API_STATUSES.IDLE) {
            const newProduct: productPOSTResponseModel = productAPIPost.data;
            productAPIGet.call(API_GET_PRODUCT_DETAILS(newProduct.id, lang));
        }
    }, [productAPIPost.status]);

    const handleProductChange = (product: productPayload, productValid: boolean) => {
        setIsProductValid(productValid);
        setProductPayload(productValid ? product : null);
        if (originalProduct === null && productValid) {
            setOriginalProduct(JSON.stringify(product));
        }
    };

    const handleClose = () => {
        setOriginalProduct(null);
        onClose();
    };

    const handleSaveClick = () => productAPIPost.call(API_PRODUCT_DATA_PRODUCTS, productPayload);

    return (
        <Drawer className="frameDescriptionDrawerRoot"
                anchor="right"
                hideBackdrop
                open={open}
        >
            <LoadingOverlay show={productAPIPost.status === API_STATUSES.PENDING || productAPIGet.status === API_STATUSES.PENDING}/>
            <ProductDetails autosetSupplier
                            clone
                            isDescribingFrame
                            isNewProduct={false}
                            onProductChange={(newProductPayload, productValidity) => handleProductChange(newProductPayload, productValidity)}
                            productId={productId}
            />
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!isProductValid}>{translate({id: 'a.add'})}</Button>
                        <ButtonClose onClick={handleClose}/>
                    </>
                }
            />
        </Drawer>
    );
};

export default ProductDrawerGiveAwayBundling;
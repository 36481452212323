/* eslint-disable react-hooks/exhaustive-deps */
import './BPCOView.scss';

import {useEffect, useState} from 'react';
import {Button} from '@mui/material';
import {
    API_PRODUCT_DATA_BPCOS,
    API_PRODUCT_DATA_BPCOS_CREATE,
    API_STATUSES
} from 'config/api/constants';
import {paths} from 'paths';
import {BPCOPayloadModel} from 'shared/models/BPCO.model';
import {useFormatMessage} from 'utils/translate';
import {getId, getURLParam} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import BPCODetails from './BPCODetails';

const BPCOView = ({history}) => {
    const translate = useFormatMessage();

    const [returnPath, setReturnPath] = useState<string>(paths.BPCOList);
    // const [clone, setClone] = useState<boolean>(false); // @TODO coming soon in MPM-2569
    const [isNewBPCO, setIsNewBPCO] = useState<boolean>(false);
    const [BPCOId, setBPCOId] = useState<string>(null);
    const [BPCOPayload, setBPCOPayload] = useState<BPCOPayloadModel>(null);
    const [isBPCOValid, setIsBPCOValid] = useState<boolean>(false);
    const [originalBPCO, setOriginalBPCO] = useState<string>(null);

    const BPCOsAPIPost = useApi('post', {}, {errMsg: 'bpco.saveErr', succMsg: 'bpco.succ', failureMsg: 'bpco.duplicate'});
    const BPCOsAPIPut = useApi('put', {}, {errMsg: 'bpco.saveErr', succMsg: 'bpco.succ', failureMsg: 'bpco.duplicate'});

    useEffect(() => {
        const fromUrl: string = getURLParam('from');
        if (fromUrl) setReturnPath(fromUrl);

        const id: string = getId();
        if (id) {
            setBPCOId(id);
        }
        const isNew: string = getURLParam('new');
        if (isNew) {
            setIsNewBPCO(true);
        }
        // @TODO coming soon in MPM-2569
        // const clone: string = getURLParam('clone');
        // if (clone){
        //     setClone(true);
        // }
        return () => {
            BPCOsAPIPost.clearToken();
            BPCOsAPIPut.clearToken();
        }
    }, []);

    const handleBPCOChange = (BPCO: BPCOPayloadModel, BPCOValid: boolean) => {
        setIsBPCOValid(BPCOValid);
        setBPCOPayload(BPCOValid ? BPCO : null);
        if (originalBPCO === null && BPCOValid) {
            setOriginalBPCO(JSON.stringify(BPCO));
        }
    };

    const handleSaveClick = () => {
        BPCOId ? BPCOsAPIPut.call(API_PRODUCT_DATA_BPCOS(BPCOId), BPCOPayload) : BPCOsAPIPost.call(API_PRODUCT_DATA_BPCOS_CREATE, BPCOPayload);
    }

    const isBPCOChanged = (): boolean => isNewBPCO ? true : originalBPCO !== JSON.stringify(BPCOPayload);

    const canSave = () => isBPCOValid && isBPCOChanged();

    const handleClose = () => history.push(returnPath);

    const showLoading = () => BPCOsAPIPost.status === API_STATUSES.PENDING || BPCOsAPIPut.status === API_STATUSES.PENDING;

    return (
        <div className="viewRoot BPCOViewRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <BPCODetails onBPCOChange={(newBPCOPayload, BPCOValidity) => handleBPCOChange(newBPCOPayload, BPCOValidity)}
                             BPCOId={BPCOId}
                />
            </div>
            <Footer actionsRight={
                <>
                    <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                    <ButtonClose onClick={handleClose}/>
                </>
            }/>
        </div>
    );
}

export default BPCOView;
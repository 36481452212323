import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {FormattedMessage} from 'react-intl/lib';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {useFormatMessage} from 'utils/translate';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {IRootState} from 'shared/reducers';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import PaperX from 'components/PaperX/PaperX';
import {CountrySelector} from 'components/Selectors';
import {Tab, Tabs} from '@mui/material';
import StatisticsGeneral from './StatisticsGeneral';
import StatisticsManuallyCreatedPromo from './StatisticsManuallyCreatedPromo';

export enum statisticsViewTypes {
    GENERAL_STATISTICS = 'GENERAL_STATISTICS',
    MANUALLY_CREATED_PROMOTIONS = 'MANUALLY_CREATED_PROMOTIONS'
}

const Statistics = () => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const [tableView, setTableView] = useState<statisticsViewTypes>(statisticsViewTypes.GENERAL_STATISTICS);

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const handleCountryChange = (val: string) => dispatch(setPreferredCountry(val));

    const handleSwitchTableView = (e, value: statisticsViewTypes) => setTableView(value);

    const [handleBtnClick, setHandleBtnClick] = useState<() => void>(null);

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div className="_formRowDouble noMargins">
                            <Tabs value={tableView} onChange={handleSwitchTableView} color="secondary">
                                <Tab label={translate({id: 'statistics.generalStatistics'})} value={statisticsViewTypes.GENERAL_STATISTICS}/>
                                <Tab label={translate({id: 'statistics.manuallyCreatedPromotions'})} value={statisticsViewTypes.MANUALLY_CREATED_PROMOTIONS}/>
                            </Tabs>
                            <CountrySelector onCountryChange={(countryId) => handleCountryChange(countryId)} value={countryMarket.preferredCountry} />
                        </div>
                    </PaperX>
                    {tableView === statisticsViewTypes.GENERAL_STATISTICS
                    ?
                        <StatisticsGeneral 
                            setParentClickHandler={setHandleBtnClick}
                            countryMarket={countryMarket}/>
                    :
                        <StatisticsManuallyCreatedPromo
                            setParentClickHandler={setHandleBtnClick}
                            countryMarket={countryMarket}/>
                    }
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button onClick={handleBtnClick} variant="outlined">
                            <FormattedMessage id="a.exportExcel"/>
                        </Button>
                        <Link to={paths.home}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default Statistics;
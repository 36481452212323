/* eslint-disable react-hooks/exhaustive-deps */
import './FranchiseOwnersList.scss';

import {ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import { FormattedMessage } from 'react-intl';
import {Button, Divider} from '@mui/material';
import {paths} from 'paths';
import {useFormatMessage} from 'utils/translate';
import {getId} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {API_FRANCHISE_OWNER_FOR_RETAILER, API_STATUSES} from 'config/api/constants';
import {franchiseOwner, franchiseOwnersResponseModel} from 'shared/models/franchiseOwner.model';
import {branchPerRetailerResponseModel} from 'shared/models/dict.model';
import {retailer} from 'shared/models/retailer.model';
import {fetchBranchesPerRetailer} from 'shared/reducers/branchsePerRetailer';
import {IRootState} from 'shared/reducers';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {RetailerSelector} from 'components/Selectors';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import {ActionListCard} from 'components/Cards/ActionListCard';
import {PaperX} from 'components/PaperX';

const FranchiseOwnersList = () => {
    const dispatch = useDispatch();
    const ownersApiGet = useApi('get', [], {errMsg: 'franchiseOwnersList.err'});
    const translate = useFormatMessage();

    const [retailerId, setRetailerId] = useState<string>('');
    const [selectedOwner, setSelectedOwner] = useState<franchiseOwner>(null);
    const [branchesForOwner, setBranchesForOwner] = useState<branchPerRetailerResponseModel>([]);

    const branchesData = useSelector((state: IRootState) => state.branchesPerRetailer.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesPerRetailer.status);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const buildOwnersCardsList = () => {
        const response: franchiseOwnersResponseModel = ownersApiGet.data;
        return response.map((item) => <ActionListCard key={item.id} selectedId={selectedOwner ? selectedOwner.id : ''} id={item.id} item={item} label={item.name} onCardClick={setSelectedOwner} path={paths.franchiseOwner} canEdit/>);
    }
    useEffect(() => {
        const retailerId: string = getId();
        if (retailerId) setRetailerId(retailerId);
    }, []);

    useEffect(() => {
        if (retailerId) {
            getOwnersData(retailerId);
            setSelectedOwner(null);
            setBranchesForOwner([]);
            dispatch(fetchBranchesPerRetailer(retailerId,selectedLanguage));
        }
    }, [retailerId]);

    useEffect(() => {
        if (selectedOwner === null) {
            setBranchesForOwner([]);
        } else {
            setBranchesForOwner(branchesData.filter((item) => selectedOwner.branchIds.find((branchId) => branchId === item.id )));
        }
    }, [selectedOwner]);

    const getOwnersData = (retailerId: string) => ownersApiGet.call(API_FRANCHISE_OWNER_FOR_RETAILER(retailerId));

    const handleRetailerChange = (retailer: retailer) => setRetailerId(retailer ? retailer.id : null);

    const branchesColumnDefs: ColDef[] = [
        { field: 'city', headerName: translate({ id: 'b.city' })},
        { field: 'postCode', headerName: translate({ id: 'b.postCode' }) },
        { field: 'street', headerName: translate({ id: 'b.street' }) },
        { field: 'houseNumber', headerName: translate({ id: 'b.houseNumber' }), flex: 0 },
        { field: 'regionName', headerName: translate({ id: 'b.region'}) },
        { field: 'nielsenRegionName', headerName: translate({ id: 'b.nielsenRegion'}) }
    ];

    const getHeaderLabel = (): ReactNode => <span>{translate({id: 'branchesOf'})}:&nbsp; {selectedOwner ? selectedOwner.name : '-'}</span>;

    const showLoading = (): boolean => branchesStatus === API_STATUSES.PENDING || ownersApiGet.status === API_STATUSES.PENDING;

    return (
        <div className="viewRoot franchiseOwnersRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX className="inputsContainer">
                        <div className="retailerWithCountry">
                            <div className="retailerContainer">
                                <RetailerSelector retailerId={retailerId} onRetailerChange={handleRetailerChange}/>
                            </div>
                        </div>
                    </PaperX>
                    <div className="_directionRow">
                        <PaperX className="ownersContainer">
                            <div className="_header"><FormattedMessage id="b.franchiseOwners"/></div>
                            <Divider/>
                            <div className="ownersListContainer">
                                <ul>
                                    {buildOwnersCardsList()}
                                </ul>
                            </div>
                        </PaperX>
                        <PaperX className="_fullWidth _fullHeight">
                            <div className="_directionCol">
                                <div className="_header">{getHeaderLabel()}</div>
                                <AgTable
                                    rowData={branchesForOwner}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        floatingFilter: true,
                                        filter: 'agTextColumnFilter',
                                        floatingFilterComponentParams: {suppressFilterButton:true}
                                    }}
                                    columnDefs={branchesColumnDefs}
                                    localStorageColumnsKey={localStorageColumnsKeys.franchiseOwnersList}
                                />
                            </div>
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={retailerId ? `${paths.franchiseOwner}?id=${selectedOwner ? selectedOwner.id : '' }` : paths.franchiseOwner}>
                            <Button variant="contained" color="primary" disabled={!selectedOwner}>{translate({id: 'a.edit'})}</Button>
                        </Link>
                        <Link to={retailerId ? `${paths.franchiseOwner}?retailerId=${retailerId}` : paths.franchiseOwner}>
                            <Button variant="contained" color="primary">{translate({id: 'franchiseOwner.create'})}</Button>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default FranchiseOwnersList;
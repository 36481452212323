/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {API_GET_SUPPLIERS_FOR_BRAND} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import BrandSearch from 'modules/MasterData/Brand/BrandSearch';

interface SupplierDialogWithBrandSelectionDialogProps {
    marketId: string,
    onClose: () => void,
    onBrandChange: (brand: basic, supplier: basic) => void,
    onSupplierChange: (supplier: basic) => void,
    open: boolean,
    originalBrand: basic
}

const SupplierDialogWithBrandSelectionDialog = (props: SupplierDialogWithBrandSelectionDialogProps) => {
    const {originalBrand, marketId, onClose, onBrandChange, onSupplierChange, open} = props;
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const cancelToken = useRef(null);
    
    const [selectedBrand, setSelectedBrand] = useState<basic>(null);
    const [selectedSupplier, setSelectedSupplier] = useState<basic>(null);

    const [suppliers, setSuppliers] = useState<basic[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if (open) {
            setSuppliers([]);
            setSelectedBrand(originalBrand);
        } else {
            setSelectedBrand(null);
            setSelectedSupplier(null);
        }
    }, [open]);

    useEffect(() => {
        if (selectedBrand) {
            getSuppliersForBrand();
            setSelectedSupplier(null);
        }
    }, [selectedBrand]);

    const getSuppliersForBrand = () => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current();
        axios.get<basic[]>(API_GET_SUPPLIERS_FOR_BRAND(selectedBrand.id), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setSuppliers(resp.data);
                    if (resp.data.length === 1) {
                        setSelectedSupplier(resp.data[0]); // requirement to automatically set supplier if only one supplier exists
                    }
                } else {
                    handleError();
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    handleError();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleError = () => {
        enqueueSnackbar(`${translate({id: 'supplier.errors'})}`, {variant: 'error'});
        onClose();
    };

    const handleSelectedSupplierToggle = (supplier: basic) => {
        if (selectedSupplier?.id === supplier.id) {
            setSelectedSupplier(null)
        } else {
            setSelectedSupplier(supplier)
        }
    };

    const decideFollowUpAction = () => {
        if (selectedBrand.id === originalBrand.id) {
            // trigger simple supplier change flow
            onSupplierChange(selectedSupplier);
        } else {
            // trigger brand change flow with duplicate check
            onBrandChange(selectedBrand, selectedSupplier);
        }
    };

    const isSaveDisabled = (): boolean => { //requirement to enforce supplier if it is available
        if (suppliers.length !== 0 && selectedSupplier === null) {
            return true
        }
        return false;
    };

    return (
        <Dialog open={open} fullWidth maxWidth='md'>
            <LoadingOverlay show={isLoading}/>
            <DialogTitle>
                <FormattedMessage id="supplier.selectAndBrand"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <div className="_formRow">
                    <BrandSearch data={selectedBrand}
                                 onBrandSelect={(brand) => setSelectedBrand(brand)}
                                 queryParams={{marketId: marketId, active: true}}
                    />
                </div>
                <div className="_formRow">
                    <span className="_bold"><FormattedMessage id="b.brand"/>:&nbsp;</span>{selectedBrand?.name}
                </div>
                <span className="_bold"><FormattedMessage id="b.suppliers"/>:</span>
                <ul>
                    {suppliers.map((item) => (
                        <li key={item.id}>
                            {item.name}
                            <Checkbox checked={selectedSupplier?.id === item.id}
                                      color="primary"
                                      disabled={suppliers.length === 1} // requirement to automatically set supplier if only one supplier exists
                                      onChange={(e) => handleSelectedSupplierToggle(item)}/>
                        </li>))
                    }
                </ul>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={decideFollowUpAction} disabled={isSaveDisabled()}><FormattedMessage id="a.submit"/></Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.close"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default SupplierDialogWithBrandSelectionDialog;
/* eslint-disable react-hooks/exhaustive-deps */
import './StructuredBrandDetails.scss';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Chip} from '@mui/material';
import {API_GET_STRUCTURED_BRAND} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import {supplierSearchResult} from 'shared/models/supplier.model';
import {structuredBrand, structuredBrandUI} from 'shared/models/brand.model';
import {IRootState} from 'shared/reducers';
import {basic} from 'shared/models/_common.model';
import {responseValidation} from 'utils/responseValidation';
import MarketDisplay from 'components/Displays/MarketDisplay';
import {PaperX} from 'components/PaperX';
import ActiveCheckbox from 'components/ActiveCheckbox';
import BrandSearch from 'modules/MasterData/Brand/BrandSearch';
import {LoadingOverlay} from 'components/LoadingOverlay';
import SupplierSearchPanel from './SupplierSearchPanel';

const buildBrandTemplate = (marketId: string): structuredBrandUI => ({
    active: true,
    id: '',
    mainBrand: null,
    marketId,
    secondaryBrands: [],
    suppliers: []
});

const buildUIbrandFromBeResponse = (response: structuredBrand): structuredBrandUI => {
    const mainBrand = response?.brands[0];
    const {active, id, marketId, suppliers} = response;
    let secondaryBrands: basic[] = [];
    if (response?.brands?.length > 1 ) {
        response.brands.shift();
        secondaryBrands = response.brands;
    }
    return {
        active,
        id,
        mainBrand,
        marketId,
        secondaryBrands,
        suppliers
    }
};

interface StructuredBrandDetailsProps {
    brandId?: string,
    history: any,
    onBrandChange: (brand: structuredBrandUI) => void
}

const StructuredBrandDetails = ({brandId, history, onBrandChange}: StructuredBrandDetailsProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const market = useSelector((state: IRootState) => state.userProfile.countryMarket.market);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brand, setBrand] = useState<structuredBrandUI>(buildBrandTemplate(market));

    useEffect(() => {
        if (brandId) {
            getBrandData(brandId);
        } else if (market) {
            setBrand(buildBrandTemplate(market));
        }
    }, []);

    useEffect(() => {
        if (!brandId) setBrand(buildBrandTemplate(market));
    }, [market]);

    useEffect(() => {
        onBrandChange(brand);
    }, [brand]);

    const getBrandData = (brandId: string) => {
        setIsLoading(true);
        axios.get<structuredBrand>(API_GET_STRUCTURED_BRAND(brandId))
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    const brandData: structuredBrand = resp.data;
                    setBrand(buildUIbrandFromBeResponse(brandData));
                } else {
                    notifyError('brand.err');
                }
            })
            .catch((e) => {
                console.log(e);
                notifyError('brand.err');
            })
            .finally(() => setIsLoading(false))
    };

    const handleMainBrandChange = (value: basic) => {
        if (value === null) {
            const newBrand: structuredBrandUI = {...brand, mainBrand: null};
            setBrand(newBrand);
        } else if (brand?.secondaryBrands?.find((item) => item.id === value.id)) {
            notifyError('brand.already');
            return;
        } else {
            const {id, name} = value;
            const newBrand: structuredBrandUI = {...brand, mainBrand: {id, name}};
            setBrand(newBrand);
        }
    };

    const setActive = (active: boolean) => {
        const newBrand: structuredBrandUI = {...brand};
        newBrand.active = active;
        setBrand(newBrand);
    };

    const handleSecondaryBrandSelect = (brand2add: basic) => {
        const {id, name} = brand2add;
        if (brand?.secondaryBrands?.find((item) => item.id === id) || brand?.mainBrand?.id === id) {
            notifyError('brand.already');
        } else {
            const newBrand: structuredBrandUI = {...brand};
            newBrand.secondaryBrands.push({id, name});
            setBrand(newBrand);
        }
    }

    const handleSecondaryBrandDelete = (brandId: string) => {
        const newBrand: structuredBrandUI = {...brand};
        newBrand.secondaryBrands = brand.secondaryBrands.filter((item) => item.id !== brandId);
        setBrand(newBrand);
    }

    const notifyError = (msgId: string) => enqueueSnackbar(`${translate({id: msgId})}`, {variant: 'error', persist: false});

    const handleSupplierAdd = (supplier: supplierSearchResult) => {
        if (!brand.suppliers.find((item) => item.id === supplier.id)) {
            const newBrand: structuredBrandUI = {...brand};
            const newSupplier: basic = {
                id: supplier.id,
                name: supplier.name
            }
            newBrand.suppliers.push(newSupplier);
            setBrand(newBrand);
        }
    };

    const handleSupplierRemove = (supplierId: string) => {
        const newBrand: structuredBrandUI = {...brand};
        newBrand.suppliers = brand.suppliers.filter((item) => item.id !== supplierId)
        setBrand(newBrand);
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <div className="_directionCol _fullHeight _fullWidth">
                <PaperX className="weldBottom">
                    <div className="_header">
                        <FormattedMessage id="brand.struct"/>
                    </div>
                    <MarketDisplay marketId={brand?.marketId || ''}/>
                    <div className="_formRowDouble">
                        <div>
                            <BrandSearch onBrandSelect={handleMainBrandChange}
                                         data={brand?.mainBrand}
                                         queryParams={{marketId: brand?.marketId, active: true, structured: false}}
                                         autoFocus={!!!brandId}
                                         customLabel={translate({id: 'brand.main'})}
                                         clearOnSelect
                                         required/>
                        </div>
                        <div>
                            <ActiveCheckbox value={brand ? brand.active : true} onChange={(e, value) => setActive(value)}/>
                        </div>
                    </div>
                    <div>
                        <FormattedMessage id="brand.secondary"/>:
                    </div>
                    <div className="_formRowDouble">
                        <div>
                            <BrandSearch onBrandSelect={handleSecondaryBrandSelect}
                                         queryParams={{marketId: brand?.marketId, active: true, structured: false}}
                                         autoFocus={!!brandId}
                                         customLabel={translate({id: 'brand.selectSec'})}
                                         clearOnSelect/>
                        </div>
                    </div>
                    <div className="structuredBrandsContainer">
                        {brand?.secondaryBrands?.map((item) => <Chip color="secondary" variant="outlined"
                                                                     key={item.id}
                                                                     label={item.name}
                                                                     onDelete={() => handleSecondaryBrandDelete(item.id)}/>)}
                    </div>
                    <div className="_bold">
                        <FormattedMessage id="b.suppliers"/>:
                    </div>
                </PaperX>
                <PaperX className="weldTop _scrollY _fullHeight">
                    {brand?.suppliers.length === 0 && '-'}
                    <ul>
                        {brand?.suppliers.map((item) => <li key={item.id} >{item.name}<Button onClick={() => handleSupplierRemove(item.id)}>
                            <FormattedMessage id="a.remove"/>
                        </Button></li>)}
                    </ul>
                </PaperX>
            </div>
            <div className="_directionCol _fullHeight _fullWidth">
                <SupplierSearchPanel marketId={brand?.marketId} onSupplierAdd={(selectedSupplier) => handleSupplierAdd(selectedSupplier)}/>
            </div>
        </>
    );
};
export default StructuredBrandDetails;
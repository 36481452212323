// defaults for reference
// https://www.ag-grid.com/react-data-grid/localisation/
// const AG_GRID_LOCALE_EN = {
//     pinColumn: 'Pin Column',
//     pinLeft: 'Pin Left',
//     pinRight: 'Pin Right',
//     noPin: 'No Pin',
//     autosizeThiscolumn: 'Autosize This Column',
//     autosizeAllColumns: 'Autosize All Columns',
// }

export const AG_GRID_LOCALE_PL = {
    pinColumn: 'Przypnij kolumnę',
    pinLeft: 'Do lewej',
    pinRight: 'Do prawej',
    noPin: 'Brak',
    autosizeThiscolumn: 'Dopasuj tą kolumnę',
    autosizeAllColumns: 'Dopasuj wszystkie kolumny',
}

export const AG_GRID_LOCALE_DE = {
    pinColumn: 'Spalte fixieren',
    pinLeft: 'Links fixieren',
    pinRight: 'Rechts fixieren',
    noPin: 'Nicht fixieren',
    autosizeThiscolumn: 'Spaltenbreite automatisch anpassen',
    autosizeAllColumns: 'Alle Spalten automatisch anpassen',
}

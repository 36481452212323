import {useMutation, useQuery} from 'react-query';
import axios, {AxiosError} from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_PROMOTIONS_FOR_MEDIA, API_PROMOTIONS_FOR_MEDIA} from 'config/api/constants';
import {promotionsForSpotResponseModel, promotionMediumType} from 'shared/models/media.model';
import {promotionUI} from 'shared/models/promotion.model';
import {useFormatMessage} from 'utils/translate';
import {lockLevels, lockSetter} from 'utils/lockUtils';
import {createMediaPromotionsPayload} from 'modules/Advertisement/AdvertisementSpotOverview/advertisementsMediaOverviewIO';

const getPromotionsForMedia = async (id:string, lang:string, acquireLock:boolean): Promise<promotionsForSpotResponseModel> => {
    if (!id) {
        return null;
    }
    lockSetter(lockLevels.medium);
    const response = await axios.get(API_GET_PROMOTIONS_FOR_MEDIA(id), {params: {lang, acquireLock}});
    return response.data;
};

export const useGetPromotionsForMedia = (id: string, lang: string, isAcquireLock: boolean, on409?: Function) => {
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    return useQuery(
        [id, lang, isAcquireLock, 'getPromotionsForMedia'],
        () => getPromotionsForMedia(id, lang, isAcquireLock),
        {
            staleTime: 0,
            cacheTime: 1,
            enabled: false,
            retry: 1,
            onError: (e: AxiosError) => {
                console.log(e);
                if (e.response.status === 409) {
                    on409 && on409();
                } else enqueueSnackbar(`${translate({id: 'advertisementsOverview.fetchPromoErr'})}`, {variant: 'error', persist: false});
            }
        }
    );
}

export const usePutPromotionsForMedia = (mediumId: string, promotions: promotionUI[], promotionMediumType: promotionMediumType, onSucccess?: Function) => {
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        () => axios.put(API_PROMOTIONS_FOR_MEDIA, createMediaPromotionsPayload(promotions, mediumId, promotionMediumType)),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.updateSucc'})}`, {variant: 'success', persist: false});
                onSucccess && onSucccess();
            },
            onError: (e: AxiosError) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.updateErr'})}`, {variant: 'error', persist: false});
            }
        }
    )
}
import './DescriptionDisplayText.scss';

import {FormattedMessage} from 'react-intl';
import {productDescriptionUI} from 'shared/models/productDescription.model';
import {useFormatMessage} from 'utils/translate';
import {PaperX} from 'components/PaperX';
import {MarketDisplay} from 'components/Displays';


interface DescriptionDisplayTextProps {
    data: productDescriptionUI
}

const DescriptionDisplayText = ({data}: DescriptionDisplayTextProps) => {
    const translate = useFormatMessage();
    return (
        <PaperX className="_fullHeight _fullWidth _scrollY weldTop descriptionDisplayTextRoot">
            <div className="_formRow">
                <MarketDisplay marketId={data.marketId}/>
            </div>
            <div className="_formRow">
                <span><span className="_bold">{translate({id: 'b.category'})}:</span> {data.category.name}</span>
            </div>
            <span className="_bold"><FormattedMessage id="b.mainDescription"/></span>
            <ul>
                {data.mainDescriptions.map((item, idx) =>
                    (<li key={idx} className="_formRow">
                        <span className="_bold">{translate({id: `language.${item.language}`})}: </span><span>{item.description}</span>
                    </li>))}
            </ul>
            <span className="_bold"><FormattedMessage id='b.alternativeDescription'/></span>
            <ul>
                {data.alternativeDescriptions.map((item, idx) =>
                    (<li key={idx} className="_formRow">
                        <span className="_bold">{translate({id: `language.${item.language}`})}: </span><span>{item.description}</span>
                    </li>))}
            </ul>
        </PaperX>
    );
};

export default DescriptionDisplayText;
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const AI_CLOUD_ROLE = 'react-frontend';
const AI_CLOUD_ROLE_INSTANCE = 'react-frontend-instance';

export const initializeTracker = (instrumentationKey: string, connectionString: string) => {
    const appInsights = new ApplicationInsights({ config: {
            instrumentationKey,
            connectionString,
            disableTelemetry: false
        } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    const telemetryInitializer = (envelope) => {
        envelope.tags['ai.cloud.role'] = AI_CLOUD_ROLE;
        envelope.tags['ai.cloud.roleInstance'] = AI_CLOUD_ROLE_INSTANCE;
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);
    console.log(appInsights)
}

import './Attribute.scss';

import { useEffect, useState} from 'react';
import {Autocomplete, Chip, TextField} from '@mui/material';
import {
    multiselectAttributeDataModel,
    unitAttributeModel
} from 'shared/models/attributeComponent.model';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

type attributeMultiselectProps = {
    data: multiselectAttributeDataModel,
    disableAutoselect?: boolean,
    model: unitAttributeModel,
    onChange: Function,
    onClear: Function,
    required?: boolean,
    readonly?: boolean,
    disabled?: boolean
}

const AttributeMultiselect = (props: attributeMultiselectProps) => {
    const {data, disableAutoselect, model, onChange, onClear, required, readonly, disabled} = props;

    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(model.options.sort((a, b) => a.name.localeCompare(b.name)));
    }, [model]);

    const handleChange = (value) => (value === null) ? onClear(model.id) : onChange(model.id, {literals: value.map((val) => ({literalId: val.id || val.literalId, literalName: val.name || val.literalName}))});

    const handleClear = () => onClear(model.id);

    const findOptionForDataId = () => {
        const matchArray = [];
        data.literals.forEach((dataItem) => {
            let match = options.find((item) => item.id === dataItem.id || item.id === dataItem.literalId);
            if (match) {
                matchArray.push(match);
            }
        });
        return matchArray;
    };

    const getName = () => required ? model.name + ' *' : model.name;

    return (
        <div className="attributeRoot attributeEnum">
            <fieldset className="complexAttributeWrapper">
                <Autocomplete
                    className="attributeAutocomplete"
                    multiple
                    disabled={disabled ? disabled : readonly}
                    autoSelect={!disableAutoselect}
                    isOptionEqualToValue={(option, value) => option.id === value.id || option.id === value.literalId}
                    value={data.literals || []}
                    options={options}
                    getOptionLabel={(option) => option.name }
                    renderTags={(value, getTagProps) =>
                        findOptionForDataId().map((option, index) => (
                            <Chip color="secondary" label={option.name} {...getTagProps({ index })} />
                        ))
                    }
                    onChange={(e, value) => handleChange(value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={getName()}
                            disabled={disabled}
                        />
                    )}
                />
                {disabled ?
                    <div className="clearInputButtonContainer">
                        <IconButton onClick={handleClear} tabIndex={-1}>
                            <ClearIcon/>
                        </IconButton>
                    </div>
                    : null}
            </fieldset>
        </div>
    );
};
export default AttributeMultiselect;
/* eslint-disable react-hooks/exhaustive-deps */
import './LeafletProgressDialog.scss';

import {useEffect, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Tooltip} from '@mui/material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {API_MULTIPLE_LEAFLET_PROGRESS, API_STATUSES} from 'config/api/constants';
import {paths} from 'paths';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface MultipleProgressDialogProps {
    leafletIds: string[],
    onClose: () => void,
    open: boolean,
}

const MultipleProgressDialog = (props: MultipleProgressDialogProps) => {
    const {leafletIds, onClose, open} = props;

    const apiMultipleProgressPost = useApi('post', null, {errMsg: 'a.error2'});

    const cancelToken = useRef(null);

    useEffect(() => {
        return () => {
            if (cancelToken.current) {
                cancelToken.current();
            }
        }
    }, []);

    const getProgress = () => {
        if (cancelToken.current) cancelToken.current();
        cancelToken.current = apiMultipleProgressPost.call(API_MULTIPLE_LEAFLET_PROGRESS, leafletIds);
    };

    useEffect(() => {
        if (open) {
            getProgress();
        } else if (cancelToken.current) {
             cancelToken.current();
        }
    }, [open]);

    return (
        <Dialog className="leafletProgressDialogRoot" open={open} maxWidth={'sm'} fullWidth onClose={onClose}>
            <LoadingOverlay show={apiMultipleProgressPost.status === API_STATUSES.PENDING}/>
            <DialogTitle>
                <FormattedMessage id="a.progress" />
            </DialogTitle>
            <Divider/>
            <DialogContent>
                { apiMultipleProgressPost.data ?
                    <ul className="leafletProgressList">
                        <li><FormattedMessage id="printMediaOverview.totalFrames"/>: <span className="_bold">{apiMultipleProgressPost.data?.totalFrames}</span></li>
                        <li><FormattedMessage id="printMediaOverview.describedFrames"/>: <span className="_bold">{apiMultipleProgressPost.data?.describedFrames}</span></li>
                        <li><FormattedMessage id="printMediaOverview.reportedFrames"/>: <span className="_bold">{apiMultipleProgressPost.data?.reportedFrames}</span></li>
                        <li><FormattedMessage id="printMediaOverview.reportedPages"/>: <span className="_bold">{apiMultipleProgressPost.data?.reportedPages}</span></li>
                        <li><span>&nbsp;</span></li>
                        {apiMultipleProgressPost.data?.currentUsers.length
                        ?
                            <Tooltip placement='bottom-end' title={<ul>{apiMultipleProgressPost.data?.currentUsers.map((name: string, i: number) => <li key={i}>{name}</li>)}</ul>}>
                                <li className="currentUsersList">
                                    <FormattedMessage id="printMediaOverview.currentUsers"/>: <span className="_bold">{apiMultipleProgressPost.data?.currentUsersCount}</span>
                                </li>
                            </Tooltip>
                        :
                            <li>
                                <FormattedMessage id="printMediaOverview.currentUsers"/>: <span className="_bold">{apiMultipleProgressPost.data?.currentUsersCount}</span>
                            </li>
                        }
                  </ul>
                    :
                    <FormattedMessage id="printMediaOverview.noProgressData"/>
                }
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Link to={paths.reportedIssues}>
                    <Button variant="contained" color="primary" disabled={!(apiMultipleProgressPost.data?.reportedFrames || apiMultipleProgressPost.data?.reportedPages)}>
                        <FormattedMessage id="nav.qualityBrowse"/>
                    </Button>
                </Link>
                <ButtonClose onClick={onClose} />
            </DialogActions>
        </Dialog>
    );
};

export default MultipleProgressDialog;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Drawer} from '@mui/material';
import {
    API_BPCO_DETAILS,
    API_PRODUCT_DATA_BPCOS_CREATE,
    API_STATUSES
} from 'config/api/constants';
import {useSnackbar} from 'notistack';
import {BPCOPayloadModel, BPCOPostResponseModel, BPCOResponseModel} from 'shared/models/BPCO.model';
import {IRootState} from 'shared/reducers';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {ButtonClose} from 'components/Buttons';
import Footer from 'components/Footer';
import {extendOrOverwritePromotionType} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';
import BPCODetails from 'modules/MasterData/BPCO/BPCODetails/BPCODetails';
import {extendOrOverwritePromotionTemplate} from 'modules/Advertisement/FrameDescription/ProductDrawer/ProductDrawer';

export interface BPCODrawerProps {
    clone: boolean,
    isNewBPCO: boolean,
    onClose?: Function,
    onCreate?: (createdBPCO: BPCOResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => void,
    open: boolean,
    BPCOId: string,
}

const BPCODrawer = (props: BPCODrawerProps) => {
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();
    const {clone, 
        isNewBPCO,
        onClose, onCreate, open, BPCOId} = props;
    // const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const [BPCOPayload, setBPCOPayload] = useState<BPCOPayloadModel>(null);
    const [isBPCOValid, setIsBPCOValid] = useState<boolean>(false);
    const [originalBPCO, setOriginalBPCO] = useState<string>(null);

    const langData = useSelector((state: IRootState) => state.userProfile.langData);

    const BPCOsAPIPost = useApi('post', {}, {succMsg: 'bpco.succ'});
    const BPCOsAPIGet = useApi('get', null, {errMsg: 'bpco.err'});

    const [extendOrOverwritePromotion, setExtendOrOverwritePromotion] = useState<extendOrOverwritePromotionType>(extendOrOverwritePromotionTemplate);

    useEffect(() => {
        if (BPCOsAPIGet.status === API_STATUSES.IDLE) {
            setOriginalBPCO(null);
            onCreate(BPCOsAPIGet.data, extendOrOverwritePromotion);
        } else if (BPCOsAPIGet.status === API_STATUSES.ERROR) {
            handleClose();
        }
    }, [BPCOsAPIGet.status]);

    useEffect(() => {
        if (BPCOsAPIPost.status === API_STATUSES.IDLE) {
            // get data of new created BPCO
            const newBPCO: BPCOPostResponseModel = BPCOsAPIPost.data;
            BPCOsAPIGet.call(API_BPCO_DETAILS(newBPCO.id, langData));
        } else if (BPCOsAPIPost.status === API_STATUSES.ERROR && BPCOsAPIPost.errorCode === 409) {
            if (BPCOsAPIPost?.dataError?.id) {
                BPCOsAPIGet.call(API_BPCO_DETAILS(BPCOsAPIPost.dataError.id, langData));
            } else {
                enqueueSnackbar(`${translate({id: 'bpco.duplicate'})}`, {variant: 'error', persist: false});
            }
        } else if (BPCOsAPIPost.status === API_STATUSES.ERROR) {
            enqueueSnackbar(`${translate({id: 'bpco.saveErr'})}`, {variant: 'error', persist: false});
        }

    }, [BPCOsAPIPost.status]);

    const handleBPCOChange = (BPCO: BPCOPayloadModel, BPCOValid: boolean) => {
        setIsBPCOValid(BPCOValid);
        setBPCOPayload(BPCOValid ? BPCO : null);
        if (originalBPCO === null && BPCOValid) {
            setOriginalBPCO(JSON.stringify(BPCO));
        }
    };

    const handleExtendClick = () => {
        setExtendOrOverwritePromotion(extendOrOverwritePromotionTemplate);
        handleSaveClick();
    };

    const handleOverwriteClick = () => {
        setExtendOrOverwritePromotion({extend: false, overwriteProductId: BPCOId});
        handleSaveClick();
    };

    const handleSaveClick = () => BPCOsAPIPost.call(API_PRODUCT_DATA_BPCOS_CREATE, BPCOPayload);

    const isBPCOChanged = (): boolean => isNewBPCO ? true : originalBPCO !== JSON.stringify(BPCOPayload);

    const canSave = () => isBPCOValid && isBPCOChanged();

    const handleClose = () => {
        setOriginalBPCO(null);
        onClose();
    };

return (<Drawer className="frameDescriptionDrawerRoot"
                anchor="right"
                hideBackdrop
                open={open}
        >
            <LoadingOverlay show={BPCOsAPIPost.status === API_STATUSES.PENDING || BPCOsAPIGet.status === API_STATUSES.PENDING}/>
            <BPCODetails onBPCOChange={(newBPCOPayload, BPCOValidity) => handleBPCOChange(newBPCOPayload, BPCOValidity)}
                         autosetSupplier
                         BPCOId={BPCOId}
                         isDescribingFrame
            />
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        {clone ?
                            <>
                                <Button color="primary" variant="contained" onClick={handleExtendClick} disabled={!canSave()}>{translate({id: 'a.add'})}</Button>
                                <Button color="primary" variant="contained" onClick={handleOverwriteClick} disabled={!canSave()}>{translate({id: 'a.overwrite'})}</Button>
                            </>
                            :
                            <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        }
                        <ButtonClose onClick={handleClose}/>
                    </>
                }
            />

        </Drawer>);
}

export default BPCODrawer;
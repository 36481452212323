import axios from 'axios';
import {useQuery} from 'react-query';
import {API_REGIONS} from 'config/api/constants';
import {regionResponseModel} from 'shared/models/region.model';

const fetchRegion = async (): Promise<regionResponseModel> => {
    const response = await axios.get(API_REGIONS);
    return response.data
};

export const useRegionQuery = () => useQuery(['fetchRegion'], () => fetchRegion(), {staleTime: Infinity});
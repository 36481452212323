/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_REPORTED_PRODUCTS} from 'config/api/constants';
import {paths} from 'paths';
import {IRootState} from 'shared/reducers';
import {reportedProductsResponseModel} from 'shared/models/product.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import ProductPreview from 'components/gfx/ProductPreview/ProductPreview';
import {getFilteredProductsColDef} from 'modules/MasterData/Product/ProductsListFilterResults';

const ReportedProducts = () => {
    const history = useHistory();
    const translate = useFormatMessage();
    const cancelToken = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const userLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const market = useSelector((state: IRootState) => state.userProfile.countryMarket.market);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<reportedProductsResponseModel[]>([]);

    const fetchReportedProducts = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);

        axios.get<reportedProductsResponseModel[]>(API_GET_REPORTED_PRODUCTS(market, userLanguage), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setData(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch(e => {
                if(!e?.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleRowClick = (e: RowClickedEvent) => {
        const htmlTargetEl = e.event.target as HTMLElement;
        if (htmlTargetEl.className.split(' ').includes('ag-cell')){
            history.push(`${paths.productDetails}?id=${e.data.id}&from=${paths.reportedIssues}`);
        } else return;
    };

    const columnDefs: ColDef[] = [
        {
            field: 'comments',
            headerName: translate({ id: 'a.reason' }),
            width: 400,
            lockVisible: true,
            valueGetter: (params) =>
                params.data.comments.map((comment) => `${comment.text}${comment.author ? ` [${comment.author}]` : ''}`).join(', '),
        },
    ];

    useEffect(() => fetchReportedProducts(), [market]);

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <AgTable
                rowData={data}
                defaultColDef={{
                    resizable: true,
                    sortable: true
                }}
                columnDefs={columnDefs.concat(getFilteredProductsColDef(translate))}
                detailCellRenderer={(rowData) => <ProductPreview productId={rowData.data.id}/>}
                onRowClicked={handleRowClick}
                masterDetail={true}
                localStorageColumnsKey={localStorageColumnsKeys.reportedProductsList}
            />
        </>
    );
};

export default ReportedProducts;
import {useMutation, useQuery} from 'react-query';
import axios, {AxiosError} from 'axios';
import {useSnackbar} from 'notistack';
import {API_DELETE_PAGE_CONNECTION, API_GET_PROMOTIONS_FOR_FRAME, API_PROMOTIONS_FOR_FRAME} from 'config/api/constants';
import {promotionsForFrameResponseModel} from 'shared/models/frame.model';
import {promotionUI} from 'shared/models/promotion.model';
import {useFormatMessage} from 'utils/translate';
import { lockLevels, lockSetter } from 'utils/lockUtils';
import {createPromotionsPayload} from './advertisementsOverviewIO';

const getPromotionsForFrame = async (id:string, lang:string, acquireLock:boolean): Promise<promotionsForFrameResponseModel> => {
    if (!id) {
        return null;
    }
    lockSetter(lockLevels.page);
    const response = await axios.get(API_GET_PROMOTIONS_FOR_FRAME(id), {params: {lang, acquireLock}});
    return response.data;
};

export const useGetPromotionsForFrame = (id:string, lang:string, isAcquireLock:boolean, on404?: Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useQuery(
        [id, lang, isAcquireLock, 'getPromotionsForFrame'],
        () => getPromotionsForFrame(id, lang, isAcquireLock),
        {
            staleTime: 0,
            cacheTime: 1,
            enabled: false,
            retry: 1,
            onError: (e: AxiosError) => {
                console.log(e);
                if (e.response.status === 404) {
                    on404 && on404();
                }
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.fetchPromoErr'})}`, {variant: 'error', persist: false});
            }
        }
    );
}

export const usePutPromotionsForFrame = (frameId: string, promotions: promotionUI[], validFrom: string, validTo: string, onSucccess?:Function, on404?: Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        () => axios.put(API_PROMOTIONS_FOR_FRAME, createPromotionsPayload(promotions,frameId, validFrom, validTo)),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.updateSucc'})}`, {variant: 'success', persist: false});
                onSucccess && onSucccess();
            },
            onError: (e: AxiosError) => {
                console.log(e);
                if (e.response.status === 404) {
                    on404 && on404();
                }
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.updateErr'})}`, {variant: 'error', persist: false});
            }
        }
    )
}

export const useDeleteConnectionOfPage = (leafletId: string, pageNumber: number, onSuccess?:Function, onError?: Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        () => axios.delete(API_DELETE_PAGE_CONNECTION(leafletId, pageNumber)).catch((error) => {
            throw(error);
        }),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'advertisementsOverview.disconnectPageSucc'})}`, {variant: 'success', persist: false});
                onSuccess && onSuccess();
            },
            onError: (e: AxiosError) => {
                console.log(e);
                onError && onError();
                if (e.response.status === 409){
                    enqueueSnackbar(`${translate({id: 'page.locked'})}`, {variant: 'error', persist: false});
                } else enqueueSnackbar(`${translate({id: 'advertisementsOverview.disconnectPageErr'})}`, {variant: 'error', persist: false});
            }
        }
    )
}
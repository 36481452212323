import './Displays.scss';
import './GeneralInfoDisplay.scss';

import {useFormatMessage} from 'utils/translate';

interface GeneralInfoDisplayProps {
    countryThemedWeeks: string[],
    promotionTypes: string[],
    themeSeasons: string[],
    qualitySeals: string[],
}
const GeneralInfoDisplay = ({ countryThemedWeeks, promotionTypes, themeSeasons, qualitySeals}: GeneralInfoDisplayProps) => {
    const translate = useFormatMessage();
    return (
        <div className="generalInfoDisplayRoot">
            {promotionTypes.length ? <span className="displayChip infoChip" title={translate({id: 'b.promotionType'})}>{promotionTypes.join(', ')}</span> : null}
            {themeSeasons.length ? <span className="displayChip infoChip infoChip3" title={translate({id: 'b.promotionSeasonTheme'})}>{themeSeasons.join(', ')}</span> : null}
            {countryThemedWeeks.length ? <span className="displayChip infoChip" title={translate({id: 'b.promotionCountryWeek'})}>{countryThemedWeeks.join(', ')}</span> : null}
            {qualitySeals.length ? <span className="displayChip infoChip infoChip2" title={translate({id: 'b.promotionQS'})}>{qualitySeals.join(', ')}</span> : null}
        </div>
    )
};

export default GeneralInfoDisplay;
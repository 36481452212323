/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState, SyntheticEvent} from 'react';
import {useSelector} from 'react-redux';
import {
    Autocomplete,
    CircularProgress,
    InputAdornment,
    TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    API_CATEGORY_ELASTIC_SEARCH,
    API_CATEGORY_SEARCH,
    API_STATUSES
} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import useDebounce from 'utils/debounce';
import {IRootState} from 'shared/reducers';
import {
    categorySearchResponseObject,
    categorySearchResponseObjectUI,
    categorySelectorOption,
    categoryType as caType
} from 'shared/models/category.model';

export const getTypeLabel = (type: string): string => {
    switch (type) {
        case caType.SEGMENT:
            return 'b.categorySegment';
        case caType.FAMILY:
            return 'b.categoryFamily';
        case caType.CLASS:
            return 'b.categoryClass';
        case caType.BRICK:
            return 'b.categoryBrick';
        default:
            return 'a.error';
    }
}

const makeCategorySelectorOption = (option: string | categorySearchResponseObjectUI, type: caType):categorySelectorOption => {
    if (typeof option !== 'string' && option !== null) {
        let selectionType:caType = null;
        if (option.brickId) {
            selectionType = caType.BRICK;
        } else if (option.classId) {
            selectionType = caType.CLASS;
        } else if (option.familyId) {
            selectionType = caType.FAMILY;
        } else if (option.segmentId) {
            selectionType = caType.SEGMENT;
        }
        return {
            id: option.brickId || option.classId || option.familyId || option.segmentId,
            name: option.brickName || option.className || option.familyName || option.segmentName,
            type: selectionType
        };
    }
    return {id: '', name: '', type};
}

export interface categorySearchByTypeProps {
    onCategorySelect: (category: categorySelectorOption) => void,
    value: categorySelectorOption,
    categoryType?: caType,
    customLabel?: string,
    disabled?: boolean,
    getOnlyIfActiveFlagEquals?: boolean,
    required?: boolean
}

export const CategorySearch = (props:categorySearchByTypeProps) => {
    const {getOnlyIfActiveFlagEquals, onCategorySelect, value, categoryType, customLabel, disabled, required} = props;
    const translate = useFormatMessage();
    const label = categoryType ? `${translate({id: 'b.category'})}: ${translate({id: getTypeLabel(categoryType)})}` : translate({id: 'b.category'});
    const minQueryLen = 3;
    const searchApiGet = useApi('get', [], {errMsg: 'a.error2'});

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [queryStr, setQueryStr] = useState<string>('');

    const [localResults, setLocalResults] = useState<categorySearchResponseObjectUI[]>([]);
    const [dbncdQueryStr, setDbncdQueryStr] = useDebounce(queryStr, 500);

    useEffect(() => {
        if (dbncdQueryStr.length >= minQueryLen) {
            searchApiGet.call(categoryType ? API_CATEGORY_ELASTIC_SEARCH : API_CATEGORY_SEARCH, {params: {search: dbncdQueryStr, type: categoryType, lang: selectedLanguage, active: getOnlyIfActiveFlagEquals, limit: 100}});
        }
    }, [dbncdQueryStr]);

    useEffect(() => {
        if (searchApiGet.status === API_STATUSES.IDLE) {
            setLocalResults(groupResults(searchApiGet.data));
        } else {
            setLocalResults([]);
        }
    }, [searchApiGet.status]);

    const groupResults = (options: categorySearchResponseObject[]): categorySearchResponseObjectUI[] => options.map((item) => {
        let categoryLabel = '-unknown category-';
        if (item.brickId) {
            categoryLabel = translate({id: 'b.categoryBrick'});
        } else if (item.classId) {
            categoryLabel = translate({id: 'b.categoryClass'});
        } else if (item.familyId) {
            categoryLabel = translate({id: 'b.categoryFamily'});
        } else if (item.segmentId) {
            categoryLabel = translate({id: 'b.categorySegment'});
        }
        return {...item, categoryLabel};
    });

    const buildCategoryPath = (option) => {
        const {className, familyName, segmentName} = option;
        let categoryPath = ['-unknown-'];
        if (categoryType === caType.BRICK) {
            categoryPath = [option.brickName, className, familyName, segmentName];
        } else if (categoryType === caType.CLASS) {
            categoryPath = [className, familyName, segmentName];
        } else if (categoryType === caType.FAMILY) {
            categoryPath = [familyName, segmentName];
        } else if (categoryType === caType.SEGMENT) {
            categoryPath = [segmentName];
        }
        return categoryPath
    };

    // filtering undefined values, as BE model does not provide full category path
    const labelRenderer = ():string => {
        if (value?.name) {
            return `${value.name} [${translate({id: getTypeLabel(value.type)})}]`;
        }
        return '';
    }

    const listOptionRenderer = (option) => {
        // filtering undefined values, as BE model does not provide full category path
        return buildCategoryPath(option).filter((item) => item).map((item, idx, ar) => {
            return idx + 1 === ar.length ? <span key={idx}>{item}</span> : <span key={idx+"a"} className="categoryArrow">{item}<NavigateNextIcon key={idx+"b"} color="primary"/></span>;
        });
    };

    const listRenderer = (props, option) =>
        <li {...props}
            className="mpmSearchOption noJustify"
            key={ option.brickId || option.classId || option.familyId || option.segmentId }>
            {listOptionRenderer(option)}
        </li>;

    const handleAutocompleteChange = (e: SyntheticEvent, option: string | categorySearchResponseObjectUI) =>
        onCategorySelect(makeCategorySelectorOption(option, categoryType));

    const handleSearchInputChange = (val: string) => {
        if (searchApiGet.status === API_STATUSES.PENDING) {
            searchApiGet.clearToken();
        }
        setQueryStr(val);
        setDbncdQueryStr(val)
    };

    return (<div className="mpmSearchRoot">
        <Autocomplete
            className="searchInput"
            selectOnFocus
            clearOnBlur
            autoSelect
            value={value?.name || null}
            options={localResults}
            groupBy={(option) => option.categoryLabel}
            onChange={handleAutocompleteChange}
            filterOptions={(option) => option}
            getOptionLabel={() => labelRenderer()}
            renderOption={(props, option) => listRenderer(props, option)}
            disabled={disabled}
            renderInput={(params) => <TextField {...params}
                                                onChange={(e) => handleSearchInputChange(e.target.value)}
                                                label={customLabel || label}
                                                required={required}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <>
                                                            <InputAdornment position="start">
                                                                {searchApiGet.status === API_STATUSES.PENDING ? <CircularProgress size={24}/>: <SearchIcon/> }
                                                            </InputAdornment>
                                                            {params.InputProps.startAdornment}
                                                        </>
                                                    )
                                                }}
            />}
        />
    </div>)
};
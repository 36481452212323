import './HeaderDataDialog.scss';

import {useEffect, useState} from 'react';
import {GetContextMenuItemsParams, GridOptions, MenuItemDef} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {branch} from 'shared/models/branch.model';
import RepresentativeBranch from 'modules/MasterData/AdvertisingArea/RepresentativeBranch';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {BranchesSelector} from 'components/Selectors';
import ButtonClose from 'components/Buttons/ButtonClose';
import PaperX from 'components/PaperX/PaperX';
import {defaultColCfgWithSearch} from 'components/AgTable/AgTable';

interface branchesSelectionDialogProps {
    open: boolean,
    busy: boolean,
    branches: branch[],
    selectedBranches: string[],
    onSelectionChange: (branchIds: string[]) => void,
    onRepBranchChange: (branchId: string) => void,
    onClose: Function,
    leafletRepresentativeBranchId: string
}

const BranchesSelectionDialog = (props: branchesSelectionDialogProps) => {
    const {open, busy, branches, selectedBranches, onSelectionChange, onClose, leafletRepresentativeBranchId, onRepBranchChange} = props;
    const translate = useFormatMessage();

    const [representativeBranchId, setRepresentativeBranchId] = useState<string>('');

    useEffect(() => {
        setRepresentativeBranchId(leafletRepresentativeBranchId);
    }, [leafletRepresentativeBranchId]);

    const columnDefs: ColDef[] = [
        { field: 'postCode', headerName: translate({ id: 'b.postCode' }), sortable: true, maxWidth: 100, filter: 'agTextColumnFilter', floatingFilter: true, floatingFilterComponentParams: {suppressFilterButton:true} },
        { field: 'city', headerName: translate({ id: 'b.city' }), sortable: true, flex: 1, filter: 'agTextColumnFilter', floatingFilter: true, floatingFilterComponentParams: {suppressFilterButton:true} },
        { headerName: translate({ id: 'b.street' }), sortable: true, flex: 1, filter: 'agTextColumnFilter', floatingFilter: true, floatingFilterComponentParams: {suppressFilterButton:true},
            valueGetter:
                (params) => `${params.data.street} ${params.data.houseNumber ? params.data.houseNumber : ''}`
        },
        { field: 'regionName', headerName: translate({ id: 'b.region'}), sortable: true, flex: 1, filter: 'agTextColumnFilter', floatingFilter: true, floatingFilterComponentParams: {suppressFilterButton:true}},
    ];
    const getContextMenu = (rowNode: GetContextMenuItemsParams): MenuItemDef[] => rowNode.node.data.isRep ? [{name: translate({id: 'advertisingArea.cantSetAsRep'})}] :
        [
            {
                name: translate({id: 'advertisingArea.setAsRep'}),
                action: () => onRepBranchChange(rowNode.node.data.id)
            }
        ];
    const rightTableProps: GridOptions = {
        defaultColDef: {
            ...defaultColCfgWithSearch,
            cellClass: (params) => params.data.id === representativeBranchId ? '_highlightedTableRow': null,
            tooltipValueGetter: () => translate({id: 'a.right4context'})
        },
        tooltipShowDelay: 500,
        suppressContextMenu: false,
        getContextMenuItems: (rowNode) => getContextMenu(rowNode)
    };
    return (<Dialog className="branchesSelectionDialogRoot" open={open} fullScreen>
        <LoadingOverlay show={busy}/>
        <DialogContent className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <PaperX className="_fullWidth">
                        <div className="_formRowDouble">
                            <div></div>
                            <RepresentativeBranch id={representativeBranchId}/>
                        </div>
                    </PaperX>
                    <BranchesSelector
                        columnDefs={columnDefs}
                        branches={branches}
                        selectedBranches={selectedBranches}
                        onSelectionChange={onSelectionChange}
                        rightTableProps={rightTableProps}
                    />
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <ButtonClose onClick={onClose} />
        </DialogActions>
    </Dialog>);
};
export default BranchesSelectionDialog;
/* eslint-disable react-hooks/exhaustive-deps */
import './DictionaryElement.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import { Link } from 'react-router-dom';
import {CellValueChangedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Checkbox} from '@mui/material';
import {paths} from 'paths';
import {API_DICTIONARY_ELEMENT_BY_ID, API_MASTER_DATA_DICTIONARY_ELEMENTS, API_STATUSES} from 'config/api/constants';
import {dictionaryElement, dictionaryElementPayload, dictionaryTranslationRow} from 'shared/models/dictionaries.model';
import {getId, getURLParam} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import AgTable from 'components/AgTable/AgTable';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {languages} from './Dictionary';
import DictionaryName from './DictionaryName';

const dictionaryTemplate: dictionaryElement = {
    active: true,
    alternatives: {en: ''},
    translations: {en: ''},
    id: '',
    dictionaryId: ''
};

const DictionaryElement = ({history}) => {
    const translate = useFormatMessage();
    const elementAPIGet = useApi('get', dictionaryTemplate, {errMsg: 'dictionaryElement.err'});
    const elementAPIPost = useApi('post', dictionaryTemplate, {errMsg: 'dictionary.saveErr', succMsg: 'dictionary.succ'});
    const elementAPIPut = useApi('put', dictionaryTemplate, {errMsg: 'dictionary.saveErr', succMsg: 'dictionary.succ'});

    const [dictionaryId, setDictionaryId] = useState<string>('');
    const [elementId, setElementId] = useState<string>('');
    const [elementActive, setElementActive] = useState<boolean>(true);
    const [lock4edit, setLock4edit] = useState<boolean>(false);
    const [dictionaryData, setDictionaryData] = useState<dictionaryTranslationRow[]>([]);

    const dictElemColumnDefs: ColDef[] = [
        {field: 'lang', headerName: translate({id: 'dictionaryElement.lang'}), cellRenderer: (params) => translate({id: params.value})},
        {field: 'trans', headerName: translate({id: 'dictionaryElement.trans'}), flex: 1, editable: true,
            tooltipValueGetter: () => translate({id: 'dictionaryElement.info'}),
            cellClass: (params) => params.data.lang === 'en' && !params.value ? 'required-cell' : ''
        }
    ];

    const alternativeValuesColumn: ColDef[] = [{field: 'alt', headerName: translate({id: 'dictionaryElement.alt'}), flex: 1, editable: true, tooltipValueGetter: () => translate({id: 'dictionaryElement.info'})}];

    const dictionary2array = (element: dictionaryElement): dictionaryTranslationRow[] => {
        if (element.alternatives) {
            return ['en'].concat(languages).map((lang) => ({lang, trans: element.translations[lang] || '', alt: element.alternatives[lang] || ''}));
        }
        return ['en'].concat(languages).map((lang) => ({lang, trans: element.translations[lang] || ''}));
    };

    useEffect(() => {
        const elementId: string = getId();
        if (elementId) {
            elementAPIGet.call(API_DICTIONARY_ELEMENT_BY_ID(elementId))
        } else {
            const dictId: string = getURLParam('dictionaryId');
            if (dictId) {
                setDictionaryId(dictId);
                setDictionaryData(dictionary2array(dictionaryTemplate));
            }
        }
    }, []);

    useEffect(() => {
        if (elementAPIGet.status === API_STATUSES.IDLE) {
            const dictionaryItem: dictionaryElement = elementAPIGet.data;
            const {active, id, dictionaryId} = dictionaryItem;
            setElementId(id);
            setElementActive(active);
            setDictionaryId(dictionaryId);
            setDictionaryData(dictionary2array(dictionaryItem));
        }
    }, [elementAPIGet.status]);

    useEffect(() => {
        if (elementAPIPost.status === API_STATUSES.IDLE || elementAPIPut.status === API_STATUSES.IDLE) {
            history.push(`${paths.dictionary}?id=${dictionaryId}`);
        }
    }, [elementAPIPost.status, elementAPIPut.status]);

    const buildColumnDefs = (): ColDef[] => dictElemColumnDefs.concat(alternativeValuesColumn);

    // @TODO restore below code later on, when we have information whether given dictionary should have alternatives or not
    // const buildColumnDefs = () => {
    //     if (elementAPIGet.data.alternatives) {
    //         return dictElemColumnDefs.concat(alternativeValuesColumn as any)
    //     }
    //     return dictElemColumnDefs;
    // };

    const handleCellChange = (event: CellValueChangedEvent) => {
        const newData: dictionaryTranslationRow[] = [...dictionaryData];
        newData[event.rowIndex] = event.data;
        setDictionaryData(newData);
    };

    const handleSaveClick = () => {
        const saveObject: dictionaryElementPayload = {
            active: elementActive,
            alternatives: {en: ''},
            dictionaryId,
            translations: {en: ''}
        };
        dictionaryData.forEach((item) => {
            saveObject.translations[item.lang] = item.trans;
            saveObject.alternatives[item.lang] = item.alt;
        });
        if (elementId) {
            saveObject.id = elementId;
            elementAPIPut.call(`${API_MASTER_DATA_DICTIONARY_ELEMENTS}/${elementId}`, saveObject);
        } else {
            elementAPIPost.call(API_MASTER_DATA_DICTIONARY_ELEMENTS, saveObject);
        }
    };

    const canSave = (): boolean => dictionaryData.find((item) => item.lang === 'en' && item.trans === '') === undefined &&
        dictionaryData.find((item) => item.lang !== 'en' && item.trans !== '') !== undefined;

    const isLoading= (): boolean => elementAPIGet.status === API_STATUSES.PENDING || elementAPIPost.status === API_STATUSES.PENDING || elementAPIPut.status === API_STATUSES.PENDING;

    return (<div className="viewRoot dictionaryElementRoot">
        <div className="viewport">
            <LoadingOverlay show={isLoading()}/>
            <div className="viewContainer _directionCol">
                <PaperX>
                    <div className="dictionaryElementHeader">
                        <div>
                            <div><FormattedMessage id="dictionary.dict"/>:</div>
                            <div><FormattedMessage id="a.active"/>:</div>
                        </div>
                        <div>
                            <div>
                                <DictionaryName dictionaryId={dictionaryId}/>
                            </div>
                            <div className="checkboxContainer">
                                <Checkbox checked={elementActive} color="primary" onChange={(e) => setElementActive(e.target.checked)}/>
                            </div>
                        </div>
                    </div>
                </PaperX>
                <PaperX className="_fullHeight _fullTable">
                    <AgTable
                        rowData={dictionaryData}
                        defaultColDef={{suppressMenu: true}}
                        columnDefs={buildColumnDefs()}
                        onCellValueChanged={handleCellChange}
                        onCellEditingStarted={() => setLock4edit(true)}
                        onCellEditingStopped={() => setLock4edit(false)}
                        tooltipShowDelay={1000}
                    />
                </PaperX>
            </div>
        </div>
        <Footer
            actionsRight={
                <>
                    {!canSave() ? <span className="validationInfo"><FormattedMessage id="dictionaryElement.info2"/></span> : null}
                    <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={lock4edit || !canSave()}><FormattedMessage id="a.save"/></Button>
                    <Link to={`${paths.dictionary}?id=${dictionaryId}`}>
                        <ButtonClose/>
                    </Link>
                </>
            }
        />
    </div>);
};

export default DictionaryElement;
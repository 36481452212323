import Highlighter from 'react-highlight-words';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {CellClickedEvent} from 'ag-grid-community';
import {Button} from '@mui/material';
import {categoryTableItem} from 'shared/models/category.model';
import {useFormatMessage} from 'utils/translate';
import AgTable from 'components/AgTable/AgTable';

interface CategoryTurboSearchTableProps {
    data: categoryTableItem[],
    onSelect: (categoryRow: categoryTableItem) => void,
    onShowDetails: (categoryRow: categoryTableItem) => void,
    remoteSearch?: boolean, // if true, remote search is performed so we need to change highlight logic
    searchPhrase: string
}

const CategoryTurboSearchTable = ({data, remoteSearch, onSelect, onShowDetails, searchPhrase}: CategoryTurboSearchTableProps) => {
    const translate = useFormatMessage();
    const columnDefs: ColDef[] = [
        {field: 'sName', headerName: translate({id: 'b.categorySegment'}), flex: 1,
            onCellClicked: (params: CellClickedEvent) => onSelect(params.data),
            cellRenderer: (params) => <Highlighter textToHighlight={params.data.sName || ''} searchWords={remoteSearch ? searchPhrase?.split(' ') : [searchPhrase]} autoEscape={true} highlightClassName={"highlightColor"}/>},
        {field: 'fName', headerName: translate({id: 'b.categoryFamily'}), flex: 1,
            onCellClicked: (params: CellClickedEvent) => onSelect(params.data),
            cellRenderer: (params) => <Highlighter textToHighlight={params.data.fName || ''} searchWords={remoteSearch ? searchPhrase?.split(' ') : [searchPhrase]} autoEscape={true} highlightClassName={"highlightColor"}/>},
        {field: 'cName', headerName: translate({id: 'b.categoryClass'}), flex: 1,
            onCellClicked: (params: CellClickedEvent) => onSelect(params.data),
            cellRenderer: (params) => <Highlighter textToHighlight={params.data.cName || ''} searchWords={remoteSearch ? searchPhrase?.split(' ') : [searchPhrase]} autoEscape={true} highlightClassName={"highlightColor"}/>},
        {field: 'bName', headerName: translate({id: 'b.categoryBrick'}), flex: 1,
            onCellClicked: (params: CellClickedEvent) => onSelect(params.data),
            cellRenderer: (params) => <Highlighter textToHighlight={params.data.bName || ''} searchWords={remoteSearch ? searchPhrase?.split(' ') : [searchPhrase]} autoEscape={true} highlightClassName={"highlightColor"}/>},
        {cellRenderer: (params) =>
                params.data.bName ? <Button size="small" variant="outlined" color="primary" onClick={() => onShowDetails(params.data)}>{translate({id: 'a.details'})}</Button> : null}
    ];

    return (
            <AgTable rowData={data}
                     defaultColDef={{
                         suppressMenu: true,
                         resizable: true,
                         sortable: true
                     }}
                     columnDefs={columnDefs}
            />
    );
};

export default CategoryTurboSearchTable;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import {CircularProgress, InputAdornment, TextField} from '@mui/material';
import {API_STATUSES} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import useDebounce from 'utils/debounce';
import {storeInLocalStorage} from 'utils/storageUtils';
import {responseValidation} from 'utils/responseValidation';

interface productSearchProps<T> {
    labelId?: string,
    marketId?: string,
    onNewData: (productsTableData: Array<T>) => void,
    queryStrStorageKey?: string,
    restoredQueryStr?: string,
    searchUrl: string,
    templatesExcluded?: boolean,
}

type productSearchFilters = {
    lang?: string,
    marketId: string,
    search?: string,
    templatesExcluded?: boolean,
}

const minSearchLen: number = 1; //@TODO already exists in original ProductSearch, consider export or refactoring

const sanitizeFrameFilters = (filters: productSearchFilters): productSearchFilters => {
    if (!(filters.search?.length > minSearchLen) || !filters.marketId) return null;
    const saneFilters: productSearchFilters = {
        marketId: filters.marketId
    };
    if (filters.lang) saneFilters.lang = filters.lang;
    if (filters.marketId) saneFilters.marketId = filters.marketId;
    if (filters.search && filters.search.length > minSearchLen) saneFilters.search = filters.search;
    return Object.keys(saneFilters).length === 0 ? null : saneFilters;
};

const ProductSearch = <T extends unknown>(props:productSearchProps<T>) => {
    const translate = useFormatMessage();
    const {labelId, marketId, onNewData, queryStrStorageKey, restoredQueryStr, searchUrl, templatesExcluded} = props;

    const [queryStr, setQueryStr] = useState<string>('');
    const [filters, setFilters] = useState<productSearchFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then

    const [status, setStatus] = useState(API_STATUSES.NEW);

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const cancelToken = useRef(null);

    useEffect(() => {
        if (restoredQueryStr) {
            setQueryStr(restoredQueryStr);
            if (marketId) {
                setFilters({...filters, marketId,  search: restoredQueryStr});
            } else {
                setFilters({...filters, search: restoredQueryStr});
            }
        }
        return () => {
            if (cancelToken.current) {
                cancelToken.current();
            }
        }
    }, []);

    useEffect(() => {
        setFilters({...filters, search: queryStr});
    }, [queryStr]);

    useEffect(() => {
        setFilters(
            {
                ...filters,
                lang: userProfile.langData,
                marketId: marketId || userProfile.countryMarket.market
            });
    }, [userProfile]);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);
    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters: productSearchFilters = sanitizeFrameFilters(dbncdFilters);
            const stringifiedSaneFilters: string = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                if (saneFilters)
                    getData(cancelToken.current, saneFilters);
            }
        }
    }, [dbncdFilters]);

    useEffect(() => {
        if (templatesExcluded !== undefined && dbncdFilters) {
            const saneFilters: productSearchFilters = sanitizeFrameFilters(dbncdFilters);
            if (saneFilters) getData(cancelToken.current, saneFilters);
        }
    }, [templatesExcluded]);

    const getData = (origToken, filters: productSearchFilters) => {
        if (origToken) {
            origToken();
        }
        onNewData([]);
        setStatus(API_STATUSES.PENDING);
        const extendedFilters: productSearchFilters = templatesExcluded ? {...filters, templatesExcluded: templatesExcluded} : filters;
        axios.get(searchUrl, {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            ),
            params: extendedFilters
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    onNewData(resp.data);
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e)
                }
            })
            .finally(() => setStatus(API_STATUSES.IDLE));
    };

    const handleSearchInputChange = (val: string) => {
        setQueryStr(val);
        if (queryStrStorageKey) {
            storeInLocalStorage(queryStrStorageKey, val);
        }
    };

    return (<TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                       label={translate({id: labelId || 'a.search'})}
                       value={queryStr}
                       InputProps={{
                           startAdornment: <InputAdornment position="start">
                               {status === API_STATUSES.PENDING ? <CircularProgress size={24}/>: <SearchIcon color="secondary"/> }
                           </InputAdornment>
                       }}
                       fullWidth
    />);
};

export default ProductSearch;
import './FramePreview.scss';
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {CircularProgress} from '@mui/material';
import {API_IMAGE_MANAGEMENT_FRAMES, API_STATUSES} from 'config/api/constants';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import FrameDialogZoom from './FrameDialogZoom';
import FrameLensZoom from './FrameLensZoom';

export enum zoomTypes {
    dialog = 'dialogZoom',
    lens = 'lensZoom'
}

type framePreviewProps = {
    customClassName?: string,
    frameId: string,
    zoomType?: zoomTypes,
    noBackground?: boolean,
}

const frameProgressSpinner = <CircularProgress size={48}/>;

const FramePreview = (props: framePreviewProps) => {
    const {customClassName, frameId, zoomType, noBackground} = props;
    const frameImageApiGet = useApi('get', null, {errMsg: 'frameDescription.errImg'});
    const [frameImage, setFrameImage] = useState<string>(null);
    const [isDialogZoomOpen, setIsDialogZoomOpen] = useState<boolean>(false);

    const getFrameImage = (frameId: string) => frameImageApiGet.call(`${API_IMAGE_MANAGEMENT_FRAMES}/${frameId}`, { responseType: 'blob' });

    const getClassName = (): string => {
        let className: string = noBackground ? 'framePreviewRoot' : 'framePreviewRoot withBackground';
        if (zoomType === zoomTypes.dialog) className += ' withZoom';
        return className;
    }

    useEffect(() => {
        if (frameId) {
            getFrameImage(frameId);
        } else {
            setFrameImage(null);
        }
    }, [frameId]);

    useEffect(() => {
        if (frameImageApiGet.status === API_STATUSES.IDLE) {
            if (frameImageApiGet.data) {
                const imageObjectURL = URL.createObjectURL(frameImageApiGet.data);
                setFrameImage(imageObjectURL);
            } else {
                console.error('error fetching frame data');
            }
        }
    }, [frameImageApiGet.status]);

    const handleZoom = () => {
        if (frameImage && zoomType === zoomTypes.dialog) setIsDialogZoomOpen(true);
    }

    const render = () => {
        switch (frameImageApiGet.status) {
            case API_STATUSES.PENDING:
                return frameProgressSpinner;
            case API_STATUSES.IDLE:
                return (
                    <>
                        {zoomType !== zoomTypes.lens && <img onClick={handleZoom} className={customClassName ? `image ${customClassName}` : 'image'} src={frameImage} alt=""/>}
                        {zoomType === zoomTypes.dialog && <FrameDialogZoom imgUrl={frameImage} onClose={() => setIsDialogZoomOpen(false)} show={isDialogZoomOpen} />}
                        {zoomType === zoomTypes.lens && <FrameLensZoom imgUrl={frameImage} customClassName={customClassName}/>}
                    </>
                );
            default:
                return null;
        }
    };

    return (
      <div className={getClassName()}>
          {render()}
      </div>
    );
};

export default FramePreview;
import {categoryTableItemWithSearchString} from 'shared/models/category.model';
enum ACTION_TYPES {
    SET_CATEGORY_HIERARCHY_STATE = 'SET_CATEGORY_HIERARCHY_STATE'
}

export const setCategoryHierarchyState = (newState: CategoryHierarchyState) => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.SET_CATEGORY_HIERARCHY_STATE,
        payload: newState
    })
};

export type CategoryHierarchyState = {
    lang: string,
    data: categoryTableItemWithSearchString[]
}

const initialState: CategoryHierarchyState = {
    lang: '',
    data: []
};

const categoryHierarchyReducer = (state: CategoryHierarchyState = initialState, action): CategoryHierarchyState => {
    switch (action.type) {
        case (ACTION_TYPES.SET_CATEGORY_HIERARCHY_STATE): {
            return {...state, lang: action.payload.lang, data: action.payload.data}
        }
        default:
            return state;
    }
}

export default categoryHierarchyReducer;
import {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {Button} from '@mui/material';
import {
    API_PRODUCT_BULK_UPDATE_PRE_VALIDATION,
    API_PRODUCT_DATA_PRODUCTS_BULK_UPDATE,
    API_PRODUCT_WITH_SUPPLIER_SEARCH
} from 'config/api/constants';
import {paths} from 'paths';
import {
    bulkProductUpdatePayload,
    bulkProductUpdateDuplicateCheckResponse,
    productWithSupplierSearchResultResponse,
} from 'shared/models/product.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {characteristicPayloadProper} from 'shared/models/attribute.model';
import {
    productSearchTableRow,
    transformResponse
} from 'modules/MasterData/Product/ProductsListSearchResults';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import CharacteristicsDrawer from './CharacteristicsDrawer';
import ProductBulkChangeFilters, {productBulkChangeFilters} from './ProductBulkChangeFilters';
import ProductBulkChangeTable from './ProductBulkChangeTable';
import DuplicateFoundDialog from './DuplicateFoundDialog';
import {useSnackbar} from 'notistack';

export enum DIALOGS {
    NONE = 'NONE',
    CONFIRM_UPDATE = 'CONFIRM_UPDATE',
    CONFIRM_DUPLICATES = 'CONFIRM_DUPLICATES',
    SELECT_DESCRIPTION = 'SELECT_DESCRIPTION'
}

const ProductBulkChange = () => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<productBulkChangeFilters>(null);
    const [brickId, setBrickId] = useState<string>('');
    const [productsTableData, setProductsTableData] = useState<productSearchTableRow[]>([]);
    const [characteristicsDrawerOpen, setCharacteristicsDrawerOpen] = useState<boolean>(false);
    const [resetCounter, setResetCounter] = useState<number>(0);
    const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
    const [newCharacteristics, setNewCharacteristics] = useState<characteristicPayloadProper[]>([]);
    
    const [duplicates, setDuplicates] = useState<productSearchTableRow[]>([]);

    const [showDialog, setShowDialog] = useState<DIALOGS>(DIALOGS.NONE);

    const cancelToken = useRef(null);

    const getData = (filters: productBulkChangeFilters) => {
        if (cancelToken?.current) {
            cancelToken.current();
        }
        setIsLoading(true);
        setSelectedProductIds([]);
        setNewCharacteristics([])
        setProductsTableData([]);
        setDuplicates([]);
        axios.get<productWithSupplierSearchResultResponse>(API_PRODUCT_WITH_SUPPLIER_SEARCH, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )
        })
            .then((resp) => {
                setIsLoading(false);
                if (responseValidation(resp.data)) {
                    setProductsTableData(transformResponse(resp.data));
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    setIsLoading(false);
                }
            });
    };

    const check4duplicates = () => {
        setIsLoading(true);
        const payload: bulkProductUpdatePayload = {
            categoryBrickId: brickId,
            productIds: selectedProductIds,
            characteristics: newCharacteristics
        };
        axios.post<bulkProductUpdateDuplicateCheckResponse>(API_PRODUCT_BULK_UPDATE_PRE_VALIDATION, payload)
            .then((response) => {
                if (response.data?.duplicates?.length) {
                    setIsLoading(false);
                    const potentialDuplicates: productSearchTableRow[] = [];
                    response.data.duplicates.forEach((id) => {
                        potentialDuplicates.push(productsTableData.find((item) => item.id === id));
                    });
                    setDuplicates(potentialDuplicates);
                    setShowDialog(DIALOGS.CONFIRM_DUPLICATES);
                } else {
                    doBulkUpdate(payload);
                }
            })
            .catch((e) => {
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                setIsLoading(false);
                setShowDialog(DIALOGS.NONE);
                console.log(e);
            });
    };

    const doBulkUpdate = (payload: bulkProductUpdatePayload) => {
        axios.patch(API_PRODUCT_DATA_PRODUCTS_BULK_UPDATE, payload)
            .then(() => {
                enqueueSnackbar(
                    `${translate({id: 'product.bulkSucc'})}. ${translate({id: 'product.bulkSucc2'})}`,
                    {
                        variant: 'success',
                        persist: false
                    });
                setCharacteristicsDrawerOpen(false);
                setResetCounter(resetCounter + 1);
                setProductsTableData([]);
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => {
                setShowDialog(DIALOGS.NONE);
                setIsLoading(false);
            });
    };

    const handleFiltersChange = (f: productBulkChangeFilters) => {
        setBrickId(f.categoryBrickId);
        if (f.search && f.categoryBrickId) {
            setFilters(f);
            getData(f)
        }
    };

    const handleCharacteristicsUpdateConfirmations = (characteristics: characteristicPayloadProper[]) => {
        setNewCharacteristics(characteristics)
        setShowDialog(DIALOGS.CONFIRM_UPDATE);
    };
    
    const handleConfirmUpdateClick = () => check4duplicates();

    const handleConfirmDuplicatesClick = () => {
        setIsLoading(true);
        setShowDialog(DIALOGS.NONE);
        doBulkUpdate({
            categoryBrickId: brickId,
            productIds: selectedProductIds,
            characteristics: newCharacteristics
        });
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <ProductBulkChangeFilters brickRequired
                                                  onFilterChange={(f) => handleFiltersChange(f)}
                                                  onReloadClick={() => getData(filters)}/>
                        {productsTableData?.length > 99 && <span className="_bold"><FormattedMessage id="a.searchLimit"/></span>}
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _fullTable _customTooltipPositionFix">
                        <ProductBulkChangeTable data={productsTableData}
                                                onSelectionChange={(products) => setSelectedProductIds(products.map((item) => item.id ))}/>
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained"
                                onClick={() => setCharacteristicsDrawerOpen(true)}
                                disabled={selectedProductIds.length < 2 || !brickId}>
                            <FormattedMessage id="product.bulkUpdate" values={{count: selectedProductIds.length}}/>
                        </Button>
                        <Link to={paths.productsList}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
            <CharacteristicsDrawer brickId={brickId}
                                   onClose={() => setCharacteristicsDrawerOpen(false)}
                                   onConfirm={(characteristics) => handleCharacteristicsUpdateConfirmations(characteristics)}
                                   open={characteristicsDrawerOpen}
                                   productIds={selectedProductIds}
                                   resetCounter={resetCounter}
            />
            <ConfirmDialog open={showDialog === DIALOGS.CONFIRM_UPDATE}
                           onConfirm={handleConfirmUpdateClick}
                           onCancel={() => setShowDialog(DIALOGS.NONE)}
                           message={<FormattedMessage id="product.confirmUpdate" values={{count: selectedProductIds.length}}/>}
                           confirmLabelId="a.yes"
                           cancelLabelId="a.no"
            />
            <DuplicateFoundDialog data={duplicates}
                                  onConfirmDuplicates={handleConfirmDuplicatesClick}
                                  open={showDialog === DIALOGS.CONFIRM_DUPLICATES}
                                  onClose={() => setShowDialog(DIALOGS.NONE)}
            />
        </div>
    );
};

export default ProductBulkChange;
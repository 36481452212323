export const dateFormat: string = 'yyyy-MM-dd';

export type validity = {
    validFrom: string,
    validTo: string
}

export type validityDateType = {
    validFrom: Date,
    validTo: Date
}
import {Link} from 'react-router-dom';
import {paths} from 'paths';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import MyAdvertisements from './MyAdvertisements';

const MyAdvertisementsView = () => {
    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <MyAdvertisements/>
                </div>
            </div>
            <Footer
                actionsRight={
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>
        
    );
};

export default MyAdvertisementsView;
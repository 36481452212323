import {differentFrameModel, frame} from 'shared/models/frame.model';
import {fabric} from 'fabric';
import { getComplementaryColor, getComplementaryColorAlpha } from './ColorPicker/ColorPicker';

export type CanvasFrame = Partial<frame> & {
    set: (key: string, value: string) => void;
};

export const MIN_FRAME_WIDTH: number = 50;
export const MIN_FRAME_HEIGHT: number = 50;

export const disableManipulation = {
    lockMovementX: true,
    lockMovementY: true,
    lockRotation: true,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    hoverCursor: 'default',
};

export const getReportedFrameColor = (fill: string, stroke: string) => ({
    fill: getComplementaryColorAlpha(fill, "80"),
    stroke: getComplementaryColor(stroke),
});

export const buildRectObject = (frame: frame | differentFrameModel, fill: string, stroke: string, interactive: boolean, hideControls?:boolean) => {
    const color = frame.reported ? getReportedFrameColor(fill, stroke) : { fill, stroke };
    let rectCfg: any = {
        left: frame.x1,
        top: frame.y1,
        fill: color.fill,
        stroke: color.stroke,
        strokeWidth: 1,
        width: frame.x2 - frame.x1,
        height: frame.y2 - frame.y1,
        frameId: frame.frameId || null,
        selectable: interactive,
        evented: interactive
    }
    if (hideControls === true) {
        rectCfg = {...rectCfg, ...disableManipulation};
    }
    return new fabric.Rect(rectCfg);
};

export const colorFrame = (frame: CanvasFrame, fill: string, stroke: string) => {
    const color = frame.reported ? getReportedFrameColor(fill, stroke) : { fill, stroke };
    frame.set("fill", color.fill);
    frame.set("stroke", color.stroke);
};

export const buildImageObject = (imgElement, width: number, height: number) => new fabric.Image(imgElement, {
        left: 0,
        top: 0,
        angle: 0,
        width,
        height
    });

export const getControls = () => {
    const CTRLS = fabric.Rect.prototype.controls;
    return {
        ...fabric.Rect.prototype.controls,
        bl: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.bl.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.bl.cursorStyleHandler(eventData, corner, fabricObject),
            x: -0.5,
            y: 0.5
        }),
        br: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.br.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.br.cursorStyleHandler(eventData, corner, fabricObject),
            x: 0.5,
            y: 0.5
        }),
        mb: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.mb.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.mb.cursorStyleHandler(eventData, corner, fabricObject),
            x: 0,
            y: 0.5
        }),
        ml: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.ml.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.ml.cursorStyleHandler(eventData, corner, fabricObject),
            x: -0.5,
            y: 0
        }),
        mr: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.mr.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.mr.cursorStyleHandler(eventData, corner, fabricObject),
            x: 0.5,
            y: 0
        }),
        mt: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.mt.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.mt.cursorStyleHandler(eventData, corner, fabricObject),
            x: 0,
            y: -0.5
        }),
        tl: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.tl.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.tl.cursorStyleHandler(eventData, corner, fabricObject),
            x:-0.5,
            y:-0.5
        }),
        tr: new fabric.Control({
            actionHandler: (eventData, transform, x, y) => eventData.ctrlKey ? CTRLS.tr.actionHandler(eventData, transform, x, y) : () => null,
            cursorStyleHandler: (eventData, corner, fabricObject) => CTRLS.tr.cursorStyleHandler(eventData, corner, fabricObject),
            x:0.5,
            y:-0.5
        }),
        mtr: new fabric.Control({ visible: false })
    };
};

export const frameTooSmall = (shape): boolean => shape.width < MIN_FRAME_WIDTH || shape.height < MIN_FRAME_HEIGHT;
import {v4 as uuidv4} from 'uuid';
import {mediumPromotionsPayload, promotionMediumType} from 'shared/models/media.model';
import {
    PROMOTION_TYPES,
    promotionSave,
    fullFramePromotions,
    promotionUI,
    framePromotionsPayload
} from 'shared/models/promotion.model';
import {productSearchResultUI} from 'shared/models/product.model';
import {handyAttributes} from 'shared/handyAttributes';
import {promotionsForFrameResponseModel, apiGetPromotionsForFrameResponsePromotionType} from 'shared/models/frame.model';

export const transformPromotions4UI = (promotions: apiGetPromotionsForFrameResponsePromotionType[]) => {
    return promotions.map((item) => {
        const selectedItemProps = item.promotionObjectType === PROMOTION_TYPES.PRODUCT ? item.product : item.bpco;

        const {
            brand,
            category,
            characteristics
        } = selectedItemProps;

        const singlePromotion:promotionUI = {
            UIId: item.id, // this is already saved promotion, we can use its id in UI model
            absoluteDiscount: item.absoluteDiscount || null,
            brand,
            category,
            characteristics,
            countryThemedWeeks: {id: handyAttributes.countryThemedWeeks, literals: []},
            currency: item.currency,
            description: '',
            giveAwayBundledProducts: item.giveAwayBundledProducts,
            hasError: false,
            id: item.id,
            mpu: item.mpu,
            promotionObjectType: item.promotionObjectType,
            promotionTypes: {id: handyAttributes.promotionType, literals: []},
            promotionalPrice: item.promotionalPrice || null,
            qualitySeals: {id: handyAttributes.qualitySeal, literals: []},
            regularPrice: item.regularPrice || null,
            relativeDiscount: item.relativeDiscount || null,
            themeSeasons: {id: handyAttributes.themeSeason, literals: []},
            validityDates: item.validityDates,
        }

        if (item.promotionTypes?.length) {
            item.promotionTypes.forEach((promoTypeId) => singlePromotion.promotionTypes.literals.push({literalId: promoTypeId}));
        }
        if (item.qualitySeals?.length) {
            item.qualitySeals.forEach((qualitySealId) => singlePromotion.qualitySeals.literals.push({literalId: qualitySealId}));
        }
        if (item.themeSeasons?.length) {
            item.themeSeasons.forEach((themeSeasonId) => singlePromotion.themeSeasons.literals.push({literalId: themeSeasonId}));
        }
        if (item.countryThemedWeeks?.length) {
            item.countryThemedWeeks.forEach((countryThemedWeeksId) => singlePromotion.countryThemedWeeks.literals.push({literalId: countryThemedWeeksId}));
        }

        if (item.promotionObjectType === PROMOTION_TYPES.PRODUCT) {
            singlePromotion.productId = selectedItemProps.id;
            singlePromotion.isTemplate = item.product.isTemplate;
            singlePromotion.description = item.product.description;
        } else {
            singlePromotion.bpcoId = selectedItemProps.id;
            singlePromotion.description = item.bpco.description;
        }

        return singlePromotion;
    })
};

const promotionBE2FE = (promotionBE: promotionsForFrameResponseModel): fullFramePromotions => {
    const {
        frameId,
        promotions,
        validFrom,
        validTo
    } = promotionBE;

    return {
        frameId,
        validFrom,
        validTo,
        promotions: transformPromotions4UI(promotions)
    };
}

// @TODO this is variant for framedescription where productId or bpcoId is obtained from product/bpco 'id' property
// in edit advertisement  productID or bpcoId should be obrained from corresponding fields (productId/bpcoId) as 'id' property now identifies promotion
export const createPromotionsPayloadOLD = (promotions:promotionUI[]): promotionSave[] => promotions.map((item) => {
    const {
        absoluteDiscount,
        mpu,
        promotionObjectType,
        promotionTypes,
        promotionalPrice,
        qualitySeals,
        regularPrice,
        relativeDiscount,
        themeSeasons,
        countryThemedWeeks,
        giveAwayBundledProducts,
        validityDates
    } = item;
    const newItem: promotionSave = {
        absoluteDiscount,
        giveAwayBundledProductIds: giveAwayBundledProducts?.map(item => item.id),
        mpu,
        promotionObjectType,
        promotionTypes: promotionTypes.literals.map((item) => item.literalId),
        promotionalPrice,
        qualitySeals: qualitySeals.literals.map((item) => item.literalId),
        regularPrice,
        relativeDiscount,
        themeSeasons: themeSeasons.literals.map((item) => item.literalId),
        countryThemedWeeks: countryThemedWeeks.literals.map((item) => item.literalId),
        validityDates
    };
    if (promotionObjectType === PROMOTION_TYPES.BPCO) {
        newItem.bpcoId = item.bpcoId;
    } else {
        newItem.productId = item.productId;
    }
    return newItem;
});

export const createPromotionsPayload = (promotions:promotionUI[], frameId: string, validFrom: string, validTo: string): framePromotionsPayload => {
   const newPromotions: promotionSave[] = promotions.map((item) => {
        const {
            absoluteDiscount,
            id,
            mpu,
            promotionObjectType,
            promotionTypes,
            promotionalPrice,
            qualitySeals,
            regularPrice,
            relativeDiscount,
            themeSeasons,
            countryThemedWeeks,
            giveAwayBundledProducts,
            validityDates
        } = item;
        const newItem: promotionSave = {
            absoluteDiscount,
            giveAwayBundledProductIds: giveAwayBundledProducts?.map(item => item.id),
            mpu,
            promotionObjectType,
            promotionTypes: promotionTypes.literals.map((item) => item.literalId),
            promotionalPrice,
            qualitySeals: qualitySeals.literals.map((item) => item.literalId),
            regularPrice,
            relativeDiscount,
            themeSeasons: themeSeasons.literals.map((item) => item.literalId),
            countryThemedWeeks: countryThemedWeeks.literals.map((item) => item.literalId),
            validityDates
        };
        if (promotionObjectType === PROMOTION_TYPES.BPCO) {
            newItem.bpcoId = item.bpcoId;
        } else {
            newItem.productId = item.productId;
        }
        if (id) { // id is optional, only exist when updating promotion
            newItem.id = id;
        }
        return newItem;
    });

    return {
        frameId,
        validFrom,
        validTo,
        promotions: newPromotions
    };
}

export const createFrameDescriptionPayload = (frameId: string, validFrom: string, validTo: string, promotions: promotionUI[]): framePromotionsPayload => {
    return {
        frameId,
        validFrom,
        validTo,
        promotions: createPromotionsPayloadOLD(promotions)
    };
}

export const createMediumDescriptionPayload = (mediumId: string, promotionMediumType: promotionMediumType, promotions: promotionUI[]): mediumPromotionsPayload => {
    return {
        mediumId,
        promotionMediumType,
        promotions: createPromotionsPayloadOLD(promotions)
    }
}

export const transformPromotionsResponse = (data: promotionsForFrameResponseModel): fullFramePromotions => promotionBE2FE(data);

export const makePromotionOfProductSearch = (product: productSearchResultUI,
                                             derivedThemeSeasons: string[] = null,
                                             derivedThemedWeeks: string[] = null,
                                             derivedPromotionTypes: string[] = null,
                                             currency: string): promotionUI => {
    const uniqueKey = uuidv4();
    const {
        brand,
        category,
        isTemplate,
        promotionObjectType,
        characteristics,
        description
    } = product;
    const promotion:promotionUI =  {
        brand,
        category,
        description,
        // id - actually this is only property of product item that we omit, in current implementation id is property of promotion
        promotionObjectType,
        characteristics,
        isTemplate,
        UIId: uniqueKey,
        regularPrice: null,
        promotionalPrice: null,
        absoluteDiscount: null,
        relativeDiscount: null,
        mpu: 1,
        currency,
        derivedThemeSeasons: {
            id: handyAttributes.themeSeason,
            // literals: leafletData.headerData.themeSeasons ? leafletData.headerData.themeSeasons.map((item) => ({literalId: item})) : []
            literals: derivedThemeSeasons ? derivedThemeSeasons.map((item) => ({literalId: item})) : []
        },
        derivedThemedWeeks: {
            id: handyAttributes.countryThemedWeeks,
            literals: derivedThemedWeeks ? derivedThemedWeeks.map((item) => ({literalId: item})) : []
        },
        derivedPromotionTypes: {
            id: handyAttributes.promotionType,
            literals: derivedPromotionTypes ? derivedPromotionTypes.map((item) => ({literalId: item})) : []
        },
        giveAwayBundledProductIds: null,
        giveAwayBundledProducts: [],
        themeSeasons: {id: handyAttributes.themeSeason, literals: []},
        qualitySeals: {id: handyAttributes.qualitySeal, literals: []},
        promotionTypes: {id: handyAttributes.promotionType, literals: []},
        countryThemedWeeks: {id: handyAttributes.countryThemedWeeks, literals: []},
        hasError: false,
        validityDates: []
    };
    if (product.promotionObjectType === PROMOTION_TYPES.PRODUCT) {
        promotion.productId = product.id;
        promotion.description = product.description;
    }
    if (product.promotionObjectType === PROMOTION_TYPES.BPCO) {
        promotion.bpcoId = product.id;
    }
    return promotion;
};
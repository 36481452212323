/* eslint-disable react-hooks/exhaustive-deps */
import './PromotionValidityDialog.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {format, isBefore} from 'date-fns';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {validity, validityDateType} from 'shared/models/validity.model';
import {dateFormat} from 'shared/models/validity.model';
import {useFormatMessage} from 'utils/translate';
import {ValiditySelector} from 'components/Selectors';

interface promotionValidityDialogProps {
    dates: validity[],
    open: boolean,
    onConfirm: (dates: validity[]) => void,
    onClose: () => void
}

const PromotionValidityDialog = (props: promotionValidityDialogProps) => {
    const {dates, open, onClose, onConfirm} = props;
    const translate = useFormatMessage();

    const [localDates, setLocalDates] = useState<validityDateType[]>([]);

    useEffect(() => {
        if (open) {
            if (dates?.length) {
                setLocalDates(dates.map((item) => ({validFrom: new Date(item.validFrom), validTo: new Date(item.validTo)})));
            } else {
                setLocalDates([{validFrom: null, validTo: null}]);
            }
        }
    }, [open]);

    const setValidityFrom = (key: number, value: Date) => {
        const newLocalDates: validityDateType[] = [...localDates];
        newLocalDates[key].validFrom = value;
        setLocalDates(newLocalDates);
    };

    const setValidityTo = (key: number, value: Date) => {
        const newLocalDates: validityDateType[] = [...localDates];
        newLocalDates[key].validTo = value;
        setLocalDates(newLocalDates);
    };

    const removeValidity = (key: number) => {
        const newLocalDates: validityDateType[] = [...localDates];
        newLocalDates.splice(key, 1);
        setLocalDates(newLocalDates);
    };

    const addDate = () => {
        const newLocalDates: validityDateType[] = [...localDates];
        newLocalDates.push({validFrom: null, validTo: null});
        setLocalDates(newLocalDates);
    };

    const handleConfirm = () => onConfirm(localDates.map((item) => (
            {
                validFrom: format(item.validFrom, dateFormat),
                validTo: format(item.validTo, dateFormat)
            }
        ))
    );

    const canConfirm = (): boolean => {
        let result: boolean = true;
        localDates.forEach((item) => {
            if (item.validFrom === null || item.validTo === null) {
                result = false;
            } else if (isBefore(item.validTo, item.validFrom)) {
                result = false;
            }
        });
        return result;
    };

    return (
        <Dialog className="promotionValidityDialogRoot" open={open}>
            <DialogTitle>
                <FormattedMessage id="promotion.validity"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                {localDates.map((item, idx) =>
                    <div className="_formRow" key={idx}>
                        <div>
                            <ValiditySelector id={idx}
                                              autoFillSecondDate={true}
                                              removable
                                              rangeMode
                                              labelFrom={translate({id: 'promotion.validFrom'})}
                                              labelTo={translate({id: 'promotion.validTo'})}
                                              validityFrom={item.validFrom ||  null }
                                              validityTo={item.validTo ||  null}
                                              onRemove={(id) => removeValidity(id)}
                                              onValidityFromChange={(id, date) => { setValidityFrom(id, date)}}
                                              onValidityToChange={(id, date) => { setValidityTo(id, date)}}
                            />
                        </div>
                    </div>)
                }
                <Button color="primary" variant="outlined" endIcon={<AddIcon/>} onClick={addDate}>
                    <FormattedMessage id="a.add"/>
                </Button>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleConfirm} disabled={!canConfirm()}><FormattedMessage id="a.confirm"/></Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.close"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default PromotionValidityDialog;
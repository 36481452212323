import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_PRODUCT_DATA_STRUCTURED_BRAND_PUT} from 'config/api/constants';
import {paths} from 'paths';
import {structuredBrandPayload, structuredBrandUI} from 'shared/models/brand.model';
import {useFormatMessage} from 'utils/translate';
import {getId} from 'utils/routing';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {LoadingOverlay} from 'components/LoadingOverlay';
import StructuredBrandDetails from './StructuredBrandDetails';
import {makeArrayOfBrandsWithRedundantOrderNumberAndMainBrandIsFirst} from './StructuredBrandNewView';

const StructuredBrandsEditView = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brandId, setBrandId] = useState<string>('');
    const [brand, setBrand] = useState<structuredBrandUI>(null);

    useEffect(() => {
        const id = getId();
        if (id) {
            setBrandId(id);
        }
    }, []);

    const handleBrandChange = (value: structuredBrandUI) => setBrand(value);

    const handleSaveClick = () => {
        setIsLoading(true);
        const payload: structuredBrandPayload = {
            active: brand.active,
            brands: makeArrayOfBrandsWithRedundantOrderNumberAndMainBrandIsFirst(brand.mainBrand, brand.secondaryBrands),
            supplierIds: brand.suppliers.map((item) => item.id)
        };
        axios.put(API_PRODUCT_DATA_STRUCTURED_BRAND_PUT(brandId), payload,  {
            params: {
                marketId: brand.marketId
            }
        })
            .then(() => history.push(`${paths.brands}?reload=true`))
            .catch((e) => {
                console.log(e);
                if (e?.response?.status === 409) {
                    enqueueSnackbar(`${translate({id: 'brand.duplicate'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    };

    const canSave = () => {
        if (!brandId || !brand || brand.mainBrand === null || brand.secondaryBrands.length === 0) {
            return false;
        }
        return true;
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={isLoading}/>
                    {brandId && <StructuredBrandDetails brandId={brandId}
                                                        history={history}
                                                        onBrandChange={(brand: structuredBrandUI) => handleBrandChange(brand)}/>}
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        <Link to={paths.brands}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default StructuredBrandsEditView;
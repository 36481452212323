import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer, { IRootState } from 'shared/reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: any;
  }
}

const defaultMiddlewares = [thunk];
let composedMiddlewares = null;

if (process.env.NODE_ENV === "production") {
  composedMiddlewares = middlewares => compose(applyMiddleware(...defaultMiddlewares, ...middlewares));
} else {
  composedMiddlewares = middlewares => compose(applyMiddleware(...defaultMiddlewares, ...middlewares),window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f);
}

const initialize = (initialState?: IRootState, middlewares = []) => createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
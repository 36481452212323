import {format, parseISO} from 'date-fns';
import {
    pageValidity,
    pageValidityBE,
    pageValidityBEGrouped,
    pageValidityBEGroupedWithRanges,
    pageValidityBEPageRangesStringified,
    validateResult
} from 'shared/models/leaflet.model';
import {dateFormat} from 'shared/models/validity.model';

export const dateToString = (date:Date):string => date ? format(date, dateFormat) : null;

export const lastOfLast = (ar) => {
    const l = ar.length - 1;
    const subL = ar[l].length - 1;
    return ar[l][subL];
};

export function importPageValidities(pageValiditiesBE:pageValidityBE[]): pageValidity[] {
    const convert = (data: pageValidityBEPageRangesStringified): pageValidity => {
        return {
            pageNumber: data.pageNumber,
            validFrom: parseISO(data.validFrom),
            validTo: parseISO(data.validTo),
            modified: false
        }
    };

    const groupedByDate: pageValidityBEGrouped[] = [];
    pageValiditiesBE.forEach((item) => {
        const {validFrom, validTo} = item;
        const existingDateGroupIndex = groupedByDate.findIndex((existing) => validFrom === existing.validFrom && validTo === existing.validTo);
        if (existingDateGroupIndex > -1) {
            groupedByDate[existingDateGroupIndex].pageNumber.push(item.pageNumber);
        } else {
            const newItem: pageValidityBEGrouped = {pageNumber: [], validFrom: item.validFrom, validTo: item.validTo};
            newItem.pageNumber = [item.pageNumber];
            groupedByDate.push(newItem);
        }
    });
    const withPagesSorted: pageValidityBEGrouped[] = groupedByDate.map((item) => {
        const newItem: pageValidityBEGrouped = {...item};
        newItem.pageNumber = item.pageNumber.sort((a, b) => a - b);
        return newItem;
    });
    const withRangesGrouped: pageValidityBEGroupedWithRanges[] = withPagesSorted.map((item) => {
        const newItem: pageValidityBEGroupedWithRanges = {pageNumber: [], validFrom: item.validFrom, validTo: item.validTo};
        const ranges = [];
        item.pageNumber.forEach((pgNo, idx) => {
            if (idx === 0) {
                ranges.push([pgNo]);
            } else {
                const lastest = lastOfLast(ranges);
                if (lastest && lastest + 1 === pgNo) {
                    ranges[ranges.length-1].push(pgNo);
                } else {
                    ranges.push([pgNo]);
                }
            }
        });
        newItem.pageNumber = ranges;
        return newItem;
    });
    const withRangesStringified: pageValidityBEPageRangesStringified[] = withRangesGrouped.map((item) => {
        const newItem: pageValidityBEPageRangesStringified = {pageNumber: '', validFrom: item.validFrom, validTo: item.validTo};
        let pagesString = '';
        item.pageNumber.forEach((pgRange) => {
            if (pgRange.length === 1) {
                pagesString += pgRange[0] + ',';
            } else {
                pagesString += pgRange[0] + '-' + pgRange[pgRange.length - 1] + ',';
            }
        });
        newItem.pageNumber = pagesString.slice(0, pagesString.length - 1);
        return newItem
    });
    return withRangesStringified.map((item) => convert(item));
}

export function exportPageValidities(pageValiditiesUI:pageValidity[]): pageValidityBE[] {
    const transformed: pageValidityBE[] = [];
    pageValiditiesUI.forEach((item) => {
        const {validFrom, validTo} = item;
        const byComa = item.pageNumber.split(',');
        byComa.forEach((pgOrRange) => {
            if (pgOrRange.indexOf('-') > 0) {
                const startEnd = pgOrRange.split('-');
                for (let i = parseInt(startEnd[0]); i <= parseInt(startEnd[1]); i++) {
                    transformed.push({pageNumber: i, validFrom: dateToString(validFrom), validTo: dateToString(validTo)})
                }
            } else {
                transformed.push({pageNumber: parseInt(pgOrRange), validFrom: dateToString(validFrom), validTo: dateToString(validTo)})
            }
        });
    });
    return transformed;
}

export function validateValidities(validities:pageValidityBE[]): validateResult {
    let duplicates = new Set([]);
    const pageNumbers = validities.map((item) => item.pageNumber);
    pageNumbers.forEach((pgNo) => {
        const duplicate = pageNumbers.filter((item) => item === pgNo);
        if (duplicate.length > 1) {
            duplicates.add(pgNo);
        }
    });
    return duplicates.size ? {hasError: true, duplicatePages: Array.from(duplicates)} : {hasError: false};
}

export function checkValiditesForNulls(validities:pageValidityBE[]): validateResult {
    let hasError: boolean = false;
    validities.forEach((item) => {
        if (item.validFrom === null || item.validTo === null) {
            hasError = true;
        }
    });
    return {hasError};
}

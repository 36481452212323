/* eslint-disable react-hooks/exhaustive-deps */
import './BrickAttributeDialog.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useFormatMessage} from 'utils/translate';
import {API_LITERALS_SEARCH} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {attributeResponseModel} from 'shared/models/attribute.model';
import Search from 'components/Search/Search';
import CancelIcon from '@mui/icons-material/Cancel';

interface brickAttributeDialogProps {
    open: boolean,
    onClose: () => void,
    attribute: attributeResponseModel,
    onSave: (attributeId: string, literals: basic[], inactiveLiterals) => void
}

const BrickAttributeDialog = (props: brickAttributeDialogProps) => {
    const {attribute, open, onClose, onSave} = props;
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [attributeLiterals, setAttributeLiterals] = useState<basic[]>([]);
    const [inactiveAttributeLiterals, setInactiveAttributeLiterals] = useState<basic[]>([]);

    useEffect(() => {
        if (open && attribute) {
            setAttributeLiterals(attribute.activeAssignedLiterals);
            setInactiveAttributeLiterals(attribute.inactiveAssignedLiterals);
        }
    }, [open]);

    const handleMoveItemToInactive = (itemId: string) => {
        const item = attributeLiterals.find(item => item.id === itemId);
        if (item) {
          const newActive = attributeLiterals.filter(item => item.id !== itemId);
          const newInactive = [...inactiveAttributeLiterals, item];
          setAttributeLiterals(newActive);
          setInactiveAttributeLiterals(newInactive);
        }
    }

    const handleMoveItemToActive = (itemId: string) => {
        const item = inactiveAttributeLiterals.find(item => item.id === itemId);
        if (item) {
          const newActive = inactiveAttributeLiterals.filter(item => item.id !== itemId);
          const newInactive = [...attributeLiterals, item];
          setAttributeLiterals(newInactive);
          setInactiveAttributeLiterals(newActive);
        }
    }

    const handleLiteralSelect = (literal: basic) => {
        if (literal) {
            if (!attributeLiterals.find((item) => item.id === literal.id) && !inactiveAttributeLiterals.find((item) => item.id === literal.id)) {
                const newLiterals: basic[] = [...attributeLiterals];
                newLiterals.push(literal);
                setAttributeLiterals(newLiterals);
            } else enqueueSnackbar(`${translate({id: 'literals.duplicate'})}`, {variant: 'error', persist: false});
        }
    };

    const handleLiteralDelete = (literalId: string, attributeState: basic[]) => {
        const newLiterals: basic[] = [...attributeState];
        if (attributeState === attributeLiterals) setAttributeLiterals(newLiterals.filter((item) => item.id !== literalId));
        else setInactiveAttributeLiterals(newLiterals.filter((item) => item.id !== literalId));
    }

    if (!attribute) {
        return null;
    }

    return (
        <Dialog className="brickAttributeDialogRoot" open={open} fullScreen>
            <DialogTitle>
                <FormattedMessage id="category.setupBrickDependentAttr" values={{attributeName: attribute.name}}/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <div className="_halfWidth">
                    <Search onSelectionChange={handleLiteralSelect} autoSelect={true} autoFocus={true} clearOnSelect={true} searchUrl={API_LITERALS_SEARCH} label="literals.label"/>
                </div>
                <p className="_bold">
                    <FormattedMessage id ="literals.activeLiterals"/>:
                </p>
                <div className="literalsList">
                    {attributeLiterals && attributeLiterals.length
                    ? attributeLiterals.map((item) => <Button color="secondary" variant="outlined" className="brickAttributeBtn" key={`active-${item.id}`}>
                                                            <Tooltip title={translate({id: 'a.tooltipClick2Deactivate'})}>
                                                                <span onClick={() => handleMoveItemToInactive(item.id)}>{item.name}</span>
                                                            </Tooltip>
                                                            <CancelIcon onClick={() => handleLiteralDelete(item.id, attributeLiterals)} className="deleteBtn"/>
                                                        </Button>)
                    : '-'}
                </div>
                <p className="_bold">
                    <FormattedMessage id ="literals.inactiveLiterals"/>:
                </p>
                <div className="literalsList">
                    {inactiveAttributeLiterals && inactiveAttributeLiterals.length 
                    ? inactiveAttributeLiterals.map((item) => <Button color="secondary" variant="outlined" className="brickAttributeBtn" key={`inactive-${item.id}`}>
                                                            <Tooltip title={translate({id: 'a.tooltipClick2Activate'})}>
                                                            <span onClick={() => handleMoveItemToActive(item.id)}>{item.name}</span>
                                                        </Tooltip>
                                                        <CancelIcon onClick={() => handleLiteralDelete(item.id, inactiveAttributeLiterals)} className="deleteBtn"/>
                                                    </Button>)
                    : '-'}
                </div>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => onSave(attribute.id, attributeLiterals, inactiveAttributeLiterals)}><FormattedMessage id="a.confirm"/></Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.cancel"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default BrickAttributeDialog;
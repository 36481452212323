import './Promotion.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {DeleteOutline} from '@mui/icons-material';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {Button, FormHelperText, Tooltip} from '@mui/material';
import IconButton from '@mui/material/IconButton'
import {useSnackbar} from 'notistack';
import {handyAttributes, hardcodedPercent} from 'shared/handyAttributes';
import {shortCharacteristicUI} from 'shared/models/product.model';
import {PROMOTION_TYPES, promotionTableRowBE, promotionMediaTableRowBE, promotionUI} from 'shared/models/promotion.model';
import {useFormatMessage} from 'utils/translate';
import {storeInLocalStorage} from 'utils/storageUtils';
import Attribute from 'components/Attribute';
import {valueTypes} from 'components/Inputs/NumberInput';
import {NumberInput, PriceInput} from 'components/Inputs';
import {externalError, PriceInputRef} from 'components/Inputs/PriceInput';
import ReportProduct from 'components/ReportProduct/ReportProduct';
import {frameValidityTemplate} from 'modules/Advertisement/FrameValidity/FrameValidity';
import {GiveAwayBundlingDrawer} from './GiveAwayBundlingDrawer';
import PromotionValidity from './PromotionValidity/PromotionValidity';

interface promotionProps {
    countryId: string,
    data: promotionUI,
    onUpdatePromotion: (key: string, value: any, UIId: string) => void,
    onRemovePromotion?: Function,
    onPromotionError: (error: boolean, UIId: string) => void,
    onClonePromotion: (promotionUIId: string) => void,
    onCloneProduct: (productId: string, promotionUIId: string) => void,
    onCloneBPCO: (bpcoId: string, promotionUIId: string) => void,
    autoFocus?: boolean,
    mpuRequired?: boolean,//defaults to true if not specified
}

export enum promotionReasons {
    'paramsRequired' = 'v.paramsRequired',
    'promoLessThanRegular' = 'v.promoLessThanRegular'
}

enum fieldErrorIds {
    promotionalPrice = 'promotionalPrice',
    regularPrice = 'regularPrice',
    relativeDiscount = 'relativeDiscount',
    absoluteDiscount = 'absoluteDiscount',
    mpu = 'mpu',
    promoPriceGtEqRegularPrice = 'promoPriceGtEqRegularPrice'
}

type fieldErrorsType = {
    [key in fieldErrorIds]: boolean
};

export const promotionCopyLocalStorage: string = 'clipboardPromotion';

const Promotion = (props:promotionProps) => {
    const {countryId, data, onPromotionError, onUpdatePromotion, onRemovePromotion, onCloneBPCO, onClonePromotion, onCloneProduct} = props;
    const isMpuRequired = props.mpuRequired === false ? false : true;
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const promotionalPriceRef = useRef<PriceInputRef>(null);
    const regularPriceRef = useRef<PriceInputRef>(null);

    const [wantChangeThemeSeasons, setWantChangeThemeSeasons] = useState<boolean>(false);
    const [wantChangeCountryThemedWeeks, setWantChangeCountryThemedWeeks] = useState<boolean>(false);
    const [wantChangePromotionTypes, setWantChangePromotionTypes] = useState<boolean>(false);
    const [disabledByError, setDisabledByError] = useState<boolean>(false);
    const [isGiveAwayBundlingDialogOpen, setIsGiveAwayBundlingDialogOpen] = useState<boolean>(false);

    const [formErrors, setFormErrors] = useState<fieldErrorsType>({
        [fieldErrorIds.promotionalPrice]: false,
        [fieldErrorIds.regularPrice]: false,
        [fieldErrorIds.relativeDiscount]: false,
        [fieldErrorIds.absoluteDiscount]: false,
        [fieldErrorIds.mpu]: false,
        [fieldErrorIds.promoPriceGtEqRegularPrice]: false
    });

    useEffect(() => {
        let hasErrors: boolean = false;
        for (let i in formErrors) {
            if (formErrors[i]) hasErrors = true;
        }
        setDisabledByError(hasErrors);
        onPromotionError(hasErrors, data.UIId);
    }, [formErrors]);

    const handleFormErrors = (key: fieldErrorIds, error: boolean) => {
        if (formErrors[key] !== error) {
            const newErrors: fieldErrorsType = {...formErrors};
            newErrors[key] = error;
            setFormErrors(newErrors);
        }
    }

    const handleUpdatePromotion = (key: string, value: number | string[], error: boolean) => {
        onUpdatePromotion(key, value, data.UIId);
        handleFormErrors(fieldErrorIds[key], error);
    }

    const handleAttrChange = (hrn:string, value: any) => { //hrn - human readable name; value can be any of promotion attribute - enum, validity date etc
        onUpdatePromotion(hrn, value, data.UIId);
    };

    const handleChangeThemeSeasons = () => setWantChangeThemeSeasons(true);

    const handleChangeCountryThemedWeeks = () => setWantChangeCountryThemedWeeks(true);

    const handleChangePromotionTypes = () => setWantChangePromotionTypes(true);

    const openProductWindow = () => {
        if (data.productId) {
            onCloneProduct(data.productId, data.UIId);
        } else if (data.bpcoId) {
            onCloneBPCO(data.bpcoId, data.UIId);
        }
    };

    const handleClonePromotion = () => onClonePromotion(data.UIId);

    const handleCopyForm = () => {
        storeInLocalStorage(promotionCopyLocalStorage, JSON.stringify(data));
        enqueueSnackbar(`${translate({id: 'promotion.copied'})}`, {variant: 'info', persist: false});
    };

    const handlePasteForm = () => {
        const clipboardPromotion = JSON.parse(localStorage.getItem(promotionCopyLocalStorage));
        if (clipboardPromotion) { 
            onUpdatePromotion('promotionTypes', clipboardPromotion.promotionTypes, data.UIId);
            onUpdatePromotion('themeSeasons', clipboardPromotion.themeSeasons, data.UIId);
            onUpdatePromotion('countryThemedWeeks', clipboardPromotion.countryThemedWeeks, data.UIId);
            onUpdatePromotion('qualitySeals', clipboardPromotion.qualitySeals, data.UIId);
            onUpdatePromotion('relativeDiscount', clipboardPromotion.relativeDiscount, data.UIId);
            onUpdatePromotion('absoluteDiscount', clipboardPromotion.absoluteDiscount, data.UIId);
            onUpdatePromotion('mpu', clipboardPromotion.mpu, data.UIId);
            onUpdatePromotion('promotionalPrice', clipboardPromotion.promotionalPrice, data.UIId);    
            onUpdatePromotion('regularPrice', clipboardPromotion.regularPrice, data.UIId);
            onUpdatePromotion('giveAwayBundledProducts', clipboardPromotion.giveAwayBundledProducts, data.UIId);
            onUpdatePromotion('validityDates', clipboardPromotion.validityDates, data.UIId);
            if (promotionalPriceRef.current) promotionalPriceRef.current.resetInputValue();
            if (regularPriceRef.current) regularPriceRef.current.resetInputValue();
        } else {
            enqueueSnackbar(`${translate({id: 'promotion.copiedErr'})}`, {variant: 'error', persist: false});
        }
    };

    const handleRemoveGiveAwayBundling = (id) => onUpdatePromotion('giveAwayBundledProducts', data.giveAwayBundledProducts.filter(item => item.id !== id.id), data.UIId);

    const comparePrices = ():externalError => {
        const pricesValid: externalError = {
            error: false,
            reason: null
        };
        if (
            (data.promotionalPrice === null && data.regularPrice === null) ||
            (data.promotionalPrice !== null && data.regularPrice === null) ||
            (data.promotionalPrice === null && data.regularPrice !== null)
        ) {
            handleFormErrors(fieldErrorIds.promoPriceGtEqRegularPrice, false);
            return pricesValid;
        }
        if (data.promotionalPrice >= data.regularPrice) {
            handleFormErrors(fieldErrorIds.promoPriceGtEqRegularPrice, true);
            return {error: true, reason: promotionReasons.promoLessThanRegular};
        }
        handleFormErrors(fieldErrorIds.promoPriceGtEqRegularPrice, false);
        return pricesValid;
    };

    return (
        <div className="promotionRoot" id={data.UIId}>
            <div className='_directionRow'>
                <div className="_directionCol productColumn">
                    {data.isTemplate && <span className="isTemplate"><FormattedMessage id="product.isTemplate"/></span>}
                    <ProductProperty label="b.brand" value={data.brand}/>
                    <ProductProperty label="b.category" value={data.category}/>
                    <ProductProperty label="b.descr" value={data.description}/>
                    {data.characteristics.map((item) => <ProductCharacteristic key={item.id} label={item.name} value={item.value}/>)}
                    {!data.isTemplate && <ReportProduct id={data.productId}/>}
                </div>
                <div className="_directionCol pricesColumn">
                    <div className="_formRowDouble">
                        <PriceInput onChange={(propIdentifier, value, error) => handleUpdatePromotion(propIdentifier, value, error)}
                                    value={data[fieldErrorIds.promotionalPrice]}
                                    label="b.promotionalPrice"
                                    externalError={comparePrices()}
                                    propIdentifier={fieldErrorIds.promotionalPrice}
                                    ref={promotionalPriceRef}
                                    autoFocus />
                        <PriceInput onChange={(propIdentifier, value, error) => handleUpdatePromotion(propIdentifier, value, error)}
                                    value={data[fieldErrorIds.regularPrice]}
                                    label="b.regularPrice"
                                    externalError={comparePrices()}
                                    propIdentifier={fieldErrorIds.regularPrice}
                                    ref={regularPriceRef}/>
                    </div>
                    <div className="_formRowDouble">
                        <NumberInput valueType={valueTypes.float}
                                    onChange={(propIdentifier, value, error) => handleUpdatePromotion(propIdentifier, value, error)}
                                    label="b.relativeDiscount"
                                    value={data[fieldErrorIds.relativeDiscount]}
                                    propIdentifier={fieldErrorIds.relativeDiscount}
                                    range={{from: 0.0001}}
                                    endAdornment={hardcodedPercent}
                        />
                        <NumberInput valueType={valueTypes.float}
                                    onChange={(propIdentifier, value, error) => handleUpdatePromotion(propIdentifier, value, error)}
                                    label="b.absoluteDiscount"
                                    value={data[fieldErrorIds.absoluteDiscount]}
                                    propIdentifier={fieldErrorIds.absoluteDiscount}
                                    range={{from: 0.0001}}
                                    endAdornment={data.currency}
                        />
                    </div>
                    <ParamsMissing data={data}/>
                    <div className="_formRowDouble">
                        <NumberInput valueType={valueTypes.int}
                                    onChange={(propIdentifier, value, error) => handleUpdatePromotion(propIdentifier, value, error)}
                                    label="b.mpu"
                                    value={data[fieldErrorIds.mpu]}
                                    propIdentifier={fieldErrorIds.mpu}
                                    required={isMpuRequired}
                                    range={{from: 1}}
                        />
                        <div className="numInput"/>
                    </div>
                </div>
                <div className="_directionCol typesColumn">
                    {data.derivedPromotionTypes?.literals?.length > 0 && <>
                        <div className="derivedPropertyContainer">
                            <div>
                                <Attribute id={handyAttributes.promotionType}
                                        data={data.derivedPromotionTypes}
                                        onChange={(key, value) => handleAttrChange('promotionTypes', value)}
                                        multiselect
                                        readonly/>
                            </div>
                            <div>
                                <Button onClick={handleChangePromotionTypes} variant="contained" color="primary" disabled={wantChangePromotionTypes}>
                                    <FormattedMessage id="a.override"/>
                                </Button>
                            </div>
                        </div>
                        <FormHelperText>
                            {translate({id: 'promotion.derivedPropsExplanation'})}
                        </FormHelperText>
                    </>
                    }
                    {(wantChangePromotionTypes || data.promotionTypes?.literals?.length > 0 || data.derivedPromotionTypes?.literals?.length === 0) &&
                        <>
                            <div className="_formRow">
                                <div>
                                    <Attribute id={handyAttributes.promotionType}
                                               data={data.promotionTypes}
                                               onChange={(key, value) => handleAttrChange('promotionTypes', value)}
                                               multiselect/>
                                </div>
                            </div>
                            {wantChangePromotionTypes && <FormHelperText>
                                    {translate({id: 'promotion.overridePropsExplanation'})}
                                </FormHelperText>
                            }
                        </>
                    }
                    {data.derivedThemeSeasons?.literals?.length > 0 && <>
                        <div className="derivedPropertyContainer">
                            <div>
                                <Attribute id={handyAttributes.themeSeason}
                                        data={data.derivedThemeSeasons}
                                        onChange={(key, value) => handleAttrChange('themeSeasons', value)}
                                        multiselect
                                        readonly/>
                            </div>
                            <div>
                                <Button onClick={handleChangeThemeSeasons} variant="contained" color="primary" disabled={wantChangeThemeSeasons}>
                                    <FormattedMessage id="a.override"/>
                                </Button>
                            </div>
                        </div>
                        <FormHelperText>
                            {translate({id: 'promotion.derivedPropsExplanation'})}
                        </FormHelperText>
                    </>
                    }
                    {(wantChangeThemeSeasons || data.themeSeasons?.literals?.length > 0 || data.derivedThemeSeasons?.literals?.length === 0) &&
                        <>
                            <div className="_formRow">
                                <div>
                                    <Attribute id={handyAttributes.themeSeason}
                                                data={data.themeSeasons}
                                                onChange={(key, value) => handleAttrChange('themeSeasons', value)}
                                                multiselect/>
                                </div>
                            </div>
                            {wantChangeThemeSeasons && <FormHelperText>
                                    {translate({id: 'promotion.overridePropsExplanation'})}
                                </FormHelperText>
                            }
                        </>
                    }
                    {data.derivedThemedWeeks?.literals?.length > 0 && <>
                        <div className="derivedPropertyContainer">
                            <div>
                                <Attribute id={handyAttributes.countryThemedWeeks}
                                        data={data.derivedThemedWeeks}
                                        onChange={(key, value) => handleAttrChange('countryThemedWeeks', value)}
                                        multiselect
                                        readonly/>
                            </div>
                            <div>
                                <Button onClick={handleChangeCountryThemedWeeks} variant="contained" color="primary" disabled={wantChangeCountryThemedWeeks}>
                                    <FormattedMessage id="a.override"/>
                                </Button>
                            </div>
                        </div>
                        <FormHelperText>
                            {translate({id: 'promotion.derivedPropsExplanation'})}
                        </FormHelperText>
                    </>
                    }
                    {(wantChangeCountryThemedWeeks || data.countryThemedWeeks?.literals?.length > 0 || data.derivedThemedWeeks?.literals?.length === 0) &&
                        <>
                            <div className="_formRow">
                                <div>
                                <Attribute id={handyAttributes.countryThemedWeeks}
                                            data={data.countryThemedWeeks}
                                            onChange={(key, value) => handleAttrChange('countryThemedWeeks', value)}
                                            multiselect/>
                                </div>
                            </div>
                            {wantChangeCountryThemedWeeks && <FormHelperText>
                                    {translate({id: 'promotion.overridePropsExplanation'})}
                                </FormHelperText>
                            }
                        </>
                    }
                    <div className="_formRow">
                        <div>
                            <Attribute id={handyAttributes.qualitySeal} data={data.qualitySeals} onChange={(key, value) => handleAttrChange('qualitySeals', value)} multiselect/>
                        </div>
                    </div>
                </div>
                <GiveAwayBundlingDrawer countryId={countryId}
                                        data={data}
                                        open={isGiveAwayBundlingDialogOpen}
                                        onClose={() => setIsGiveAwayBundlingDialogOpen(false)}
                                        onUpdatePromotion={onUpdatePromotion}
                />
            </div>
            <div className="giveAwayBundlingRow">
                {(!!data?.giveAwayBundledProducts?.length) && <span className="_bold">{translate({id: 'productSearch.giveAwayBundling'})}</span>}
                {/*@todo using idx to avoid duplicate indexes when same product selected twice; proper fix in MPM-3166 */}
                {data?.giveAwayBundledProducts?.map((item, idx) => (
                    <div key={item.id + idx}>
                        <Tooltip
                            placement='bottom-end'
                            title={
                                <ul>
                                    {item?.characteristics.map((characteristic, i) => (
                                        <li key={i}>{characteristic?.name}: {characteristic?.value}</li>
                                    ))}
                                </ul>
                            }
                        >
                        <div className='giveAwayBundlingTooltip'>
                            <span className="giveAwayBundlingItem">{item?.brand}, {item?.category}, {item?.characteristics.find(characteristic => characteristic.hasOwnProperty('value'))?.value}</span>
                        </div>
                        </Tooltip>
                        <IconButton color="secondary" onClick={() => handleRemoveGiveAwayBundling(item)}>
                                <DeleteOutline fontSize="small"/>
                        </IconButton>
                    </div>
                ))}
            </div>
            <PromotionValidity validityDates={data.validityDates} onChange={(dates) => handleAttrChange('validityDates', dates)}/>
            <div className="promotionActions">
                        <Button onClick={handleClonePromotion} color="primary" variant="outlined" size="small" tabIndex={-1} disabled={disabledByError} endIcon={<CopyAllIcon/>}>
                            <FormattedMessage id="productSearch.clonePromotion"/>
                        </Button>
                        <Button onClick={handleCopyForm} color="primary" variant="outlined" size="small" tabIndex={-1} disabled={disabledByError}>
                            <FormattedMessage id="a.copy"/>
                        </Button>
                        <Button onClick={handlePasteForm} color="primary" variant="outlined" size="small" tabIndex={-1}>
                            <FormattedMessage id="a.paste"/>
                        </Button>
                        {data.promotionObjectType === PROMOTION_TYPES.PRODUCT
                            ?
                            <Button color="primary" variant="outlined" size="small" tabIndex={-1}
                                    onClick={openProductWindow}
                            >
                                <FormattedMessage id="product.createCopy"/>
                            </Button>
                            :
                            <Button color="primary" variant="outlined" size="small" tabIndex={-1}
                                    onClick={openProductWindow}
                            >
                                <FormattedMessage id="bpco.createCopy"/>
                            </Button>
                        }
                        <Button className="giveAwayBundlingBtn" color="primary" variant="outlined" size="small" tabIndex={-1}
                                onClick={() => setIsGiveAwayBundlingDialogOpen(true)}>
                            <FormattedMessage id="productSearch.giveAwayBundling"/>
                        </Button>
                        {onRemovePromotion &&
                            <Button onClick={() => onRemovePromotion(data.UIId)} color="primary" variant="outlined" size="small"
                                    tabIndex={-1}>
                                <FormattedMessage id="a.remove"/>
                            </Button>
                        }
            </div>
        </div>
    );
};

interface productPropertyProps {
    label: string,
    value: string
}

const ProductProperty = (props:productPropertyProps) => (
    <div className="productPropertyRow">
        <span className="productPropertyCol productPropertyLabel">
            <FormattedMessage id={props.label}/>:
        </span>
        <span className="productPropertyCol">{props.value}</span>
    </div>);

const ProductCharacteristic = (props:productPropertyProps) => (
    <div className="productPropertyRow">
        <span className="productPropertyCol productPropertyLabel">{props.label}:</span>
        <span className="productPropertyCol">{props.value}</span>
    </div>);

interface paramsMissingProps {
    data: promotionUI
}

const ParamsMissing = (props: paramsMissingProps) => {
    const {promotionalPrice, regularPrice, absoluteDiscount, relativeDiscount} = props.data;

    return testPromotionParameters(promotionalPrice, regularPrice, absoluteDiscount, relativeDiscount) ? <div className="_formRow">
        <span className="promoInvalidMsg">
            <FormattedMessage id={promotionReasons.paramsRequired}/>
        </span>
    </div> : null;
}

const testPromotionParameters = (promotionalPrice: number, regularPrice: number, absoluteDiscount: number, relativeDiscount: number) : boolean =>
    promotionalPrice === null && regularPrice === null && absoluteDiscount === null && relativeDiscount === null;

// @TODO its used in one place (not here). move it there, why export from here?
export const buildPromotionRow = (promotion: promotionUI, derivedThemeSeasons: string[], derivedThemedWeeks: string[], derivedPromotionTypes: string[], frameValidity: frameValidityTemplate ):promotionTableRowBE => {
    const rowData: promotionTableRowBE = {
        absoluteDiscount: promotion.absoluteDiscount || null,
        currency: promotion.currency,
        // frameId?: string,
        // id?: string,
        id: promotion.UIId,
        mpu: promotion.mpu|| null,
        promotionObjectType: promotion.promotionObjectType,
        promotionTypes: promotion.promotionTypes.literals.length > 0 ? promotion.promotionTypes.literals.map((item) => item.literalName) : derivedPromotionTypes,
        promotionalPrice: promotion.promotionalPrice || null,
        qualitySeals: promotion.qualitySeals.literals.map((item) => item.literalName),
        regularPrice: promotion.regularPrice || null,
        relativeDiscount: promotion.relativeDiscount || null,
        themeSeasons: promotion.themeSeasons.literals.length > 0 ? promotion.themeSeasons.literals.map((item) => item.literalName) : derivedThemeSeasons,
        countryThemedWeeks: promotion.countryThemedWeeks.literals.length > 0 ? promotion.countryThemedWeeks.literals.map((item) => item.literalName) : derivedThemedWeeks,
        frameValidFrom: frameValidity.validFrom,
        frameValidTo: frameValidity.validTo,
        giveAwayBundledProducts: promotion?.giveAwayBundledProducts?.map(item => item.brand),
        validityDates: promotion.validityDates
    };
    if (rowData.promotionObjectType === PROMOTION_TYPES.PRODUCT) {
        rowData.product = {
            brand: promotion.brand,
            characteristics: promotion.characteristics,
            category: promotion.category,
            description: promotion.description,
            // gtin: promotion.gtin,
            gtin: '',
            id: promotion.productId,
            isTemplate: promotion.isTemplate,
            // productLineId: promotion.productLineId,
            productLineId: '',
            // productLineName: promotion.productLineName,
            productLineName: ''
        }
    } else {
        let bpcoCharacteristics: shortCharacteristicUI[] = promotion.characteristics;
        if (promotion.description) {
            bpcoCharacteristics = bpcoCharacteristics.concat([{id: handyAttributes.description, name: 'artificially restored description', value: promotion.description}]);
        }
        rowData.bpco = {
            alternativeDescription: null,
            brand: promotion.brand,
            characteristics: bpcoCharacteristics,
            category: promotion.category,
            description: promotion.description,
            id: promotion.bpcoId
        }
    }
    return rowData;
}

export const buildMediaPromotionRow = (promotion: promotionUI, derivedThemeSeasons: string[], derivedThemedWeeks: string[]): promotionMediaTableRowBE => {
    const rowData: promotionMediaTableRowBE = {
        absoluteDiscount: promotion.absoluteDiscount || null,
        currency: promotion.currency,
        id: promotion.UIId,
        mpu: promotion.mpu|| null,
        promotionObjectType: promotion.promotionObjectType,
        promotionTypes: promotion.promotionTypes.literals.map((item) => item.literalName),
        promotionalPrice: promotion.promotionalPrice || null,
        qualitySeals: promotion.qualitySeals.literals.map((item) => item.literalName),
        regularPrice: promotion.regularPrice || null,
        relativeDiscount: promotion.relativeDiscount || null,
        themeSeasons: promotion.themeSeasons.literals.length > 0 ? promotion.themeSeasons.literals.map((item) => item.literalName) : derivedThemeSeasons,
        countryThemedWeeks: promotion.countryThemedWeeks.literals.length > 0 ? promotion.countryThemedWeeks.literals.map((item) => item.literalName) : derivedThemedWeeks,
        giveAwayBundledProducts: promotion?.giveAwayBundledProducts?.map(item => item.brand),
        validityDates: promotion.validityDates
    };
    if (rowData.promotionObjectType === PROMOTION_TYPES.PRODUCT) {
        rowData.product = {
            brand: promotion.brand,
            characteristics: promotion.characteristics,
            category: promotion.category,
            description: promotion.description,
            gtin: '',
            id: promotion.productId,
            isTemplate: promotion.isTemplate,
            productLineId: '',
            productLineName: ''
        }
    } else {
        let bpcoCharacteristics: shortCharacteristicUI[] = promotion.characteristics;
        if (promotion.description) {
            bpcoCharacteristics = bpcoCharacteristics.concat([{id: handyAttributes.description, name: 'artificially restored description', value: promotion.description}]);
        }
        rowData.bpco = {
            alternativeDescription: null,
            brand: promotion.brand,
            characteristics: bpcoCharacteristics,
            category: promotion.category,
            description: promotion.description,
            id: promotion.bpcoId
        }
    }
    return rowData;
}

export default Promotion;
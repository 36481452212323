import {useMutation, useQuery} from 'react-query';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {
    API_CATEGORIES_HIERARCHY,
    API_CATEGORY_BRICK_ATTRIBUTES_DETAILS,
    API_CATEGORY_DETAILS,
    API_GET_CATEGORY_BRICK_DETAILS,
    API_GET_CATEGORY_DETAILS
} from 'config/api/constants';
import {categoryBEModel, categoryHierarchyResponseModel} from 'shared/models/category.model';
import {categoryBrick, categoryBrickTemplate} from 'shared/models/categoryBrick.model';
import {useFormatMessage} from 'utils/translate';

const fetchBrick = async (id:string, lang:string): Promise<categoryBrick> => {
    if (!id) {
        return categoryBrickTemplate;
    }
    const response = await axios.get(API_GET_CATEGORY_BRICK_DETAILS(id, lang));
    return response.data;
};

export const useBrickQuery = (id:string, lang: string, enabled?: boolean) => useQuery(
    [id, lang, 'fetchBrick'],
    () => fetchBrick(id, lang),
    {
        initialData: categoryBrickTemplate,
        staleTime: 0,
        cacheTime: 1,
        enabled // passing enabled: true when initializing this hook prevents query from autotriggering
    }
);

const fetchBrickAttributes = async (id:string, lang:string):Promise<categoryBrick> => {
    if (!id) {
        return categoryBrickTemplate;
    }
    const response = await axios.get(API_CATEGORY_BRICK_ATTRIBUTES_DETAILS(id), {params: {lang}});
    return response.data;
};

export const useBrickAttributesQuery = (id:string, lang: string, enabled?: boolean) => useQuery(
    [id, lang, 'fetchBrick'],
    () => fetchBrickAttributes(id, lang),
    {
        initialData: categoryBrickTemplate,
        staleTime: 0,
        cacheTime: 1,
        enabled // passing enabled: true when initializing this hook prevents query from autotriggering
    }
);

const fetchCategory = async (id:string, lang:string): Promise<categoryBEModel> => {
    const response = await axios.get(API_GET_CATEGORY_DETAILS(id), {params: {lang}});
    return response.data;
};

export const useCategoryQuery = (id:string, lang: string, enabled?: boolean) => useQuery(
    [id, lang, 'fetchCategory'],
    () => fetchCategory(id, lang),
    {
        staleTime: 0,
        cacheTime: 1,
        enabled // passing enabled: true when initializing this hook prevents query from autotriggering
    }
);

const fetchCategoriesHierarchy = async (lang: string, active?: boolean): Promise<categoryHierarchyResponseModel> => {
    const response = await axios.get(API_CATEGORIES_HIERARCHY, {params: {lang, active}});
    return response.data;
};

export const useCategoriesHierarchyQuery = (lang: string, active?: boolean) => useQuery(
    [lang, active, 'fetchCategoriesHierarchy'],
    () => fetchCategoriesHierarchy(lang, active),
    {
        staleTime: 0,
        cacheTime: 1
    });

export const useCategoryPut = (categoryId: string, payload: categoryBEModel, onSuccess?:Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        () => axios.put(API_CATEGORY_DETAILS + `/${categoryId}` , payload),
        {
            onSuccess: () => {
                enqueueSnackbar(`${translate({id: 'category.updateSucc'})}`, {variant: 'success', persist: false});
                if (onSuccess) onSuccess();
            },
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'category.attrUpdateErr'})}`, {variant: 'error', persist: false});
            }
        }
    )
}

export const useCategoryPost = (payload: categoryBEModel, onSuccess?:Function) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    return useMutation(
        () => axios.post(API_CATEGORY_DETAILS, payload),
        {
            onSuccess: (response) => {
                enqueueSnackbar(`${translate({id: 'category.createSucc'})}`, {variant: 'success', persist: false});
                if (onSuccess) onSuccess(response);
            },
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'category.createErr'})}`, {variant: 'error', persist: false});
            }
        }
    )
}
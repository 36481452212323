import './PromotionValidity.scss';

import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {IconButton, Tooltip} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {validity} from 'shared/models/validity.model';
import PromotionValidityDialog from './PromotionValidityDialog';

interface promotionValidityProps {
    validityDates: validity[],
    onChange: (dates: validity[]) => void
}

const PromotionValidity = (props:promotionValidityProps) => {
    const {validityDates, onChange} = props;

    const [open, setOpen] = useState<boolean>(false);

    const handleConfirm = (dates: validity[]) => {
        setOpen(false);
        onChange(dates);
    };

    return (
        <>
            <div className="promotionValidityRoot">
                <div className="_bold">
                    <FormattedMessage id="promotion.validity"/>:&nbsp;
                </div>
                <div className="value">
                    <ValidityValue validity={validityDates[0]}/>
                    {validityDates?.length > 1 &&
                        <Tooltip className="tooltip" title={
                            validityDates.map((item, idx) => idx !== 0 ? <div key={idx}><ValidityValue validity={item}/><br/></div> : null)
                        }>
                            <span>&nbsp;<FormattedMessage id='promotion.more' values={{count: validityDates.length - 1}}/>...</span>
                        </Tooltip>
                    }
                </div>
                <div>
                    <IconButton color="secondary" onClick={() => setOpen(true)}>
                        <EditIcon/>
                    </IconButton>
                </div>
            </div>
            <PromotionValidityDialog open={open}
                                     dates={validityDates}
                                     onClose={() => setOpen(false)}
                                     onConfirm={(dates) => handleConfirm(dates)}/>
        </>
    );
};

export default PromotionValidity;

interface validityValueProps {
    validity: validity
}

const ValidityValue = ({validity}: validityValueProps) => {
    if (validity) {
        if (validity.validFrom !== validity.validTo) {
            return <span>{validity.validFrom} - {validity.validTo}</span>;
        } else {
            return <span>{validity.validFrom}</span>;
        }
    }
    return null;
}
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {API_EXPORT} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {fetchProductsAPI} from 'shared/models/product.model';
import {saneFilters, sanitizeFilters} from 'components/ProductFilter/ProductFilter';
import {ButtonExport} from 'components/Buttons';

const productListFiltersKey: string = 'PRODUCTS_LIST_FILTERS';

const ProductExport = () => {
        const userSettings = useSelector((state: IRootState) => state.userSettings);
        const userProfile = useSelector((state: IRootState) => state.userProfile);

        const saneFilters = useCallback(() => {
            return userSettings.settings[productListFiltersKey] ? sanitizeFilters(userSettings.settings[productListFiltersKey]) : {};
        }, [userSettings]);

        const [isExportButtonDisabled, setIsExportButtonDisabled] = useState<boolean>(false);

        useEffect(() => {
            setIsExportButtonDisabled(canExport(saneFilters()));
        }, [saneFilters]);

        const canExport = (saneFilters: saneFilters) => {
            for (const key in saneFilters) {
                // Exclude active filter
                if (key === 'active') continue;
                if (!saneFilters[key].value) return false;
            }
            return true;
        }

        const exportData = async () => {
            const filter: fetchProductsAPI = {
                ...saneFilters(),
                lang: userProfile.langData,
                marketId: userProfile.countryMarket.market
            };
            try {
                const response = await axios.post(API_EXPORT,
                    {
                        filter,
                        exportType: 'PRODUCTS_CSV'
                    }
                );
                window.open(response.data, '_blank');
            } catch (error) {
                console.error('Export error:', error);
            }
        }

        return (
            <ButtonExport onClick={exportData} disabled={isExportButtonDisabled}/>
        )
    }
;

export default ProductExport;

import {SelectionChangedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Tooltip} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import AgTable, {
    localStorageColumnsKeys,
    selectAllColDef
} from 'components/AgTable/AgTable';
import {MainAttributesMUITooltipContent} from 'modules/MasterData/Product/Tooltips';
import {productSearchTableRow} from 'modules/MasterData/Product/ProductsListSearchResults';

interface ProductBulkDescriptionTableProps {
    data: productSearchTableRow[],
    onSelectionChange: (selectedProducts: productSearchTableRow[]) => void
}

const ProductBulkDescriptionTable = ({data, onSelectionChange}: ProductBulkDescriptionTableProps) => {
    const translate = useFormatMessage();

    const columnDefs: ColDef[] = [
        selectAllColDef,
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'category', headerName: translate({id: 'b.categoryBrick'}), flex: 1 },
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            cellRenderer: (params) => <Tooltip title={<MainAttributesMUITooltipContent value={params.data.characteristics}/>}>
                <div>{params.value}</div>
            </Tooltip>
        },
        { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1}
    ];

    return (
        <AgTable
            rowData={data}
            defaultColDef={{
                resizable: true,
                sortable: true,
                suppressMenu: true
            }}
            tooltipShowDelay={0}
            columnDefs={columnDefs}
            suppressContextMenu={false}
            onSelectionChanged={(e: SelectionChangedEvent) => onSelectionChange(e.api.getSelectedNodes().map((node) => node.data))}
            rowSelection="multiple"
            localStorageColumnsKey={localStorageColumnsKeys.productBulkDescriptionChangeTable}
        />
    );
};

export default ProductBulkDescriptionTable;
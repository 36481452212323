import {attributeResponseModel, slimAttributeResponseModel} from 'shared/models/attribute.model';
import {basic} from './_common.model';

export type categoryBrick = {
    active: boolean,
    attributes: slimAttributeResponseModel[]
    activeAssignedAttributes?: slimAttributeResponseModel[]
    id: string,
    inactiveAssignedAttributes?: slimAttributeResponseModel[],
    name: string,
}

export type mapOfApiAttributeResponse = {
    [key: string]: attributeResponseModel
}

export type categoryBrickUI = {
    name: string,
    id: string,
    active: boolean,
    activeAssignedAttributes: basic[],
    inactiveAssignedAttributes: basic[],
    fullAttributes: mapOfApiAttributeResponse
}

export const categoryBrickTemplate:categoryBrick = {
    name: '',
    id: '',
    active: true,
    attributes: [],
    activeAssignedAttributes: [],
    inactiveAssignedAttributes: []
}

export type literalsAssignment = {
    literalId: string,
    activeAssignment: boolean
}

type regularAttributePayloadModel = {
    attributeId: string
}
type brickDependantAttributePayloadModel = {
    attributeId: string,
    activeAssignment: boolean,
    literals: literalsAssignment[]
}
export type apiCategoryBrickDetailsPayload = (regularAttributePayloadModel|brickDependantAttributePayloadModel)[];
/* eslint-disable react-hooks/exhaustive-deps */
import './BannerSelector.scss';

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Autocomplete, TextField} from '@mui/material';
import {API_STATUSES} from 'config/api/constants';
import {bannerWithRetailer} from 'shared/models/banner.model';
import {IRootState} from 'shared/reducers';
import {fetchBannersWithRetailers} from 'shared/reducers/bannersWithRetailer';
import {useFormatMessage} from 'utils/translate';

type BannerSelectorConnectedProps = {
    bannerId: string,
    onBannerChange: (bannerId: bannerWithRetailer) => void
};

const BannerSelectorConnected = ({bannerId, onBannerChange}: BannerSelectorConnectedProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const bannersStatus = useSelector((state: IRootState) => state.bannersWithRetailer.status);
    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const [filteredBanners, setFilteredBanners] = useState<bannerWithRetailer[]>([]);

    const [selectedBanner, setSelectedBanner] = useState<bannerWithRetailer>(null);

    useEffect(() => {
        if (bannersStatus === API_STATUSES.ERROR) {
            dispatch(fetchBannersWithRetailers());
        }
    }, []);

    useEffect(() => {
        if (bannersStatus === API_STATUSES.NEW) {
            dispatch(fetchBannersWithRetailers());
        }
        if (bannersStatus === API_STATUSES.IDLE) {
            setFilteredBanners(bannersData?.filter((item) => item.countryId === countryMarket.preferredCountry));
        }
    }, [bannersStatus]);

    useEffect(() => {
        const foundBanner: bannerWithRetailer = bannersData?.find((item) => item.bannerId === bannerId);
        if (foundBanner && foundBanner.countryId === countryMarket.preferredCountry) {
            setSelectedBanner(foundBanner);
            onBannerChange(foundBanner);
        } else {
            setSelectedBanner(null);
            onBannerChange(null);
        }
    },[bannersData, bannerId, countryMarket]);

    useEffect(() => {
        setFilteredBanners(bannersData?.filter((item) => item.countryId === countryMarket.preferredCountry));
    }, [countryMarket]);

    const filterBanners = (options: bannerWithRetailer[], { inputValue }): bannerWithRetailer[] => {
        const loCased: string = inputValue ? inputValue.toLowerCase() : '';
        return options.filter((item) => item.bannerName.toLowerCase().indexOf(loCased) > -1 || item.retailerName.toLowerCase().indexOf(loCased) > -1);
    }

    const handleAutocompleteChange = (value: bannerWithRetailer | string) => {
        if (typeof value !== 'string') {
            setSelectedBanner(value);
            onBannerChange(value);
        }
    };

    return (
        <div className="bannerSelectorRoot">
            <Autocomplete
                className="bannerSelectorInput"
                options={filteredBanners}
                filterOptions={filterBanners}
                onChange={(e, value) => handleAutocompleteChange(value)}
                isOptionEqualToValue={(option, value) => option.bannerId === value.bannerId}
                getOptionLabel={(option) => `${option.bannerName}       -       ${option.retailerName} (${option.countryId})`}
                renderOption={(props, option) => <li {...props} className={selectedBanner && option.bannerId === selectedBanner.bannerId ? "bannerSelectorOption selected" : "bannerSelectorOption"} key={option['bannerId']}><span className="bannerName">{option['bannerName']}</span> <span>{option['retailerName']} ({option['countryId']})</span></li>}
                value={selectedBanner}
                defaultValue={filteredBanners.find((item) => item.bannerId === bannerId)}
                renderInput={(params) => <TextField {...params} label={translate({id: 'b.banner'})} />}
            />
            {bannersStatus === API_STATUSES.PENDING && <div className="bannerSelectorInput loading"><span>loading</span></div>}
        </div>
    );
}

export default BannerSelectorConnected;
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@mui/material';
import { useFormatMessage } from 'utils/translate';
import useDebounce from 'utils/debounce';
import {IRootState} from 'shared/reducers';
import {setUserSetting} from 'shared/reducers/userSettings';
import { PaperX } from 'components/PaperX';
import { CountrySelector } from 'components/Selectors';
import {inputSize} from 'components/Selectors/CountrySelector/CountrySelector';

const userSettingsKey: string = 'KEY_HIERARCHY_LIST_FILTERS';
type keyHierarchyFilter = {
  country: string,
  search: string
}

export const KeyHierarchyFilters = (props) => {
  const {onCountryChange, onSearchStringChange} = props;
  const dispatch = useDispatch();
  const translate = useFormatMessage();

  const savedFilters = useSelector((state: IRootState) => state.userSettings.settings);
  const [country, setCountry] = useState<string>(translate({id: 'a.any'}));
  const [searchString, setSearchString] = useState<string>('');
  const [dbncdSearchString, setDbncdSearchString] = useDebounce(searchString, 500);

  useEffect(() => {
    const storedVal: keyHierarchyFilter = savedFilters[userSettingsKey];
    if (storedVal) {
      setSearchString(storedVal.search);
      setDbncdSearchString(storedVal.search);
      setCountry(storedVal.country)
    }
  }, []);

  const handleSearchStringChange = (value: string) => {
    setSearchString(value);
    setDbncdSearchString(value);
  };

  useEffect(()=> {
    onCountryChange(country);
    saveFilters();
  }, [country]);

  useEffect(()=> {
    onSearchStringChange(dbncdSearchString);
    saveFilters();
  }, [dbncdSearchString]);

  const handleCountryChange = (value: string) => setCountry(value);

  const saveFilters = () => dispatch(setUserSetting(userSettingsKey, {
      country,
      search: dbncdSearchString
    }));

  return (
    <PaperX className="hierarchyFiltersRoot">
      <div className="_header"><FormattedMessage id='filters'/></div>
        <div className="_filters">
          <div className="_filterItem">
            <CountrySelector onCountryChange={(countryId) => handleCountryChange(countryId)} value={country} any size={inputSize.small}/>
          </div>
          <div className="_filterItem">
            <TextField color="primary" label={translate({id: 'a.search'})} value={searchString} onChange={(e) => handleSearchStringChange(e.target.value)} size="small" style={{"width": "100%"}}/>
          </div>
        </div>
    </PaperX>
  )
};
export default KeyHierarchyFilters;
import './PaperX.scss';

const PaperX = (props) => {
    return (
        <div {...props} className={`${props.className || ''} paperXRoot`}>
            <div className="paperX">
                {props.children}
            </div>
        </div>
    )
};

export default PaperX;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Button, Drawer} from '@mui/material';
import {useSnackbar} from 'notistack';
import {API_GET_PRODUCT_DETAILS, API_PRODUCT_DATA_PRODUCTS_DETAILED_RESPONSE, API_STATUSES} from 'config/api/constants';
import {productDetailsResponseModel, productPayload} from 'shared/models/product.model';
import {IRootState} from 'shared/reducers';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {ProductDetails} from 'modules/MasterData/Product/ProductDetails';
import {extendOrOverwritePromotionType} from "modules/Advertisement/FrameDescription/FrameDescriptionMVP";

export interface productDrawerProps {
    clone: boolean,
    isNewProduct: boolean,
    onClose?: Function,
    onCreate?: (createdProduct: productDetailsResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => void,
    open: boolean,
    productId: string,
}

export const extendOrOverwritePromotionTemplate = {
    extend: true,
    overwriteProductId: null
}

const ProductDrawer = (props: productDrawerProps) => {
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();
    const {clone, isNewProduct, onClose, onCreate, open, productId} = props;
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const [productPayload, setProductPayload] = useState<productPayload>(null);
    const [isProductValid, setIsProductValid] = useState<boolean>(false);
    const [originalProduct, setOriginalProduct] = useState<string>(null);

    const productAPIPost = useApi('post', null, {succMsg: 'product.succ'});
    const productAPIGet = useApi('get', null, {errMsg: 'product.err'});

    const [extendOrOverwritePromotion, setExtendOrOverwritePromotion] = useState<extendOrOverwritePromotionType>(extendOrOverwritePromotionTemplate);

    useEffect(() => {
        if (productAPIGet.status === API_STATUSES.IDLE) {
            setOriginalProduct(null);
            onCreate(productAPIGet.data, extendOrOverwritePromotion);
        } else if (productAPIGet.status === API_STATUSES.ERROR) {
            handleClose();
        }
    }, [productAPIGet.status]);

    useEffect(() => {
        if (productAPIPost.status === API_STATUSES.IDLE) {
            const newProduct: productDetailsResponseModel = productAPIPost.data;
            setOriginalProduct(null);
            onCreate(newProduct, extendOrOverwritePromotion);
        } else if (productAPIPost.status === API_STATUSES.ERROR && productAPIPost.errorCode === 409) {
            if (productAPIPost?.dataError?.id) {
                productAPIGet.call(API_GET_PRODUCT_DETAILS(productAPIPost.dataError.id, lang));
            } else {
                enqueueSnackbar(`${translate({id: 'product.duplicate'})}`, {variant: 'error', persist: false});
            }
        } else if (productAPIPost.status === API_STATUSES.ERROR) {
            enqueueSnackbar(`${translate({id: 'product.saveErr'})}`, {variant: 'error', persist: false});
        }
    }, [productAPIPost.status]);

    const handleProductChange = (product: productPayload, productValid: boolean) => {
        setIsProductValid(productValid);
        setProductPayload(productValid ? product : null);
        if (originalProduct === null && productValid) {
            setOriginalProduct(JSON.stringify(product));
        }
    };

    const handleClose = () => {
        setOriginalProduct(null);
        onClose();
    };

    const handleSaveClick = () =>
        productAPIPost.call(API_PRODUCT_DATA_PRODUCTS_DETAILED_RESPONSE(lang), productPayload);

    const handleExtendClick = () => {
        setExtendOrOverwritePromotion(extendOrOverwritePromotionTemplate);
        handleSaveClick();
    };

    const handleOverwriteClick = () => {
        setExtendOrOverwritePromotion({extend: false, overwriteProductId: productId});
        handleSaveClick();
    };

    const isProductChanged = () => isNewProduct ? true : originalProduct !== JSON.stringify(productPayload);

    const canSave = () => isProductValid && isProductChanged();

    return (
        <Drawer className="frameDescriptionDrawerRoot"
                anchor="right"
                hideBackdrop
                open={open}
        >
            <LoadingOverlay show={productAPIPost.status === API_STATUSES.PENDING || productAPIGet.status === API_STATUSES.PENDING}/>
            <ProductDetails autosetSupplier
                            clone={clone}
                            isDescribingFrame
                            isNewProduct={isNewProduct}
                            onProductChange={(newProductPayload, productValidity) => handleProductChange(newProductPayload, productValidity)}
                            productId={productId}
            />
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        {clone ?
                            <>
                                <Button color="primary" variant="contained" onClick={handleExtendClick} disabled={!canSave()}>{translate({id: 'a.add'})}</Button>
                                <Button color="primary" variant="contained" onClick={handleOverwriteClick} disabled={!canSave()}>{translate({id: 'a.overwrite'})}</Button>
                            </>
                            :
                            <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                        }
                        <ButtonClose onClick={handleClose}/>
                    </>
                }
            />
        </Drawer>
    );
};

export default ProductDrawer;
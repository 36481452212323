import {
    pageCountryThemedWeeks,
    pageCountryThemedWeeksBE,
    pageCountryThemedWeeksBEGrouped,
    pageCountryThemedWeeksBEGroupedWithRanges,
    pageCountryThemedWeeksBEPageRangesStringified,
    validateResult
} from 'shared/models/leaflet.model';
import {handyAttributes} from 'shared/handyAttributes';
import {multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {lastOfLast} from 'modules/Leaflet/HeaderData/pageValiditiesIO';

function restoreCountryThemedWeeksAttribute(countryThemedWeeks: string[]): multiselectAttributeDataModel {
    return {
        id: handyAttributes.countryThemedWeeks,
        literals: countryThemedWeeks ? countryThemedWeeks.map((item) => ({literalId: item})) : []
    }
}

export function importPageCountryThemedWeeks(pageCountryThemedWeeksBE: pageCountryThemedWeeksBE[]): pageCountryThemedWeeks[] {
    const convert = (data: pageCountryThemedWeeksBEPageRangesStringified): pageCountryThemedWeeks => {
        return {
            pageNumber: data.pageNumber,
            themedWeeks: restoreCountryThemedWeeksAttribute(data.countryThemedWeekIds),
            modified: false
        }
    };

    const groupedByCountryThemedWeeks: pageCountryThemedWeeksBEGrouped[] = [];
    if (pageCountryThemedWeeksBE) {
        pageCountryThemedWeeksBE.forEach((item) => {
            const existingCountryThemedWeeksGroupIndex = groupedByCountryThemedWeeks.findIndex((existing) => {
                for (let id in existing.countryThemedWeekIds) {
                    if (!existing.countryThemedWeekIds.includes(item.countryThemedWeekIds[id])) {
                        return false;
                    }
                }
                return true;
            });
            if (existingCountryThemedWeeksGroupIndex > -1) {
                groupedByCountryThemedWeeks[existingCountryThemedWeeksGroupIndex].pageNumber.push(item.pageNumber);
            } else {
                const newItem: pageCountryThemedWeeksBEGrouped = {pageNumber: [], countryThemedWeekIds: item.countryThemedWeekIds};
                newItem.pageNumber = [item.pageNumber];
                groupedByCountryThemedWeeks.push(newItem);
            }
        });
    }

    const withCountryThemedWeeksSorted: pageCountryThemedWeeksBEGrouped[] = groupedByCountryThemedWeeks.map((item) => {
        const newItem: pageCountryThemedWeeksBEGrouped = {...item};
        newItem.pageNumber = item.pageNumber.sort((a, b) => a - b);
        return newItem;
    });

    const withRangesGrouped: pageCountryThemedWeeksBEGroupedWithRanges[] = withCountryThemedWeeksSorted.map((item) => {
        const newItem: pageCountryThemedWeeksBEGroupedWithRanges = {pageNumber: [], countryThemedWeekIds: item.countryThemedWeekIds};
        const ranges = [];
        item.pageNumber.forEach((pgNo, idx) => {
            if (idx === 0) {
                ranges.push([pgNo]);
            } else {
                const lastest = lastOfLast(ranges);
                if (lastest && lastest + 1 === pgNo) {
                    ranges[ranges.length-1].push(pgNo);
                } else {
                    ranges.push([pgNo]);
                }
            }
        });
        newItem.pageNumber = ranges;
        return newItem;
    });

    const withRangesStringified: pageCountryThemedWeeksBEPageRangesStringified[] = withRangesGrouped.map((item) => {
        const newItem: pageCountryThemedWeeksBEPageRangesStringified = {pageNumber: '', countryThemedWeekIds: item.countryThemedWeekIds};
        let pagesString: string = '';
        item.pageNumber.forEach((pgRange) => {
            if (pgRange.length === 1) {
                pagesString += pgRange[0] + ',';
            } else {
                pagesString += pgRange[0] + '-' + pgRange[pgRange.length - 1] + ',';
            }
        });
        newItem.pageNumber = pagesString.slice(0, pagesString.length - 1);
        return newItem;
    });
    return withRangesStringified.map((item) => convert(item));
}

export function exportPageCountryThemedWeeks(pageCountryThemedWeeksUI: pageCountryThemedWeeks[]): pageCountryThemedWeeksBE[] {
    const transformed: pageCountryThemedWeeksBE[] = [];
    pageCountryThemedWeeksUI.forEach((item) => {
        const byComa = item.pageNumber.split(',');
        byComa.forEach((pgOrRange) => {
            if (pgOrRange.indexOf('-') > 0) {
                const startEnd = pgOrRange.split('-');
                for (let i = parseInt(startEnd[0]); i <= parseInt(startEnd[1]); i++) {
                    transformed.push({pageNumber: i, countryThemedWeekIds: item.themedWeeks.literals.map((literal) => literal.literalId)});
                }
            } else {
                transformed.push({pageNumber: parseInt(pgOrRange), countryThemedWeekIds: item.themedWeeks.literals.map((literal) => literal.literalId)});
            }
        });
    });
    return transformed;
}

export function validateCountryThemedWeeks(countryThemedWeeks: pageCountryThemedWeeksBE[]): validateResult {
    let duplicates = new Set([]);
    const pageNumbers = countryThemedWeeks.map((item) => item.pageNumber);
    pageNumbers.forEach((pgNo) => {
        const duplicate = pageNumbers.filter((item) => item === pgNo);
        if (duplicate.length > 1) {
            duplicates.add(pgNo);
        }
    });
    return duplicates.size ? {hasError: true, duplicatePages: Array.from(duplicates)} : {hasError: false};
}
import './BPCODescriptionDisplay.scss';

import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Tooltip} from '@mui/material';
import {simpleDescription} from 'shared/models/description.model';
import {useFormatMessage} from 'utils/translate';

interface BPCODescriptionDisplayProps {
    alt: simpleDescription[],
    main: simpleDescription[],
}

const BPCODescriptionDisplay = ({alt, main}: BPCODescriptionDisplayProps) => {
    const translate = useFormatMessage();
    const [expanded, setExpanded] = useState<boolean>(false);
    return (
        <div className={`BPCODescriptionDisplayRoot${expanded ? ' expanded' : ''}`}>
            <Tooltip title={translate({id: 'a.click2expand'})}>
                <div className="description" onClick={() => setExpanded(true)}>
                    <span className="_bold">
                        <FormattedMessage id="b.mainDescription"/>:
                    </span>
                    <br/>
                    {main.length? main.map((item) => `${item.description} (${item.language})`).join(', ') : '-'}
                </div>
            </Tooltip>
            <Tooltip title={translate({id: 'a.click2expand'})}>
                <div className="description" onClick={() => setExpanded(true)}>
                    <span className="_bold">
                        <FormattedMessage id="b.alternativeDescription"/>:
                    </span>
                    <br/>
                    {alt.length ? alt.map((item) => `${item.description} (${item.language})`).join(', ') : '-'}
                </div>
            </Tooltip>
        </div>
    );
}
export default BPCODescriptionDisplay;
import PageCountryThemedWeeksSelector from 'components/Selectors/PageCountryThemedWeeksSelector/PageCountryThemedWeeksSelector';
import {multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {pageCountryThemedWeeks} from 'shared/models/leaflet.model';

type PageCountryThemedWeeksProps = {
    numberOfPages: number,
    onCountryThemedWeeksChange: (themedWeeks: pageCountryThemedWeeks[]) => void,
    pageCountryThemedWeeks: pageCountryThemedWeeks[]
}

const PageCountryThemedWeeks = ({numberOfPages, onCountryThemedWeeksChange, pageCountryThemedWeeks}: PageCountryThemedWeeksProps) => {
    const setPageCountryThemedWeeksPage = (idx: number, page: string) => {
        const pgCountryThemedWeeks: pageCountryThemedWeeks[] = pageCountryThemedWeeks.map((item, index) => index === idx ? {...item, pageNumber: page, modified: true} : item);
        onCountryThemedWeeksChange(pgCountryThemedWeeks);
    };

    const setPageCountryThemedWeeks = (idx: number, themedWeeks: multiselectAttributeDataModel) => {
        const pgCountryThemedWeeks: pageCountryThemedWeeks[] = pageCountryThemedWeeks.map((item, index) => index === idx ? {...item, themedWeeks: themedWeeks, modified: true} : item);
        onCountryThemedWeeksChange(pgCountryThemedWeeks);
    };

    const removePageCountryThemedWeeks = (idx: number) => {
        const pgCountryThemedWeeks: pageCountryThemedWeeks[] = [...pageCountryThemedWeeks];
        pgCountryThemedWeeks.splice(idx, 1);
        onCountryThemedWeeksChange(pgCountryThemedWeeks);
    };

    return (<>
        {pageCountryThemedWeeks.map((item, idx) =>
            <div className="_formRowDouble" key={idx}>
                <div>
                    <PageCountryThemedWeeksSelector id={idx}
                                              numberOfPages={numberOfPages}
                                              onPageChange={(id, page) => setPageCountryThemedWeeksPage(id, page)}
                                              onCountryThemedWeeksChange={(id, themedWeeks) => setPageCountryThemedWeeks(id, themedWeeks)}
                                              onRemove={(id) => removePageCountryThemedWeeks(id)}
                                              pageNumber={item.pageNumber}
                                              countryThemedWeeks={item.themedWeeks || null}
                    />
                </div>
            </div>)
        }
    </>);
}

export default PageCountryThemedWeeks;
import './FrameDetails.scss';

import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Tooltip} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {BPCODetails, ProductDetails} from 'components/Displays';
import {useSlimAttributeQuery} from 'components/Attribute/attributeAPI';
import {handyAttributes} from 'shared/handyAttributes';
import {IRootState} from 'shared/reducers';
import {apiGetPromotionsForFrameResponsePromotionType} from 'shared/models/frame.model';
import {PROMOTION_TYPES} from 'shared/models/promotion.model';

interface FrameDetailsProps {
    data: apiGetPromotionsForFrameResponsePromotionType,
    isLast: boolean,
    validFrom: string,
    validTo: string
}

const FrameDetails = ({data, isLast, validFrom, validTo}: FrameDetailsProps) => {
    const {absoluteDiscount, bpco, countryThemedWeeks, currency, giveAwayBundledProducts, mpu, product, promotionObjectType, promotionalPrice, qualitySeals, regularPrice, relativeDiscount, themeSeasons} = data;
    const translate = useFormatMessage();
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const themeSeasonDictionary = useSlimAttributeQuery(handyAttributes.themeSeason, selectedLanguage, true);
    const themedWeeksDictionary = useSlimAttributeQuery(handyAttributes.countryThemedWeeks, selectedLanguage, true);
    const qualitySealDictionary = useSlimAttributeQuery(handyAttributes.qualitySeal, selectedLanguage, true);

    const themeSeasonValue = themeSeasonDictionary.data?.options &&
            themeSeasonDictionary.data.options
                .filter((item) => themeSeasons.includes(item.id))
                .map((item) => item.name)
                    
    const themedWeeksValue = themedWeeksDictionary.data?.options &&
    themedWeeksDictionary.data.options
                .filter((item) => countryThemedWeeks.includes(item.id))
                .map((item) => item.name)

    const qualitySealValue = qualitySealDictionary.data?.options &&
    qualitySealDictionary.data.options
                .filter((item) => qualitySeals.includes(item.id))
                .map((item) => item.name)
    
    return (
        <div className="frameDetailsRoot">
            <h3><FormattedMessage id="b.product"/></h3>
            {promotionObjectType === PROMOTION_TYPES.PRODUCT ? <ProductDetails product={product}/> : <BPCODetails bpco={bpco}/>}
            {(!!giveAwayBundledProducts?.length) && <div className="_bold sectionTitle">{translate({id: 'productSearch.giveAwayBundling'})}</div>}
                {giveAwayBundledProducts?.map((item) => (
                    <div key={item.id}>
                        <Tooltip
                            placement='bottom-end'
                            title={
                                <ul>
                                    {item?.characteristics.map((characteristic, i) => (
                                        <li key={i}>{characteristic?.name}: {characteristic?.value}</li>
                                    ))}
                                </ul>
                            }
                        >
                            <div className='giveAwayBundlingTooltip'>
                                <span>{item?.brand}, {item?.category}, {item?.characteristics.find(characteristic => characteristic.hasOwnProperty('value'))?.value}</span>
                            </div>
                        </Tooltip>
                     </div>
            ))}
            <div className="_bold sectionTitle"><FormattedMessage id="b.price"/></div>
            <ul>
                <li>
                    <span className="_bold"><FormattedMessage id="b.promotionalPrice"/>:&nbsp;</span>{promotionalPrice ? `${promotionalPrice} ${currency}` : '-'}
                </li>
                <li>
                    <span className="_bold"><FormattedMessage id="b.regularPrice"/>:&nbsp;</span>{regularPrice ? `${regularPrice} ${currency}` : '-'}
                </li>
                <li>
                    <span className="_bold"><FormattedMessage id="b.relativeDiscount"/>:&nbsp;</span>{relativeDiscount ? `${relativeDiscount} %` : '-'}
                </li>
                <li>
                    <span className="_bold"><FormattedMessage id="b.absoluteDiscount"/>:&nbsp;</span>{absoluteDiscount ? `${absoluteDiscount} ${currency}` : '-'}
                </li>
                <li>
                    <span className="_bold "><FormattedMessage id="b.MPU"/>:&nbsp;</span>{mpu ? `${mpu}` : '-'}
                </li>
            </ul>
            <div className="_bold sectionTitle"><FormattedMessage id="b.general"/></div>
            <ul>
                <li>
                    <span className="_bold"><FormattedMessage id="b.promotionQS"/>:&nbsp;</span>{qualitySealValue ? qualitySealValue.join(', ') : '-'}
                </li>
                <li>
                    <span className="_bold"><FormattedMessage id="b.promotionCountryWeek"/>:&nbsp;</span>{themedWeeksValue ? themedWeeksValue.join(', ') : '-'}
                </li>
                <li>
                    <span className="_bold"><FormattedMessage id="b.promotionSeasonTheme"/>:&nbsp;</span>{themeSeasonValue ? themeSeasonValue.join(', ') : '-'}
                </li>
            </ul>
            {!isLast && <div className="divider"></div>}
        </div>
    );
};

export default FrameDetails;
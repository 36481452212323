/* eslint-disable react-hooks/exhaustive-deps */
import './MediaAudiofile.scss';

import {ChangeEvent, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_MEDIA_RADIO_SPOT_AUDIOFILE} from 'config/api/constants';
import {useFormatMessage} from 'utils/translate';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {Button, IconButton} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {CloudUpload, DeleteOutline, VolumeUp} from '@mui/icons-material';

interface MediaAudiofileProps {
    country: string
    filename?: string,
    isUploadDisabled?: boolean,
    isPlayMode: boolean,
    onDelete?: () => void,
    onSelect?: (file: File) => void,
    storageFileName?: string
}

const MediaAudiofile = (props: MediaAudiofileProps) => {
    const {country, filename, isUploadDisabled, isPlayMode, onSelect, onDelete, storageFileName} = props;
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const fileInputRef = useRef(null);
    const audioFileRef = useRef(null);

    const [selectedFile, setSelectedFile] = useState<File | string>(null);
    const [audiofile, setAudiofile] = useState<string>(undefined);

    useEffect((() => {
        if (filename) setSelectedFile(filename);
    }), [filename]);

    useEffect(() => {
        if (isPlayMode && country && storageFileName) {
            handleGetAudiofile();
        }
    }, [storageFileName]);

    const uploadBtnClickHandler = () => fileInputRef.current.click();

    const handleGetAudiofile = async () => {
        try {
          const response = await axios.get(API_MEDIA_RADIO_SPOT_AUDIOFILE(country, storageFileName), {
            responseType: 'arraybuffer',
          });
          const blob = new Blob([response.data], {type: 'audio/mpeg'});
          const url = URL.createObjectURL(blob);
          setAudiofile(url);
        } catch (error) {
            enqueueSnackbar(`${translate({id: 'media.radioAudiofileFetchErr'})}`, {variant: 'error', persist: false});
        }
    };

    const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        if (selectedFile) {
            setSelectedFile(selectedFile);
            onSelect(selectedFile);
        }
        fileInputRef.current.value = null;
    }

    const handleFileDelete = () => {
        setSelectedFile(null);
        onDelete();
    }

    const getAudiofileName = () => {
        if (typeof selectedFile === 'object') return selectedFile?.name;
        else return selectedFile;
    }

    const handleCopyFileName = () => navigator.clipboard.writeText(getAudiofileName());

    return (
        <div className="mediaAudiofileRoot">
            {!isPlayMode 
            ?
                <>
                    <div className="fileUploadIcon">
                        <CloudUpload fontSize='inherit'/>
                    </div>
                    <p className={`fileUploadText ${isUploadDisabled ? 'fileUploadTextDisabled' : ''}`}>
                        {translate({id: 'media.chooseRadioSpotFile'})}
                    </p>
                    <Button
                        onClick={uploadBtnClickHandler}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!!isUploadDisabled}
                    >
                        {translate({id: 'fileUpload.chooseFiles'})}
                    </Button>
                    {/* HIDDEN INPUT */}
                    <input
                        type="file"
                        accept=".mp3, .wav"
                        multiple
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileSelect}
                    />
                </>
            :
                <>
                    <div className="fileUploadIcon">
                        <VolumeUp fontSize='inherit'/>
                    </div>
                    <p className="fileUploadText">
                        {translate({id: 'media.radioSpotPlayAudio'})}
                    </p>
                    <audio controls src={audiofile} onClick={handleGetAudiofile} ref={audioFileRef}/>
                </>
            }
            <div className="fileUploadedName">
                {translate({id: 'fileUpload.selectedFile'})}
                <div className="fileList">{selectedFile ? getAudiofileName() : '-'}
                    {selectedFile &&
                        <>
                            <IconButton onClick={handleCopyFileName}>
                                <Tooltip title={translate({id: 'media.copyFileName'})}>
                                    <CopyAllIcon color="secondary" fontSize='small'/>
                                </Tooltip>
                            </IconButton>
                            <IconButton onClick={handleFileDelete} disabled={!!audiofile}>
                                <Tooltip title={translate({id: 'media.deleteFile'})}>
                                    <DeleteOutline color={!!audiofile ? 'disabled' : 'secondary'} fontSize='small'/>
                                </Tooltip>
                            </IconButton>
                        </>        
                    }
                </div>
            </div>
        </div>
   )
}
export default MediaAudiofile;

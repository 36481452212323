import {translations} from './dictionaries.model';
import {payloadTaxItem, taxItemWithCountry} from './taxes.model';

export enum categoryType {
    SEGMENT = 'SEGMENT',
    FAMILY = 'FAMILY',
    CLASS = 'CLASS',
    BRICK = 'BRICK'
}

export const categoryOrder:categoryType[] = [categoryType.SEGMENT, categoryType.FAMILY, categoryType.CLASS, categoryType.BRICK];

export type categorySelectorOption = {
    id: string,
    name: string,
    type: categoryType
}

export type categoryTableItem = {
    sName: string,
    sId: string,
    fName?: string,
    fId?: string,
    cName?: string,
    cId?: string,
    bName?: string,
    bId?: string,
}

export type categoryTableItemWithSearchString = categoryTableItem & {searchStr: string};

export type categoryTreeItem = {
    id: string,
    name: string,
    items?: categoryTreeItem[]
}

export type categoryHierarchyResponseModel = categoryTreeItem[];

export type categoryBEModel = {
    active: boolean,
    alternativeCategoryId?: string,
    alternativeCategoryName?: string,
    id?: string,
    parentCategoryId?: string,
    parentCategoryName?: string,
    translations: translations,
    type: categoryType,
}

export type categoryUIModel = {
    active: boolean,
    alternativeCategory?: categorySelectorOption,
    id?: string,
    parentCategory?: categorySelectorOption
    translations: any[],
    type: categoryType,
}

export interface brickUIModel extends categoryUIModel {
    taxes: taxItemWithCountry[]
}

export interface brickPayloadModel extends categoryBEModel {
    taxes: payloadTaxItem[]
}
// @TODO 3 revise below todos after implementation of MPM-3251!
// @TODO verify if still true after replacing api/product-slim-data/categories with api/product-slim-data/categories/search
// if not, then categorySearchResponseObject fields are no more optional
// @TODO 2 update: might be the case that we still need old API for cases where you want to actually find any category so two types would be needed....

// shape of response object depends on its level
// top level will always come with its name and id
// lower levels come with just names
// ex:
// brick = {
//     brickId: string,
//     brickName: string,
//     className: string,
//     familyName: string,
//     segmentName: string,
// }
// class = {
//     classId: string,
//     className: string,
//     familyName: string,
//     segmentName: string,
// }
// family = {
//     familyId: string,
//     familyName: string,
//     segmentName: string,
// }
// segment = {
//     segmentId: string,
//     segmentName: string,
// }
export type categorySearchResponseObject = {
    brickId?: string,
    brickName?: string,
    classId?: string,
    className?: string,
    familyId?: string
    familyName?: string,
    segmentId?: string,
    segmentName: string
}
export type categorySearchResponseObjectUI = categorySearchResponseObject & {
    categoryLabel: string
}
export type categorySearchResponseModel = categorySearchResponseObjectUI[]; //@TODO to be removed with obsolete CategorySearchBPCO_2bRemoved component after implementation of MPM-3251

/* eslint-disable react-hooks/exhaustive-deps */

import {useState} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Button, Checkbox, FormControlLabel} from '@mui/material';
import {paths} from 'paths';
import {useFormatMessage} from 'utils/translate';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import Footer from 'components/Footer';
import {ButtonClose, ButtonNew} from 'components/Buttons';
import ProductsListSearchResults from './ProductsListSearchResults';
import ProductsListFilterResults from './ProductsListFilterResults';
import ProductExport from 'components/ProductExport/ProductExport';
import ImportGtins from 'components/ImportGtins/ImportGtins';

export enum viewModes {
    filters = 'filters',
    search = 'search'
}

const productsListViewModeLocalStorageKey: string = 'productsListView';

//typesafe storing
const storeSafely = (mode: viewModes) => storeInLocalStorage(productsListViewModeLocalStorageKey, mode);

const ProductsList = ({history}) => {
    const translate = useFormatMessage();

    const [viewMode, setViewMode] = useState<viewModes>(viewModes[getFromLocalStorage(productsListViewModeLocalStorageKey)] || viewModes.search);
    const goToDetails = (productId: string) => history.push(`${paths.productDetails}?id=${productId}`, {source: paths.productsList});
    const toggleViewMode = () => {
        const mode2set = viewMode === viewModes.search ? viewModes.filters : viewModes.search;
        storeSafely(mode2set);
        setViewMode(mode2set);
    };

    return (
        <div className="viewRoot productsListRoot">
            <div className="viewport">
                {viewMode === viewModes.search ?
                    <ProductsListSearchResults onRowClick={goToDetails}/>
                    :
                    <ProductsListFilterResults onRowClick={goToDetails}/>
                }
            </div>
            <Footer
                actionsLeft={
                    <>
                        <FormControlLabel
                            control={<Checkbox checked={viewMode === viewModes.filters} color="primary"
                                               onChange={toggleViewMode}/>}
                                               label={translate({id: 'filters'})}
                        />
                    </>
                }
                actionsRight={
                    <>
                        {viewMode !== viewModes.search &&
                                <>
                                    <ImportGtins/>
                                    <ProductExport/>
                                </>}
                        <Link to={paths.productBulkChange}>
                            <Button variant="outlined">
                                <FormattedMessage id="product.bulk"/>
                            </Button>
                        </Link>
                        <Link to={paths.productBulkDescription}>
                            <Button variant="outlined">
                                <FormattedMessage id="product.bulkDescription"/>
                            </Button>
                        </Link>
                        <Link to={paths.productBulkSupplier}>
                            <Button variant="outlined">
                                <FormattedMessage id="product.bulkSupplier"/>
                            </Button>
                        </Link>
                        <Link to={paths.productDetailsNew + '?new=true'}>
                            <ButtonNew/>
                        </Link>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default ProductsList;

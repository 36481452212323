/* eslint-disable react-hooks/exhaustive-deps */

import {useRef, useState} from 'react';
import {GetContextMenuItemsParams} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import axios from 'axios';
import {API_GET_FILTERED_PRODUCT_DESCRIPTIONS} from 'config/api/constants';
import {productDescriptionResponseModel} from 'shared/models/productDescription.model';
import {descriptionFilters} from 'shared/models/description.model';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {PaperX} from 'components/PaperX';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {LoadingOverlay} from 'components/LoadingOverlay';
import DescriptionsFilter from 'components/DescriptionFilter/DescriptionsFilter';

const storageKey = 'filters_productDescriptionsFilter';
const storageKey4Brick = 'filters_productDescriptionsFilter_brick';

interface ProductDescriptionListProps {
    forceReloadCounter?: number,
    getContextMenu?: (rowData: GetContextMenuItemsParams) => void,
    hasContextMenu?: boolean,
    onRowClick: (descriptionId: string) => void
}

const ProductDescriptionList = ({forceReloadCounter, getContextMenu, hasContextMenu, onRowClick}: ProductDescriptionListProps) => {
    const cancelToken = useRef(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [productDescriptionData, setProductDescriptionData] = useState<productDescriptionResponseModel[]>([]);

    const getData = (filters: descriptionFilters) => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current();
        setProductDescriptionData([]);
        axios.get<productDescriptionResponseModel[]>(API_GET_FILTERED_PRODUCT_DESCRIPTIONS, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setProductDescriptionData(response.data);
                }
            })
            .catch((e) => {
                if(!e?.__CANCEL__) {
                    console.log(e);
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleFiltersChange = (filters: descriptionFilters) => {
        if (!filters) return;
        if (Object.keys(filters).length > 2 && filters.marketId) {
            getData(filters);
        }
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <PaperX>
                <DescriptionsFilter forceReloadCounter={forceReloadCounter}
                                    onFilterChange={(filters) => handleFiltersChange(filters)}
                                    storageKey={storageKey}
                                    storageKey4Brick={storageKey4Brick}
                />
            </PaperX>
            <PaperX className="_fullHeight _fullWidth _fullTable">
                <ProductDescriptionListTable data={productDescriptionData}
                                             getContextMenu={getContextMenu}
                                             hasContextMenu={hasContextMenu}
                                             onRowClick={(descriptionId) => onRowClick(descriptionId)}/>
            </PaperX>
        </>
    )
};

export default ProductDescriptionList;

interface ProductDescriptionListTableProps {
    data: productDescriptionResponseModel[],
    getContextMenu?: (rowData: GetContextMenuItemsParams) => void,
    hasContextMenu?: boolean,
    onRowClick: (descriptionId: string) => void,
}

export const ProductDescriptionListTable = ({data, getContextMenu, hasContextMenu, onRowClick}: ProductDescriptionListTableProps) => {
    const translate = useFormatMessage();

    const columnDefs: ColDef[] = [
        { field: 'mainDescriptions', headerName: translate({id: 'b.mainDescription'}), width:400, valueGetter: (params) => {
                const mainDescriptions:string[] = [];
                for (let index in params.data.mainDescriptions){
                    mainDescriptions.push(`${params.data.mainDescriptions[index].description} (${params.data.mainDescriptions[index].language})`)
                }
                return mainDescriptions.join(', ');
            }
        },
        { field: 'alternativeDescriptions', headerName: translate({id: 'b.alternativeDescription'}), width: 400, valueGetter: (params) => {
                const altDescriptions:string[] = [];
                for (let index in params.data.alternativeDescriptions){
                    altDescriptions.push(`${params.data.alternativeDescriptions[index].description} (${params.data.alternativeDescriptions[index].language})`)
                }
                return altDescriptions.join(', ');
            }
        },
        { field: 'brickName', headerName: translate({id: 'b.category'}), flex: 1, lockVisible: true}
    ];

    return (
        <AgTable
            rowData={data}
            defaultColDef={{
                resizable: true,
                sortable: true
            }}
            columnDefs={columnDefs}
            onRowClicked={(params) => onRowClick(params.data.id)}
            getContextMenuItems={(rowData) => getContextMenu(rowData)}
            suppressContextMenu={!hasContextMenu}
            localStorageColumnsKey={localStorageColumnsKeys.productDescriptionList}
        />
    );
};
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {v4 as uuid} from 'uuid';
import {Button, IconButton} from '@mui/material';
import {DeleteOutline} from '@mui/icons-material';
import {API_BPCO_DESCRIPTIONS, API_STATUSES} from 'config/api/constants';
import {simpleDescriptionUi} from 'shared/models/description.model';
import {BPCODescriptionUI, BPCODescriptionPayload, BPCODescriptionSingleResponseModel} from 'shared/models/BPCO.model';
import {IRootState} from 'shared/reducers';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {PaperX} from 'components/PaperX';
import {MarketDisplay} from 'components/Displays';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {transformBPCODescriptionForBE, transformBPCODescriptionForUI} from '../BPCOio';
import DescriptionInputX from 'modules/MasterData/ProductDescription/DescriptionInputX';

const buildBPCODescriptionTemplate = (marketId: string): BPCODescriptionUI => {
    return {
        alternativeDescriptions: [],
        mainDescriptions: [
            {
                description: '',
                language: '',
                UIId: uuid()
            }
        ],
        marketId
    };
}

function validateDescription(descr: BPCODescriptionUI): boolean {
    const invalidMain = descr.mainDescriptions.find((item) => !item.language || !item.description);
    const invalidAlt = descr.alternativeDescriptions.find((item) => !item.language || !item.description);
    if (invalidMain || invalidAlt) return false;
    const usedLangs = descr.mainDescriptions.map((item) => item.language);
    const uniqLangs = new Set(usedLangs);
    return usedLangs.length === uniqLangs.size;
}

interface BPCODescriptionProps {
    onBPCODescriptionChange: (description: BPCODescriptionPayload, isDescriptionValid: boolean) => void,
    descriptionId?: string
}

const BPCODescription = ({onBPCODescriptionChange, descriptionId}: BPCODescriptionProps) => {
    const userProfile = useSelector((state: IRootState) => state.userProfile);
    const [description, setDescription] = useState<BPCODescriptionUI>(buildBPCODescriptionTemplate(userProfile.countryMarket.market));
    const BPCODescriptionAPIGet = useApi('get', null, {errMsg: 'bpco.err2'});
   
    useEffect(() => {
        onBPCODescriptionChange(transformBPCODescriptionForBE(description), validateDescription(description));
    }, [description]);

    useEffect(() => {
        if (descriptionId) BPCODescriptionAPIGet.call(`${API_BPCO_DESCRIPTIONS}/${descriptionId}`);
    }, [descriptionId]);

    useEffect(() => {
        if (BPCODescriptionAPIGet.status === API_STATUSES.IDLE) {
            const resp: BPCODescriptionSingleResponseModel = BPCODescriptionAPIGet.data;
            setDescription(transformBPCODescriptionForUI(resp));
        }
    }, [BPCODescriptionAPIGet.status]);

    const handleDescriptionChange = (index: number, newDescription: simpleDescriptionUi, altOrMain: string) => {
        const newDescr = {...description};
        newDescr[altOrMain][index] = newDescription;
        setDescription(newDescr)
    };

    const handleDelete = (index: number, altOrMain: string) => {
        const newDescription = {...description};
        newDescription[altOrMain].splice(index, 1);
        setDescription(newDescription)
    };

    const checkIfLanguageIsAlreadyInUse = (lang: string): boolean => {
        if (lang) {
            return description.mainDescriptions
                .map((item) => item.language)
                .filter((l) => l === lang)
                .length > 1;
        }
        return false;
    };

    const buildMainDescriptions = (): JSX.Element[] => description.mainDescriptions.map((item, idx) =>
        <div className="_formRowDouble descriptionContainer" key={item.UIId}>
            <DescriptionInputX error={checkIfLanguageIsAlreadyInUse(item.language)}
                               index={idx}
                               onChange={(key,value) => handleDescriptionChange(key, value, 'mainDescriptions')}
                               productDescription={item}
            />
            {
                description.mainDescriptions.length > 1 && 
                <div className="deleteBtn">
                    <IconButton onClick={() => handleDelete(idx, 'mainDescriptions')}>
                        <DeleteOutline color="secondary" fontSize='small'/>
                    </IconButton>
                </div>
            }
        </div>
    );

    const buildAltDescriptions = (): JSX.Element[] => description.alternativeDescriptions.map((item, idx) =>
        <div className="_formRowDouble descriptionContainer" key={item.UIId}>
            <DescriptionInputX error={false}
                               index={idx}
                               onChange={(key,value) => handleDescriptionChange(key, value, 'alternativeDescriptions')}
                               productDescription={item}
            />
            <div className="deleteBtn">
                <IconButton onClick={() => handleDelete(idx, 'alternativeDescriptions')}>
                    <DeleteOutline color="secondary" fontSize='small'/>
                </IconButton>
            </div>
        </div>
    );

    const addDescription = (altOrMain: string) => {
        const newDescription = {...description};
        newDescription[altOrMain].push({language: '', description: '', UIId: uuid()});
        setDescription(newDescription);
    };

    return (
        <>
            <LoadingOverlay show={BPCODescriptionAPIGet.status === API_STATUSES.PENDING}/>
            <PaperX className="_fullWidth _fullHeight _scrollY">
                <div className="_header">
                    <FormattedMessage id="b.bpcoDescription"/>
                </div>
                <div className="_formRow">
                    <MarketDisplay marketId={description.marketId}/>
                </div>
                <div>
                    <div>
                        <FormattedMessage id="b.mainDescription"/>
                    </div>
                    {buildMainDescriptions()}
                    <div className="addBtn">
                        <Button variant="outlined" color="primary" onClick={() => addDescription('mainDescriptions')}>
                            <FormattedMessage id='b.addMainDescription'/>
                        </Button>
                    </div>
                </div>
                <div>
                    <div className="alternativeDescriptionTitle">
                        <FormattedMessage id='b.alternativeDescription'/>
                    </div>
                    {buildAltDescriptions()}
                    <div className="addBtn">
                        <Button variant="outlined" color="primary" onClick={() => addDescription('alternativeDescriptions')}>
                            <FormattedMessage id='b.addAlternativeDescription'/>
                        </Button>
                    </div>
                </div>
            </PaperX>
        </>
    )
};
export default BPCODescription;
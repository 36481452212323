/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, TextField} from '@mui/material';
import {API_GET_BRAND} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {brand} from 'shared/models/brand.model';
import {supplierSearchResult} from 'shared/models/supplier.model';
import {basic} from 'shared/models/_common.model';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {PaperX} from 'components/PaperX';
import MarketDisplay from 'components/Displays/MarketDisplay';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {LoadingOverlay} from 'components/LoadingOverlay';
import SupplierSearchPanel from './SupplierSearchPanel';

const buildBrandTemplate = (marketId: string): brand => ({
    active: true,
    id: '',
    marketId,
    name: '',
    structured: false,
    suppliers: []
});

interface BrandDetailsProps {
    brandId?: string,
    onBrandChange: (brand: brand) => void
}

const BrandDetails = ({brandId, onBrandChange}: BrandDetailsProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brand, setBrand] = useState<brand>(null);
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    useEffect(() => {
        if (brandId) {
            getBrandData(brandId);
        } else if (countryMarket.market) {
            setBrand(buildBrandTemplate(countryMarket.market));
        }
    }, []);

    useEffect(() => {
        if (!brandId) {
            setBrand(buildBrandTemplate(countryMarket.market));
        }
    }, [countryMarket])

    useEffect(() => {
        onBrandChange(brand)
    }, [brand]);

    const getBrandData = (brandId: string) => {
        setIsLoading(true);
        axios.get<brand>(API_GET_BRAND(brandId))
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setBrand(resp.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'brand.err'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'brand.err'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false));
    };

    const updateBrand = (key: string, value: string | boolean) => {
        const newBrand = {...brand};
        newBrand[key] = value;
        setBrand(newBrand);
    };

    const handleSupplierAdd = (supplier: supplierSearchResult) => {
       if (!brand.suppliers.find((item) => item.id === supplier.id)) {
           const newBrand: brand = {...brand};
           const newSupplier: basic = {
               id: supplier.id,
               name: supplier.name
           };
           newBrand.suppliers.push(newSupplier);
           setBrand(newBrand);
       }
    };

    const onSupplierRemove = (supplierId: string) => {
        const newBrand: brand = {...brand};
        newBrand.suppliers = brand.suppliers.filter((item) => item.id !== supplierId)
        setBrand(newBrand);
    };

    return (
      <>
          <LoadingOverlay show={isLoading}/>
          <div className="_directionCol _fullHeight _fullWidth">
              <PaperX className="weldBottom">
                  <div className="_header">
                      <FormattedMessage id="b.brand"/>
                  </div>
                  <MarketDisplay marketId={brand?.marketId || ''}/>
                  <div className="_formRow">
                      <TextField variant="outlined"
                                 label={translate({id: 'b.name'})}
                                 value={brand ? brand.name : ''}
                                 autoFocus={brandId ? false : true}
                                 required
                                 onChange={(e) => updateBrand('name', e.target.value)}
                      />
                  </div>
                  <div className="_formRowDouble">
                      <ActiveCheckbox value={brand ? brand.active : true} onChange={updateBrand}/>
                  </div>
                  <div className="_bold">
                      <FormattedMessage id="b.suppliers"/>:
                  </div>
              </PaperX>
              <PaperX className="weldTop _scrollY _fullHeight">
                  <ul>
                      {brand?.suppliers.map((item) => <li key={item.id}>{item.name}<Button onClick={() => onSupplierRemove(item.id)}>
                          <FormattedMessage id="a.remove"/>
                      </Button></li>)}
                  </ul>
              </PaperX>
          </div>
          <div className="_directionCol _fullHeight _fullWidth">
              <SupplierSearchPanel marketId={brand?.marketId} onSupplierAdd={(selectedSupplier) => handleSupplierAdd(selectedSupplier)}/>
          </div>
      </>
    );
};
export default BrandDetails;
import PagePromotionTypesSelector from 'components/Selectors/PagePromotionTypesSelector/PagePromotionTypesSelector';
import {multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {pagePromotionTypes} from 'shared/models/leaflet.model';

type PagePromotionTypesProps = {
    numberOfPages: number,
    onPromotionTypesChange: (promotionType: pagePromotionTypes[]) => void
    pagePromotionTypes: pagePromotionTypes[]
}

const PagePromotionTypes = ({numberOfPages, onPromotionTypesChange, pagePromotionTypes}: PagePromotionTypesProps) => {
    const setPagePromotionTypesPage = (idx: number, page: string) => {
        const pgPromotionTypes: pagePromotionTypes[] = pagePromotionTypes.map((item, index) => index === idx ? {...item, pageNumber: page, modified: true} : item);
        onPromotionTypesChange(pgPromotionTypes);
    };

    const setPagePromotionTypes = (idx: number, promotionTypes: multiselectAttributeDataModel) => {
        const pgPromotionTypes: pagePromotionTypes[] = pagePromotionTypes.map((item, index) => index === idx ? {...item, promotionTypes: promotionTypes, modified: true} : item);
        onPromotionTypesChange(pgPromotionTypes);
    };

    const removePagePromotionTypes = (idx: number) => {
        const pgPromotionTypes: pagePromotionTypes[] = [...pagePromotionTypes];
        pgPromotionTypes.splice(idx, 1);
        onPromotionTypesChange(pgPromotionTypes);
    };

    return (<>
        {pagePromotionTypes.map((item, idx) =>
            <div className="_formRowDouble" key={idx}>
                <div>
                    <PagePromotionTypesSelector id={idx}
                                              numberOfPages={numberOfPages}
                                              onPageChange={(id, page) => setPagePromotionTypesPage(id, page)}
                                              onPromotionTypesChange={(id, promotionTypes) => setPagePromotionTypes(id, promotionTypes)}
                                              onRemove={(id) => removePagePromotionTypes(id)}
                                              pageNumber={item.pageNumber}
                                              promotionTypes={item.promotionTypes || null}
                    />
                </div>
            </div>)
        }
    </>);
}

export default PagePromotionTypes;
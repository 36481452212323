import {Button} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface ButtonExportProps {
    onClick?: Function
    disabled?: boolean
}

const ButtonExport = ({onClick, disabled}: ButtonExportProps) => {
    const translate = useFormatMessage();
    const handleClick = () => onClick ? onClick() : null;
    return (
        <Button color="secondary"
                variant="outlined"
                startIcon={<FileDownloadIcon />}
                onClick={handleClick}
                disabled={disabled}>
            {translate({id: 'a.export'})}
        </Button>
    );
};

export default ButtonExport;

import {Checkbox, FormControlLabel} from '@mui/material';
import {useFormatMessage} from 'utils/translate';

interface ToggleTemplateProps {
    onChange: (value: boolean) => void,
    value: boolean
}

const ToggleTemplates = ({onChange, value}: ToggleTemplateProps) => {
    const translate = useFormatMessage();
    return (
        <FormControlLabel control={
                            <Checkbox checked={value}
                                      color="primary"
                                      onChange={(e) => onChange(e.target.checked)}
                            />
                          }
                          label={translate({id: 'product.showTemplates'})}
        />
    );
};

export default ToggleTemplates;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import {commonAttributeDataModel} from 'shared/models/attributeComponent.model';
import {attrTypes} from 'modules/Dictionaries/Attributes/Attribute';
import {states} from 'shared/models/states.model';
import {slimAttributeResponseModel} from 'shared/models/attribute.model';
import {useSlimAttributeQuery, isResponseValid} from './attributeAPI';
import AttributeFreetext from './AttributeFreetext';
import AttributeRange from './AttributeRange';
import AttributeNumber from './AttributeNumber';
import AttributeEnum from './AttributeEnum';
import AttributeMultiselect from './AttributeMultiselect';
import {AttributeError, AttributeLoading} from './AttributeHelpers';
import AttributeMultiselectText from './AttributeMultiselectText';


type AttributeProps = {
    id?: string,
    data: commonAttributeDataModel,
    newData?: slimAttributeResponseModel
    disableAutoselect?: boolean,
    onChange?: (attributeKey: string, attributeObject: commonAttributeDataModel) => void,
    required?: boolean,
    multiselect?: boolean,
    readonly?: boolean,
    textmode?: boolean,
    disabled?: boolean
}

export const isAttributeValid = (characteristic): boolean => {
    if (characteristic) {
        if (characteristic.unitId) {
            // range or numeric attribute
            if (((characteristic.valueFrom || characteristic.valueFrom === 0) && (characteristic.valueTo || characteristic.valueTo === 0))  || characteristic.value) {
                return true;
            }
        } else if (characteristic.literalId) {
            return true;
        }
        else if (characteristic.text) {
            return true;
        }
    }
    return false;
};

const Attribute = (props:AttributeProps) => {
    const {id, disableAutoselect, disabled, newData, data, onChange, required, multiselect, readonly, textmode} = props;

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const slimAttributeQuery = useSlimAttributeQuery(id, selectedLanguage, (id !== null && id !== undefined));
    const [componentState, setComponentState] = useState<states>(states.loading);
    const [attributeModel, setAttributeModel] = useState(null);

    useEffect(() => {
        if (newData) {
            setComponentState(states.ready);
            setAttributeModel(newData);
        }
    },[newData])

    useEffect(() => {
        if (slimAttributeQuery.isLoading) {
            setComponentState(states.loading);
        } else if (slimAttributeQuery.isError) {
            setComponentState(states.error);
        } else if (slimAttributeQuery.isSuccess){
            if (slimAttributeQuery.data){
                if (isResponseValid(slimAttributeQuery.data)) {
                    setComponentState(states.ready);
                    setAttributeModel(slimAttributeQuery.data);
                }
            }
        }
    }, [slimAttributeQuery])

    const handleClear = (attributeId) => onChange(attributeId, null);

    const renderByType = (model) => {
        if (model === null) {
            return <AttributeError/>;
        }
        switch (model.type) {
            case attrTypes.FREETEXT:
                return <AttributeFreetext model={model} data={data} onChange={onChange} onClear={handleClear} required={required} disabled={disabled}/>;
            case attrTypes.RANGE:
                return <AttributeRange model={model} data={data} onChange={onChange} onClear={handleClear} required={required} disabled={disabled}/>;
            case attrTypes.NUMERICAL:
                return <AttributeNumber model={model} data={data} onChange={onChange} onClear={handleClear} required={required} disabled={disabled}/>;
            case attrTypes.ENUMERATION:
            case attrTypes.BRICK_DEPENDENT_ENUMERATION:
                if (multiselect) {
                    if (textmode) {
                        return <AttributeMultiselectText model={model} data={data} /*disabled={disabled}*//>;
                    }
                    return <AttributeMultiselect model={model} data={data} onChange={onChange} onClear={handleClear} required={required} readonly={readonly} disableAutoselect={disableAutoselect} disabled={disabled}/>;
                }
                return <AttributeEnum model={model} data={data} onChange={onChange} onClear={handleClear} required={required} disabled={disabled}/>;
            default:
                return <AttributeError/>;
        }
    };

    const renderByState = (state) => {
        switch (state) {
            case states.loading:
                return <AttributeLoading textmode={textmode}/>;
            case states.error:
                return <AttributeError textmode={textmode}/>;
            case states.ready:
                return renderByType(attributeModel);
            default:
                return <AttributeError textmode={textmode}/>;
        }
    };

    return renderByState(componentState);
};
export default Attribute;
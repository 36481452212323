/* eslint-disable react-hooks/exhaustive-deps */

import {ReactElement, useEffect, useMemo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from '@mui/material';
import {API_MASTER_DATA_RETAILERS, API_STATUSES} from 'config/api/constants';
import {retailer} from 'shared/models/retailer.model';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import ButtonClose from 'components/Buttons/ButtonClose';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {KeyAccountSelector} from 'components/Selectors';
import {ConfirmDialog} from 'components/ConfirmDialog';

interface HierarchyDialogRetailerProps {
    open: boolean,
    onClose: (reload?: boolean) => void,
    retailer: retailer
}

export const retailerTemplate:retailer = {
    id: '',
    name: '',
    active: true,
    keyAccountId: ''
};

export const HierarchyDialogRetailer = (props:HierarchyDialogRetailerProps) => {
    const translate = useFormatMessage();
    const retailerAPIPost = useApi('post', null, {errMsg: 'retailer.saveErr', succMsg: 'retailer.succ', failureMsg: 'retailer.duplicate'});
    const retailerAPIPut = useApi('put', null, {errMsg: 'retailer.saveErr', succMsg: 'retailer.succ', failureMsg: 'retailer.duplicate'});
    const {open, onClose, retailer} = props;

    const [retailerLocal, setRetailerLocal] = useState<retailer>(retailerTemplate);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

    const buildConfirmationMsg = (): ReactElement => <p>{`${translate({id: 'b.retailer'})} ${translate({id: 'hierarchy.confirmation1'})}`}
        <br/>
        <br/>
        {`${translate({id: 'hierarchy.confirmation2'})}`}
        <br/>
        {`${translate({id: 'b.banners'})}`},
        <br/>
        {`${translate({id: 'b.branches'})}`}
    </p>;
    const confMsg: ReactElement = useMemo(() => buildConfirmationMsg(), []);

    useEffect(() => {
        if (retailer) {
            setRetailerLocal(retailer);
        }
    }, [retailer]);

    useEffect(() => {
        if (retailerAPIPost.status === API_STATUSES.IDLE) {
            onClose(true);
        }
    }, [retailerAPIPost.status]);

    useEffect(() => {
        if (retailerAPIPut.status === API_STATUSES.IDLE) {
            onClose(true);
        }
    }, [retailerAPIPut.status]);

    const handleRetailerPropertyChange = (key: string, value: string | boolean) => {
        const newRetailerLocal: retailer = {...retailerLocal};
        newRetailerLocal[key] = value;
        setRetailerLocal(newRetailerLocal);
    };

    const cantSave = (): boolean => !retailerLocal.name || !retailerLocal.keyAccountId;

    const showLoading = (): boolean => retailerAPIPut.status === API_STATUSES.PENDING || retailerAPIPost.status === API_STATUSES.PENDING;

    const handleSaveClick = () => {
        if (retailerLocal.id) {
            if (retailerLocal.active) {
                retailerAPIPut.call(`${API_MASTER_DATA_RETAILERS}/${retailerLocal.id}`, retailerLocal);
            } else {
                setConfirmationDialogOpen(true);
            }
        } else {
            retailerAPIPost.call(API_MASTER_DATA_RETAILERS, retailerLocal);
        }
    };

    const handleSaveConfirm = () => {
        setConfirmationDialogOpen(false);
        retailerAPIPut.call(`${API_MASTER_DATA_RETAILERS}/${retailerLocal.id}`, retailerLocal);
    };

    return (
        <>
            <Dialog open={open} fullWidth onClose={() => {if (!showLoading()) onClose();}}>
                <LoadingOverlay show={showLoading()}/>
                <DialogTitle>
                    <FormattedMessage id={retailerLocal.id ? 'b.retailer' : 'hierarchy.createRetailer'}/>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className="_formRow">
                        <TextField
                            required
                            className="_fullWidth"
                            label={translate({id: 'b.name'})}
                            value={retailerLocal.name}
                            onChange={(e) => handleRetailerPropertyChange('name', e.target.value)}
                            autoFocus
                        />
                    </div>
                    <div className="_formRow">
                        <KeyAccountSelector keyAccountId={retailerLocal.keyAccountId}
                                            required
                                            onKeyAccountChange={(keyAccount) => handleRetailerPropertyChange('keyAccountId', keyAccount ? keyAccount.id : '')}/>
                    </div>
                    <ActiveCheckbox value={retailerLocal.active} onChange={(key, value) => handleRetailerPropertyChange(key, value)}/>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                    <ButtonClose onClick={() => onClose()}/>
                </DialogActions>
            </Dialog>
            <ConfirmDialog cancelLabelId="a.cancel"
                           confirmLabelId="a.save"
                           onCancel={() => setConfirmationDialogOpen(false)}
                           onConfirm={handleSaveConfirm}
                           open={confirmationDialogOpen}
                           message={confMsg}/>
        </>
    );
}
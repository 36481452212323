/* eslint-disable react-hooks/exhaustive-deps */
import './CategoryAttributes.scss';

import {ReactElement, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {
    API_ATTRIBUTE,
    API_ATTRIBUTES_SEARCH,
    API_BRICK_DEPENDENT_ATTRIBUTE,
    API_CATEGORY_BRICK_DETAILS,
    API_GET_CATEGORY_BRICK_DETAILS
} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {apiCategoryBrickDetailsPayload, categoryBrickUI, mapOfApiAttributeResponse} from 'shared/models/categoryBrick.model';
import {attributeResponseModel} from 'shared/models/attribute.model';
import {IRootState} from 'shared/reducers';
import {getId, getURLParam} from 'utils/routing';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Search from 'components/Search/Search';
import {attrTypes} from 'modules/Dictionaries/Attributes/Attribute';
import CategoryAttributeCard from './CategoryAttributeCard';
import BrickAttributeDialog from './BrickAttributeDialog';
import {canSave, transformCategoryResponse} from './CategoryAttributesHelpers';
import {literalsAssignment} from 'shared/models/categoryBrick.model'

interface CategoryAttributesProps {
    history?: any // @TODO find out what type is this,
    externalCategoryId?: string
}

const CategoryAttributes = ({history, externalCategoryId}: CategoryAttributesProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const [categoryId, setCategoryId] = useState<string>(null);
    const [returnPath, setReturnPath] = useState<string>('');
    const [categoryUI, setCategoryUI] = useState<categoryBrickUI>(null);

    const [brickAttributeDialogOpen, setBrickAttributeDialogOpen] = useState<boolean>(false);
    const [selectedBrickAttribute, setSelectedBrickAttribute] = useState<attributeResponseModel>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [saveDisabledDueToReadErr, setSaveDisabledDueToReadErr] = useState<boolean>(false);
    const [currentActiveAssignedAttributes, setCurrentActiveAssignedAttributes] = useState<string[]>(null);

    const getCategory = (categoryId: string) => {
        // step 2 - get category details
        setIsLoading(true);
        axios.get(API_GET_CATEGORY_BRICK_DETAILS(categoryId, selectedLanguage))
            .then((response) => {
                if (responseValidation(response.data)) {
                    setIsLoading(false);
                    setCategoryUI(transformCategoryResponse(response.data));
                } else {
                    setIsLoading(false);
                    enqueueSnackbar(`${translate({id: 'category.err'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
                enqueueSnackbar(`${translate({id: 'category.err'})}`, {variant: 'error', persist: false});
            });
    };

    const getBrickAttribute = (attributeId: string) => {
        axios.get(API_BRICK_DEPENDENT_ATTRIBUTE(categoryId, attributeId, selectedLanguage))
            .then((response) => {
                if (responseValidation(response.data)) {
                    const attribute: attributeResponseModel = response.data;
                    const newCategoryUI: categoryBrickUI = {...categoryUI};
                    newCategoryUI.fullAttributes[attributeId] = attribute;
                    setCategoryUI(newCategoryUI);
                } else {
                    enqueueSnackbar(`${translate({id: 'attributes.err'})}`, {variant: 'error', persist: false});
                    setSaveDisabledDueToReadErr(true);
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'attributes.err'})}`, {variant: 'error', persist: false});
                setSaveDisabledDueToReadErr(true);
                setIsLoading(false)
            });
    };

    const getAttribute = (attributeId: string, fromSearchResult: boolean = false) => {
        // step 3a - retrieving attribute
        axios.get(API_ATTRIBUTE(attributeId, selectedLanguage))
            .then((response) => {
                if (responseValidation(response.data)) {
                    if (response.data.type !== attrTypes.BRICK_DEPENDENT_ENUMERATION || fromSearchResult) {
                        const newCategoryUI: categoryBrickUI = {...categoryUI};
                        newCategoryUI.fullAttributes[attributeId] = response.data;
                        setCategoryUI(newCategoryUI);
                        setCurrentActiveAssignedAttributes(newCategoryUI.activeAssignedAttributes.map(item => item.id));
                    } else {
                        //step 3b - is brick-dependant, need to search again in context of brick
                        getBrickAttribute(attributeId);
                    }
                } else {
                    enqueueSnackbar(`${translate({id: 'attributes.err'})}`, {variant: 'error', persist: false});
                    setSaveDisabledDueToReadErr(true);
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'attributes.err'})}`, {variant: 'error', persist: false});
                setSaveDisabledDueToReadErr(true);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        // view mode - id obtained from path
        // step1 - obtain category id
        const id: string = getId();
        if (id) {
            getCategory(id);
            setCategoryId(id);
        }
        const retPath: string = getURLParam('from');
        if (retPath) {
            setReturnPath(retPath);
        }
    }, []);

    useEffect(() => {
        // embedded mode - id obtained from props
        if (externalCategoryId) {
            getCategory(externalCategoryId);
            setCategoryId(externalCategoryId);
        }
    }, [externalCategoryId]);

    useEffect(() => {
        if (categoryUI) {
            // step3 - retrieve - attribute details one by one
            for (const id in categoryUI.fullAttributes) {
                if (categoryUI.fullAttributes[id] === null) {
                    setIsLoading(true);
                    getAttribute(id);
                    return;
                }
            }
            setIsLoading(false);
        }
    }, [categoryUI]);

    const handleCheckboxChange = (checked: boolean, childId: string) => {
        setCurrentActiveAssignedAttributes(prevIds => {
            if (checked) {
                return prevIds.includes(childId) ? prevIds : [...prevIds, childId];
            } else {
                return prevIds.filter(id => id !== childId);
            }
        });

        setCategoryUI(prevState => {
            if (checked) {
                return {
                    ...prevState,
                    activeAssignedAttributes: [...prevState.activeAssignedAttributes, prevState.fullAttributes[childId]]
                };
            } else {
                return {
                    ...prevState,
                    activeAssignedAttributes: prevState.activeAssignedAttributes.filter(item => item.id !== childId)
                };
            }

        });
    };

    const makeAttributeCards = (): ReactElement => {
        const attributeCards: ReactElement[] = [];
        for (const attributeId in categoryUI.fullAttributes) {
            if (categoryUI.fullAttributes[attributeId]) {
                attributeCards.push(<CategoryAttributeCard key={attributeId}
                                                           data={categoryUI.fullAttributes[attributeId]}
                                                           activeAssignment={categoryUI.activeAssignedAttributes.some((item: basic) => item.id === categoryUI.fullAttributes[attributeId].id)}
                                                           onCheckboxChange={handleCheckboxChange}
                                                           onEdit={(attributeId) => handleBrickAttributeDialogOpen(attributeId)}
                                                           onRemove={(attributeId) => handleAttrRemove(attributeId)}/>);
            }
        }
        return <>{attributeCards}</>;
    };

    const makeAttributeCardsReadOnly = (): ReactElement => {
        const attributeCards: ReactElement[] = [];
        for (const attributeId in categoryUI.fullAttributes) {
            if (categoryUI.fullAttributes[attributeId]) {
                attributeCards.push(<CategoryAttributeCard key={attributeId}
                                                           data={categoryUI.fullAttributes[attributeId]}/>);
            }
        }
        return <>{attributeCards}</>;
    };

    const handleSearchSelectionChange = (selectedAttribute: basic) => {
        if (!categoryUI.fullAttributes[selectedAttribute.id]) {
            setIsLoading(true);
            getAttribute(selectedAttribute.id, true);
        }
    };

    const handleBrickAttributeSave = (attributeId: string, literals: basic[], inactiveLiterals: basic[]) => {
        handleBrickAttributeDialogClose();
        const newCategoryUI: categoryBrickUI = {...categoryUI};
        newCategoryUI.fullAttributes[attributeId].activeAssignedLiterals = literals;
        newCategoryUI.fullAttributes[attributeId].inactiveAssignedLiterals = inactiveLiterals;
        setCategoryUI(newCategoryUI);
    };

    const handleBrickAttributeDialogOpen = (attributeId: string) => {
        setSelectedBrickAttribute(categoryUI.fullAttributes[attributeId]);
        setBrickAttributeDialogOpen(true);
    }

    const handleBrickAttributeDialogClose = () => {
        setSelectedBrickAttribute(null);
        setBrickAttributeDialogOpen(false);
    };

    const handleAttrRemove = (attributeId: string) => {
        const newFullAttributes: mapOfApiAttributeResponse = {};
        for (const id in categoryUI.fullAttributes) {
            if (id !== attributeId) {
                newFullAttributes[id] = categoryUI.fullAttributes[id];
            }
        }
        const newCategoryUI: categoryBrickUI = {...categoryUI};
        newCategoryUI.fullAttributes = newFullAttributes;
        setCategoryUI(newCategoryUI);
    };

    const handleConfirmSaveClick = () => {
        setIsConfirmDialogOpen(false);
        handleSave();
    }

    const handleSave = () => {
        const payload: apiCategoryBrickDetailsPayload = [];
        for (const attributeId in categoryUI.fullAttributes) {
            if (categoryUI.fullAttributes[attributeId].type !== attrTypes.BRICK_DEPENDENT_ENUMERATION) {
                payload.push ({
                    attributeId,
                    activeAssignment: currentActiveAssignedAttributes.some((item) => item === categoryUI.fullAttributes[attributeId].id)
                });
            } else {
                payload.push({
                    attributeId,
                    activeAssignment: currentActiveAssignedAttributes.some((item) => item === categoryUI.fullAttributes[attributeId].id),
                    literals: [
                        ...categoryUI.fullAttributes[attributeId].activeAssignedLiterals.map((item): literalsAssignment => ({
                            literalId: item.id,
                            activeAssignment: true
                        })),
                        ...categoryUI.fullAttributes[attributeId].inactiveAssignedLiterals.map((item): literalsAssignment => ({
                            literalId: item.id,
                            activeAssignment: false
                        }))
                    ]
                });
            }
        }
        setIsLoading(true);
        axios.put(API_CATEGORY_BRICK_DETAILS(categoryId), payload)
            .then(() => {
                enqueueSnackbar(`${translate({id: 'category.updateSucc'})}`, {variant: 'success', persist: false});
            })
            .catch((e) => {
                console.log(e)
                enqueueSnackbar(`${translate({id: 'category.attrUpdateErr'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false))
    };

    const goBack = () => returnPath ? history.push(returnPath) : history.push(`${paths.category}?id=${categoryId}`);

    if (externalCategoryId) {
        return (
            <>
                <LoadingOverlay show={isLoading}/>
                {categoryUI && <>
                    <PaperX class="weldBottom">
                        <div className="_header">
                            <FormattedMessage id="b.categoryBrick"/>: {categoryUI.name}
                        </div>
                    </PaperX>
                    <PaperX className="weldTop _scrollY">
                        {makeAttributeCardsReadOnly()}
                    </PaperX>
                </>
                }
            </>
        );
    }
    return (
        <div className="viewRoot categoryAttributesRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading}/>
                {categoryUI &&
                    <div className="viewContainer _directionCol">
                        <PaperX>
                            <div className="_header">
                                <FormattedMessage id="b.categoryBrick"/>: {categoryUI.name}
                            </div>
                        </PaperX>
                        <PaperX className="weldBottom">
                            <div className="_halfWidth">
                                <Search onSelectionChange={handleSearchSelectionChange}
                                        autoSelect={true}
                                        autoFocus={true}
                                        clearOnSelect={true}
                                        searchUrl={API_ATTRIBUTES_SEARCH}
                                        label="attributes.label"/>
                            </div>
                        </PaperX>
                        <PaperX className="weldTop _scrollY">
                            {makeAttributeCards()}
                        </PaperX>
                    </div>
                }
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button color="primary" variant="contained"
                                disabled={!canSave(categoryUI) || saveDisabledDueToReadErr}
                                onClick={() => setIsConfirmDialogOpen(true)}>
                            <FormattedMessage id="a.save"/>
                        </Button>
                        <Button color="primary" variant="outlined" onClick={goBack}>
                            <FormattedMessage id="a.close"/>
                        </Button>
                    </>
                }
            />
            <BrickAttributeDialog open={brickAttributeDialogOpen}
                                  attribute={selectedBrickAttribute}
                                  onSave={(attributeId, literals, inactiveLiterals) => handleBrickAttributeSave(attributeId, literals, inactiveLiterals)}
                                  onClose={handleBrickAttributeDialogClose}/>
            <ConfirmDialog open={isConfirmDialogOpen}
                           onConfirm={handleConfirmSaveClick}
                           onCancel={() => setIsConfirmDialogOpen(false)}
                            message={<>
                                        <FormattedMessage id="attributes.savePrompt"/>
                                        <p>
                                            <FormattedMessage id="a.savePrompt"/>
                                        </p>
                                    </>}
                           confirmLabelId="a.yes"
                           cancelLabelId="a.no"
            />
        </div>
    );
}

export default CategoryAttributes;
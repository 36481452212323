import './ReportedFramesSummary.scss';

import {Link} from 'react-router-dom';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {IconButton, Tooltip} from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {paths} from 'paths';
import {reportedEntity, reportedFrameIssuesTableRow} from 'shared/models/_common.model';
import {frame, reportedFrameIssueType} from 'shared/models/frame.model';
import {useFormatMessage} from 'utils/translate';
import {PaperX} from 'components/PaperX';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';

interface reportedFramesSummaryProps {
    leafletId: string,
    frames: frame[],
    onRowClick: (pageNumber: number) => void,
    pages: reportedEntity[]
}

function combineReportedEntities (frames: frame[], pages: reportedEntity[]): reportedFrameIssuesTableRow[] {
    const framesMap: reportedFrameIssuesTableRow[] = frames.map((frame) => ({
        comment: frame.comment,
        pageNumber: frame.pageNumber,
        frameId: frame.frameId,
        reportedIssueType: frame.reportedIssueType,
        reportedBy: frame.reportedBy,
    }));
    if (pages.length) {
        return framesMap.concat(
            pages.map((page) => {
                return { comment: page.comment, pageNumber: page.pageNumber, frameId: null, reportedIssueType: null, reportedBy: page.reportedBy };
            })
        );
    }
    return framesMap;
}

const ReportedFramesSummary = (props:reportedFramesSummaryProps) => {
    const translate = useFormatMessage();
    const {leafletId, frames, onRowClick, pages} = props;

    const columDefs: ColDef[] = [
        {
            field: "pageNumber",
            headerName: translate({ id: "reportedFramesSummary.pageNo" }),
            width: 100,
        },
        {
            field: "comment",
            headerName: translate({ id: "a.reason" }),
            cellRenderer: (params) => (
                <div className="reasonText">{params.data.comment}</div>
            ),
            tooltipValueGetter: (params) => params.data.comment,
        },
        {
            field: "reportedBy",
            headerName: translate({ id: "b.reportedBy" }),
            flex: 1,
            cellRenderer: (params) => (
                <div className="reporterAndFix">
                    <div className="reporterText">{params.data.reportedBy}</div>
                    <FixFrameActionButton
                        frameId={params.data.frameId}
                        frameIssueType={params.data.reportedIssueType}
                        leafletId={leafletId}
                        pageNumber={params.data.pageNumber}
                    />
                </div>
            ),
            tooltipValueGetter: (params) => params.data.reportedBy,
        },
    ];

    return (
        <PaperX className="_fullHeight _fullTable reportedFramesSummaryRoot">
            <AgTable
                defaultColDef={{
                    suppressMenu: false,
                    resizable: true,
                    sortable: true
                }}
                rowData={combineReportedEntities(frames, pages)}
                columnDefs={columDefs}
                onRowClicked = {(rowData) => onRowClick(rowData.data.pageNumber)}
                localStorageColumnsKey={localStorageColumnsKeys.reportedFramesSummary}
            />
        </PaperX>
    );
};

interface FixFrameActionButtonProps {
    leafletId: string,
    frameId: string,
    pageNumber: number,
    frameIssueType: reportedFrameIssueType
}

const FixFrameActionButton = ({frameId, frameIssueType, leafletId, pageNumber }: FixFrameActionButtonProps) => {
    const translate = useFormatMessage();

    if (frameIssueType === reportedFrameIssueType.UNABLE_TO_DESCRIBE) {
        return (
            <Link to={`${paths.describeFrame}?leafletId=${leafletId}&frameId=${frameId}&fixingFrame=true&from=${paths.reportedFramesBrowser}`} className="fixLink">
                <Tooltip title={translate({id: 'printMediaOverview.describeFrame'})}>
                    <IconButton size="small" color="primary">
                        <DriveFileRenameOutlineIcon/>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }
    // otherwise if frameIssueType === reportedFrameIssueType.FRAMING_ISSUE or whole page is reported
    return (
        <Link to={`${paths.fixReportedFrames}?id=${leafletId}&pageNumber=${pageNumber}`} className="fixLink">
            <Tooltip title={translate({id: 'reportedFramesBrowser.fixBtn'})}>
                <IconButton size="small" color="primary">
                    <AppRegistrationIcon/>
                </IconButton>
            </Tooltip>
        </Link>
    );
};

export default ReportedFramesSummary;
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { API_STATUSES } from 'config/api/constants';
import { paths } from 'paths';
import {bannerWithRetailer} from 'shared/models/banner.model';
import {branch} from 'shared/models/branch.model';
import { IRootState } from 'shared/reducers';
import { fetchBranchesList } from 'shared/reducers/branchesList';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {getId, getURLParam} from 'utils/routing';
import { useFormatMessage } from 'utils/translate';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import {CountryBannerSelector} from 'components/Selectors';
import {PaperX} from 'components/PaperX';
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";

interface BranchTableRow {
    data: branch
}

const BranchesList = (props) => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();

    const branchesData = useSelector((state: IRootState) => state.branchesList.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesList.status);

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [bannerId, setBannerId] = useState<string>('');
    const [branchesOfBanner, setBranchesOfBanner] = useState<branch[]>([]);

    const [returnPath, setReturnPath] = useState<string>(paths.masterData);

    useEffect(() => {
        const bannerIdFromUrl: string = getId();
        const fromUrl: string = getURLParam('from');
        if (bannerIdFromUrl) {
            setBannerId(bannerIdFromUrl);
        }
        if (fromUrl) {
            setReturnPath(fromUrl);
        }
    }, []);

    useEffect(() => {
        if (bannerId) {
            dispatch(fetchBranchesList(bannerId,selectedLanguage));
        }
        setBranchesOfBanner([]);
    },[bannerId]);

    useEffect(() => {
        if (bannerId) {
            setBranchesOfBanner(branchesData);
        }
    },[branchesData]);

    const editBranch = (row: BranchTableRow) => props.history.push(`${paths.branchDetails}?bannerId=${bannerId}&id=${row.data.id}&from=${returnPath}`);

    const getRowId = (params): string => params.data.id;

    const columnDefs: ColDef[] = [
        { field: 'postCode', headerName: translate({ id: 'b.postCode' }), flex: 1},
        { field: 'city', headerName: translate({ id: 'b.city' }), flex: 1},
        { field: 'street', headerName: translate({ id: 'b.street' }), flex: 1},
        { field: 'houseNumber', headerName: translate({ id: 'b.houseNumber' })},
        { field: 'regionName', headerName: translate({ id: 'b.region'}), flex: 1 },
        { field: 'latitude', headerName: translate({ id: 'b.latitude'}), flex: 1, filter: false},
        { field: 'longitude', headerName: translate({ id: 'b.longitude'}), filter: false },
        { field: 'nielsenRegionName', headerName: translate({ id: 'b.nielsenRegion'}), flex: 1, filter: false },
        { field: 'typeOfBranchName', headerName: translate({ id: 'b.typeOfBranch'}), flex: 1 },
        { field: 'advertisingAreas', headerName: translate({ id: 'b.advertisingArea'}), flex: 1,
            valueGetter: (params): string => {
                if (params.data.advertisingAreas.length !== 0) {
                    return params.data.advertisingAreas.map((index) => index.name).join(", ");
                }
                return '';
            }
        },
        { field: 'franchiseOwner', headerName: translate({ id: 'b.franchiseOwner'}), flex: 1,
            valueGetter: (params): string => {
                if (params.data.franchiseOwner.length !== 0) {
                    return params.data.franchiseOwner.map((index) => index.name).join(", ");
                }
                return '';
            }
        },
        { field: 'retailerHeadquarter', headerName: translate({ id: 'b.retailerHQ'}), flex: 1,
            valueGetter: (params): string => params.data.retailerHeadquarter?.name || ''
        }
    ];

    const handleCountryChange = (value: string) => dispatch(setPreferredCountry(value));

    const handleBannerChange = (banner: bannerWithRetailer) => setBannerId(banner?.bannerId);

    return(
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <div className="_directionCol">
                        <LoadingOverlay show={branchesStatus === API_STATUSES.PENDING}/>
                        <PaperX>
                            <CountryBannerSelector country={countryMarket.preferredCountry}
                                                   bannerId={bannerId}
                                                   onCountryChange={handleCountryChange}
                                                   onBannerChange={handleBannerChange}
                            />
                        </PaperX>
                        <PaperX className="_fullHeight _fullTable">
                            <AgTable
                                rowData={branchesOfBanner}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    floatingFilter: true,
                                    filter: 'agTextColumnFilter',
                                    floatingFilterComponentParams: {suppressFilterButton:true}
                                }}
                                columnDefs={columnDefs}
                                onRowClicked={(rowData: BranchTableRow) => editBranch(rowData)}
                                getRowId={getRowId}
                                localStorageColumnsKey={localStorageColumnsKeys.branchesList}
                            />
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={`${paths.branchDetails}?bannerId=${bannerId}&new=true`}>
                            <Button color="primary" variant="contained">{translate({id: 'a.new'})}</Button>
                        </Link>
                        <Link to={returnPath} style={{textDecoration: 'none'}}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};
export default BranchesList;
import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_ADVERTISEMENTS_OVERVIEW_USER} from 'config/api/constants';
import {
    advertisementsOverviewResponseModel,
    advertisementsOverviewRow,
    myFrameFilters
} from 'shared/models/advertisement.model';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import AdvOverviewPromotionsTable from 'components/Tables/AdvOverviewPromotionsTable';
import {flattenCharacteristics} from 'modules/Advertisement/AdvertisementsOverviewPromotions/AdvOverviewPromotions';
import MyAdvertisementsFilters from './MyAdvertisementsFilters';

const MyAdvertisements = () => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [advertisements, setAdvertisements] = useState<advertisementsOverviewRow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const cancelToken = useRef(null);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const getData = (filters: myFrameFilters) => {
        setLoading(true);
        if (cancelToken.current) cancelToken.current();
        setAdvertisements([]);
        axios.get<advertisementsOverviewResponseModel>(API_ADVERTISEMENTS_OVERVIEW_USER, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setAdvertisements(flattenCharacteristics(response.data));
                } else {
                    enqueueSnackbar(`${translate({id: 'advertisementsOverview.fetchPromoErr'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e?.__CANCEL__) {
                    let errMsgId = 'advertisementsOverview.fetchPromoErr'
                    if (e.response.status === 422) {
                        errMsgId = 'a.2many';
                    }
                    enqueueSnackbar(`${translate({id: errMsgId})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setLoading(false));
    };

    const handleFiltersChange = (filters: myFrameFilters) => {
        if (!filters) return;
        if (Object.keys(filters).length > 2) {
            const { creationRange, ...filtersWithoutCreationRange } = filters;
            getData(filtersWithoutCreationRange);
        }
    };

    return (
        <>
            <LoadingOverlay show={loading}/>
            <div className="viewContainer _directionCol">
                <PaperX>
                    <MyAdvertisementsFilters onFilterChange={(filters) => handleFiltersChange(filters)}/>
                </PaperX>
                <PaperX className="_fullHeight _fullTable">
                    <AdvOverviewPromotionsTable data={advertisements}/>
                </PaperX>
            </div>
        </>
    );
};

export default MyAdvertisements;
import {
    PROMOTION_TYPES,
    fullSpotPromotions,
    promotionSave,
    promotionUI
} from 'shared/models/promotion.model';
import {mediumPromotionsPayload, promotionsForSpotResponseModel, promotionMediumType} from 'shared/models/media.model';
import {transformPromotions4UI} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewIO';

const promotionBE2FE = (promotionBE: promotionsForSpotResponseModel): fullSpotPromotions => {
    const {
        mediumId,
        promotions,
        validFrom,
        validTo
    } = promotionBE;

    return {
        mediumId,
        validFrom,
        validTo,
        promotions: transformPromotions4UI(promotions)
    };
}

export const createMediaPromotionsPayload = (promotions: promotionUI[], mediumId: string, promotionMediumType: promotionMediumType): mediumPromotionsPayload => {
    const newPromotions: promotionSave[] = promotions.map((item) => {
         const {
             absoluteDiscount,
             id,
             mpu,
             promotionObjectType,
             promotionTypes,
             promotionalPrice,
             qualitySeals,
             regularPrice,
             relativeDiscount,
             themeSeasons,
             countryThemedWeeks,
             giveAwayBundledProducts,
             validityDates
         } = item;
         const newItem: promotionSave = {
             absoluteDiscount,
             giveAwayBundledProductIds: giveAwayBundledProducts?.map(item => item.id),
             mpu,
             promotionObjectType,
             promotionTypes: promotionTypes.literals.map((item) => item.literalId),
             promotionalPrice,
             qualitySeals: qualitySeals.literals.map((item) => item.literalId),
             regularPrice,
             relativeDiscount,
             themeSeasons: themeSeasons.literals.map((item) => item.literalId),
             countryThemedWeeks: countryThemedWeeks.literals.map((item) => item.literalId),
             validityDates
         };
         if (promotionObjectType === PROMOTION_TYPES.BPCO) {
             newItem.bpcoId = item.bpcoId;
         } else {
             newItem.productId = item.productId;
         }
         if (id) { // id is optional, only exist when updating promotion
             newItem.id = id;
         }
         return newItem;
     });
 
     return {
         mediumId,
         promotionMediumType,
         promotions: newPromotions
     };
 }

export const transformMediaPromotionsResponse = (data: promotionsForSpotResponseModel): fullSpotPromotions => promotionBE2FE(data);


import './Attribute.scss';

import { useEffect, useState} from 'react';
import {Autocomplete, Box, TextField} from '@mui/material';
import {
    enumAttributeDataModel,
    unitAttributeModel
} from 'shared/models/attributeComponent.model';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import {useFormatMessage} from "utils/translate";

type attributeEnumProps = {
    data: enumAttributeDataModel,
    model: unitAttributeModel,
    onChange: Function,
    onClear: Function,
    required?: boolean,
    disabled?: boolean
}

const literalTemplate = {
    id: null,
    name: null,
    options: null,
    onChange: null
}

const AttributeEnum = (props: attributeEnumProps) => {
    const {data, model, onChange, onClear, required, disabled} = props;
    const translate = useFormatMessage();

    const deprecatedString = ` (${translate({id: 'b.deprecated'})})`;

    const [options, setOptions] = useState([]);
    const [deprecatedLiteralId, setDeprecatedLiteralId] = useState<string>(null);
    const [deprecatedLiteral, setDeprecatedLiteral] = useState<unitAttributeModel>(!model?.options.find((index) => index.id === data?.literalId )? {...literalTemplate, id: data?.literalId, name: data?.literalName + deprecatedString} : literalTemplate);

   useEffect(() => {
        if (model && data){
            if (data.literalId !== undefined){
                if (!model.options.find((index) => index.id === data.literalId)){
                    setDeprecatedLiteralId(data.literalId);
                    setDeprecatedLiteral({...literalTemplate, id: data.literalId, name: data.literalName + deprecatedString});
                }
            }
        }
    }, [model, data, deprecatedString])

    useEffect(() => {
        if (model){
            const sortedOptions = model.options.sort((a, b) => a.name.localeCompare(b.name));
            if (data && data.literalId !== undefined){
                if (deprecatedLiteral?.id !== null && deprecatedLiteral?.id !== undefined&& !sortedOptions.find((index) => index.id === deprecatedLiteralId)){
                    sortedOptions.unshift(deprecatedLiteral);
                }
                if (sortedOptions.length === 0){
                    setOptions([{id: data.literalId, name: data.literalName}]);
                }else {
                    const deprecatedIndex = sortedOptions.findIndex((index) => index.id === deprecatedLiteralId);
                    if (deprecatedIndex > 0){
                        sortedOptions.splice(deprecatedIndex,1);
                        sortedOptions.unshift(deprecatedLiteral)
                    }
                }
                setOptions(sortedOptions);
            }else setOptions(sortedOptions);
        }
    }, [model, data, deprecatedLiteral, deprecatedLiteralId]);

    const handleChange = (value) => (value === null) ? onClear(model.id) : onChange(model.id, {literalId: value.id});

    const findOptionForDataId = () => {
        let match = options.find((item) => item.id === data.literalId);
        return match ? match.name : '';
    };

    const getName = () => required ? model.name + ' *' : model.name;

    const handleClear = () => onClear(model.id);

    return (
        <div className="attributeRoot attributeEnum">
            <fieldset className="complexAttributeWrapper">
                <Autocomplete
                    className="attributeAutocomplete"
                    autoSelect
                    isOptionEqualToValue={(option, value) => option.id === value.id || option.id === value.unitId}
                    value={data}
                    options={options}
                    getOptionLabel={(option) => option.name || findOptionForDataId()}
                    renderOption={(props, option) => {
                        return(
                            <Box key={option['id']} className={`${option['id'] === deprecatedLiteralId && 'deprecated'}`}>
                                <li {...props} key={option['id']}>
                                    {option['name']}
                                </li>
                            </Box>
                        );
                    }}
                    renderInput={(params) => <TextField {...params} label={getName()} />}
                    onChange={(e, value) => handleChange(value)}
                    disabled={disabled}
                />
                {disabled ?
                    <div className="clearInputButtonContainer">
                        <IconButton onClick={handleClear} tabIndex={-1}>
                            <ClearIcon/>
                        </IconButton>
                    </div>
                    : null}
            </fieldset>
        </div>
    );
};
export default AttributeEnum;
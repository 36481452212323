import {
    pageThemeSeason,
    pageThemeSeasonBE,
    pageThemeSeasonBEGrouped,
    pageThemeSeasonBEGroupedWithRanges,
    pageThemeSeasonBEPageRangesStringified,
    validateResult
} from "shared/models/leaflet.model";
import {handyAttributes} from "shared/handyAttributes";
import {multiselectAttributeDataModel} from "shared/models/attributeComponent.model";

function restoreAttribute (themeSeasons: string[]): multiselectAttributeDataModel {
    return {
        id: handyAttributes.themeSeason,
        literals: themeSeasons ? themeSeasons.map((item) => ({literalId: item})) : []
    }
}

export function importPageSeasonThemes(pageThemeSeasonBE:pageThemeSeasonBE[]): pageThemeSeason[] {
    const convert = (data: pageThemeSeasonBEPageRangesStringified): pageThemeSeason => {
        return {
            pageNumber: data.pageNumber,
            themeSeasons: restoreAttribute(data.themeSeasonIds),
            modified: false
        }
    };

    const lastOfLast = (ar) => {
        const l = ar.length - 1;
        const subL = ar[l].length - 1;
        return ar[l][subL];
    };

    const groupedByThemeSeason: pageThemeSeasonBEGrouped[] = [];
    if (pageThemeSeasonBE){
        pageThemeSeasonBE.forEach((item) => {
            const existingSeasonThemeGroupIndex = groupedByThemeSeason.findIndex((existing) => {
                for (let id in existing.themeSeasonIds){
                    if (!existing.themeSeasonIds.includes(item.themeSeasonIds[id])){
                        return false;
                    }
                }
                return true;
            });
            if (existingSeasonThemeGroupIndex > -1) {
                groupedByThemeSeason[existingSeasonThemeGroupIndex].pageNumber.push(item.pageNumber);
            } else {
                const newItem: pageThemeSeasonBEGrouped = {pageNumber: [], themeSeasonIds: item.themeSeasonIds};
                newItem.pageNumber = [item.pageNumber];
                groupedByThemeSeason.push(newItem);
            }
        });
    }
    const withSeasonThemeSorted: pageThemeSeasonBEGrouped[] = groupedByThemeSeason.map((item) => {
        const newItem: pageThemeSeasonBEGrouped = {...item};
        newItem.pageNumber = item.pageNumber.sort((a, b) => a - b);
        return newItem;
    });
    const withRangesGrouped: pageThemeSeasonBEGroupedWithRanges[] = withSeasonThemeSorted.map((item) => {
        const newItem: pageThemeSeasonBEGroupedWithRanges = {pageNumber: [], themeSeasonIds: item.themeSeasonIds};
        const ranges = [];
        item.pageNumber.forEach((pgNo, idx) => {
            if (idx === 0) {
                ranges.push([pgNo]);
            } else {
                const lastest = lastOfLast(ranges);
                if (lastest && lastest + 1 === pgNo) {
                    ranges[ranges.length-1].push(pgNo);
                } else {
                    ranges.push([pgNo]);
                }
            }
        });
        newItem.pageNumber = ranges;
        return newItem;
    });
    const withRangesStringified: pageThemeSeasonBEPageRangesStringified[] = withRangesGrouped.map((item) => {
        const newItem: pageThemeSeasonBEPageRangesStringified = {pageNumber: '', themeSeasonIds: item.themeSeasonIds};
        let pagesString = '';
        item.pageNumber.forEach((pgRange) => {
            if (pgRange.length === 1) {
                pagesString += pgRange[0] + ',';
            } else {
                pagesString += pgRange[0] + '-' + pgRange[pgRange.length - 1] + ',';
            }
        });
        newItem.pageNumber = pagesString.slice(0, pagesString.length - 1);
        return newItem
    });
    return withRangesStringified.map((item) => convert(item));
}

export function exportPageSeasonThemes(pageSeasonThemeUi:pageThemeSeason[]): pageThemeSeasonBE[] {
    const transformed: pageThemeSeasonBE[] = [];
    pageSeasonThemeUi.forEach((item) => {
        const byComa = item.pageNumber.split(',');
        byComa.forEach((pgOrRange) => {
            if (pgOrRange.indexOf('-') > 0) {
                const startEnd = pgOrRange.split('-');
                for (let i = parseInt(startEnd[0]); i <= parseInt(startEnd[1]); i++) {
                    transformed.push({pageNumber: i, themeSeasonIds: item.themeSeasons.literals.map((literal) => literal.literalId)})
                }
            } else {
                transformed.push({pageNumber: parseInt(pgOrRange), themeSeasonIds: item.themeSeasons.literals.map((literal) => literal.literalId)})
            }
        });
    });
    return transformed;
}

export function validateThemeSeasons(themeSeasons:pageThemeSeasonBE[]): validateResult {
    let duplicates = new Set([]);
    const pageNumbers = themeSeasons.map((item) => item.pageNumber);
    pageNumbers.forEach((pgNo) => {
        const duplicate = pageNumbers.filter((item) => item === pgNo);
        if (duplicate.length > 1) {
            duplicates.add(pgNo);
        }
    });
    return duplicates.size ? {hasError: true, duplicatePages: Array.from(duplicates)} : {hasError: false};
}
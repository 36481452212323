/* eslint-disable react-hooks/exhaustive-deps */
import './CountryBannerSelector.scss';

import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import { CountrySelector, BannerSelector } from 'components/Selectors';
import {IRootState} from 'shared/reducers';
import {bannerWithRetailer} from 'shared/models/banner.model';

interface CountryBannerSelectorProps {
    country: string;
    onCountryChange: (countryId: string) => void;
    bannerId: string;
    onBannerChange: (banner: bannerWithRetailer) => void;
    className?: string,
    disabledCountry?: boolean
}

const CountryBannerSelector = (props: CountryBannerSelectorProps) => {
    const {country, onCountryChange, bannerId, onBannerChange, className, disabledCountry} = props;
    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);

    useEffect(() => {
        if (bannersData.length && bannerId) {
            const matchingBanner: bannerWithRetailer = bannersData.find((item) => item.bannerId === bannerId);
            if (matchingBanner && matchingBanner.countryId !== country) {
                onCountryChange(matchingBanner.countryId);
            }
        }
    }, [bannerId, bannersData]);

    const handleBannerChange = (banner: bannerWithRetailer) => onBannerChange(banner);

    return (
        <div className={`${className ? className + ' ' : ''}countryBannerSelectorRoot`}>
            <CountrySelector
                compact
                disabled={disabledCountry}
                value={country}
                onCountryChange={(countryId) => onCountryChange(countryId)}
            />
            <div style={{width: "100%"}}>
                <BannerSelector
                    bannerId={bannerId}
                    countryId={country}
                    onBannerChange={handleBannerChange}
                />
            </div>
        </div>
    );
};

export default CountryBannerSelector;
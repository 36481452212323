import {API_LITERALS_SEARCH} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {literalObject} from 'shared/models/literal.model';
import {PaperX} from 'components/PaperX';
import {DictionaryElementsTable} from 'components/Tables';
import Search from 'components/Search/Search';

interface AttributeLiteralProps {
    data: literalObject[],
    onLiteralSelect: (literal: basic) => void,
    onRowClick: (literalId: string) => void,
}

const AttributeLiteral = ({data, onLiteralSelect, onRowClick}: AttributeLiteralProps) => {
    return (<>
        <PaperX>
            <div className="_halfWidth">
                <Search onSelectionChange={onLiteralSelect} searchUrl={API_LITERALS_SEARCH} label="literals.label"/>
            </div>
        </PaperX>
        <PaperX className="_fullHeight _fullTable">
            <DictionaryElementsTable data={data} onRowClick={onRowClick} tooltipLabelId="a.tooltipClck2Remove"/>
        </PaperX>
    </>);
};

export default AttributeLiteral;
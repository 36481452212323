import {FormattedMessage} from 'react-intl';

export const DuplicateDialogMsg = () => (
    <>
        <p>
            <FormattedMessage id="headerData.duplicateLeaflet"/>
        </p>
        <p>
            <FormattedMessage id="headerData.duplicateConfirm"/>
        </p>
    </>
);
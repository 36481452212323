/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import {paths} from 'paths';
import axios from 'axios';
import {API_GET_PRODUCTS_WITHOUT_GTINS} from 'config/api/constants';
import {filteredProductsResponseModel} from 'shared/models/product.model';
import {gtinsQsDashboardTablesProps} from 'shared/models/gtin.model';
import {useSnackbar} from 'notistack';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import {LoadingOverlay} from 'components/LoadingOverlay';

const GtinQsWithoutGtins = (props: gtinsQsDashboardTablesProps) => {
    const {history, tableView} = props;
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const userProfile = useSelector((state: IRootState) => state.userProfile);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [productsData, setProductsData] = useState<filteredProductsResponseModel[]>([]);
    const cancelToken = useRef(null);

    const columnDefs: ColDef[] = [
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'alternativeDescriptions', headerName: translate({id: 'b.alternativeDescription'}), flex: 1 },
        { field: 'category', headerName: translate({id: 'b.category'}), flex: 1 },
        { field: 'typeOfPackage', headerName: translate({id: 'b.typeOfPackage'}), flex: 1 },
        { field: 'capacity', headerName: translate({id: 'b.capacity'}), flex: 1,
            valueGetter: (params) => {
                const from = params.data.capacityFrom;
                const to = params.data.capacityTo;
                if (from || to) {
                    if (from === to) {
                        return from;
                    }
                    return `${from} - ${to}`;
                }
                return '';
            }
        },
        { field: 'capacityUnit', headerName: translate({id: 'b.capacityUnit'}), flex: 1 },
        { field: 'contentOfTradingUnits', headerName: translate({id: 'b.contentOfTradingUnit'}), flex: 1 },
        { field: 'contentOfTradingUnitsUnit', headerName: translate({id: 'b.contentOfTradingUnitUnit'}), flex: 1 },
        { field: 'variety', headerName: translate({id: 'b.variety'}), flex: 1 },
        { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1 },
        { field: 'productLineName', headerName: translate({id: 'b.productLine'}), flex: 1 },
        { field: 'active', headerName: translate({ id: 'a.active' }), width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), suppressMenu: true},
    ];

    useEffect(() => {
        fetchProductsWihoutGtins();
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => fetchProductsWihoutGtins(), [userProfile]);
    
    const fetchProductsWihoutGtins = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);
        axios.get<filteredProductsResponseModel[]>(API_GET_PRODUCTS_WITHOUT_GTINS(userProfile.langData, userProfile.countryMarket.market), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setProductsData(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch(e => {
                if(!e?.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    }

    const goToDetails = (productId: string) => history.push(`${paths.productDetails}?id=${productId}&from=${paths.gtin}&activeTab=${tableView}`);

    return (        
        <>
            <LoadingOverlay show={isLoading}/>
            <AgTable
                columnDefs={columnDefs}
                defaultColDef={{
                    resizable: true,
                    sortable: true
                }}
                rowData={productsData}
                onRowClicked = {(rowData: RowClickedEvent) => goToDetails(rowData.data.id)}
                localStorageColumnsKey={localStorageColumnsKeys.gtinQsDashboardWithoutGtins}
            />
        </>
    );
};
export default GtinQsWithoutGtins;
import {productSearchResultUI} from 'shared/models/product.model';

export const groupResults = (data:productSearchResultUI[]) => {
    const ajdis = {};
    // make groups: brand + category
    data.forEach((item) => {
        const uniq = item.brand + item.category;
        if (ajdis[uniq]) {
            ajdis[uniq].push({...item});
        } else {
            ajdis[uniq] = [{...item}];
        }
    });
    // make array of product groups
    const groups:productSearchResultUI[][] = Object.values(ajdis);

    groups.forEach((group) => {
        if (group && group.length > 1) {
            group.forEach((product) => {
                // make list of characteristics of all OTHER products in this group
                const otherProductsCharacteristics = group
                    .filter((otherProduct) => otherProduct.id !== product.id)
                    .map((item) => item.characteristics)
                    .flat();
                // check if characteristic of this product is unique within this group
                product.characteristics = product.characteristics
                    .map((characteristic) => {
                        if (otherProductsCharacteristics.find((otherCharacteristic) => otherCharacteristic.id === characteristic.id && otherCharacteristic.value === characteristic.value) ) {
                            return {...characteristic};
                        }
                        return {...characteristic, uniq: true};
                    });
            })
        }
    });
    return groups;
}
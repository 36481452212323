import axios from 'axios';
import { useQuery } from 'react-query';
import { API_GET_REPRESENTATIVE_BRANCHES_FOR_BANNER } from 'config/api/constants';
import {representativeBranchesForBanner} from 'shared/models/banner.model';

const getRepresentativeBranchList = async(bannerId: string): Promise<representativeBranchesForBanner> => {
    if (!bannerId) return [];
    else {
        const response = await axios.get(API_GET_REPRESENTATIVE_BRANCHES_FOR_BANNER(bannerId));
        return response.data;
    }
}

export const useGetRepresentativeBranchList = (bannerId:string) => {
    return useQuery(
        [bannerId, 'getRepresentativeBranchList'],
        () => getRepresentativeBranchList(bannerId),
        {
            enabled: !!bannerId,
            retry: 2,
            retryDelay: 500,
            onError: (e) => console.log(e),
        }
    );
}
import axios from 'axios';
import {useQuery} from 'react-query';
import {
    API_LEAFLET_MANAGEMENT_STATS_MEDIA,
    API_LEAFLET_MANAGEMENT_STATS_PROMOTIONS
} from 'config/api/constants';
import {statisticsResponseModel} from 'shared/models/statistics.model';

const fetchStatsMedia = async (countryId: string): Promise<statisticsResponseModel> => {
    const response = await axios.get(API_LEAFLET_MANAGEMENT_STATS_MEDIA(countryId));
    return response.data;
};

export const useStatsMediaQuery = (countryId: string, handleError=()=>null) => useQuery(['fetchStatsMedia', countryId], () => fetchStatsMedia(countryId), {staleTime: 0, onError: handleError, retry: false});

const fetchStatsPromo = async (countryId: string): Promise<statisticsResponseModel> => {
    const response = await axios.get(API_LEAFLET_MANAGEMENT_STATS_PROMOTIONS(countryId));
    return response.data;
};

export const useStatsPromoQuery = (countryId: string, handleError=()=>null) => useQuery(['fetchStatsPromo', countryId], () => fetchStatsPromo(countryId), {staleTime: 0, onError: handleError, retry: false});
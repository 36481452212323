export const USER_SETTINGS_SET = 'USER_SETTINGS_SET';

export const setUserSetting = (key, value) => (dispatch) => {
    dispatch({
        type: USER_SETTINGS_SET,
        payload: {key, value}
    })
};

const initialState = {
    settings: {}
};

export type UserSettingsState = Readonly<typeof initialState>;

const userSettingsReducer = (state: UserSettingsState = initialState, action): UserSettingsState => {
    switch (action.type) {
        case USER_SETTINGS_SET:
            const {key, value} = action.payload;
            const newState = {...state};
            newState.settings[key] = value;
            return newState;
        default:
            return state;
    }
};

export default userSettingsReducer;
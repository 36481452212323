import {useHistory} from 'react-router-dom';
import {Dialog, DialogContent} from '@mui/material';
import BrandNewView from 'modules/MasterData/Brand/BrandNewView';
import {basic} from 'shared/models/_common.model';

interface brandNewDialogProps {
    onClose: () => void,
    onSelect: (brand: basic) => void,
    open: boolean
}

const BrandNewDialog = ({onClose, onSelect, open}: brandNewDialogProps) => {
    const history = useHistory()

    const handleBrandCreated = (newBrand: basic) => {
        onSelect(newBrand);
        onClose();
    };

    return (
        <Dialog className="_dialogOnDescribeFrameView _dialog-likeView" open={open} fullScreen>
            <DialogContent className="_directionCol">
                <BrandNewView history={history}
                              onClose={onClose}
                              onSelect={(newBrand) => handleBrandCreated(newBrand)}
                              isDescribingFrame
                />
            </DialogContent>
        </Dialog>
    );
};

export default BrandNewDialog;
/* eslint-disable react-hooks/exhaustive-deps */
import './RetailerSelector.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { API_STATUSES } from 'config/api/constants';
import {retailerItem} from 'shared/models/dict.model';
import {retailer} from 'shared/models/retailer.model';
import { IRootState } from 'shared/reducers';
import {fetchRetailers} from 'shared/reducers/retailers';
import { useFormatMessage } from 'utils/translate';

interface RetailerSelectorProps {
    retailerId?: string,
    onRetailerChange?: (retailer: retailer) => void,
    required?:boolean
}

const RetailerSelector = ({ retailerId, onRetailerChange, required }: RetailerSelectorProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const retailersStatus = useSelector((state: IRootState) => state.retailers.status);
    const retailersData = useSelector((state: IRootState) => state.retailers.data);

    const [selectedRetailer, setSelectedRetailer] = useState<retailerItem>(null);
    
    useEffect(() => {
        if (retailersStatus === API_STATUSES.ERROR) {
            dispatch(fetchRetailers());
        }
    }, []);

    useEffect(() => {
        if (retailersStatus === API_STATUSES.NEW) {
            dispatch(fetchRetailers());
        }
    }, [retailersStatus]);

    useEffect(() => {
        const foundRetailer: retailerItem = retailersData.find((item) => item.id === retailerId);
        if (foundRetailer) {
          setSelectedRetailer(foundRetailer);
        }
    },[retailersData, retailerId]);

    const filterRetailers = (options: retailer[], { inputValue }) => {
        const loCased: string = inputValue ? inputValue.toLowerCase() : '';
        return options.filter((item) => item.name.toLowerCase().indexOf(loCased) > -1);
    };

    const handleAutocompleteChange = (value: retailer | string) => {
        if (typeof value !== 'string') {
            setSelectedRetailer(value);
            onRetailerChange(value);
        }
    };

    return (
      <Autocomplete
          options={retailersData}
          filterOptions={filterRetailers}
          onChange={(e, value) => handleAutocompleteChange(value)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => `${option.name}`}
          renderOption={(props, option) => <li {...props} className={selectedRetailer && option.id === selectedRetailer.id ? "bannerSelectorOption selected" : "bannerSelectorOption"} key={option['id']}><span className="retailerName">{option['name']}</span></li>}
          value={selectedRetailer}
          defaultValue={retailersData.find((item) => item.id === retailerId)}
          renderInput={(params) => <TextField {...params} required={required} label={translate({id: 'b.retailer'})} />}
      />
    )
};

export default RetailerSelector;
import {PROMOTION_TYPES} from 'shared/models/promotion.model';
import {validity} from 'shared/models/validity.model';
import {characteristic} from 'shared/models/product.model';

export enum reportedFrameIssueType {
    FRAMING_ISSUE = 'FRAMING_ISSUE',
    UNABLE_TO_DESCRIBE = 'UNABLE_TO_DESCRIBE'
}

export type basicFramePayloadObject = {
    frameId?: string, // PUT method use frameId
    id?: string,    // POST method use id
    x1: number,
    x2: number,
    y1: number,
    y2: number,
    validFrom?: string,
    validTo?: string
}

export type framesPerPagePerLeafletPayloadModel = basicFramePayloadObject[];

export type frame = {
    comment?: string,
    frameId: string,
    leafletId: string,
    pageNumber: number,
    remarked?: boolean,
    reported?: boolean,
    reportedBy?: string,
    reportedIssueType?: reportedFrameIssueType,
    validFrom?: string,
    validTo?: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
}

export type framesResponseModel = frame[];

export type apiGetPromotionsForFrameResponsePromotionTypeProductType = {
    active: boolean,
    alternativeProductId: string,
    brand: string,
    category: string,
    characteristics: characteristic[],
    description: string,
    gtin: string,
    id: string,
    isTemplate: boolean,
    productLineId: string,
    productLineName: string
}
export type apiGetPromotionsForFrameResponsePromotionTypeBPCOType = {
    active: boolean,
    alternativeDescription: string,
    brand: string,
    category: string,
    characteristics: characteristic[],
    description: string,
    id: string
}

export type apiGetPromotionsForFrameResponsePromotionType = {
    absoluteDiscount: number,
    bpco: apiGetPromotionsForFrameResponsePromotionTypeBPCOType,
    countryThemedWeeks: string[],
    currency: string,
    giveAwayBundledProducts: apiGetPromotionsForFrameResponsePromotionTypeProductType[],
    id: string;
    mpu: number,
    product: apiGetPromotionsForFrameResponsePromotionTypeProductType,
    promotionObjectType: PROMOTION_TYPES,
    promotionTypes: string[],
    promotionalPrice: number,
    qualitySeals: string[],
    regularPrice: number,
    relativeDiscount: number,
    themeSeasons: string[],
    validityDates: validity[],
}

export type productSuggestion = apiGetPromotionsForFrameResponsePromotionType & {
    frameId: string,
    frameValidFrom: string,
    frameValidTo: string
}

export type productSuggestionResponseModel = productSuggestion[];

export type promotionsForFrameResponseModel = {
    frameId: string,
    promotions: apiGetPromotionsForFrameResponsePromotionType[],
    validFrom: string,
    validTo: string
};

export type differentFrameModel = {
    frameId: string,
    comment?: string,
    reported: boolean,
    reportedIssueType?: reportedFrameIssueType,
    status: boolean,
    validFrom?: string,
    validTo?: string,
    x1: number,
    x2: number,
    y1: number,
    y2: number,
}

export type framesPerPagePerLeafletResponseModel = {
    createdAt: string, // @TODO this date is in different format than other dates in system ex. 2023-04-27T07:13:24.205876
    frames: differentFrameModel[],  // @TODO these frames are different than frames. why? can we make better name this type?
                                    // especially missing leafletId and pageNumber are painful as they make this response incompatible
                                    // with buildRectObject function that use 'normal/full' frame model/type
    leafletId: string,
    pageId: string,
    pageNumber: number,
    status: string, // @TODO unclear whose status is this - leaflet or page?
    storageFileName: string,
    validFrom: string,
    validTo: string,
}

export type frameProposal = {
    confidence: number,
    x1: number,
    x2: number,
    y1: number,
    y2: number,
}

export type frameProposalsResponseModel = {
    frameProposals: frameProposal[],
    leafletId: string,
    pageNumber: number
}

export type reportFramePayloadModel = {
    comment: string
}

export type reportAdvertisementPayloadModel = reportFramePayloadModel;

export function framesPageNumberOffset (frames: frame[]): frame[] {
    return frames.map((frame) => ({...frame, pageNumber: frame.pageNumber - 1}));
}
export function framePageNumberOffset (frame: frame): frame {
    return {...frame, pageNumber: frame.pageNumber - 1};
}

// templates

export const frameTemplate:frame = {
    frameId: '',
    leafletId: '',
    pageNumber: 0,
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
};
import './FileUploadOverlay.scss';
import {BackupOutlined} from '@mui/icons-material';

const FileUploadOverlay = ({show}: {show: boolean}) => {
  return (show &&
    <div className="fileUploadOverlay">
      <div className="backdrop"></div>
      <div className="uploadOverlayCircleContainer">
        <div className="uploadOverlayCircle"></div>
        <div className="uploadOverlayIconContainer">
          <div className="uploadOverlayIcon">
            <BackupOutlined color="inherit" fontSize='inherit'/>
          </div>
          <h2 className="uploadOverlayTitle">Drop Leaflets</h2>
        </div>
      </div>
    </div>
  );
};

export default FileUploadOverlay;

import {Checkbox, FormControlLabel} from '@mui/material';
import {useFormatMessage} from 'utils/translate';

interface activityFilterProps {
    value: boolean,
    onChange: (value: boolean) => void
}
const ActivityFilter = (props: activityFilterProps) => {
    const {onChange, value} = props;
    const translate = useFormatMessage();
    return (<>
            <span>{translate({id: "a.show"})}:</span>
            <FormControlLabel
                control={<Checkbox checked={value === undefined} onChange={() => onChange(undefined)}/>}
                label={translate({id: 'a.all'})}
            />
            <FormControlLabel
                control={<Checkbox checked={value === true} onChange={() => onChange(true)}/>}
                label={translate({id: 'a.active'})}
            />
            <FormControlLabel
                control={<Checkbox checked={value === false} onChange={() => onChange(false)}/>}
                label={translate({id: 'a.inactive'})}
            />
    </>);
}

export default ActivityFilter;
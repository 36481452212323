import './FrameLensZoom.scss';
import {useEffect, useRef, useState} from 'react';

interface FrameLensZoomProps {
    imgUrl: string,
    customClassName?: string
}

interface Position {
    x: number,
    y: number
}

const initialPosition: Position = {
    x: 0,
    y: 0
}

const FrameLensZoom = ({imgUrl, customClassName}: FrameLensZoomProps) => {
    const [lensPosition, setLensPosition] = useState<Position>(initialPosition);
    const [backgroundPosition, setBackgroundPosition] = useState<Position>(initialPosition);
    const [distances, setDistances] = useState<Position>(initialPosition);
    const [isLensVisible, setIsLensVisible] = useState<boolean>(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const lensRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLImageElement>(null);

    const calculateDistances = () => {
        if (containerRef.current && imgRef.current) {
          const containerRect: DOMRect = containerRef.current.getBoundingClientRect();
          const rect: DOMRect = imgRef.current.getBoundingClientRect();
          setDistances({
            x: rect.x - containerRect.x,
            y: rect.y - containerRect.y
          });
        }
      };

    useEffect(() => {
        calculateDistances();
        window.addEventListener('resize', calculateDistances);
        return () => window.removeEventListener('resize', calculateDistances);
    }, []);

    useEffect(() => calculateDistances(), [lensPosition]);

    const handleMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const img = imgRef.current;
        const lens = lensRef.current;
        if (!img || !lens) return;

        const rect: DOMRect = img.getBoundingClientRect();
        const lensWidth: number = lens.offsetWidth;
        const lensHeight: number = lens.offsetHeight;

        let x: number = e.clientX - rect.left - lensWidth / 2;
        let y: number = e.clientY - rect.top - lensHeight / 2;

        if (x > img.width - lensWidth) x = img.width - lensWidth;
        if (x < 0) x = 0;
        if (y > img.height - lensHeight) y = img.height - lensHeight;
        if (y < 0) y = 0;
        setLensPosition({ x, y });

        const backgroundX: number = (x / (img.width - lensWidth)) * 100;
        const backgroundY: number = (y / (img.height - lensHeight)) * 100;
        setBackgroundPosition({ x: backgroundX, y: backgroundY });
    };

    const handleMouseEnter = () => {
        if (imgRef.current) {
            const lensSize: number = 100; // the size of the lens is defined in the .scss file (class 'lens')
            const imgWidth: number = imgRef.current.offsetWidth;
            const imgHeight: number = imgRef.current.offsetHeight;
    
            if (imgWidth > lensSize && imgHeight > lensSize) {
              setIsLensVisible(true);
            } else return;
        }
    }

    return (
        <div className={`frameLensZoomContainer ${customClassName ? customClassName : ''}`}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={() => setIsLensVisible(false)}
            ref={containerRef}>
            <img ref={imgRef} src={imgUrl} alt=""/>
            {isLensVisible && <div ref={lensRef}
                                   className="lens"
                                   style={{
                                       left: lensPosition.x+distances.x,
                                       top: lensPosition.y+distances.y,
                                       backgroundImage: `url(${imgUrl})`,
                                       backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
                                       backgroundSize: `${imgRef.current?.width * 2}px ${imgRef.current?.height * 2}px`
                                   }}
            />}
        </div>
    );
};

export default FrameLensZoom;


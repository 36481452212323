import {isToday, isYesterday} from 'date-fns';
import {getObjectFromLocalStorage, removeFromLocalStorage, storeInLocalStorage} from './storageUtils';

export const errorsNotToLog = [401, 403]; // 409 is for duplicate check failed or cannot access due to lock. should this be logged as well?

export type faiLogEntry = {
    code: number,
    id: string,
    response: string,
    timeStamp: number
}

export const faiLogKey: string = 'faiLog';
const failIdHeaderName: string = 'x-markant-failure-id';

export function getFaiLog(): faiLogEntry[] {
    return getObjectFromLocalStorage(faiLogKey) || [];
}

function saveFaiLog(log: faiLogEntry[]) {
    storeInLocalStorage(faiLogKey, JSON.stringify(log));
}

export function clearFaiLog() {
    if (getObjectFromLocalStorage(faiLogKey)) {
        removeFromLocalStorage(faiLogKey);
    }
}

export function removeOldFaiLogEntries() {
    const log: faiLogEntry[] = getFaiLog();
    if (log.length) {
        saveFaiLog(log.filter((item) => isToday(item.timeStamp) || isYesterday(item.timeStamp)));
    }
}

export function logFailure(error) {
    const data: any = error?.response?.data;
    const id: string = error?.response?.headers[failIdHeaderName];
    const code: number = error?.response?.status;
    if (id) {
        const log: faiLogEntry[] = getFaiLog();
        const newEntry: faiLogEntry = {
            code,
            id,
            response: data || '',
            timeStamp: new Date().getTime()
        }
        log.push(newEntry);
        saveFaiLog(log);
    }
}
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button} from '@mui/material';
import {API_PRODUCT_DATA_DESCRIPTION, API_STATUSES} from 'config/api/constants';
import {paths} from 'paths';
import {productDescriptionPayload} from 'shared/models/productDescription.model';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ProductDescription from './ProductDescription';
import {transformDescriptionUpdatePayload} from './productDescriptionIO';

// @TODO quickfix to add marketId without refactoring whole productDescription thing
export const addMarketIdHaxForCreate = (productDescription: any, marketId: string): productDescriptionPayload => {
    return {...productDescription, marketId};
};

const ProductView = ({history}) => {
    const translate = useFormatMessage();

    const descriptionAPIPut = useApi('put', null, {errMsg: 'b.descriptionSaveErr', succMsg: 'b.descriptionSaveSucc', failureMsg: 'productDescription.duplicate'});
    const descriptionAPIPost = useApi('post', null, {errMsg: 'b.descriptionSaveErr', succMsg: 'b.descriptionSaveSucc', failureMsg: 'productDescription.duplicate'});

    const [isNewDescription, setIsNewDescription] = useState<boolean>(false);
    const [productDescriptionId, setProductDescriptionId] = useState<string>(null);
    const [isDescriptionValid, setIsDescriptionValid] = useState<boolean>(false);
    const [descriptionPayload, setDescriptionPayload] = useState<productDescriptionPayload>(null);
    const [originalDescription, setOriginalDescription] = useState<string>(null);

    useEffect(() => {
        const id: string = getId();
        if (id) {
            setProductDescriptionId(id);
        }
        const newDescr: string = getURLParam('new');
        if (newDescr){
            setIsNewDescription(true);
        }
    }, []);

    useEffect(() => {
        if (descriptionAPIPut.status === API_STATUSES.IDLE) history.push(paths.productDescriptionList);
    }, [descriptionAPIPut.status]);

    useEffect(() => {
        if (descriptionAPIPost.status === API_STATUSES.IDLE) history.push(paths.productDescriptionList);
    }, [descriptionAPIPost.status]);

    const handleSaveClick = () => {
        if (productDescriptionId) {
            descriptionAPIPut.call(`${API_PRODUCT_DATA_DESCRIPTION}/${productDescriptionId}`, transformDescriptionUpdatePayload(descriptionPayload));
        } else {
            descriptionAPIPost.call(API_PRODUCT_DATA_DESCRIPTION, descriptionPayload);
        }
    }

    const handleProductDescriptionChange = (description: productDescriptionPayload, descriptionValid: boolean) => {
        setIsDescriptionValid(descriptionValid);
        setDescriptionPayload(descriptionValid ? description : null);
        if (originalDescription === null && descriptionValid) {
            setOriginalDescription(JSON.stringify(description));
        }
    }

    // no check for new product as there's nothing to compare with
    const isDescriptionChanged = () => isNewDescription ? true : originalDescription !== JSON.stringify(descriptionPayload);

    const canSave = (): boolean => isDescriptionValid && isDescriptionChanged();

    return (
        <div className="viewRoot productDescriptionRoot">
            <div className="viewport">
                <div className="viewContainer">
                    <LoadingOverlay show={descriptionAPIPut.status === API_STATUSES.PENDING || descriptionAPIPost.status === API_STATUSES.PENDING}/>
                    <ProductDescription onProductDescriptionChange={handleProductDescriptionChange} descriptionId={productDescriptionId}/>
                </div>
            </div>
                <Footer actionsRight={
                            <>
                                <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>
                                <Link to={paths.productDescriptionList}>
                                    <ButtonClose/>
                                </Link>
                            </>
                        }
                />
        </div>
    )
}

export default ProductView;
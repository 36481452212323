export type retailerHQListItem = {
    branchIds: string[],
    id: string,
    name: string
    retailerId: string
    retailerName: string
}

export type retailerHQ = {
    id?: string,
    name: string,
    retailerId: string,
    retailerName: string,
    branchIds: string[]
}

export const retailerHQTemplate: retailerHQ = {
    name: '',
    retailerId: '',
    retailerName: '',
    branchIds: []
}

export type retailerHQPayload = {
    branchIds: string[],
    name: string,
    retailerId: string
}
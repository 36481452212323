import './PromotionsTableEraseDrawer.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Divider, Drawer} from '@mui/material';
import {promotionUI} from 'shared/models/promotion.model';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import {
    pricePropNames,
    PriceRow,
    LiteralsRow,
    literalPropNames,
    DatesRow,
    BundlesRow
} from './PromotionsTableEraseRows';

interface PromotionsTableEraseDrawerProps {
    data: promotionUI[],
    onClose: () => void,
    onConfirm: (payload: props2eraseType) => void,
    open: boolean,
}

export type props2eraseType = {
    [key in pricePropNames|literalPropNames|string]: boolean
};

const PromotionsTableEraseDrawer = ({data, onClose, onConfirm, open}: PromotionsTableEraseDrawerProps) => {

    const [props2erase, setProps2erase] = useState<props2eraseType>({});

    useEffect(() => {
        if (open) {
            setProps2erase({});
        }
    }, [data, open]);

    const handleEraseToggle = (propertyName: pricePropNames|literalPropNames|string, value: boolean) => {
        const newProps2erase = {...props2erase};
        newProps2erase[propertyName] = value;
        setProps2erase(newProps2erase);
    };

    return (
        <Drawer className="promotionsTableEraseDrawerRoot" open={open} onClose={onClose} anchor="right">
            <div className="viewContainer _directionRow">
                <PaperX className="_fullHeight _fullWidth _scrollY">
                    <PriceRow data={data} label="b.promotionalPrice" propName={pricePropNames.promotionalPrice}
                              onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <PriceRow data={data} label="b.regularPrice" propName={pricePropNames.regularPrice}
                              onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <PriceRow data={data} label="b.relativeDiscount" propName={pricePropNames.relativeDiscount}
                              onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <PriceRow data={data} label="b.absoluteDiscount" propName={pricePropNames.absoluteDiscount}
                              onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <PriceRow data={data} label="b.mpu" propName={pricePropNames.mpu}
                              onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <Divider></Divider>
                    <LiteralsRow data={data} label="b.promotionType" propName={literalPropNames.promotionTypes}
                                 onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <LiteralsRow data={data} label="b.promotionSeasonTheme" propName={literalPropNames.themeSeasons}
                                 onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <LiteralsRow data={data} label="b.countryThemedWeek" propName={literalPropNames.countryThemedWeeks}
                                 onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <LiteralsRow data={data} label="b.promotionQS" propName={literalPropNames.qualitySeals}
                                 onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <Divider></Divider>
                    <DatesRow data={data}
                              onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                    <Divider></Divider>
                    <BundlesRow data={data}
                                onChange={(propName, value) => handleEraseToggle(propName, value)}/>
                </PaperX>
            </div>
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={() => onConfirm(props2erase)}>
                            <FormattedMessage id="frameDescription.eraseAction"/>
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.cancel"/></Button>
                    </>
                }
            />
        </Drawer>
    );
};

export default PromotionsTableEraseDrawer;
import './SummaryCard.scss';

import {FormattedMessage} from 'react-intl';

type summaryCardContentItem = {
    label: string, //translation id
    value: string | React.ReactNode, // can be string or react element
    optionalClass?: string
}

interface summaryCardProps {
    content: summaryCardContentItem[]
}

const getClassName = (item: summaryCardContentItem): string => item.optionalClass ?`summaryRow ${item.optionalClass}` : 'summaryRow';

const SummaryCard = (props:summaryCardProps) => {
    const {content} = props;
    const labels = content.map((item) => <div key={item.label} className={getClassName(item)}><FormattedMessage id={item.label}/></div>);
    const values = content.map((item) => <div key={item.label} className={getClassName(item)}>{item.value}</div>);
    return (
        <div className="summaryCardRoot">
            <div className="summaryCardContainer">
                <div className="labels">
                    {labels}
                </div>
                <div className="values">
                    {values}
                </div>
            </div>
        </div>
    );
};
export default SummaryCard;
import './ProductSuggestion.scss';

import {FormattedMessage} from 'react-intl';
import {basic} from 'shared/models/_common.model';
import {productSuggestion} from 'shared/models/frame.model';
import {productSearchResultUI} from 'shared/models/product.model';
import {FramePreview} from 'components/gfx';
import {GeneralInfoDisplay, PriceDisplay, ProductDetails} from 'components/Displays';

interface ProductSuggestionProps {
    data: productSuggestion,
    onClick: (product: productSearchResultUI) => void
}

const ProductSuggestion = (props: ProductSuggestionProps) => {
    const {
        absoluteDiscount,
        // bpco, //@TODO BPCO is available if needed
        // countryThemedWeeks, //@ TODO currently incompatible, BE to be adjusted
        currency,
        mpu,
        product,
        promotionObjectType,
        // promotionTypes, //@ TODO currently incompatible, BE to be adjusted
        promotionalPrice,
        // qualitySeals, //@ TODO currently incompatible, BE to be adjusted
        regularPrice,
        relativeDiscount,
        // themeSeasons, //@ TODO currently incompatible, BE to be adjusted
        frameId,
    } = props.data;
    return <div className="productSuggestionRoot" onClick={() => props.onClick({...product, promotionObjectType, relatedPromotionsCount: null})}>
        {/*@TODO upgrade with zoom feature when frame management is merged*/}
        <FramePreview frameId={frameId}/>
        <h3><FormattedMessage id="b.product"/></h3>
        <ProductDetails product={product}/>
        <h3><FormattedMessage id="b.price"/></h3>
        <PriceDisplay absoluteDiscount={absoluteDiscount}
                      currency={currency}
                      mpu={mpu}
                      promotionalPrice={promotionalPrice}
                      regularPrice={regularPrice}
                      relativeDiscount={relativeDiscount}/>
        <h3><FormattedMessage id="b.general"/></h3>
    </div>;
};

export default ProductSuggestion;

interface GeneralInformationProps {
    countryThemedWeeks: basic[]
    promotionTypes: basic[]
    qualitySeals: basic[]
    themeSeasons: basic[]
}

export const GeneralInformation = (props: GeneralInformationProps) => {
    const {countryThemedWeeks, promotionTypes, qualitySeals, themeSeasons} = props;
    const anything: number = countryThemedWeeks.length + promotionTypes.length + qualitySeals.length + themeSeasons.length;

    if (anything) {
        return <>
            <h3><FormattedMessage id="b.general"/></h3>
            <GeneralInfoDisplay countryThemedWeeks={countryThemedWeeks.map((item) => item.name)}
                                promotionTypes={promotionTypes.map((item) => item.name)}
                                themeSeasons={themeSeasons.map((item) => item.name)}
                                qualitySeals={qualitySeals.map((item) => item.name)}/>
            </>;
    }
    return null;
}
import {
    pagePromotionTypes,
    pagePromotionTypesBE,
    pagePromotionTypesBEGrouped,
    pagePromotionTypesBEGroupedWithRanges,
    pagePromotionTypesBEPageRangesStringified,
    validateResult
} from 'shared/models/leaflet.model';
import {handyAttributes} from 'shared/handyAttributes';
import {multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {lastOfLast} from 'modules/Leaflet/HeaderData/pageValiditiesIO';

function restorePromotionTypeAttribute (promotionType: string[]): multiselectAttributeDataModel {
    return {
        id: handyAttributes.promotionType,
        literals: promotionType ? promotionType.map((item) => ({literalId: item})) : []
    }
}

export function importPagePromotionTypes(promotionTypesBE: pagePromotionTypesBE[]): pagePromotionTypes[] {
    const convert = (data: pagePromotionTypesBEPageRangesStringified): pagePromotionTypes => {
        return {
            pageNumber: data.pageNumber,
            promotionTypes: restorePromotionTypeAttribute(data.promotionTypeIds),
            modified: false
        }
    };

    const groupedByPromotionTypes: pagePromotionTypesBEGrouped[] = [];
    if (promotionTypesBE) {
        promotionTypesBE.forEach((item) => {
            const existingPromotionTypesGroupIndex = groupedByPromotionTypes.findIndex((existing) => {
                for (let id in existing.promotionTypeIds) {
                    if (!existing.promotionTypeIds.includes(item.promotionTypeIds[id])) {
                        return false;
                    }
                }
                return true;
            });
            if (existingPromotionTypesGroupIndex > -1) {
                groupedByPromotionTypes[existingPromotionTypesGroupIndex].pageNumber.push(item.pageNumber);
            } else {
                const newItem: pagePromotionTypesBEGrouped = {pageNumber: [], promotionTypeIds: item.promotionTypeIds};
                newItem.pageNumber = [item.pageNumber];
                groupedByPromotionTypes.push(newItem);
            }
        });
    }

    const withPromotionTypesSorted: pagePromotionTypesBEGrouped[] = groupedByPromotionTypes.map((item) => {
        const newItem: pagePromotionTypesBEGrouped = {...item};
        newItem.pageNumber = item.pageNumber.sort((a, b) => a - b);
        return newItem;
    });

    const withRangesGrouped: pagePromotionTypesBEGroupedWithRanges[] = withPromotionTypesSorted.map((item) => {
        const newItem: pagePromotionTypesBEGroupedWithRanges = {pageNumber: [], promotionTypeIds: item.promotionTypeIds};
        const ranges = [];
        item.pageNumber.forEach((pgNo, idx) => {
            if (idx === 0) {
                ranges.push([pgNo]);
            } else {
                const lastest = lastOfLast(ranges);
                if (lastest && lastest + 1 === pgNo) {
                    ranges[ranges.length-1].push(pgNo);
                } else {
                    ranges.push([pgNo]);
                }
            }
        });
        newItem.pageNumber = ranges;
        return newItem;
    });

    const withRangesStringified: pagePromotionTypesBEPageRangesStringified[] = withRangesGrouped.map((item) => {
        const newItem: pagePromotionTypesBEPageRangesStringified = {pageNumber: '', promotionTypeIds: item.promotionTypeIds};
        let pagesString: string = '';
        item.pageNumber.forEach((pgRange) => {
            if (pgRange.length === 1) {
                pagesString += pgRange[0] + ',';
            } else {
                pagesString += pgRange[0] + '-' + pgRange[pgRange.length - 1] + ',';
            }
        });
        newItem.pageNumber = pagesString.slice(0, pagesString.length - 1);
        return newItem;
    });
    return withRangesStringified.map((item) => convert(item));
}

export function exportPagePromotionTypes(pagePromotionTypesUI: pagePromotionTypes[]): pagePromotionTypesBE[] {
    const transformed: pagePromotionTypesBE[] = [];
    pagePromotionTypesUI.forEach((item) => {
        const byComa = item.pageNumber.split(',');
        byComa.forEach((pgOrRange) => {
            if (pgOrRange.indexOf('-') > 0) {
                const startEnd = pgOrRange.split('-');
                for (let i = parseInt(startEnd[0]); i <= parseInt(startEnd[1]); i++) {
                    transformed.push({pageNumber: i, promotionTypeIds: item.promotionTypes.literals.map((literal) => literal.literalId)});
                }
            } else {
                transformed.push({pageNumber: parseInt(pgOrRange), promotionTypeIds: item.promotionTypes.literals.map((literal) => literal.literalId)});
            }
        });
    });
    return transformed;
}

export function validatePromotionType(promotionType: pagePromotionTypesBE[]): validateResult {
    let duplicates = new Set([]);
    const pageNumbers = promotionType.map((item) => item.pageNumber);
    pageNumbers.forEach((pgNo) => {
        const duplicate = pageNumbers.filter((item) => item === pgNo);
        if (duplicate.length > 1) {
            duplicates.add(pgNo);
        }
    });
    return duplicates.size ? {hasError: true, duplicatePages: Array.from(duplicates)} : {hasError: false};
}
import {leafletSummary, leafletMetaData, pageValidityBE} from 'shared/models/leaflet.model';
import {headerData} from 'shared/models/leaflet.model';
import {FormattedMessage} from 'react-intl';

interface leafletSummaryProps {
    data: leafletSummary,
    frameValidity?: string
}

export const buildLeafletSummary = (headerData: headerData, metaData: leafletMetaData, pageData: pageValidityBE): leafletSummary => {
    return {
        banner: headerData.bannerName,
        repBranch: headerData.leafletRepresentativeBranchName,
        numberOfPages: metaData.numberOfPages,
        pageNumber: pageData.pageNumber,
        leafletValidity: {
            validFrom: headerData.validFrom,
            validTo: headerData.validTo
        },
        pageValidity: {
            validFrom: pageData.validFrom,
            validTo: pageData.validTo
        }
    }
}

const LeafletSummary = (props: leafletSummaryProps) => {
    const {data, frameValidity} = props;
    const leafletValidtyOverride: boolean = !!data?.pageValidity?.validFrom;
    const pageValidtyOverride: boolean = !!frameValidity;
    return <div>
        <FormattedMessage id="b.banner"/>: {data && <span className="_bold">{data?.banner}</span>}
        <br/>
        <FormattedMessage id="b.representativeBranch"/>: {data && <span className="_bold">{data?.repBranch}</span>}
        <br/>
        <FormattedMessage id="headerData.pages"/>: {data && <span className="_bold">{data?.pageNumber} <FormattedMessage id="a.of"/> {data?.numberOfPages}</span>}
        <br/>
        <FormattedMessage id="b.leafletValidity"/>: {data && <span className={`_bold ${leafletValidtyOverride && '_disabled'}`}>{data?.leafletValidity.validFrom} - {data?.leafletValidity.validTo}</span>}
        <br/>
        <FormattedMessage id="b.pageValidity"/>: {data && <span className={`_bold ${pageValidtyOverride && '_disabled'}`}>{data?.pageValidity.validFrom} - {data?.pageValidity.validTo}</span>}
    </div>
}

export default LeafletSummary;
import {v4 as uuidv4} from 'uuid';

import {characteristicModel} from 'shared/models/attribute.model';
import {
    characteristicUI,
    extendedProductSearchResultUI,
    productDetailsResponseModel,
    productSearchResultUI
} from 'shared/models/product.model';
import {
    PROMOTION_TYPES,
    promotionSuggestionsForFrame,
    promotionUI
} from 'shared/models/promotion.model';
import {handyAttributes} from 'shared/handyAttributes';
import {BPCOResponseModel} from 'shared/models/BPCO.model';
import {getObjectFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';

function convertCharacteristics(productDetailsResponseCharacteristics: characteristicModel[]): characteristicUI[] {
    return productDetailsResponseCharacteristics.map((item) => {
        if ('text' in item) { // freetext
            return {
                id: item.attributeId,
                name: item.attributeName,
                value: item.text
            };
        } else if ('valueFrom' in item) { // range
            let value: string = item.valueFrom + '';
            if (item.valueTo !== item.valueFrom) {
                value += ` - ${item.valueTo}`;
            }
            value += ` ${item.unitName}`;
            // @TODO thats full name, while product from search results use short names so we get inconsistency depending if we create promotion from product search or product get API
            return {
                id: item.attributeId,
                name: item.attributeName,
                value
            };
        } else if ('value' in item) { // value with unit
            return {
                id: item.attributeId,
                name: item.attributeName,
                value: `${item.value} ${item.unitName}`
                // @TODO thats full name, while product from search results use short names so we get inconsistency depending if we create promotion from product search or product get API
            }
        }
        else { // literal
            return {
                id: item.attributeId,
                name: item.attributeName,
                value: item.literalName,
                // valueId: item.literalId
            };
        }
    });
}

export function productDetails2productSearchResultUI(product: productDetailsResponseModel): productSearchResultUI {
    const gtin = product?.gtins.find((item) => item.active === true);
    const productSearchResultUIObject: productSearchResultUI = {
        brand: product.brandName,
        category: product.categoryBrickName,
        characteristics: convertCharacteristics(product.characteristics),
        description: product.mainDescriptions.map((item) => `${item.description} (${item.language})`).join(', '),
        id: product.id,
        isTemplate: product.isTemplate,
        promotionObjectType: PROMOTION_TYPES.PRODUCT, // its always product, BPCOs will be implemented in future if needed
        relatedPromotionsCount: null //productDetailsResponseModel does not contain that information nor it is needed here
    };
    if (gtin) {
        productSearchResultUIObject.gtin = gtin.value;
    }
    return productSearchResultUIObject;
}

export function productsDetailsForGiveAwayUI(product: productDetailsResponseModel): extendedProductSearchResultUI {
    const gtin = product?.gtins.find((item) => item.active === true);
    const alternativeDescription = product.alternativeDescriptions.map((item) => item.description !== null ? `${item.description} (${item.language})` : null);
    const alternativeDescriptionFiltered = alternativeDescription.filter(item => item !== null).join(', ');

    const productSearchResultUIObject: extendedProductSearchResultUI = {
        alternativeProductId: null,
        active: product.active,
        alternativeDescription: alternativeDescriptionFiltered,
        brand: product.brandName,
        category: product.categoryBrickName,
        characteristics: convertCharacteristics(product.characteristics),
        description: product.mainDescriptions.map((item) => `${item.description} (${item.language})`).join(', '),
        gtin: gtin ? gtin.value : '',
        id: product.id,
        isTemplate: product.isTemplate,
        marketId: product.marketId,
        productLineId: null,
        productLineName: null,
        promotionObjectType: PROMOTION_TYPES.PRODUCT,
        relatedPromotionsCount: null //productDetailsResponseModel does not contain that information nor it is needed here
    };
    return productSearchResultUIObject;
}

export function BPCODetails2productSearchResultUI(BPCO: BPCOResponseModel): productSearchResultUI {
    const gtin = BPCO?.gtins?.find((item) => item.active === true);
    const BPCOSearchResultUIObject: productSearchResultUI = {
        brand: BPCO.brandName,
        category: BPCO.categoryBrickName,
        characteristics: convertCharacteristics(BPCO.characteristics),
        description: BPCO.mainDescriptions.map((item) => `${item.description} (${item.language})`).join(', '),
        id: BPCO.id,
        isTemplate: false,// BPCO.isTemplate,
        promotionObjectType: PROMOTION_TYPES.BPCO, // its always product, BPCOs will be implemented in future if needed
        relatedPromotionsCount: null //BPCOResponseModel does not contain that information nor it is needed here
    };
    if (gtin) {
        BPCOSearchResultUIObject.gtin = gtin.value;
    }
    return BPCOSearchResultUIObject;
}

export const transformPromotionsSuggestions = (data: promotionSuggestionsForFrame): promotionUI[] => promotionSuggestionsBE2FE(data);

const promotionSuggestionsBE2FE = ({promotions}: promotionSuggestionsForFrame): promotionUI[] => promotions
    .map((item) => {
        const selectedItemProps = item.promotionObjectType === PROMOTION_TYPES.PRODUCT ? item.product : item.bpco;

        const {
            brand,
            category,
            characteristics
        } = selectedItemProps;

        const singlePromotion:promotionUI = {
            UIId: uuidv4(),
            absoluteDiscount: item.absoluteDiscount || null,
            brand,
            category,
            characteristics,
            countryThemedWeeks: {id: handyAttributes.countryThemedWeeks, literals: []},
            currency: item.currency,
            description: '',
            giveAwayBundledProducts: item.giveAwayBundledProducts,
            hasError: false,
            mpu: item.mpu,
            promotionObjectType: item.promotionObjectType,
            promotionTypes: {id: handyAttributes.promotionType, literals: []},
            promotionalPrice: item.promotionalPrice || null,
            qualitySeals: {id: handyAttributes.qualitySeal, literals: []},
            regularPrice: item.regularPrice || null,
            relativeDiscount: item.relativeDiscount || null,
            themeSeasons: {id: handyAttributes.themeSeason, literals: []},
            validityDates: [],
        }

        if (item.promotionTypes?.length) {
            item.promotionTypes.forEach((promoType) => singlePromotion.promotionTypes.literals.push({literalId: promoType.id, literalName: promoType.name}));
        }
        if (item.qualitySeals?.length) {
            item.qualitySeals.forEach((qualitySeal) => singlePromotion.qualitySeals.literals.push({literalId: qualitySeal.id, literalName: qualitySeal.name}));
        }
        if (item.themeSeasons?.length) {
            item.themeSeasons.forEach((themeSeason) => singlePromotion.themeSeasons.literals.push({literalId: themeSeason.id, literalName: themeSeason.name}));
        }
        if (item.countryThemedWeeks?.length) {
            item.countryThemedWeeks.forEach((countryThemedWeeks) => singlePromotion.countryThemedWeeks.literals.push({literalId: countryThemedWeeks.id, literalName: countryThemedWeeks.name}));
        }

        if (item.promotionObjectType === PROMOTION_TYPES.PRODUCT) {
            singlePromotion.productId = selectedItemProps.id;
            singlePromotion.isTemplate = item.product.isTemplate;
            singlePromotion.description = item.product.description;
        } else {
            singlePromotion.bpcoId = selectedItemProps.id;
            singlePromotion.description = item.bpco.description;
            singlePromotion.characteristics = characteristics.filter((item) => item.id !== handyAttributes.description);
        }

        return singlePromotion;
    });

export const addDerivedLeafletValues = (promotions: promotionUI[], derivedThemeSeasons: string[] = null, derivedThemedWeeks: string[] = null, derivedPromotionTypes: string[] = null): promotionUI[] =>
    promotions
        .map((item) => ({...item,
            derivedThemeSeasons: {
                id: handyAttributes.themeSeason,
                // literals: leafletData.headerData.themeSeasons ? leafletData.headerData.themeSeasons.map((item) => ({literalId: item})) : []
                literals: derivedThemeSeasons ? derivedThemeSeasons.map((item) => ({literalId: item})) : []
            },
            derivedThemedWeeks: {
                id: handyAttributes.countryThemedWeeks,
                literals: derivedThemedWeeks ? derivedThemedWeeks.map((item) => ({literalId: item})) : []
            },
            derivedPromotionTypes: {
                id: handyAttributes.promotionType,
                literals: derivedPromotionTypes ? derivedPromotionTypes.map((item) => ({literalId: item})) : []
            }

        }));

const frameDescriptionBackupKey: string = 'frameDescrBackup';

type storedPromotions = {
    frameId: string,
    promotions: promotionUI[]
}

export function persistPromotions (frameId: string, promotions: promotionUI[]) {
    storeInLocalStorage(frameDescriptionBackupKey, JSON.stringify({frameId, promotions}));
}

export function restorePromotions (frameId: string): promotionUI[] {
    const savedPromotions: storedPromotions = getObjectFromLocalStorage(frameDescriptionBackupKey);
    if (savedPromotions && savedPromotions.frameId === frameId && Array.isArray(savedPromotions.promotions)) {
        return savedPromotions.promotions;
    }
    return [];
}
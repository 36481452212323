import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import axios from 'axios';
import {parseParams} from 'utils/axiosHooks/axiosHooks';
import {useSnackbar} from 'notistack';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {API_RADIO_SPOTS_OVERVIEW} from 'config/api/constants';
import {paths} from 'paths';
import {radioSpotOverviewRow, spotFilters} from 'shared/models/media.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {validityDatesGetter} from 'components/AgTable/getters';
import {SpotsOverviewLabel} from 'modules/Medium/RadioSpot/RadioSpotOverview/RadioSpotOverviewActions';
import RadioSpotOverviewFilters from 'modules/Medium/RadioSpot/RadioSpotOverview/RadioSpotOverviewFilters';

const AdvertisementSpotOverview = (props) => {
    const translate = useFormatMessage();
    const cancelToken = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    
    const dateFns = new AdapterDateFns();

    const [data, setData] = useState<radioSpotOverviewRow[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // @TODO there's reusable notifyError utility function available now, use when merged with dev
    const notifyError = () => enqueueSnackbar(`${translate({id: 'media.radioFetchErr'})}`, {variant: 'error', persist: false});

    const getData = (filters: spotFilters) => {
        setIsLoading(true);
        setData([]);
        axios.get<radioSpotOverviewRow[]>(API_RADIO_SPOTS_OVERVIEW, {
            params: filters,
            paramsSerializer: (params) => parseParams(params),
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if(responseValidation(resp.data)) {
                    setData(resp.data.map((item) => ({
                        ...item,
                        status: item.status,
                        createdAt: item.createdAt ? dateFns.formatByString(new Date(item.createdAt), 'yyyy/MM/dd kk:mm:ss') : ''
                    })));
                } else {
                    notifyError();
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    notifyError();
                }
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {
        return () => cancelToken.current && cancelToken.current();
    }, []);

    const spotsColumnDefs: ColDef[] = [
        { field: 'banner', headerName: translate({ id: 'b.banner' }), flex: 1, lockVisible: true},
        { field: 'language', headerName: translate({id: 'dictionaryElement.lang'}), flex: 1, maxWidth: 120, valueGetter: (params) => params.data.language ? (translate({id: 'language.'+ params.data.language})) : ''},
        { field: 'status', headerName: translate({ id: 'printMediaOverview.status' }), maxWidth: 200, cellRenderer: (params) => <SpotsOverviewLabel value={params.value}/>},
        { field: 'validityDates', headerName: translate({ id: 'printMediaOverview.validityDates' }), flex: 1, valueGetter: (params) => (validityDatesGetter(params.data))},
        { field: 'createdAt', headerName: translate({ id: 'printMediaOverview.creationDate'}), maxWidth: 200},
        { field: 'createdBy', headerName: translate({ id: 'printMediaOverview.createdBy'}), maxWidth: 120},
        { field: 'modifiedBy', headerName: translate({ id: 'printMediaOverview.modifiedBy'}), maxWidth: 120}
    ];

    const handleFiltersChange = (filters: spotFilters) => {
        if (!filters) return;
        getData(filters);
    };

    const goToSpot = (spotId) => props.history.push(`${paths.advertisementBrowserMedia}?id=${spotId}&from=${paths.advertisementsSpotOverview}`);

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <RadioSpotOverviewFilters onFilterChange={(filters) => handleFiltersChange(filters)} view="advertisement"/>
                    </PaperX>
                    <PaperX className="_fullHeight _fullTable">
                        <AgTable
                            defaultColDef={{
                                suppressMenu: false,
                                resizable: true,
                                sortable: true
                            }}
                            rowData={data}
                            columnDefs={spotsColumnDefs}
                            onRowClicked = {(rowData) => goToSpot(rowData.data.id)}
                            localStorageColumnsKey={localStorageColumnsKeys.advertisementsSpotsOverview}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>
    );
}

export default AdvertisementSpotOverview;
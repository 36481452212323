import {useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {API_PRODUCT_SEARCH} from 'config/api/constants';
import {paths} from 'paths';
import {productSearchResultResponse} from 'shared/models/product.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {ButtonClose} from 'components/Buttons';
import {PaperX} from 'components/PaperX';
import {
    productSearchTableRow,
    productsListSearchQueryStrLocalStorageKey,
    transformResponse
} from 'modules/MasterData/Product/ProductsListSearchResults';
import ProductBulkChangeFilters, {productBulkChangeFilters} from 'modules/MasterData/Product/ProductBulkChange/ProductBulkChangeFilters';
import ProductBulkDescriptionTable from './ProductBulkDescriptionTable';
import ProductBulkDescriptionDialog from './ProductBulkDescriptionDialog';

const ProductBulkDescription = () => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProductBulkDescriptionDialogOpen, setIsProductBulkDescriptionDialogOpen] = useState<boolean>(false);
    const [productsTableData, setProductsTableData] = useState<productSearchTableRow[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<productSearchTableRow[]>([]);
    const [filters, setFilters] = useState<productBulkChangeFilters>(null);

    const cancelToken = useRef(null);

    const getData = (filters: productBulkChangeFilters) => {
        if (cancelToken?.current) {
            cancelToken.current();
        }
        setIsLoading(true);
        setSelectedProducts([]);
        setProductsTableData([]);
        axios.get<productSearchResultResponse>(API_PRODUCT_SEARCH, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )
        })
            .then((resp) => {
                setIsLoading(false);
                if (responseValidation(resp.data)) {
                    setProductsTableData(transformResponse(resp.data));
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    setIsLoading(false);
                }
            });
    };

    const handleFiltersChange = (f: productBulkChangeFilters) => {
        if (f.search) {
            setFilters(f);
            getData(f)
        }
    };

    const handleDialogClose = (updateSuccessful: boolean) => {
        setIsProductBulkDescriptionDialogOpen(false);
        if (updateSuccessful) {
            setProductsTableData([]);
            setSelectedProducts([]);
            enqueueSnackbar(
                `${translate({id: 'product.bulkSucc'})}. ${translate({id: 'product.bulkSucc2'})}`,
                {
                    variant: 'success',
                    persist: false
                });
        }
    };

    return (
        <>
            <div className="viewRoot">
                <div className="viewport">
                    <LoadingOverlay show={isLoading}/>
                    <div className="viewContainer _directionCol">
                        <PaperX>
                            <div className="filters noMargins">
                                <ProductBulkChangeFilters onFilterChange={(f) => handleFiltersChange(f)}
                                                          onReloadClick={() => getData(filters)}
                                                          restoredQueryStrKey={productsListSearchQueryStrLocalStorageKey}/>
                                {productsTableData?.length > 99 && <span className="_bold"><FormattedMessage id="a.searchLimit"/></span>}
                            </div>
                        </PaperX>
                        <PaperX className="_fullHeight _fullWidth _fullTable _customTooltipPositionFix">
                            <ProductBulkDescriptionTable data={productsTableData}
                                                    onSelectionChange={(products) => setSelectedProducts(products)}/>
                        </PaperX>
                    </div>
                </div>
                <Footer
                    actionsRight={
                        <>
                            <Button variant="contained"
                                    onClick={() => setIsProductBulkDescriptionDialogOpen(true)}
                                    disabled={selectedProducts.length < 2}>
                                <FormattedMessage id="product.bulkUpdate" values={{count: selectedProducts.length}}/>
                            </Button>
                            <Link to={paths.productsList}>
                                <ButtonClose/>
                            </Link>
                        </>
                    }
                />
            </div>
            <ProductBulkDescriptionDialog data={selectedProducts}
                                          onClose={(updateSuccessful) => handleDialogClose(updateSuccessful)}
                                          open={isProductBulkDescriptionDialogOpen}
            />
        </>
    );
};

export default ProductBulkDescription;
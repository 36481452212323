/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link, useHistory} from 'react-router-dom';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {categoryBEModel, categoryType, categoryUIModel} from 'shared/models/category.model';
import {IRootState} from 'shared/reducers';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable from 'components/AgTable/AgTable';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {ConfirmDialog} from 'components/ConfirmDialog';
import {dictElemColumnDefs, dictionary2arrayX} from 'modules/Dictionaries/Literals/Literal';
import {CategorySearch, getTypeLabel} from 'modules/MasterData/ProductCategories/CategorySearch';
import {buildCategoryUItemplate, transform4BE, transform4UI} from 'modules/MasterData/Category/categoryIO';
import {useCategoryPost, useCategoryPut, useCategoryQuery} from './categoryAPI';
import {categoryChanged, categoryCreated} from './CategoriesHierarchy';

const CategoryDetails = () => {
    const translate = useFormatMessage();
    const history = useHistory();
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [catId, setCatId] = useState<string>();
    const [catType, setCatType] = useState<categoryType>(null);
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
    const [hasParentId, setHasParentId] = useState<boolean>();
    const [lock4edit, setLock4edit] = useState<boolean>(false);
    const [data, setData] = useState<categoryUIModel>(buildCategoryUItemplate(categoryType.SEGMENT));
    const [deactivated, isDeactivated] = useState<boolean>(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

    const categoryQuery = useCategoryQuery(catId, selectedLanguage, false);
    const updateCategoryAPI = useCategoryPut(catId, transform4BE(data), () => storeId());
    const createCategoryAPI = useCategoryPost(transform4BE(data), (response) => storeId(response));

    useEffect(() => {
        const id = getId()? getId : getURLParam('parentId');
        setHasParentId(getURLParam('parentId') ? true : false);
        if (id) {
            setCatId(id);
        } else {
            const type = getURLParam('type');
            if (type) {
                setCatType(categoryType[type]);
                setIsCreateMode(true);
            }
        }
    }, []);

    useEffect(() => {
        if (catId) {
            categoryQuery.refetch();
        }
    }, [catId]);

    useEffect(() => {
        if (catType && !catId) {
            setData(buildCategoryUItemplate(catType));
        }
    }, [catType]);

    useEffect(() => {
        if (categoryQuery.data) {
            if (hasParentId){
                const data = transform4UI(categoryQuery.data);
                data.translations = dictionary2arrayX({en: ''});
                setData(data);
            }else {
                setData(transform4UI(categoryQuery.data));
            }
        }
    }, [categoryQuery.data])

    const handleCellChange = (event) => {
        const newData = {...data};
        newData.translations[event.rowIndex] = event.data;
        setData(newData);
    }

    const getTableHeaderTranslationKey = (type:categoryType):string => {
        switch (type) {
            case categoryType.SEGMENT:
                return 'category.sName';
            case categoryType.FAMILY:
                return 'category.fName';
            case categoryType.CLASS:
                return 'category.cName';
            case categoryType.BRICK:
                return 'category.bName';
            default:
                return 'a.error'
        }
    }

    const validateCategory = (categoryToSave:categoryBEModel):boolean => {
        let isValid = true;
        if (categoryToSave.type !== categoryType.SEGMENT) {
            if (!categoryToSave.parentCategoryId) {
                isValid = false;
            }
        }
        if (!categoryToSave.active) {
            if (!categoryToSave.alternativeCategoryId) {
                isValid = false;
            }
        }
        if (!categoryToSave.translations['en']) {
            isValid = false;
        }
        return isValid;
    }

    const canSave = ():boolean => !lock4edit && validateCategory(transform4BE(data));

    const handleActiveChange = (key, value) => {
        setData({...data, active: value, alternativeCategory: {id: '', name: '', type: data.type}});
        isDeactivated(!value);
    }

    const storeId = (response?) => {
        if (response) {
            if (response.data.type !== categoryType.BRICK){
                localStorage.setItem(categoryCreated, JSON.stringify(response.data));
            }
        }else {
            if (data.type !== categoryType.BRICK){
                localStorage.setItem(categoryChanged, JSON.stringify(data));
            }
        }
        history.push(`${paths.categoriesHierarchy}`)
    }

    const handleSelectParentCategory = (value) => setData({...data, parentCategory: value});

    const handleSelectAlternativeCategory = (value) => setData({...data, alternativeCategory: value});

    const handleSaveClick = () => {
        if (deactivated){
            setConfirmationDialogOpen(true)
        }else {
            handleSaveConfirm();
        }
    }

    const handleSaveConfirm = () => {
        setConfirmationDialogOpen(false);
        if (catId){
            if (hasParentId){
                createCategoryAPI.mutate();
            }else {
                updateCategoryAPI.mutate();
            }
        }else {
            createCategoryAPI.mutate();
        }
    }

    const buildConfirmationMsg = () => <p>{`${translate({id:getTypeLabel(data.type)})} ${translate({id: 'hierarchy.confirmation1'})}`}
        <br/>
        <br/>
        {`${translate({id: 'category.confirmation'})}`}
    </p>;

    const confMsg = useMemo(() => buildConfirmationMsg(), []);

    return (
        <div className="viewRoot categoryDetailsRoot">
            <div className="viewport">
                <LoadingOverlay show={updateCategoryAPI.isLoading || createCategoryAPI.isLoading || categoryQuery.isLoading} />
                <div className="viewContainer _directionRow">
                    <PaperX className="_halfWidth">
                        {data && data.parentCategory && <CategorySearch customLabel={`${translate({id: "category.parent"})} [${translate({id: getTypeLabel(data.parentCategory.type)})}]`}
                                                                        getOnlyIfActiveFlagEquals={true}
                                                                        onCategorySelect={handleSelectParentCategory}
                                                                        value={data.parentCategory}
                                                                        categoryType={data.parentCategory.type} />}
                        <ActiveCheckbox tabIndex={-1} value={data.active} onChange={handleActiveChange} disabled={isCreateMode || !data.active}/>
                        <CategorySearch customLabel={`${translate({id: "category.alternative"})} [${translate({id: getTypeLabel(data.type)})}]`}
                                        getOnlyIfActiveFlagEquals={true}
                                        onCategorySelect={handleSelectAlternativeCategory}
                                        value={data.alternativeCategory}
                                        disabled={data.active}
                                        categoryType={data.type} />
                    </PaperX>
                    <div className="_halfWidth _directionCol">
                        <PaperX className="weldBottom">
                            <div className="_header">{translate({id: getTableHeaderTranslationKey(data.type)})}</div>
                        </PaperX>
                        <PaperX className="weldTop _fullWidth _fullHeight _fullTable">
                            <AgTable
                                rowData={data.translations}
                                columnDefs={dictElemColumnDefs(translate)}
                                defaultColDef={{suppressMenu: true}}
                                onCellValueChanged={handleCellChange}
                                onCellEditingStarted={() => setLock4edit(true)}
                                onCellEditingStopped={() => setLock4edit(false)}
                                tooltipShowDelay={1000}
                            />
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={!canSave()}>
                            <FormattedMessage id="a.save"/>
                        </Button>
                        <Link to={paths.categoriesHierarchy}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
            <ConfirmDialog open={confirmationDialogOpen}
                           onConfirm={handleSaveConfirm}
                           onCancel={() => setConfirmationDialogOpen(false)}
                           message={confMsg}
                           confirmLabelId="a.save"
                           cancelLabelId="a.cancel"/>
        </div>
    )
}

export default CategoryDetails;
import {useFormatMessage} from 'utils/translate';

interface SupplierDisplayProps {
    name: string
}

const SupplierDisplay = ({name}: SupplierDisplayProps) => {
    const translate = useFormatMessage();
    return <span><span className="_bold">{translate({id: 'b.supplier'})}:</span> {name || '-'}</span>;
}

export default SupplierDisplay;
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {branch} from 'shared/models/branch.model';
import { advertisingAreaUIModel } from 'shared/models/advertisingArea.model';
import {useFormatMessage} from 'utils/translate';
import PaperX from 'components/PaperX/PaperX';
import AgTable, {defaultColCfgWithSearch, localStorageColumnsKeys} from 'components/AgTable/AgTable';

interface advertisingAreaSummaryProps {
    branchesForArea: branch[],
    selectedArea: advertisingAreaUIModel
}

const AdvertisingAreaSummary = (props: advertisingAreaSummaryProps) => {
    const translate = useFormatMessage();
    const {branchesForArea, selectedArea} = props;
    const getHeaderLabel = (): React.ReactNode => <span>{translate({id: 'branchesOf'})}:&nbsp; {selectedArea ? selectedArea.name : '-'}</span>;

    const commonColDefs: ColDef = {
        ...defaultColCfgWithSearch,
        cellClass: (params) => params.data.id === selectedArea?.representativeBranchId ? '_highlightedTableRow': null
    };

    const branchesColumnDefs: ColDef[] = [
        { field: 'postCode', headerName: translate({ id: 'b.postCode' }), maxWidth: 100},
        { field: 'city', headerName: translate({ id: 'b.city' }), flex: 1},
        { field: 'street', headerName: translate({ id: 'b.street' }), flex: 1},
        { field: 'houseNumber', headerName: translate({ id: 'b.houseNumber' })},
        { field: 'regionName', headerName: translate({ id: 'b.region'}), flex: 1},
        { field: 'nielsenRegionName', headerName: translate({ id: 'b.nielsenRegion'}), flex: 1}
    ];

    return (
        <PaperX  className="_fullWidth _fullHeight">
        <div className="_directionCol">
            <div className="_header">{getHeaderLabel()}</div>
            <AgTable
                rowData={branchesForArea}
                defaultColDef={commonColDefs}
                columnDefs={branchesColumnDefs}
                localStorageColumnsKey={localStorageColumnsKeys.advertisingAreaSummary}
            />
        </div>
    </PaperX>
    )
}

export default AdvertisingAreaSummary;
import {useState, FocusEvent} from 'react';
import {InputAdornment, TextField} from '@mui/material';
import {reasons} from 'shared/models/inputValidation';
import {useFormatMessage} from 'utils/translate';

type numberInputRanges = {
    from?: number,
    to?: number
}

export type validationResult = {
    value: number,
    error: boolean,
    reason: reasons
}

export enum valueTypes {
    'int' = 'int',
    'float' = 'float'
}

export enum numberReasons {
    'requiredIsEmpty' = 'v.requiredIsEmpty',
    'notANumber' = 'v.notANumber',
    'outOfRange' = 'v.outOfRange'
}

interface numberInputProps {
    autoFocus?: any,
    endAdornment?: any,
    label: string,
    onChange: (propIdentifier: string, value: number, error: boolean) => void,
    propIdentifier: string,
    range?: numberInputRanges,
    required?: boolean,
    value: number,
    valueType: valueTypes
}

export const helperText = (reason: reasons, translate: Function): string => reason ? translate({id: reason}) : null;

const NumberInput = (props:numberInputProps) => {
    const {onChange, endAdornment, label, value, propIdentifier, valueType, autoFocus, required, range} = props;
    const translate = useFormatMessage();
    const [hasError, setHasError] = useState<boolean>(false);
    const [reason, setReason] = useState<reasons>(null);

    const validate = (value: string): validationResult => {
        const result: validationResult = {
            value: null,
            error: false,
            reason: null
        };

        if (value === '') {
            if (required) {
                result.error = true;
                result.reason = numberReasons.requiredIsEmpty;
            } else {
                result.error = false;
            }
            result.value = null;
            return result;
        }

        const parsedValue: number = valueType === valueTypes.int ? parseInt(value) : parseFloat(value);

        if (isNaN(parsedValue)) {
            result.error = true;
            result.reason = numberReasons.notANumber;
            result.value = null;
            return result;
        }
        if (range) {
            if (parsedValue < range.from || parsedValue > range.to) {
                result.error = true;
                result.reason = numberReasons.outOfRange;
                result.value = parsedValue;
                return result;
            }
        }

        result.error = false;
        result.value = parsedValue;
        return result;
    }

    const handleChange = (inputValue: string) => {
        const {error, reason, value} = validate(inputValue);
        setHasError(error);
        setReason(reason);
        onChange(propIdentifier, value, error);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (isNaN(e.target.valueAsNumber)) { // clear input if entry is not finished, ex 1. (missing decimal part)
            e.target.value='';
        }
    }

    return <div className="numInput">
        <TextField
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            value={value === null ? '' : value}
            onKeyDown={(e) => {if (e.code === "KeyE") e.preventDefault()}}
            onChange={(e) => handleChange(e.target.value)}
            label={translate({id: label})}
            onBlur={handleBlur}
            autoFocus={autoFocus}
            error={hasError}
            InputProps={{
                endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>
            }}
            type="number"
            required={required}
            helperText={helperText(reason, translate)}
        />
    </div>;
}

export default NumberInput;
import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import {leafletRepresentativeBranch} from 'shared/models/leaflet.model';
import { useFormatMessage } from 'utils/translate';
import { AttributeError, AttributeLoading } from 'components/Attribute/AttributeHelpers';
import { useGetRepresentativeBranchList } from './LeafletRepresentativeBranchSelectorAPI';

interface leafletRepresentativeBranchSelectorProps {
  bannerId: string;
  onSelect?: (representativeBranch: repBranch) => void;
  data: repBranch;
}

export type repBranch = {
  id: string,
  repBranch: string
}

export const LeafletRepresentativeBranchSelector = (props: leafletRepresentativeBranchSelectorProps) => {
  const { bannerId, onSelect, data } = props;
  const translate = useFormatMessage();

  const [selectedRepresentativeBranch, setSelectedRepresentativeBranch] = useState<repBranch>(null);
  const [representativeBranchList, setRepresentativeBranchList] = useState<leafletRepresentativeBranch[]>([]);
  const representativeBranchAPI = useGetRepresentativeBranchList(bannerId);

  const handleAutocompleteChange = (value: repBranch | string) => {
    if (!bannerId) setSelectedRepresentativeBranch(null);
    if (typeof value !== 'string') {
      setSelectedRepresentativeBranch(value);
      onSelect(value ? value : null);
    }
  };

  useEffect(() => {
    if (representativeBranchAPI.data) {
      setRepresentativeBranchList(representativeBranchAPI.data);
    }
  }, [representativeBranchAPI]);

  useEffect(() => {
    setSelectedRepresentativeBranch(null);
    setRepresentativeBranchList([]);
  }, [bannerId]);

  if (representativeBranchAPI.isError) return <AttributeError />;
  if (representativeBranchAPI.isLoading) return <AttributeLoading />;

  return (
      <div>
        <Autocomplete
            disabled={!bannerId || representativeBranchAPI.isLoading}
            value={selectedRepresentativeBranch || data}
            options={representativeBranchList}
            getOptionLabel={(option:leafletRepresentativeBranch) => option.repBranch}
            onChange={(e, value) => handleAutocompleteChange(value)}
            renderInput={(params) => <TextField {...params} label={translate({ id: 'b.representativeBranch' })} />}
        />
      </div>
  );
};

export default LeafletRepresentativeBranchSelector;
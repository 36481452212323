import './Displays.scss';
import './PriceDisplay.scss';

import {FormattedMessage} from 'react-intl/lib';
import {useFormatMessage} from 'utils/translate';

interface PriceDisplayProps {
    absoluteDiscount: number,
    currency: string,
    mpu: number,
    promotionalPrice: number,
    regularPrice: number,
    relativeDiscount: number,
}

const PriceDisplay = ({absoluteDiscount, currency, mpu, promotionalPrice, regularPrice, relativeDiscount}: PriceDisplayProps) => {
    const translate = useFormatMessage();
    const MPUChip = () => mpu ? <span className="displayChip priceChip"><FormattedMessage id="b.MPU"/>: {mpu}</span> : <span className="priceChip"> - </span>;
    return <div className="priceDisplayRoot">
        <span className="displayChip priceChip2" title={translate({id: 'b.promotionalPrice'})}>{promotionalPrice ? `${promotionalPrice} ${currency}` : '-'}</span>
        <span className="displayChip priceChip" title={translate({id: 'b.regularPrice'})}> {regularPrice ? `${regularPrice} ${currency}` : '-'}</span>
        <span className="displayChip priceChip" title={translate({id: 'b.relativeDiscount'})}>{relativeDiscount ? `${relativeDiscount} %` : '-'}</span>
        <span className="displayChip priceChip" title={translate({id: 'b.absoluteDiscount'})}> {absoluteDiscount ? `${absoluteDiscount}` : '-'}</span>
        {MPUChip()}
    </div>;
}

export default PriceDisplay;
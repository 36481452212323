import {useState} from 'react';
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {DeleteOutline} from '@mui/icons-material';
import {validity} from 'shared/models/validity.model';
import FrameValidityDialog from 'modules/Advertisement/FrameValidity/FrameValidityDialog';
import FrameValidity from 'modules/Advertisement/FrameValidity/FrameValidity';

interface EditFrameValidityProps {
    disableDelete: boolean,
    disableEdit?: boolean,
    frameValidity: validity,
    onDelete: () => void,
    onSave: (frameValidity: validity) => void,
}

const EditFrameValidity = (props: EditFrameValidityProps) => {
    const {disableDelete, disableEdit, frameValidity, onDelete, onSave} = props;
    const [open, setOpen] = useState<boolean>(false);

    const handleValiditySave = (frameValidity: validity) => {
        onSave(frameValidity);
        setOpen(false);
    }

    return (
        <>
            <div className="_directionRow frameValidity">
                <FrameValidity frameValidity={frameValidity}/>
                <IconButton className="btn" 
                            onClick={() => setOpen(true)}
                            disabled={disableEdit}
                >
                    <EditIcon />
                </IconButton>
                <IconButton className="btn"
                            onClick={onDelete}
                            disabled={disableDelete}
                >
                    <DeleteOutline />
                </IconButton>
            </div>
            <FrameValidityDialog open={open}
                                 onClose={() => setOpen(false)}
                                 onSave={(frameValidity) => handleValiditySave(frameValidity)}
                                 validity={frameValidity}
            />
        </>
    );
};

export default EditFrameValidity;
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import ButtonClose from 'components/Buttons/ButtonClose';
import MyAdvertisements from './MyAdvertisements';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';

interface MyAdvertisementsDialogProps {
    onClose: () => void,
    open: boolean
}

const MyAdvertisementsDialog = (props: MyAdvertisementsDialogProps) => {
    const {onClose, open} = props;
    return (
        <Dialog className="_dialog-likeView" open={open} fullScreen>
            <DialogContent className="_directionCol">
                <MyAdvertisements/>
            </DialogContent>
            <DialogActions>
                <DialogAuxiliaryActions>
                    <NavigationMenuButton/>
                </DialogAuxiliaryActions>
                <ButtonClose onClick={onClose}/>
            </DialogActions>
        </Dialog>
    );
};

export default MyAdvertisementsDialog;
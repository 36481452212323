import './GoFullScreen.scss';
import {OpenInNew} from '@mui/icons-material';
import {IconButton} from '@mui/material';

const GoFullScreen = ({path}: {path: string}) => {
    const openFullScreen = () => window.open(path);

    return (
        <IconButton color="primary" className="openInNew" onClick={openFullScreen} tabIndex={-1}>
            <OpenInNew/>
        </IconButton>
    );
};

export default GoFullScreen;
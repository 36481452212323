import './MediaDescriptionSummaryDrawer.scss';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {Button, Drawer} from '@mui/material';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import {buildMediaPromotionRow} from 'components/Promotion/Promotion';
import {useSlimAttributeQuery} from 'components/Attribute/attributeAPI';
import {IRootState} from 'shared/reducers';
import {handyAttributes } from 'shared/handyAttributes';
import {promotionUI} from 'shared/models/promotion.model';
import {radioSpotHeaderData} from 'shared/models/media.model';
import AdvertisementsMediaTable from 'modules/Advertisement/AdvertisementsOverview/AdvertisementsMediaTable';

interface mediaDescriptionSummaryDrawerProps {
    canSave: boolean,
    handleSubmit: () => void,
    headerData: radioSpotHeaderData,
    onClose: () => void,
    onRowClick: (id: string) => void,
    open: boolean,
    promotions: promotionUI[]
}

const MediaDescriptionSummaryDrawer = (props: mediaDescriptionSummaryDrawerProps) => {
    const {open, canSave, onClose, onRowClick, handleSubmit, headerData, promotions} = props;

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [themeSeasonDerivedFromLeaflet, setThemeSeasonDerivedFromLeaflet] = useState<string[]>([]);
    const [themedWeeksDerivedFromLeaflet, setThemedWeeksDerivedFromLeaflet] = useState<string[]>([]);
    const themeSeasonDictionary = useSlimAttributeQuery(handyAttributes.themeSeason, selectedLanguage, true);
    const themedWeeksDictionary = useSlimAttributeQuery(handyAttributes.countryThemedWeeks, selectedLanguage, true);

    useEffect(() => {
        if (themeSeasonDictionary.data?.options) {
            setThemeSeasonDerivedFromLeaflet(
                themeSeasonDictionary.data.options
                    .filter((item) => headerData.themeSeasons.includes(item.id))
                    .map((item) => item.name + '*')
            );
        }
        if (themedWeeksDictionary.data?.options) {
            setThemedWeeksDerivedFromLeaflet(
                themedWeeksDictionary.data.options
                    .filter((item) => headerData.countryThemedWeeks.includes(item.id))
                    .map((item) => item.name + '*')
            );
        }
    }, [headerData, themeSeasonDictionary.data, themedWeeksDictionary.data]);

    return (
        <Drawer className="mediaDescriptionSummaryDrawerRoot"
            anchor="right"
            hideBackdrop
            open={open}
            onClose={onClose}
        >
            <div className="viewContainer _directionRow">
                <PaperX className="_fullHeight _fullWidth _scrollY">
                    <AdvertisementsMediaTable
                        rowData={promotions.map((item) => buildMediaPromotionRow(item, themeSeasonDerivedFromLeaflet, themedWeeksDerivedFromLeaflet))}
                        onRowClick={onRowClick}
                    />
                </PaperX>
            </div>
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        <Button onClick={handleSubmit} variant={"contained"} disabled={!canSave}>
                            <FormattedMessage id="a.submit"/>
                        </Button>
                        <ButtonClose onClick={onClose} />
                    </>
                }
            />
        </Drawer>
    );
};

export default MediaDescriptionSummaryDrawer;
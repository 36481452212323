import {brickUIModel, categorySelectorOption} from 'shared/models/category.model';
import {taxItem, taxItemWithCountry} from 'shared/models/taxes.model';
import {useFormatMessage} from 'utils/translate';
import {PaperX} from 'components/PaperX';
import ActiveCheckbox from 'components/ActiveCheckbox';
import AgTable from 'components/AgTable/AgTable';
import {CategorySearch} from 'modules/MasterData/ProductCategories';
import {dictElemColumnDefs} from 'modules/Dictionaries/Literals/Literal';
import CategoryTaxes from 'modules/MasterData/Category/CategoryTaxes/CategoryTaxes';

interface BrickDetailsProps {
    brick: brickUIModel,
    disableActiveCheckbox: boolean,
    onBrickChange: (brick: brickUIModel) => void,
    onCellEdit: (shouldLock: boolean) => void
}

const BrickDetails = (props: BrickDetailsProps) => {
    const {brick, disableActiveCheckbox, onBrickChange, onCellEdit} = props;
    const translate = useFormatMessage();

    const handleActiveChange = (key: string, value: boolean) => {
        const newBrick = {...brick, active: value, alternativeCategory: {id: '', name: '', type: brick.type}};
        onBrickChange(newBrick);
    };

    const handleSelectParentCategory = (newParent: categorySelectorOption) => {
        const newBrick = {...brick, parentCategory: newParent};
        onBrickChange(newBrick);
    };

    const handleSelectAlternativeCategory = (newAlt: categorySelectorOption) => {
        const newBrick = {...brick, alternativeCategory: newAlt};
        onBrickChange(newBrick);
    };

    const handleCellChange = (event) => {
        const newBrick = {...brick};
        newBrick.translations[event.rowIndex] = event.data;
        onBrickChange(newBrick);
    };

    const handleTaxChange = (newTax: taxItem, country: string) => {
        const newTaxWcountry: taxItemWithCountry = {...newTax, country};
        const newBrick = {...brick};
        let newTaxes = [...newBrick.taxes];
        const idx = newTaxes.findIndex((item) => item.country === country);
        if (!newTaxWcountry.id) {
            newTaxes = newTaxes.filter((item) => item.country !== country);
        }
        else if (idx > -1) {
            newTaxes[idx] = newTaxWcountry;
        } else {
            newTaxes.push(newTaxWcountry);
        }
        newBrick.taxes = newTaxes;
        onBrickChange(newBrick);
    };

    return (
        <>
            <div className="_halfWidth _directionCol">
                <PaperX>
                    <CategorySearch customLabel={`${translate({id: "category.parent"})} [${translate({id: 'b.categoryClass'})}]`}
                                                                    getOnlyIfActiveFlagEquals={true}
                                                                    onCategorySelect={(category) => handleSelectParentCategory(category)}
                                                                    value={brick.parentCategory}
                                                                    categoryType={brick.parentCategory.type} />
                    <ActiveCheckbox tabIndex={-1}
                                    value={brick.active}
                                    onChange={handleActiveChange}
                                    disabled={disableActiveCheckbox || !brick.active}/>
                    <CategorySearch customLabel={`${translate({id: "category.alternative"})} [${translate({id: 'b.categoryBrick'})}]`}
                                    getOnlyIfActiveFlagEquals={true}
                                    onCategorySelect={(category) => handleSelectAlternativeCategory(category)}
                                    value={brick.alternativeCategory}
                                    disabled={brick.active}
                                    categoryType={brick.type} />
                </PaperX>
                <PaperX className="_fullHeight _fullTable">
                    <CategoryTaxes onTaxChange={handleTaxChange} taxes={brick.taxes}/>
                </PaperX>
            </div>
            <div className="_halfWidth _directionCol">
                <PaperX className="weldBottom">
                    <div className="_header">{translate({id: 'category.bName'})}</div>
                </PaperX>
                <PaperX className="weldTop _fullWidth _fullHeight _fullTable">
                    <AgTable
                        rowData={brick.translations}
                        columnDefs={dictElemColumnDefs(translate)}
                        defaultColDef={{suppressMenu: true}}
                        onCellValueChanged={handleCellChange}
                        onCellEditingStarted={() => onCellEdit(true)}
                        onCellEditingStopped={() => onCellEdit(false)}
                        tooltipShowDelay={1000}
                    />
                </PaperX>
            </div>
        </>
    );
};

export default BrickDetails;
import {useState} from 'react';
import {IconButton} from '@mui/material';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FaiLogDiaLog from './FaiLogDiaLog';

const FaiLog = () => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    return (
        <>
            <IconButton onClick={() => setDialogOpen(true)}>
                <EventNoteIcon color='secondary' />
            </IconButton>
            {dialogOpen && <FaiLogDiaLog onClose={() => setDialogOpen(false)}/>}
        </>
    );
};

export default FaiLog;
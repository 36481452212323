import {Checkbox, FormControlLabel} from '@mui/material';
import {useFormatMessage} from 'utils/translate';

type activeCheckboxProps = {
    value: boolean,
    onChange: (key: string, value: boolean) => void,
    tabIndex?: number,
    disabled?: boolean
}

const ActiveCheckbox = (props:activeCheckboxProps) => {
    const translate = useFormatMessage();
    const {tabIndex, value, onChange, disabled} = props;
    return (
        <FormControlLabel
            control={<Checkbox tabIndex={tabIndex || null} checked={value} color="primary" onChange={(e) => onChange('active', e.target.checked)} disabled={disabled}/>}
            label={translate({id: 'a.active'})}/>
    );
};

export default ActiveCheckbox;
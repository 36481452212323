import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storeValue, /*localStorageUpdated*/ } from 'shared/reducers/storagePile';

const StoragePile = () => {
  const dispatch = useDispatch();

  const onLocalStorageUpdated = useCallback(() => {
    Object.keys(localStorage).forEach(function(key){
      dispatch(storeValue(key, localStorage.getItem(key)));
    });
    // @TODO or can just drop whole locale storage and let reducer update single fields if needed
    // dispatch(localStorageUpdated(localStorage);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('storage', onLocalStorageUpdated);
  }, [onLocalStorageUpdated]);

  return null;
};

export default StoragePile;
/* eslint-disable react-hooks/exhaustive-deps */
import './DescriptionInputX.scss';

import {useEffect, useState} from 'react';
import {IconButton, TextField} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {simpleDescriptionUi} from 'shared/models/description.model';
import {useFormatMessage} from 'utils/translate';
import LanguageSelector from 'components/Selectors/LanguageSelector/LanguageSelector';

const descriptionLangList: string[] = ['bg', 'cs', 'de', 'de_AT', 'de_CH', 'en', 'fr', 'hr', 'it', 'hu', 'nl', 'pl', 'ro', 'sk', 'sl'];

interface descriptionInputXProps {
    error: boolean,
    index: number
    onChange: (index: number, value: simpleDescriptionUi) => void,
    productDescription: simpleDescriptionUi,
}

const DescriptionInputX = (props:descriptionInputXProps) => {
    const translate = useFormatMessage();
    const [simpleDescription, setSimpleDescription] = useState<simpleDescriptionUi>({language: null, description: '', UIId: null})

    useEffect(() => {
        if (props.productDescription){
            setSimpleDescription(props.productDescription);
        }
    },[props.productDescription])

    const handleLanguageChange = (value:string) => {
        const updatedDescription = {...simpleDescription};
        updatedDescription.language = value;
        setSimpleDescription(updatedDescription);
        props.onChange(props.index, updatedDescription);
    };

    const handleClearDescription = () => {
        const updatedDescription = {...simpleDescription};
        updatedDescription.description = '';
        setSimpleDescription(updatedDescription);
        props.onChange(props.index, updatedDescription);
    };

    const handleDescriptionChange = (value:string) => {
        const updatedDescription = {...simpleDescription};
        updatedDescription.description = value;
        setSimpleDescription(updatedDescription);
        props.onChange(props.index, updatedDescription);
    };

    return (
        <div className="_formRow descriptionInputRoot">
            <div className="languageSelector">
                <LanguageSelector language={simpleDescription.language} onLanguageChange={handleLanguageChange} languageSet={descriptionLangList} label={'dictionaryElement.lang'} error={props.error}/>
            </div>
            <div className="description">
                <TextField
                    fullWidth
                    label={translate({id: 'b.descr'})}
                    value={simpleDescription.description}
                    onChange={(e) => handleDescriptionChange(e.target.value)}
                />
            </div>
            <div className="clearBtn">
                <IconButton onClick={handleClearDescription} tabIndex={-1}>
                    <ClearIcon/>
                </IconButton>
            </div>
        </div>
    )
}

export default DescriptionInputX;
/* eslint-disable react-hooks/exhaustive-deps */
import './ReportedAdvertisementsBrowser.scss';

import {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_REMARKED_FRAMES_FOR_LEAFLET} from 'config/api/constants';
import {paths} from 'paths';
import {framesPageNumberOffset, framesResponseModel} from 'shared/models/frame.model';
import {useFormatMessage} from 'utils/translate';
import {getId, getURLParam} from 'utils/routing';
import {responseValidation} from 'utils/responseValidation';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {LeafletBrowser} from 'components/gfx';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {
    useGetLeafletDetails,
} from 'modules/Quality/ReportedFrames/ReportedFramesBrowser/ReportedFramesBrowserAPI';
import {reportIssuesType} from 'modules/Advertisement/FrameDescription/FrameDescriptionReportDialog';
import ReportedAdvertisementsSummary from './ReportedAdvertisementsSummary';

const ReportedAdvertisementsBrowser = ({history}) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const cancelToken = useRef(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [returnPath, setReturnPath] = useState<string>(paths.reportedIssues);
    const [id, setId] = useState<string>(null);
    const [selectedReportType, setSelectedReportType] = useState<reportIssuesType>(null);
    const [selectedPageNumber, setSelectedPageNumber] = useState<number>(0);
    const [data, setData] = useState<framesResponseModel>(null);

    const leafletAPI = useGetLeafletDetails(id);

    useEffect(() => {
        const leafletId: string = getId();
        const fromUrl: string = getURLParam('from');
        const reportType: string = getURLParam('reportType');

        if (leafletId) setId(leafletId);
        if (fromUrl) setReturnPath(fromUrl);
        if (reportType) setSelectedReportType(reportType as reportIssuesType);
    }, []);

    useEffect(() => id && fetchFramesForLeaflet(), [id, selectedReportType]);

    const fetchFramesForLeaflet = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);

        axios.get<framesResponseModel>(API_GET_REMARKED_FRAMES_FOR_LEAFLET(id), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setData(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch(e => {
                if(!e?.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    }

    const handleRowClick = (pageNumber: number) => {
        if (selectedPageNumber === pageNumber - 1) {
            // solving scenario: usr click row, then change page manually, then  click same row (same page number) again
            // - as there will be no change in selectedPageNumber leaflet page won't change,
            setSelectedPageNumber(0);
        }
        setSelectedPageNumber(pageNumber - 1);
    }

    return (
        <div className="viewRoot reportedAdvertisementsBrowserRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading || leafletAPI.isFetching}/>
                <div className="viewContainer _directionRow">
                    <PaperX className="_fullWidth leafletContainer">
                        {data && <LeafletBrowser leaflet={leafletAPI.data}
                                                 frames={framesPageNumberOffset(data)}
                                                 externalPageNumber={selectedPageNumber}
                                                 showFileName
                                                 hideReportPage
                        />}
                    </PaperX>
                    <div className="_directionCol">
                        {data && <ReportedAdvertisementsSummary frames={data.filter((frame) => frame.remarked === true)}
                                                                leafletId={id}
                                                                onRowClick={handleRowClick}
                        />}
                    </div>

                </div>
            </div>
            <Footer
                actionsRight={
                    <ButtonClose onClick={() => history.push(returnPath)} />
                }
            />
        </div>
    );
};

export default ReportedAdvertisementsBrowser;
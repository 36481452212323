import axios from 'axios';
import {API_GET_TAXES_DICTIONARY, API_STATUSES} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {dictTaxes} from 'shared/models/dict.model';
import {taxesPerCountry} from 'shared/models/taxes.model';

enum ACTION_TYPES {
    START_FETCHING_DICT_TAXES = 'START_FETCHING_DICT_TAXES',
    ON_SUCCESS_DICT_TAXES = 'ON_SUCCESS_DICT_TAXES',
    ON_ERROR_DICT_TAXES = 'ON_ERROR_DICT_TAXES'
}

export function fetchDictTaxes () {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_DICT_TAXES
        });
        axios.get<taxesPerCountry[]>(API_GET_TAXES_DICTIONARY).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_DICT_TAXES,
                    payload: {data: resp.data}
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_DICT_TAXES});
            }
        }).catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_DICT_TAXES})
        })
    }
}

const initialState: dictTaxes = {
    status: API_STATUSES.NEW,
    data: []
};

export type DictTaxesState = Readonly<typeof initialState>;

const dictTaxesReducer = (state: DictTaxesState = initialState, action): DictTaxesState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_DICT_TAXES:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_DICT_TAXES:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_DICT_TAXES:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default dictTaxesReducer;
import {TextField} from '@mui/material';
import {freetextAttributeDataModel, freetextAttributeModel} from 'shared/models/attributeComponent.model';
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

type attributeFreetextProps = {
    data: freetextAttributeDataModel,
    model: freetextAttributeModel,
    onChange: Function,
    required?: boolean,
    disabled?: boolean,
    onClear: Function

}

const AttributeFreetext = (props: attributeFreetextProps) => {
    const {model, data, onChange, required, disabled, onClear} = props;

    const handleChange = (value) => onChange(model.id, {text: value});

    const handleClear = () => onClear(model.id);

    return (
        <div className="attributeRoot">
            <TextField
                required={required}
                fullWidth
                label={model.name}
                value={data ? data.text : ''}
                onChange={(e) => handleChange(e.target.value)}
                disabled={disabled}
            />
            <div className="clearInputButtonContainer">
                <IconButton onClick={handleClear} tabIndex={-1}>
                    <ClearIcon/>
                </IconButton>
            </div>
        </div>
    );
};
export default AttributeFreetext;
/* eslint-disable react-hooks/exhaustive-deps */
// @TODO unable to type & test nielsen region selector until it is available in the app
import {useEffect, useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {useNielsenRegionQuery} from "./NielsonRegionAPI";
import {AttributeError, AttributeLoading} from "../../Attribute/AttributeHelpers";
import {useFormatMessage} from 'utils/translate';
import {states} from 'shared/models/states.model';

interface NielsenRegionSelectorProps {
    country: string;
    nielsenRegionId?: string;
    onNielsenChange?: (nielsenRegionId: string) => void;
    disabled?: boolean
}

export const NielsenRegionSelector = (props: NielsenRegionSelectorProps) => {
    const {nielsenRegionId, onNielsenChange, disabled, country} = props;
    const nielsenRegionQuery = useNielsenRegionQuery();
    const [componentState, setComponentState] = useState<states>(states.loading);
    const [nielsenRegionsData, setNielsenRegionsData] = useState([]);

    const translate = useFormatMessage();

    useEffect(() => {
        if (nielsenRegionQuery.isLoading) {
            setComponentState(states.loading);
        } else if (nielsenRegionQuery.isError) {
            setComponentState(states.error);
        } else if (nielsenRegionQuery.isSuccess) {
            setComponentState(states.ready);
            setNielsenRegionsData(nielsenRegionQuery.data);
        }
    }, [nielsenRegionQuery]);

    const nielsenRegionsByCountry = nielsenRegionsData.filter(nielsenRegion => nielsenRegion.countryId === country);

    const renderByState = (state) => {
        switch (state) {
            case states.loading:
                return <AttributeLoading/>;
            case states.error:
                return <AttributeError errorLabel="industrySelector.err"/>
            case states.ready:
                return <FormControl fullWidth>
                    <InputLabel>{translate({id: "b.nielsenRegion"})}</InputLabel>
                    <Select color="primary"
                            value={nielsenRegionId}
                            disabled={disabled}
                            onChange={(e) => onNielsenChange(e.target.value)}
                            label={translate({id: "b.nielsenRegion"})}
                    >
                        {nielsenRegionsByCountry.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                    </Select>
                </FormControl>
            default:
                return <AttributeError errorLabel="industrySelector.err"/>
        }
    };

    return renderByState(componentState);
}

export default NielsenRegionSelector;
import {radioSpotActions} from 'modules/Medium/RadioSpot/RadioSpotOverview/RadioSpotOverview';
import {promotionSave} from 'shared/models/promotion.model';
import {apiGetPromotionsForFrameResponsePromotionType} from 'shared/models/frame.model';

export enum radioSpotStatus {
    REGISTERED = 'REGISTERED', // verification needed
    PENDING = 'PENDING', // waiting for AI proposals
    VERIFIED = 'VERIFIED', // after verification or no need it - ready to describe
    DONE = 'DONE' // finished, ready
}

export enum promotionMediumType {
    PROMOTION = 'PROMOTION',
    PRESS_ADVERTISEMENT = 'PRESS_ADVERTISEMENT',
    RADIO_SPOT = 'RADIO_SPOT',
    TV_SPOT = 'TV_SPOT'
}

export type mediumPromotionsPayload = {
    mediumId: string,
    promotionMediumType: promotionMediumType,
    promotions: promotionSave[]
}

export type radioSpotHeaderData = {
    bannerId: string,
    bannerName: string,
    countryThemedWeeks: string[],
    language: string,
    themeSeasons: string[],
    transcription: string,
    validFrom: string,
    validTo: string
}

export type radioSpotResponseModel = {
    country: string,
    headerData: radioSpotHeaderData,
    radioSpotId: string,
    status: radioSpotStatus,
    transcriptProposals: string[],
    metaData: {
        sourceFile: string
        storageFileName: string
    }
}

export type radioSpotPutPostPayloadModel = {
    country: string,
    headerData: {
        bannerId: string,
        countryThemedWeeks: string[],
        language: string,
        themeSeasons: string[],
        transcription: string,
        validFrom: string,
        validTo: string
    }
}

export type radioSpotOverviewRow = {
    availableActions: radioSpotActions[],
    banner: string,
    createdAt: string,
    createdBy: string,
    id: string,
    language: string,
    modifiedBy: string,
    quickAction: radioSpotActions,
    status: string,
    validFrom: string,
    validTo: string
}

export type spotFilters = {
    bannerId?: string,
    countryId?: string,
    language?: string,
    status?: radioSpotStatus[],
    validFrom?: string,
    validTo?: string,
}

export type mediaSummary = {
    banner: string,
    language: string,
    validFrom: string,
    validTo: string
}

export const radioSpotTemplate: radioSpotResponseModel = {
    country: '',
    headerData: {
        bannerId: '',
        bannerName: '',
        countryThemedWeeks: [],
        language: '',
        themeSeasons: [],
        transcription: '',
        validFrom: '',
        validTo: ''
    },
    radioSpotId: '',
    status: null,
    transcriptProposals: [],
    metaData: {
        sourceFile: '',
        storageFileName: ''
    }
};

export type promotionsForSpotResponseModel = {
    mediumId: string,
    promotions: apiGetPromotionsForFrameResponsePromotionType[],
    validFrom: string,
    validTo: string
};
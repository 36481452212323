/* eslint-disable react-hooks/exhaustive-deps */
import 'modules/MasterData/BPCO/BPCODescriptions/BPCODescriptionView.scss'

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import axios, {AxiosError, CancelTokenSource} from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import {Button, Dialog, DialogActions, DialogContent, InputAdornment, TextField} from '@mui/material';
import {ButtonClose} from 'components/Buttons';
import {API_BPCO_DESCRIPTIONS, API_GET_BPCO_DESCRIPTIONS_LIST, API_STATUSES} from 'config/api/constants';
import {
    BPCODescriptionPayload,
    BPCODescriptionResponseModel
} from 'shared/models/BPCO.model';
import {IRootState} from 'shared/reducers';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import useDebounce from 'utils/debounce';
import {responseValidation} from 'utils/responseValidation';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import BPCODescriptionsTable from './BPCODescriptionsTable';
import BPCODescription from './BPCODescription';

interface BPCODescriptionSearchDialogProps {
    marketId: string,
    open: boolean,
    onClose: () => void,
    onSelect: (description: BPCODescriptionResponseModel) => void
}

const BPCODescriptionSearchDialog = (props: BPCODescriptionSearchDialogProps) => {
    const {marketId, open, onClose, onSelect} = props;
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();
    const cancelToken = useRef<CancelTokenSource | null>(null);

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [queryStr, setQueryStr] = useState<string>('');
    const [dbncdQueryStr, setDbncdQueryStr] = useDebounce(queryStr, 500);
    const [BPCODescriptionData, setBPCODescriptionData] = useState<BPCODescriptionResponseModel[]>([]);

    const [showingSearchDescription, setShowingSearchDescription] = useState<boolean>(true);
    const [descriptionPayload, setDescriptionPayload] = useState<BPCODescriptionPayload>(null);

    const BPCODescriptionAPIPost = useApi('post', null, {errMsg: 'b.descriptionSaveErr', succMsg: 'b.descriptionSaveSucc', failureMsg: 'bpco.descriptionDuplicate'});

    const handleSearchInputChange = (value: string) => {
        setQueryStr(value);
        setDbncdQueryStr(value);
    }

    useEffect(() => {
        if (dbncdQueryStr.length > 2) {
            getData();
        }
    }, [dbncdQueryStr]);

    useEffect(() => {
        if (BPCODescriptionAPIPost.status === API_STATUSES.IDLE && BPCODescriptionAPIPost?.data?.brandProductCategoryObjectDescriptionId) {
            getCreatedDescription(BPCODescriptionAPIPost.data.brandProductCategoryObjectDescriptionId);
        }
    }, [BPCODescriptionAPIPost.status, BPCODescriptionAPIPost.data]);

    const getData = () => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current.cancel();
        setBPCODescriptionData([]);
        axios.get<BPCODescriptionResponseModel[]>(API_GET_BPCO_DESCRIPTIONS_LIST, {
            params: {
                description: queryStr,
                lang: userProfile.langData,
                marketId: marketId
            },
            cancelToken: cancelToken.current?.token
        })
            .then((response) => {
                if (responseValidation(response.data)) {
                    setBPCODescriptionData(response.data);
                }
            })
            .catch((e: AxiosError) => {
                if (axios.isAxiosError(e)) console.log(e)
            })
            .finally(() => setIsLoading(false));
    };

    const getCreatedDescription = (id: string) => {
        setIsLoading(true);
        axios.get<BPCODescriptionResponseModel>(`${API_BPCO_DESCRIPTIONS}/${id}`)
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    const createdBPCODescription: BPCODescriptionResponseModel = resp.data;
                    cleanup();
                    onSelect(createdBPCODescription);
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'bpco.errorFetchingCreated'})}`, {variant: 'info', persist: false});
                cleanup();
            })
            .finally(() => setIsLoading(false));
    };

    const cleanup = () => {
        setShowingSearchDescription(true);
        setDescriptionPayload(null);
    };

    const handleProductDescriptionChange = (description: BPCODescriptionPayload, descriptionValid: boolean) => {
        setDescriptionPayload(descriptionValid ? description : null);
    };

    const handleSaveClick = () => BPCODescriptionAPIPost.call(API_BPCO_DESCRIPTIONS, descriptionPayload);

    return (
        <Dialog className="_dialog-likeView" open={open} fullScreen>
            <LoadingOverlay show={isLoading || BPCODescriptionAPIPost.status === API_STATUSES.PENDING}/>
            {showingSearchDescription
                ?
                <DialogContent className="_directionCol">
                    <PaperX>
                        <div className="_formRowDouble noMargins">
                            <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                                       value={queryStr}
                                       label={translate({id: 'b.bpcoDescription'})}
                                       fullWidth
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <SearchIcon/>
                                               </InputAdornment>
                                           )
                                       }}/>
                        </div>
                    </PaperX>
                    <PaperX className="_fullHeight _fullTable _scrollY">
                        <BPCODescriptionsTable data={BPCODescriptionData}
                                               onRowClick={(description) => onSelect(description)}
                        />
                    </PaperX>
                </DialogContent>
                :
                <DialogContent className="BPCODescriptionRoot">
                    <BPCODescription onBPCODescriptionChange={handleProductDescriptionChange}/>
                </DialogContent>
            }
            <DialogActions>
                {showingSearchDescription
                    ?
                    <>
                        <Button color="primary" variant="contained"
                                onClick={() => setShowingSearchDescription(false)}>
                            <FormattedMessage id="a.new"/>
                        </Button>
                        <ButtonClose onClick={onClose}/>
                    </>
                    :
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!descriptionPayload}>{translate({id: 'a.save'})}</Button>
                        <ButtonClose onClick={() => setShowingSearchDescription(true)}/>
                    </>
                    }
            </DialogActions>
        </Dialog>
    );
};

export default BPCODescriptionSearchDialog;
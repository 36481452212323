/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {
    API_CATEGORY_DETAILS, API_UPDATE_BRICK,
    API_GET_CATEGORY_DETAILS, API_GET_TAXES_FOR_BRICK,
} from 'config/api/constants';
import {paths} from 'paths';
import {
    brickUIModel,
    categoryBEModel,
    categorySelectorOption,
    categoryType,
    categoryUIModel
} from 'shared/models/category.model';
import {getId, getURLParam} from 'utils/routing';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {IRootState} from 'shared/reducers';
import {taxItemWithCountry} from 'shared/models/taxes.model';
import {buildBrickPayload, buildBrickUITemplate, getParent4type, transform4UI} from 'modules/MasterData/Category/categoryIO';
import BrickDetails from 'modules/MasterData/Category/Brick/BrickDetails';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import {ConfirmDialog} from 'components/ConfirmDialog';

const BrickView = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [brick, setBrick] = useState<brickUIModel>(null);
    const [disableActiveCheckbox, setDisableActiveCheckbox] = useState<boolean>(false);
    const [lock4edit, setLock4edit] = useState<boolean>(false); // disable save bc table changes not confirmed
    const [isBrickValid, setIsBrickValid] = useState<boolean>(false);
    const [deactivated, setDeactivated] = useState<boolean>(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    useEffect(() => {
        setIsBrickValid(validateBrick());
    }, [brick]);

    const notifyAndReturn = () => {
        enqueueSnackbar(`${translate({id: 'category.err'})}`, {variant: 'error', persist: false});
        history.push(`${paths.categoriesHierarchy}`);
    };

    const getBrickAndItsTaxes = (brickId: string) => {
        Promise.all([
            axios.get<categoryBEModel>(API_GET_CATEGORY_DETAILS(brickId), {params: {lang: selectedLanguage}}), // the brick
            axios.get<taxItemWithCountry[]>(API_GET_TAXES_FOR_BRICK(brickId)) // its taxes
        ])
            .then((resp) => {
                setIsLoading(false);// cant do in finally only bc scope is lost by redirect
                if (responseValidation(resp[0].data) && responseValidation(resp[1].data)) {
                    const transformedBrick: categoryUIModel = transform4UI(resp[0].data);
                    setBrick({...transformedBrick, taxes: resp[1].data})
                } else {
                    notifyAndReturn();
                }
            })
            .catch((e) => {
                console.log(e);
                notifyAndReturn();
            });
    };

    const getParentCategoryData = (brickId: string) => {
        setIsLoading(true);
            axios.get<categoryBEModel>(API_GET_CATEGORY_DETAILS(brickId), {params: {lang: selectedLanguage}})
            .then((resp) => {
                setIsLoading(false);
                if (responseValidation(resp.data)) {
                    const parentCategory: categorySelectorOption = {
                        id: resp.data.parentCategoryId,
                        name: resp.data.parentCategoryName,
                        type: getParent4type(categoryType.CLASS)
                    }
                    setBrick(buildBrickUITemplate(parentCategory));
                } else {
                    notifyAndReturn();
                }
            })
            .catch((e) => {
                console.log(e);
                notifyAndReturn();
            });
    };

    const updateBrick = () => {
        setIsLoading(true);
        axios.put(API_UPDATE_BRICK(brick.id), buildBrickPayload(brick))
            .then(() => {
                enqueueSnackbar(`${translate({id: 'category.updateSucc'})}`, {variant: 'success', persist: false});
                history.push(paths.categoriesHierarchy);
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'category.updateErr'})}`, {variant: 'error', persist: false});
                setIsLoading(false); // cant do in finally only bc scope is lost by redirect
            });
    }

    const createBrick = () => {
        setIsLoading(true);
        axios.post(API_CATEGORY_DETAILS, buildBrickPayload(brick))
            .then(() => {
                enqueueSnackbar(`${translate({id: 'category.createSucc'})}`, {variant: 'success', persist: false});
                history.push(paths.categoriesHierarchy);
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'category.createErr'})}`, {variant: 'error', persist: false});
                setIsLoading(false); // cant do in finally only bc scope is lost by redirect
            });
    };

    useEffect(() => {
        const id = getId();
        if (id) { // edit mode
            getBrickAndItsTaxes(id);
        } else { // create mode
            setDisableActiveCheckbox(true);
            const parentForId = getURLParam('getParentForId');
            if (parentForId) { // need to find parent category
                getParentCategoryData(parentForId);
            } else { // user define parent category
                setBrick(buildBrickUITemplate());
            }
        }
    }, []);

    const validateBrick = (): boolean => {
        if (!brick) {
            return false;
        }
        if (!brick?.parentCategory?.id) {
            return false;
        }
        const enTrans = brick.translations.find((item) => item.lang === "en");
        if (!enTrans?.trans) {
            return false;
        }
        if (!brick.active && !brick.alternativeCategory.id) {
            return false;
        }
        return true;
    };

    const handleBrickChange = (newBrick: brickUIModel) => {
        if (brick.active === true && newBrick.active === false) { // brick has been deactivated, now know to display popup before saving
            setDeactivated(true);
        }
        setBrick(newBrick);
    };

    const handleSaveClick = () => {
        if (brick?.id) {
            if (deactivated) {
                setConfirmationDialogOpen(true);
            } else {
                updateBrick();
            }
        } else {
            createBrick();
        }
    };

    const handleSaveConfirm = () => {
        setConfirmationDialogOpen(false);
        updateBrick();
    };

    return (
        <div className="viewRoot categoryDetailsRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading} />
                <div className="viewContainer _directionRow">
                    {brick !== null && <BrickDetails brick={brick}
                                                     disableActiveCheckbox={disableActiveCheckbox}
                                                     onBrickChange={(newBrick) => handleBrickChange(newBrick)}
                                                     onCellEdit={(value) => setLock4edit(value)}
                    />}
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={lock4edit || !isBrickValid}>
                            <FormattedMessage id="a.save"/>
                        </Button>
                        <Link to={paths.categoriesHierarchy}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
            <ConfirmDialog open={confirmationDialogOpen}
                           onConfirm={handleSaveConfirm}
                           onCancel={() => setConfirmationDialogOpen(false)}
                           message={
                               <p>
                                   {`${translate({id: 'b.categoryBrick'})} ${translate({id: 'hierarchy.confirmation1'})}`}
                                   <br/>
                                   <br/>
                                   {`${translate({id: 'category.confirmation'})}`}
                               </p>
                           }
                           confirmLabelId="a.save"
                           cancelLabelId="a.cancel"/>
        </div>
    );
};

export default BrickView;
import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {paths} from 'paths';
import {
    advertisementsOverviewResponseModel,
    advertisementsOverviewRow,
    frameFilters
} from 'shared/models/advertisement.model';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {getId, getURLParam} from 'utils/routing';
import {API_ADVERTISEMENTS_OVERVIEW} from 'config/api/constants';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import AdvOverviewPromotionsTable from 'components/Tables/AdvOverviewPromotionsTable';
import AdvOverviewPromotionsFilters from './AdvOverviewPromotionsFilters';

export const flattenCharacteristics = (data: advertisementsOverviewResponseModel): advertisementsOverviewRow[] => data
    .map((row) => ({
            ...row,
            characteristics: row.characteristics.map((characteristic) => `${characteristic.name}: ${characteristic.value}`).join(', ')
        })
    );

const AdvOverviewPromotions = () => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [advertisements, setAdvertisements] = useState<advertisementsOverviewRow[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [hideTextSearch, setHideTextSearch] = useState<boolean>(false);
    const [returnPath, setReturnPath] = useState<string>(paths.home);

    const cancelToken = useRef(null);

    useEffect(() => {
        if (getId()) {
            setHideTextSearch(true);
        }
        const retPath: string = getURLParam('from');
        if (retPath) {
            setReturnPath(retPath);
        }
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const getData = (filters: frameFilters, id: string) => {
        setLoading(true);
        if (cancelToken.current) cancelToken.current();
        setAdvertisements([]);
        // replacing search string with objectId as per MPM-3116
        let finalFilters: frameFilters = {};
        if (id) {
            for (let key in filters) {
                if (key !== 'search') {
                    finalFilters[key] = filters[key];
                }
                finalFilters['objectId'] = id;
            }
        } else {
            finalFilters = filters;
        }
        axios.get<advertisementsOverviewResponseModel>(API_ADVERTISEMENTS_OVERVIEW, {
            params: finalFilters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setAdvertisements(flattenCharacteristics(response.data));
                } else {
                    enqueueSnackbar(`${translate({id: 'advertisementsOverview.fetchPromoErr'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e?.__CANCEL__) {
                    let errMsgId = 'advertisementsOverview.fetchPromoErr'
                    if (e.response.status === 422) {
                        errMsgId = 'a.2many';
                    }
                    enqueueSnackbar(`${translate({id: errMsgId})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setLoading(false));
    };

    const handleFiltersChange = (filters: frameFilters) => {
        if (!filters) return;

        const len = Object.keys(filters).length;
        const id = getId();
        if (len > 2 || (len > 1 && id)) {
            getData(filters, id);
        }
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={loading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <AdvOverviewPromotionsFilters onFilterChange={(filters) => handleFiltersChange(filters)}
                                                      hideTextSearch={hideTextSearch}
                        />
                    </PaperX>
                    <PaperX className="_fullHeight _fullTable">
                        <AdvOverviewPromotionsTable data={advertisements}/>
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <Link to={returnPath}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>
    );
};

export default AdvOverviewPromotions;
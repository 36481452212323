import './ProductBPCODisplay.scss';

import {FormattedMessage} from 'react-intl';
import {Divider} from '@mui/material';
import {handyAttributes} from 'shared/handyAttributes';
import {
    apiGetPromotionsForFrameResponsePromotionTypeBPCOType,
    apiGetPromotionsForFrameResponsePromotionTypeProductType
} from 'shared/models/frame.model';

interface ProductDetailsProps {
    product: apiGetPromotionsForFrameResponsePromotionTypeProductType
}

export const ProductDetails = ({product}: ProductDetailsProps) => {
    const {brand, category, characteristics, description, gtin} = product;
    return <ul className="productDisplayRoot">
        <li>
            <span className="_bold"><FormattedMessage id="b.brand"/>:&nbsp;</span>{brand}
        </li>
        <li>
            <span className="_bold"><FormattedMessage id="b.category"/>:&nbsp;</span>{category}
        </li>
        <li>
            <span className="_bold"><FormattedMessage id="b.descr"/>:&nbsp;</span>{description}
        </li>
        <li>
            <span className="_bold"><FormattedMessage id="b.gtin"/>:&nbsp;</span>{gtin}
        </li>
        <li><Divider/></li>
        <li className="characteristics">
            {characteristics
                .map((item) => <div key={item.id}><span className="_bold">{item.name}:&nbsp;</span>{item.value}</div>)
            }
        </li>
    </ul>;
};

interface BPCODetailsProps {
    bpco: apiGetPromotionsForFrameResponsePromotionTypeBPCOType
}

export const BPCODetails = ({bpco}: BPCODetailsProps) => {
    const {brand, characteristics, category, description} = bpco;

    return <ul className="productDisplayRoot">
        <li>
            <span className="_bold"><FormattedMessage id="b.brand"/>:&nbsp;</span>{brand}
        </li>
        <li>
            <span className="_bold"><FormattedMessage id="b.category"/>:&nbsp;</span>{category}
        </li>
        <li>
            <span className="_bold"><FormattedMessage id="b.descr"/>:&nbsp;</span>{description}
        </li>
        <li><Divider/></li>
        <li className="characteristics">
            {characteristics
                .filter((item) => item.id !== handyAttributes.description)
                .map((item) => <div key={item.id}><span className="_bold">{item.name}:&nbsp;</span>{item.value}</div>)
            }
        </li>
    </ul>;
};
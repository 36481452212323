import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import CountrySelector, {inputSize} from 'components/Selectors/CountrySelector/CountrySelector';

interface CountrySelectorConnectedProps {
    compact?: boolean,
    size?: inputSize
}

const CountrySelectorConnected = (props: CountrySelectorConnectedProps) => {
    const dispatch = useDispatch();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const handleCountryChange = (newCountry) => dispatch(setPreferredCountry(newCountry));
    return <CountrySelector onCountryChange={handleCountryChange}
                            size={props.size || inputSize.medium}
                            value={countryMarket.preferredCountry}
                            compact={props.compact || false}
    />;
}

export default CountrySelectorConnected;
/* eslint-disable react-hooks/exhaustive-deps */
import './ProductPreview.scss';
import {paths} from 'paths';
import {useHistory} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSnackbar} from 'notistack';
import {Button, Dialog, DialogActions, Divider, IconButton} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios, {AxiosError} from 'axios';
import {frame} from 'shared/models/frame.model';
import {API_IMAGE_FOR_PRODUCT, API_IMAGE_MANAGEMENT_FRAMES, API_LEAFLET_MANAGEMENT_FRAMES} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import {useFormatMessage} from 'utils/translate';

interface productPreviewProps {
    productId: string,
    isProductDetailsView?: boolean
}

const ProductPreview = ({productId, isProductDetailsView}: productPreviewProps) => {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [noImages, setNoImages] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [imageIds, setImageIds] = useState<string[]>([]);
    const [currentImages, setCurrentImages] = useState<string[]>([]);
    const [currentFrameIds, setCurentFrameIds] = useState<string[]>([]);
    const [currentFrameIndex, setCurrentFrameIndex] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [selectedImage, setSelectedImage] = useState<string>(null);
    const iterationNumber: number = 3;
    
    useEffect(() => {
        if (productId) fetchImageIds();
    }, [productId]);

    const fetchImageIds = () => {
        setIsLoading(true);
        axios.get<string[]>(API_IMAGE_FOR_PRODUCT(productId))
            .then((response) => {
                if (responseValidation(response.data)) {
                    if (response.data.length === 0) {
                        setNoImages(true);
                        setIsLoading(false);
                    } else {
                        setImageIds(response.data);
                        fetchImages(response.data.slice(0, iterationNumber));
                    }
                } else {
                    setIsError(true);
                    setIsLoading(false);
                }
            })
            .catch((error: AxiosError) => {
                setIsError(true);
                setIsLoading(false);
                console.error(`Error fetching image IDs: ${error}`);
            });
    }

    const fetchImages = (ids: string[]) => {
        setIsLoading(true);
        setCurentFrameIds(ids);
        const imageRequests = ids.map(id => 
            axios.get(`${API_IMAGE_MANAGEMENT_FRAMES}/${id}`, {responseType: 'blob'})
                .then(response => URL.createObjectURL(response.data))
        );
        Promise.all(imageRequests)
            .then(imageURLs => {
                setCurrentImages(imageURLs);
            })
            .catch((error: AxiosError) => {
                setIsError(true);
                console.error(`Error fetching images: ${error}`);
            })
            .finally(() => setIsLoading(false));
    };

    const handleNextImg = () => {
        const newIndex: number = currentIndex + iterationNumber;
        if (newIndex < imageIds.length) {
            fetchImages(imageIds.slice(newIndex, newIndex + iterationNumber));
            setCurrentIndex(newIndex);
        }
    };

    const handleImageClick = (src: string, i: number) => {
        setSelectedImage(src);
        setCurrentFrameIndex(i);
        setOpenDialog(true);
    };

    const handleGoToAdvertisement = () => {
        setIsDialogLoading(true);
        axios.get<frame>(`${API_LEAFLET_MANAGEMENT_FRAMES}/${currentFrameIds[currentFrameIndex]}?checkLock=false`)
            .then((response) => {
                if (responseValidation(response.data)) {
                    const {leafletId, frameId, pageNumber} = response.data;
                    if (leafletId && frameId && pageNumber) {
                        history.push(`${paths.advertisementBrowser}?id=${leafletId}&page=${pageNumber}&frameId=${frameId}&from=${window.location.pathname}`);
                    } else enqueueSnackbar(translate({id: 'frameDescription.err'}), {variant: 'error'});
                } else {
                    enqueueSnackbar(translate({id: 'frameDescription.err'}), {variant: 'error'});
                }
            })
            .catch((error: AxiosError) => {
                console.error(`Error: ${error}`);
                enqueueSnackbar(translate({id: 'frameDescription.err'}), {variant: 'error'});
            })
            .finally(() => setIsDialogLoading(false));
    }

    const handleRetryFetch = () => {
        setIsError(false);
        setNoImages(false);
        fetchImageIds();
        setCurrentIndex(0);
        setCurrentFrameIndex(0);
    };

    return (
        <div className="productPreviewRoot">
            <div className="imagesContainer">
                <LoadingOverlay show={isLoading}/>
                {noImages && <div className="infoLabel">{translate({id: 'product.noImgAvailable'})}</div>}
                {isError && <div className="infoLabel">{translate({id: 'product.errImg'})}</div>}
                {!noImages && !isError && currentImages.map((imgSrc, i) => (
                    <div key={i}>
                        <img src={imgSrc} onClick={() => handleImageClick(imgSrc, i)} alt=""/>
                    </div>
                ))}
            </div>
            <IconButton onClick={handleRetryFetch}>
                <RefreshIcon />
            </IconButton>
            <Button variant="contained" onClick={handleNextImg} disabled={isLoading || currentIndex+iterationNumber >= imageIds.length}>
                {translate({id: 'a.next'})}
            </Button>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth>
                <LoadingOverlay show={isDialogLoading}/>
                <img src={selectedImage} alt=""/>
                {!isProductDetailsView &&
                <>
                    <Divider/>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={handleGoToAdvertisement} disabled={!currentFrameIds[currentFrameIndex]}>
                            <FormattedMessage id="product.showAdvertisement"/>
                        </Button>
                        <ButtonClose onClick={() => setOpenDialog(false)}/>
                    </DialogActions>
                </>}
            </Dialog>
        </div>
    );
};

export default ProductPreview;

import {pageThemeSeason} from "shared/models/leaflet.model";
import PagesSeasonThemeSelector from "components/Selectors/PageSeasonThemeSelector/PagesSeasonThemeSelector";
import {multiselectAttributeDataModel} from "shared/models/attributeComponent.model";

type PageThemeSeasonProps = {
    pageThemeSeasons: pageThemeSeason[],
    numberOfPages: number,
    onThemeSeasonChange: (themeSeason:pageThemeSeason[]) => void,
}

const PageThemeSeasons = ({pageThemeSeasons, numberOfPages, onThemeSeasonChange}:PageThemeSeasonProps) => {
    const setPageThemeSeasonPage = (idx: number, page: string) => {
        const pgSeasonTheme: pageThemeSeason[] = pageThemeSeasons.map((item, index) => index === idx ? {...item, pageNumber: page, modified: true} : item);
        onThemeSeasonChange(pgSeasonTheme);
    };

    const setPageSeasonThemes = (idx: number, seasonThemes: multiselectAttributeDataModel) => {
        const pgSeasonTheme: pageThemeSeason[] = pageThemeSeasons.map((item, index) => index === idx ? {...item, themeSeasons: seasonThemes, modified: true} : item);
        onThemeSeasonChange(pgSeasonTheme);
    };

    const removePageSeasonTheme = (idx: number) => {
        const pgSeasonTheme: pageThemeSeason[] = [...pageThemeSeasons];
        pgSeasonTheme.splice(idx, 1);
        onThemeSeasonChange(pgSeasonTheme);
    };

    return (
        <>{pageThemeSeasons.map((item, idx) =>
            <div className="_formRowDouble" key={idx}>
                <div>
                    <PagesSeasonThemeSelector id={idx}
                                              pageNumber={item.pageNumber}
                                              numberOfPages={numberOfPages}
                                              themeSeasons={item.themeSeasons || null}
                                              onPageChange={(id, page) => setPageThemeSeasonPage(id, page)}
                                              onRemove={(id) => removePageSeasonTheme(id)}
                                              onSeasonThemeChange={(id, seasonThemes) => setPageSeasonThemes(id, seasonThemes)}
                    />
                </div>
            </div>)
        }</>
    );
}

export default PageThemeSeasons;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {API_STATUSES} from 'config/api/constants';
import {dictionary} from 'shared/models/dictionaries.model';
import {IRootState} from 'shared/reducers';
import {fetchDictDictionaries} from 'shared/reducers/dictDictionaries';

interface DictionaryNameProps {
    dictionaryId: string
}

const DictionaryName = ({dictionaryId}: DictionaryNameProps) => {
    const dispatch = useDispatch();
    const dictionariesStatus = useSelector((state: IRootState) => state.dictDictionaries.status);
    const dictionariesData = useSelector((state: IRootState) => state.dictDictionaries.data);

    useEffect(() => {
        if (dictionariesStatus === API_STATUSES.ERROR) {
            dispatch(fetchDictDictionaries());
        }
    }, []);


    useEffect(() => {
        if (dictionariesStatus === API_STATUSES.NEW) {
            dispatch(fetchDictDictionaries());
        }
    }, [dictionariesStatus]);

    // @TODO lang as property when we get multilang support
    const getLabel = (lang: string): string => {
        const dict: dictionary = dictionariesData.find((item) => item.id === dictionaryId);
        return dict ? dict.translations[lang] : '';
    };

    return (
      <span>{getLabel('en')}</span>
    );
};

export default DictionaryName;
import axios from 'axios';
import { API_STATUSES, API_KEY_HIERARCHY } from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {hierarchyReducerModel} from 'shared/models/hierarchy.model';

enum ACTION_TYPES {
    START_FETCHING_HIERARCHY = 'START_FETCHING_HIERARCHY',
    ON_SUCCESS_HIERARCHY = 'ON_SUCCESS_HIERARCHY',
    ON_ERROR_HIERARCHY = 'ON_ERROR_HIERARCHY'
}

export function fetchKeyHierarchy () {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_HIERARCHY
        });
        axios.get(API_KEY_HIERARCHY).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_HIERARCHY,
                    payload: {data: resp.data}
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_HIERARCHY});
            }
        })
        .catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_HIERARCHY})
        })
    }
}

const initialState: hierarchyReducerModel = {
    status: API_STATUSES.NEW,
    data: []
};

export type KeyHierarchyState = Readonly<typeof initialState>;

const keyHierarchyReducer = (state: KeyHierarchyState = initialState, action): KeyHierarchyState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_HIERARCHY:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_HIERARCHY:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_HIERARCHY:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default keyHierarchyReducer;
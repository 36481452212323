import de from './de';
import en from './en';
import pl from './pl';

const lang = {
    de: de,
    en: en,
    pl: pl
}

export default lang;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom'
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_LEAFLETS_WITH_REPORTED_FRAMES} from 'config/api/constants';
import {paths} from 'paths';
import {IRootState} from 'shared/reducers';
import {printMediaOverviewTableRow} from 'shared/models/leaflet.model';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {getLeafletsTableColumnDefs} from 'modules/Advertisement/AdvertisementsOverview/AdvertisementsOverview';

const ReportedFrames = () => {
    const history = useHistory();
    const translate = useFormatMessage();
    const cancelToken = useRef(null);
    const {enqueueSnackbar} = useSnackbar();

    const country = useSelector((state: IRootState) => state.userProfile.countryMarket.preferredCountry);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<printMediaOverviewTableRow[]>([]);

    const fetchReportedFrames = () => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);

        axios.get<printMediaOverviewTableRow[]>(API_GET_LEAFLETS_WITH_REPORTED_FRAMES(country), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setData(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch(e => {
                if(!e?.__CANCEL__) {
                    console.error(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => fetchReportedFrames(), [country]);

    const leafletsColumnDefs: ColDef[] = getLeafletsTableColumnDefs(translate);

    const goToLeaflet = (leafletId: string) => history.push(`${paths.reportedFramesBrowser}?id=${leafletId}`);

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <AgTable
                defaultColDef={{
                    suppressMenu: false,
                    resizable: true,
                    sortable: true
                }}
                rowData={data}
                columnDefs={leafletsColumnDefs}
                onRowClicked = {(rowData) => goToLeaflet(rowData.data.id)}
                localStorageColumnsKey={localStorageColumnsKeys.framesOverview}
            />
        </>
    );
};

export default ReportedFrames;
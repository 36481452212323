/* eslint-disable react-hooks/exhaustive-deps */
import 'modules/Advertisement/AdvertisementsOverviewPromotions/AdvOverviewPromotionsFilters.scss';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {myFrameFilters} from 'shared/models/advertisement.model';
import {bannersWithRetailerResponseModel, countryAndBanner} from 'shared/models/banner.model';
import {IRootState} from 'shared/reducers';
import {isBannerInCountry} from 'shared/reducers/bannersWithRetailer';
import {countryMarketItem} from 'shared/reducers/userProfile';
import useDebounce from 'utils/debounce';
import {useFormatMessage} from 'utils/translate';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {NumberInput} from 'components/Inputs';
import {valueTypes} from 'components/Inputs/NumberInput';
import CreationDateRangeInput, {timeRanges} from 'components/Inputs/CreationDateRangeInput';
import CountryBannerSelectorConnected from 'components/Selectors/CountryBannerSelector/CountryBannerSelectorConnected';

interface MyAdvertisementsFiltersProps {
    onFilterChange: (filters: myFrameFilters) => void
}

const sanitizeFrameFilters = (filters: myFrameFilters, bannersData: bannersWithRetailerResponseModel): myFrameFilters => {
    const saneFilters: myFrameFilters = {};
    if (filters.search && filters.search.length > 2) saneFilters.search = filters.search;
    if (filters.countryId) saneFilters.countryId = filters.countryId;
    if (filters.lang) saneFilters.lang = filters.lang;
    if (filters.bannerId && isBannerInCountry(filters.bannerId, filters.countryId, bannersData)) saneFilters.bannerId = filters.bannerId;
    if (filters.creationRange) saneFilters.creationRange = filters.creationRange;
    if (filters.createdFrom) saneFilters.createdFrom = filters.createdFrom;
    if (filters.createdTo) saneFilters.createdTo = filters.createdTo;
    if (filters.promotionalPrice) {
        saneFilters.promotionalPrice = filters.promotionalPrice;
    }
    return Object.keys(saneFilters).length === 0 ? null : saneFilters;
}

const storageKey = 'filters_myAdvertisementsFilters';

function clearBannerIdIfCountryHasChangedOutside (filters: myFrameFilters, countryMarket: countryMarketItem): myFrameFilters {
    if (filters) {
        if (filters.countryId !== countryMarket.preferredCountry) {
            return {...filters, bannerId: null};
        }
        return filters;
    }
    return null;
}

const buildFiltersTemplate= (country: string, lang: string): myFrameFilters => {
    return {
        bannerId: '',
        countryId: country,
        lang,
        createdFrom: '',
        createdTo: '',
        promotionalPrice: null,
        creationRange: timeRanges.TODAY,
        search: '',
    }
};

const MyAdvertisementsFilters = (props: MyAdvertisementsFiltersProps) => {
    const {onFilterChange} = props;
    const translate = useFormatMessage();

    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);
    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [filters, setFilters] = useState<myFrameFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then

    const handleSearchInputChange = (val: string) => setFilters({...filters, search: val});

    useEffect(() => {
        if (filters?.countryId !== userProfile?.countryMarket?.preferredCountry) {
            setFilters({...filters, countryId: userProfile?.countryMarket?.preferredCountry});
        }
    }, [userProfile]);

    const handleCountryBannerChange = (countryBanner: countryAndBanner) => setFilters({
        ...filters,
        bannerId: countryBanner.banner || '',
        countryId: countryBanner.country
    });

    const handlePromoPriceChange = (price: number, error: boolean) => setFilters({...filters, promotionalPrice: error ? null : price, currency: userProfile.currency});

    useEffect(() => {
        const storedFilters: myFrameFilters = clearBannerIdIfCountryHasChangedOutside(JSON.parse(getFromLocalStorage(storageKey)), userProfile.countryMarket);
        if (storedFilters) {
            storedFilters.lang = userProfile.langData;
            storedFilters.countryId = userProfile.countryMarket.preferredCountry;
            setFilters(storedFilters);
        } else {
            setFilters(buildFiltersTemplate(userProfile.countryMarket.preferredCountry, userProfile.langData));
        }
    }, []);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);
    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeFrameFilters(dbncdFilters, bannersData);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
                storeInLocalStorage(storageKey, stringifiedSaneFilters);
            }
        }
    }, [dbncdFilters]);

    const handleCreationRangeChange = (value: timeRanges, from: string, to: string) => {
        setFilters({...filters, creationRange: value,  createdFrom: from, createdTo: to});
    };

    return !filters ? null :
        <div className="promotionsOverviewFiltersRoot">
            <div>
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           value={filters.search || ''}
                           fullWidth
                           label={translate({id: 'b.product'})}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )
                           }}
                />
            </div>
            <div>
                <CountryBannerSelectorConnected bannerId={filters.bannerId}
                                                onCountryBannerChange={(countryBanner) => handleCountryBannerChange(countryBanner)}
                />
            </div>
            <div>
                <CreationDateRangeInput data={filters.creationRange}
                                        onChange={(value, from, to) => handleCreationRangeChange(value, from, to)}/>
            </div>
            <div>
                <NumberInput valueType={valueTypes.float}
                             onChange={(propIdentifier, value, error) => handlePromoPriceChange(value, error)}
                             label="b.promotionalPrice"
                             value={filters.promotionalPrice || null}
                             propIdentifier={'promoPrice'}
                             endAdornment={userProfile.currency}
                />
            </div>
        </div>;
};

export default MyAdvertisementsFilters;
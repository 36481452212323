import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {useDeleteConnectionOfPage} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewAPI';

interface DisconnectPageProps {
    isPageConnected: boolean,
    leafletId: string,
    onPageDisconnected: () => void,
    page: number
}

const DisconnectPage = (props: DisconnectPageProps) => {
    const {isPageConnected, leafletId, onPageDisconnected, page} = props;

    const translate = useFormatMessage();
    const [busy, setBusy] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const odDisconnectSuccess = () => {
        onPageDisconnected();
        setOpen(false);
        setBusy(false);
    }
    const odDisconnectError = () => setBusy(false);

    const disconnectPageAPI = useDeleteConnectionOfPage(leafletId, page, odDisconnectSuccess, odDisconnectError);

    const handleDisconnectPage = () => disconnectPageAPI.mutate();

    return <>
        <LoadingOverlay show={busy}/>
        <Button variant="contained" color="primary"
                onClick={() => setOpen(true)}
                disabled={!isPageConnected}
        >
            <FormattedMessage id="advertisementsOverview.disconnectPage"/>
        </Button>
        <ConfirmDialog open={open}
                       onConfirm={() => handleDisconnectPage()}
                       onCancel={() => setOpen(false)}
                       message={translate({id:'advertisementsOverview.disconnectPageMsg'})}
                       confirmLabelId={'a.yes'}
                       cancelLabelId={'a.close'}/>
    </>
}

export default DisconnectPage;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {paths} from 'paths';
import {getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {Tab, Tabs} from '@mui/material';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import {ButtonClose} from 'components/Buttons';
import {MarketSelector} from 'components/Selectors';
import GtinQsDuplicatedGtins from './GtinQsDuplicatedGtins';
import GtinQsWithoutGtins from './GtinQsWithoutGtins';

export enum tableViewTypes {
    DUPLICATED_GTINS = 'DUPLICATED_GTINS',
    PRODUCTS_WIHOUT_GTINS = 'PRODUCTS_WIHOUT_GTINS'
}

const GtinQsDashboard = ({history}) => {
    const translate = useFormatMessage();

    const [tableView, setTableView] = useState<tableViewTypes>(tableViewTypes.PRODUCTS_WIHOUT_GTINS);

    useEffect(() => {
        if (getURLParam('activeTab') === tableViewTypes.PRODUCTS_WIHOUT_GTINS) setTableView(tableViewTypes.PRODUCTS_WIHOUT_GTINS);
        else if (getURLParam('activeTab') === tableViewTypes.DUPLICATED_GTINS) setTableView(tableViewTypes.DUPLICATED_GTINS);
    }, []);

    const handleSwitchTableView = (e, value: tableViewTypes) => setTableView(value);

    return (
        <div className="viewRoot gtinListRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div className="_formRowDouble noMargins">
                            <Tabs value={tableView} onChange={handleSwitchTableView} color="secondary">
                                <Tab label={translate({id: 'gtin.productsWithoutGtins'})} value={tableViewTypes.PRODUCTS_WIHOUT_GTINS}/>
                                <Tab label={translate({id: 'gtin.duplicatedGtins'})} value={tableViewTypes.DUPLICATED_GTINS}/>
                            </Tabs>
                            {tableView === tableViewTypes.PRODUCTS_WIHOUT_GTINS && <MarketSelector/>}
                        </div>
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _fullTable _customTooltipPositionFix">
                        {tableView === tableViewTypes.PRODUCTS_WIHOUT_GTINS 
                            ?
                            <GtinQsWithoutGtins history={history} tableView={tableView}/>
                            :
                            <GtinQsDuplicatedGtins history={history} tableView={tableView}/>
                        }
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>
    )
};
export default GtinQsDashboard;


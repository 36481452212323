import SettingsIcon from '@mui/icons-material/Settings';
import {IconButton} from '@mui/material';
import {useDispatch} from 'react-redux';
import {openOptionsDialog} from 'shared/reducers/userProfileActions';

const OptionsDialogButton = () => {
    const dispatch = useDispatch();
    return <IconButton onClick={() => dispatch(openOptionsDialog())}>
        <SettingsIcon color='secondary' />
    </IconButton>;
}

export default OptionsDialogButton;
import './Layout.scss';

import { ReactElement, ReactNode } from 'react';
import Header from 'components/Header';
import { Container } from '@mui/material';

type LayoutProps = {
  headerContent: ReactElement[],
  children: ReactNode
};

const Layout = ({headerContent, children}: LayoutProps) => {
  return (
    <>
      <Header>{headerContent}</Header>
      <Container maxWidth="lg">
        <div className="layoutRoot">
          {children}
        </div>
      </Container>
    </>
  )
};

export default Layout;
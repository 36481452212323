/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {paths} from 'paths';
import {printMediaOverviewTableRow, mediumTypeTranslations, mediumType, leafletStatus} from 'shared/models/leaflet.model';
import {parseParams, useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {transformBEresponse} from 'modules/Leaflet/PrintMediaOverview/printMediaOverviewIO';
import {PrintMediaOverviewLabel} from 'modules/Leaflet/PrintMediaOverview/PrintMediaOverviewAction';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {API_LEAFLET_MANAGEMENT_LEAFLETS_OVERVIEW, API_STATUSES} from 'config/api/constants';
import {PaperX} from 'components/PaperX';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {validityDatesGetter} from 'components/AgTable/getters';
import OverviewFilters, { overviewFilters } from 'components/OverviewFilters';
import {status} from 'components/OverviewFilters/OverviewFilters';

export const getLeafletsTableColumnDefs = (translate: Function) => {
    return [
        { field: 'banner', headerName: translate({ id: 'b.banner' }), flex: 1, lockVisible: true},
        { field: 'fileName', headerName: translate({ id: 'b.fileName' }), flex: 1},
        { field: 'language', headerName: translate({id: 'dictionaryElement.lang'}), flex: 1, maxWidth: 120, valueGetter: (params) => params.data.language ? (translate({id: 'language.'+ params.data.language})) : ''},
        { field: 'numberOfPages', headerName: translate({ id: 'printMediaOverview.numberOfPages' }), cellClass: 'ag-cell-align-right', maxWidth: 120},
        { field: 'status', headerName: translate({ id: 'printMediaOverview.status' }), maxWidth: 200, cellRenderer: (params) => <PrintMediaOverviewLabel value={params.value}/>},
        { field: 'leafletTypes', headerName: translate({ id: 'printMediaOverview.leafletType' }), flex: 1},
        { field: 'mediumType', headerName: translate({ id: 'b.mediumType' }), flex: 1, valueGetter: (params) => params.data.mediumType ? (translate({id: params.data.mediumType === mediumType.PRESS_ADVERTISEMENT ? mediumTypeTranslations[mediumType.PRESS_ADVERTISEMENT] : mediumTypeTranslations[mediumType.LEAFLET] })) : ''},
        { field: 'validityDates', headerName: translate({ id: 'printMediaOverview.validityDates' }), maxWidth: 200, valueGetter: (params) => (validityDatesGetter(params.data))},
        { field: 'createdAt', headerName: translate({ id: 'printMediaOverview.creationDate'}), maxWidth: 200},
        { field: 'createdBy', headerName: translate({ id: 'printMediaOverview.createdBy'}), maxWidth: 120},
        { field: 'modifiedBy', headerName: translate({ id: 'printMediaOverview.modifiedBy'}), maxWidth: 120},
        { field: 'repBranch', headerName: translate({ id: 'b.representativeBranch' }), flex: 1}
    ]
}

const AdvertisementsOverview = (props) => {
    const translate = useFormatMessage();
    const leafletsAPIGet = useApi('get', [], {errMsg: 'a.error2'});
    const [leaflets, setLeaflets] = useState<printMediaOverviewTableRow[]>([]);
    const [leafletFilters, setLeafletFilters] = useState<overviewFilters>(null);

    useEffect(() => {
        if (leafletFilters) {
            const filteredFilters = {};
            for (let prop in leafletFilters) {
                if (prop === "status") {
                    if (!leafletFilters[prop].includes(leafletStatus.ALL) && leafletFilters[prop].length !== status.length) {
                        filteredFilters[prop] = leafletFilters[prop];
                    }
                } else if (prop === "leafletTypeId") {
                    if (leafletFilters[prop]) {
                        filteredFilters[prop] = leafletFilters[prop].map((item) => item.literalId);
                    }
                } else if (prop === "representativeBranchId") {
                    if (leafletFilters[prop]) {
                        filteredFilters[prop] = leafletFilters[prop].id;
                    }
                } else filteredFilters[prop] = leafletFilters[prop];
            }
            leafletsAPIGet.call(API_LEAFLET_MANAGEMENT_LEAFLETS_OVERVIEW, {params: filteredFilters, paramsSerializer: (params) => parseParams(params),});
        }
    }, [leafletFilters]);

    const handleFilterChange = (filters:overviewFilters) => setLeafletFilters(filters);

    useEffect(() => {
        setLeaflets(transformBEresponse(leafletsAPIGet.data));
    }, [leafletsAPIGet.data]);

    const printMediaColumnDefs = getLeafletsTableColumnDefs(translate);

    const goToLeaflet = (leafletId) => props.history.push(`${paths.advertisementBrowser}?id=${leafletId}`);

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={leafletsAPIGet.status === API_STATUSES.PENDING}/>
                <div className="viewContainer _directionCol">
                    <OverviewFilters onFilterChange={handleFilterChange} view={"advertisement"}/>
                    <PaperX className="_fullHeight _fullTable">
                        <AgTable
                            defaultColDef={{
                                suppressMenu: false,
                                resizable: true,
                                sortable: true
                            }}
                            rowData={leaflets}
                            columnDefs={printMediaColumnDefs}
                            onRowClicked = {(rowData) => goToLeaflet(rowData.data.id)}
                            localStorageColumnsKey={localStorageColumnsKeys.advertisementsOverview}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>);
};

export default AdvertisementsOverview;
import axios from 'axios';
import {
    API_RELEASE_LEAFLET_LOCK,
    API_RELEASE_PAGE_LOCK,
    API_RELEASE_PRODUCT_LOCK,
    API_RELEASE_MEDIUM_LOCK,
    API_RENEW_PAGE_LOCK
} from 'config/api/constants';

export enum lockLevels {
    'leaflet' = 'leaflet',
    'page' = 'page',
    'product' = 'product',
    'medium' = 'medium'
}

const currentDate = new Date().toLocaleString();
const lockLocalStorageKeys = {
    leaflet: 'leafletLocked',
    page: 'pageLocked',
    product: 'productLocked',
    medium: 'mediumLocked'
};

export const releasePageLock = () => axios.delete(API_RELEASE_PAGE_LOCK);
export const renewPageLock = (leafletId: string, pageNumber: number) => axios.put(API_RENEW_PAGE_LOCK(leafletId, pageNumber));
export const releaseLeafletLock = () => axios.delete(API_RELEASE_LEAFLET_LOCK);
export const releaseProductLock = () => axios.delete(API_RELEASE_PRODUCT_LOCK);
export const releaseMediumLock = () => axios.delete(API_RELEASE_MEDIUM_LOCK);

export const lockSetter = (lockLevel:lockLevels) => localStorage.setItem(lockLocalStorageKeys[lockLevels[lockLevel]], currentDate);

export const releaseIfLocked = () => {
    if (localStorage.getItem(lockLocalStorageKeys[lockLevels.leaflet])) {
        localStorage.removeItem(lockLocalStorageKeys[lockLevels.leaflet]);
        releaseLeafletLock();
    }
    if (localStorage.getItem(lockLocalStorageKeys[lockLevels.page])) {
        localStorage.removeItem(lockLocalStorageKeys[lockLevels.page]);
        releasePageLock();
    }
    if (localStorage.getItem(lockLocalStorageKeys[lockLevels.product])) {
        localStorage.removeItem(lockLocalStorageKeys[lockLevels.product]);
        releaseProductLock();
    }
    if (localStorage.getItem(lockLocalStorageKeys[lockLevels.medium])) {
        localStorage.removeItem(lockLocalStorageKeys[lockLevels.medium]);
        releaseMediumLock();
    }
}
import { NavigationMenuButton } from 'modules/NavigationMenu';
import './Footer.scss';

type FooterProps = {
  actionsLeft?: React.ReactNode,
  actionsRight?: React.ReactNode,
  hideNavigationMenu?: boolean,
};

const Footer = ({ actionsLeft, actionsRight, hideNavigationMenu }: FooterProps) => {

  return (
    <div className="footerRoot">
      <div className="actionsLeft">
        {!hideNavigationMenu && <NavigationMenuButton />}
        {actionsLeft}
      </div>
      <div className="actionsRight">{actionsRight}</div>
    </div>
  )
};

export default Footer;
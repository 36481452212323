import {countryItem, marketItem} from 'shared/models/dict.model';

export const markets: marketItem[] = [
    {
        name: 'Austria',
        countries: [
            {
                name: 'Austria, Republic of',
                id: 'AT',
                countryId: 'AT'
            }
        ],
        id: '909c063f-8558-4876-92ae-3514e535de4a'
    },
    {
        name: 'Belux',
        countries: [
            {
                name: 'Belgium, Kingdom of',
                id: 'BE',
                countryId: 'BE'
            },
            {
                name: 'Luxembourg, Grand Duchy of',
                id: 'LU',
                countryId: 'LU'
            }
        ],
        id: 'eb21a948-f8c9-4565-bc17-1af17e3e4615'
    },
    {
        name: 'Bulgaria',
        countries: [
            {
                name: 'Bulgaria, Republic of',
                id: 'BG',
                countryId: 'BG'
            }
        ],
        id: '0d22917d-e29e-46dc-97b1-4a9b5c342439'
    },
    {
        name: 'Switzerland',
        countries: [
            {
                name: 'Switzerland, Swiss Confederation',
                id: 'CH',
                countryId: 'CH'
            }
        ],
        id: 'b878e1c0-d331-4f56-becc-a33472752167'
    },
    {
        name: 'Czech Republic',
        countries: [
            {
                name: 'Czech Republic',
                id: 'CZ',
                countryId: 'CZ'
            }
        ],
        id: '711682ff-3dc5-43f1-96aa-10bf0277387b'
    },
    {
        name: 'Germany',
        countries: [
            {
                name: 'Germany, Federal Republic of',
                id: 'DE',
                countryId: 'DE'
            }
        ],
        id: '8661d8b1-4e16-408f-b401-ea32d47f28c9'
    },
    {
        name: 'Croatia',
        countries: [
            {
                name: 'Croatia, Republic of',
                id: 'HR',
                countryId: 'HR'
            }
        ],
        id: '7d548f29-88d6-42b7-8acd-ee2b0d94422c'
    },
    {
        name: 'Hungary',
        countries: [
            {
                name: 'Hungary, Republic of',
                id: 'HU',
                countryId: 'HU'
            }
        ],
        id: '959c586f-80b2-4cd2-8068-e19a99bb5c2b'
    },
    {
        name: 'Moldova',
        countries: [
            {
                name: 'Moldova, Republic of',
                id: 'MD',
                countryId: 'MD'
            }
        ],
        id: '9a178fe3-471a-4e18-9f3a-b012735f7631'
    },
    {
        name: 'Poland',
        countries: [
            {
                name: 'Poland, Republic of',
                id: 'PL',
                countryId: 'PL'
            }
        ],
        id: '37d47331-9ae9-4f5a-a142-72c185bd3580'
    },
    {
        name: 'Romania',
        countries: [
            {
                name: 'Romania',
                id: 'RO',
                countryId: 'RO'
            }
        ],
        id: '937b1413-3a9f-4bf5-90d3-29fde5645bf8'
    },
    {
        name: 'Slovenia',
        countries: [
            {
                name: 'Slovenia, Republic of',
                id: 'SI',
                countryId: 'SI'
            }
        ],
        id: 'db08e8ae-96d7-4339-8a7f-db01cf030158'
    },
    {
        name: 'Slovakia',
        countries: [
            {
                name: 'Slovakia (Slovak Republic)',
                id: 'SK',
                countryId: 'SK'
            }
        ],
        id: '79748004-677b-4d2c-8828-f95a822d2b7e'
    }
];

export const countries: countryItem[] = [
    {
        name: 'Austria, Republic of',
        id: 'AT',
        countryId: 'AT'
    },
    {
        name: 'Belgium, Kingdom of',
        id: 'BE',
        countryId: 'BE'
    },
    {
        name: 'Bulgaria, Republic of',
        id: 'BG',
        countryId: 'BG'
    },
    {
        name: 'Croatia, Republic of',
        id: 'HR',
        countryId: 'HR'
    },
    {
        name: 'Czech Republic',
        id: 'CZ',
        countryId: 'CZ'
    },
    {
        name: 'Germany, Federal Republic of',
        id: 'DE',
        countryId: 'DE'
    },
    {
        name: 'Hungary, Republic of',
        id: 'HU',
        countryId: 'HU'
    },
    {
        name: 'Luxembourg, Grand Duchy of',
        id: 'LU',
        countryId: 'LU'
    },
    {
        name: 'Moldova, Republic of',
        id: 'MD',
        countryId: 'MD'
    },
    {
        name: 'Poland, Republic of',
        id: 'PL',
        countryId: 'PL'
    },
    {
        name: 'Romania',
        id: 'RO',
        countryId: 'RO'
    },
    {
        name: 'Slovakia (Slovak Republic)',
        id: 'SK',
        countryId: 'SK'
    },
    {
        name: 'Slovenia, Republic of',
        id: 'SI',
        countryId: 'SI'
    },
    {
        name: 'Switzerland, Swiss Confederation',
        id: 'CH',
        countryId: 'CH'
    }
];

/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_EXPORT_STATISTICS} from 'config/api/constants';
import {countryMarketItem} from 'shared/reducers/userProfile';
import {useFormatMessage} from 'utils/translate';
import {parseParams} from 'utils/axiosHooks/axiosHooks';
import PaperX from 'components/PaperX/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import CountrySelectionDialog from './CountrySelectionDialog';

interface StatisticsGeneralProps {
    setParentClickHandler: Function,
    countryMarket: countryMarketItem
}

const StatisticsManuallyCreatedPromo = ({setParentClickHandler, countryMarket}: StatisticsGeneralProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [countrySelectionDialogOpen, setCountrySelectionDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setParentClickHandler(() => openCountrySelectDialog);
    }, [countryMarket]);

    const openCountrySelectDialog = () => setCountrySelectionDialogOpen(true);

    const handleExportClick = (countriesList: string[]) => {
        setIsLoading(true);
        axios.get(API_EXPORT_STATISTICS,
            {
                params: {countryId: countriesList},
                paramsSerializer: (params) => parseParams(params),
            }
        ).then((response) => {
            window.open(response.data, '_blank');
            setCountrySelectionDialogOpen(false);
        }).catch((e) => {
            enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            console.log(e);
        }).finally(() => setIsLoading(false));
    }

    return (
        <>
            <PaperX className="_fullHeight _fullWidth">
                <LoadingOverlay show={isLoading}/>
                <div className="_fullHeight _centerX _centerY">
                    {translate({id: 'statistics.manuallyCreatedPromotionsInfoBtn'})}
                </div>
            </PaperX>
            <CountrySelectionDialog onClose={() => setCountrySelectionDialogOpen(false)}
                                    onExport={(countriesList) => handleExportClick(countriesList)}
                                    open={countrySelectionDialogOpen} />
        </>
    );
};

export default StatisticsManuallyCreatedPromo;
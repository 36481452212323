import './ProductFilter.scss';

import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, TextField} from '@mui/material';
import {IRootState} from 'shared/reducers';
import {setUserSetting} from 'shared/reducers/userSettings';
import {filterObject} from 'shared/models/filterComponent.model';
import {useFormatMessage} from 'utils/translate';
import useDebounce from 'utils/debounce';
import ActiveCheckbox from 'components/ActiveCheckbox';

interface productFilterProps {
    filterOptions: string[],
    activeBox: boolean,
    onFiltersChange: (filters: saneFilters) => void,
    userSettings: string
}

export const sanitizeFilters = (filters:filters):saneFilters => {
    const newFilters = Object.keys(filters)
        .filter( (key) => filters[key].value )
        // eslint-disable-next-line
        .reduce( (res, key) => (res[key] = filters[key].value, res), {} );
    return Object.entries(newFilters).length === 0 && newFilters.constructor === Object ? null : newFilters;
};

export type filters = {
    active: {value: string},
    brand: filterObject,
    capacity: filterObject,
    capacityUnit: filterObject,
    category: filterObject,
    contentOfTradingUnits: filterObject,
    contentOfTradingUnitsUnit: filterObject,
    description: filterObject,
    gtin: filterObject,
    productLineName: filterObject,
    typeOfPackage: filterObject,
    variety: filterObject,
    brickId: filterObject
}

export type saneFilters = { // used to build API calls, only those used exist
    active?: {value: string},
    brand?: filterObject,
    capacity?: filterObject,
    capacityUnit?: filterObject,
    category?: filterObject,
    contentOfTradingUnits?: filterObject,
    contentOfTradingUnitsUnit?: filterObject,
    description?: filterObject,
    gtin?: filterObject,
    productLineName?: filterObject,
    typeOfPackage?: filterObject,
    variety?: filterObject,
    brickId?: filterObject
}

const ProductFilter = (props:productFilterProps) => {
    const translate = useFormatMessage();
    
    const blankFilters: filters = {
        active: {value: 'true'},
        brand: {value: '', label: translate({id: 'b.brand'})},
        capacity: {value: '', label: translate({id: 'b.capacity'})},
        capacityUnit: {value: '', label: translate({id: 'b.capacityUnit'})},
        category: {value: '', label: translate({id: 'b.category'})},
        contentOfTradingUnits: {value: '', label: translate({id: 'b.contentOfTradingUnit'})},
        contentOfTradingUnitsUnit: {value: '', label: translate({id: 'b.contentOfTradingUnitUnit'})},
        description: {value: '', label: translate({id: 'b.descr'})},
        gtin: {value: '', label: translate({id: 'b.gtin'})},
        productLineName: {value: '', label: translate({id: 'b.productLine'})},
        typeOfPackage: {value: '', label: translate({id: 'b.typeOfPackage'})},
        variety: {value: '', label: translate({id: 'b.variety'})},
        brickId: {value: '', label: translate({id: 'b.categoryBrick'})}
    };
    
    const {filterOptions, activeBox, userSettings} = props;
    const [filters, setFilters] = useState<filters>(blankFilters);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);

    const dispatch = useDispatch();
    const firstUpdate = useRef<boolean>(true);

    const savedFilters = useSelector((state: IRootState) => state.userSettings.settings);

    const buildFilterOptionTextField = () => filterOptions.map((item, index) =>
        <TextField
            key={index}
            color="primary"
            label={blankFilters[item].label}
            value={filters[item].value}
            onChange={(e) => updateFilter(e.target.value, item)}
            size="small"
            style={{"width": "100%"}}
        />)

    useEffect(() => {
        sanitizeFilters(dbncdFilters);
    }, [dbncdFilters]);

    useEffect(() => {
        if (userSettings !== null){
            // debounced filters cause two api calls - one with initial filters, second one with restored filters, so now
            if (firstUpdate.current) {
                // if it is first update
                if (savedFilters[userSettings]) {
                    // component will first try to set debounced to restored filters
                    setFilters(savedFilters[userSettings]);
                    setDbncdFilters(savedFilters[userSettings]);
                } else {
                    // or just yell 'no filters here'
                    props.onFiltersChange(sanitizeFilters(dbncdFilters));
                }
                firstUpdate.current = false;
            } else {
                // on subsequent updates component will yell actual debounced filter, whether they're set by user or restored in first update
                props.onFiltersChange(sanitizeFilters(dbncdFilters));
                if (dbncdFilters) dispatch(setUserSetting(userSettings, filters));
            }
        } else {
            props.onFiltersChange(sanitizeFilters(dbncdFilters));
        }
        // eslint-disable-next-line
    }, [dbncdFilters]);

    const onClearFiltersClick = () => {
        setFilters(blankFilters);
        setDbncdFilters(blankFilters);
    };

    const updateFilter = (value: string, key: string) => {
        const newFilters: filters = {...filters};
        if (key !== 'active'){
            newFilters[key] = {value: value, label:filters[key].label};
        }else newFilters[key] = {value: value};
        setFilters(newFilters);
        setDbncdFilters(newFilters);
    };

    return (
        <div className="complexMDFilters">
            <ul>
                {buildFilterOptionTextField()}
            </ul>
            {activeBox ?
                <div className="filterActive">
                    <ActiveCheckbox onChange={(key, value) => updateFilter(value.toString(), key)} value={filters.active.value === 'true'}/>
                </div> : null
            }
            <Button onClick={onClearFiltersClick} variant="contained" color="primary" size="small">{translate({id: 'filters.clear'})}</Button>
        </div>
    )
};
export default ProductFilter;
import axios from 'axios';
import {API_DICTIONARIES, API_STATUSES} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {dictionaries} from 'shared/models/dictionaries.model';

enum ACTION_TYPES {
    START_FETCHING_DICT_DICTIONARIES = 'START_FETCHING_DICT_DICTIONARIES',
    ON_SUCCESS_DICT_DICTIONARIES = 'ON_SUCCESS_DICT_DICTIONARIES',
    ON_ERROR_DICT_DICTIONARIES = 'ON_ERROR_DICT_DICTIONARIES'
}

export function fetchDictDictionaries () {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_DICT_DICTIONARIES
        });
        axios.get(API_DICTIONARIES).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_DICT_DICTIONARIES,
                    payload: {data: resp.data}
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_DICT_DICTIONARIES});
            }
        }).catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_DICT_DICTIONARIES})
        })
    }
}

const initialState: dictionaries = {
    status: API_STATUSES.NEW,
    data: []
};

export type DictDictionariesState = Readonly<typeof initialState>;

const dictDictionariesReducer = (state: DictDictionariesState = initialState, action): DictDictionariesState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_DICT_DICTIONARIES:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_DICT_DICTIONARIES:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_DICT_DICTIONARIES:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default dictDictionariesReducer;
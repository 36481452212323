import {useState} from 'react';
import {Button} from '@mui/material';
import {API_SUPPLIER_SEARCH} from 'config/api/constants';
import {supplierSearchResult} from 'shared/models/supplier.model';
import {PaperX} from 'components/PaperX';
import ProductSearch from 'components/ProductSearch/ProductSearch';

interface SupplierSearchPanelProps {
    marketId: string,
    onSupplierAdd: (selectedSupplier: supplierSearchResult) => void
}

const SupplierSearchPanel = (props: SupplierSearchPanelProps) => {
    const {marketId, onSupplierAdd} = props;
    const [suppliers, setSuppliers] = useState<supplierSearchResult[]>([]);

    const handleSearchResponse = (searchResult: supplierSearchResult[]) => setSuppliers(searchResult);

    return (<>
        <PaperX className="weldBottom">
            {marketId && <ProductSearch<supplierSearchResult> marketId={marketId || ''}
                                                           labelId="supplier.search"
                                                           onNewData={(results) => handleSearchResponse(results)}
                                                           searchUrl={API_SUPPLIER_SEARCH}
            />}
        </PaperX>
        <PaperX className="weldTop _scrollY _fullHeight">
            <ul>
                {suppliers.map((item) => <li key={item.id} >{item.name}<Button onClick={() => onSupplierAdd(item)}>add</Button></li>)}
            </ul>
        </PaperX>
    </>);
};

export default SupplierSearchPanel;
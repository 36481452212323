import {FormattedMessage} from 'react-intl';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {inputSize} from 'components/Selectors/CountrySelector/CountrySelector';
import {useFormatMessage} from 'utils/translate';

interface CountryDisplayAsInputProps {
    compact?: boolean, // @TODO now use compact by default, make it configurable
    required?: boolean,
    size?: inputSize,
    value: string
}

const CountryDisplayAsInput = (props: CountryDisplayAsInputProps) => {
    const {compact, required, size, value} = props;
    const translate = useFormatMessage();
    return (
        <div className={compact ? "countrySelectorRootCompact" : "countrySelectorRoot"}>
            <FormControl className="countrySelect" required={required} fullWidth>
                <InputLabel color="primary"><FormattedMessage id="b.country"/></InputLabel>
                <Select color="primary" value={value} size={size || "medium"} disabled
                        required={required}
                        label={translate({id: 'b.country'})}
                >
                    <MenuItem value={value}>{value}</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default CountryDisplayAsInput;
import './PageSeasonThemeSelector.scss'

import {IconButton, TextField} from '@mui/material';
import {Attribute} from 'components/Attribute';
import {handyAttributes} from 'shared/handyAttributes';
import {DeleteOutline} from '@mui/icons-material';
import {useFormatMessage} from 'utils/translate';
import {multiselectAttributeDataModel} from 'shared/models/attributeComponent.model';
import {applyRangeMask} from 'components/Selectors/ValiditySelector/ValiditySelector';

type PageSeasonThemeSelectorProps = {
    id: number,
    pageNumber?: number | string, // can be string if used in rangeMode
    numberOfPages: number,
    themeSeasons: multiselectAttributeDataModel,
    onPageChange?: (id: number, pageNumber: string) => void,
    onRemove?: (id: number) => void,
    onSeasonThemeChange: (id:number, seasonTheme: multiselectAttributeDataModel) => void,
}

const PagesSeasonThemeSelector = ({id, pageNumber, numberOfPages, themeSeasons, onPageChange, onRemove, onSeasonThemeChange}:PageSeasonThemeSelectorProps) => {
    const translate = useFormatMessage();

    const handleSeasonThemeChange = (value: multiselectAttributeDataModel) => {
        onSeasonThemeChange(id, value);
    }

    const handlePageChange = (value: string | number) => {
        if (typeof value === 'number') {
            onPageChange(id, value + '');
        } else {
            onPageChange(id, value);
        }
    };

    const handleRangeInputChange = (value: string) => onPageChange(id, applyRangeMask(value, 1, numberOfPages));

    return (
        <div className="pageSeasonThemeRoot">
            <div className="pageNumber">
                { numberOfPages ?
                    <TextField
                        label={translate({id: 'headerData.pages'})}
                        value={pageNumber}
                        onChange={(e) => handlePageChange(e.target.value)}
                        onBlur={(e) => handleRangeInputChange(e.target.value)}
                        error={!pageNumber}
                    />
                    : null
                }
            </div>
            <div className="seasonThemeRoot">
                <Attribute id={handyAttributes.themeSeason}
                           data={themeSeasons}
                           onChange={(key, value) => handleSeasonThemeChange(value)}
                           multiselect
                           disableAutoselect={true}/>
            </div>
            <IconButton onClick={() => onRemove(id)}>
                <DeleteOutline color="secondary" fontSize="small"/>
            </IconButton>
        </div>
    );
}

export default PagesSeasonThemeSelector;
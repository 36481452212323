import './HeaderDataDialog.scss';
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {API_ADVERTISING_AREA_FOR_BANNER, API_STATUSES} from 'config/api/constants';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {branch} from 'shared/models/branch.model';
import {advertisingAreaUIModel} from 'shared/models/advertisingArea.model';
import AdvertisingAreasIndex from 'modules/MasterData/AdvertisingArea/AdvertisingAreasIndex';
import AdvertisingAreaSummary from 'modules/MasterData/AdvertisingArea/AdvertisingAreaSummary';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';

interface advertisingAreaDialogProps {
    open: boolean,
    busy: boolean,
    onClose: Function,
    onSelectArea: (branchesOfArea: branch[], representativeBranchId: string) => void,
    onCheckboxClick: (branchesOfArea: branch[], representativeBranchId: string) => void,
    bannerId: string,
    branches: branch[]
}

const AdvertisingAreaDialog = (props: advertisingAreaDialogProps) => {
    const {open, busy, onClose, bannerId, branches, onSelectArea, onCheckboxClick} = props;
    const translate = useFormatMessage();
    const areasAPIGet = useApi('get', [], {errMsg: 'advertisingAreaList.err'});

    const [selectedArea, setSelectedArea] = useState<advertisingAreaUIModel>(null);
    const [branchesForArea, setBranchesForArea] = useState<branch[]>([]);
    const [selectedComboArea, setSelectedComboArea] = useState<branch[]>([]);

    const getAreasData = (bannerId: string) => areasAPIGet.call(API_ADVERTISING_AREA_FOR_BANNER(bannerId));

    useEffect(() => {
        if (open) setSelectedComboArea([]);
    }, [open]);

    useEffect(() => {
        if (selectedArea === null || selectedArea === undefined) {
            setBranchesForArea([]);
        } else {
            setBranchesForArea(branches.filter((item) => selectedArea.branchIds.find((branchId) => branchId === item.id )));
        }
    }, [selectedArea]);

    useEffect(() => {
        if (branches && branches?.length > 0 && (selectedArea !== null && selectedArea !== undefined)) {
            setBranchesForArea(branches.filter((item) => selectedArea.branchIds.find((branchId) => branchId === item.id )));
        } else {
            setBranchesForArea([]);
        }
    }, [branches]);

    useEffect(() => {
        if (bannerId) {
            getAreasData(bannerId);
            setSelectedArea(null);
            setBranchesForArea([]);
        }
    }, [bannerId]);


    const handleCheckboxSelect = (value) => {
        const filteredBranches:branch[] = branches.filter((item) => value.branchIds.includes(item.id));
        const updatedSelectComboArea:branch[] = [...selectedComboArea];

        filteredBranches.forEach(branch => {
            const index:number = updatedSelectComboArea.findIndex(selected => selected.id === branch.id);
            if (index !== -1) {
                updatedSelectComboArea.splice(index, 1);
            } else {
                updatedSelectComboArea.push(branch);
            }
        });
        setSelectedComboArea(updatedSelectComboArea);
    }

    return (<Dialog className="advertisingAreaDialogRoot" open={open} fullScreen>
        <LoadingOverlay show={busy || areasAPIGet.status === API_STATUSES.PENDING}/>
        <DialogContent className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <AdvertisingAreasIndex selectedArea={selectedArea} areas={areasAPIGet.data} onAreaClick={setSelectedArea} onCheckboxClick={handleCheckboxSelect} canSelect/>
                    <AdvertisingAreaSummary branchesForArea={branchesForArea} selectedArea={selectedArea}/>
                </div>
            </div>
        </DialogContent>
        <DialogActions className="dialogFooter">
            <Button color="primary" variant="contained" disabled={Array.isArray(selectedComboArea) && selectedComboArea.length === 0} onClick={() => onCheckboxClick(selectedComboArea, null)} className="leftBtn">
                <FormattedMessage id="headerData.useComboAdvAreas"/>
            </Button>
            <Button color="primary" variant="contained" disabled={!selectedArea} onClick={() => onSelectArea(branchesForArea, selectedArea?.representativeBranchId)}>
                {translate({id: 'headerData.useAdvArea'}, {areaName: selectedArea ? selectedArea.name : ' - '})}
            </Button>
            <ButtonClose onClick={onClose} />
        </DialogActions>
    </Dialog>);
};
export default AdvertisingAreaDialog;
import './Attribute.scss';

import {ReactNode, useEffect, useState} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {
    numberAttributeDataModel,
    rangeAttributeDataModel,
    unitAttributeModel
} from 'shared/models/attributeComponent.model';
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear';

type attributeUnitProps = {
    children: ReactNode,
    data: numberAttributeDataModel & rangeAttributeDataModel,
    model: unitAttributeModel,
    onChange: Function,
    onClear: any,
    required?: boolean,
    disabled?: boolean
}

const AttributeUnit = (props: attributeUnitProps) => {
    const translate = useFormatMessage();
    const {children, data, model, onChange, onClear, required, disabled} = props;

    const [options, setOptions] = useState([]);

    useEffect(() => {
        let optionsArr = [];
        model.options
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach((item) => {
                item.options.sort((a, b) => a.name.localeCompare(b.name));
                optionsArr = optionsArr.concat(item.options.map((subItem) => {
                    return {...subItem, unit: item.name}
                }));
            });
        setOptions(optionsArr);
    }, [model]);

    const handleUnitChange = (key, value) => onChange(key, value ? value.id : '');

    const findOptionForDataId = () => {
        let match = options.find((item) => item.id === data.unitId);
        return match ? match.name : '';
    };

    const getName = () => required ? model.name + ' *' : model.name;

    return (
        <div className="attributeRoot attributeRange">
            <fieldset className="complexAttributeWrapper">
                <legend>{getName()}</legend>
                {children}
                <Autocomplete
                    className="attributeAutocomplete"
                    autoSelect
                    isOptionEqualToValue={(option, value) => option.id === value.id || option.id === value.unitId}
                    value={data}
                    options={options}
                    groupBy={(option) => option.unit}
                    getOptionLabel={(option) => option.name || findOptionForDataId()}
                    renderOption={(props, option) => <li {...props} key={option['id']}>{option['name']}</li>}
                    renderInput={(params) => <TextField {...params} label={translate({id: 'b.unit'})} />}
                    onChange={(e, value) => handleUnitChange('unitId', value)}
                    disabled={disabled}
                />
                <div className="clearInputButtonContainer">
                    <IconButton onClick={onClear} tabIndex={-1}>
                        <ClearIcon/>
                    </IconButton>
                </div>
            </fieldset>
        </div>
    );
};
export default AttributeUnit;
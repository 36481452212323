/* eslint-disable react-hooks/exhaustive-deps */

import {ReactElement, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
    API_CATEGORIES_HIERARCHY,
    API_CATEGORY_ELASTIC_SEARCH
} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {
    categoryHierarchyResponseModel,
    categorySearchResponseObject,
    categoryTableItem,
    categoryTableItemWithSearchString
} from 'shared/models/category.model';
import {setCategoryHierarchyState} from 'shared/reducers/categoryHierarchy';
import useDebounce from 'utils/debounce';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {
    addSearchString,
    transformElasticSearch4UI,
    tree2table
} from 'modules/MasterData/Category/categoryIO';
import CategoryAttributes from 'modules/MasterData/Category/CategoryAttributes/CategoryAttributes';
import CategoryTurboSearchTable from './CategoryTurboSearchTable';

interface CategoryTurboSearchProps {
    customLauncher?: ReactElement,
    onCategorySelect: (categoryRow: categoryTableItem) => void,
    onFrameDescriptionView?: boolean,
    remoteSearch?: boolean, //perform API call to elastic search if set to true; otherwise search in local hierarchy data
}

const CategoryTurboSearch = ({customLauncher, onCategorySelect, onFrameDescriptionView, remoteSearch}: CategoryTurboSearchProps) => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState<boolean>(false);
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [categories, setCategories] = useState<categoryTableItemWithSearchString[]>([]);
    const [filteredCategories, setFilteredCategories] = useState<categoryTableItem[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [dbncdSearchString, setDbncdSearchString] = useDebounce(searchString, 500);
    const [reload, setReload] = useState<boolean>(false); // need to force reload in rare cases when language is changed after categories are fetched

    const [brickId, setBrickId] = useState<string>('');

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const cachedCategoryHierarchy = useSelector((state: IRootState) => state.categoryHierarchy);

    const cancelToken = useRef(null);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if ( !remoteSearch && ((open && !categories.length) || reload) ) {
            getCategories();
            setReload(false);
        }
    }, [open]);

    useEffect(() => {
        if (!reload) {
            setReload(true);
        }
    }, [selectedLanguage]);

    useEffect(() => {
        if (!searchString) {
            setFilteredCategories(categories);
        } else if (!remoteSearch) {
            searchCategories(searchString);
        }
    }, [searchString]);

    useEffect(()=> {
        if (remoteSearch) {
            if (dbncdSearchString?.length > 2) {
                searchCategoriesRemote(dbncdSearchString);
            } else {
                setCategories([]);
                setFilteredCategories([]);
            }
        }
    }, [dbncdSearchString]);

    const searchCategories = (searchString: string) => {
        const phrase = searchString.toLowerCase();
        setFilteredCategories(categories.filter((item) => (item.searchStr.indexOf(phrase) > -1)));
    };

    const getCategories = () => {
        if (selectedLanguage !== cachedCategoryHierarchy.lang || cachedCategoryHierarchy.data.length === 0) {
            setLoading(true);
            if (cancelToken.current) cancelToken.current();
            axios.get<categoryHierarchyResponseModel>(API_CATEGORIES_HIERARCHY, {
                params: {lang: selectedLanguage},
                cancelToken: new axios.CancelToken(
                    cancel => (cancelToken.current = cancel)
                )
            })
                .then((resp) => {
                    if (responseValidation(resp.data)) {
                        const transformedResp: categoryTableItemWithSearchString[] = addSearchString(tree2table(resp.data));
                        setCategories(transformedResp);
                        setFilteredCategories(transformedResp);
                        dispatch(setCategoryHierarchyState({lang: selectedLanguage, data: transformedResp}));
                    } else {
                        enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    }
                })
                .catch((e) => {
                    if (!e.__CANCEL__) {
                        console.log(e);
                        enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setCategories(cachedCategoryHierarchy.data);
            setFilteredCategories(cachedCategoryHierarchy.data);
        }
    };

    const searchCategoriesRemote = (searchString: string) => {
        setLoading(true);
        if (cancelToken.current) cancelToken.current();
        axios.get<categorySearchResponseObject[]>(API_CATEGORY_ELASTIC_SEARCH, {
            params: {
                lang: selectedLanguage,
                search: searchString
            },
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )
        })
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    const transformedResp: categoryTableItemWithSearchString[] = addSearchString(transformElasticSearch4UI(resp.data));
                    setCategories(transformedResp);
                    setFilteredCategories(transformedResp);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if (!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .finally(() => setLoading(false));
    };

    const handleSelect = (categoryRow: categoryTableItem) => {
        onCategorySelect(categoryRow);
        setOpen(false);
    };

    const handleSelectFromDetails = () => {
        setDetailsOpen(false);
        handleSelect(categories.find((item) => item.bId === brickId));
    };

    const handleShowDetails = (categoryRow: categoryTableItem) => {
        setBrickId(categoryRow.bId)
        setDetailsOpen(true)
    };

    const handleSearchStringChange = (value: string) => {
        setSearchString(value);
        setDbncdSearchString(value);
    };

    return (
        <>
            {customLauncher ? <div onClick={() => setOpen(true)}>{customLauncher}</div> : <IconButton onClick={() => setOpen(true)} color="primary" tabIndex={-1}>
                <SearchIcon/>
            </IconButton>}
            <Dialog className={`${onFrameDescriptionView ? '_dialogOnDescribeFrameView _dialog-likeView' : '_dialog-likeView'}`}
                    open={open} fullScreen onClose={() => setOpen(false)}>
                <LoadingOverlay show={loading}/>
                <DialogContent className="_directionCol">
                    <PaperX>
                        <TextField className="_halfWidth"
                                   value={searchString}
                                   onChange={(e) => handleSearchStringChange(e.target.value)}
                                   label={translate({id: 'a.search'})}
                                   autoFocus
                        />
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _fullTable">
                        <CategoryTurboSearchTable data={filteredCategories}
                                                  onSelect={(categoryRow) => handleSelect(categoryRow)}
                                                  onShowDetails={(categoryRow) => handleShowDetails(categoryRow)}
                                                  searchPhrase={searchString}
                                                  remoteSearch={remoteSearch}
                        />
                    </PaperX>
                </DialogContent>
                <DialogActions>
                    <ButtonClose onClick={() => setOpen(false)}/>
                </DialogActions>
            </Dialog>
            <Dialog className="_dialog-likeView" maxWidth="lg" fullWidth
                    open={detailsOpen && !!brickId}
                    onClose={() => setDetailsOpen(false)}>
                <CategoryAttributes externalCategoryId={brickId}/>
                <Divider/>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={handleSelectFromDetails}>
                        <FormattedMessage id="a.select"/>
                    </Button>
                    <ButtonClose onClick={() => setDetailsOpen(false)}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CategoryTurboSearch;
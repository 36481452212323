import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_FILTERED_PRODUCTS} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {fetchProductsAPI, filteredProductsResponseModel} from 'shared/models/product.model';
import {useFormatMessage} from 'utils/translate';
import {saneFilters, sanitizeFilters} from 'components/ProductFilter/ProductFilter';

const fetchProducts = async (filters:fetchProductsAPI, cancelToken): Promise<filteredProductsResponseModel[]> => {
    if (cancelToken) {
        cancelToken();
    }
    const params = {};
    if (filters !== null) {
        for (let key in filters) {
            params[key] = filters[key];
        }
    }
    const response = await axios.get(API_GET_FILTERED_PRODUCTS, {params: params, cancelToken: new axios.CancelToken(
            cancel => (cancelToken = cancel)
        )});

    return response.data
};

export const useProductsQuery = (filters: saneFilters, cancelToken, userSettingsKey: string, marketId: string) => {
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const settings = useSelector((state: IRootState) => state.userSettings.settings);
    const lang = useSelector((state: IRootState) => state.userProfile.langData);

    const buildAPIParams = ():fetchProductsAPI => {
        if (userSettingsKey !== null) {
            if (settings[userSettingsKey] !== null && settings[userSettingsKey] !== undefined) {
                return {...sanitizeFilters(settings[userSettingsKey]), lang, marketId};
            }
            return {...filters, lang, marketId};
        } else {
            return {...filters, lang, marketId};
        }
    };

    return useQuery(
        [filters, marketId],
        () => fetchProducts(buildAPIParams(), cancelToken),
        {
            staleTime: 0,
            cacheTime: 1,
            enabled: false,
            onError: (e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'product.listErr'})}`, {variant: 'error', persist: false});
            }
        }
    );
}
import './ActionListCard.scss';

import { Link } from 'react-router-dom';
import {Checkbox} from '@mui/material';
import {advertisingAreaUIModel} from 'shared/models/advertisingArea.model';
import {franchiseOwner} from 'shared/models/franchiseOwner.model';
import {ButtonEdit} from 'components/Buttons';

interface ActionListCardProps {
    canEdit?: boolean,
    canSelect?: boolean,
    id: string,
    item: advertisingAreaUIModel | franchiseOwner,
    label: string,
    onCardClick: (advertisingAreaUIModel) => void,
    onCheckboxSelect?: (advertisingAreaUIModel) => void
    path: string,
    selectedId: string,
}

export const ActionListCard = (props: ActionListCardProps) => {

    return (<li className={props.selectedId === props.id ? 'actionListCard selected' : 'actionListCard'} onClick={() => props.onCardClick(props.item)}>
        {props.canSelect
        ?
        <Checkbox tabIndex={-1} onChange={() => props.onCheckboxSelect(props.item)}/>
        :
        null
        }
        <div className="actionListCardLabel">{props.label}</div>
        {props.canEdit 
        ?
        <Link to={`${props.path}?id=${props.id}`}>
            <ButtonEdit/>
        </Link> 
        :
        null}
    </li>);
};
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import {
    API_GET_SUPPLIERS_FOR_BRAND,
    API_PRODUCT_BULK_UPDATE_BRAND_SUPPLIER_PRE_VALIDATION, API_PRODUCT_DATA_PRODUCTS_BRAND_BULK_UPDATE,
    API_PRODUCT_DATA_PRODUCTS_SUPPLIER_BULK_UPDATE,
    API_PRODUCT_WITH_SUPPLIER_SEARCH
} from 'config/api/constants';
import {paths} from 'paths';
import {
    bulkProductBrandSupplierUpdatePayload,
    bulkProductUpdateDuplicateCheckResponse,
    productWithSupplierSearchResultResponse
} from 'shared/models/product.model';
import {basic} from 'shared/models/_common.model';
import {IRootState} from 'shared/reducers';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {ConfirmDialog} from 'components/ConfirmDialog';
import ProductBulkChangeTable from 'modules/MasterData/Product/ProductBulkChange/ProductBulkChangeTable';
import {
    productSearchTableRow,
    productsListSearchQueryStrLocalStorageKey,
    transformResponseWithSuppliers
} from 'modules/MasterData/Product/ProductsListSearchResults';
import ProductBulkChangeFilters, {productBulkChangeFilters} from 'modules/MasterData/Product/ProductBulkChange/ProductBulkChangeFilters';
import SupplierDialogWithBrandSelectionDialog from 'modules/MasterData/Product/ProductDetails/SupplierDialogWithBrandSelection';

const ProductBulkSupplier = () => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [productsTableData, setProductsTableData] = useState<productSearchTableRow[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<productSearchTableRow[]>([]);
    const [supplier2Change, setSupplier2Change] = useState<basic>(null);
    const [brand2Change, setBrand2Change] = useState<basic>(null);
    const [brand, setBrand] = useState<basic>(null);
    const [filters, setFilters] = useState<productBulkChangeFilters>(null);
    const [supplierDialogWithBrandSelectionOpen, setSupplierDialogWithBrandSelectionOpen] = useState<boolean>(false);

    const [duplicates, setDuplicates] = useState<number>(null);

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const [confirmSupplierChangeDialogOpen, setConfirmSupplierChangeDialogOpen] = useState<boolean>(false);
    const [confirmBrandChangeDialogOpen, setConfirmBrandChangeDialogOpen] = useState<boolean>(false);
    const [confirmDuplicatesDialogOpen, setConfirmDuplicatesDialogOpen] = useState<boolean>(false);

    const cancelToken = useRef(null);
    const cancelSuppliersToken = useRef(null);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
            if (cancelSuppliersToken.current) cancelSuppliersToken.current();
        }
    }, []);

    useEffect(() => {
        setBrand(null);
    }, [selectedProducts]);

    const getData = (filters: productBulkChangeFilters) => {
        if (cancelToken?.current) {
            cancelToken.current();
        }
        setIsLoading(true);
        setSelectedProducts([]);
        setProductsTableData([]);
        axios.get<productWithSupplierSearchResultResponse>(API_PRODUCT_WITH_SUPPLIER_SEARCH, {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )
        })
            .then((resp) => {
                setIsLoading(false);
                if (responseValidation(resp.data)) {
                    setProductsTableData(transformResponseWithSuppliers(resp.data));
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    setIsLoading(false);
                }
            });
    };

    const handleFiltersChange = (f: productBulkChangeFilters) => {
        if (f.search) {
            setFilters(f);
            getData(f)
        }
    };

    const productsFromSameBrand = (): boolean => {
        const brands = new Set<string>(selectedProducts.map((item) => item.brand));
        return brands.size === 1;
    };

    const startBrandSupplierChange = () => {
        if (cancelSuppliersToken?.current) {
            cancelSuppliersToken.current();
        }
        setIsLoading(true);
        // cant get brand via API_GET_BRAND bc normal and structured brands use different endpoints and it cant be determined here
        // so we rebuild it to populate supplier selector dialog
        axios.get<basic>(API_GET_SUPPLIERS_FOR_BRAND(selectedProducts[0].brandId), {
            params: filters,
            cancelToken: new axios.CancelToken(
                cancel => (cancelSuppliersToken.current = cancel)
            )
        })
            .then((resp) => {
                setIsLoading(false);
                if (responseValidation(resp.data)) {
                    const newBrand: basic = {
                        id: selectedProducts[0].brandId,
                        name: selectedProducts[0].brand,
                    }
                    setBrand(newBrand);
                    setSupplierDialogWithBrandSelectionOpen(true);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                    setIsLoading(false);
                }
            });
    };

    const handleSupplierSelect = (supplier: basic) => {
        setSupplier2Change(supplier);
        setConfirmSupplierChangeDialogOpen(true);
    };

    const handleSupplierChangeConfirm = () => {
        setIsLoading(true);
        const payload: bulkProductBrandSupplierUpdatePayload = {
            brandId: selectedProducts[0].brandId,
            productIds: selectedProducts.map((item) => item.id),
            supplierId: supplier2Change?.id
        };
        axios.patch(API_PRODUCT_DATA_PRODUCTS_SUPPLIER_BULK_UPDATE, payload)
            .then(() => {
                enqueueSnackbar(
                    `${translate({id: 'product.bulkSucc'})}. ${translate({id: 'product.bulkSucc2'})}`,
                    {
                        variant: 'success',
                        persist: false
                    });
                cleanup();
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const testDuplicates = () => {
        setIsLoading(true);
        const payload: bulkProductBrandSupplierUpdatePayload = {
            brandId: brand2Change.id,
            productIds: selectedProducts.map((item) => item.id),
            supplierId: supplier2Change?.id || null
        };
        axios.post<bulkProductUpdateDuplicateCheckResponse>(API_PRODUCT_BULK_UPDATE_BRAND_SUPPLIER_PRE_VALIDATION, payload)
            .then((resp) => {
                if (resp?.data?.duplicates?.length > 0) {
                    setDuplicates(resp.data.duplicates.length);
                    setConfirmDuplicatesDialogOpen(true);
                    setIsLoading(false);
                } else {
                    updateBrandFinalCall();
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                setIsLoading(false);
            });
        // no setIsLoading(false) in finally as there is a case when we want to keep spinner spinning
    };

    const handleBrandChange = (brand: basic, supplier: basic) => {
        setBrand2Change(brand);
        setSupplier2Change(supplier);
        setConfirmBrandChangeDialogOpen(true);
    };

    const handleBrandChangeConfirm = () => {
        testDuplicates();
    };
    
    const handleDuplicateConfirm = () => {
        setConfirmDuplicatesDialogOpen(false);
        updateBrandFinalCall();
    };

    const updateBrandFinalCall = () => {
        setIsLoading(true);
        const payload: bulkProductBrandSupplierUpdatePayload = {
            brandId: brand2Change.id,
            productIds: selectedProducts.map((item) => item.id),
            supplierId: supplier2Change?.id || null
        };
        axios.patch(API_PRODUCT_DATA_PRODUCTS_BRAND_BULK_UPDATE, payload)
            .then(() => {
                enqueueSnackbar(
                    `${translate({id: 'product.bulkSucc'})}. ${translate({id: 'product.bulkSucc2'})}`,
                    {
                        variant: 'success',
                        persist: false
                    });
                cleanup();
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false));
    };
    
    const cleanup = () => {
        setConfirmBrandChangeDialogOpen(false);
        setSupplierDialogWithBrandSelectionOpen(false);
        setConfirmSupplierChangeDialogOpen(false);
        setSupplier2Change(null);
        setBrand2Change(null);
        setSelectedProducts([]);
        setProductsTableData([]);
        setDuplicates(null);
    };

    return (
        <>
            <div className="viewRoot">
                <div className="viewport">
                    <LoadingOverlay show={isLoading}/>
                    <div className="viewContainer _directionCol">
                        <PaperX>
                            <div className="filters noMargins">
                                <ProductBulkChangeFilters onFilterChange={(f) => handleFiltersChange(f)}
                                                          onReloadClick={() => getData(filters)}
                                                          restoredQueryStrKey={productsListSearchQueryStrLocalStorageKey}/>
                                {productsTableData?.length > 99 && <span className="_bold"><FormattedMessage id="a.searchLimit"/></span>}
                            </div>
                        </PaperX>
                        <PaperX className="_fullHeight _fullWidth _fullTable _customTooltipPositionFix">
                            <ProductBulkChangeTable data={productsTableData}
                                                    onSelectionChange={(products) => setSelectedProducts(products)}/>
                        </PaperX>
                    </div>
                </div>
                <Footer
                    actionsRight={
                        <>
                            <Button variant="contained"
                                    onClick={() => startBrandSupplierChange()}
                                    disabled={selectedProducts.length < 2 || !productsFromSameBrand()}>
                                <FormattedMessage id="product.bulkUpdate" values={{count: selectedProducts.length}}/>
                            </Button>
                            <Link to={paths.productsList}>
                                <ButtonClose/>
                            </Link>
                        </>
                    }
                />
            </div>
            <SupplierDialogWithBrandSelectionDialog marketId={countryMarket.market}
                                                    onClose={() => {
                                                        setSupplierDialogWithBrandSelectionOpen(false);
                                                        setSupplier2Change(null);
                                                        setBrand2Change(null);
                                                    }}
                                                    onBrandChange={(brand, supplier) => handleBrandChange(brand, supplier)}
                                                    onSupplierChange={(supplier) => handleSupplierSelect(supplier)}
                                                    open={supplierDialogWithBrandSelectionOpen}
                                                    originalBrand={brand}
            />
            <ConfirmDialog open={confirmSupplierChangeDialogOpen}
                           onConfirm={handleSupplierChangeConfirm}
                           onCancel={() => setConfirmSupplierChangeDialogOpen(false)}
                           message={
                               <p>
                                   <FormattedMessage id="product.bulkSupplierConfirm" values={{sName: supplier2Change?.name,  count: selectedProducts?.length}}/>
                               </p>
                           }
                           confirmLabelId="a.confirm"
                           cancelLabelId="a.cancel"/>
            <ConfirmDialog open={confirmBrandChangeDialogOpen}
                           onConfirm={handleBrandChangeConfirm}
                           onCancel={() => setConfirmBrandChangeDialogOpen(false)}
                           message={
                                   <div>
                                       <div className="_formRow">
                                            <FormattedMessage id="product.bulkUpdate" values={{count: selectedProducts.length}}/>:
                                       </div>
                                       <div className="_formRow">
                                           <span className="_bold"><FormattedMessage id="b.brand"/>:</span>&nbsp;{brand2Change?.name}
                                       </div>
                                       {supplier2Change &&
                                        <div className="_formRow">
                                           <span className="_bold"><FormattedMessage id="b.supplier"/>:</span>&nbsp; {supplier2Change?.name}
                                       </div>}
                                   </div>
                           }
                           confirmLabelId="a.confirm"
                           cancelLabelId="a.cancel"/>
            <ConfirmDialog open={confirmDuplicatesDialogOpen}
                           onConfirm={handleDuplicateConfirm}
                           onCancel={() => setConfirmDuplicatesDialogOpen(false)}
                           message={
                                   <p>
                                       <FormattedMessage id="product.bulkDuplicates" values={{count: duplicates}}/>
                                   </p>
                           }
                           confirmLabelId="a.confirm"
                           cancelLabelId="a.cancel"/>
        </>
    );
}

export default ProductBulkSupplier;
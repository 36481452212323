import {FormattedMessage} from 'react-intl';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {combinedGtin} from 'shared/models/gtin.model';
import {useFormatMessage} from 'utils/translate';
import AgTable from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';


interface gtinShortList {
    gtins: combinedGtin[],
    onRowClick: (value: string) => void
}

const GtinShortList = (props: gtinShortList) => {
    const translate = useFormatMessage();
    const {gtins, onRowClick} = props;

    const colDef: ColDef[] = [
        {field: 'value', headerName: translate({ id: 'b.gtin' })},
        {field: 'active', headerName: translate({ id: 'a.active' }),
            cellRenderer: (params) => <ActiveFlagCell value={params.value} disabled={true}/>},
        {field: 'gtinFound', headerName: translate({ id: 'product.gtinVerified'}), flex: 1,
            cellRenderer: (params) => <FoundCellRenderer data={params.data}/>}
    ];

    const handleRowClick = (data: combinedGtin) => onRowClick(data.value);

    const getContextMenu = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
        if (!params.node || !params.node.data) return [];
        const copyThisGTIN: MenuItemDef = {
            name: translate({id: 'product.copyThisGtin'}),
            action: () => {
                const singleGtin: string = params.node.data.value;
                if (singleGtin) navigator.clipboard.writeText(singleGtin);
            },
            icon: '<span class="ag-icon ag-icon-copy"></span>'
        };
        const copyAllGTINs: MenuItemDef = {
            name: translate({id: 'product.copyAllGtins'}),
            action: () => {
                const allGtins: string[] = [];
                params.api.forEachNode(node => {
                    if (node.data.value) {
                        allGtins.push(node.data.value);
                    }
                });
                const allGtinsList = allGtins.join(', ');
                navigator.clipboard.writeText(allGtinsList);
            },
            icon: '<span class="ag-icon ag-icon-copy"></span>',
            disabled: gtins.length < 2
        };
        return [copyThisGTIN, copyAllGTINs];
    };

    return (
        <div className="_fullHeight _fullTable">
            <AgTable defaultColDef={{
                        sortable: true,
                        suppressMenu: true
                     }}
                     rowData={gtins}
                     columnDefs={colDef}
                     suppressContextMenu={false}
                     getContextMenuItems={getContextMenu}
                     onRowClicked={(rowData) => handleRowClick(rowData.data)}
            />
         </div>
    );
};

interface foundCellRendererProps {
    data: {
        gtinFound: boolean
    }
}

export const FoundCellRenderer = (props: foundCellRendererProps) => {
    const {gtinFound} = props.data;
    if (gtinFound === undefined) {
        return <FormattedMessage id="a.loading"/>;
    }
    return <ActiveFlagCell value={gtinFound} disabled={true}/>;
};

export default GtinShortList;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_PRODUCT_DATA_GET_DESCRIPTION, API_STATUSES} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {productDescriptionResponseModel} from 'shared/models/productDescription.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {BPCODescriptionDisplay, StatusDisplayError, StatusDisplayLoading} from './index';

interface ProductDescriptionDisplayProps {
    data?: productDescriptionResponseModel, // provide description to just display
    id?: string, // use id to make component fetch description,
    status?: API_STATUSES // optional when using 'data' property to pass status of external loader
}

const ProductDescriptionDisplay = ({data, id, status}: ProductDescriptionDisplayProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const [description, setDescription] = useState<productDescriptionResponseModel>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const cancelToken = useRef(null);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    const getProductDescription = (id: string) => {
        if (cancelToken.current) cancelToken.current();
        setIsLoading(true);
        axios.get<productDescriptionResponseModel>(API_PRODUCT_DATA_GET_DESCRIPTION(id, userProfile.langData), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setDescription(resp.data);
                    setIsError(false);
                } else {
                    enqueueSnackbar(`${translate({id: 'productDescription.err'})}`, {variant: 'error'});
                    setIsError(true);
                }
            })
            .catch((e) => {
                if(!e?.__CANCEL__) {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'productDescription.err'})}`, {variant: 'error'});
                    setIsError(true);
                }
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (id) {
            getProductDescription(id);
        } else if (data) {
            setDescription(data);
            if (cancelToken.current) cancelToken.current();
        } else {
            setDescription(null);
            if (cancelToken.current) cancelToken.current();
        }
    }, [id, data]);

    if (isLoading || status === API_STATUSES.PENDING) {
        return <StatusDisplayLoading/>;
    }

    if (isError || status === API_STATUSES.ERROR) {
        return <StatusDisplayError/>;
    }

    return (
        <>
            <span className="_bold">
                <FormattedMessage id="b.category"/>:
            </span>
            <br/>
            <span>{description?.brickHierarchy.split(' > ').reverse().join(' > ')}</span>
            {description && <BPCODescriptionDisplay main={description.mainDescriptions} alt={description.alternativeDescriptions}/>}
        </>
    );
};

export default ProductDescriptionDisplay;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {format, startOfWeek, subDays} from 'date-fns';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {dateFormat} from 'shared/models/validity.model';
import {useFormatMessage} from 'utils/translate';

export enum timeRanges {
    THIS_WEEK = 'THIS_WEEK',
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY'
}

const translationKeys = {
    THIS_WEEK: 'timeRange.currentWeek',
    TODAY: 'timeRange.today',
    YESTERDAY: 'timeRange.yesterday',
};

interface CreationDateRangeInputProps {
    data: timeRanges,
    onChange: (value: timeRanges, createdFrom: string, createdTo: string) => void
}

const today: Date = new Date();

const CreationDateRangeInput = (props: CreationDateRangeInputProps) => {
    const {data, onChange} = props;
    const translate = useFormatMessage();

    const [label, setLabel] = useState<string>('');

    useEffect(() => {
        handleChange(data);
    }, []);

    const handleChange = (value: timeRanges | string) => {
        const todayStr: string = format(new Date(), dateFormat);
        const lbl = translate({id: 'printMediaOverview.creationDate'});
        if (value === timeRanges.TODAY) {
            onChange(value, todayStr, todayStr);
            setLabel(`${lbl}: ${todayStr}`);
        } else if (value === timeRanges.YESTERDAY) {
            const ystrdyStr: string = format(subDays(today, 1), dateFormat);
            onChange(value, ystrdyStr, ystrdyStr);
            setLabel(`${lbl}: ${ystrdyStr}`);
        } else if (value === timeRanges.THIS_WEEK) {
            const startOfWkStr: string = format(startOfWeek(today), dateFormat);
            onChange(value, startOfWkStr, todayStr);
            setLabel(`${lbl}: ${startOfWkStr} - ${todayStr}`);
        }
    };

    return (
            <FormControl fullWidth>
                <InputLabel>{label}</InputLabel>
                <Select value={data || timeRanges.TODAY}
                        label={label}
                        onChange={(e) => handleChange(e.target.value)}>
                    <MenuItem value={timeRanges.TODAY}>
                        <FormattedMessage id={translationKeys[timeRanges.TODAY]}/>
                    </MenuItem>
                    <MenuItem value={timeRanges.YESTERDAY}>
                        <FormattedMessage id={translationKeys[timeRanges.YESTERDAY]}/>
                    </MenuItem>
                    <MenuItem value={timeRanges.THIS_WEEK}>
                        <FormattedMessage id={translationKeys[timeRanges.THIS_WEEK]}/>
                    </MenuItem>
                </Select>
            </FormControl>
    );
};

export default CreationDateRangeInput;
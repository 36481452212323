import {PROMOTION_TYPES} from './promotion.model';
import {characteristic} from './product.model';
import {timeRanges} from 'components/Inputs/CreationDateRangeInput';
import {reportIssuesType} from 'modules/Advertisement/FrameDescription/FrameDescriptionReportDialog';
import {mediumType} from './leaflet.model';

export type advertisementsOverviewObject = {
    absoluteDiscount: number,
    bannerName: string,
    brandName: string,
    category: string,
    characteristics: characteristic[], // array of characteristic in src/shared/models/product.model.ts, name & value like in product overview
    countryThemedWeeks: string[],
    currency: string,
    description: string,
    frameId: string,
    leafletId: string,
    mediumId: string,
    mediumType: mediumType,
    mpu: number,
    pageNumber: number,
    promotionObjectType: PROMOTION_TYPES,
    promotionTypes: string[],
    promotionalPrice: number,
    qualitySeals: string[],
    regularPrice: number,
    relativeDiscount: number,
    repBranch: string,
    retailerName: string,
    themeSeasons: string[],
}

export type advertisementsOverviewRow = Omit<advertisementsOverviewObject, 'characteristics'> & {
    characteristics: string
}

export type advertisementsOverviewResponseModel = advertisementsOverviewObject[];

export type frameFilters = {
    bannerId?: string,
    countryId?: string,
    currency?: string,
    lang?: string,
    objectId?: string,
    promotionalPrice?: number,
    search?: string,
    validFrom?: string,
    validTo?: string,
}

export type myFrameFilters = {
    bannerId?: string,
    countryId?: string,
    createdFrom?: string,
    createdTo?: string,
    currency?: string,
    lang?: string,
    creationRange?: timeRanges,
    promotionalPrice?: number,
    search?: string,
}

const reportTypeTranslations = {
    [reportIssuesType.frame]: 'nav.frame',
    [reportIssuesType.advertisement]: 'b.advertisementProposals',
    [reportIssuesType.product]: 'masterData.products',
}

export const reportTypeList = Object.keys(reportTypeTranslations).map(key => ({
    value: key,
    label: reportTypeTranslations[key],
  }));
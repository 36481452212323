import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {IRootState} from 'shared/reducers';
import {dictionaryWithElements, dictionaryElement} from 'shared/models/dictionaries.model';
import {states} from 'shared/models/states.model';
import {AttributeError, AttributeLoading} from 'components/Attribute/AttributeHelpers';
import {useIndustryQuery} from './industryAPI';

interface IndustrySelectorProps {
    value: string,
    onChange: (industryId: string) => void
    required?: boolean
}

export const getTranslation = (item: dictionaryWithElements | dictionaryElement, selectedLanguage: string): string => item.translations[selectedLanguage] || item.translations['en'];

const IndustrySelector = (props:IndustrySelectorProps) => {
    const {onChange, value, required} = props;
    const industryQuery = useIndustryQuery();
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [componentState, setComponentState] = useState<states>(states.loading);
    const [industryData, setIndustryData] = useState<dictionaryWithElements>(null);

    useEffect(() => {
        if (industryQuery.isLoading) {
            setComponentState(states.loading);
        } else if (industryQuery.isError) {
            setComponentState(states.error);
        } else if (industryQuery.isSuccess) {
            setComponentState(states.ready);
            setIndustryData(industryQuery.data);
        }
    }, [industryQuery]);

    const renderByState = (state: states): JSX.Element => {
        switch (state) {
            case states.loading:
                return <AttributeLoading/>;
            case states.error:
                return <AttributeError errorLabel="industrySelector.err"/>
            case states.ready:
                return <FormControl fullWidth required={required}>
                    <InputLabel>{getTranslation(industryData, selectedLanguage)}</InputLabel>
                    <Select color="primary"
                                   value={value}
                                   required={required}
                                   onChange={(e) => onChange(e.target.value)}
                                   label={getTranslation(industryData, selectedLanguage)}
                    >
                        {industryData.elements.map((item) => <MenuItem key={item.id} value={item.id}>{getTranslation(item, selectedLanguage)}</MenuItem>)}
                    </Select>
                </FormControl>
            default:
                return <AttributeError errorLabel="industrySelector.err"/>
        }
    };

    return renderByState(componentState);
};

export default IndustrySelector;
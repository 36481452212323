import 'components/Selectors/CountrySelector/CountrySelector.scss';
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect} from 'react';
import {useSelector} from "react-redux";
import {bannerWithRetailer, countryAndBanner} from 'shared/models/banner.model';
import {IRootState} from 'shared/reducers';
import {isBannerInCountry} from 'shared/reducers/bannersWithRetailer';
import BannerSelectorConnected from 'components/Selectors/BannerSelector/BannerSelectorConnected';
import CountrySelectorConnected from 'components/Selectors/CountrySelector/CountrySelectorConnected';

interface CountryBannerSelectorConnectedProps {
    bannerId: string;
    onCountryBannerChange: (countryBanner: countryAndBanner) => void;
    className?: string,
}

const CountryBannerSelectorConnected = ({bannerId, className, onCountryBannerChange}: CountryBannerSelectorConnectedProps) => {
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);

    const handleBannerChange = (banner: bannerWithRetailer) => {
        if (banner && banner.bannerId !== bannerId) {
            onCountryBannerChange({country: countryMarket.preferredCountry, banner: banner.bannerId});
        } else if (banner === null && bannerId) {
            onCountryBannerChange({country: countryMarket.preferredCountry, banner: null});
        }
    };

    useEffect(() => {
        if (bannerId && isBannerInCountry(bannerId, countryMarket.preferredCountry, bannersData)) {
            onCountryBannerChange({country: countryMarket.preferredCountry, banner: bannerId});
        } else {
            onCountryBannerChange({country: countryMarket.preferredCountry, banner: null});
        }
    }, [countryMarket]);

    return (
        <div className={`${className ? className + ' ' : ''}countryBannerSelectorRoot`}>
            <CountrySelectorConnected compact/>
            <div style={{width: "100%"}}>
                <BannerSelectorConnected bannerId={bannerId}
                                         onBannerChange={(banner) => handleBannerChange(banner)}
                />
            </div>
        </div>
    );
};

export default CountryBannerSelectorConnected;
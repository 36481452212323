import axios from 'axios';
import {API_DICTIONARY_BY_ID} from 'config/api/constants';
import {useQuery} from 'react-query';
import {typeOfBranchDictionaryId} from 'shared/handyDictionaryEntries';
import {dictionaryWithElements} from 'shared/models/dictionaries.model';

const fetchTypeOfBranch = async (): Promise<dictionaryWithElements> => {
    const response = await axios.get(API_DICTIONARY_BY_ID(typeOfBranchDictionaryId));
    return response.data
};

export const useTypeOfBranchQuery = () => useQuery([typeOfBranchDictionaryId], () => fetchTypeOfBranch(), {staleTime: Infinity});
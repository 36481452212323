import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from '@mui/material';
import {API_REPORT_PAGE} from 'config/api/constants';
import {reportPagePayload} from 'shared/models/page.model';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface ReportPageProps {
    leafletId: string,
    pageNumber: number
}

const ReportPage = ({leafletId, pageNumber}: ReportPageProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const canSubmit = (): boolean => comment.length > 5 && leafletId && pageNumber > 0;

    const handleSubmit = () => {
        setIsLoading(true);
        const payload: reportPagePayload = {comment};
        axios.put(API_REPORT_PAGE(leafletId, pageNumber), payload)
            .then(() => {
                enqueueSnackbar(`${translate({id: 'leafletBrowser.reported'})}`, {variant: 'success', persist: false});
                handleClose();
            })
            .catch((e) => {
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                console.log(e);
            })
            .finally(() => setIsLoading(false));
    }

    const handleClose = () => {
        setOpen(false);
        setComment('');
    }

    return <>
        <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
            <FormattedMessage id="leafletBrowser.report"/>
        </Button>
        <LoadingOverlay show={isLoading}/>
        <Dialog open={open} maxWidth={'md'} fullWidth>
            <DialogTitle>
                <FormattedMessage id="leafletBrowser.report"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <TextField autoFocus
                           fullWidth
                           label={translate({id: 'a.reason'})}
                           minRows={4}
                           multiline
                           onChange={(e) => setComment(e.target.value)}
                           value={comment}
                />
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button onClick={handleSubmit} variant={"contained"} disabled={!canSubmit()}>
                    <FormattedMessage id="a.submit"/>
                </Button>
                <ButtonClose onClick={handleClose} />
            </DialogActions>
        </Dialog>
    </>;
}

export default ReportPage;
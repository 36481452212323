import './ProductIssuesDisplay.scss';

import {FormattedMessage} from 'react-intl';
import WarningIcon from '@mui/icons-material/Warning';
import {Button} from '@mui/material';
import { ProductComment } from 'shared/models/product.model';

interface ProductIssuesDisplayProps {
    comments: ProductComment[],
    onResolveClick?: () => void
}

const ProductIssuesDisplay = ({comments, onResolveClick}: ProductIssuesDisplayProps) => {
    return comments?.length ? (
        <div className="productIssuesDisplayRoot">
            <div className="title">
                <WarningIcon/><FormattedMessage id="product.hasIssues"/>
            </div>
            <ul>
                {comments.map((comment, idx) => (
                    <li key={idx}>
                        {comment.text}<br />
                        {comment.author ? <><FormattedMessage id="b.reportedBy" />: {comment.author}</> : null}
                    </li>
                ))}
            </ul>
            {onResolveClick &&
                <Button size="small" variant="outlined" onClick={onResolveClick}>
                    <FormattedMessage id="product.fixReported"/>
                </Button>
            }
        </div>
    ) : null;
};

export default ProductIssuesDisplay;
import './AttributesDisplay.scss';
import {characteristicUI} from 'shared/models/product.model';

interface AttributesDisplayProps {
    data: characteristicUI[]
}
const AttributesDisplay = ({data}: AttributesDisplayProps) =>
        <div className="attributesDisplayRoot">
            {data.map((item, idx) => <span title={item.name} key={idx}>{item.value}</span>)}
        </div>;

export default AttributesDisplay;
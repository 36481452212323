import './LoadingOverlay.scss';

import {CircularProgress} from '@mui/material';

type LoadingOverlayProps = {
  show: boolean,
  fullScreen?: boolean
}

export const LoadingOverlay = ({show, fullScreen}: LoadingOverlayProps) => {
  if (show) {
    return (
      <div className={fullScreen ? 'loadingOverlayContainer fullScreen' : 'loadingOverlayContainer'}>
        <CircularProgress/>
      </div>
    );
  }
  return null;
};
import './Tooltips.scss';

import {ITooltipParams} from 'ag-grid-community';
import {handyAttributes} from 'shared/handyAttributes';

export const MainAttributesTooltip = (props: ITooltipParams) =>
    <div className="productSearchResultsTooltipRoot">
        <ul>
            {props.value
                .filter((item) => item.id !== handyAttributes.description)
                .map((item) => <li key={item.id}><span className="attrName">{item.name}:</span>{item.value}</li>)
            }
        </ul>
    </div>;

export const MainAttributesMUITooltipContent = (props) =>
    <ul className="productSearchResultsMUITooltipContentRoot">
        {props.value
            .filter((item) => item.id !== handyAttributes.description)
            .map((item) => <li key={item.id}><span className="attrName">{item.name}:</span>{item.value}</li>)
        }
    </ul>;

export const CategoryTooltip = (props: ITooltipParams) =>
    <div className="productSearchResultsTooltipRoot">
        <span className="categoryName">{props.value}</span>
    </div>;
import {
    brickPayloadModel,
    brickUIModel,
    categoryBEModel,
    categoryOrder,
    categorySearchResponseObject,
    categorySelectorOption,
    categoryTableItem,
    categoryTableItemWithSearchString,
    categoryTreeItem,
    categoryType,
    categoryUIModel
} from 'shared/models/category.model';
import {array2dictionary, dictionary2arrayX} from 'modules/Dictionaries/Literals/Literal';

type intermediateForm = categoryTableItem & {items?:categoryTreeItem[]};

export const tree2table = (categoryTree:categoryTreeItem[]) :categoryTableItem[] => {
    const segments: intermediateForm[] = categoryTree.map((segment) => {
            const row: intermediateForm = {sName: segment.name, sId: segment.id};
            if (segment.items) {
                row.items = segment.items;
            }
            return row;
        }
    );
    // @TODO could make it cleaner...
    let families: intermediateForm[] = [];
    segments.forEach((segment) => {
        families = families.concat(segment.items.map((family) => {
            const row: intermediateForm = {...segment, fName: family.name, fId: family.id};
            if (family.items) {
                row.items = family.items;
            }
            return row;
        }));
    });
    segments.forEach((segment) => {
        if (segment.items.length === 0) {
            families.push(segment);
        }
    })

    let classes: intermediateForm[] = [];
    families.forEach((family) => {
        classes = classes.concat(family.items.map((categoryClass) => {
            const row: intermediateForm = {...family, cName: categoryClass.name, cId: categoryClass.id};
            if (categoryClass.items) {
                row.items = categoryClass.items;
            }
            return row;
        }));
    });
    families.forEach((family) => {
        if (family.items.length === 0) {
            classes.push(family);
        }
    })

    let bricks: intermediateForm[] = [];
    classes.forEach((categoryClass) => {
        bricks = bricks.concat(categoryClass.items.map((brick) => {
            const row: intermediateForm = {...categoryClass, bName: brick.name, bId: brick.id};
            return row;
        }));
    });
    classes.forEach((categoryClass) => {
        if (categoryClass.items.length === 0) {
            bricks.push(categoryClass);
        }
    })
    return bricks;
}

// joining and lowercasing 4 fields to single string, separate them by '_' to avoid misleading matches when end of field A and start of field B match search phrase
export const addSearchString = (categoryTable: categoryTableItem[]): categoryTableItemWithSearchString[] => categoryTable
    .map((item) => ({...item, searchStr: `${item.sName?.toLowerCase()}_${item.fName?.toLowerCase()}_${item.cName?.toLowerCase()}_${item.bName?.toLowerCase()}`}));

export const getParent4type = (type: categoryType): categoryType => categoryOrder[categoryOrder.findIndex((item) => item === type) - 1];

export const transform4UI = (category: categoryBEModel):categoryUIModel => {
    const {active, alternativeCategoryId, id, parentCategoryId, type, parentCategoryName, alternativeCategoryName} = category;
    const uiModel:categoryUIModel = {
        active,
        id,
        translations: dictionary2arrayX(category.translations),
        type
    }
    if (type !== categoryType.SEGMENT) {
        uiModel.parentCategory = {
            id: parentCategoryId,
            name: parentCategoryName,
            type: getParent4type(type)
        };
    }
    if (alternativeCategoryId) {
        uiModel.alternativeCategory = {
            id: alternativeCategoryId,
            name: alternativeCategoryName,
            type: type
        };
    }
    return uiModel;
}

export const transform4BE = (category: categoryUIModel):categoryBEModel => {
    const { active, translations, type, parentCategory, alternativeCategory } = category;
    const beModel:categoryBEModel = {
        active,
        translations: array2dictionary(translations.filter((item) => item.trans)), //filtering out unfilled translations
        type
    }
    if (parentCategory && parentCategory.id) {
        beModel.parentCategoryId = parentCategory.id;
    }
    if (alternativeCategory && alternativeCategory.id) {
        beModel.alternativeCategoryId = alternativeCategory.id;
    }
    return beModel;
}

export const buildCategoryUItemplate = (type:categoryType):categoryUIModel => {
    const template:categoryUIModel = {
        active: true,
        translations: dictionary2arrayX({en: ''}),
        type: type,
        alternativeCategory: {
            id: '',
            name: '',
            type: type
        }
    };
    if (type !== categoryType.SEGMENT) {
        template.parentCategory = {
            id: '',
            name: '',
            type: getParent4type(type)
        }
    }
    return  template;
}

export const buildBrickUITemplate = (parentCategory?: categorySelectorOption): brickUIModel => {
    const blankParentCategory: categorySelectorOption = {
        id: '',
        name: '',
        type: getParent4type(categoryType.BRICK)
    };
    return {
            active: true,
            translations: dictionary2arrayX({en: ''}),
            type: categoryType.BRICK,
            alternativeCategory: {
                id: '',
                name: '',
                type: categoryType.BRICK
            },
            parentCategory: parentCategory || blankParentCategory,
            taxes: []
        };
}

export const buildBrickPayload = (brick: brickUIModel): brickPayloadModel => (
    {
        ...transform4BE(brick),
        taxes: brick.taxes.map(
            (item) => item.id
        )
    }
);

export const transformElasticSearch4UI = (data: categorySearchResponseObject[]): categoryTableItem[]  =>
    data.map((item) => ({
        sName: item.segmentName,
        sId: item.segmentId,
        fName: item.familyName,
        fId: item.familyId,
        cName: item.className,
        cId: item.classId,
        bName: item.brickName,
        bId: item.brickId,
    }));
import {useDispatch} from 'react-redux';
import {
    setUserLanguageData,
    setLanguageUserInterface,
    restoreCountryMarket
} from 'shared/reducers/userProfileActions';
import {
    LOCAL_STORAGE_KEY_LANG_DATA,
    LOCAL_STORAGE_KEY_LANG_USER_INTERFACE,
    LOCAL_STORAGE_KEY_COUNTRY_MARKET,
    countryMarketItem
} from 'shared/reducers/userProfile';
import {getFromLocalStorage, getObjectFromLocalStorage} from './storageUtils';

const LoadUserProfile = () => {
    const dispatch = useDispatch();
    // load data lang
    const langData = getFromLocalStorage(LOCAL_STORAGE_KEY_LANG_DATA);
    if (langData) dispatch(setUserLanguageData(langData));
    // load user interface lang
    const langUserInterface = getFromLocalStorage(LOCAL_STORAGE_KEY_LANG_USER_INTERFACE);
    if (langUserInterface) dispatch(setLanguageUserInterface(langUserInterface));
    // load country and market
    const countryMarket: countryMarketItem = getObjectFromLocalStorage(LOCAL_STORAGE_KEY_COUNTRY_MARKET);
    if (countryMarket) dispatch(restoreCountryMarket(countryMarket));

    return null;
};

export default LoadUserProfile;
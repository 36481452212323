import {markets} from 'shared/reducers/countryMarketConst';
import {useFormatMessage} from 'utils/translate';

interface MarketDisplayProps {
    marketId: string
}

const MarketDisplay = ({marketId}: MarketDisplayProps) => {
    const translate = useFormatMessage();
    return <span><span className="_bold">{translate({id: 'b.market'})}:</span> {markets.find((item) => item.id === marketId)?.name || marketId}</span>;
};

export default MarketDisplay;
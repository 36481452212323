import {Dialog} from "@mui/material";

interface FrameDialogZoomProps {
    imgUrl: string,
    onClose: () => void
    show: boolean,
}

const FrameDialogZoom = (props: FrameDialogZoomProps) => {
    const {imgUrl, onClose, show} = props;
    return show ? (
        <Dialog open={show} onClose={onClose} onClick={onClose}>
            <img src={imgUrl} alt=""/>
        </Dialog>
    ) : null;
}

export default FrameDialogZoom;
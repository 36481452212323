import './AdvertisingAreasIndex.scss';

import {FormattedMessage} from 'react-intl';
import {Divider} from '@mui/material';
import {PaperX} from 'components/PaperX';
import {paths} from 'paths';
import {ActionListCard} from 'components/Cards/ActionListCard';
import {advertisingAreaUIModel} from 'shared/models/advertisingArea.model';

interface advertisingAreasIndexProps {
    areas: advertisingAreaUIModel[],
    selectedArea: advertisingAreaUIModel,
    onAreaClick: (advertisingArea: advertisingAreaUIModel) => void,
    onCheckboxClick?: (advertisingArea: advertisingAreaUIModel) => void
    canEdit?: boolean,
    canSelect?: boolean
}

const AdvertisingAreasIndex = (props:advertisingAreasIndexProps) => {
    const {areas, selectedArea, onAreaClick, onCheckboxClick, canEdit, canSelect} = props;
    const buildAdvertisingAreasCardsList = () => areas
        .map((item) =>
            <ActionListCard key={item.id}
                            selectedId={selectedArea ? selectedArea.id : ''}
                            id={item.id} item={item}
                            label={item.name}
                            onCardClick={(area) => onAreaClick(area)}
                            onCheckboxSelect={(value) => onCheckboxClick(value)}
                            path={paths.advertisingArea}
                            canEdit={canEdit}
                            canSelect={canSelect}
            />);

    return (
        <PaperX className="advAreasIndexRoot">
            <div className="_header"><FormattedMessage id="b.advertisingAreas"/></div>
            <Divider/>
            <div className="advAreasListContainer">
                <ul>
                    {buildAdvertisingAreasCardsList()}
                </ul>
            </div>
        </PaperX>
    );
}

export default AdvertisingAreasIndex;
import axios from 'axios';
import {API_MASTER_DATA_RETAILERS, API_STATUSES} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {dictRetailersResponseModel, dictRetailers} from 'shared/models/dict.model';

enum ACTION_TYPES {
    START_FETCHING_RETAILERS = 'START_FETCHING_RETAILERS',
    ON_SUCCESS_RETAILERS = 'ON_SUCCESS_RETAILERS',
    ON_ERROR_RETAILERS = 'ON_ERROR_RETAILERS'
}

const sortResponse = (response: dictRetailersResponseModel): dictRetailersResponseModel =>
    response.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);

export function fetchRetailers () {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_RETAILERS
        });
        axios.get(API_MASTER_DATA_RETAILERS).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_RETAILERS,
                    payload: {data: sortResponse(resp.data)}
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_RETAILERS});
            }
        }).catch((e) => {
            console.error(e);
            dispatch({type: ACTION_TYPES.ON_ERROR_RETAILERS})
        })
    }
}

const initialState: dictRetailers = {
    status: API_STATUSES.NEW,
    data: []
};

export type RetailersState = Readonly<typeof initialState>;

const retailersReducer = (state: RetailersState = initialState, action): RetailersState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_RETAILERS:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_RETAILERS:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_RETAILERS:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default retailersReducer;
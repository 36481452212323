/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';
import {IRootState} from 'shared/reducers';
import {countries} from 'shared/reducers/countryMarketConst';

interface CountrySelectionDialogProps {
    onClose: () => void,
    onExport: (countriesList: string[]) => void,
    open: boolean
}

const CountrySelectionDialog = ({onClose, onExport, open}: CountrySelectionDialogProps) => {
    const [countriesList, setCountriesList] = useState<string[]>([]);
    const defaultCountry = useSelector((state: IRootState) => state.userProfile.countryMarket.preferredCountry);

    useEffect(() => {
        if (open) {
            setCountriesList([defaultCountry]);
        }
    }, [open]);

    const handleCountryToggle = (countryId: string) => {
        const newCountriesList = [...countriesList];
        if (countriesList.includes(countryId)) {
            setCountriesList(newCountriesList.filter((item) => item !== countryId));
        } else {
            newCountriesList.push(countryId);
            setCountriesList(newCountriesList);
        }
    };

    const buildCountriesList = () => countries.map((country) => (
        <li>
            <Checkbox checked={countriesList.includes(country.id)}
                      color="primary"
                      onChange={() => handleCountryToggle(country.id)}/>
            {country.name}
        </li>
    ));

    const handleSelectAllClick = () => setCountriesList(countries.map((country) => country.id));

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage id="statistics.selectCountries"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Button size="small" onClick={handleSelectAllClick}><FormattedMessage id="a.selectAll"/></Button>
                <ul>
                    {buildCountriesList()}
                </ul>
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={() => onExport(countriesList)} disabled={countriesList.length === 0}>
                    <FormattedMessage id="a.exportExcel"/>
                </Button>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.close"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default CountrySelectionDialog;
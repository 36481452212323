/* eslint-disable react-hooks/exhaustive-deps */
import './RadioSpotOverviewFilters.scss';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {format, parseISO} from 'date-fns';
import {Checkbox, FormControl, InputLabel, ListItemIcon, MenuItem, Select} from '@mui/material';
import {spotFilters, radioSpotStatus} from 'shared/models/media.model';
import {IRootState} from 'shared/reducers';
import {countryAndBanner} from 'shared/models/banner.model';
import {dateFormat} from 'shared/models/validity.model';
import useDebounce from 'utils/debounce';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {radioSpotStatusLabels} from './RadioSpotOverviewActions';
import {tryDate2String} from 'components/OverviewFilters/OverviewFilters';
import {ValiditySelector} from 'components/Selectors';
import {useFormatMessage} from 'utils/translate';
import CountryBannerSelectorConnected from 'components/Selectors/CountryBannerSelector/CountryBannerSelectorConnected';

export interface RadioSpotOverviewFiltersProps {
    onFilterChange: (filters: spotFilters) => void,
    view: string
}

const sanitizeSpotFilters = (filters: spotFilters): spotFilters => {
    const saneFilters: spotFilters = {};
    if (filters.countryId) saneFilters.countryId = filters.countryId;
    // if (filters.language) saneFilters.language = filters.language;
    if (filters.bannerId) saneFilters.bannerId = filters.bannerId;
    if (filters.validFrom) saneFilters.validFrom = filters.validFrom;
    if (filters.validTo) saneFilters.validTo = filters.validTo;
    if (filters.status) saneFilters.status = filters.status;
    return Object.keys(saneFilters).length === 0 ? null : saneFilters;
}

export const status: string[] = [
    'REGISTERED',
    'PENDING',
    'VERIFIED',
    'DONE'
]

const spotsOverviewFiltersStorageKey = 'filters_spotsOverviewFilters_v2';
const advertisementSpotsOverviewFiltersStorageKey = 'filters_advertisementSpotsOverviewFilters_v2';

// const buildFiltersTemplate= (country: string, lang: string): spotFilters => { // @TODO: need proper language selector
const buildFiltersTemplate = (country: string): spotFilters => {
    return {
        // language: lang, // @TODO: need proper language selector
        countryId: country,
        bannerId: '',
        status: [],
        validFrom: null,
        validTo: null,
    }
};

export const getRadioSpotOverviewFilters = (): spotFilters => JSON.parse(getFromLocalStorage(spotsOverviewFiltersStorageKey));

const RadioSpotOverviewFilters = (props: RadioSpotOverviewFiltersProps) => {
    const {onFilterChange} = props;
    const translate = useFormatMessage();
    const storageKey: string = props.view === 'spot' ? spotsOverviewFiltersStorageKey : advertisementSpotsOverviewFiltersStorageKey;

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [filters, setFilters] = useState<spotFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then

    const handleCountryBannerChange = (countryBanner: countryAndBanner) => setFilters({
        ...filters,
        bannerId: countryBanner.banner || '',
        countryId: countryBanner.country
    });

    const handleDateFromChange = (validFrom: Date) => {
        const newDate: string = tryDate2String(validFrom);
        setFilters({...filters, validFrom: newDate ? format(validFrom, dateFormat) : ''});
    };

    const handleDateToChange = (validTo: Date) => {
        const newDate: string = tryDate2String(validTo);
        setFilters({...filters, validTo: newDate ? format(validTo, dateFormat) : ''});
    };

    useEffect(() => {
        const storedFilters: spotFilters = JSON.parse(getFromLocalStorage(storageKey));
        if (storedFilters) {
            // storedFilters.lang = userProfile.langData;  // @TODO: need proper language selector
            storedFilters.countryId = userProfile.countryMarket.preferredCountry;
            setFilters(storedFilters);
        } else {
            // setFilters(buildFiltersTemplate(userProfile.preferredCountry, userProfile.langData)); // @TODO: need proper language selector
            setFilters(buildFiltersTemplate(userProfile.countryMarket.preferredCountry));
        }
    }, []);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);
    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeSpotFilters(dbncdFilters);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
                storeInLocalStorage(storageKey, stringifiedSaneFilters);
            }
        }
    }, [dbncdFilters]);

    const handleStatusChange = (e) => {
        const selectedStatus: radioSpotStatus = e.target.value;
        setFilters((prevFilters) => {
            let updatedStatus: radioSpotStatus[];
            if (Array.isArray(selectedStatus)) {
                updatedStatus = selectedStatus;
            } 
            return { ...prevFilters, status: updatedStatus };
        });
    };

    const getTranslatedStatuses = (filterStatus: string[]): string => {
        const translatedStatus: string[] = [];
        for (const item in filterStatus) {
            translatedStatus.push(translate({id: radioSpotStatusLabels[filters.status[item]]}));
        }
        return translatedStatus.join(', ');
      }

    return !filters ? null :
        <div className="spotsOverviewFiltersRoot">
            <FormControl className="statusFilterSelector">
                <InputLabel>{translate({id: 'media.radioSpotFilterStatus'})}</InputLabel>
                <Select
                    label={translate({id: 'media.radioSpotFilterStatus'})}
                    multiple
                    value={filters.status || []}
                    onChange={handleStatusChange}
                    renderValue={(filterStatus) => getTranslatedStatuses(filterStatus)}
                >
                    {status.map((item) => (
                        <MenuItem key={item} value={item}>
                            <ListItemIcon>
                                <Checkbox checked={filters?.status?.includes(item as radioSpotStatus)}/>
                            </ListItemIcon>
                            {translate({id: radioSpotStatusLabels[item]})}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div>
                <CountryBannerSelectorConnected bannerId={filters.bannerId}
                                                onCountryBannerChange={(countryBanner) => handleCountryBannerChange(countryBanner)}
                />
            </div>
            <div>
                <ValiditySelector id={1}
                                  labelFrom={translate({id: 'b.validFrom'})}
                                  labelTo={translate({id: 'b.validTo'})}
                                  validityFrom={filters.validFrom ? parseISO(filters.validFrom) : null}
                                  validityTo={filters.validTo ? parseISO(filters.validTo) : null}
                                  onValidityFromChange={(id, date) => handleDateFromChange(date)}
                                  onValidityToChange={(id, date) => handleDateToChange(date)}/>
            </div>
        </div>;
}

export default RadioSpotOverviewFilters;
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { API_STATUSES } from 'config/api/constants';
import {keyAccountObject} from 'shared/models/keyAccount.model';
import { IRootState } from 'shared/reducers';
import {fetchKeyHierarchy} from 'shared/reducers/keyHierarchy';
import { useFormatMessage } from 'utils/translate';

interface KeyAccountSelectorProps {
    keyAccountId?: string,
    onKeyAccountChange?: (keyAccount: keyAccountObject) => void,
    required?: boolean
}

const KeyAccountSelector = ({ keyAccountId, onKeyAccountChange, required }: KeyAccountSelectorProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const hierarchyStatus = useSelector((state: IRootState) => state.keyHierarchy.status);
    const hierarchyData = useSelector((state: IRootState) => state.keyHierarchy.data);

    const [selectedKeyAccount, setSelectedKeyAccount] = useState<keyAccountObject>(null);

    useEffect(() => {
        if (hierarchyStatus === API_STATUSES.ERROR) {
            dispatch(fetchKeyHierarchy());
        }
    }, []);

    useEffect(() => {
        if (hierarchyStatus === API_STATUSES.NEW) {
            dispatch(fetchKeyHierarchy());
        }
    }, [hierarchyStatus]);

    useEffect(() => {
        const foundKeyAcc: keyAccountObject = hierarchyData.find((item) => item.id === keyAccountId);
        if (foundKeyAcc) {
            setSelectedKeyAccount(foundKeyAcc);
        }
    },[hierarchyData, keyAccountId]);

    const filterKeyAccounts = (options: keyAccountObject[], { inputValue }): keyAccountObject[] => {
        const loCased: string = inputValue ? inputValue.toLowerCase() : '';
        return options.filter((item) => item.name.toLowerCase().indexOf(loCased) > -1);
    };

    const handleAutocompleteChange = (value: keyAccountObject | string) => {
        if (typeof value !== 'string') {
            setSelectedKeyAccount(value);
            onKeyAccountChange(value);
        }
    };

    return (
        <Autocomplete
            options={hierarchyData}
            filterOptions={filterKeyAccounts}
            onChange={(e, value) => handleAutocompleteChange(value)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => <li {...props} className={selectedKeyAccount && option.id === selectedKeyAccount.id ? "MPMSelectorOption selected" : "MPMSelectorOption"} key={option['id']}><span className="retailerName">{option['name']}</span></li>}
            value={selectedKeyAccount}
            defaultValue={hierarchyData.find((item) => item.id === keyAccountId)}
            renderInput={(params) => <TextField {...params} required={required} label={translate({id: 'b.keyAccount'})} />}
        />
    )
};

export default KeyAccountSelector;
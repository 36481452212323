import './LeafletProgressDialog.scss';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFormatMessage } from "utils/translate";
import { paths } from 'paths';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import ButtonClose from 'components/Buttons/ButtonClose';
import LeafletProgress from './LeafletProgress';
import {reportIssuesType} from 'modules/Advertisement/FrameDescription/FrameDescriptionReportDialog';

interface leafletProgressDialogProps {
    open: boolean,
    leafletId: string,
    leafletFileName: string,
    onClose?: () => void
}

const LeafletProgressDialog = (props:leafletProgressDialogProps) => {
    const translate = useFormatMessage();
    const history = useHistory();
    const {onClose, open, leafletId, leafletFileName} = props;
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const handleReportedFramesChange = (value: boolean) => setIsDisabled(value);

    const goToReportedIssues = () => history.push(`${paths.reportedFramesBrowser}?id=${leafletId}&from=${paths.printMediaOverview}&reportType=${reportIssuesType.frame}`);

    return (
        <Dialog className="leafletProgressDialogRoot" open={open} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <FormattedMessage id="a.progress"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <LeafletProgress
                    leafletId={leafletId}
                    leafletFileName={leafletFileName}
                    onReportedFramesChange={handleReportedFramesChange}
                    isDialogMode
                />
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={goToReportedIssues} disabled={!isDisabled}>{translate({id: 'nav.qualityBrowse'})}</Button>
                <ButtonClose onClick={onClose} />
            </DialogActions>
        </Dialog>
    );
};

export default LeafletProgressDialog;
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Checkbox} from '@mui/material';
import {paths} from 'paths';
import {categoryBrick} from 'shared/models/categoryBrick.model';
import {IRootState} from 'shared/reducers';
import {categorySelectorOption, categoryType} from 'shared/models/category.model';
import {getId} from 'utils/routing';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import SummaryCard from 'components/Cards/SummaryCard';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {CategorySearch} from 'modules/MasterData/ProductCategories/CategorySearch';
import {useBrickQuery} from './categoryAPI';

const Category = () => {
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    // const [selectedCategory, setSelectedCategory] = useState<categorySelectorOption>({id: '', name:'', type: categoryType.BRICK});
    const [selectedCategory, setSelectedCategory] = useState<categorySelectorOption>(null);
    const [categoryId, setCategoryId] = useState<string>('');
    const [cardContent, setCardContent] = useState(null);

    const categoryQuery = useBrickQuery(categoryId, selectedLanguage, false);

    useEffect(() => {
        const id = getId();
        if (id) {
            setCategoryId(id);
        }
    }, []);

    useEffect(() => {
        if (selectedCategory !== null) {
            setSelectedCategory(null);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (categoryQuery.data) {
            buildSummaryCard(categoryQuery.data);
        } else {
            setCardContent(null);
        }
    }, [categoryQuery.data]);

    const handleCategorySelect = (option: categorySelectorOption) => {
        setCategoryId(option ? option.id : '');
        setSelectedCategory(option);
    };

    const buildSummaryCard = (category: categoryBrick) => {
        const content = [
            {label: 'b.name', value: category.name},
            {label: 'a.active', value: <Checkbox checked={category.active} disabled/>, optionalClass: 'checkbox'},
            // {label: 'attributes.label', value: category.attributes.map((attribute) => attribute.name).join(', ')}
            {label: 'attributes.label', value: category.activeAssignedAttributes.map((attribute) => attribute.name).join(', ')}
        ];
        setCardContent(content);
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={categoryQuery.isFetching}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div>
                            <CategorySearch onCategorySelect={handleCategorySelect}
                                            value={selectedCategory}
                                            categoryType={categoryType.BRICK}/>
                        </div>
                    </PaperX>
                    {cardContent && categoryQuery.isFetched &&
                        <Link to={`${paths.categoriesAttributes}?id=${categoryId}`}  className="_halfWidth">
                            <div>
                                {/*@TODO in the future there will be no clickable card, but two buttons: one to edit attributes, other to edit hierarchy*/}
                                <SummaryCard content={cardContent}/>
                            </div>
                        </Link>
                    }
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
}

export default Category;
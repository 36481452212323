import './GtinsDetailsDialog.scss';
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Dialog, DialogActions, DialogContent} from '@mui/material';
import {useSnackbar} from 'notistack';
import {productInfoObject} from './Gtins';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {
    externalGtin,
    externalGtinDataPayloadModel,
    externalGtinDataResponseModel,
    gtinObject
} from 'shared/models/gtin.model';
import {IRootState} from 'shared/reducers';
import {API_FETCH_EXTERNAL_GTIN_DATA} from 'config/api/constants';
import useDebounce from 'utils/debounce';
import {checkGtin} from 'utils/gtinsValidationUtils';
import {useFormatMessage} from 'utils/translate';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {FoundCellRenderer} from './GtinShortList';
import GtinInput from './GtinInput';
import ProductInfo from './ProductInfo';
import {getGtinsDialogClassName} from './GtinsDetailsDialog';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';

interface addGtinDialogProps {
    existingGtins: gtinObject[],
    isDescribingFrame?: boolean,
    onAdd: (gtin: string, gtins: externalGtin[]) => void,
    onClose: Function,
    open: boolean,
    productInfo: productInfoObject,
}

const AddGtinDialog = (props: addGtinDialogProps) => {
    const {existingGtins, isDescribingFrame, onAdd, onClose, open, productInfo} = props;
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const selectedDataLang = useSelector((state: IRootState) => state.userProfile.langData);

    const [gtin, setGtin] = useState<string>('');
    const [dbncdGtin, setDbncdGtin] = useDebounce(gtin, 500);

    const [gtins, setGtins] = useState<externalGtinDataResponseModel>([]);

    const [isLoading, setIsloading] = useState<boolean>(false);

    const cancelToken = useRef(null);

    const fetchExternalGtinData = (gtin2find: string) => {
        const payload: externalGtinDataPayloadModel = [gtin2find];
        setIsloading(true);
        if (cancelToken.current) {
            cancelToken.current();
        }
        setGtins([]);
        axios.post(API_FETCH_EXTERNAL_GTIN_DATA(countryMarket.preferredCountry, selectedDataLang), payload, {cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                const externalGtins: externalGtinDataResponseModel = response.data;
                setGtins(externalGtins);
                setIsloading(false);
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e)
                    enqueueSnackbar(`${translate({id: 'product.gtinFetchErr'})}`, {variant: 'error', persist: false});
                    setGtins([]);
                    setIsloading(false);
                }
            });
    }

    useEffect(() => {
        return () => {
            if(cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if (checkGtin(gtin) && !gtinAlreadySelected(gtin, existingGtins)) {
            fetchExternalGtinData(dbncdGtin);
        }
        setGtins([]);
    }, [dbncdGtin]);

    const colDef: ColDef[] = [
        {field: 'value', headerName: translate({ id: 'b.gtin' }), sort: 'asc'},
        {field: 'brand', headerName: translate({ id: 'b.brand' })},
        {field: 'description', headerName: translate({ id: 'b.descr' }), flex: 1},
        {field: 'supplier', headerName: translate({ id: 'b.supplier' })},
        {field: 'capacity', headerName: translate({ id: 'b.capacity'})},
        {field: 'gtinFound', headerName: translate({ id: 'product.gtinVerified'}),
            cellRenderer: (params) => <><FoundCellRenderer data={params.data}/>{params.data.verifiedBy}</>}
    ];

    const handleGtinValueChange = (key: number, value: string) => {
        setGtin(value);
        setDbncdGtin(value);
    };

    const handleAddClick = () => {
        const gtin2Add: string = gtins[0].value;
        if (gtinAlreadySelected(gtin2Add, existingGtins)) {
            enqueueSnackbar(`${translate({id: 'product.gtinExists'})}`, {variant: 'error', persist: false});
        } else {
            onAdd(gtin2Add, gtins); // not using typed-in gtin but gtin from the response as it always contain leading zeros
            setGtin('');
            setDbncdGtin('');
        }
    };

    const handleCloseClick = () => {
        onClose();
        setGtin('');
        setDbncdGtin('');
    };

    return (
    <Dialog className={getGtinsDialogClassName(isDescribingFrame)} open={open} fullScreen>
        <LoadingOverlay show={isLoading}/>
        <DialogContent className="_directionCol">
            <div className="_halfWidth">
                <ProductInfo productInfo={productInfo}/>
            </div>
            <PaperX className="weldBottom">
                <GtinInput value={gtin}
                           onChange={(index: number, value: string) => handleGtinValueChange(index, value)}
                           propIdentifier={0}
                           error={!checkGtin(gtin)}/>
            </PaperX>
            <PaperX className="_fullHeight _fullTable _scrollY weldTop">
                <AgTable
                    defaultColDef={{
                        resizable: true,
                        sortable: true
                    }}
                    rowData={gtins}
                    columnDefs={colDef}
                    localStorageColumnsKey={localStorageColumnsKeys.gtinDetails2}
                />
            </PaperX>
        </DialogContent>
        <DialogActions>
            {!isDescribingFrame && (
                <DialogAuxiliaryActions>
                    <NavigationMenuButton/>
                </DialogAuxiliaryActions>
            )}
            {gtinAlreadySelected(gtin, existingGtins) && <span className="gtinAlreadyUsedErr"><FormattedMessage id="product.gtinExists"/></span>}
            <Button onClick={handleAddClick} variant="contained" disabled={!checkGtin(gtin) || gtinAlreadySelected(gtin, existingGtins) || gtins.length === 0}>
                <FormattedMessage id="product.addGtin"/>
            </Button>
            <ButtonClose onClick={() => handleCloseClick()}/>
        </DialogActions>
    </Dialog>
    );
};

const gtinAlreadySelected = (gtin2match: string, existingGtins: gtinObject[]): boolean => existingGtins
    .map((item) => item.value)
    .find((flatGtin) => flatGtin === gtin2match) !== undefined;

export default AddGtinDialog;
import axios from 'axios';
import {useQuery} from 'react-query';
import {API_MASTER_DATA_INDUSTRY} from 'config/api/constants';
import {dictionaryWithElements} from 'shared/models/dictionaries.model';

const fetchIndustry = async (): Promise<dictionaryWithElements> => {
    const response = await axios.get(API_MASTER_DATA_INDUSTRY);
    return response.data;
};

export const useIndustryQuery = () => useQuery(['fetchIndustry'], () => fetchIndustry(), {staleTime: Infinity});
import './ValiditySelector.scss';

import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {DeleteOutline} from '@mui/icons-material';
// import MpmKeyboardDatePicker from 'components/MpmPickers/MpmKeyboardDatePicker';
import { DatePicker } from '@mui/x-date-pickers';
import {isBefore} from 'date-fns';
import {useFormatMessage} from 'utils/translate';
import ClearIcon from '@mui/icons-material/Clear';

type ValiditySelectorProps = {
  autoFillSecondDate?: boolean,
  id: number,
  labelFrom?: string,
  labelTo?: string,
  numberOfPages?: number,
  onPageChange?: (id: number, pageNumber: string) => void,
  onRemove?: (id: number) => void,
  onValidityFromChange: (id: number, date: Date) => void,
  onValidityToChange: (id: number, date: Date) => void,
  pageNumber?: number | string, // can be string if used in rangeMode
  rangeMode?: boolean,
  removable?: boolean,
  required?: boolean,
  validityFrom: Date,
  validityTo: Date,
};

const testNumberInRange = (value: number, min: number, max: number): boolean => value >= min && value <= max;

export const applyRangeMask = (value: string, min: number, max: number): string => {
    const newValue: string = value;
    const forbiddenCharactersRegexp = /[^0-9-,]/g;
    const startsWithDigit = /^\d/g;
    const endsWithDigit = /\d$/g;
    const doubleDashRegexp = /--/g;

    const matched: string = newValue.replace(forbiddenCharactersRegexp, '');
    const doubleDashesRemoved: string = matched.replace(doubleDashRegexp, '');
    const byCommas: string[] = doubleDashesRemoved.split(',');
    const verifyRanges: string[] = byCommas.map((item) => {
        if (item && item.match(startsWithDigit) && item.match(endsWithDigit)) {
            return item;
        }
        return '';
    });
    const fromTo: string[] = verifyRanges.map((item) => {
        if (item.indexOf('-') > 0) {
            const ab = item.split('-');
            const a = parseInt(ab[0]);
            const b = parseInt(ab[1]);
            return testNumberInRange(a, min, max) && testNumberInRange(b, min, max) && b >= a ? item : '';
        } else {
            return testNumberInRange(parseInt(item), min, max) ? item : '';
        }
    });
    return fromTo.filter(i => i).join(',');
};

const ValiditySelector = ({ autoFillSecondDate, validityFrom, validityTo, onValidityFromChange, onValidityToChange, id, labelFrom, labelTo, pageNumber, numberOfPages, onPageChange, removable, onRemove, rangeMode, required }: ValiditySelectorProps) => {
  const translate = useFormatMessage();

  const handlePageChange = (value: string | number) => {
      if (typeof value === 'number') {
          onPageChange(id, value + '');
      } else {
          onPageChange(id, value);
      }
  };

  const handleRangeInputChange = (value: string) => onPageChange(id, applyRangeMask(value, 1, numberOfPages));

  const handleValidFromModified = (id, date) => {
      onValidityFromChange(id, date);
      if (autoFillSecondDate && validityTo === null) {
          onValidityToChange(id, date);
      }
  }

  const handleValidToModified = (id: number, date: Date) => onValidityToChange(id, date);

  const validateFrom = (e: boolean): boolean => {
      return e || isBefore(validityTo, validityFrom);
  };

  const validateTo = (e: boolean): boolean => {
      return e || isBefore(validityTo, validityFrom);
  };

  return (
    <div className='validitySelectorRoot'>
      { numberOfPages ?
          rangeMode ?
              <TextField
                  label={translate({id: 'headerData.pages'})}
                  value={pageNumber}
                  onChange={(e) => handlePageChange(e.target.value)}
                  onBlur={(e) => handleRangeInputChange(e.target.value)}
                  error={!pageNumber}
              />
              :
              <FormControl
                margin="normal"
                style={{width: '50%'}}
              >
                <InputLabel>Page</InputLabel>
                <Select
                  value={pageNumber}
                  onChange={(e) => handlePageChange(e.target.value)}
                >
                  {
                    Array.from(Array(numberOfPages).keys()).map(i => ++i).map((item) =>
                      <MenuItem value={item} key={item}>Page {item}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
          : null
      }
      <div className="datepickerRoot left">
          <DatePicker
              clearable
              clearText={translate({id: 'a.clear'})}
              label={labelFrom || translate({id: pageNumber ? 'headerData.pgValidF' : 'headerData.leafletValidF'})}
              value={validityFrom}
              onChange={date => handleValidFromModified(id, date)}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                  <>
                      <TextField {...params} error={validateFrom(params.error)} required={required}/>
                      <IconButton className="clearBtn" onClick={() => handleValidFromModified(id, null)}>
                          <ClearIcon />
                      </IconButton>
                  </>
              )}
          />
      </div>
      <div className="datepickerRoot">
            <DatePicker
                clearable
                clearText={translate({id: 'a.clear'})}
                label={labelTo || translate({id: pageNumber ? 'headerData.pgValidT' : 'headerData.leafletValidT'})}
                value={validityTo}
                onChange={(date) => handleValidToModified(id, date)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                    <>
                        <TextField {...params} error={validateTo(params.error)} required={required}/>
                        <IconButton className="clearBtn" onClick={() => handleValidToModified(id, null)}>
                            <ClearIcon />
                        </IconButton>
                    </>
                )}
            />
      </div>
      {
        removable ?
        <IconButton
          onClick={() => onRemove(id)}
        >
          <DeleteOutline color="secondary" fontSize="small"/>
        </IconButton> : null
      }
    </div>
  )
};

export default ValiditySelector;
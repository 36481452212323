import 'components/Selectors/Selectors.scss';
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Autocomplete, TextField} from '@mui/material';
import {API_STATUSES} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {fetchDictDictionaries} from 'shared/reducers/dictDictionaries';
import {dictionary} from 'shared/models/dictionaries.model';
import {useFormatMessage} from 'utils/translate';

type DictionarySelectorProps = {
    dictionaryId?: string,
    onDictionaryChange?: (dictionary: dictionary) => void
};

export const DictionarySelector = ({ dictionaryId, onDictionaryChange }: DictionarySelectorProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();
    
    const lang = useSelector((state: IRootState) => state.userProfile.langData);
    const dictionariesStatus = useSelector((state: IRootState) => state.dictDictionaries.status);
    const dictionariesData = useSelector((state: IRootState) => state.dictDictionaries.data);
    const [selectedDictionary, setSelectedDictionary] = useState<dictionary>({id: '', translations: {bg: '', cs: '', en: '', de: '', fr: '', hr: '', hu: '', pl: '', ro: '', sk: '', sl: ''}});

    const defaultLang: string = 'en';

    useEffect(() => {
        if (dictionariesStatus === API_STATUSES.ERROR) {
            dispatch(fetchDictDictionaries());
        }
    }, []);

    useEffect(() => {
        if (dictionariesStatus === API_STATUSES.NEW) {
            dispatch(fetchDictDictionaries());
        }
    }, [dictionariesStatus]);

    useEffect(() => {
        const foundDictionaries: dictionary = dictionariesData.find((item) => item.id === dictionaryId);
        if (foundDictionaries) {
            setSelectedDictionary(foundDictionaries);
        }
    },[dictionariesData, dictionaryId]);

    const sortDictionariesData = (dictionariesData: dictionary[], lang: string): dictionary[] =>
        dictionariesData.sort((a, b) => {
            const valueA = a.translations[lang] || a.translations[defaultLang];
            const valueB = b.translations[lang] || b.translations[defaultLang];
            if (valueA.toLowerCase() < valueB.toLowerCase()) return -1;
            else return 0;
        });


    const filterDictionaries = (options: dictionary[], { inputValue }): dictionary[] => {
        const loCased: string = inputValue ? inputValue.toLowerCase() : '';
        return options.filter((item) => item.translations[lang] ? item.translations[lang].toLowerCase().indexOf(loCased) > -1 : item.translations[defaultLang].toLowerCase().indexOf(loCased) > -1);
    }

    const handleAutocompleteChange = (value: dictionary | string) => {
        if (typeof value !== 'string') {
            setSelectedDictionary(value);
            onDictionaryChange(value);
        }
    };

    return (
        <div className="MPMSelectorRoot">
            <Autocomplete
                className="MPMSelectorInput"
                options={sortDictionariesData(dictionariesData,lang)}
                filterOptions={filterDictionaries}
                onChange={(e, value) => handleAutocompleteChange(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.translations[lang] || option.translations[defaultLang])}
                renderOption={(props, option) => <li {...props} className={selectedDictionary && option.id === selectedDictionary.id ? "MPMSelectorOption selected" : "MPMSelectorOption"} key={option.id}>
                                                    <span className="MPMName">{option.translations[lang] || option.translations[defaultLang]}</span>
                                                 </li>}
                value={selectedDictionary}
                defaultValue={dictionariesData.find((item) => item.id === dictionaryId)?.id}
                renderInput={(params) => <TextField {...params} label={translate({id: 'dictionary.dict'})} />}
            />
            {dictionariesStatus === API_STATUSES.PENDING && <div className="MPMSelectorInput loading"><span>loading</span></div>}
        </div>
    );
};

import './Attribute.scss';

import {TextField} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {numberAttributeDataModel, unitAttributeModel} from 'shared/models/attributeComponent.model';
import AttributeUnit from './AttributeUnit';

type attributeNumberProps = {
    data: numberAttributeDataModel,
    model: unitAttributeModel,
    onChange: Function,
    onClear: Function,
    required?: boolean,
    disabled?: boolean
}

const AttributeNumber = (props: attributeNumberProps) => {
    const translate = useFormatMessage();
    const {model, data, onChange, onClear, required, disabled} = props;

    const handleChange = (key, value) => {
        const newData = {...data};
        newData[key] = value;
        onChange(model.id, newData)
    };

    return (
        <AttributeUnit model={model} data={data} onChange={handleChange} onClear={() => onClear(model.id)} required={required} disabled={disabled}>
            <TextField
                className="numberInput"
                type="number"
                label={translate({id: 'a.value'})}
                value={data ? data.value : ''}
                onChange={(e) => handleChange('value', e.target.value)}
                disabled={disabled}
            />
        </AttributeUnit>
    );
};
export default AttributeNumber;
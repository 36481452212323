import axios from 'axios';
import { API_STATUSES, API_BRANCHES_FOR_BANNER } from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {branchesPerBanner} from 'shared/models/dict.model';

enum ACTION_TYPES {
    START_FETCHING_BRANCHES_LIST = 'START_FETCHING_BRANCHES_LIST',
    ON_SUCCESS_BRANCHES_LIST = 'ON_SUCCESS_BRANCHES_LIST',
    ON_ERROR_BRANCHES_LIST = 'ON_ERROR_BRANCHES_LIST'
}

export function fetchBranchesList (bannerId: string, lang: string) {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_BRANCHES_LIST
        });
        axios.get(API_BRANCHES_FOR_BANNER(bannerId, lang)).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_BRANCHES_LIST,
                    payload: { data: resp.data }
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_BRANCHES_LIST});
            }
        }).catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_BRANCHES_LIST})
        })
    }
}

const initialState: branchesPerBanner = {
    status: API_STATUSES.NEW,
    data: []
};

export type BranchesListState = Readonly<typeof initialState>;
 
const branchesListReducer = (state: BranchesListState = initialState, action): BranchesListState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_BRANCHES_LIST:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_BRANCHES_LIST:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_BRANCHES_LIST:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default branchesListReducer;
/* eslint-disable react-hooks/exhaustive-deps */

import {ReactElement, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {Button, Drawer} from '@mui/material';
import {useSnackbar} from 'notistack';
import {API_CATEGORY_BRICK_ATTRIBUTES_DETAILS} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {categoryBrick} from 'shared/models/categoryBrick.model';
import {
    characteristicPayloadProper,
    characteristicsObject,
    slimAttributeResponseModel
} from 'shared/models/attribute.model';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import Attribute, {isAttributeValid} from 'components/Attribute';
import {PaperX} from 'components/PaperX';

interface CharacteristicsDrawerProps {
    brickId: string
    onClose: () => void
    onConfirm: (characteristics: characteristicPayloadProper[]) => void
    open: boolean,
    productIds: string[],
    resetCounter: number
}

export function characteristicsObject2Array (characteristics: characteristicsObject): characteristicPayloadProper[] | string {
    const characteristicsArray: characteristicPayloadProper[] = [];
    for (const id in characteristics) {
        const characteristic = {
            attributeId: id
        };
        if (characteristics[id]) {
            for (const prop in characteristics[id]) {
                if (prop !== 'attributeName' && prop !== 'unitName') { //excluding UI-model-specific properties
                    characteristic[prop] = characteristics[id][prop];
                }
            }
            if (isAttributeValid(characteristic)) {
                characteristicsArray.push(characteristic);
            } else {
                return id;
            }
        }
    }
    return characteristicsArray;
}

const CharacteristicsDrawer = ({brickId, onClose, onConfirm, open, resetCounter}: CharacteristicsDrawerProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [lastBrickId, setLastBrickId] = useState<string>('');
    const [lastBrickDataSuccess, setLastBrickDataSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [categoryCharacteristics, setCategoryCharacteristics] = useState<characteristicsObject>({});
    const [categoryCharacteristicsData, setCategoryCharacteristicsData] = useState<slimAttributeResponseModel[]>([]);

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const getCharacteristicsForBrick = () => {
        setIsLoading(true);
        axios.get<categoryBrick>(API_CATEGORY_BRICK_ATTRIBUTES_DETAILS(brickId), {params: {lang: selectedLanguage}})
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setLastBrickDataSuccess(true);
                    setCategoryCharacteristics({});
                    setCategoryCharacteristicsData(resp.data.attributes);
                } else {
                    enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (open && brickId) {
            if (lastBrickId !== brickId) {
                setCategoryCharacteristicsData([]);
                setCategoryCharacteristics({});
                setLastBrickId(brickId);
                setLastBrickDataSuccess(false);
                getCharacteristicsForBrick();
            } else if (!lastBrickDataSuccess){
                getCharacteristicsForBrick();
            }
        }
    }, [open]);

    useEffect(() => {
        if (resetCounter > 0) {
            setCategoryCharacteristics({});
        }
    }, [resetCounter]);

    const handleSubmit = () => {
        const validCharArrayOrIncorrectCharId: characteristicPayloadProper[] | string = characteristicsObject2Array(categoryCharacteristics);
        if (typeof validCharArrayOrIncorrectCharId === 'string') {
            enqueueSnackbar(
                `${translate({id: 'a.invalidAttr'})}: ${categoryCharacteristicsData.find((item) => item.id === validCharArrayOrIncorrectCharId)?.name}`,
                {variant: 'error', persist: false});
        } else {
            onConfirm(validCharArrayOrIncorrectCharId);
        }
    };

    const handleClose = () => {
        setCategoryCharacteristics({});
        onClose();
    };

    const handleCategoryCharacteristicChange = (key, value) => {
        const newCategoryCharacteristics = {...categoryCharacteristics};
        if (value === null) {
            delete newCategoryCharacteristics[key];
        } else {
            newCategoryCharacteristics[key] = value;
        }
        setCategoryCharacteristics(newCategoryCharacteristics);
    };

    const buildCategoryCharacteristicsList = (characteristicSet: slimAttributeResponseModel[]): ReactElement[] => {
        const uiElements: ReactElement[] = [];
        for (let id in characteristicSet){
            uiElements.push(<div className="_formRow" key={id}>
                <div>
                    <Attribute data={categoryCharacteristics[characteristicSet[id].id]}
                               newData={characteristicSet[id]}
                               onChange={handleCategoryCharacteristicChange}/>
                </div>
            </div>);
        }
        return uiElements;
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <Drawer anchor="right"
                    open={open}>
                <div className="viewContainer _directionRow">
                    <PaperX className="_scrollY">
                        {buildCategoryCharacteristicsList(categoryCharacteristicsData)}
                    </PaperX>
                </div>
                <Footer
                    hideNavigationMenu
                    actionsRight={
                        <>
                            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!Object.keys(categoryCharacteristics).length}>
                                {translate({id: 'a.submit'})}
                            </Button>
                            <ButtonClose onClick={handleClose} />
                        </>
                    }
                />
            </Drawer>
        </>
    );
};
export default CharacteristicsDrawer;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Button} from '@mui/material';
import {API_PRODUCT_DATA_PRODUCTS, API_PRODUCT_LOCK, API_PRODUCT_REPLACE, API_STATUSES} from 'config/api/constants';
import {useSnackbar} from 'notistack';
import axios, {AxiosError} from 'axios';
import {paths} from 'paths';
import {FormattedMessage} from 'react-intl';
import {productPayload, productPOSTResponseModel} from 'shared/models/product.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {lockLevels, lockSetter, releaseProductLock} from 'utils/lockUtils';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';
import ProductDetails from './ProductDetails';

const ProductView = ({history}) => {
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    const [returnPath, setReturnPath] = useState<string>(paths.productsList);
    const [returnPathActiveTab, setReturnPathActiveTab] = useState<string>(null);
    const [clone, setClone] = useState<boolean>(false);
    const [confirmProductReplacementDialogOpen, setConfirmProductReplacementDialogOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNewProduct, setIsNewProduct] = useState<boolean>(false);
    const [productId, setProductId] = useState<string>(null);
    const [productPayload, setProductPayload] = useState<productPayload>(null);
    const [isProductValid, setIsProductValid] = useState<boolean>(false);
    const [originalProduct, setOriginalProduct] = useState<string>(null);
    const [hasBeenReplaced, setHasBeenReplaced] = useState<boolean>(false);

    const productAPIPost = useApi('post', null, {errMsg: 'product.saveErr', succMsg: 'product.succ', failureMsg: 'product.duplicate'});
    const productAPIPut = useApi('put', null, {succMsg: 'product.succ'});
    const productLockAPIPost = useApi('post', {}, {errMsg: 'a.error2'});

    useEffect(() => {
        const fromUrl: string = getURLParam('from');
        if (fromUrl) setReturnPath(fromUrl);

        const activeTabPreviousView: string = getURLParam('activeTab');
        if (activeTabPreviousView) setReturnPathActiveTab(activeTabPreviousView);
        
        const productId: string = getId();
        if (productId) {
            lockSetter(lockLevels.product);
            productLockAPIPost.call(API_PRODUCT_LOCK(productId)); 
        }
    }, []);

    useEffect(() => {
        if (productLockAPIPost.status === API_STATUSES.ERROR ) {
            if (returnPathActiveTab) history.push(`${returnPath}?activeTab=${returnPathActiveTab}`);
            else history.push(returnPath);
        }
    }, [productLockAPIPost.status]);

    const handleClose = () => {
        releaseProductLock();
        if (returnPathActiveTab) history.push(`${returnPath}?activeTab=${returnPathActiveTab}`);
        else history.push(returnPath);
    };

    useEffect(() => {
        const id: string = getId();
        if (id) {
            setProductId(id);
        }
        const clone: string = getURLParam('clone');
        if (clone){
            setClone(true);
        }
        const newProduct: string = getURLParam('new');
        if (newProduct){
            setIsNewProduct(true);
        }
        return () => {
            productAPIPost.clearToken();
            productAPIPut.clearToken();
        }
    }, []);

    useEffect(() => {
        if (productAPIPost.status === API_STATUSES.IDLE) {
            if (clone) {
                // redirect back to product details from 'clone' view
                history.push(`${paths.productDetails}?id=${productId}`);
            } else {
                // redirect to product details from 'new' view
                const result: productPOSTResponseModel = productAPIPost.data;
                history.push(`${paths.productDetails}?id=${result.id}`);
            }
        }
    }, [productAPIPost.status]);

    useEffect(() => {
        if (productAPIPut.status === API_STATUSES.ERROR) {
            if (productAPIPut.errorCode === 409 && productAPIPut?.dataError?.id) {
                setConfirmProductReplacementDialogOpen(true);
            } else enqueueSnackbar(`${translate({id: 'product.saveErr'})}`, {variant: 'error', persist: false});
        }
    }, [productAPIPut.status]);

    // redirect to 'copy' view
    const handleCopyClick = () => {
        if (returnPath){
            if (returnPath === paths.gtin && returnPathActiveTab) {
                history.push(`${paths.productDetailsClone}?id=${productId}&clone=true&from=${returnPath}&activeTab=${returnPathActiveTab}`);
            } else {
                history.push(`${paths.productDetailsClone}?id=${productId}&clone=true&from=${returnPath}`);
            }
        } else {
            history.push(`${paths.productDetailsClone}?id=${productId}&clone=true`);
        }
    };

    const handleProductChange = (product: productPayload, productValid: boolean, hasBeenReplaced: boolean) => {
        setIsProductValid(productValid);
        setProductPayload(productValid ? product : null);
        setHasBeenReplaced(hasBeenReplaced);
        if (originalProduct === null && productValid) {
            setOriginalProduct(JSON.stringify(product));
        }
    };

    const handleConfirmProductReplacement = () => {
        setIsLoading(true);
        axios.patch(API_PRODUCT_REPLACE(productId), {replacementProductId: productAPIPut?.dataError?.id})
            .then(() => {
                enqueueSnackbar(`${translate({id: 'product.succ'})}`, {variant: 'success', persist: false});
            })
            .catch((e: AxiosError) => {
                console.warn(e);
                enqueueSnackbar(`${translate({id: 'a.error2'})}`, {variant: 'error', persist: false});
            })
            .finally(() => {
                setIsLoading(false);
                setConfirmProductReplacementDialogOpen(false);
            });
    };

    const handleSaveClick = () => productId ? productAPIPut.call(`${API_PRODUCT_DATA_PRODUCTS}/${productId}`, productPayload) : productAPIPost.call(API_PRODUCT_DATA_PRODUCTS, productPayload);

    const handleSaveCopyClick = () => productAPIPost.call(API_PRODUCT_DATA_PRODUCTS, productPayload);

    // no check for new product as there's nothing to compare with
    const isProductChanged = () => isNewProduct ? true : originalProduct !== JSON.stringify(productPayload);

    const canSave = () => isProductValid && isProductChanged();

    const showLoading = () => productAPIPost.status === API_STATUSES.PENDING || productAPIPut.status === API_STATUSES.PENDING || isLoading;

    return (
        <div className="viewRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <ProductDetails clone={clone}
                                isNewProduct={isNewProduct}
                                productId={productId}
                                onProductChange={(newProductPayload, productValidity, hasBeenReplaced) => handleProductChange(newProductPayload, productValidity, hasBeenReplaced)}
                />
            </div>
            {!clone ?
                <Footer actionsLeft={
                    <Button color="primary" variant="contained" onClick={handleCopyClick} disabled={!productId || hasBeenReplaced}>{translate({id: 'product.createCopy'})}</Button>
                }
                  actionsRight={
                      <>
                          <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave() || hasBeenReplaced}>{translate({id: 'a.save'})}</Button>
                          <ButtonClose onClick={handleClose}/>
                      </>
                  }
                />
                :
                <Footer actionsRight={
                    <>
                        <Button color="primary" variant="contained" onClick={handleSaveCopyClick} disabled={!canSave()}>{translate({id: 'a.saveCopy'})}</Button>
                        <ButtonClose onClick={handleClose}/>
                    </>
                }
                />
            }
            <ConfirmDialog open={confirmProductReplacementDialogOpen}
                           onConfirm={handleConfirmProductReplacement}
                           onCancel={() => setConfirmProductReplacementDialogOpen(false)}
                           message={<FormattedMessage id="product.duplicateConfirmPrompt"/>}
                           confirmLabelId="a.yes"
                           cancelLabelId="a.no"
            />
        </div>
    )
}

export default ProductView;
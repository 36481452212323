import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {literalObject} from 'shared/models/literal.model';
import {useFormatMessage} from 'utils/translate';
import AgTable from 'components/AgTable/AgTable';
import {ActiveFlagCell, DictionaryElementCell} from 'components/./AgTable/renderers';
import {languages} from 'modules/Dictionaries/Dictionary';

interface dictionaryElementsTableProps {
    data: literalObject[],
    onRowClick?: (id: string) => void,
    tooltipLabelId?: string
}

const DictionaryElementsTable = (props: dictionaryElementsTableProps) => {
    const {data, onRowClick, tooltipLabelId} = props;
    const translate = useFormatMessage();

    const buildColumnDefs = (): ColDef[] => {
        const defs: ColDef[] = [
            { field: 'active', headerName: translate({ id: 'a.active' }), pinned: 'left', width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), floatingFilter: false, floatingFilterComponentParams: {suppressFilterButton:true}, filter: null},
            { field: 'en', headerName: translate({ id: 'en' }), pinned: 'left', autoHeight: true, cellRenderer: (params) => <DictionaryElementCell dictEl={params.data} lang="en"/>, valueGetter: (params) => params.data.alternatives ? params.data.translations['en'] + params.data.alternatives['en'] : params.data.translations['en'], floatingFilterComponentParams: {suppressFilterButton:true}}
        ];
        const otherLangs: ColDef[] =
            languages.map((lang) => ({
                field: lang,
                headerName: translate({ id: lang }),
                autoHeight: true,
                cellRenderer: (params) => <DictionaryElementCell dictEl={params.data} lang={lang}/>,
                valueGetter: (params) => params.data.alternatives ? params.data.translations[lang] + params.data.alternatives[lang] : params.data.translations[lang] ,
                tooltipValueGetter: () => tooltipLabelId ? translate({id: tooltipLabelId}) : null,
                floatingFilterComponentParams: {suppressFilterButton:true}
            }));
        return defs.concat(otherLangs);
    };

    return (
        <AgTable
            rowData={data}
            defaultColDef={{
                suppressMenu: true,
                floatingFilter: true,
                filter: 'agTextColumnFilter',
                suppressMovable: true
            }}
            columnDefs={buildColumnDefs()}
            onRowClicked = {(rowData) => {
                if (onRowClick) onRowClick(rowData.data.id);
            }}
        />
    )
};

export default DictionaryElementsTable;
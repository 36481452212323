/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {branch} from 'shared/models/branch.model';
import {getRepresentativeBranchString} from 'modules/MasterData/AdvertisingArea/RepresentativeBranch';

interface leafletRepresentativeBranchProps {
    branches: branch[],
    leafletRepresentativeBranchId: string
}
const LealeftRepresentativeBranch = (props: leafletRepresentativeBranchProps) => {
    const {branches, leafletRepresentativeBranchId} = props;
    const [address, setAddress] = useState<string>('-');

    const getAddress = () => {
        const match: branch = branches.find((branch) => branch.id === leafletRepresentativeBranchId);
        if (match) {
            setAddress(getRepresentativeBranchString(match));
        } else if (leafletRepresentativeBranchId) {
            setAddress(leafletRepresentativeBranchId);
        } else {
            setAddress('-');
        }
    }

    useEffect(() => {
        getAddress();
    }, [branches, leafletRepresentativeBranchId]);

    return (
        <>
            <FormattedMessage id="b.representativeBranch"/>:
            <br/>
            <span>{address}</span>
        </>
    );
};

export default LealeftRepresentativeBranch;
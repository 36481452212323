import {Button} from '@mui/material';

const TestView = () => {
  const setSthngInLclStrg = () => {
    const randomValue = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    localStorage.setItem('stringSample', randomValue.toString() + 'asd');
  };

  const setSthngInLclStrg1 = () => {
    const randomValue = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    localStorage.setItem('intSample', randomValue.toString());
  };

  const setSthngInLclStrg11 = () => {
    const randomValue = Math.floor(Math.random() * (10000 - 1 + 1)) + 1.33;
    localStorage.setItem('floatSample', randomValue.toString());
  };

  const setSthngInLclStrg111 = () => {
    const randomValue = Math.floor(Math.random() * (10000 - 1 + 1)) + 1.33;
    const objToSave = {
      randomNumber: randomValue,
      description: 'asd'
    };
    localStorage.setItem('objectSample', JSON.stringify(objToSave));
  };

  const setSthngInLclStrg2 = () => {
    const randomValue = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    localStorage.setItem('forDifferentView', randomValue.toString());
  };

  const setSthngInLclStrg3 = () => {
    const randomValue = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    localStorage.setItem('ignoreMe', randomValue.toString());
  };

  return (
      <div>
        <Button onClick={setSthngInLclStrg}>set something in local storage (string)</Button>
        <br/>
        <Button onClick={setSthngInLclStrg1}>set something in local storage (int)</Button>
        <br/>
        <Button onClick={setSthngInLclStrg11}>set something in local storage (float)</Button>
        <br/>
        <Button onClick={setSthngInLclStrg111}>set something in local storage (object)</Button>
        <br/>
        <Button onClick={setSthngInLclStrg2}>set something in local that will be ignored in other view</Button>
        <br/>
        <Button onClick={setSthngInLclStrg3}>set something in local that will be ignored by StoragePile</Button>
      </div>
  );
};

export default TestView;
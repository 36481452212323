import './ColorPicker.scss';
import {storeInLocalStorage} from 'utils/storageUtils';

interface colorPickerProps {
    value: string,
    onChange: (string) => void,
    isInsideCanvas?: boolean
}

export const frameFillLocalStorageKey: string = 'frameFill';
export const defaultFrameFillColor: string = '#ff000080';
export const getComplementaryColor = (color: string): string => {
    return '#' +
        ("0" + (255 - parseInt(color.substring(1, 3), 16)).toString(16)).slice(-2) +
        ("0" + (255 - parseInt(color.substring(3, 5), 16)).toString(16)).slice(-2) +
        ("0" + (255 - parseInt(color.substring(5, 7), 16)).toString(16)).slice(-2);
};
export const getComplementaryColorAlpha = (color: string, alpha: string) =>
    `${getComplementaryColor(color)}${alpha}`;

const ColorPicker = ({isInsideCanvas, value, onChange}: colorPickerProps) => {
    const handleColorChange = (value: string) =>{
        const colorWithOpacity = value + '80'; // adding opacity, it is then removed with slice method when passing value to color picker component as it does not support alpha
        storeInLocalStorage(frameFillLocalStorageKey, colorWithOpacity);
        onChange(colorWithOpacity);
    }

    return (
        <input className={isInsideCanvas ? "colorPickerAbsolute" : ""} id="colorPicker" type="color" value={value.slice(0, 7)} onChange={(e)=> handleColorChange(e.target.value)}/>
    );
}

export default ColorPicker;
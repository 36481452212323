import './LeafletUploadCard.scss';

import {useEffect, useState} from 'react';
import {Divider, IconButton, LinearProgress, Paper} from '@mui/material';
import {Cancel, DeleteOutline, Replay} from '@mui/icons-material';
import {API_STATUSES} from 'config/api/constants';
import {countries} from 'shared/reducers/countryMarketConst';
import { useFormatMessage } from 'utils/translate';
import {leafletUploadDialogActions} from './LeafletUploadDialog';

type LeafletUploadCardProps = {
    fileName: string,
    fileSize: string,
    status: string,
    id: string,
    country: string,
    onClick: (id: string, action: leafletUploadDialogActions) => void
};

function getStatusLabelId(status: string): string {
    switch (status){
        case API_STATUSES.NEW:
            return 'leafletUpload.statusNew';
        case API_STATUSES.PENDING:
            return 'leafletUpload.statusLoading';
        case API_STATUSES.IDLE:
            return 'leafletUpload.statusLoaded';
        case API_STATUSES.CANCELLED:
            return 'leafletUpload.statusCancelled';
        case API_STATUSES.ERROR:
            return 'a.error';
        default:
            return 'a.error';
    }
}

const LeafletUploadCard = ({fileName, fileSize, status, id, country, onClick}: LeafletUploadCardProps) => {
    const translate = useFormatMessage();

    const [uploadedCountry, setUploadedCountry] = useState<string>(country);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        if (isFirstLoad) {
            setUploadedCountry(country);
            setIsFirstLoad(false);
        }
    }, [isFirstLoad, country]);

    const getCountryName = () => countries.find((item) => item.id === uploadedCountry).name;

    const getActionButton = () => {
        switch (status) {
            case API_STATUSES.PENDING:
                return <IconButton color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.CANCEL)}>
                        <Cancel/>
                    </IconButton>;
            case API_STATUSES.CANCELLED:
            case API_STATUSES.ERROR:
                return [
                    <IconButton key="retryBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.RETRY)}>
                        <Replay/>
                    </IconButton>,
                    <IconButton key="removeBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.REMOVE)}>
                        <DeleteOutline/>
                    </IconButton>];
            default:
                return <IconButton color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.REMOVE)}>
                    <DeleteOutline/>
                </IconButton>;
        }
    };

    const handleClick = (action: leafletUploadDialogActions) => onClick(id, action);

    return (
      <Paper className="leafletUploadCardRoot" elevation={0}>
          <div className="detailsContainer">
              <div className="_directionCol labels">
                  <span>{translate({id: 'b.fileName'})}:</span>
                  <Divider/>
                  <span>{translate({id: 'leafletUpload.fileSize'})}:</span>
                  <Divider/>
                  <span>{translate({id: 'b.country'})}:</span>
              </div>
              <div className="_directionCol details">
                  <div>
                      {fileName}
                  </div>
                  <Divider/>
                  <div>
                      {fileSize}
                  </div>
                  <Divider/>
                  <div>
                      {getCountryName()}
                  </div>
              </div>
              <div className="status">
                  {translate({id: getStatusLabelId(status)})}
              </div>
              <div className="actionButtons">
                  {getActionButton()}
              </div>
          </div>
          {status === API_STATUSES.PENDING && <LinearProgress/>}
      </Paper>
    );
};

export default  LeafletUploadCard;
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {GetContextMenuItemsParams, MenuItemDef, SelectionChangedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {useSnackbar} from 'notistack';
import {API_LEAFLET_MANAGEMENT_LEAFLET_DELETE, API_LEAFLET_MANAGEMENT_LEAFLETS_OVERVIEW} from 'config/api/constants';
import {paths} from 'paths';
import {mediumType, mediumTypeTranslations, printMediaOverviewTableRow, leafletStatus} from 'shared/models/leaflet.model';
import {parseParams} from 'utils/axiosHooks/axiosHooks';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import {LoadingOverlay} from 'components/LoadingOverlay';
import AgTable, {localStorageColumnsKeys, selectAllColDef} from 'components/AgTable/AgTable';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import {validityDatesGetter} from 'components/AgTable/getters';
import {status} from 'components/OverviewFilters/OverviewFilters';
import DeleteMediaDialog from 'components/DeleteMediaDialog/DeleteMediaDialog';
import {PrintMediaOverviewAction} from './PrintMediaOverviewAction';
import {transformBEresponse} from './printMediaOverviewIO';
import OverviewFilters, {overviewFilters} from 'components/OverviewFilters';
import LeafletProgressDialog from './LeafletProgress/LeafletProgressDialog';
import MultipleProgressDialog from './LeafletProgress/MultipleProgressDialog';

export enum leafletActions {
    FRAME = 'FRAME',
    ENTER_GLOBAL_HEADER_DATA = 'ENTER_GLOBAL_HEADER_DATA',
    EDIT_GLOBAL_HEADER_DATA = 'EDIT_GLOBAL_HEADER_DATA',
    DESCRIBE_FRAMES = 'DESCRIBE_FRAMES'
}

const PrintMediaOverview = ({history}) => {
    const translate = useFormatMessage();
    const cancelToken = useRef(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProgressDialogOpen, setIsProgressDialogOpen] = useState<boolean>(false);
    const [multipleProgressDialogOpen, setMultipleProgressDialogOpen] = useState<boolean>(false);
    const [leaflets, setLeaflets] = useState<printMediaOverviewTableRow[]>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [selectedLeaflet, setSelectedLeaflet] = useState<printMediaOverviewTableRow>(null);
    const [selectedLeafletIds, setSelectedLeafletIds] = useState<string[]>([]);
    const [leafletFilters, setLeafletFilters] = useState<overviewFilters>();
    const [leafletId, setLeafletId] = useState<string>();
    const [leafletFileName, setLeafletFileName] = useState<string>();

    const { enqueueSnackbar } = useSnackbar();

    const handleFilterChange = (filters:overviewFilters) => {
        if (cancelToken.current) {
            cancelToken.current();
        }
        setLeafletFilters(filters);
    };

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if (leafletFilters) {
            loadLeaflets();
        }
    },[leafletFilters]);

    const loadLeaflets = () => {
        setIsLoading(true);
        setLeaflets([]);
        const filteredFilters = {};
        for (let prop in leafletFilters) {
            if (prop === 'status') {
                if (!leafletFilters[prop].includes(leafletStatus.ALL) && leafletFilters[prop].length !== status.length) {
                    filteredFilters[prop] = leafletFilters[prop];
                }
            }else if (prop === 'leafletTypeId') {
                if (leafletFilters[prop]){
                    filteredFilters[prop] = leafletFilters[prop].map((item) => item.literalId);
                }
            }else if (prop === 'representativeBranchId') {
                if (leafletFilters[prop]){
                    filteredFilters[prop] = leafletFilters[prop].id;
                }
            } else filteredFilters[prop] = leafletFilters[prop];
        }
        axios.get(API_LEAFLET_MANAGEMENT_LEAFLETS_OVERVIEW, {params: filteredFilters,
            paramsSerializer: (params) => parseParams(params),
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setLeaflets(transformBEresponse(resp.data));
                }
            })
            .catch(e => {
                if(!e.__CANCEL__) {
                    console.log(e)
                }
            }).finally(() => {
                setIsLoading(false);
        });
    };

    const printMediaColumnDefs: ColDef[] = [
        {...selectAllColDef, headerTooltip : translate({ id: 'a.selectAll' })},
        { field: 'banner', headerName: translate({ id: 'b.banner' }), flex: 1, lockVisible: true},
        { field: 'fileName', headerName: translate({ id: 'b.fileName' }), flex: 1},
        { field: 'language', headerName: translate({id: 'dictionaryElement.lang'}), flex: 1, maxWidth: 120, valueGetter: (params) => params.data.language ? (translate({id: 'language.'+ params.data.language})) : ''},
        { field: 'numberOfPages', headerName: translate({ id: 'printMediaOverview.numberOfPages' }), cellClass: 'ag-cell-align-right', maxWidth: 120},
        { field: 'status', headerName: translate({ id: 'printMediaOverview.status' }), flex: 1, cellRenderer: (params) => <PrintMediaOverviewAction data={params.data} value={params.value}/>},
        { field: 'leafletTypes', headerName: translate({ id: 'printMediaOverview.leafletType' }), flex: 1},
        { field: 'mediumType', headerName: translate({ id: 'b.mediumType' }), flex: 1, 
            valueGetter: (params) => params.data.mediumType ? (translate({id: params.data.mediumType === mediumType.PRESS_ADVERTISEMENT ? mediumTypeTranslations[mediumType.PRESS_ADVERTISEMENT] : mediumTypeTranslations[mediumType.LEAFLET] })) : ''
        },
        { field: 'validityDates', headerName: translate({ id: 'printMediaOverview.validityDates' }), flex: 1, valueGetter: (params) => (validityDatesGetter(params.data))},
        { field: 'createdAt', headerName: translate({ id: 'printMediaOverview.creationDate'}), maxWidth: 200},
        { field: 'createdBy', headerName: translate({ id: 'printMediaOverview.createdBy'}), maxWidth: 120},
        { field: 'modifiedBy', headerName: translate({ id: 'printMediaOverview.modifiedBy'}), maxWidth: 120},
        { field: 'repBranch', headerName: translate({ id: 'b.representativeBranch' }) , flex: 1}
    ];

    const canDo = (doWhat: leafletActions, availableActions: string[]): boolean => availableActions.findIndex((item) => item === doWhat) > -1;

    const getContextMenu = (rowNode: GetContextMenuItemsParams): (MenuItemDef | string)[] =>
        [
            canDo(leafletActions.ENTER_GLOBAL_HEADER_DATA, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'b.enterHeaderData'}),
                    action: () => {
                        history.push(`${paths.enterHeaderData}?id=${rowNode.node.data.id}&from=${paths.printMediaOverview}`)
                    }
                } : null,
            canDo(leafletActions.EDIT_GLOBAL_HEADER_DATA, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'printMediaOverview.editHeaderData'}),
                    action: () => {
                        history.push(`${paths.enterHeaderData}?id=${rowNode.node.data.id}&from=${paths.printMediaOverview}`)
                    }
                } : null,
            canDo(leafletActions.FRAME, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'printMediaOverview.editFrames'}),
                    action: () => {
                        history.push(`${paths.framing}?leafletId=${rowNode.node.data.id}&from=${paths.printMediaOverview}`)
                    }
                } :
                {
                    name: translate({id: 'printMediaOverview.editFrames'}),
                    disabled: true
                },
            canDo(leafletActions.DESCRIBE_FRAMES, rowNode.node.data.availableActions) ?
                {
                    name: translate({id: 'printMediaOverview.describeFrame'}),
                    action: () => {
                        history.push(`${paths.describeFrame}?leafletId=${rowNode.node.data.id}&from=${paths.printMediaOverview}`)
                    }
                } :
                {
                    name: translate({id: 'printMediaOverview.describeFrame'}),
                    disabled: true
                },
            rowNode.node.data.status >= 3 ?
                {
                    name: translate({id: 'nav.overviewAdvertisements'})
                } :
                {
                    name: translate({id: 'nav.overviewAdvertisements'}),
                    disabled: true
                },
                {
                    name: `${translate({id: 'a.progress'})} - ${rowNode.node.data.fileName}`,
                    action: () => {
                        setLeafletId(() => rowNode.node.data.id);
                        setLeafletFileName(() => rowNode.node.data.fileName);
                        setIsProgressDialogOpen(true);
                    }
                },
                {
                    name: `${translate({id: 'a.progress'})} (${selectedLeafletIds.length})`,
                    action: () => {
                        setMultipleProgressDialogOpen(true);
                    },
                    disabled: selectedLeafletIds.length === 0
                },
            'separator',
            rowNode.node.data.status !== leafletStatus.UPLOADED ?
                {
                    name: translate({id: 'printMediaOverview.delete'}),
                    action: () => {
                        setSelectedLeaflet(rowNode.node.data);
                        setDeleteDialogOpen(true);
                    }
                } :
                {
                    name: translate({id: 'printMediaOverview.delete'}),
                    disabled: true
                }
        ];

    const handleDeleteConfirm = () => {
        setIsLoading(true);
        axios.delete(API_LEAFLET_MANAGEMENT_LEAFLET_DELETE(selectedLeaflet['id']), {cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then(() => {
                loadLeaflets();
                enqueueSnackbar(`${translate({id: 'printMediaOverview.deleteSucc'})}`,{variant: 'success', persist: false});
                setDeleteDialogOpen(false);
            })
            .catch((e) => {
                setIsLoading(false);
                enqueueSnackbar(`${translate({id: 'printMediaOverview.deleteErr'})}`,{variant: 'error', persist: false});
                if(!e.__CANCEL__) {
                    console.log(e)
                }
            });
    };

    const handleDeleteCancel = () => {
        setDeleteDialogOpen(false);
        setSelectedLeaflet(null);
    };

    const handleCellClicked = (event) => {
        if (event.column.colId === 'status'){
            if (event.data.quickAction === leafletActions.ENTER_GLOBAL_HEADER_DATA){
                history.push(`${paths.enterHeaderData}?id=${event.data.id}`);
            }else if (event.data.quickAction === leafletActions.FRAME) {
                history.push(`${paths.framing}?leafletId=${event.data.id}&from=${paths.printMediaOverview}`);
            }else if (event.data.quickAction === leafletActions.DESCRIBE_FRAMES) {
                history.push(`${paths.describeFrame}?leafletId=${event.data.id}&from=${paths.printMediaOverview}`);
            }
        }
    };

    const handleSelectionChange = (event: SelectionChangedEvent) => setSelectedLeafletIds(event.api.getSelectedNodes().map((node) => node.data.id));

    return (
      <div className="viewRoot">
          <div className="viewport">
              <LoadingOverlay show={isLoading}/>
              <div className="viewContainer _directionCol">
                <OverviewFilters onFilterChange={handleFilterChange} view={"leaflet"}/>
                <PaperX className="_fullHeight _fullTable">
                       <AgTable
                           defaultColDef={{
                               resizable: true,
                               sortable: true,
                               tooltipValueGetter: () => translate({id: 'a.right4context'})
                           }}
                           rowData={leaflets}
                           columnDefs={printMediaColumnDefs}
                           suppressContextMenu={false}
                           getContextMenuItems={getContextMenu}
                           onSelectionChanged={(e: SelectionChangedEvent) => handleSelectionChange(e)}
                           rowSelection="multiple"
                           localStorageColumnsKey={localStorageColumnsKeys.printMediaOverview}
                           onCellClicked={(e) => handleCellClicked(e)}
                       />
                </PaperX>
              </div>
          </div>
          <LeafletProgressDialog open={isProgressDialogOpen}
                                 leafletId={leafletId}
                                 leafletFileName={leafletFileName}
                                 onClose={() => setIsProgressDialogOpen(false)}
          />
          <MultipleProgressDialog open={multipleProgressDialogOpen}
                                  leafletIds={selectedLeafletIds}
                                  onClose={() => setMultipleProgressDialogOpen(false)}
          />
          <Footer actionsRight={<Link to={paths.home}>
                                    <ButtonClose/>
                                </Link>}
          />
          <DeleteMediaDialog open={deleteDialogOpen} onConfirm={handleDeleteConfirm} onCancel={handleDeleteCancel} fileName={selectedLeaflet?.fileName}/>
      </div>
    );
};

export default PrintMediaOverview;
/* eslint-disable react-hooks/exhaustive-deps */
import './BPCODetails.scss';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IRootState} from 'shared/reducers';
import {FormattedMessage} from 'react-intl';
import {Button, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {
    API_BPCO_DETAILS,
    API_STATUSES
} from 'config/api/constants';
import {handyAttributes} from 'shared/handyAttributes';
import {basic} from 'shared/models/_common.model';
import {BPCODescriptionResponseModel, BPCOPayloadModel, buildBPCOTemplate, UIBPCOModel} from 'shared/models/BPCO.model';
import {categorySelectorOption} from 'shared/models/category.model';
import {commonAttributeDataModel} from 'shared/models/attributeComponent.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {PaperX} from 'components/PaperX';
import {Attribute, isAttributeValid} from 'components/Attribute';
import ActiveCheckbox from 'components/ActiveCheckbox';
import BPCODescriptionDisplay from 'components/Displays/BPCODescriptionDisplay';
import {LoadingOverlay} from 'components/LoadingOverlay';
import BrandSearch from 'modules/MasterData/Brand/BrandSearch';
import {MarketDisplay} from 'components/Displays';
import SupplierDisplay from 'components/Displays/SupplierDisplay';
import {transformBPCOForBE, transformForUI} from 'modules/MasterData/BPCO/BPCOio';
import BPCODescriptionSearchDialog from '../BPCODescriptions/BPCODescriptionSearchDialog';
import BrandNewDialog from 'modules/MasterData/Product/ProductDetails/BrandNewDialog/BrandNewDialog';
import StructuredBrandNewDialog from 'modules/MasterData/Product/ProductDetails/StructuredBrandNewDialog/StructuredBrandNewDialog';
import SupplierDialog from 'modules/MasterData/Product/ProductDetails/SupplierDialog'
import {CategorySearchBPCO2bRemoved} from 'modules/MasterData/ProductCategories/CategorySearchBPCO2bRemoved';

interface BPCODetailsProps {
    autosetSupplier?: boolean,
    isDescribingFrame?: boolean // set true when need to alter layout for frame description view
    onBPCOChange: (BPCO: BPCOPayloadModel, isBPCOValid: boolean) => void,
    BPCOId?: string
}

const BPCODetails = (props: BPCODetailsProps) => {
    const {autosetSupplier, isDescribingFrame, onBPCOChange, BPCOId} = props;
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const langData = useSelector((state: IRootState) => state.userProfile.langData);

    const BPCOsAPIGet = useApi('get', null, {errMsg: 'bpco.err'});

    const [BPCO, setBPCO] = useState<UIBPCOModel>(buildBPCOTemplate(countryMarket.market));
    const [BPCODescriptionSearchDialogOpen, setBPCODescriptionSearchDialogOpen] = useState<boolean>(false);
    const [supplierDialogOpen, setSupplierDialogOpen] = useState<boolean>(false);
    const [isBrandNewDialogOpen, setIsBrandNewDialogOpen] = useState<boolean>(false);
    const [isStructuredBrandNewDialogOpen, setIsStructuredBrandNewDialogOpen] = useState<boolean>(false);

    const [brandChangeCounter, setBrandChangeCounter] = useState<number>(0);

    useEffect(() => {
        if (autosetSupplier) setBrandChangeCounter(brandChangeCounter+1);
        return () => {
            BPCOsAPIGet.clearToken();
        }
    }, []);

    useEffect(() => {
        if (BPCOId) {
            BPCOsAPIGet.call(API_BPCO_DETAILS(BPCOId, langData));
        }
    }, [BPCOId]);

    useEffect(() => {
        onBPCOChange(transformBPCOForBE(BPCO), canSave());
    }, [BPCO]);

    useEffect(() => {
        if (BPCOsAPIGet.data) {
            const BPCOData: UIBPCOModel = transformForUI(BPCOsAPIGet.data);
            if (BPCOData) {
                setBPCO(BPCOData);
            }
        }
    }, [BPCOsAPIGet.data]);

    const handleCategorySelect = (option: categorySelectorOption) => {
        const newBPCO: UIBPCOModel = {...BPCO};
        newBPCO.category = option;
        setBPCO(newBPCO);
    }

    const handleCharacteristicChange = (key: string, value: commonAttributeDataModel) => {
        const newBPCO: UIBPCOModel = {...BPCO};
        newBPCO.characteristics[key] = value;
        setBPCO(newBPCO);
    };

    const handleBrandSelect = (brand: basic) => {
        setBrandChangeCounter(brandChangeCounter+1);
        setBPCO({...BPCO, brand, supplierId: '', supplierName: ''});
    };

    const handleActiveChange = (key: string, value: boolean) => setBPCO({...BPCO, active: value});

    const canSave = (): boolean => {
        const hasDescription: string = BPCO.descriptionId;
        const hasBrand: string = BPCO.brand && BPCO.brand.id;
        const hasCategory: string = BPCO.category && BPCO.category.id;
        // if optional characteristic is used - check if they're entered fully
        if (BPCO.characteristics[handyAttributes.capacity]) {
            if (!isAttributeValid(BPCO.characteristics[handyAttributes.capacity])) {
                return false;
            }
        }
        if (BPCO.characteristics[handyAttributes.contentOfTradingUnit]) {
            if (!isAttributeValid(BPCO.characteristics[handyAttributes.contentOfTradingUnit])) {
                return false;
            }
        }
        return !!hasDescription && ( !!hasBrand || !!hasCategory);
    };

    const showLoading = (): boolean => BPCOsAPIGet.status === API_STATUSES.PENDING;

    const handleDescriptionSelect = (description: BPCODescriptionResponseModel) => {
        setBPCODescriptionSearchDialogOpen(false);
        const newBPCO = {...BPCO};
        const {alternativeDescriptions, id, mainDescriptions} = description;
        newBPCO.alternativeDescriptions = alternativeDescriptions;
        newBPCO.mainDescriptions = mainDescriptions;
        newBPCO.descriptionId = id;
        setBPCO(newBPCO);
    };

    const handleSupplierSelect = (supplier: basic) => {
        const newBPCO: UIBPCOModel = {...BPCO};
        newBPCO.supplierId = supplier.id;
        newBPCO.supplierName = supplier.name;
        setBPCO(newBPCO);
        setSupplierDialogOpen(false);
    };

    const handleSupplierRemove = () => {
        const newBPCO: UIBPCOModel = {...BPCO};
        newBPCO.supplierId = '';
        newBPCO.supplierName = '';
        setBPCO(newBPCO);
        setSupplierDialogOpen(false);
    };

    const handleOpenNewBrandDialog = () => setIsBrandNewDialogOpen(true);

    const handleOpenstructuredNewBrandDialog = () => setIsStructuredBrandNewDialogOpen(true);

    return (
            <>
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionRow BPCODetailsRoot">
                    <PaperX className="_fullHeight _fullWidth _scrollY">
                        <div className="_header">
                            <FormattedMessage id="masterData.BPCO"/>
                        </div>
                        <div className="_formRow">
                            <MarketDisplay marketId={BPCO.marketId}/>
                        </div>
                        <div className="_formRow">
                            <BrandSearch onBrandSelect={handleBrandSelect} data={BPCO.brand} queryParams={{marketId: BPCO.marketId, active: true}}/>
                        </div>
                        {isDescribingFrame && 
                            <>
                                <Button variant="outlined" color="primary" size="small" className="newBrandBtn" onClick={handleOpenNewBrandDialog}>
                                    <FormattedMessage id="brand.create"/>
                                </Button>
                                <Button variant="outlined" color="primary" size="small" onClick={handleOpenstructuredNewBrandDialog}>
                                    <FormattedMessage id="brand.createStructured"/>
                                </Button>
                            </>}
                        <div className="_formRow supplierRow">
                            <SupplierDisplay name={BPCO.supplierName}/>
                            <IconButton color="secondary" onClick={() => setSupplierDialogOpen(true)} disabled={!BPCO?.brand?.id}>
                                <EditIcon/>
                            </IconButton>
                        </div>
                        <div className="_formRow">
                            <CategorySearchBPCO2bRemoved onCategorySelect={handleCategorySelect} value={BPCO.category} getOnlyIfActiveFlagEquals/>
                        </div>
                        <div className="_formRow">
                            <div className="descriptionContainer">
                                {BPCO.descriptionId ?
                                    <BPCODescriptionDisplay main={BPCO.mainDescriptions} alt={BPCO.alternativeDescriptions}/>
                                    :
                                    <div className="required" onClick={() => setBPCODescriptionSearchDialogOpen(true)}>
                                        <FormattedMessage id="b.descr"/> <span><FormattedMessage id="v.requiredIsEmpty"/></span>*
                                    </div>
                                }
                                <div className="actionWrapper">
                                    <IconButton color="secondary" onClick={() => setBPCODescriptionSearchDialogOpen(true)}>
                                        <EditIcon/>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ActiveCheckbox tabIndex={-1} value={BPCO.active} onChange={handleActiveChange}/>
                        </div>
                    </PaperX>
                    <PaperX className="_fullHeight _fullWidth _scrollY">
                        <div className="_header">
                            <FormattedMessage id="b.characteristics"/>
                        </div>
                        <div className="_formRow">
                            <div>
                                <Attribute id={handyAttributes.capacity} data={BPCO.characteristics[handyAttributes.capacity]} onChange={handleCharacteristicChange}/>
                            </div>
                        </div>
                        <div className="_formRow">
                            <div>
                                <Attribute id={handyAttributes.contentOfTradingUnit} data={BPCO.characteristics[handyAttributes.contentOfTradingUnit]} onChange={handleCharacteristicChange}/>
                            </div>
                        </div>
                    </PaperX>
                </div>
                <BPCODescriptionSearchDialog marketId={BPCO.marketId}
                                             open={BPCODescriptionSearchDialogOpen}
                                             onClose={() => setBPCODescriptionSearchDialogOpen(false)}
                                             onSelect={(description) => handleDescriptionSelect(description)}
                />
                <SupplierDialog brand={BPCO?.brand}
                                currentSupplierId={BPCO?.supplierId}
                                onClose={() => setSupplierDialogOpen(false)}
                                onRemove={() => handleSupplierRemove()}
                                onSelect={(supplier) => handleSupplierSelect(supplier)}
                                open={supplierDialogOpen}
                                brandChangeCounter={brandChangeCounter}
                />
                {isDescribingFrame &&
                    <>
                        <BrandNewDialog open={isBrandNewDialogOpen}
                                        onSelect={handleBrandSelect}
                                        onClose={() => setIsBrandNewDialogOpen(false)}/>
                        <StructuredBrandNewDialog open={isStructuredBrandNewDialogOpen}
                                                onSelect={handleBrandSelect}
                                                onClose={() => setIsStructuredBrandNewDialogOpen(false)}/>
                    </>}
            </>
    )
};
export default BPCODetails;
/* eslint-disable react-hooks/exhaustive-deps */
import './HQ.scss';

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, TextField} from '@mui/material';
import {retailerHQ, retailerHQPayload, retailerHQTemplate} from 'shared/models/retailerHQ.model';
import {
    API_CREATE_RETAILER_HQ,
    API_GET_RETAILER_HQ,
    API_STATUSES, API_UPDATE_RETAILER_HQ
} from 'config/api/constants';
import {paths} from 'paths';
import {retailer} from 'shared/models/retailer.model';
import {fetchBranchesPerRetailer} from 'shared/reducers/branchsePerRetailer';
import {IRootState} from 'shared/reducers';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {BranchesSelector, RetailerSelector} from 'components/Selectors';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';

const RetailerHQDetails = ({history}) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const HQAPIGet = useApi('get', retailerHQTemplate, {errMsg: 'retailerHQ.err'});
    const HQAPIPost = useApi('post', retailerHQTemplate, {errMsg: 'retailerHQ.saveErr', succMsg: 'retailerHQ.succ'});
    const HQAPIPut = useApi('put', retailerHQTemplate, {errMsg: 'retailerHQ.saveErr', succMsg: 'retailerHQ.succ'});

    const [currentHQ, setCurrentHQ] = useState<retailerHQ>(retailerHQTemplate);
    const [retailerId, setRetailerId] = useState<string>('');

    const branchesData = useSelector((state: IRootState) => state.branchesPerRetailer.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesPerRetailer.status);

    useEffect(() => {
        const HQId: string = getId();
        const retailerId: string = getURLParam('retailerId');
        if (retailerId) {
            setRetailerId(retailerId);
        } else if (HQId) {
            HQAPIGet.call(API_GET_RETAILER_HQ(HQId))
        }
    }, []);

    useEffect(() => {
        if (retailerId !== currentHQ.retailerId) {
            // cleanup selected branches
            setCurrentHQ({...currentHQ, retailerId, branchIds: []});
        } else {
            setCurrentHQ({...currentHQ, retailerId});
        }
        if (retailerId) {
            dispatch(fetchBranchesPerRetailer(retailerId, selectedLanguage));
        }
    }, [retailerId]);

    useEffect(() => {
        if (HQAPIGet.status === API_STATUSES.IDLE) {
            const HQ: retailerHQ = HQAPIGet.data;
            setCurrentHQ(HQ);
            setRetailerId(HQAPIGet.data.retailerId);
        }
    }, [HQAPIGet.status]);

    useEffect(() => {
        if (HQAPIPost.status === API_STATUSES.IDLE || HQAPIPut.status === API_STATUSES.IDLE) {
            history.push(`${paths.retailerHQList}?id=${retailerId}`);
        }
    }, [HQAPIPost.status, HQAPIPut.status]);

    const handleSaveClick = () => {
        const {branchIds, id, name, retailerId} = currentHQ;
        const payload: retailerHQPayload = {branchIds, name, retailerId};
        if (currentHQ.id) {
            HQAPIPut.call(API_UPDATE_RETAILER_HQ(id), payload);
        } else {
            HQAPIPost.call(API_CREATE_RETAILER_HQ, payload);
        }
    };

    const handleRetailerChange = (retailer: retailer) => setRetailerId(retailer.id);

    const handleNameChange = (name: string) => setCurrentHQ({...currentHQ, name});

    const handleSelectionChange = (branchIds: string[]) => setCurrentHQ({...currentHQ, branchIds});

    // cant create new HQ without branches, but can remove branches from existing HQ
    const cantSave = (): boolean => !currentHQ.retailerId || !currentHQ.name || (!currentHQ.id && currentHQ.branchIds.length === 0);

    const showLoading = (): boolean => branchesStatus === API_STATUSES.PENDING || HQAPIGet.status === API_STATUSES.PENDING || HQAPIPost.status === API_STATUSES.PENDING || HQAPIPut.status === API_STATUSES.PENDING;

    const columnDefs: ColDef[] = [
        { field: 'postCode', headerName: translate({ id: 'b.postCode' }), maxWidth: 100, flex: 1 },
        { field: 'city', headerName: translate({ id: 'b.city' }), flex: 1},
        { field: 'street', headerName: translate({ id: 'b.street' }), flex: 1 },
        { field: 'houseNumber', headerName: translate({ id: 'b.houseNumber' }), headerTooltip : translate({ id: 'b.houseNumber' }), width: 100 },
        { field: 'regionName', headerName: translate({ id: 'b.region'}), flex: 1},
        { field: 'nielsenRegionName', headerName: translate({ id: 'b.nielsenRegion'}), headerTooltip : translate({ id: 'b.nielsenRegion' }), width: 100 },
        { field: 'bannerName', headerName: translate({ id: 'b.banner'}) }
    ];

    return (
        <div className="viewRoot HQRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX className="inputsContainer">
                        <div className="retailerWithCountry">
                            <div className="retailerContainer">
                                <RetailerSelector retailerId={currentHQ.retailerId} onRetailerChange={handleRetailerChange}/>
                            </div>
                        </div>
                        <TextField
                            className="_halfWidth"
                            value={currentHQ.name}
                            onChange={(e) => handleNameChange(e.target.value)}
                            label={translate({id: 'retailerHQ.name'})}
                        />
                    </PaperX>
                    <BranchesSelector branches={branchesData}
                                      selectedBranches={currentHQ.branchIds}
                                      onSelectionChange={handleSelectionChange}
                                      columnDefs={columnDefs}
                    />
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>
                        <Link to={retailerId ? `${paths.retailerHQList}?id=${retailerId}` : paths.retailerHQList}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default RetailerHQDetails;
/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {brandFilters} from 'shared/models/brand.model';
import {IRootState} from 'shared/reducers';
import useDebounce from 'utils/debounce';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {useFormatMessage} from 'utils/translate';
import {MarketSelector} from 'components/Selectors';

interface BrandsFiltersProps {
    onFilterChange: (filters: brandFilters) => void
}

const sanitizeFrameFilters = (filters: brandFilters): brandFilters => {
    const saneFilters: brandFilters = {};
    if (filters.active) saneFilters.active = filters.active;
    if (filters.marketId) saneFilters.marketId = filters.marketId;
    if (filters.search && filters.search.length) saneFilters.search = filters.search;
    if (filters.structured) saneFilters.structured = filters.structured;
    return Object.keys(saneFilters).length ? saneFilters : null;
}

const storageKey: string = 'filters_brandsFilter';

export const buildFiltersTemplate = (marketId: string): brandFilters => {
    return {
        active: null,
        marketId,
        structured: null,
        search: null

    };
};

const BrandsFilters = ({onFilterChange}: BrandsFiltersProps) => {
    const translate = useFormatMessage();

    const market = useSelector((state: IRootState) => state.userProfile.countryMarket.market);

    const [filters, setFilters] = useState<brandFilters>(null);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>('');
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);

    const handleSearchInputChange = (value: string) => setFilters({...filters, search: value});

    useEffect(() => {
        const storedFilters: brandFilters = JSON.parse(getFromLocalStorage(storageKey));
        if (storedFilters) {
            storedFilters.marketId = market;
            setFilters(storedFilters);
        } else setFilters(buildFiltersTemplate(market));
    }, []);

    useEffect(() => {
        if (filters && market) {
            setFilters({...filters, marketId: market});
        }
    }, [market]);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);
    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeFrameFilters(dbncdFilters);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
                storeInLocalStorage(storageKey, stringifiedSaneFilters);
            }
        }
    }, [dbncdFilters]);

    return !filters ? null :
        <div className="_formRowDouble noMargins">
            <div>
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           value={filters.search || ''}
                           fullWidth
                           label={translate({id: 'b.brand'})}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )
                           }}
                />
            </div>
            <div>
                <MarketSelector />
            </div>
        </div>;
};

export default BrandsFilters;
/* eslint-disable react-hooks/exhaustive-deps */
import './LeafletBrowserDrawer.scss';
import {useEffect, useRef, useState} from 'react';
import {Drawer} from '@mui/material';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {API_LEAFLET_MANAGEMENT_LEAFLETS, API_STATUSES} from 'config/api/constants';
import LeafletBrowser from 'components/gfx/LeafletBrowser/LeafletBrowser';

interface LeafletBrowserDrawerProps {
    leafletId: string,
    pageNumber: number,
    isOpen: boolean
}

const LeafletBrowserDrawer = (props: LeafletBrowserDrawerProps) => {
    const {isOpen, leafletId, pageNumber} = props;

    const [previousLeafletId, setPreviousLeafletId] = useState<string>(null);
    const [previousPageNumber, setPreviousPageNumber] = useState<number>(null);
    const initialLoad = useRef(true);

    const leafletAPIGet = useApi('get', {}, {errMsg: 'headerData.err', failureMsg: 'headerData.errLocked'});

    useEffect(() => {
        if (isOpen) {
            if (initialLoad.current || previousLeafletId !== leafletId || previousPageNumber !== pageNumber) {
                leafletAPIGet.call(`${API_LEAFLET_MANAGEMENT_LEAFLETS}/${leafletId}`);
                setPreviousLeafletId(leafletId);
                setPreviousPageNumber(pageNumber);
                initialLoad.current = false;
            }
        }
    }, [leafletId, pageNumber, isOpen]);

    const externalPageNumber = () => pageNumber === leafletAPIGet?.data?.metaData?.numberOfPages ? undefined : pageNumber;

    return (
        <Drawer className="leafletBrowserDrawerRoot"
                anchor="right"
                variant="persistent"
                open={isOpen}
                hideBackdrop
        >
            <LoadingOverlay show={leafletAPIGet.status === API_STATUSES.PENDING}/>
            <div className="_directionRow">
                <PaperX className="_scrollY _fullWidth">
                    <LeafletBrowser leaflet={leafletAPIGet.data}
                                    externalPageNumber={leafletAPIGet.status === API_STATUSES.IDLE && externalPageNumber()}
                                    showFileName
                                    hideColorPicker
                                    hideReportPage/>
                </PaperX>
            </div>
        </Drawer>
    );
};

export default LeafletBrowserDrawer;
/* eslint-disable react-hooks/exhaustive-deps */
import './CountrySelector.scss';

import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {countries} from 'shared/reducers/countryMarketConst';
import {IRootState} from 'shared/reducers';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {useFormatMessage} from 'utils/translate';
import {inputSize} from 'components/Selectors/CountrySelector/CountrySelector';

interface CountrySelectorConnectedProps {
    any?: boolean,
    compact?: boolean,
    disabled?: boolean
    size?: inputSize,
}

const CountrySelectorConnected = ({compact, any, size, disabled} : CountrySelectorConnectedProps) => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const buildCountriesList = (): React.ReactNode[] => compact ?
        countries.map((country) => (<MenuItem key={country.id} value={country.id}>{country.id}</MenuItem>))
        :
        countries.map((country) => (<MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>));

    const countriesList = useMemo(buildCountriesList, [countries, compact]);

    return <div className={compact ? "countrySelectorRootCompact" : "countrySelectorRoot"}>
        <FormControl className="countrySelect" fullWidth>
            <InputLabel color="primary"><FormattedMessage id="b.country"/></InputLabel>
            <Select color="primary" value={countryMarket.preferredCountry} size={size || "medium"} disabled={disabled}
                    onChange={(e) => dispatch(setPreferredCountry(e.target.value))}
                    label={translate({id: 'b.country'})}
            >
                {any && <MenuItem key="anyCountry" value={translate({id: 'a.any'})}><FormattedMessage id="a.any"/></MenuItem>}
                {countriesList}
            </Select>
        </FormControl>
    </div>
};

export default CountrySelectorConnected;
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { LicenseManager } from 'ag-grid-enterprise';
import './index.scss';

import initStore from 'config/store';
import {
    setAxiosInterceptors,
    updateJWTTokenOnAxios,
} from 'utils/tokenUtils';
import { releaseIfLocked } from 'utils/lockUtils';
import {initializeTracker} from 'utils/analytics';
import {removeOldFaiLogEntries} from 'utils/failureService';
import {Auth0Provider, useAuth0} from '@auth0/auth0-react';

window.addEventListener('popstate', () => releaseIfLocked());

const store = initStore();
removeOldFaiLogEntries();

const Login = () => { // @TODO move to own component
    const { user, isAuthenticated, getAccessTokenSilently, isLoading, loginWithRedirect, error } = useAuth0();
    const [wait4it, setWait4it] = useState<boolean>(true);
    const authenticatedGroupName = process.env.REACT_APP_AUTH_GROUP || window.ENTERING_REACT_APP_ENV_VARIABLES.REACT_APP_AUTH_GROUP;

    useEffect(() => {
        if(error) {
            console.log('AUTH ERROR', error);
            return;
        }
        if (!isLoading) {
            if (isAuthenticated) {
                getAccessTokenSilently()
                    .then((token) => {
                        updateJWTTokenOnAxios(token);
                        setAxiosInterceptors(loginWithRedirect, getAccessTokenSilently);
                        const instrumentationEnabled = process.env.REACT_APP_INSIGHTS_ENABLED === 'true' || window.ENTERING_REACT_APP_ENV_VARIABLES.REACT_APP_INSIGHTS_ENABLED;
                        const instrumentationKey = process.env.REACT_APP_INSIGHTS_KEY || window.ENTERING_REACT_APP_ENV_VARIABLES.REACT_APP_INSIGHTS_KEY;
                        const connectionString = process.env.REACT_APP_INSIGHTS_INGESTION_POINT || window.ENTERING_REACT_APP_ENV_VARIABLES.REACT_APP_INSIGHTS_INGESTION_POINT;

                        if(instrumentationEnabled) {
                            console.log('Azure Insights enabled');
                            initializeTracker(instrumentationKey, connectionString);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        loginWithRedirect();
                    })
                    .finally(() => setWait4it(false));
            } else {
                loginWithRedirect();
            }
        }
    }, [isLoading, isAuthenticated, error]);

    if (isLoading || wait4it) {
        return <p>Loading...</p>;
    }
    if (error) {
        return <p>Authorization error</p>;
    }
    if (!isAuthenticated) {
        return<p>Redirecting to authorization service...</p>;
    }
    return user?.markant_groups?.includes(authenticatedGroupName) ? <App/> : <p>Unauthorized access. User is not a member of authorized group.</p>;
}

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH_PROVIDER || window.ENTERING_REACT_APP_ENV_VARIABLES.REACT_APP_AUTH_PROVIDER}
            clientId={process.env.REACT_APP_CLIENT_ID || window.ENTERING_REACT_APP_ENV_VARIABLES.REACT_APP_CLIENT_ID}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            authorizationParams={{
                display:"page",
                redirect_uri: window.location.origin
            }}
            authorizeTimeoutInSeconds={process.env.REACT_APP_AUTH_TIMEOUT ? parseInt(process.env.REACT_APP_AUTH_TIMEOUT) : 10}
        >
            <Login/>
        </Auth0Provider>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

LicenseManager.setLicenseKey(
    "CompanyName=Markant Services International GmbH,LicensedGroup=Markant Services International GmbH,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-013949,ExpiryDate=2_March_2022_[v2]_MTY0NjE3OTIwMDAwMA==c9adc1da1bc44c6a14d7686af2b65433"
);
